import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import FeedbackExport from '../../components/pages/admin/feedbacks/FeedbackExport'

const useFeedbacksRoute = () => {
  return [
    { path: '/feedbacks', component: ResourcesTab, hasPermission: true },
    { path: '/feedbacks/exportaciones', component: FeedbackExport, hasPermission: true }
  ]
}
export default useFeedbacksRoute
