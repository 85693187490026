import PropTypes from 'prop-types'
import React from 'react'
import ForwardIcon from '@material-ui/icons/Forward'

const Forward = ({ className }) => <ForwardIcon className={className} />

export default Forward

Forward.propTypes = {
  className: PropTypes.string
}
