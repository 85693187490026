import PropTypes from 'prop-types'
import React from 'react'
import { FormControl, FormGroup, FormLabel } from '@material-ui/core'
import clsx from 'clsx'
import CheckboxField from '../../molecules/fields/CheckboxField'
import useCategory from '../../../hooks/useCategory'

const CategoriesFilter = ({ categoryAlign = 'vertical', suffix = '' }) => {
  const { getCategoriesWithTutorial } = useCategory()
  const { data: categoriesList } = getCategoriesWithTutorial(['categoriesWithTutorial'])
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" classes={{ root: 'font-bold mr-4 mb-1.5 inline-block text-gray-800 text-base' }}>
        Categoría
      </FormLabel>
      <FormGroup>
        <div
          className={clsx(
            'flex flex-wrap flex-col',
            categoryAlign === 'horizontal' && 'md:grid md:grid-flow-row md:grid-cols-max3 lg:grid-cols-max4 gap-x-10'
          )}
        >
          {categoriesList?.map(category => (
            <CheckboxField
              className={clsx(categoryAlign === 'horizontal' ? 'w-max' : 'md:mr-2')}
              rippleSize="small"
              key={category.id}
              name={`category${suffix}`}
              label={category.name}
              value={category.id.toString()}
              margin="none"
            />
          ))}
        </div>
      </FormGroup>
    </FormControl>
  )
}

export default CategoriesFilter

CategoriesFilter.propTypes = {
  categoryAlign: PropTypes.string,
  suffix: PropTypes.string
}
