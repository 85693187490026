import React from 'react'
import FormsChart from './FormsChart'
import InstitutionsChart from './InstitutionsChart'
import useStatData from '../../../../hooks/useStatData'
import UsersChart from './usersChart'
import AnswersChart from './AnswersChart'
import ReceivedRequestsChart from './ReceivedRequestsChart'
import PropTypes from 'prop-types'

const ChartDisplay = ({ selectedIndicator, accessRules }) => {
  const { results } = useStatData(selectedIndicator)

  const renderChart = () => {
    if (!accessRules.includes(selectedIndicator)) {
      return null
    }
    switch (selectedIndicator) {
      case 'forms':
        return <FormsChart results={results} />
      case 'institutions':
        return <InstitutionsChart results={results} />
      case 'users':
        return <UsersChart results={results} />
      case 'answers':
        return <AnswersChart results={results} />
      case 'receivedRequests':
        return <ReceivedRequestsChart results={results} />
      default:
        return <div>Gráfico no habilitado</div>
    }
  }

  return <div className="chart-display">{renderChart()}</div>
}

ChartDisplay.propTypes = {
  selectedIndicator: PropTypes.string.isRequired,
  accessRules: PropTypes.array.isRequired
}

export default ChartDisplay
