import React from 'react'
import FunctionaryPage from '../../../organisms/onboarding/FunctionaryPage'
import useTutorial from '../../../../hooks/useTutorial'

const TutorialDetail = () => {
  const { getTutorial } = useTutorial()
  return <FunctionaryPage getArticle={getTutorial} />
}

export default TutorialDetail
