import PropTypes from 'prop-types'
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import AddIcon from '@material-ui/icons/Add'
import UserSection from './UserSection'
import useQuery from '../../../hooks/useQuery'
import axios from 'axios'
import { getUnselected } from '../../../utils/helpers'
import useFieldValue from '../../../hooks/useFieldValue'
import { roles } from '../../../configs/permissionsConfig'

const { collaborator } = roles
const fetchUsers = (areaID, branchOfficeId) =>
  axios
    .get(`/admin/v1/users/all.json`, {
      params: { area_id: areaID, branch_office_id: branchOfficeId, role: collaborator }
    })
    .then(a => a.data)

const AccessSection = ({ baseName, areaID }) => {
  const { fields } = useFieldArray(`${baseName}.accesses_attributes`)
  const branchOfficeId = useFieldValue('branch_office_id')
  const { data: users, status } = useQuery(['users', areaID, branchOfficeId], fetchUsers)
  if (status !== 'success') return null
  const selectedUsers = fields.value.map(field => (field?._destroy ? undefined : field?.user_id?.toString()))
  return (
    <>
      <div className="mb-6">
        {fields.map(
          (name, index) =>
            !fields.value[index]?._destroy && (
              <UserSection
                key={index}
                name={name}
                onRemove={() => fields.markForDestroy(index)}
                users={getUnselected(users, selectedUsers, index)}
              />
            )
        )}
      </div>
      <button type="button" className="font-bold text-lg text-blue-700" onClick={() => fields.push()}>
        <AddIcon />
        Agregar cuenta
      </button>
    </>
  )
}
export default AccessSection

AccessSection.propTypes = {
  areaID: PropTypes.number,
  baseName: PropTypes.string
}
