import PropTypes from 'prop-types'
import React from 'react'
import { HorizontalLinearStepper } from './HorizontalLinearStepper'
import clsx from 'clsx'

const sizeMap = {
  m: 'max-w-xl',
  l: 'max-w-208'
}

const FormsHeader = ({
  title,
  hint,
  steps = {},
  activeStep = null,
  description,
  size = 'm',
  classHint = 'mb-10 md:mb-16'
}) => {
  const activeStepIndex = Object.values(steps).findIndex(step => step === activeStep)
  const _steps = Object.values(steps).map(step => step.label)
  const _description = description || (activeStep ? activeStep.hint : null)
  const stepTitle = activeStep?.title || activeStep?.label
  return (
    <div className="mb-6 px-6 md:px-0">
      <h1 aria-describedby="page-description" className="title mb-4">
        {title}
      </h1>
      <p className={classHint} id="page-description">
        {hint}
      </p>
      {steps && activeStep && (
        <>
          <div className={clsx('m-auto mb-8', sizeMap[size])}>
            <HorizontalLinearStepper steps={_steps} activeStep={activeStepIndex} />
          </div>
          <h2 className="text-2xl font-bold">
            {activeStepIndex + 1}. {stepTitle}
          </h2>
        </>
      )}
      {_description && (
        <div className="mb-2" id="page-hint">
          {_description}
        </div>
      )}
    </div>
  )
}
export default FormsHeader

FormsHeader.propTypes = {
  activeStep: PropTypes.object,
  classHint: PropTypes.string,
  description: PropTypes.node,
  hint: PropTypes.string,
  size: PropTypes.string,
  steps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string
}
