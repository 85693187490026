import PropTypes from 'prop-types'
import React from 'react'
import * as validations from '../../../utils/validations'
import TextWithAlternativesField from './TextWithAlternativesField'

const RadioField = ({ baseName, label }) => {
  return (
    <TextWithAlternativesField
      hasAddOtherOption
      baseName={baseName}
      label={label}
      alternativeValidations={validations.requiredArray('Ingresa al menos dos opciones', 2)}
      asideAlternative={<legend className="font-bold flex items-center">Alternativas</legend>}
    />
  )
}

export default RadioField

RadioField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string
}
