import React from 'react'
import PageEdit from '../../../organisms/onboarding/PageEdit'
import useUpgrade from '../../../../hooks/useUpgrade'
import { useParams } from 'react-router-dom'

const headerInfo = { title: 'Crear artículo de actualización' }

const UpgradeEdit = () => {
  const { id } = useParams()
  const { getUpgrade, updateUpgrade } = useUpgrade()
  const { data: initialValues, status } = getUpgrade(id)

  if (status !== 'success') return null

  return <PageEdit headerInfo={headerInfo} initialValues={initialValues} updateArticle={updateUpgrade} />
}
export default UpgradeEdit
