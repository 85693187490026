import React from 'react'
import Alert from '../../../components/molecules/Alert'

const alertMessages = {
  language_saved: 'Registro exitoso de nuevo idioma',
  term_saved: 'Edición exitosa de término',
  terms_bulk_upload_saved: 'Carga masiva de traducciones exitosa'
}

const renderAlert = action => {
  const message = alertMessages[action]
  if (!message) return null

  return (
    <Alert
      showClose
      type="success"
      className="mt-4"
      displayPositionClassName="fixed md:bottom-6 mb-6 p-6 rounded-lg shadow-lg z-50 transform -translate-x-1/2 left-1/2 flex md:w-1/3 w-11/12 text-green-800"
      iconCloseColorClassName="text-blue-700 text-lg"
      iconButtonClassName="text-blue-700"
      showTextClose
    >
      {message}
    </Alert>
  )
}

export default renderAlert
