import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from '@material-ui/core'

const LightTooltip = ({ children, ...props }) => {
  return (
    <Tooltip
      classes={{
        arrow: 'text-neutral-100',
        tooltip: 'bg-neutral-100 text-gray-800 text-sm font-normal leading-5 rounded shadow-3xl p-4'
      }}
      {...props}
      arrow
    >
      {children}
    </Tooltip>
  )
}
export default LightTooltip

LightTooltip.propTypes = {
  children: PropTypes.node
}
