import React, { Fragment, useState } from 'react'
import Container from '../../../atoms/Container'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { Form } from 'react-final-form'
import { Button } from '../../../atoms/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import FileField from '../../../molecules/fields/FileField'
import * as validations from '../../../../utils/validations'
import useTerms from '../../../../hooks/useTerms'
import { useHistory } from 'react-router-dom'

const TermsBulkUpload = () => {
  const { importTerms } = useTerms()
  const history = useHistory()
  const [error, setError] = useState('')

  const handleSubmit = async values => {
    try {
      const response = await importTerms(values.file)
      if (response.status === 200) {
        setError('')
        history.push(`/traducciones?action=terms_bulk_upload_saved`)
      } else {
        throw new Error('Upload failed')
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Error al subir archivo')
    }
  }

  return (
    <Fragment>
      <PageNavigation />
      <Container className="md:pt-14 pt-8 md:pb-16 pb-0 px-5 md:px-0">
        <h1 className="title text-3xl md:text-4xl mb-10">Subir carga masiva</h1>
        <div className="mt-4 bg-gray-150 p-6">
          <h2 className="title text-base mb-4">
            1. Descarga el archivo XLSX con el listado contenido estático disponible para traducir
          </h2>
          <p className="mb-4">Selecciona el idioma al que deseas traducir el contenido estático.</p>
          <Button
            el="a"
            href="/admin/v1/terms/export_terms.xlsx"
            target="_blank"
            size="full"
            className="py-2 mt-2 mb-8 md:max-w-56"
            variant="secondary"
            startIcon={<GetAppIcon />}
          >
            Descargar ejemplo
          </Button>
          <h2 className="title text-base mb-4">2. Sube archivo XLSX con el contenido estático traducido</h2>
          <p className="mb-4">
            Si es tu primera carga masiva, intenta subir un archivo de pocas traducciones para probar la funcionalidad.
          </p>

          <Form onSubmit={handleSubmit} autoComplete="off">
            {({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <FileField name="file" className="mt-2" required={validations.required()} extensions={['xlsx']} />

                {error && <div className="text-red-500">{error}</div>}

                <div className="flex justify-end md:mt-8">
                  <Button
                    size="full"
                    type="submit"
                    className="mt-2 md:mt-auto md:w-56 h-13 lg:max-w-sm text-base md:text-lg font-bold"
                  >
                    Subir carga masiva
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Container>
    </Fragment>
  )
}

export default TermsBulkUpload
