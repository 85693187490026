import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { TruncatedTextWithTooltip } from '../../molecules/TruncatedTextWithTooltip'
import useUserPermission from '../../../hooks/useUserPermission'
import UserInfoTable from '../forms/UserInfoTable'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import QuickSearchPinnedButton from './QuickSearchPinnedButton'

const QuickSearchTable = ({ quickSearches = [], onDestroy, pinnedCount }) => {
  const { canFilterByInstitution, canCreate, canEdit, canDelete } = useUserPermission('quickSearch')

  return (
    <div className="pt-8 pb-2">
      <Table
        className="table-fixed md:w-full"
        values={quickSearches}
        head={
          <Tr body={false}>
            <Th className="w-11">ID</Th>
            <Th className="md:w-80">Nombre</Th>
            {canFilterByInstitution && <Th className="md:w-40">Entidad</Th>}
            <Th className="min-w-45 md:w-45">Creación</Th>
            <Th className="min-w-45 md:w-45">Actualización</Th>
            <Th className="md:w-27.5">Publicación</Th>
            <Th className="md:w-28">Consultas</Th>
            {canCreate && <Th className="min-w-48 w-48">Acciones</Th>}
          </Tr>
        }
        body={quickSearches.map(quickSearch => (
          <Tr key={quickSearch.id} className={quickSearch.pinned ? 'bg-yellow-100' : ''}>
            <Td>{quickSearch.id}</Td>
            <Td>
              <TruncatedTextWithTooltip text={quickSearch.name}>
                {props => (
                  <Link to={`/consultas-rapidas/${quickSearch.id}/vista-previa`} {...props}>
                    {quickSearch.name}
                  </Link>
                )}
              </TruncatedTextWithTooltip>
            </Td>
            {canFilterByInstitution && <Td>{quickSearch.institution}</Td>}
            <Td>
              <UserInfoTable
                id={quickSearch.created_by}
                name={quickSearch.creator_name}
                eliminatorName={quickSearch.creator_eliminator_name}
                eliminatedName={quickSearch.eliminated_creator_name}
                date={quickSearch.date_created_at}
                hour={quickSearch.hour_created_at}
                hasHour
              />
            </Td>
            <Td>
              <UserInfoTable
                id={quickSearch.updated_by}
                name={quickSearch.updater_name}
                eliminatorName={quickSearch.updater_eliminator_name}
                eliminatedName={quickSearch.eliminated_updater_name}
                date={quickSearch.date_updated_at}
                hour={quickSearch.hour_updated_at}
                hasHour
              />
            </Td>
            <Td>{quickSearch.published ? 'Publicado' : 'No publicado'}</Td>
            <Td>
              {quickSearch.quick_search_queries}
              {quickSearch.quick_search_queries === 1 ? ' consulta' : ' consultas'}
            </Td>
            {canCreate && (
              <Td>
                {canEdit(quickSearch) && <EditButton to={`/consultas-rapidas/${quickSearch.id}/editar`} />}
                {canDelete(quickSearch) && <DeleteButton onClick={() => onDestroy(quickSearch)} />}
                <QuickSearchPinnedButton
                  quickSearch={quickSearch}
                  disabled={!quickSearch.pinned && pinnedCount >= 10}
                />
              </Td>
            )}
          </Tr>
        ))}
      />
    </div>
  )
}

export default QuickSearchTable

QuickSearchTable.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  quickSearches: PropTypes.arrayOf(PropTypes.object),
  pinnedCount: PropTypes.number
}
