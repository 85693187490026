import PropTypes from 'prop-types'
import React from 'react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import Error from '../Error'
import clsx from 'clsx'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import Hint from '../../atoms/Hint'
import DateFieldToolbar from './DateFieldToolbar'
import DateFieldText from './DateFieldText'
import { marginSizes } from '../../../configs/sizesConfig'
import { useCustomField } from '../../../hooks/useCustomField'

const sizeMap = {
  '2xl': 'sm:max-w-sm',
  xl: 'sm:max-w-xs'
}

const DateField = ({
  label,
  name,
  placeholder,
  validate,
  containerClassName = '',
  margin = 'normal',
  classNameLabel,
  size = 'xl',
  hint,
  format,
  ariaLabel,
  hideError = false,
  ...props
}) => {
  const { input, gotError } = useCustomField(name, { validate })
  return (
    <>
      <label className={clsx('block font-bold mb-2', classNameLabel)} htmlFor={`${name}-input`}>
        {label}
      </label>
      {hint && <Hint hint={hint} name={name} />}
      <div
        className={clsx(
          'bg-white relative js-field-container border-gray-800 border-2 min-h-12 rounded',
          sizeMap[size],
          containerClassName,
          marginSizes[margin],
          gotError ? 'focus-within:border-red-500 border-red-500' : 'focus-within:border-yellow-500'
        )}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            {...input}
            autoOk
            fullWidth
            id={`${name}-input`}
            placeholder={placeholder}
            variant="inline"
            format={format || 'dd/MM/yyyy'}
            value={input.value || null}
            InputProps={{
              disableUnderline: true,
              className: 'p-2 flex items-center text-base',
              'aria-label': placeholder || ariaLabel
            }}
            KeyboardButtonProps={{ className: 'ml-0 p-0', 'aria-label': 'Abrir selector de fecha' }}
            keyboardIcon={<CalendarIcon className="text-blue-700" />}
            ToolbarComponent={DateFieldToolbar}
            TextFieldComponent={DateFieldText}
            onChange={input.onChange}
            invalidDateMessage=""
            maxDateMessage=""
            minDateMessage=""
            {...props}
          />
        </MuiPickersUtilsProvider>
        {!hideError && <Error name={name} className="absolute -bottom-6" />}
      </div>
    </>
  )
}

export default DateField

DateField.propTypes = {
  classNameLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  format: PropTypes.string,
  hideError: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  size: PropTypes.string,
  validate: PropTypes.func
}
