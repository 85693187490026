import PropTypes from 'prop-types'
import React from 'react'
import SelectField from './fields/SelectField'
import clsx from 'clsx'

const SortBy = ({ options, name = 'sort', className, disabled }) => {
  const _options = options || [
    { value: 'desc', label: 'Más recientes primero' },
    { value: 'asc', label: 'Más antiguos primero' }
  ]
  return (
    <div className={clsx('md:flex items-center', className)}>
      <label htmlFor="sort-input" className="font-bold mr-4 pb-2 md:pb-0 w-max">
        Ordenar por:
      </label>
      <SelectField
        size="full"
        name={name}
        containerClassName="min-w-60 mt-2 md:mt-0"
        options={_options}
        margin="none"
        disabled={disabled}
      />
    </div>
  )
}

export default SortBy

SortBy.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool
}
