import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Button } from '../atoms/Button'
import { ReactSVG as Svg } from 'react-svg'
import ArrowLeft from '../../images/icons/arrow_left.svg'
import clsx from 'clsx'
import { ScrollToElement } from './ScrollToElement'

const DescriptionCarousel = ({ steps }) => {
  const [index, setIndex] = useState(0)
  const pageChanged = useRef()

  const lastStepIndex = steps.length - 1
  const isLastStep = lastStepIndex === index
  const isFirstStep = index === 0
  const onNextClick = () => (isLastStep ? '' : setIndex(() => Math.min(index + 1, lastStepIndex)))
  const onPrevClick = () => setIndex(() => Math.max(index - 1, 0))
  const handleStep = step => {
    pageChanged.current = true
    setIndex(step)
  }

  return (
    <div className="md:container mx-auto px-0 md:px-4 mb-12">
      <div className="border-2 border-neutral-400 items-center md:rounded-lg">
        {pageChanged.current && <ScrollToElement trigger={index} elementId="pageSteps" />}
        <div
          className="grid md:flex justify-items-center md:items-center md:justify-between border-b-2 border-neutral-400 py-6 px-6 md:px-12"
          id="pageSteps"
        >
          <div className="flex items-center mb-6 md:mb-0">
            <p className="bg-blue-700 rounded-full px-4 py-2 text-lg font-bold text-white mt-px ml-2">{index + 1}</p>
            <p className="ml-2">{steps[index].hint}</p>
          </div>
          <ul className="flex items-center justify-center h-6">
            {steps.map((_, i) => (
              <li key={i} className="flex items-center">
                <button
                  className={clsx(
                    'bg-blue-700 rounded-full text-xs font-bold text-white mt-px ml-2 transition-size duration-300 ease-in-out',
                    i === index ? 'w-6 h-6' : 'w-4 h-4 border-4 border-blue-400 border-solid'
                  )}
                  onClick={() => handleStep(i)}
                >
                  {index === i && i + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="px-8 md:px-16 py-6 bg-neutral-100 relative">
          <div>
            <div>{steps[index].page}</div>
            <div className="absolute left-0 h-1/2 bottom-0">
              <Button
                variant="text"
                onClick={onPrevClick}
                startIcon={<Svg src={ArrowLeft} className={clsx(isFirstStep ? 'text-gray-500' : 'text-black')} />}
                className="-top-8 pl-0 md:pl-4"
                disabled={isFirstStep}
              />
            </div>
            <div className="absolute right-0 h-1/2 bottom-0">
              <Button
                variant="text"
                onClick={onNextClick}
                endIcon={
                  <Svg
                    src={ArrowLeft}
                    className={clsx(isLastStep ? 'text-gray-500' : 'text-black', 'transform rotate-180')}
                  />
                }
                className="-top-8 pr-0 md:pr-4"
                disabled={isLastStep}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DescriptionCarousel

DescriptionCarousel.propTypes = {
  steps: PropTypes.array
}
