import PropTypes from 'prop-types'
import React from 'react'
import SelectField from '../../molecules/fields/SelectField'
import { dataToOptions } from '../../../utils/helpers'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import AutocompleteFieldFilter from '../../molecules/filters/AutocompleteFieldFilter'
import useFieldValue from '../../../hooks/useFieldValue'

const ServiceTypeFilter = ({ filters, status }) => {
  const serviceType = useFieldValue('service_type')

  const serviceForms = [
    {
      id: 'forms',
      name: 'Formularios',
      selectFieldName: 'form_id',
      label: 'Selecciona un formulario',
      selectAllLabel: 'Todos los formularios',
      options: filters.forms
    },
    {
      id: 'quick_search',
      name: 'Consultas rápidas',
      selectFieldName: 'quick_search_id',
      label: 'Selecciona una consulta rápida',
      selectAllLabel: 'Todas las consultas rápidas',
      options: filters.quick_searches
    },
    {
      id: 'procedure_management',
      name: 'Gestión de trámites',
      selectFieldName: 'enabled_procedure_management_id',
      label: 'Selecciona un trámite',
      selectAllLabel: 'Todos los trámites',
      options: filters.enabled_procedure_managements
    }
  ]

  const selectedService = serviceType && serviceForms.find(s => s.id.toString() === serviceType)
  return (
    <div>
      <SelectField
        name="service_type"
        label="Tipo de servicio"
        size="2xl"
        options={dataToOptions(serviceForms)}
        emptyLabel="Todos los servicios"
        margin="dense"
      />
      {serviceType && (
        <div className="bg-neutral-300 px-4 py-4">
          <WhenFieldChanges field="service_type" set="form_id" to={null} />
          <WhenFieldChanges field="service_type" set="quick_search_id" to={null} />
          <WhenFieldChanges field="service_type" set="enabled_procedure_management_id" to={null} />
          <AutocompleteFieldFilter
            key={selectedService.id}
            label={selectedService.label}
            name={selectedService.selectFieldName}
            defaultOptionLabel={selectedService.selectAllLabel}
            options={selectedService.options}
            status={status}
            boxClassName="text-left"
            margin="none"
          />
        </div>
      )}
    </div>
  )
}

export default ServiceTypeFilter

ServiceTypeFilter.propTypes = {
  filters: PropTypes.object,
  status: PropTypes.string
}
