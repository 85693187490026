import PropTypes from 'prop-types'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import { Element } from 'react-scroll'
import clsx from 'clsx'
import Header from '../../molecules/Header'
import useWindowsSize from '../../../hooks/useWindowsSize'
import NavigateButtons from '../../molecules/buttons/NavigateButtons'
import { PageNavigation } from '../navigation/PageNavigation'

const FunctionaryPage = ({ getArticle, isPreview }) => {
  const [positionRight, setPositionRight] = useState()
  const { id } = useParams()
  const divRef = useRef()
  const { width: widthScreen } = useWindowsSize()
  const { data: article, status } = getArticle(id)
  useLayoutEffect(() => {
    const withDiv = divRef.current?.clientWidth
    withDiv && setPositionRight((widthScreen - withDiv) / 2)
  })

  if (status !== 'success') return null
  return (
    <div className="relative">
      {isPreview && <Header />}
      <PageNavigation breadcrumbData={article} breadcrumbClassName={clsx(!isPreview && 'fixed w-full z-50 -mt-22')} />
      <div
        className={clsx('grid md:flex mt-12 mb-4 gap-16 container mx-auto px-4', !isPreview && 'mt-40')}
        ref={divRef}
      >
        <div className={clsx(isPreview ? 'max-h-168 overflow-y-auto w-full' : 'md:w-3/4')} id="containerDiv">
          <div className={clsx(isPreview && 'md:w-3/4')}>
            <p className="font-bold text-3xl mb-4">{article.name}</p>
            <p className="hint-html-injected mb-8 md:mb-4" dangerouslySetInnerHTML={{ __html: article.description }} />
            <NavigateButtons isPreview={isPreview} positionRight={positionRight} sections={article.sections} />
            {article.sections?.map((section, index) => (
              <Element name={index.toString()} key={index}>
                <FieldsSeparator />
                <p className="hint-html-injected font-bold text-xl mb-4">{section.title}</p>
                <div className="hint-html-injected md:mb-4" dangerouslySetInnerHTML={{ __html: section.hint }} />
              </Element>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FunctionaryPage

FunctionaryPage.propTypes = {
  getArticle: PropTypes.func.isRequired,
  isPreview: PropTypes.bool
}
