import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const FooterLinks = ({ list }) => {
  return (
    <ul className="list-footer">
      {list.map((item, index) => (
        <li key={item.href} className={clsx(index + 1 < list.length && 'mb-5')}>
          <a
            className="text-white block underline font-normal antialiased"
            href={item.href}
            rel="noreferrer"
            target="_blank"
          >
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default FooterLinks

FooterLinks.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object)
}
