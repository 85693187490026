import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SelectField from './SelectField'
import { dataToOptions } from '../../../utils/helpers'
import AutocompleteField from './AutocompleteField'
import useInstitutionType from '../../../hooks/useInstitutionType'
import useInstitution from '../../../hooks/useInstitution'

const InstitutionTypeFieldFilter = ({ name, label, showInstitutions = false }) => {
  const [selectedInstitutionType, setSelectedInstitutionType] = useState('')

  const { getInstitutionTypes } = useInstitutionType()
  const { data: institutionTypes } = getInstitutionTypes()
  const { getInstitutions } = useInstitution()
  const { data: institutions } = getInstitutions(selectedInstitutionType)

  const handleInstitutionTypeChange = event => {
    setSelectedInstitutionType(event.target.value)
  }

  return (
    <>
      <SelectField
        className="min-w-60 mb-5 mt-3"
        label={label || 'Tipo de entidad'}
        name={`${name}_institution_type`}
        onChangeEvent={e => handleInstitutionTypeChange(e)}
        margin="none"
        size="full"
        emptyLabel="Todos"
        options={dataToOptions(institutionTypes)}
      />

      {showInstitutions && (
        <AutocompleteField
          className="min-w-60 mb-4 mt-3"
          label="Entidad"
          name={`${name}_institution_id`}
          margin="none"
          size="full"
          emptyLabel="Todas"
          options={dataToOptions(institutions)}
        />
      )}
    </>
  )
}

export default InstitutionTypeFieldFilter

InstitutionTypeFieldFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  showInstitutions: PropTypes.bool
}
