import PropTypes from 'prop-types'
import React, { cloneElement, isValidElement } from 'react'

const FieldTitle = ({ title, hint, disabled }) => {
  const isReactElement = isValidElement(hint)
  return (
    <>
      <p className="font-bold mb-2 md:mb-0">{title}</p>
      <div>{isReactElement ? cloneElement(hint, { disabled }) : hint}</div>
    </>
  )
}
export default FieldTitle

FieldTitle.propTypes = {
  hint: PropTypes.node,
  title: PropTypes.node,
  disabled: PropTypes.bool
}
