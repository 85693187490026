import PropTypes from 'prop-types'
import React from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'

const Visibility = ({ className }) => <VisibilityIcon className={className} />

export default Visibility

Visibility.propTypes = {
  className: PropTypes.string
}
