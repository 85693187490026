import React from 'react'
import ErrorAccessPage from '../../templates/ErrorAccessPage'

const AdminNotPermitted = () => {
  return (
    <div className="mb-28">
      <ErrorAccessPage
        importantText="Tu usuario no tiene los permisos para acceder a este contenido."
        description="Comunícate con el coordinador de tu entidad para solicitar el permiso necesario. Con tu usuario actual, puedes ingresar a otras secciones del menú."
        textButton="Ir a la página de inicio"
        linkButton="/"
      />
    </div>
  )
}

export default AdminNotPermitted
