import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'

const ShortTextCitizenField = ({
  index = null,
  name,
  payload,
  required,
  disabled = false,
  margin,
  onlyNumbers,
  onlyLetters
}) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const translationText = useTextValidation()
  return (
    <TextField
      label={label}
      size="2xl"
      name={name}
      hint={payload?.hint}
      disabled={disabled}
      validate={validations.mix(
        validations.maxLength(100, translationText.maxLenght(100)),
        required && validations.required(translationText.required),
        onlyNumbers && validations.isNumber(translationText.isNumberPlease),
        onlyLetters && validations.isLetter(translationText.isLetterPlease)
      )}
      margin={margin}
    />
  )
}

export default ShortTextCitizenField

ShortTextCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool,
  onlyNumbers: PropTypes.bool,
  onlyLetters: PropTypes.bool
}
