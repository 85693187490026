import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import BaseIconButton from '../atoms/BaseIconButton'
import Tooltip from '../atoms/Tooltip'
import InfoIcon from '../atoms/icons/Info'
import clsx from 'clsx'

const InfoTooltip = ({ tooltipText, iconClassName, leaveTouchDelay }) => {
  const tooltipRef = useRef(null)
  const handleClick = () => {
    tooltipRef.current.openTooltip()
  }
  return (
    <Tooltip title={tooltipText} ref={tooltipRef} leaveTouchDelay={leaveTouchDelay}>
      <BaseIconButton color="primary" ariaLabel={tooltipText} onClick={handleClick} size="small">
        <InfoIcon className={clsx('text-2xl', iconClassName)} />
      </BaseIconButton>
    </Tooltip>
  )
}

export default InfoTooltip

InfoTooltip.propTypes = {
  iconClassName: PropTypes.string,
  tooltipText: PropTypes.node,
  leaveTouchDelay: PropTypes.number
}
