import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

const useStyles = makeStyles(() => ({
  root: { minHeight: '0!important' },
  content: { margin: '0!important' }
}))

const Accordion = ({ title, children }) => {
  const classes = useStyles()
  return (
    <MuiAccordion defaultExpanded={true} classes={{ root: 'bg-transparent shadow-none' }}>
      <MuiAccordionSummary
        expandIcon={<ExpandMore className="text-blue-700 mx-3 md:hidden" />}
        classes={{
          root: clsx('bg-transparent p-0 mt-8 mb-4 overflow-hidden', classes.root),
          content: classes.content,
          expandIcon: 'p-0'
        }}
      >
        {title}
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={{ root: 'p-0' }}>{children}</MuiAccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion

Accordion.propTypes = {
  children: PropTypes.node,
  title: PropTypes.element
}
