import { useAuth } from '../../components/molecules/Auth'
import useHasRolePermissions from '../useHasRolePermissions'
import useInstitution from '../useInstitution'
import { roles, types } from '../../configs/permissionsConfig'
import { roles as userRoles } from '../../configs/usersConfig'
import general from './general'

const { admin, coordinator, branch_coordinator: branchCoordinator, collaborator } = roles
const { general: generalType } = types

const User = () => {
  const { user } = useAuth()
  const { canFilterByBranchOffice } = general()
  const { querySameInstitution, ownInstitution } = useInstitution()
  const hasRolePermissions = useHasRolePermissions()

  const canCreate = hasRolePermissions([admin, coordinator, branchCoordinator]) && querySameInstitution
  const canEdit = institutionId => canCreate && ownInstitution(institutionId)
  const canDelete = (institutionId, userId) => canCreate && ownInstitution(institutionId) && userId !== user.id

  const canFilterByAdmin = hasRolePermissions([admin])
  const canEditPassword = hasRolePermissions([admin])
  const canSeeUserInfo = hasRolePermissions([admin, coordinator, branchCoordinator])

  const canEditRoles = hasRolePermissions([admin, coordinator, branchCoordinator])
  const canEditEmail = hasRolePermissions([admin, coordinator, branchCoordinator])
  const canEditProfileType = hasRolePermissions([admin, coordinator, branchCoordinator])

  const canChangeAllowedData = values =>
    hasRolePermissions([
      admin,
      coordinator,
      branchCoordinator,
      { rolePermission: [collaborator, generalType, 'manage_permission'], additionalCondition: values?.id === user.id }
    ])

  const canEditManagePermission = hasRolePermissions([admin, coordinator, branchCoordinator])
  const canEditCreateOrEditForms = hasRolePermissions([admin, coordinator, branchCoordinator])
  const canEditCreateOrEditQuickSearches = hasRolePermissions([admin, coordinator, branchCoordinator])

  const allowedRolesByRol = {
    admin: [admin, coordinator, branchCoordinator, collaborator],
    coordinator: canFilterByBranchOffice ? [coordinator, branchCoordinator, collaborator] : [coordinator, collaborator],
    branch_coordinator: [branchCoordinator, collaborator],
    collaborator: [collaborator],
    logged_out: []
  }

  const allowedRoles = allowedRolesByRol[user.role]

  const canShowAllowedData = role => role === collaborator

  const getRoleName = user => userRoles.find(role => role.code === user.role)?.name

  const canSeeBranchOfficeAssignedNotification =
    hasRolePermissions([collaborator]) && user?.branch_office_id && !user?.showed_branch_office_notification

  return {
    canFilterByAdmin,
    canEditPassword,
    canCreate,
    canEdit,
    canDelete,
    canSeeUserInfo,
    canEditEmail,
    canEditRoles,
    canEditProfileType,
    canChangeAllowedData,
    canEditCreateOrEditForms,
    canEditCreateOrEditQuickSearches,
    canEditManagePermission,
    allowedRoles,
    canShowAllowedData,
    getRoleName,
    canSeeBranchOfficeAssignedNotification
  }
}

export default User
