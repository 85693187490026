import PropTypes from 'prop-types'
import React from 'react'
import { CircularProgress as CircularProgressIcon } from '@material-ui/core'

const CircularProgress = ({ className, ariaLabel, id }) => (
  <CircularProgressIcon aria-label={ariaLabel} size={24} className={className} id={id} />
)

export default CircularProgress

CircularProgress.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  id: PropTypes.string
}
