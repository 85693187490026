import React from 'react'
import axios from 'axios'
import Exports from '../../../templates/Exports'
import queryString from 'query-string'

const fetchExports = search => {
  const parsedSearch = queryString.parse(search)
  const url = queryString.stringifyUrl({
    url: `/admin/v1/exports.json?exportable_id=null&exportable_type=feedback`,
    query: parsedSearch
  })
  return axios.get(url).then(a => a.data)
}
const filterLabel = {
  search: 'Nombre o ID',
  institution_name: 'Emisor',
  reason_type_code: 'Tipo',
  sender_type_code: 'Entidad',
  device_type_code: 'Dispositivo',
  device_os_code: 'Sistema Operativo',
  browser_code: 'Navegador'
}
const AnswerExport = () => {
  const exportDescription = 'Estos son los archivos exportados para el feedback'
  return <Exports fetchExports={fetchExports} exportDescription={exportDescription} filterLabel={filterLabel} />
}

export default AnswerExport
