import PropTypes from 'prop-types'
import React from 'react'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import Email from '@material-ui/icons/EmailOutlined'
import Phone from '@material-ui/icons/PhoneOutlined'
import { Button } from '../../atoms/Button'
import ArrowLeft from '../../../images/icons/vector_left.svg'
import { ReactSVG as Svg } from 'react-svg'
import useGlobalConfigs from '../../../hooks/useGlobalConfigs'
import { isSuccessful } from '../../../hooks/useQuery'

const getValuesToOperate = (values, data) => {
  const amount = parseFloat(values[0]).toFixed(2)
  return { amount, constantValue: data[values[1].toLowerCase()] }
}

const executeFormulaAndgetFormattedText = (formula, data) => {
  let total = ''
  if (formula.includes('%*')) {
    const values = getValuesToOperate(formula.split('%*'), data)
    total = (values?.amount / 100) * values?.constantValue
  } else {
    const operators = ['+', '-', '*', '/']
    const indexOperator = operators.findIndex(operator => formula.includes(operator))
    const operator = operators[indexOperator]
    if (operator) {
      const values = getValuesToOperate(formula.split(operator), data)
      total = eval(`${values?.amount} ${operator} ${values?.constantValue}`).toFixed(2)
    } else {
      total = formula
    }
  }
  if (parseFloat(total) >= 0) {
    return `<span class='inline-block border-green-600 border border-solid font-semibold bg-green-500 text-green-800 leading-5 text-sm md:text-base p-1 md:mx-1 mb-2' >
       S/ ${total}
      </span>
    `
  } else return `{costo: ${formula}}`
}

const getFormulas = text => {
  const regex = /{costo:(.*?)}/gim
  let matches
  const formulas = []

  while ((matches = regex.exec(text)) !== null) {
    if (matches.index === regex.lastIndex) regex.lastIndex++
    matches?.forEach((_match, index) => {
      if (index === 1) formulas.push(_match)
    })
  }
  return formulas
}

const getFormattedText = (text, data) => {
  let formattedText = text
  const formulas = getFormulas(text)
  formulas.forEach(formula => {
    const moneyTag = executeFormulaAndgetFormattedText(formula, data)
    formattedText = formattedText.replace(`{costo:${formula}}`, moneyTag)
  })
  return formattedText
}

const ResultInfo = ({ labelTypeDocument, documentNumber, welcomeMessage, title, hint, contactInfo, onBack }) => {
  const { data, status } = useGlobalConfigs()
  if (!isSuccessful(status)) return null
  return (
    <div className="px-12 pt-6 pb-12">
      <Button
        size="m"
        variant="text"
        onClick={() => onBack()}
        className="-ml-4 font-bold"
        startIcon={<Svg src={ArrowLeft} />}
      >
        Hacer otra consulta
      </Button>
      <p className="text-xl mb-6">
        Resultado para {labelTypeDocument}: <strong className="font-bold">{documentNumber}</strong>
      </p>
      <p className="text-2xl mb-2">{welcomeMessage}</p>
      <strong
        className="text-xl font-bold mb-6 break-words block"
        dangerouslySetInnerHTML={{ __html: getFormattedText(title, data) }}
      />
      <div
        className="hint-html-injected mt-6 mb-12 break-words"
        dangerouslySetInnerHTML={{ __html: getFormattedText(hint, data) }}
      />
      {contactInfo && (
        <div className="mb-12">
          <FieldsSeparator />
          <p className="mb-2">Para más información, comunícate con nosotros:</p>
          <div className="flex mb-2">
            <Email className="mr-2" />
            {contactInfo.email}
          </div>
          <div className="flex">
            <Phone className="mr-2" />
            {contactInfo.phone}
          </div>
        </div>
      )}
    </div>
  )
}

export default ResultInfo

ResultInfo.propTypes = {
  contactInfo: PropTypes.exact({
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  documentNumber: PropTypes.string,
  hint: PropTypes.string,
  labelTypeDocument: PropTypes.string,
  onBack: PropTypes.func,
  title: PropTypes.string,
  welcomeMessage: PropTypes.string
}
