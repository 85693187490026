import { Button } from '../../atoms/Button'
import ExcelExport from '../../atoms/icons/ExcelExport'
import StatCards from './StatCards'
import React from 'react'
import useFilters from '../../../hooks/useFilters'
import PropTypes from 'prop-types'

const StatCardsWrapper = ({ onShowMoreDetail }) => {
  const { results, filters } = useFilters('/admin/v1/stats.json', { sort: 'desc' })

  const downloadExcel = (baseURL, filters) => {
    const searchParams = new URLSearchParams(filters)
    return `${baseURL}?${searchParams.toString()}`
  }

  return (
    <>
      <StatCards results={results.stats} onShowMore={onShowMoreDetail} />
      <div className="py-8 px-6 bg-blue-300 flex justify-end items-center border-b border-gray-350 border-solid">
        <Button
          className="flex mb-2 px-4 py-3"
          el="a"
          variant="secondary"
          href={downloadExcel('/admin/v1/stats.xlsx', filters)}
          target="_blank"
        >
          <ExcelExport /> Exportar en Excel
        </Button>
      </div>
    </>
  )
}
StatCardsWrapper.propTypes = {
  onShowMoreDetail: PropTypes.func.isRequired
}
export default StatCardsWrapper
