import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useForm } from 'react-final-form'
import CheckboxField from '../../molecules/fields/CheckboxField'
import SelectField from '../../molecules/fields/SelectField'
import useFieldValue from '../../../hooks/useFieldValue'
import * as validations from '../../../utils/validations'
import Value from '../../molecules/Value'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import { dataToOptions } from '../../../utils/helpers'
import TextWithAlternativesField from './TextWithAlternativesField'

const CheckboxesField = ({ baseName, label }) => {
  const { mutators } = useForm()
  const alternatives = useFieldValue(`${baseName}.payload.alternatives`)

  const quantityAlternativeLimit = Array(alternatives.length)
    .fill()
    .map((_, i) => ({ code: i + 1, name: i + 1 }))
    .splice(1, alternatives.length - 2)

  useEffect(() => {
    if (alternatives.length <= 2) {
      mutators.clear(`${baseName}.payload.alternative_limits`)
    }
  }, [alternatives.length])

  return (
    <TextWithAlternativesField
      hasAddOtherOption
      baseName={baseName}
      label={label}
      alternativeValidations={validations.requiredArray('Ingresa al menos una opción.', 1)}
      asideAlternative={
        <>
          <legend className="font-bold flex items-center">Alternativas</legend>
          <div className="flex flex-wrap ml">
            <WhenFieldChanges
              field={`${baseName}.has_alternative_limit`}
              set={`${baseName}.payload.alternative_limits`}
              to={null}
            />
            <CheckboxField
              name={`${baseName}.has_alternative_limit`}
              margin="none"
              label="Limitar la cantidad de alternativas que se pueden marcar"
              className="mb-7"
              disabled={alternatives.length <= 2}
            />
            <Value name={`${baseName}.has_alternative_limit`}>
              {({ value }) =>
                value &&
                alternatives.length > 2 && (
                  <div className="w-96 md:w-32">
                    <SelectField
                      name={`${baseName}.payload.alternative_limits`}
                      size="w-24"
                      options={dataToOptions(quantityAlternativeLimit)}
                      validate={validations.required()}
                      className="w-full md:w-96"
                      parentClassName="md:ml-4"
                      emptyLabel=""
                    />
                  </div>
                )
              }
            </Value>
          </div>
        </>
      }
    />
  )
}

export default CheckboxesField

CheckboxesField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string
}
