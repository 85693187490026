import axios from 'axios'
import useCrud from './useCrud'

const useLanguages = (locale = 'es', state = '') => {
  const { get: getLanguages } = useCrud(`/admin/v1/languages`)
  const { data: languages } = getLanguages(['languages', state], { state })

  const { get: getCheckFormsInDisabledLanguages } = useCrud(`/admin/v1/languages/check_forms_in_disabled_languages`)
  const { data: checkFormsInDisabledLanguages } = getCheckFormsInDisabledLanguages(['checkFormsInDisabledLanguages'])

  const { get: getLanguage } = useCrud(`/admin/v1/languages`)
  const { data: language } = getLanguage(['language', locale], { locale })

  const { create: createLanguage } = useCrud(`/admin/v1/languages`)

  const updateLanguageStatuses = async values => {
    const updates = Object.keys(values).map(code => ({
      code,
      state: values[code] ? 'enabled' : 'disabled'
    }))

    try {
      const response = await axios.post('/admin/v1/languages/update_states', { languages: updates })
      return response
    } catch (error) {
      alert(error)
    }
  }

  return {
    languages,
    createLanguage,
    language,
    updateLanguageStatuses,
    checkFormsInDisabledLanguages
  }
}

export default useLanguages
