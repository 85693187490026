import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import HiddenField from './HiddenField'

const DocIdDocTypeField = ({ baseName, question, questionTranslated }) => {
  return (
    <div className="grid gap-4">
      {question.payload.person_type !== 'juridica' && (
        <Field name={`${baseName}.payload.doc_type_label`} validate={validations.required()}>
          {({ input, meta }) => (
            <TextField
              label="Etiqueta de la pregunta"
              size="full"
              hint={question.payload.doc_type_label}
              error={meta.touched && meta.error}
              {...input}
              placeholder="Ingresa traducción"
            />
          )}
        </Field>
      )}

      <Field name={`${baseName}.payload.doc_id_label`} validate={validations.required()}>
        {({ input, meta }) => (
          <TextField
            label="Etiqueta de la pregunta"
            size="full"
            hint={question.payload.doc_id_label}
            {...input}
            placeholder="Ingresa traducción"
          />
        )}
      </Field>

      <HiddenField name={`${baseName}.payload.has_hint`} value={question.payload.has_hint} />
      <HiddenField name={`${baseName}.payload.has_indication_text`} value={question.payload.has_indication_text} />
      <HiddenField name={`${baseName}.payload.person_type`} value={question.payload.person_type} />
    </div>
  )
}

export default DocIdDocTypeField

DocIdDocTypeField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
