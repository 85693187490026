import React, { Fragment, useState } from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import AreasSection from './AreasSection'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import TaskByUserTypeModal from './TaskByUserTypeModal'

const UserTypesSection = () => {
  const [userType, setUserType] = useState(null)
  const { fields } = useFieldArray(`user_types_attributes`)
  return (
    <div className="bg-neutral-100 px-4 md:px-12 py-8 md:py-12 mb-8">
      {fields.map((name, index) => (
        <Fragment key={index}>
          {index !== 0 && <FieldsSeparator />}
          <div className="flex flex-col md:flex-row md:justify-between">
            <div>
              <div className="font-bold text-xl md:text-2xl mb-1">
                {index + 1}. {fields.value[index]?.name}
              </div>
              <div className="md:pl-7 mb-4">{fields.value[index]?.hint}</div>
            </div>
            <button
              className="font-bold text-blue-700 text-lg mb-6"
              onClick={() => setUserType(fields.value[index])}
              type="button"
            >
              Ver sus tareas
            </button>
          </div>
          <div className="md:pl-7">
            <AreasSection baseName={name} />
          </div>
        </Fragment>
      ))}
      {userType !== null && <TaskByUserTypeModal userType={userType} onClose={() => setUserType(null)} />}
    </div>
  )
}
export default UserTypesSection
