import React from 'react'
import SelectFieldFilter from '../../molecules/filters/SelectFieldFilter'
import { useQuery } from 'react-query'
import axios from 'axios'
import useUserPermission from '../../../hooks/useUserPermission'
import { FormControl } from '@material-ui/core'
import ServiceTypeFilter from './ServiceTypeFilter'
import CheckboxField from '../../molecules/fields/CheckboxField'

const fetchFilters = () => axios.get('/admin/v1/feedbacks/filters.json').then(res => res.data)

const FeedbacksFilter = () => {
  const { canSeeSenderType, canSeeServiceType } = useUserPermission('feedback')
  const { data: filters, status } = useQuery(['feedback_filters'], fetchFilters, { refetchOnWindowFocus: false })

  const senderTypes = [
    {
      code: 'public_entity',
      name: 'Entidad pública'
    },
    {
      code: 'citizen_to_contact',
      name: 'Ciudadano (quiere ser contactado)'
    },
    {
      code: 'citizen_not_contact',
      name: 'Ciudadano (no quiere ser contactado)'
    }
  ]

  if (status !== 'success') return null
  return (
    <div className="pt-6 pb-2 w-full grid grid-cols-1 md:grid-cols-3 gap-8">
      {canSeeSenderType && (
        <FormControl component="fieldset">
          <legend className="text-base font-bold text-gray-800">Emisor</legend>
          {senderTypes.map((option, index) => (
            <CheckboxField key={index} name="sender_type_code" margin="none" label={option.name} value={option.code} />
          ))}
        </FormControl>
      )}
      <FormControl component="fieldset">
        <legend className="text-base font-bold text-gray-800">Tipo</legend>
        {filters.reason_types.map((option, index) => (
          <CheckboxField key={index} name="reason_type_code" margin="none" label={option.name} value={option.code} />
        ))}
      </FormControl>
      {canSeeServiceType && <ServiceTypeFilter filters={filters} status={status} />}
      <SelectFieldFilter
        label="Dispositivo"
        options={filters.device_types}
        name="device_type_code"
        emptyLabel="Todos los dispositivos"
        margin="none"
      />
      <SelectFieldFilter
        label="Sistema operativo"
        options={filters.device_os_s}
        name="device_os_code"
        emptyLabel="Todos los sistemas"
        margin="none"
      />
      <SelectFieldFilter
        label="Navegador"
        options={filters.browsers}
        name="browser_code"
        emptyLabel="Todos los navegadores"
        margin="none"
      />
    </div>
  )
}

export default FeedbacksFilter
