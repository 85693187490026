import PropTypes from 'prop-types'
import React from 'react'
import RadioField from '../question_config_fields/RadioField'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioCitizenField from '../question_fields/RadioCitizenField'

const Form = props => {
  return <RadioField baseName={props.baseName} />
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <RadioField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <RadioCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const RadioQuestion = {
  selectOption: {
    value: 'radio',
    label: 'Botones (selección única)',
    icon: <RadioButtonCheckedIcon fontSize="small" />
  },
  Form,
  Template,
  name: 'RadioQuestion'
}

export default RadioQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
