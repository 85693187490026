import React from 'react'
import CheckboxesField from '../../molecules/fields/CheckboxesField'
import DateField from '../../molecules/fields/DateField'
import Filter from '../../molecules/filters/Filter'
import * as validations from '../../../utils/validations'
import { FormControl, FormLabel } from '@material-ui/core'
import { answerStates } from '../../../configs/answerConfig'

const webHookStates = [
  { code: 'success', name: 'Enviadas' },
  { code: 'initial', name: 'No enviadas' }
]

const AnswersFilter = () => (
  <Filter>
    <FormControl component="fieldset" classes={{ root: 'block md:flex' }}>
      <CheckboxesField label="Estado" options={answerStates} name="status" />
    </FormControl>
    <FormControl component="fieldset">
      <CheckboxesField label="Enviadas a la API" options={webHookStates} name="webhook_status" />
    </FormControl>
    <FormControl component="fieldset">
      <FormLabel component="legend" className="font-bold text-gray-800 text-base">
        Fecha de creación
      </FormLabel>
      <DateField name="from" placeholder="Desde" validate={validations.isDate()} />
      <DateField name="to" placeholder="Hasta" validate={validations.isDate()} />
    </FormControl>
  </Filter>
)

export default AnswersFilter
