import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import IconButton from '../../molecules/buttons/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import useForm from '../../../hooks/useForm'
import { isError, isSuccessful } from '../../../hooks/useQuery'
import { AlertContext } from '../../../utils/contexts'

const FormsCopyButton = ({ form }) => {
  const { invalidateForms } = useForm()
  const {
    form: subscribedForm,
    isSubscriptionEnabled,
    setIsSubscriptionEnabled,
    setForm,
    streamData
  } = useContext(AlertContext)

  useEffect(() => {
    if (isError(streamData?.state) || isSuccessful(streamData?.state)) {
      invalidateForms()
    }
  }, [streamData])

  const handleClick = () => {
    setForm(form)
    setIsSubscriptionEnabled()
  }

  return (
    <IconButton
      text="Crear copia"
      onClick={handleClick}
      disabled={isSubscriptionEnabled && subscribedForm.id === form.id}
    >
      <FileCopyIcon />
    </IconButton>
  )
}

export default FormsCopyButton

FormsCopyButton.propTypes = {
  form: PropTypes.object.isRequired
}
