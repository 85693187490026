import PropTypes from 'prop-types'
import React from 'react'
import { numberFormatter } from '../../../utils/helpers'

const TasksWithPayment = ({ data }) => {
  return (
    <>
      <div className="mb-2">Este trámite tiene los siguientes pagos:</div>
      <ul className="list-disc ml-4">
        {data.map((taskWithPayment, index) =>
          taskWithPayment.kind === 'default' ? (
            <li key={index}>
              Pago de <strong>{numberFormatter.currency(taskWithPayment.payment.cost)}</strong> al momento de{' '}
              <strong>enviar el formulario.</strong>
            </li>
          ) : taskWithPayment.payment.amount_type === 'fixed' ? (
            <li key={index}>
              Pago de <strong>{numberFormatter.currency(taskWithPayment.payment.cost)}</strong> en la tarea de{' '}
              <strong>{taskWithPayment.name}.</strong>
            </li>
          ) : (
            <li key={index}>
              Pago cuyo monto <strong>será definido por {taskWithPayment.origin_user_type_name}</strong> en la tarea de{' '}
              <strong>{taskWithPayment.name}.</strong>
            </li>
          )
        )}
      </ul>
    </>
  )
}
export default TasksWithPayment

TasksWithPayment.propTypes = {
  data: PropTypes.array
}
