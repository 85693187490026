import PropTypes from 'prop-types'
import React, { useState } from 'react'
import FeedbackForm from './FeedbackForm'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../molecules/Auth'
import { useTranslation } from 'react-i18next'

const Feedback = ({ senderType }) => {
  const auth = useAuth()
  const { user } = auth || {}
  const { pathname } = useLocation()
  const [feedbackSent, setFeedbackSent] = useState(false)
  const { t, ready } = useTranslation('feedback', { useSuspense: false })

  if (pathname === '/' || (pathname === '/formularios' && !user)) return null
  if (!ready) return null

  return (
    <section className="bg-white mt-8 mb-6">
      <div className="bg-gray-150 w-full md:w-2/3 lg:max-w-lg m-auto rounded">
        {feedbackSent ? (
          <span className="flex justify-center font-bold p-4 md:px-8 md:py-5 text-blue-700">
            {t('feedback_thank_you_for_helping_us_improve')}
          </span>
        ) : (
          <FeedbackForm onFeedbackSent={() => setFeedbackSent(true)} senderType={senderType} />
        )}
      </div>
    </section>
  )
}

export default Feedback

Feedback.propTypes = {
  senderType: PropTypes.string
}
