import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import UserInfoTable from '../forms/UserInfoTable'
import CheckboxField from '../../molecules/fields/CheckboxField'
import useFieldValue from '../../../hooks/useFieldValue'
import useForm from '../../../hooks/useForm'
import useQuickSearch from '../../../hooks/useQuickSearch'
import { useField } from 'react-final-form'

const UsersFormAssignedTable = ({ name, formsQuickSearches = [] }) => {
  const allValue = useFieldValue(`${name}.all_forms`)
  const { input: formField } = useField(`${name}.forms`)
  const { input: quickSearchField } = useField(`${name}.quick_searches`)
  const firstUpdate = useRef(true)

  const { getAllFormIds } = useForm()
  const { data: allFormIds, status: allFormIdsStatus } = getAllFormIds(['allFormIds'])
  const { getAllQuickSearchIds } = useQuickSearch()
  const { data: allQuickSearchIds, status: allQuickSearchIdsStatus } = getAllQuickSearchIds(['allQuickSearchIds'])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (allValue) {
      formField.onChange(allFormIds)
      quickSearchField.onChange(allQuickSearchIds)
    } else {
      formField.onChange([])
      quickSearchField.onChange([])
    }
  }, [allValue])

  return (
    <div>
      <div className="bg-white p-4 inline-flex items-center">
        {allFormIdsStatus && allQuickSearchIdsStatus && (
          <CheckboxField name={`${name}.all_forms`} margin="none" label="Seleccionar todos" />
        )}
      </div>
      <Table
        className="table-fixed md:w-full"
        values={formsQuickSearches}
        head={
          <Tr body={false}>
            <Th className="w-10">-</Th>
            <Th className="w-10">ID</Th>
            <Th className="md:w-80">Nombre</Th>
            <Th className="md:w-40">Clase de formulario</Th>
            <Th className="min-w-45 md:w-45">Creación</Th>
            <Th className="min-w-45 md:w-45">Actualización</Th>
            <Th className="md:w-27.5">Publicación</Th>
          </Tr>
        }
        body={
          <>
            {formsQuickSearches.map(form => (
              <Tr key={form.id}>
                <Td>
                  <CheckboxField name={`${name}.${form.allowed_user_name}`} value={form.id} margin="none" />
                </Td>
                <Td>{form.id}</Td>
                <Td>{form.name}</Td>
                <Td>{form.type}</Td>
                <Td>
                  <UserInfoTable
                    id={form.created_by}
                    name={form.creator_name}
                    eliminatorName={form.creator_eliminator_name}
                    eliminatedName={form.eliminated_creator_name}
                    date={form.date_created_at}
                    hour={form.hour_created_at}
                    hasHour
                    readOnly
                  />
                </Td>
                <Td>
                  <UserInfoTable
                    id={form.updated_by}
                    name={form.updater_name}
                    eliminatorName={form.updater_eliminator_name}
                    eliminatedName={form.eliminated_updater_name}
                    date={form.date_updated_at}
                    hour={form.hour_updated_at}
                    hasHour
                    readOnly
                  />
                </Td>
                <Td>{form.published ? 'Publicado' : 'No publicado'}</Td>
              </Tr>
            ))}
          </>
        }
      />
    </div>
  )
}

UsersFormAssignedTable.propTypes = {
  name: PropTypes.string,
  formsQuickSearches: PropTypes.array
}

export default UsersFormAssignedTable
