import PropTypes from 'prop-types'
import React from 'react'
import CitizenFormContainer from '../citizens/CitizenFormContainer'
import { formValuesPreview } from '../../../configs/templatesConfig'
import { Link } from 'react-router-dom'
import { Button } from '../../atoms/Button'
import Dialog from '../../molecules/dialogs/Dialog'
import useInitialForm from '../../../hooks/useInitialForm'
import FieldsSeparator from '../../atoms/FieldsSeparator'

const SelectPreviewModal = ({ procedureManagement, onClose, hideSelect = false }) => {
  const { data: initialForm, status } = useInitialForm(procedureManagement.id)

  return (
    status === 'success' && (
      <Dialog
        maxWidth="md"
        renderTitle={
          <>
            <p className="text-lg font-normal">
              Vista previa del <span className="font-bold text-lg">formulario de solicitud del ciudadano</span>
            </p>
            <p className="font-normal font-bold text-2xl">{procedureManagement.name}</p>
            <FieldsSeparator className="my-2" />
          </>
        }
        renderContent={
          <div className="border-3 border-neutral-300">
            <CitizenFormContainer form={formValuesPreview(initialForm)} isPreview />
          </div>
        }
        renderActions={
          !hideSelect && (
            <Button
              type="button"
              el={Link}
              to={`/gestiones-de-tramites-habilitadas/crear/${procedureManagement.id}`}
              className="my-2 mr-6"
            >
              Seleccionar trámite
            </Button>
          )
        }
        onClose={onClose}
      />
    )
  )
}
export default SelectPreviewModal

SelectPreviewModal.propTypes = {
  hideSelect: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  procedureManagement: PropTypes.object
}
