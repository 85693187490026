import React from 'react'
import PropTypes from 'prop-types'
import CustomChart from './CustomChart'
import useStatisticChart from '../../../../hooks/useStatisticChart'

const UsersChart = ({ results }) => {
  const headerNames = {
    all: 'Todos',
    admin: 'Administradores',
    coordinator: 'Coordinadores de entidad',
    branch_coordinator: 'Coordinadores de sede',
    collaborator: 'Colaboradores'
  }
  const { chartData, chartTime } = useStatisticChart(results, ['Años', 'Usuarios registrados'], headerNames)

  return (
    <CustomChart
      chartData={chartData}
      titleTime={chartTime}
      titleOptions="Cantidad de usuarios"
      title="Usuarios registrados"
      description="Conoce la cantidad de usuarios registrados. Puedes usar los filtros para búsquedas específicas."
    />
  )
}

export default UsersChart

UsersChart.propTypes = {
  results: PropTypes.object.isRequired
}
