import xlsx from 'xlsx'

export const sortArray = (x, y) => x.label?.localeCompare(y?.label, undefined, { numeric: true })

export const readFile = (file, setArrayRows) => {
  if (file) {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onloadend = () => {
      setArrayRows(getArrayRows(reader.result))
    }
    reader.onerror = () => {
      alert('No se pudo leer el archivo Excel')
    }
  }
}

const getArrayRows = readerResult => {
  const spreadsheet = xlsx.read(readerResult, { type: 'binary' })
  const worksheet = spreadsheet.Sheets[spreadsheet.SheetNames[0]]
  return xlsx.utils.sheet_to_json(worksheet, { header: 1, raw: false })
}
