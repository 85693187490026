import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ReactSVG as Svg } from 'react-svg'
import IconButton from '@material-ui/core/IconButton'
import arrowCircleDown from '../../../images/icons/arrow_circle_down.svg'
import arrowCircleUp from '../../../images/icons/arrow_circle_up.svg'
import CategoriesEdit from './CategoriesEdit'
import useCategory from '../../../hooks/useCategory'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Td from '../../atoms/table/Td'
import Tr from '../../atoms/table/Tr'

const CategoryRow = ({ categories, index }) => {
  const [category, setCategory] = useState({})
  const [isFirst, setIsFirst] = useState(false)
  const [isLast, setIsLast] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)
  const { destroyCategory, updateCategory, invalidateCategories } = useCategory()

  const handleUpPosition = ({ id, position }) => {
    handlePositionChange(id, position - 1)
  }

  const handleDownPosition = ({ id, position }) => {
    handlePositionChange(id, position + 1)
  }

  const handleDestroy = ({ name, id }) => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la categoría "${name}"?`)) {
      destroyCategory(id, {
        onSuccess: () => invalidateCategories()
      })
    }
  }

  const handlePositionChange = (id, position) => {
    updateCategory(
      { id, position },
      {
        onSuccess: () => invalidateCategories()
      }
    )
  }

  const handleToggleForm = () => {
    setShowEditForm(!showEditForm)
  }

  useEffect(() => {
    setIsFirst(index === 0)
  }, [index])

  useEffect(() => {
    setCategory(categories[index])
    setIsLast(index === categories.length - 1)
  }, [index, categories])

  const showMoveActions = () => {
    return (
      <Td className="w-27.5 bg-neutral-100 px-2">
        <div className="w-max">
          <div className="w-12 inline-block">
            {!isLast && (
              <IconButton
                color="primary"
                aria-label="Bajar posición"
                onClick={() => {
                  handleDownPosition(category)
                }}
              >
                <Svg src={arrowCircleDown} className="flex items-center justify-center w-8 h-8 transform scale-135" />
              </IconButton>
            )}
          </div>
          <div className="w-12 inline-block">
            {!isFirst && (
              <IconButton
                aria-label="Subir posición"
                color="primary"
                onClick={() => {
                  handleUpPosition(category)
                }}
              >
                <Svg src={arrowCircleUp} className="flex items-center justify-center w-8 h-8 transform scale-135" />
              </IconButton>
            )}
          </div>
        </div>
      </Td>
    )
  }

  return (
    <Tr>
      {showEditForm ? (
        <Td colSpan={3} className="p-0">
          <CategoriesEdit onFormSubmit={handleToggleForm} onCancel={handleToggleForm} selectedCategory={category} />
        </Td>
      ) : (
        <>
          <Td>{category.name}</Td>
          <Td>{category.count}</Td>
          <Td className="w-27.5">
            <div className="w-max">
              <EditButton component="button" onClick={handleToggleForm} />
              <DeleteButton onClick={() => handleDestroy(category)} />
            </div>
          </Td>
        </>
      )}
      {categories.length > 1 && showMoveActions()}
    </Tr>
  )
}

export default CategoryRow

CategoryRow.propTypes = {
  categories: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired
}
