import PropTypes from 'prop-types'
import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'
import Error from '../../molecules/Error'
import { PaymentMethodFormsSelectionContainer } from './PaymentMethodFormsSelectionContainer'
import { banks } from '../../../configs/paymentMethodConfigs'
import * as validations from '../../../utils/validations'

export const PaymentMethods = ({ paymentTransferAccounts, enabledPaymentTransferAccountsAttributes }) => {
  return (
    <div className="relative js-field-container">
      {paymentTransferAccounts && (
        <PaymentMethodFormsSelectionContainer
          name="kind_payment"
          margin="none"
          label="Transferencia bancaria"
          checkboxValue="bank_transfer"
        >
          <p className="ml-8 mb-2">
            Los ciudadanos verán la información de las cuentas seleccionadas y se les pedirá subir una captura del
            comprobante de pago.
          </p>
          <div className="bg-gray-200 p-5 ml-8 mb-12">
            <p className="font-bold mb-4">Selecciona las cuentas disponibles para este formulario</p>
            {enabledPaymentTransferAccountsAttributes?.map((bank, index) => (
              <CheckboxField
                key={index}
                margin="none"
                name="bank_entity_ids"
                value={bank.id.toString()}
                label={banks[bank.bank] + ' (' + bank.cci_number + ')'}
                validate={validations.requiredArray()}
                hideError
              />
            ))}
            <Error name="bank_entity_ids" />
          </div>
        </PaymentMethodFormsSelectionContainer>
      )}
      <Error className="absolute -bottom-4" name="kind_payment" />
    </div>
  )
}

PaymentMethods.propTypes = {
  enabledPaymentTransferAccountsAttributes: PropTypes.arrayOf(PropTypes.object),
  paymentTransferAccounts: PropTypes.bool
}
