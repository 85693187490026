import React from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Exports from '../../../templates/Exports'
import queryString from 'query-string'

const fetchForm = formId => axios.get(`/admin/v1/forms/${formId}.json`).then(a => a.data)
const fetchExports = (search, exportableId) => {
  const parsedSearch = queryString.parse(search)

  const url = queryString.stringifyUrl({
    url: `/admin/v1/exports.json?exportable_id=${exportableId}&exportable_type=form`,
    query: parsedSearch
  })
  return axios.get(url).then(a => a.data)
}
const filterLabel = {
  search: 'Nombre o ID',
  status: 'Estados',
  from: 'Fecha desde',
  to: 'Fecha hasta'
}

const AnswerExport = () => {
  const { id: formId } = useParams()
  const { data: formData, status: formStatus } = useQuery(['formulario', formId], () => fetchForm(formId))
  const exportDescription = `Estos son los archivos exportados para las respuestas al formulario ${formData?.name}`

  if (formStatus !== 'success') return null
  return (
    <Exports
      fetchExports={fetchExports}
      generalData={formData}
      exportDescription={exportDescription}
      exportableId={formId}
      filterLabel={filterLabel}
    />
  )
}

export default AnswerExport
