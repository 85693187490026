import PropTypes from 'prop-types'
import React from 'react'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core'
import TimelineStateMessage from './TimelineStateMessage'

const TimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      padding: 0,
      flex: 0
    }
  }
})(MuiTimelineItem)

const TimelineStates = ({ statusHistory }) => {
  return (
    <Timeline className="states-timeline mt-4">
      {statusHistory.map((status, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot />
            {index < statusHistory.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <p className="font-bold leading-5">
              {status.label[0] + status.label.slice(1).toLowerCase()} - {status.date}
            </p>
            {status.user_name && <p className="leading-5 text-sm mb-6">por {status.user_name}</p>}
            <div>
              <TimelineStateMessage label="Notificación para el control interno" message={status.comment} />
              {status.citizen_comment && (
                <TimelineStateMessage label="Mensaje para el ciudadano" message={status.citizen_comment} />
              )}
            </div>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

export default TimelineStates

TimelineStates.propTypes = {
  statusHistory: PropTypes.array.isRequired
}
