import { useCallback, useState } from 'react'
import useFilters from './useFilters'
import { convertCamelToSnake } from '../utils/helpers'

const useStatData = (initialIndicator = 'forms') => {
  const [selectedIndicator, setSelectedIndicator] = useState(initialIndicator)
  const { onFilter, initialFilters, results } = useFilters(
    `/admin/v1/stats/${convertCamelToSnake(initialIndicator)}_section.json`
  )

  const fetchFilteredData = useCallback(
    filters => {
      onFilter(filters)
    },
    [onFilter]
  )

  const changeIndicator = indicator => {
    setSelectedIndicator(indicator)
  }

  return {
    selectedIndicator,
    changeIndicator,
    initialFilters,
    results,
    fetchFilteredData
  }
}

export default useStatData
