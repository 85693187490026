import PropTypes from 'prop-types'
import React from 'react'
import SubtitleCitizenField from '../question_fields/SubtitleCitizenField'

const Citizen = ({ question }) => {
  return <SubtitleCitizenField payload={question.payload} position={question.position} />
}

const SubtitleQuestion = {
  Citizen,
  subtype: 'Subtítulo',
  name: 'SubtitleQuestion'
}

export default SubtitleQuestion

Citizen.propTypes = {
  question: PropTypes.exact({
    payload: PropTypes.object,
    position: PropTypes.number
  }).isRequired
}
