import PropTypes from 'prop-types'
import React from 'react'
import { ExampleModal } from '../../molecules/dialogs/ExampleModal'
import userExamples from '../../../images/enabled_procedure_managements/area_examples.png'
import userExamplesMobile from '../../../images/enabled_procedure_managements/area_examples_mobile.png'

const AreaExamplesModal = ({ onClose }) => {
  return (
    <ExampleModal
      onClose={onClose}
      show
      desktopImage={userExamples}
      mobileImage={userExamplesMobile}
      title="Áreas internas involucradas"
    >
      Son las áreas dentro de tu institución que están involucradas en la gestión del trámite. Agrégalas si ya las has
      creado previamente o creálas si es que aún no lo has hecho. Por ejemplo, en un trámite de Denuncias, las áreas
      involucradas suelen ser:
    </ExampleModal>
  )
}
export default AreaExamplesModal

AreaExamplesModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
