import PropTypes from 'prop-types'
import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import AutoSave from './AutoSave'

const AutoSaveForm = ({ children, onSubmit, initialValues, formRef, className }) => {
  return (
    <FinalForm onSubmit={onSubmit} initialValues={initialValues} form={formRef?.current}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={className}>
          <AutoSave debounce={1000} save={onSubmit} />
          {children}
        </form>
      )}
    </FinalForm>
  )
}
export default AutoSaveForm

AutoSaveForm.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}
