import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { TruncatedTextWithTooltip } from '../../molecules/TruncatedTextWithTooltip'
import UserInfoTable from '../forms/UserInfoTable'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'

const ArticleTable = ({ articles = [], onDestroy, title, category = false }) => {
  return (
    <div className="pt-8 pb-2" data-testid={title}>
      <Table
        className="table-fixed md:w-full"
        values={articles}
        head={
          <Tr body={false}>
            <Th>Nombre</Th>
            {category && <Th>Categoría</Th>}
            <Th>Creación</Th>
            <Th>Actualización</Th>
            <Th>Estado</Th>
            <Th>Acciones</Th>
          </Tr>
        }
        body={
          <>
            {articles.map(article => (
              <Tr key={article.id}>
                <Td className="min-w-45">
                  <TruncatedTextWithTooltip text={article.name}>
                    {props => (
                      <Link to={`${article.item_url}/vista-previa`} {...props}>
                        {article.name}
                      </Link>
                    )}
                  </TruncatedTextWithTooltip>
                </Td>
                {category && <Td className="min-w-45">{article.category_name}</Td>}
                <Td className="min-w-45">
                  <UserInfoTable id={article.created_by} name={article.created_name} date={article.date_created_at} />
                </Td>
                <Td className="min-w-45">
                  <UserInfoTable id={article.updated_by} name={article.updated_name} date={article.date_updated_at} />
                </Td>
                <Td>{article.published ? 'Publicado' : 'No publicado'}</Td>
                <Td className="min-w-30 td-0">
                  <EditButton to={`${article.item_url}/editar`} />
                  <DeleteButton onClick={() => onDestroy(article)} />
                </Td>
              </Tr>
            ))}
          </>
        }
      />
    </div>
  )
}

export default ArticleTable

ArticleTable.propTypes = {
  articles: PropTypes.array,
  category: PropTypes.bool,
  onDestroy: PropTypes.func,
  title: PropTypes.string
}
