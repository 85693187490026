import PropTypes from 'prop-types'
import React from 'react'
import PinnedButton from '../../molecules/buttons/PinnedButton'
import useForm from '../../../hooks/useForm'
import usePin from '../../../hooks/services/usePin'

const FormPinnedButton = ({ form, disabled }) => {
  const { togglePinForm } = usePin(form.id)
  const { invalidateForms } = useForm(form.id)

  const handleUpdateForm = () => {
    togglePinForm(
      {},
      {
        onSuccess: () => {
          invalidateForms()
        },
        onError: () => {
          alert('Ocurrió un error al intentar fijar.')
        }
      }
    )
  }

  return <PinnedButton type="formulario" onClick={handleUpdateForm} pinned={form.pinned} disabled={disabled} />
}

export default FormPinnedButton

FormPinnedButton.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}
