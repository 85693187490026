import React, { useState } from 'react'
import { dataToOptions } from '../../../utils/helpers'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import { Form } from 'react-final-form'
import SwitchField from '../../molecules/fields/SwitchField'
import Title from '../../atoms/Title'
import Value from '../../molecules/Value'
import ConfigPaymentMethods from './ConfigPaymentMethods'
import SummaryPayments from './SummaryPayments'
import useInstitution, { isPCM, pcmId } from '../../../hooks/useInstitution'
import useInvalidateQueries from '../../../hooks/useInvalidateQueries'

const EnablePaymentToInstitutions = () => {
  const { getInstitutions, getInstitution, updateInstitution } = useInstitution()
  const [institutionID, setInstitutionID] = useState(pcmId)
  const invalidateQueries = useInvalidateQueries()
  const { data: institution, status } = getInstitution(institutionID)
  const { data: institutions, status: institutionsStatus } = getInstitutions()

  const handleChangeInstitution = value => setInstitutionID(value?.value)

  const baseName = 'payment_methods'
  const handleEnablePaymentSubmit = values => {
    updateInstitution(values, {
      onSuccess: () => invalidateQueries(['institution', values.id]),
      onError: () => alert('Ocurrió un error al intentar guardar.')
    })
  }
  if (status !== 'success' && institutionsStatus !== 'success') return null

  return (
    <div className="mx-8 md:mx-0">
      <div className="md:w-2/3 pt-16 pb-3">
        <Title aria-describedby="page-description" className="mb-4">
          Medios de pago
        </Title>
        <p id="page-description" className="mb-1">
          Selecciona la entidad cuyos medios de pago quieres ver.
        </p>
      </div>
      <Form onSubmit={handleChangeInstitution}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AutocompleteField
              options={dataToOptions(institutions) || []}
              size="3xl"
              margin="high"
              name="institution"
              onChange={handleChangeInstitution}
              defaultOption={{ value: institutionID }}
            />
          </form>
        )}
      </Form>
      {isPCM(institution) ? (
        <ConfigPaymentMethods />
      ) : (
        <Form onSubmit={handleEnablePaymentSubmit} initialValues={institution}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <fieldset className="bg-neutral-100 px-6 md:px-8 py-4 md:py-8 mb-4 relative js-field-container">
                <div className="flex items-center justify-between font-bold md:text-xl">
                  <p className="font-bold">Funcionalidad de pagos</p>
                  <SwitchField name={`${baseName}.enabled`} onChange={handleSubmit} />
                </div>
                <Value name={`${baseName}.enabled`}>
                  {({ value: paymentEnabled }) =>
                    paymentEnabled && (
                      <SummaryPayments institutionID={institutionID} paymentMethods={institution?.payment_methods} />
                    )
                  }
                </Value>
              </fieldset>
            </form>
          )}
        </Form>
      )}
    </div>
  )
}

export default EnablePaymentToInstitutions
