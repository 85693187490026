import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import PageHeading from '../../../molecules/PageHeading'
import TemplatesTable from '../../../organisms/templates/TemplatesTable'
import useFilters from '../../../../hooks/useFilters'
import axios from 'axios'
import { useQueryClient } from 'react-query'
import PageBody from '../../../molecules/PageBody'

const Templates = () => {
  const { initialFilters, results: templates, onFilter } = useFilters(`/admin/v1/templates.json`, { sort: 'desc' })
  const queryClient = useQueryClient()
  const handleDestroy = async template => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la plantilla ${template.name}?`)) {
      try {
        await axios.delete(`/admin/v1/templates/${template.id}.json`)
        queryClient.invalidateQueries([`/admin/v1/templates.json`])
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }

  return (
    <>
      <PageHeading
        title="Plantillas"
        pageDescription="Estas son las plantillas creadas para las instituciones públicas usuarias de Facilita."
        button={{ link: '/plantillas/crear', name: 'Crear plantilla', icon: <AddIcon /> }}
      />
      <PageBody results={templates} onFilter={onFilter} initialFilters={initialFilters}>
        <TemplatesTable templates={templates.data} onDestroy={handleDestroy} />
      </PageBody>
    </>
  )
}
export default Templates
