import React from 'react'
import Container from '../../../atoms/Container'
import Title from '../../../atoms/Title'
import DescriptionCarousel from '../../../molecules/DescriptionCarousel'
import img1 from '../../../../images/procedure_managements/how_to_create/img_1.png'
import img2 from '../../../../images/procedure_managements/how_to_create/img_2.png'
import img3 from '../../../../images/procedure_managements/how_to_create/img_3.png'
import img4 from '../../../../images/procedure_managements/how_to_create/img_4.png'
import imgMobile1 from '../../../../images/procedure_managements/how_to_create/img_mobile_1.png'
import imgMobile2 from '../../../../images/procedure_managements/how_to_create/img_mobile_2.png'
import imgMobile3 from '../../../../images/procedure_managements/how_to_create/img_mobile_3.png'
import imgMobile4 from '../../../../images/procedure_managements/how_to_create/img_mobile_4.png'

const step1ImageAltText = 'Imagen de paso 1: Ejemplo de preguntas para un formulario de Acceso a información pública'
const step2ImageAltText =
  'Imagen de paso 2: Tipos de usuario Ciudadano, Funcionario Responsable de Acceso a Información (FRAI) y Funcionario de Área Poseedora'
const step3ImageAltText =
  'Imagen de paso 3: Ejemplo de tareas del funcionario responsable de Acceso a información (FRAI): derivar a área interna, derivar a entidad externa, pedir subsanación a ciudadano'
const step4ImageAltText = 'Imagen de paso 4: Ejemplo de definición de flujo al derivar la solicitud a un área poseedora'

const steps = [
  {
    hint: (
      <>
        <div className="font-bold text-lg">
          Paso 1: Crea el formulario que deberá llenar el ciudadano para dar inicio a su trámite.
        </div>
        <div className="font-bold">¿Qué necesito previamente?</div>
        Tener clara la información que deberá enviar el ciudadano.
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img1} alt={step1ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile1} alt={step1ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <div className="font-bold text-lg">
          Paso 2: Crea los tipos de usuario que estarán involucrados en la gestión del servicio o trámite.
        </div>
        <div className="font-bold">¿Qué necesito previamente?</div>
        Tener claro qué tipo de funcionario participan en la gestión.
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img2} alt={step2ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile2} alt={step2ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <div className="font-bold text-lg">
          Paso 3: Crea las tareas que cada usuario deberá realizar para llevar a cabo la gestión del servicio.
        </div>
        <div className="font-bold">¿Qué necesito previamente?</div>
        Tener claro qué tareas realiza cada tipo de usuario y qué información debe enviarse en cada tarea.
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img3} alt={step3ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile3} alt={step3ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <span className="font-bold text-lg">
          Paso 4: Define el flujo de atención del trámite, es decir cómo se van conectando las tareas, cómo van
          cambiando los estados del trámite y qué reglas adicionales deben tenerse en cuenta.
        </span>
        <div className="font-bold">¿Qué necesito previamente?</div>
        Tener claro el flujo del proceso de atención del trámite.
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img4} alt={step4ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile4} alt={step4ImageAltText} />
      </>
    )
  }
]

const ProcedureManagementsHowToCreate = () => (
  <>
    <Container className="mt-12 px-4 md:px-0">
      <Title aria-describedby="page-description">¿Cómo se crea una gestión de un trámite?</Title>
      <p id="page-description" className="mb-6">
        Se crea en <span className="font-bold">4 simples pasos:</span>
      </p>
    </Container>
    <DescriptionCarousel steps={steps} />
  </>
)
export default ProcedureManagementsHowToCreate
