import PropTypes from 'prop-types'
import React from 'react'
import FileCitizenField from '../question_fields/FileCitizenField'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'

const Tracking = props => {
  const { index, answer, question } = props
  return (
    <div className="mb-6">
      <BoldLabelAndContent label={`${index}. ${question.payload.label}`}>
        <div className="block">{answer}</div>
      </BoldLabelAndContent>
    </div>
  )
}

const Citizen = props => {
  const { index, name, question, margin } = props
  return <FileCitizenField index={index} {...question} name={name} margin={margin} />
}

const FileQuestion = {
  Citizen,
  Tracking,
  name: 'FileQuestion'
}

export default FileQuestion

Citizen.propTypes = {
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}

Tracking.propTypes = {
  answer: PropTypes.string,
  index: PropTypes.number,
  question: PropTypes.object
}
