import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import CitizenFormContainer from '../../organisms/citizens/CitizenFormContainer'
import NotFound from '../../organisms/citizens/NotFound'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import CitizenNotFound from './CitizenNotFound'

const fetchForm = (id, isPreview, locale = 'es') =>
  axios.get(`/v1/forms/${id}.json?locale=${locale}`, { params: { is_preview: isPreview } }).then(res => res.data)

const CitizenFormPage = ({ isPreviewModeSelected = false }) => {
  const { id } = useParams()
  const {
    data: form,
    isSuccess,
    isError,
    error
  } = useQuery([id, 'tramite', i18n.language], () => fetchForm(id, isPreviewModeSelected, i18n.language), {
    refetchOnWindowFocus: false,
    retry: false
  })

  if (isSuccess && form) return <CitizenFormContainer form={form} isPreview={isPreviewModeSelected} />

  if (isError && error?.response?.data) return <NotFound data={error?.response.data} />

  if (isError && error?.response?.status === 404) return <CitizenNotFound />

  return null
}
CitizenFormPage.propTypes = {
  isPreviewModeSelected: PropTypes.bool
}

export default CitizenFormPage
