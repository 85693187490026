import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'

const TextWithRowsColsField = ({ baseName, question, questionTranslated }) => {
  return (
    <div className="flex mb-0">
      <div className="w-1/2">
        <h3 className="font-bold mb-4">Filas</h3>
        <div className="overflow-y-auto max-h-80">
          {question.payload.rows.map((row, index) => (
            <div key={row.id} className="flex items-baseline flex-wrap mr-8">
              <TextField
                name={`${baseName}.payload.rows[${index}].label`}
                placeholder="Ingresa la traducción."
                initialValue={questionTranslated?.payload.rows[index].label || ''}
                hint={row.label}
                rootClassName="w-full"
                size="full"
                validate={validations.required()}
              />
              <Field
                component="input"
                className="hidden"
                name={`${baseName}.payload.rows[${index}].id`}
                initialValue={row.id}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-1/2">
        <h3 className="font-bold mb-4">Columnas</h3>
        <div className="overflow-y-auto max-h-80">
          {question.payload.cols.map((col, index) => (
            <div key={col.id} className="flex items-baseline flex-wrap mr-8">
              <TextField
                name={`${baseName}.payload.cols[${index}].label`}
                placeholder="Ingresa la traducción."
                initialValue={questionTranslated?.payload.cols[index].label || ''}
                hint={col.label}
                rootClassName="w-full"
                size="full"
                validate={validations.required()}
              />
              <Field
                component="input"
                className="hidden"
                name={`${baseName}.payload.cols[${index}].id`}
                initialValue={col.id}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TextWithRowsColsField

TextWithRowsColsField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
