import PropTypes from 'prop-types'
import React from 'react'
import Container from '../../atoms/Container'
import ResultInfo from '../quick_searches/ResultInfo'
import { getLabelDocumentType, getWelcomeMessage } from '../../../configs/quickSearchesConfig'

const CitizenQuickSearchResult = ({ quickResult, onBack }) => {
  const isQuickSearchResult = true
  const labelTypeDocument = getLabelDocumentType(
    quickResult.document_type,
    quickResult.other_document_label,
    isQuickSearchResult
  )

  return (
    <>
      <Container className="py-8 md:py-16 px-4 md:px-0">
        <main className="m-auto">
          <div className="flex-1 md:flex h-full">
            <ResultInfo
              hint={quickResult.hint}
              title={quickResult.title}
              labelTypeDocument={labelTypeDocument}
              welcomeMessage={getWelcomeMessage(quickResult.citizen_full_name)}
              documentNumber={quickResult.document_number}
              contactInfo={quickResult.contact_info}
              onBack={onBack}
            />
          </div>
        </main>
      </Container>
    </>
  )
}
export default CitizenQuickSearchResult

CitizenQuickSearchResult.propTypes = {
  onBack: PropTypes.func.isRequired,
  quickResult: PropTypes.object
}
