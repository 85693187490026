import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'

const SpecialTaskFormExtensionNotice = () => {
  return (
    <div className="bg-neutral-200 p-6 mb-6">
      <TextField
        name="special_task_data.added_days"
        size="sm"
        label="Indica la cantidad de días de atención que deseas aumentar"
        validate={validations.mix(validations.required(), validations.isNumber())}
        rigthTextDecorator="días"
        margin="none"
      />
    </div>
  )
}
export default SpecialTaskFormExtensionNotice
