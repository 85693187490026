import { useMemo } from 'react'

const useInitialFormTranslationValues = (formDataInSpanish, formData = null) => {
  const initialValues = useMemo(() => {
    if (!formDataInSpanish) {
      return {}
    }

    const mapAlternatives = alternatives =>
      alternatives.map(alternative => ({
        ...alternative,
        label: ''
      }))

    const mapChildrenAttributes = children => {
      if (!children || typeof children !== 'object') {
        return {}
      }

      return Object.entries(children).reduce((acc, [key, subQuestions]) => {
        acc[key] = subQuestions.map(subQuestion => ({
          ...subQuestion,
          payload: {
            ...subQuestion.payload,
            label: '',
            hint: ''
          }
        }))
        return acc
      }, {})
    }

    if (!formData || Object.keys(formData).length === 0) {
      return {
        ...formDataInSpanish,
        name: '',
        hint: '',
        auto_response_text: '',
        steps_attributes: formDataInSpanish.steps_attributes.map(step => ({
          ...step,
          name: '',
          questions_attributes: step.questions_attributes.map(question => ({
            ...question,
            payload: {
              alternatives: question.payload.alternatives ? mapAlternatives(question.payload.alternatives) : []
            },
            children_attributes: mapChildrenAttributes(question.children_attributes)
          }))
        }))
      }
    }

    return formData
  }, [formDataInSpanish, formData])

  return initialValues
}

export default useInitialFormTranslationValues
