import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import useEnabledProcedureManagement from '../../../../hooks/useEnabledProcedureManagement'
import { isSuccessful } from '../../../../hooks/useQuery'
import QuestionsAndSubQuestions from '../../../organisms/question_fields/QuestionsAndSubQuestions'
import FormStepHeader from '../../../organisms/citizens/FormStepHeader'
import Wizard from '../../../organisms/procedure_managements/Wizard'
import ManualEntryPayment from '../../../organisms/procedures/ManualEntryPayment'
import CheckboxField from '../../../molecules/fields/CheckboxField'
import * as validations from '../../../../utils/validations'
import DateField from '../../../molecules/fields/DateField'
import { serialize } from 'object-to-formdata'
import { required } from '../../../../utils/validations'
import useProcedure from '../../../../hooks/useProcedure'
import { flatAnswers } from '../../../../utils/helpers'
import { swornDeclarationUrl } from '../../../../configs/assetsConfig'

const ProceduresManualEntry = () => {
  const { id } = useParams()
  const { push } = useHistory()
  const { findById } = useEnabledProcedureManagement(id)
  const { create } = useProcedure({ enabledProcedureManagementId: id })
  const { data: enabled, status } = findById(['enabled_procedure_management', id], { entryType: 'manual' })
  if (!isSuccessful(status)) return null

  const form = enabled?.form

  const {
    privacy_policy: privacyPolicy,
    sworn_declaration: swornDeclaration,
    it_costs: itCosts,
    cost,
    max_steps: maxSteps,
    procedure_management_id: procedureManagementId
  } = form

  const handleSubmit = async ({ values, config }) => {
    const flattedAnswers = { ...values, answers: flatAnswers(values.answers) }
    const formData = serialize({ ...flattedAnswers, sisoli: 'sisoli' }, { indices: true })
    await create(
      formData,
      { onSuccess: () => push('solicitudes?action=new_manual_entry') },
      { onUploadProgress: progressEvent => config.onUploadProgress(progressEvent) }
    )
  }

  const getStepPages = () => {
    const formSteps = form.steps_attributes
    const stepPages = []
    for (let i = 0; i < maxSteps; i++) {
      const isLastStep = i === maxSteps - 1
      const isLastQuestionsStep = i === formSteps.length - 1
      const isFirstStep = i === 0
      const formStepName = itCosts && isLastStep ? 'Comprobante de pago' : formSteps[i]?.name
      stepPages.push(
        <>
          <div className="px-6 md:px-0">
            <div className="flex flex-col gap-4 mb-8">
              <p className="text-2xl">Ingresar solicitud manual</p>
              <p className="text-4xl font-bold">{enabled.name}</p>
              <p>
                Si el ciudadano envió su solicitud por otro medio y quieres gestionarla desde Facilita, puedes
                ingresarla manualmente.
              </p>
            </div>
            {isFirstStep && (
              <div className="flex flex-col items-start p-4 md:p-6 bg-neutral-200 mb-8">
                <DateField
                  name="creation_datetime"
                  label="Fecha original de ingreso de la solicitud"
                  placeholder="Ingresar fecha"
                  margin="none"
                  validate={required()}
                  containerClassName="w-full"
                />
              </div>
            )}
          </div>
          <div className="mb-6">
            <FormStepHeader state={{ context: { activeStep: i } }} form={form} name={formStepName} />
            <div className="bg-neutral-100 p-8">
              {itCosts && isLastStep ? (
                <ManualEntryPayment cost={cost} />
              ) : (
                <>
                  <QuestionsAndSubQuestions
                    questions={formSteps[i]?.questions_attributes}
                    stepsAttributes={formSteps}
                    activeStep={i}
                    procedureManagementId={procedureManagementId}
                  />
                  {privacyPolicy && isLastQuestionsStep && (
                    <CheckboxField
                      name="accepts_privacy_policy"
                      validate={validations.required()}
                      margin="dense"
                      className="-mt-4"
                      label={
                        <p>
                          Acepto{' '}
                          <a href={privacyPolicy.url} target="_blank" rel="noreferrer">
                            la política de privacidad
                          </a>
                        </p>
                      }
                    />
                  )}
                  {swornDeclaration && isLastQuestionsStep && (
                    <CheckboxField
                      className="mb-4"
                      name="accepts_sworn_declaration"
                      validate={validations.required()}
                      label={
                        <p>
                          Acepto{' '}
                          <a href={swornDeclarationUrl} target="_blank" rel="noreferrer">
                            la declaración jurada de veracidad de la información
                          </a>
                        </p>
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )
    }
    return stepPages
  }

  return (
    <>
      <PageNavigation breadcrumbData={enabled} />
      <Container className="py-12">
        <Wizard
          mutateForm={handleSubmit}
          stepPages={getStepPages()}
          textButtons={{ lastStep: 'Enviar', previousStep: 'Continuar' }}
          makeSerialize={false}
        />
      </Container>
    </>
  )
}
export default ProceduresManualEntry
