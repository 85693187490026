import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import { Popover } from '@material-ui/core'
import Search from '@material-ui/icons/Search'
import LinesEllipsis from 'react-lines-ellipsis'
import { useField } from 'react-final-form'
import Hint from '../../atoms/Hint'
import Error from '../Error'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import { marginSizes } from '../../../configs/sizesConfig'
import Tooltip from '../../atoms/Tooltip'
import LazyLoad from 'react-lazy-load'

const sizeMap = {
  full: 'w-full',
  '5xl': 'sm:max-w-xl',
  '4xl': 'sm:max-w-lg',
  '3xl': 'sm:max-w-md',
  '2xl': 'sm:max-w-sm',
  xl: 'sm:max-w-xs',
  lg: 'sm:max-w-64'
}

const PopperComponent = props => <div {...props} />

const AutocompleteField = ({
  name,
  label,
  hint,
  options,
  defaultOption,
  renderOption,
  size = 'xl',
  margin = 'normal',
  onChange,
  validate,
  className,
  boxClassName,
  disabled,
  emptyLabel = 'Selecciona una opción',
  labelClassName,
  ...props
}) => {
  const { input } = useField(name, { validate, ...props })
  const [anchorEl, setAnchorEl] = useState(null)
  const [value, setValue] = useState()
  useEffect(() => {
    if (defaultOption && !value) {
      onChange && onChange(defaultOption)
      setValue(options?.find(({ value }) => value === defaultOption.value.toString()))
    }
  }, [anchorEl, defaultOption])

  useEffect(() => {
    if (input) {
      setValue(options?.find(({ value }) => value === input.value))
    }
  }, [input.value])

  const handleChange = (event, newValue, reason) => {
    if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') return

    input.onChange(newValue.value)
    onChange && onChange(newValue)
    onClose()
  }

  const onClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const handleOpenSelect = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleKeyDown = event => {
    if (event.key?.toLowerCase() === 'escape') {
      onClose()
    }
  }

  const refDivSelect = useRef()
  const widthPopper = refDivSelect.current?.clientWidth + 4

  const open = !!anchorEl
  const id = open ? 'autocomplete-label' : undefined
  return (
    <div className={clsx('relative', marginSizes[margin], className)}>
      {label && (
        <label
          htmlFor={`${name}-input`}
          id={`${name}-label`}
          className={clsx('block font-bold mb-1 min-w-25', labelClassName)}
        >
          {label}
        </label>
      )}
      {hint && <Hint hint={hint} name={name} />}
      <button
        type="button"
        disabled={disabled}
        ref={refDivSelect}
        className={clsx(
          'flex justify-between border-2 border-gray-800 bg-white w-full pl-4 focus:outline-main disabled:bg-neutral-100 disabled:text-gray-600 min-h-12 rounded',
          boxClassName,
          sizeMap[size]
        )}
        onClick={handleOpenSelect}
        data-testid="autocomplete-btn"
        aria-describedby={`${name}-label`}
      >
        <LinesEllipsis text={value ? value.label : emptyLabel} maxLine="4" className="py-2.5" />
        <div className="mr-px my-auto">{open ? <ArrowDropUp /> : <ArrowDropDown />}</div>
      </button>
      <Error name={name} className="absolute -bottom-6" />
      <Popover
        id={id}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        classes={{
          paper: 'border-solid border-l-2 border-r-2 border-b-2 border-t-1 border-gray-800 bg-white w-full'
        }}
        PaperProps={{ style: { width: widthPopper } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
      >
        <Autocomplete
          open
          disabled={disabled}
          id={`${name}-input`}
          noOptionsText="No se encontraron resultados"
          classes={{ paper: 'm-0', popper: 'max-w-full', listbox: 'max-h-72', option: 'min-h-10 hover:bg-neutral-200' }}
          getOptionLabel={option => option.label || ''}
          options={options}
          PopperComponent={PopperComponent}
          onChange={handleChange}
          renderOption={
            renderOption ||
            (option => (
              <LazyLoad className="w-full">
                <Tooltip title={option.label} placement="top">
                  <LinesEllipsis text={option.label} maxLine="3" />
                </Tooltip>
              </LazyLoad>
            ))
          }
          renderInput={params => (
            <TextField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              InputProps={{
                disableUnderline: true,
                className: 'pl-2 rounded-md bg-blue-300',
                startAdornment: <Search classes={{ root: 'text-neutral-500' }} />
              }}
              className={clsx('p-3 w-full', sizeMap[size])}
              onKeyDown={handleKeyDown}
            />
          )}
        />
      </Popover>
    </div>
  )
}
export default AutocompleteField

AutocompleteField.propTypes = {
  boxClassName: PropTypes.string,
  className: PropTypes.string,
  defaultOption: PropTypes.object,
  disabled: PropTypes.bool,
  emptyLabel: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  renderOption: PropTypes.func,
  size: PropTypes.string,
  labelClassName: PropTypes.string,
  validate: PropTypes.func
}
