import React from 'react'
import PropTypes from 'prop-types'
import CustomChart from './CustomChart'
import useStatisticChart from '../../../../hooks/useStatisticChart'

const AnswersChart = ({ results }) => {
  const headerNames = {
    all: 'Todos',
    simple: 'Form. simples',
    template: 'Form. de plantillas',
    quick_search: 'Form. de consultas rápidas',
    procedure: 'Form. de gestión de trámites'
  }
  const { chartData, chartTime } = useStatisticChart(results, ['Años', 'Respuestas recibidas'], headerNames)

  return (
    <CustomChart
      chartData={chartData}
      titleTime={chartTime}
      titleOptions="Cantidad de respuestas recibidas"
      title="Cantidad de respuestas recibidas"
      description="Conoce la cantidad de respuestas recibidas. Puedes usar los filtros para búsquedas específicas."
    />
  )
}

export default AnswersChart

AnswersChart.propTypes = {
  results: PropTypes.object.isRequired
}
