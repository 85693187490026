import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import arrayMutators from 'final-form-arrays'
import { clearMutator } from '../../../utils/formMutators'
import { Button } from '../../atoms/Button'
import QuestionsAndSubQuestions from '../question_fields/QuestionsAndSubQuestions'
import SpecialTaskForm from './SpecialTaskForm'
import SelectArea from './SelectArea'
import { QuestionsAndSubQuestionsContext } from '../../../utils/contexts'

const TaskExecutionsForm = ({ form, onSubmit: submit, kind, destinyUserTypeId, citizenEmail }) => {
  const ref = React.createRef()
  const activeStep = 0
  const step = form.steps_attributes[activeStep]
  const questions = step?.questions_attributes || []
  const [externalInstitutionDisabled, setExternalInstitutionDisabled] = useState(false)

  const handleDestinyInstitutionChange = (kind, params) => {
    if (kind === 'external_institution_task' && params === 'disable_questions') {
      setExternalInstitutionDisabled(true)
    } else if (kind === 'external_institution_task' && params === 'enable_questions') {
      setExternalInstitutionDisabled(false)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight - ref.current.scrollHeight - 280, behavior: 'smooth' })
  }, [])
  return (
    <div ref={ref} className="bg-neutral-100 p-4 md:p-10">
      <div className="font-bold text-2xl">{form.name}</div>
      <div className="hint-html-injected mb-8" dangerouslySetInnerHTML={{ __html: form.hint }} />
      <Form onSubmit={submit} decorators={[focusOnFirstError]} mutators={{ ...arrayMutators, clear: clearMutator }}>
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {destinyUserTypeId && <SelectArea userTypedId={destinyUserTypeId} />}
              <SpecialTaskForm kind={kind} {...form} />
              <QuestionsAndSubQuestionsContext.Provider
                value={{
                  onDestinyInstitutionChange: handleDestinyInstitutionChange,
                  procedureManagementId: form.procedure_management_id,
                  citizenEmail
                }}
              >
                <QuestionsAndSubQuestions
                  questions={questions}
                  stepsAttributes={form.steps_attributes}
                  activeStep={activeStep}
                />
              </QuestionsAndSubQuestionsContext.Provider>
              <div className="flex flex-col-reverse md:flex-row justify-end">
                <Button className="md:max-w-56 mb-3 md:mb-0" size="full" type="submit" disabled={submitting}>
                  {externalInstitutionDisabled ? 'Archivar solicitud' : 'Enviar'}
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </div>
  )
}
export default TaskExecutionsForm

TaskExecutionsForm.propTypes = {
  citizenEmail: PropTypes.string,
  destinyUserTypeId: PropTypes.number,
  form: PropTypes.object,
  kind: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
}
