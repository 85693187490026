import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import Alert from '../../molecules/Alert'
import QuestionOptionsSection from './QuestionOptionsSection'

const AddressField = ({ baseName }) => {
  return (
    <div className="grid md:grid-cols-2 flex-wrap gap-4">
      <div>
        <TextField
          label="Etiqueta de la pregunta"
          margin="none"
          size="full"
          name={`${baseName}.payload.label`}
          initialValue=""
        />
        <QuestionOptionsSection baseName={baseName} size="full" />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.ubigeo_region_label`}
          initialValue="Departamento"
          margin="dense"
          disabled
        />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.ubigeo_city_label`}
          initialValue="Provincia"
          margin="dense"
          disabled
        />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.ubigeo_district_label`}
          initialValue="Distrito"
          margin="dense"
          disabled
        />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.address`}
          initialValue="Dirección"
          margin="none"
          disabled
        />
      </div>
      <Alert type="info">Se mostrarán como alternativas todas las regiones, provincias y distritos del Perú.</Alert>
    </div>
  )
}

export default AddressField

AddressField.propTypes = {
  baseName: PropTypes.string
}
