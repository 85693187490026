import React from 'react'
import UbigeoFieldFilter from '../../../molecules/fields/UbigeoFieldFilter'
import InstitutionTypeFieldFilter from '../../../molecules/fields/InstitutionTypeFieldFilter'

const InstitutionsFilters = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div>
        <InstitutionTypeFieldFilter name="institution" />
      </div>
      <div>
        <UbigeoFieldFilter name="institution" />
      </div>
    </div>
  )
}

export default InstitutionsFilters
