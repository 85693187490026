import React from 'react'
import StatCard from './StatCard'
import UserIcon from '../../../images/icons/user.svg'
import InstitutionIcon from '../../../images/icons/institution.svg'
import FormIcon from '../../../images/icons/form.svg'
import AnswerIcon from '../../../images/icons/answer.svg'
import FormCreatedIcon from '../../../images/icons/form-created.svg'
import PropTypes from 'prop-types'

const StatCards = ({ results, onShowMore }) => {
  if (!results) return null

  return (
    <div className="flex overflow-auto space-x-6 px-6 py-8 bg-neutral-100 row scrollable">
      {typeof results.total_users !== 'undefined' && (
        <StatCard
          icon={UserIcon}
          title="Usuarios registrados"
          value={results.total_users}
          indicator="users"
          onShowMore={onShowMore}
        />
      )}
      {typeof results.total_institutions !== 'undefined' && (
        <StatCard
          icon={InstitutionIcon}
          title="Entidades registradas"
          value={results.total_institutions}
          indicator="institutions"
          onShowMore={onShowMore}
        />
      )}
      {typeof results.total_forms !== 'undefined' && (
        <StatCard
          icon={FormIcon}
          title="Formularios creados"
          value={results.total_forms}
          indicator="forms"
          onShowMore={onShowMore}
        />
      )}
      {typeof results.total_answers !== 'undefined' && (
        <StatCard
          icon={AnswerIcon}
          title="Respuestas recibidas"
          value={results.total_answers}
          indicator="answers"
          onShowMore={onShowMore}
        />
      )}
      {typeof results.total_attended !== 'undefined' && (
        <StatCard
          icon={FormCreatedIcon}
          title="Solicitudes atendidas"
          value={results.total_attended}
          indicator="receivedRequests"
          onShowMore={onShowMore}
        />
      )}
    </div>
  )
}

StatCards.propTypes = {
  results: PropTypes.shape({
    total_users: PropTypes.number,
    total_institutions: PropTypes.number,
    total_forms: PropTypes.number,
    total_answers: PropTypes.number,
    total_attended: PropTypes.number
  }),
  onShowMore: PropTypes.func.isRequired
}
export default StatCards
