import React from 'react'

const formSteps = {
  SELECTION: {
    label: 'Selección de trámite',
    title: 'Selecciona el trámite que deseas habilitar'
  },
  FORM: {
    label: 'Edición de formulario',
    title: 'Edita el formulario que da inicio al trámite',
    hint: (
      <ul className="list-disc list-inside mt-4 ml-2">
        <li className="mb-1">Revisa la configuración y las preguntas del formulario.</li>
        <li>Puedes editar la información de los campos en fondo blanco según lo requiera tu institución.</li>
      </ul>
    )
  },
  AREA_AND_USERS: {
    label: 'Áreas y cuentas de usuario',
    title: 'Configura las áreas y cuentas involucradas en la gestión del trámite'
  },
  PREVIEW: {
    label: 'Vista previa y publicación',
    hint: 'Revisa la gestión del trámite, publícala y comparte el enlace con los ciudadanos.'
  }
}

const pageDescription = ({
  canFilterByInstitution,
  canFilterByBranchOffice,
  canFilterAndHasNotBranchOffices,
  canSeeBranchRecords
}) => {
  if (canFilterByInstitution)
    return 'Estas son las gestiones de trámites creadas para las instituciones públicas usuarias de Facilita.'
  if (canFilterByBranchOffice) return 'Selecciona la sede cuyas gestiones de trámites habilitadas quieres ver.'
  if (canFilterAndHasNotBranchOffices) return 'Estas son las gestiones de trámites habilitadas en tu institución.'
  if (canSeeBranchRecords) return 'Estas son las gestiones de trámites habilitadas en tu sede.'
}

const orderOptions = canSeeAllEnabledProcedureManagements => {
  if (canSeeAllEnabledProcedureManagements) {
    return [
      { value: 'create_desc', label: 'Habilitación: más recientes primero' },
      { value: 'create_asc', label: 'Habilitación: más antigua primero' },
      { value: 'count_desc', label: 'Solicitudes: mayor cantidad primero' },
      { value: 'count_asc', label: 'Solicitudes: menor cantidad primero' }
    ]
  }

  return [
    { value: 'create_desc', label: 'Creación: más recientes primero' },
    { value: 'create_asc', label: 'Creación: más antiguos primero' },
    { value: 'update_desc', label: 'Actualización: más recientes primero' },
    { value: 'update_asc', label: 'Actualización: más antiguos primero' }
  ]
}

export { formSteps, orderOptions, pageDescription }
