import React from 'react'
import PropTypes from 'prop-types'

const FooterHeader = ({ children }) => {
  return <h2 className="mb-6 text-2xl sm:mb-4 text-white font-bold tracking-wide antialiased">{children}</h2>
}

export default FooterHeader

FooterHeader.propTypes = {
  children: PropTypes.node
}
