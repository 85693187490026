import PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as validations from '../../../utils/validations'
import { useDropzone } from 'react-dropzone'
import { useField } from 'react-final-form'
import Error from '../../molecules/Error'
import * as uuid from 'uuid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { parseToXB } from '../../../utils/helpers'
import { DropZoneBox } from '../../molecules/DropZoneBox'
import {
  humanFileSize,
  getAcceptedExtensions,
  isValidByMimeType,
  getExtensionsText
} from '../../../configs/fileTypesConfig'
import clsx from 'clsx'
import { marginSizes } from '../../../configs/sizesConfig'
import { useTranslation } from 'react-i18next'
import ErrorValidationMessage from '../../molecules/ErrorValidationMessage'

const FileCitizenField = ({ name, payload, index, required, disabled = false, margin = 'normal' }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const { input } = useField(name, {
    validate: validations.mix(
      validations.isValidFileMimeType(payload.types, ' '),
      required && validations.requiredArray('Selecciona al menos un archivo', 1),
      validations.maxFileSize(
        10,
        'MB',
        'El archivo seleccionado no cumple con el tamaño solicitado.',
        'Los archivos seleccionados no cumplen con el tamaño solicitado.'
      )
    )
  })

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    setIsDraggingOver(false)
    const uploadFiles = [...acceptedFiles, ...rejectedFiles]

    const parsedFiles = uploadFiles.map(file => {
      const fileSelected = file.errors ? file.file : file
      fileSelected.isValid = isValidByMimeType(fileSelected, payload.types)

      Object.defineProperty(fileSelected, 'name', {
        writable: true,
        value: encodeURI(fileSelected.name.replace(/\.+(\w+)$/, '.$1'))
      })

      Object.defineProperty(fileSelected, 'internalHashId', {
        writable: true,
        value: uuid.v4()
      })
      return fileSelected
    })
    input.onChange([...input.value, ...parsedFiles])
  }

  const handleDelete = hashId => {
    const persistedFiles = input.value.filter(file => file.internalHashId !== hashId)
    input.onChange(persistedFiles)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: getAcceptedExtensions(payload.types),
    onDragOver: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false)
  })

  const { t, ready } = useTranslation('validation', { useSuspense: false })
  if (!ready) return null

  return (
    <div className={clsx('relative js-field-container', marginSizes[margin])}>
      {payload?.label && (
        <label
          aria-describedby={`input-file-${index}-${name}-hint`}
          className="font-bold mb-2"
          htmlFor={`input-file-${index}-${name}`}
        >
          {index ? index + '.' : ''} {payload?.label}
        </label>
      )}
      <div className="hint-html-injected mb-2" dangerouslySetInnerHTML={{ __html: payload?.hint }} />
      <p className="mb-2">
        {t('validate_formats')} {getExtensionsText(payload.types || [])}.
        <br /> <span className="font-semibold">{t('validate_maximum_weight')} 10 MB.</span>
      </p>
      <DropZoneBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        index={index}
        name={name}
        isHovering={isDraggingOver}
        disabled={disabled}
        multipleFiles
      />
      {input.value && (
        <div className="mt-4">
          {input.value.map((v, key) => (
            <div
              className="bg-white px-4 py-2 flex justify-between items-center border-b-neutral-100 border-b"
              key={v.internalHashId}
            >
              <div>
                <p>
                  {decodeURI(v.name)} -{' '}
                  {parseToXB(v.size, 'MB') >= 0.01 ? parseToXB(v.size, 'MB') + ' MB' : humanFileSize(v.size)}
                </p>
                {!v.isValid && (
                  <ErrorValidationMessage error="El archivo seleccionado no cumple con el formato solicitado." />
                )}
              </div>
              <IconButton color="primary" type="button" onClick={() => handleDelete(v.internalHashId)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}
      <Error name={name} touched={false} className="ml-4" />
    </div>
  )
}

export default FileCitizenField

FileCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
