import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from '../../atoms/Button'
import useUser from '../../../hooks/useUser'
import { Redirect } from 'react-router-dom'
import { isSuccessful } from '../../../hooks/useQuery'
import { useField } from 'react-final-form'
import Alert from '../../molecules/Alert'
import { useDebounce } from '../../../hooks/useDebounce'
import { isEmail } from '../../../utils/validations'

const ValidateButton = ({ email, emailValidated, onValidate, onCancel }) => {
  const { getVerifyByEmail } = useUser()
  const [initialEmail, setInitialEmail] = useState(email)
  const emailToValidate = useDebounce(email, 300)
  const { data, status } = getVerifyByEmail(emailToValidate)
  const emailField = useField('email')
  const emailInvalid = !emailToValidate || Boolean(isEmail()(emailToValidate))

  const handleValidate = () => {
    setInitialEmail(email)
    onValidate()
  }

  const handleCancel = () => {
    emailField.input.onChange(initialEmail)
    onCancel()
  }

  if (emailValidated && isSuccessful(status)) {
    if (data.facilita && data.gobpe) {
      return <Redirect to={`/usuarios/${data?.facilita_user_id}/detalles`} />
    }

    if (data.gobpe && data.facilita === false) {
      return <Redirect to={`/usuarios/detalles-desde-gobpe?email=${email}`} />
    }

    if (!data.facilita && !data.gobpe) {
      return (
        <Alert type="info" className="max-w-144 self-end md:ml-3 mb-6 md:mb-0" border showClose>
          El correo no está asociado a ninguna cuenta en Gob.pe. Puedes continuar.
        </Alert>
      )
    }
  }

  return (
    <>
      {!emailValidated && (
        <div className="flex flex-col-reverse md:flex-row w-full md:w-auto items-center gap-2 min-w-72 md:mt-6 mb-6 md:mb-0 -mt-3">
          <Button type="button" size="full" className="md:max-w-56" variant="text" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            type="button"
            size="full"
            className="md:max-w-56"
            variant="secondary"
            onClick={handleValidate}
            disabled={emailInvalid}
          >
            Validar correo
          </Button>
        </div>
      )}
    </>
  )
}

export default ValidateButton

ValidateButton.propTypes = {
  email: PropTypes.string,
  emailValidated: PropTypes.bool,
  onValidate: PropTypes.func,
  onCancel: PropTypes.func
}
