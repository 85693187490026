import PropTypes from 'prop-types'
import React from 'react'
import { getQuestionTypeObject } from '../../../configs/questionTypesCitizenConfig'

const capitalize = s => s[0].toUpperCase() + s.slice(1).toLowerCase()

const QuestionComponentMounter = ({ dispatch: unsafeDispatch, kind, ...props }) => {
  if (!kind) return null
  const question = getQuestionTypeObject(kind)
  if (!question) throw new Error('El tipo de pregunta no existe')
  if (!unsafeDispatch) throw new Error('No existe tipo de componente a montar (Tracking, Citizen)')
  const dispatch = capitalize(unsafeDispatch)
  const isTracking = dispatch === 'Tracking'
  const Component = question[dispatch] || (isTracking && question.Citizen)
  if (!Component) throw new Error(`El componente no existe para el tipo ${unsafeDispatch}`)
  Component.displayName = `${question.name}.${dispatch}`
  return <Component disabled={isTracking} {...props} />
}

export default QuestionComponentMounter

QuestionComponentMounter.propTypes = {
  dispatch: PropTypes.string,
  kind: PropTypes.string.isRequired
}
