import PropTypes from 'prop-types'
import React from 'react'
import SelectField from './SelectField'
import { dateTimeFormat, getDateFromTime } from '../../../utils/helpers'

const getHoursOptions = (start, end, interval) => {
  const dateStart = getDateFromTime(start)
  const dateEnd = getDateFromTime(end)

  const hours = []
  while (dateStart <= dateEnd) {
    hours.push({
      label: `${dateTimeFormat.format(dateStart)} hrs.`,
      value: dateTimeFormat.format(dateStart)
    })
    dateStart.setMinutes(dateStart.getMinutes() + interval)
  }
  return hours
}

const TimePickerField = ({
  start = '0:00',
  end = '23:59',
  interval = 30,
  label,
  name,
  emptyLabel,
  validate,
  disabled,
  onChange,
  className,
  onOpen,
  ...props
}) => {
  const timeOptions = getHoursOptions(start, end, interval)

  return (
    <SelectField
      name={name}
      label={label}
      labelClassName="font-regular"
      emptyLabel={emptyLabel}
      options={timeOptions}
      size="full"
      margin="none"
      parentClassName="flex items-center"
      containerClassName={className}
      className="min-w-48 ml-2"
      validate={validate}
      disabled={disabled}
      onChange={onChange}
      onOpen={onOpen}
      {...props}
    />
  )
}
export default TimePickerField

TimePickerField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  emptyLabel: PropTypes.string,
  end: PropTypes.string,
  interval: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  start: PropTypes.string,
  validate: PropTypes.func.isRequired
}
