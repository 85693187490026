import React from 'react'
import ImageIcon from '@material-ui/icons/Image'
import ImageUploadBox from './ImageUploadBox'
import useToggle from '../../../../hooks/useToggle'

const ImageToolBar = props => {
  const [toggleModal, setToggleModal] = useToggle()
  return (
    <button
      className="relative mx-1 h-5 w-6 border-gray-30 border-solid rounded-sm flex justify-center items-center border-px"
      onClick={() => setToggleModal()}
      title="Insertar imagen"
      type="button"
    >
      <ImageIcon className="cursor-pointer text-lg " />
      {toggleModal && <ImageUploadBox onClose={() => setToggleModal()} {...props} />}
    </button>
  )
}

export default ImageToolBar
