import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { errorResendInfo, webhookStatusColorMap } from '../../../hooks/useAnswerToWebhook'
import WebhookStatusLabel from '../../molecules/WebhookStatusLabel'
import useToggle from '../../../hooks/useToggle'
import IconButton from '../../molecules/buttons/IconButton'
import CodeBlock from '../../molecules/CodeBlock'
import ExpandMore from '../../atoms/icons/ExpandMore'
import Tr from '../../atoms/table/Tr'
import Td from '../../atoms/table/Td'

const AnswerApiTableRow = ({ apiLog }) => {
  const [codeExpanded, toggleCodeExpanded] = useToggle()

  return (
    <>
      <Tr key={apiLog.id}>
        <Td className="break-all w-48 min-w-34">{apiLog.url}</Td>
        <Td>{apiLog.authorization}</Td>
        <Td>{apiLog.send_date}</Td>
        <Td className={clsx('font-semibold', webhookStatusColorMap[apiLog.status])}>
          <WebhookStatusLabel status={apiLog.status} infoContent={errorResendInfo.detail} />
        </Td>
        <Td>{apiLog.response_status}</Td>
        <Td>
          <div className="flex items-center gap-2">
            <p className="w-30 overflow-ellipsis overflow-hidden whitespace-nowrap">{apiLog.response_body}</p>
            <IconButton
              className="rounded"
              color="filled-primary"
              size="small"
              text={codeExpanded ? 'Ocultar' : 'Expandir'}
              onClick={toggleCodeExpanded}
            >
              <ExpandMore
                className={clsx('text-base transition-transform duration-500', codeExpanded && 'rotate-180 transform')}
              />
            </IconButton>
          </div>
        </Td>
      </Tr>
      {codeExpanded && (
        <Tr className="relative -top-2">
          <Td colSpan={6}>
            <CodeBlock text={apiLog.response_body} />
          </Td>
        </Tr>
      )}
    </>
  )
}

export default AnswerApiTableRow

AnswerApiTableRow.propTypes = {
  apiLog: PropTypes.object
}
