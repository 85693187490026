import PropTypes from 'prop-types'
import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useField, Field } from 'react-final-form'
import Hint from '../../atoms/Hint'
import Error from '../Error'

const RadioDoubleField = ({ label, name, hint, validate, cols = [], rows = [], disabled = false }) => {
  const { input } = useField(name, { validate })
  const handleRadioChange = () => {
    input.onFocus()
    input.onBlur()
  }

  return (
    <>
      <legend className="font-bold mb-2 sticky left-0 w-legend md:w-full">{label}</legend>
      {hint && <Hint hint={hint} name={name} className="sticky left-0 w-legend md:w-full" />}
      <div className="min-w-full border-collapse table">
        <div className="table-row-group">
          <div className="table-row">
            <div className="bg-neutral-200 sticky left-0 w-2/5 table-cell"></div>
            {cols.map(col => (
              <div className="text-center table-cell w-27.5" key={col?.label}>
                {col?.label}
              </div>
            ))}
          </div>
          {rows.map((row, indexRow) => (
            <Field name={`${name}[${indexRow}]`} type="radio" key={row?.label}>
              {fieldProps => (
                <RadioGroup row {...fieldProps.input} className="table-row">
                  <div className="sticky left-0 z-10 w-2/5 bg-neutral-200 table-cell">
                    <div className="md:pl-4 my-1 -mr-0.5 flex items-center">{row?.label}</div>
                  </div>
                  {cols.map((col, indexCol) => (
                    <div
                      key={`${row?.label}_${col?.label}`}
                      className="align-middle bg-neutral-200 h-11 table-cell w-27.5"
                    >
                      <div className="my-1 flex items-center justify-around w-full">
                        <Radio
                          disabled={disabled}
                          value={`${indexRow}_${indexCol}_${col?.label}`}
                          inputProps={{ 'aria-label': `Fila ${row?.label}, Columna ${col?.label}` }}
                          onChange={handleRadioChange}
                        />
                      </div>
                    </div>
                  ))}
                </RadioGroup>
              )}
            </Field>
          ))}
        </div>
      </div>
      <Error className="relative -bottom-0" name={name} />
    </>
  )
}

export default RadioDoubleField

RadioDoubleField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  hint: PropTypes.string,
  validate: PropTypes.func,
  cols: PropTypes.array,
  rows: PropTypes.array,
  disabled: PropTypes.bool
}
