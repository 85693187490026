import React from 'react'
import PagePreview from '../../../organisms/onboarding/PagePreview'
import { useParams } from 'react-router-dom'
import useTutorial from '../../../../hooks/useTutorial'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const updateTutorial = id => values => axios.put(`/admin/v1/tutorials/${id}.json`, values)

const TutorialPreview = () => {
  const { id } = useParams()
  const { getTutorial } = useTutorial()
  const queryClient = useQueryClient()
  const { mutate } = useMutation(updateTutorial(id), {
    onSuccess: () => queryClient.invalidateQueries('tutorial')
  })
  const edit = { url: `/centro-de-ayuda/articulo-tutorial/${id}/editar`, buttonLabel: 'Editar tutorial' }
  return <PagePreview getArticle={getTutorial} updateArticle={mutate} edit={edit} />
}

export default TutorialPreview
