import PropTypes from 'prop-types'
import React from 'react'
import Switch from '@material-ui/core/Switch'
import { useField } from 'react-final-form'
import { withStyles } from '@material-ui/core'
import clsx from 'clsx'

const AntSwitch = withStyles(theme => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(21px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
    borderColor: theme.palette.grey[500]
  },
  checked: {}
}))(Switch)

const SwitchField = ({ label, name, validate, parentClassName, labelClassName, ...props }) => {
  const { input } = useField(name, { validate, type: 'checkbox', ...props })
  return (
    <div className={clsx('md:flex items-start md:items-center', parentClassName)}>
      <AntSwitch
        id={`${name}-input`}
        {...input}
        onChange={e => {
          input.onChange(e.target.checked)
          props.onChange && props.onChange(e.target.checked)
        }}
      />
      {label && (
        <label
          htmlFor={`${name}-input`}
          className={clsx('md:ml-2 text-xs md:text-base font-bold leading-4 md:leading-5', labelClassName)}
        >
          {label}
        </label>
      )}
    </div>
  )
}

export default SwitchField

SwitchField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parentClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}
