import PropTypes from 'prop-types'
import React from 'react'
import PhoneCitizenField from '../question_fields/PhoneCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin } = props
  return <PhoneCitizenField index={index} {...question} name={name} disabled={disabled} margin={margin} />
}

const PhoneQuestion = {
  Citizen,
  name: 'PhoneQuestion'
}

export default PhoneQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}
