import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import UsersNew from '../../components/pages/admin/areas_users/UsersNew'
import UsersValidate from '../../components/pages/admin/areas_users/UsersValidate'
import UsersEdit from '../../components/pages/admin/areas_users/UsersEdit'
import UsersDetail from '../../components/pages/admin/areas_users/UsersDetail'
import UsersDetailFromGobpe from '../../components/pages/admin/areas_users/UsersDetailFromGobpe'
import { AreasNew } from '../../components/pages/admin/areas_users/AreasNew'
import { AreasEdit } from '../../components/pages/admin/areas_users/AreasEdit'
import useUserPermission from '../useUserPermission'
import { useAuth } from '../../components/molecules/Auth'

const useAreasUsersRoute = () => {
  const { user } = useAuth()
  const { canSeeAreasAndUsers } = useUserPermission('menu')
  const { canCreate, canEdit } = useUserPermission('user')
  const { canManageAreas } = useUserPermission('area')

  return [
    { path: '/areas-y-usuarios', component: ResourcesTab, hasPermission: canSeeAreasAndUsers },
    { path: '/usuarios/crear', component: UsersNew, hasPermission: canCreate },
    { path: '/usuarios/validar', component: UsersValidate, hasPermission: canCreate },
    { path: '/usuarios/detalles-desde-gobpe', component: UsersDetailFromGobpe, hasPermission: canCreate },
    {
      path: '/usuarios/editar-desde-gobpe',
      component: UsersDetailFromGobpe,
      props: { editByDetail: true },
      hasPermission: canCreate
    },
    { path: '/usuarios/detalles-desde-facilita', component: UsersDetailFromGobpe, hasPermission: canCreate },
    { path: '/usuarios/:id/editar', component: UsersEdit, hasPermission: canEdit(user.institution_id) },
    { path: '/usuarios/:id/detalles', component: UsersDetail, hasPermission: true },
    { path: '/areas/crear', component: AreasNew, hasPermission: canManageAreas },
    { path: '/areas/:id/editar', component: AreasEdit, hasPermission: canManageAreas },
    { path: '/mi-cuenta', component: UsersEdit, props: { title: 'Mi cuenta' }, hasPermission: true }
  ]
}
export default useAreasUsersRoute
