import { Button } from '../../atoms/Button'
import ExcelExport from '../../atoms/icons/ExcelExport'
import React, { Fragment, useState } from 'react'
import Dialog from '../../molecules/dialogs/Dialog'
import { useAuth } from '../../molecules/Auth'
import axios from 'axios'
import useStatistics from '../../../hooks/useStatistics'
import { isSuccessful } from '../../../hooks/useQuery'
import useInvalidateQueries from '../../../hooks/useInvalidateQueries'
import useFilters from '../../../hooks/useFilters'

const DownloadableResourcesSection = () => {
  const { user } = useAuth()
  const currentRole = user.role
  const [popupInfo, setPopupInfo] = useState({ show: false, title: '' })
  const { getDownloadedStatus } = useStatistics()
  const { data: downloadedStatus, status } = getDownloadedStatus(
    ['downloadedStatus'],
    {},
    { refetchOnWindowFocus: true }
  )
  const invalidateQueries = useInvalidateQueries()
  const { filters } = useFilters('')

  if (currentRole === 'collaborator') {
    return null
  }

  const handleDownloadRequest = async (endpoint, title) => {
    try {
      await axios.post(endpoint, filters)
      invalidateQueries(['downloadedStatus'])
      setPopupInfo({ show: true, title })
    } catch (error) {
      alert(error)
    }
  }

  const closePopup = () => {
    setPopupInfo({ show: false, title: '' })
  }

  const resources = [
    {
      id: 'form_excel',
      title: 'Lista de formularios creados',
      endpoint: '/admin/v1/stats/download_form_excel',
      roles: ['admin', 'coordinator', 'branch_coordinator']
    },
    {
      id: 'institution_excel',
      title: 'Lista de entidades creadas',
      endpoint: '/admin/v1/stats/download_institution_excel',
      roles: ['admin', 'coordinator']
    },
    {
      id: 'user_excel',
      title: 'Lista de usuarios creados',
      endpoint: '/admin/v1/stats/download_user_excel',
      roles: ['admin', 'coordinator', 'branch_coordinator']
    },
    {
      id: 'reception_desk_excel',
      title: 'Listado de mesas de partes',
      endpoint: '/admin/v1/stats/download_reception_desk_excel',
      roles: ['admin']
    },
    {
      id: 'access_to_public_information_excel',
      title: 'Listado de acceso a la información pública',
      endpoint: '/admin/v1/stats/download_access_to_public_information_excel',
      roles: ['admin']
    }
  ]

  if (!isSuccessful(status)) {
    return null
  }

  return (
    <>
      {popupInfo.show && (
        <Dialog
          maxWidth="sm"
          buttonClose={false}
          renderTitle={<p className="header font-bold text-lg">Exportando {popupInfo.title}</p>}
          renderContent={
            <div className="border-neutral-300 py-6">
              <p>
                Cuando finalice el proceso, recibirás la información como un archivo de Excel en el correo
                <span className="font-bold"> {user.email}.</span>
              </p>
              <p className="pt-6">
                <span className="font-bold">Nota:</span> Si deseas exportar la información relacionada a otro indicador,
                puedes hacerlo después de recibir el archivo actual, para asegurar el óptimo rendimiento de la
                plataforma.
              </p>
            </div>
          }
          renderActions={
            <Fragment>
              <div className="md:contents inline-block">
                <Button size="full" className="md:w-40 w-full" onClick={closePopup}>
                  Ok
                </Button>
              </div>
            </Fragment>
          }
          onClose={closePopup}
        />
      )}

      <div className="py-8 px-6 bg-neutral-100 border-b border-gray-350 border-solid">
        <div className="flex flex-col pb-4 md:pb-0">
          <h2 className="md:text-left w-full md:w-auto font-bold text-2xl">Recursos descargables</h2>
          <p className="my-4">
            Selecciona la información que quieres exportar, considerando que solo puedes elegir un indicador a la vez.
            Después de recibir la información solicitada en tu correo electrónico vinculado a Facilita Perú, podrás
            elegir otro indicador.
          </p>
        </div>
        <div className="w-full md:w-auto">
          {resources.map(
            resource =>
              resource.roles.includes(currentRole) && (
                <div key={resource.id} className="bg-white p-2 mb-4">
                  <div className="space-y-2">
                    <div className="flex flex-col md:flex-row justify-between items-center p-2">
                      <p>{resource.title}</p>
                      <div>
                        <Button
                          className="flex mb-2 px-4 py-3"
                          size="full"
                          variant="secondary"
                          onClick={() => handleDownloadRequest(resource.endpoint, resource.title)}
                          disabled={downloadedStatus?.status === 'EN PROCESO'}
                        >
                          <ExcelExport /> Exportar en Excel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  )
}

export default DownloadableResourcesSection
