import PropTypes from 'prop-types'
import React from 'react'
import { TabContext as TabContextMUI } from '@material-ui/lab'

export const TabContext = ({ value, children }) => <TabContextMUI value={value}>{children}</TabContextMUI>

TabContext.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
