export const findParent = (el, clas) => {
  let parentEl = null
  for (; el && el !== document; el = el.parentNode) {
    if (el.className.indexOf(clas) >= 0) {
      parentEl = el
      return parentEl
    }
  }
  return parentEl
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'toUpperFirst', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
  },
  writable: false,
  configurable: false
})
