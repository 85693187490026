import React from 'react'
import TextField from '../TextField'
import * as validations from '../../../../utils/validations'
import GoToLink from 'images/go_to_link.svg'
import DialogBox from './DialogBox'
import { Button } from '../../../atoms/Button'
import PropTypes from 'prop-types'

const handleStopPropagation = event => {
  event.stopPropagation()
}

const setURL = url => {
  try {
    const _url = new URL(url)
    return _url.toString()
  } catch (error) {
    return `http://${url}`
  }
}

const LinkToolBarBox = ({ onClose, currentState, onChange }) => {
  const handleDeleteClick = async () => {
    await onChange()
    onClose()
  }

  const linkTitle = currentState.link?.title
  const linkTarget = currentState.link?.target

  const initialValues = {
    url: linkTarget,
    selectionText: currentState.selectionText || linkTitle
  }

  const boxesHavePrevContent = linkTitle && linkTarget

  const handleSend = async (values, form, valid) => {
    form.focus('url')
    form.blur('url')
    form.focus('selectionText')
    form.blur('selectionText')
    if (valid) await onChange('link', values.selectionText, values.url, '_blank')
    onClose()
  }

  const TextBoxes = () => (
    <>
      <TextField
        name="url"
        validate={validations.mix(validations.required(), validations.isURL())}
        size="2xl"
        margin="normal"
        label="Ingresa la URL"
        labelClassName="text-14"
        errorClassName="text-xs"
        rightDecorator={LinkBox}
      />
      <TextField
        name="selectionText"
        label="Texto"
        validate={validations.required()}
        size="full"
        labelClassName="text-14"
        margin="normal"
        errorClassName="text-xs"
      />
    </>
  )

  const CancelButton = () => (
    <Button className="text-blue-600 font-bold mr-4 leading-none" onClick={handleDeleteClick} variant="text">
      Eliminar URL
    </Button>
  )

  const LinkBox = ({ inputValue }) => {
    return (
      <a
        href={setURL(inputValue)}
        className="w-1/5 bg-sky-blue-400 flex justify-center items-center py-3 border border-l-0 border-black border-solid"
        target="_blank"
        rel="noreferrer"
      >
        <img src={GoToLink} alt="" className="cursor-pointer w-5 h-4" />
      </a>
    )
  }
  LinkBox.propTypes = {
    inputValue: PropTypes.string.isRequired
  }

  return (
    <DialogBox
      initialValues={initialValues}
      titleText={`${boxesHavePrevContent ? 'Editar' : 'Insertar'} URL`}
      dialogContent={<TextBoxes />}
      onStopPropagation={handleStopPropagation}
      onSubmit={handleSend}
      cancelButton={boxesHavePrevContent && <CancelButton />}
      onClose={onClose}
    />
  )
}

LinkToolBarBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentState: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired
}

export default LinkToolBarBox
