import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import FieldTitle from '../../molecules/FieldTitle'
import FormSection from '../../molecules/FormSection'
import Value from '../../molecules/Value'
import { fieldTitle } from '../../../configs/templatesConfig'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import TextField from '../../molecules/fields/TextField'
import { HtmlField } from '../../molecules/fields/HtmlField'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { TagBox } from '../../molecules/TagBox'
import useFieldValue from '../../../hooks/useFieldValue'
import InfoAutoResponseFromTemplate from './InfoAutoResponseFromTemplate'
import PaymentMethodConfigurationSection from './PaymentMethodConfigurationSection'
import { swornDeclarationUrl } from '../../../configs/assetsConfig'
import SubType from './config_items/SubType'
import PublishSchedule from './config_items/PublishSchedule'
import CoverImage from './config_items/CoverImage'

const FormsFormSetup = ({ config }) => {
  const templateId = useFieldValue('template_id')
  const showInfoAutoResponseFromTemplate = config.auto_response === 'official_decide' && templateId
  const hasPaymentMethodConfig = config.enabled_payment_methods?.has_transfer_accounts
  return (
    <FormSection title="Información y configuración general">
      <TextField
        name="name"
        label="Nombre del trámite"
        hint={!config.from_template && 'Es el nombre que tendrá el formulario.'}
        margin="none"
        size="2xl"
        validate={validations.required()}
        disabled={config.from_template}
      />
      <FieldsSeparator />
      <SubType config={config} />
      <FieldsSeparator />
      <CoverImage />
      <FieldsSeparator />
      <HtmlField
        name="hint"
        label="Indicación general del formulario"
        placeholder="Ej.: La recepción de documentos es de 8:00 a. m. a 6:00 p. m. Ingresa tu solicitud y un especialista se comunicará contigo."
        hint="Es la información que se le dará al ciudadano para ayudarlo con el proceso del trámite."
        validate={validationsAdmin.isRichTextFieldEmpty()}
        maxContentLength={1000}
        margin="none"
      />
      {config.answers_by_email && (
        <>
          <FieldsSeparator />
          <TextField
            name="subscribed_email"
            label="Dirección de correo para recibir respuestas (opcional)"
            hint="Todas las respuestas a este formulario llegarán al correo electrónico que ingreses."
            margin="none"
            placeholder="Ej.: nombre@micorreo.com"
            validate={validations.isEmail()}
          />
        </>
      )}
      {fieldTitle('it_costs', config.it_costs) && (
        <>
          <FieldsSeparator />
          <div className={clsx('flex flex-col', !hasPaymentMethodConfig && 'md:flex-row')}>
            <div>
              <FieldTitle
                title={fieldTitle('it_costs', config.it_costs).title}
                hint={fieldTitle('it_costs', config.it_costs).hint}
                disabled={!hasPaymentMethodConfig}
              />
            </div>
            <PaymentMethodConfigurationSection hasPaymentMethodConfig={hasPaymentMethodConfig} config={config} />
          </div>
        </>
      )}
      {fieldTitle('auto_response', config.auto_response) && (
        <>
          <FieldsSeparator />
          <FieldTitle
            title={fieldTitle('auto_response', config.auto_response).title}
            hint={fieldTitle('auto_response', config.auto_response).hint}
          />
        </>
      )}
      {showInfoAutoResponseFromTemplate && <InfoAutoResponseFromTemplate />}
      <Value name="auto_response">
        {({ value: autoResponse }) =>
          autoResponse && (
            <HtmlField
              name="auto_response_text"
              label="Texto de la respuesta"
              hasMaxContentLength={false}
              allowScrolling
              className="ml-4"
              editorClassName="sm:max-w-xl"
              validate={validations.required()}
            />
          )
        }
      </Value>
      <FieldsSeparator />
      <FieldTitle
        title={
          <>
            Respuestas hacia una API <TagBox className="ml-2" label="BETA" />
          </>
        }
        hint={
          <CheckboxField
            name="has_webhook"
            margin="none"
            label="Quiero que las respuestas de los ciudadanos se registren también en un API de mi institución."
          />
        }
      />
      <Value name="has_webhook">
        {({ value }) =>
          value && (
            <>
              <TextField
                name="webhook_url"
                size="5xl"
                rootClassName="ml-8"
                rows={6}
                margin="high"
                label="Ingresa la ruta"
                hint="Haremos un POST con content-type: application/json"
                validate={validations.mix(validations.required(), validations.isURL())}
              />
              <TextField
                name="webhook_auth"
                size="full"
                rootClassName="ml-8"
                rows={6}
                margin="none"
                hint="Lo enviaremos en el header authorization y así podrás comprobar que el envío viene desde Facilita"
                label="Ingresa el texto de autenticación"
                validate={validations.required()}
              />
            </>
          )
        }
      </Value>
      {fieldTitle('is_trackable', config.is_trackable) && (
        <>
          <FieldsSeparator />
          <FieldTitle
            title={fieldTitle('is_trackable', config.is_trackable).title}
            hint={fieldTitle('is_trackable', config.is_trackable).hint}
          />
        </>
      )}
      {fieldTitle('sworn_declaration', config.sworn_declaration) && (
        <>
          <FieldsSeparator />
          <FieldTitle
            title={fieldTitle('sworn_declaration', config.sworn_declaration).title}
            hint={fieldTitle('sworn_declaration', config.sworn_declaration).hint}
          />
        </>
      )}
      {config.sworn_declaration === 'yes' && (
        <div className="mt-6 bg-neutral-300 p-4">
          <CheckboxField
            name="accepts_sworn_declaration"
            margin="none"
            label={
              <p>
                Acepto{' '}
                <a href={swornDeclarationUrl} target="_blank" rel="noreferrer">
                  la declaración jurada de veracidad de la información
                </a>
              </p>
            }
            disabled
          />
        </div>
      )}
      <>
        <FieldsSeparator />
        <PublishSchedule name="subscribed_email" />
      </>
    </FormSection>
  )
}
export default FormsFormSetup

FormsFormSetup.propTypes = {
  config: PropTypes.object
}
