import PropTypes from 'prop-types'
import React from 'react'
import PersonIcon from '@material-ui/icons/Person'
import { Link } from 'react-router-dom'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import EditButton from '../../molecules/buttons/EditButton'
import IconButton from '../../molecules/buttons/IconButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'

const StatisticsRow = ({ label, institutionId, path, count }) => {
  return (
    <p>
      {label}:{' '}
      {count ? (
        <Link to={`/${path}?institution_id=${institutionId}`} className="font-semibold">
          {count}
        </Link>
      ) : (
        count
      )}
    </p>
  )
}

const InstitutionsTable = ({ institutions = [] }) => {
  return (
    <div className="overflow-x-scroll md:overflow-x-hidden">
      <Table
        className="table-fixed md:w-full w-272 md:w-auto overflow-x-scroll"
        values={institutions}
        head={
          <Tr body={false}>
            <Th className="w-3/12 md:w-5/12">Nombre</Th>
            <Th className="w-2/12 md:w-2/12">Tipo</Th>
            <Th className="w-2/12 md:w-2/12">Ubicación</Th>
            <Th className="w-2/12 md:w-2/12">Fecha de incorporación</Th>
            <Th className="w-2/12 md:w-3/12">Estadísticas</Th>
            <Th className="w-2/12 md:w-2/12 text-center">Acciones</Th>
          </Tr>
        }
        body={institutions?.map(institution => {
          return (
            <Tr key={institution.id} className="text-lg">
              <Td>
                <Link to={`/entidades/${institution.id}/detalles`}>{institution.name}</Link>
              </Td>
              <Td>{institution.institution_type_name}</Td>
              <Td>
                <div>{institution.location?.region}</div>
                <div>{institution.location?.province}</div>
                <div>{institution.location?.district}</div>
              </Td>
              <Td>{institution.incorporation_date}</Td>
              <Td>
                <div className="grid grid-cols-1 w-48">
                  <StatisticsRow
                    label="Formularios"
                    count={institution.statistics.forms}
                    path="formularios"
                    institutionId={institution.id}
                  />
                  <StatisticsRow
                    label="Consultas rápidas"
                    count={institution.statistics.quick_searches}
                    path="consultas-rapidas"
                    institutionId={institution.id}
                  />
                  <StatisticsRow
                    label="Gestión de trámites"
                    count={institution.statistics.procedure_managements}
                    path="gestiones-de-tramites-habilitadas"
                    institutionId={institution.id}
                  />
                  <FieldsSeparator className="my-2" />
                  <StatisticsRow
                    label="Sedes"
                    count={institution.statistics.branch_offices}
                    path="sedes"
                    institutionId={institution.id}
                  />
                  <StatisticsRow
                    label="Usuarios"
                    count={institution.statistics.users}
                    path="areas-y-usuarios"
                    institutionId={institution.id}
                  />
                </div>
              </Td>
              <Td>
                <div className="flex justify-center">
                  <EditButton
                    text="Editar en gob.pe"
                    component="a"
                    href={`${window.sisoli.gobpe_url}/admin2/instituciones/${institution.slug}/edit`}
                    target="_blank"
                    rel="noreferrer"
                  />
                  <IconButton
                    text="Ver Usuarios"
                    component={'Link'}
                    to={`/areas-y-usuarios?institution_id=${institution.id}`}
                    classes={{ root: 'p-0 ml-2' }}
                  >
                    <PersonIcon />
                  </IconButton>
                </div>
              </Td>
            </Tr>
          )
        })}
      />
    </div>
  )
}

export default InstitutionsTable

StatisticsRow.propTypes = {
  count: PropTypes.number,
  institutionId: PropTypes.number,
  label: PropTypes.string,
  path: PropTypes.string
}

InstitutionsTable.propTypes = {
  institutions: PropTypes.array
}
