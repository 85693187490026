import PropTypes from 'prop-types'
import React from 'react'
import { EmailPhoneApplicantCitizenField } from '../question_fields/EmailPhoneApplicantCitizenField'

const Citizen = props => {
  const { name, index, disabled, question, margin } = props
  return <EmailPhoneApplicantCitizenField index={index} {...question} name={name} disabled={disabled} margin={margin} />
}

const EmailPhoneApplicantQuestion = {
  Citizen,
  name: 'EmailPhoneApplicantQuestion'
}

export default EmailPhoneApplicantQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}
