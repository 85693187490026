import { useTranslation } from 'react-i18next'

const useTextValidation = () => {
  const { t, ready } = useTranslation('validation', { useSuspense: false })

  if (!ready) {
    return {
      maxLenght: () => {},
      minLenght: () => {},
      lenght: () => {}
    }
  }

  const required = t('validate_required_field')
  const maxLenght = n => `${t('validate_max_characteres')} ${n} ${t('validate_characteres')}.`
  const minLenght = n => `${t('validate_min_characteres')} ${n} ${t('validate_characteres')}.`
  const lenght = n => `${t('validate_insert')} ${n} ${t('validate_characteres')}.`
  const isEmail = t('validate_email')
  const isPhone = t('validate_phone_number')
  const isPeruvianCellphone = t('validate_phone_number')
  const isNumber = t('validate_only_numbers')
  const isNumberPlease = t('validate_please_numbers')
  const isAlphanumeric = t('validate_only_numbers_or_letters')
  const isDate = t('validate_date')
  const isNotFutureDate = t('validate_date_after_today')
  const isLetter = t('validate_only_letters')
  const isLetterPlease = t('validate_please_letters')
  const notHasBlankSpaces = t('validate_cannot_contain_spaces')
  const requiredOnePerRow = t('validate_required_one_row')
  const maxOneCol = t('validate_max_one_col')

  return {
    required,
    maxLenght,
    minLenght,
    lenght,
    isEmail,
    isPhone,
    isPeruvianCellphone,
    isNumber,
    isAlphanumeric,
    isDate,
    isNotFutureDate,
    isLetter,
    notHasBlankSpaces,
    requiredOnePerRow,
    maxOneCol,
    isNumberPlease,
    isLetterPlease
  }
}

export default useTextValidation
