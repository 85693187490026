import PropTypes from 'prop-types'
import React, { useState } from 'react'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import FormSection from '../../molecules/FormSection'
import QuestionCondition from './QuestionCondition'
import clsx from 'clsx'
import { Form } from 'react-final-form'
import { useHistory, Link } from 'react-router-dom'
import { Button } from '../../atoms/Button'
import Alert from '../../molecules/Alert'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'

const FormsCondition = ({ initialValues, formParams }) => {
  const [showMessage, setShowMessage] = useState(false)

  const history = useHistory()

  const handleSubmit = async values => {
    const accepted = values.question.includes('false')
    const approvedTupa = values.approvedTupa
    setShowMessage(accepted)
    if (!accepted) {
      history.push(
        `/formularios/crear/plantilla/${initialValues.template_id}?accepted=${values.question[0]}&approvedTupa=${approvedTupa}`
      )
    }
  }

  const shortTemplateName = formParams.templateName.split(' ').slice(-1).join(' ').toLowerCase()

  return (
    <>
      {showMessage && (
        <Alert type="error" border className="mb-10">
          Todas las condiciones indicadas son obligatorias. Si respondes &ldquo;No&ldquo; en una o más de las preguntas,
          no podrás crear el formulario &ldquo;{formParams.templateName}&ldquo;. Te sugerimos trabajar en estos
          criterios y, luego, volver para continuar con este proceso.
        </Alert>
      )}
      <Form onSubmit={handleSubmit} subscription={{ pristine: true, submitting: true }}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSection>
              <div className="hidden md:flex justify-end gap-x-6 md:mb-6">
                <div className="mr-10">Sí</div>
                <div className="mr-10">No</div>
              </div>
              <QuestionCondition
                name={`question.0`}
                label={`1.- ¿La información de actas de ${shortTemplateName} que tiene tu entidad ya fue enviada al Reniec?`}
              />
              <FieldsSeparator />
              <QuestionCondition
                name={`question.1`}
                label={`2.- ¿Las condiciones y requisitos para solicitar la copia certificada de un acta de ${shortTemplateName} se encuentran en el TUPA de tu entidad?`}
              />
              <FieldsSeparator />
              <QuestionCondition
                name={`question.2`}
                label={`3.- ¿Los derechos de tramitación (canales y horarios de atención, costos, etc.) de la copia certificada de un acta de ${shortTemplateName} se encuentran en el TUPA de tu entidad?`}
              />
              <FieldsSeparator />
              <QuestionCondition name={`question.3`} label={`4.- ¿El TUPA de tu entidad se encuentra vigente?`} />
              <TextField
                name="approvedTupa"
                hint="4.1. Coloca la norma u ordenanza con la que se aprobó el TUPA de tu entidad:"
                margin="none"
                rootClassName="md:ml-5"
                size="2xl"
                validate={validations.required()}
              />
              <Alert type="warning" border className="my-10">
                Declaro que la información ingresada es cierta. Me someto al principio de presunción de veracidad y a
                los controles posteriores establecidos en el artículo IV del Título preliminar del{' '}
                <a
                  href="https://www.gob.pe/institucion/minjus/informes-publicaciones/2039342-texto-unico-ordenado-de-la-ley-n-27444-ley-del-procedimiento-administrativo-general"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Texto Único Ordenado (TUO) de la Ley N.° 27444, Ley del Procedimiento Administrativo General, aprobado
                  por el Decreto Supremo N.° 004-2019-JUS
                </a>
                .
              </Alert>
              <div className={clsx('flex gap-6 justify-end px-6 md:px-0 flex-col md:flex-row flex-col-reverse')}>
                <Button
                  className="md:max-w-56 mb-4 md:mb-0"
                  type="button"
                  el={Link}
                  to={`/formularios/crear/configuracion`}
                  size="full"
                  variant="secondary"
                >
                  Regresar
                </Button>
                <div className={clsx('md:max-w-72')}>
                  <Button className="ml-auto" type="submit" size="full">
                    Enviar información
                  </Button>
                </div>
              </div>
            </FormSection>
          </form>
        )}
      </Form>
    </>
  )
}
export default FormsCondition

FormsCondition.propTypes = {
  initialValues: PropTypes.object,
  formParams: PropTypes.exact({
    templateName: PropTypes.string
  }).isRequired
}
