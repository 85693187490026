import PropTypes from 'prop-types'
import React from 'react'
import CategoriesForm from './CategoriesForm'
import useCategory from '../../../hooks/useCategory'

const CategoriesNew = ({ onCancel, onFormSubmit }) => {
  const { createCategory, invalidateCategories } = useCategory()
  const handleSubmit = async values => {
    try {
      createCategory(values, {
        onSuccess: () => {
          invalidateCategories()
          onFormSubmit()
        }
      })
    } catch (error) {
      return { name: error.response.data.message }
    }
  }

  return (
    <CategoriesForm
      onFormSubmit={handleSubmit}
      onCancel={onCancel}
      SaveButtonLabel="Crear"
      TextFieldLabel="Nombre de la nueva categoría"
    />
  )
}

export default CategoriesNew

CategoriesNew.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
}
