import React from 'react'
import Container from '../../atoms/Container'
import { ReactSVG as Svg } from 'react-svg'
import institutionStatistics from '../../../images/icons/institutions_statistics.svg'
import officialsStatistics from '../../../images/icons/officials_statistics.svg'
import formsStatistics from '../../../images/icons/forms_statistics.svg'
import filledFormsStatistics from '../../../images/icons/filled_forms_statistics.svg'
import useHomeStatistics from '../../../hooks/services/useHomeStatistics'

export const Statistics = () => {
  const { statisticData, status } = useHomeStatistics()

  if (status !== 'success') return null
  const { users, forms, answers_received: answersReceived, institutions } = statisticData

  const statistics = [
    {
      image: institutionStatistics,
      number: institutions,
      label: (
        <>
          <strong>Entidades públicas</strong> usan Facilita
        </>
      )
    },
    {
      image: officialsStatistics,
      number: users,
      label: (
        <>
          <strong>Servidores registrados</strong> usan Facilita
        </>
      )
    },
    {
      image: formsStatistics,
      number: forms,
      label: (
        <>
          <strong>Formularios creados</strong> por entidades
        </>
      )
    },
    {
      image: filledFormsStatistics,
      number: answersReceived,
      label: (
        <>
          <strong>Solicitudes enviadas</strong> por la ciudadanía
        </>
      )
    }
  ]

  return (
    <section className="bg-blue-300 py-14 md:pt-20 md:pb-24 px-8 md:px-0">
      <Container className="lg:px-36 md:px-10">
        <h2 className="mb-8 md:mb-20 font-bold text-xl md:text-7.5 text-center">Principales cifras de Facilita Perú</h2>
        <div className="flex justify-between flex-wrap md:flex-nowrap statistics">
          {statistics.map((st, index) => (
            <div key={index} className="flex statistic">
              <Svg src={st.image} />
              <p className="ml-6 flex flex-col">
                <strong className="text-4xl">{st.number}</strong>
                {st.label}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
