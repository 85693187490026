import PropTypes from 'prop-types'
import React from 'react'
import Chip from '@material-ui/core/Chip'
import { Button } from '../../atoms/Button'

const PublishStatusSection = ({ canChangeState, mutate, item }) => {
  const { published } = item
  const allowPublish = item.allow_publish ?? true

  const type = {
    published: {
      chipClasses: 'rounded-none font-bold border-solid	border-2 bg-green-500 border-green-500 text-green-800',
      chipLabel: 'Publicado',
      buttonVariant: 'secondary',
      buttonLabel: 'Despublicar'
    },
    unPublished: {
      chipClasses: 'rounded-none font-bold border-solid	border-2 bg-yellow-300 border-yellow-400 text-yellow-800',
      chipLabel: 'No publicado',
      buttonVariant: 'primary',
      buttonLabel: 'Publicar'
    }
  }
  const state = published ? type.published : type.unPublished
  return (
    <div className="w-full md:w-72">
      <div className="text-center w-full">
        <span className="font-bold mr-4">Estado: </span>
        <Chip label={state.chipLabel} classes={{ root: state.chipClasses }} />
      </div>
      {canChangeState && (
        <div className="border-t border-neutral-400 pt-4 mt-4 md:px-10">
          <Button
            onClick={() => mutate({ published: !published })}
            size="full"
            variant={state.buttonVariant}
            disabled={!allowPublish}
          >
            {state.buttonLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default PublishStatusSection

PublishStatusSection.propTypes = {
  canChangeState: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  item: PropTypes.object,
  mutate: PropTypes.func.isRequired
}
