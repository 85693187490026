export const roles = {
  admin: 'admin',
  coordinator: 'coordinator',
  branch_coordinator: 'branch_coordinator',
  collaborator: 'collaborator'
}

export const types = {
  general: 'general',
  form: 'forms',
  quickSearch: 'quick_searches',
  procedureManagement: 'procedure_managements'
}
