import PropTypes from 'prop-types'
import React, { useState } from 'react'
import EscudoFacilita from '../../images/escudo_facilita.svg'
import EscudoGobpe from '../../images/escudo_peru.svg'
import ArrowDropDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { MenuItem, MenuList } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAuth } from './Auth'
import Container from '../atoms/Container'
import UserInfo from './UserInfo'
import Message from './Message'
import { Button } from '../atoms/Button'
import clsx from 'clsx'
import { hasExactURL } from '../../utils/helpers'
import NotificationsTraySocket from '../organisms/notifications/NotificationsTraySocket'
import { isSuccessful } from '../../hooks/useQuery'

const useStyles = makeStyles({
  arrow: {
    left: '25px'
  }
})

const Header = ({ serviceName = 'Facilita Perú', showUserInfo = true, slug }) => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()
  const { pathname } = useLocation()
  const { user, logout, status } = auth || {}
  const isFixed = hasExactURL(pathname)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleLogout = () => {
    logout().then(() => {
      setAnchorEl(null)
      history.push('/')
    })
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showRightMenu = (showUserInfo, user) => {
    if (!showUserInfo) return null

    if (isSuccessful(status)) {
      return (
        <div className="mr-16 md:mr-0 text-red-800 flex items-center gap-3">
          <div className="hidden lg:inline text-sm text-right">
            <div className="font-bold">{user.institution_name}</div>
            <div>{user.branch_office_name}</div>
          </div>
          <NotificationsTraySocket />
          <div className="bg-red-150 rounded-lg p-4">
            <button
              className="flex items-center gap-3"
              aria-controls="header-menu"
              aria-haspopup="true"
              onClick={handleClick}
              type="button"
            >
              <UserInfo user={user} />
              <div className="bg-red-800 rounded h-5 w-5 flex">
                <ArrowDropDownIcon className="text-white h-5 w-5" />
              </div>
            </button>
          </div>
          <Message
            anchorEl={anchorEl}
            onClose={handleClose}
            className="md:mt-5 md:-ml-5 mt-7 ml-10 md:w-48 w-40"
            classes={classes}
          >
            <MenuList className="" autoFocusItem={Boolean(anchorEl)}>
              <MenuItem
                className="pt-2 pb-2"
                onClick={() => {
                  history.push('/mi-cuenta')
                }}
              >
                Mi cuenta
              </MenuItem>
              <MenuItem className="pt-2 pb-2" onClick={handleLogout}>
                Cerrar sesión
              </MenuItem>
            </MenuList>
          </Message>
        </div>
      )
    } else if (pathname === '/') {
      return (
        <Button
          el={Link}
          to="/login"
          size="s"
          variant="secondary"
          color="secondary"
          className="text-sm mr-6 md:mr-0 font-bold"
        >
          Ingresa con Gob.pe
        </Button>
      )
    } else {
      return null
    }
  }

  const selectUrl = (user, slug) => {
    if (user) return '/admin/formularios'
    if (slug) return `https://www.gob.pe/${slug}`
    return '/admin'
  }

  return (
    <header
      className={clsx(
        'bg-white py-4 border-b-2 border-solid border-neutral-300 min-h-24 flex items-center',
        isFixed && 'fixed top-0 z-50 w-full'
      )}
    >
      <Container className="md:px-4 flex justify-normal md:justify-between pl-6">
        <div className="flex w-full md:w-fit">
          <a
            className="flex mr-4 items-center w-full md:w-fit"
            href={selectUrl(user, slug)}
            aria-label={serviceName === 'Facilita Perú' ? 'Gob.pe Facilita Perú' : 'Página institucional de la entidad'}
          >
            <img
              src={EscudoFacilita}
              loading="lazy"
              className="border-r border-solid border-red-800 mr-6 pr-6 hidden md:block h-11"
              alt=""
            />
            <img src={EscudoGobpe} loading="lazy" className="block md:hidden mr-4 pr-4 h-11" alt="" />
            <p
              role="heading"
              aria-level="1"
              className="text-red-800 hidden md:flex items-center text-base leading-tight"
            >
              {serviceName}
            </p>
            <p
              role="heading"
              aria-level="1"
              className="block md:hidden text-red-800 items-center text-xs leading-tight w-full"
            >
              <span className="line-clamp-2 w-full">{user === undefined && serviceName}</span>
            </p>
          </a>
        </div>
        {showRightMenu(showUserInfo, user)}
      </Container>
    </header>
  )
}

export default Header

Header.propTypes = {
  serviceName: PropTypes.node,
  showUserInfo: PropTypes.bool,
  slug: PropTypes.string
}
