import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import EditButton from '../../molecules/buttons/EditButton'

const TermsTable = ({ terms = [] }) => {
  return (
    <div className="overflow-x-scroll md:overflow-x-auto">
      <Table
        spacing="normal"
        className="table-fixed md:w-full w-800 md:w-auto"
        values={terms}
        head={
          <Tr body={false}>
            <Th className="w-24">ID</Th>
            <Th className="w-56">Sección</Th>
            <Th>Contenido</Th>
            <Th>Idiomas</Th>
            <Th className="text-right w-24">Acciones</Th>
          </Tr>
        }
        body={terms.map(term => {
          return (
            <Tr key={term.id} className="h-20">
              <Td>{term.id}</Td>
              <Td>{term.category}</Td>
              <Td>{term.content}</Td>
              <Td>{term.languages}</Td>
              <Td className="text-right">
                <EditButton to={`/traducciones/${term.id}/editar`} />
              </Td>
            </Tr>
          )
        })}
      />
    </div>
  )
}

export default TermsTable

TermsTable.propTypes = {
  terms: PropTypes.array
}
