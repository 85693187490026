import React from 'react'

const quickSearchSteps = [
  {
    label: 'Página de consulta',
    hint: 'Configura y agrega la información necesaria para tu página de consulta.'
  },
  {
    label: 'Resultados de la consulta',
    hint: (
      <ol className="list-decimal ml-12">
        <li>Crea los resultados que tendrá tu consulta.</li>
        <li>Descarga y completa el archivo en Excel para vincular los resultados con tu base de datos.</li>
        <li>Sube el archivo en Excel.</li>
      </ol>
    )
  },
  {
    label: 'Vista previa y publicación',
    hint: 'Revisa tu página de consulta, publícala y comparte el enlace con tus usuarios.'
  }
]

const options = {
  documentType: [
    { label: 'DNI', value: 'dni', pronoun: 'el ' },
    { label: 'Pasaporte', value: 'passport', pronoun: 'el ' },
    { label: 'Carné de extranjería', value: 'inmigration_cart', pronoun: 'el ' },
    { label: 'Permiso temporal de permanencia', value: 'temporary_permision', pronoun: 'el ' },
    { label: 'Cédula de identidad', value: 'identity_card', pronoun: 'la ' },
    { label: 'Otro documento', value: 'other_document' }
  ],

  validation: [
    { label: 'Sin dato de validación', value: 'none' },
    { label: 'Fecha de nacimiento', value: 'birth_date' }
  ],

  contact: [
    {
      label: 'Quiero que los datos de contacto aparezcan en las páginas de resultados.',
      value: 'contact'
    }
  ],
  colSeparator: [
    { label: 'Coma (,)', value: 'comma' },
    { label: 'Punto y coma (;)', value: 'semicolon' }
  ]
}

const headerInfo = name => ({
  new: {
    title: 'Crear consulta rápida',
    hint: 'Crear una página de consulta rápida para tu institución.'
  },
  edit: {
    title: `Editando consulta rápida: ${name}`,
    hint: 'Editar una página de consulta rápida para tu institución.'
  }
})

const getSpreadsheetPath = validationType =>
  validationType === 'none'
    ? '/Formato de Consulta Rapida sin validacion.csv'
    : '/Formato de Consulta Rapida con validacion.csv'

const getLabelResult = index => {
  if (index === 0) return 'Respuesta para números consultados que no está en la base de datos (obligatorio)'
  const initialIndex = 64
  const letterOfIndex = String.fromCharCode(initialIndex + index)
  return `"${letterOfIndex}"`
}

const getLabelDocumentType = (documentType, otherDocumentLabel, isQuickSearchResult) => {
  if (otherDocumentLabel) return otherDocumentLabel
  const documentTypeLabel = options.documentType.find(doc => doc.value === documentType)
  return isQuickSearchResult ? `${documentTypeLabel.pronoun} ${documentTypeLabel.label}` : documentTypeLabel.label
}

const getWelcomeMessage = (citizenFullName = 'nombre del ciudadano') => {
  return `Hola, ${citizenFullName || ''}`
}

const getPageDescription = ({
  canFilterByInstitution,
  canFilterByBranchOffice,
  canFilterAndHasNotBranchOffices,
  canSeeBranchRecords
}) => {
  if (canFilterByInstitution) return 'Selecciona la entidad cuyas consultas rápidas quieres ver.'

  if (canFilterByBranchOffice) return 'Selecciona la sede cuyas consultas rápidas quieres ver.'

  if (canFilterAndHasNotBranchOffices) return 'Estas son las consultas rápidas creadas en tu institución.'

  if (canSeeBranchRecords) return 'Estas son las consultas rápidas creadas en tu sede.'

  return 'Estas son las consultas rápidas a las que tienes acceso.'
}

export {
  quickSearchSteps,
  options,
  getLabelResult,
  getLabelDocumentType,
  getWelcomeMessage,
  headerInfo,
  getSpreadsheetPath,
  getPageDescription
}
