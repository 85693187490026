import React from 'react'
import AutoSaveForm from '../../../molecules/AutoSaveForm'
import useFilters from '../../../../hooks/useFilters'
import SelectFieldFilter from '../../../molecules/filters/SelectFieldFilter'
import DateRangeField from '../../../molecules/fields/DateRangeField'
import { firstFormDate } from '../../../../configs/formsConfig'

const GeneralFilters = () => {
  const { onFilter: onFilterStatistic, initialFilters: initialFiltersStatistic } = useFilters('')

  return (
    <div className="flex-col md:flex-row flex w-full items-baseline md:gap-2 gap-4">
      <AutoSaveForm
        className="w-full flex flex-row gap-10"
        onSubmit={onFilterStatistic}
        initialValues={initialFiltersStatistic}
      >
        <div className="md:w-60 w-full">
          <SelectFieldFilter
            className="mt-3 h-12"
            label="Estado de formularios:"
            emptyLabel="Todos"
            options={[
              { id: 'published', name: 'Publicados' },
              { id: 'no_published', name: 'No publicados' }
            ]}
            name={`published`}
          />
        </div>
        <div className="md:flex flex-row items-start gap-10 mt-5 md:mt-0">
          <DateRangeField name="date" label="Fecha" labelClassName="mt-3" minimumDate={firstFormDate} isHorizontal />
        </div>
      </AutoSaveForm>
    </div>
  )
}

export default GeneralFilters

GeneralFilters.propTypes = {}
