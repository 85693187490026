import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { FieldArray, useFieldArray } from 'react-final-form-arrays'
import Error from '../../molecules/Error'
import QuestionOptionsSection from './QuestionOptionsSection'
import * as uuid from 'uuid'
import { Field, useForm } from 'react-final-form'
import useAutoScroll from '../../../hooks/useAutoScroll'
import useFieldValue from '../../../hooks/useFieldValue'
import LazyLoad from 'react-lazy-load'
import { Button } from '../../atoms/Button'
import useToggle from '../../../hooks/useToggle'

const TextWithAlternativesField = ({
  baseName,
  label,
  alternativeValidations,
  asideAlternative,
  additionalOption,
  hasAddOtherOption
}) => {
  const { mutators } = useForm()
  const fieldName = `${baseName}.payload.alternatives`
  const alternatives = useFieldValue(fieldName)
  const { fields: fieldAlternatives } = useFieldArray(fieldName, { validate: alternativeValidations })
  const containerRef = useAutoScroll(alternatives.length)
  const showHr = alternatives.length > 5
  const initialOtherValue = typeof alternatives === 'object' && alternatives?.find(el => el.type === 'other')
  const [hasOtherValue, toggleHasOtherValue] = useToggle(initialOtherValue)

  const addOption = () => {
    if (hasOtherValue) {
      fieldAlternatives.insert(alternatives.length - 1, { type: 'default' })
    } else {
      fieldAlternatives.push({ type: 'default' })
    }
  }

  const addOtherOption = () => {
    fieldAlternatives.push({ type: 'other', label: 'Otro' })
    toggleHasOtherValue()
  }

  const deleteOption = (fields, index, fieldWithOther) => {
    fields.remove(index)
    mutators.clear(`${baseName}.children_attributes.${fields?.value[index]?.id}`)
    if (fieldWithOther) {
      toggleHasOtherValue()
    }
  }

  return (
    <>
      {label ? (
        <p className="font-bold mb-6">{label}</p>
      ) : (
        <TextField
          label="Etiqueta de la pregunta"
          size="2xl"
          name={`${baseName}.payload.label`}
          validate={validations.required()}
          margin="minimun"
          errorClassName="relative inset-0"
          initialValue=""
        />
      )}
      {additionalOption}
      <QuestionOptionsSection baseName={baseName} margin="normal" size="2xl" />
      <FieldArray name={fieldName} validate={alternativeValidations}>
        {({ fields }) => (
          <div className="js-field-container">
            <fieldset>
              {asideAlternative}
              {showHr && <hr className="h-0.5 bg-neutral-400 my-4" />}
              <div className="overflow-y-auto max-h-80" ref={containerRef}>
                {fields.map((name, index) => {
                  const initialValue = fields?.value[index]?.id || uuid.v4()
                  const fieldWithOther = fields?.value[index]?.type === 'other'
                  return (
                    <div className="min-h-12" key={initialValue}>
                      <LazyLoad>
                        <div className="flex items-center sm:max-w-md mb-4">
                          <TextField
                            ariaLabel={`Alternativa ${index + 1}`}
                            name={`${name}.label`}
                            margin="minimun"
                            size="2xl"
                            rootClassName="flex-1"
                            validate={validations.mix(
                              validations.required(),
                              validations.uniqueAlternativeLabel(fieldName)
                            )}
                            disabled={fieldWithOther}
                          />
                          <Field component="input" className="hidden" name={`${name}.id`} initialValue={initialValue} />
                          <IconButton
                            aria-label={`Eliminar opción ${index + 1}`}
                            onClick={() => deleteOption(fields, index, fieldWithOther)}
                            type="button"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </LazyLoad>
                    </div>
                  )
                })}
              </div>
              {showHr && <hr className="h-0.5 bg-neutral-400 mt-3 mb-4" />}
            </fieldset>
            <div className="flex items-center">
              <IconButton
                type="button"
                color="primary"
                onClick={addOption}
                classes={{ root: '-ml-4' }}
                aria-label="Agregar alternativa"
              >
                <AddBoxIcon style={{ fontSize: 50 }} />
              </IconButton>
              <p className="flex flex-col items-start md:items-center md:flex-row">
                Añade una nueva opción
                {hasAddOtherOption && !hasOtherValue && (
                  <>
                    {' o'}
                    <Button onClick={addOtherOption} variant="text" className="p-0 md:ml-1" fullWidth={false}>
                      añade la opción &quot;Otro&quot;
                    </Button>
                  </>
                )}
              </p>
            </div>
            <div className="relative">
              <Error name={fields.name} />
            </div>
          </div>
        )}
      </FieldArray>
    </>
  )
}

export default TextWithAlternativesField

TextWithAlternativesField.propTypes = {
  alternativeValidations: PropTypes.func,
  asideAlternative: PropTypes.element,
  baseName: PropTypes.string,
  label: PropTypes.string,
  additionalOption: PropTypes.node,
  hasAddOtherOption: PropTypes.bool
}
