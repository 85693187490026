import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { Button } from '../../atoms/Button'
import * as validations from '../../../utils/validations'
import useLanguages from '../../../hooks/useLanguages'

export const TermsForm = ({ initialValues = {}, onSubmit }) => {
  const { languages } = useLanguages('es', 'enabled')
  const languageCodesInTranslations = Object.keys(initialValues.translations)
  const languagesNotInTranslations = languages?.filter(language => !languageCodesInTranslations.includes(language.code))
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} autoComplete="off">
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="md:p-8 p-6 border rounded shadow-xl md:w-130">
          <h2 className="text-2xl font-semibold mb-6">Detalles del término</h2>
          <div className="mb-4">
            <p className="font-bold">Identificador o nombre del key</p>
            <p className="mt-2">{initialValues.key}</p>
          </div>

          <div className="my-4">
            <p className="font-bold">Sección/categoría del contenido</p>
            <p className="mt-2">{initialValues.category}</p>
          </div>

          <TextField
            label="Contenido en español"
            name={`translations.es.content`}
            validate={validations.required()}
            className="mt-1 p-2 w-full rounded h-12"
            size="3xl"
          />

          <div className="py-4 border-t-2">
            <p className="font-bold">Traducciones (opcional)</p>
            <p>Si no agregas una traducción, se mostrará por defecto el contenido en español.</p>
            {Object.entries(initialValues.translations).map(
              ([code, translation]) =>
                code !== 'es' && (
                  <div key={code} className="mt-4">
                    <TextField
                      label={`Contenido en ${languages?.find(lang => lang.code === code)?.name}`}
                      name={`translations.${code}.content`}
                      className="p-2 w-full rounded h-12"
                      placeholder="Ingresa el contenido"
                      size="3xl"
                    />
                  </div>
                )
            )}

            {languagesNotInTranslations &&
              languagesNotInTranslations.map(
                (translation, index) =>
                  translation.code !== 'es' && (
                    <div key={index} className="mt-4">
                      <TextField
                        label={`Contenido en ${translation.name}`}
                        name={`translations.${translation.code}.content`}
                        className="p-2 w-full rounded h-12"
                        placeholder="Ingresa el contenido"
                        size="3xl"
                      />
                    </div>
                  )
              )}
          </div>

          <div className="flex justify-start">
            <Button
              size="full"
              type="submit"
              className="mt-0 mb-2 md:mt-auto md:w-45 h-13 lg:max-w-sm text-base md:text-lg font-bold"
            >
              Guardar
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

TermsForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}
