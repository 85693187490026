import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'
import * as validations from '../../../utils/validations'
import useFieldValue from '../../../hooks/useFieldValue'

export const PaymentMethodFormsSelectionContainer = ({ children, name, label, checkboxValue, margin }) => {
  const kindPaymentValue = useFieldValue('kind_payment')
  return (
    <Fragment>
      <CheckboxField
        name={name}
        margin={margin}
        label={label}
        value={checkboxValue}
        validate={validations.requiredArray('Ingresa al menos una opción.', 1)}
        hideError={true}
      />
      {kindPaymentValue.includes(checkboxValue) && children}
    </Fragment>
  )
}

PaymentMethodFormsSelectionContainer.propTypes = {
  checkboxValue: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string
}
