import PropTypes from 'prop-types'
import React from 'react'
import * as validations from '../../../utils/validations'
import TextField from '../../molecules/fields/TextField'
import TextWithRowsColsField from './TextWithRowsColsField'
import FieldsSeparator from '../../atoms/FieldsSeparator'

const CheckboxDoubleField = ({ baseName, question, questionTranslated }) => {
  return (
    <>
      <div className="flex items-baseline flex-wrap">
        <TextField
          label="Etiqueta de la pregunta"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validations.required()}
          margin="minimun"
          rootClassName="flex-1 min-w-48"
          hint={question.payload.label}
          placeholder="Ingresa la traducción."
        />
      </div>

      <FieldsSeparator />
      <TextWithRowsColsField question={question} baseName={baseName} questionTranslated={questionTranslated} />
    </>
  )
}

export default CheckboxDoubleField

CheckboxDoubleField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
