import PropTypes from 'prop-types'
import React from 'react'
import axios from 'axios'
import ValidateEmailOrPhoneForm from './ValidateEmailOrPhoneForm'
import { useTranslation } from 'react-i18next'

const contactMediums = {
  email: {
    title: 'form_registered_we_will_your_email_account',
    label: 'form_registered_enter_code_we_have_sent_you_to'
  },
  cellphone: {
    title: 'form_registered_we_will_your_phone_number',
    label: 'form_registered_enter_code_we_have_sent_you'
  }
}

const validateCode = (key, validationCode) =>
  axios
    .post(`/v1/validation_codes/${key}/validate.json?code=${validationCode}`, { sisoli: 'sisoli' })
    .then(res => res.data)

const CitizenValidateEmailOrPhoneForm = ({ state, send }) => {
  const answer = state.context.answer_data

  const emailPhoneApplicantResponse = answer.email_phone_applicant_response
  const contactMedium = contactMediums[emailPhoneApplicantResponse.contact_medium]
  const { t } = useTranslation(['form_registered', 'button'])
  const handleFormSubmit = async (key, validationCode) => {
    try {
      await validateCode(key, validationCode)
      send('GO_NEXT')
    } catch (err) {
      return { validation_code: err.response.data.error }
    }
  }

  return (
    <ValidateEmailOrPhoneForm
      answer={answer}
      title={`
        ${
          state.context.isLastStep
            ? t('form_registered_before_submitting_the_form')
            : t('form_registered_before_continuing')
        }${t(contactMedium.title)}
      `}
      onBack={() => send('GO_BACK')}
      textFieldLabel={t(contactMedium.label)}
      submitLabel={
        state.context.isLastStep ? t('button_send_form', { ns: 'button' }) : t('button_continue', { ns: 'button' })
      }
      onSubmit={handleFormSubmit}
      type="citizen"
    />
  )
}
export default CitizenValidateEmailOrPhoneForm

CitizenValidateEmailOrPhoneForm.propTypes = {
  send: PropTypes.func,
  state: PropTypes.object
}
