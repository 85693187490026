import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../atoms/Button'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ReactPaginate from 'react-paginate'
import clsx from 'clsx'

const Paginator = ({ forcePage, pageCount, onPageChange, className }) => {
  return (
    <ReactPaginate
      previousLabel={
        <Button variant="text" className="font-bold" startIcon={<NavigateBeforeIcon />}>
          Anterior
        </Button>
      }
      nextLabel={
        <Button variant="text" className="font-bold" endIcon={<NavigateNextIcon />}>
          Siguiente
        </Button>
      }
      breakLabel={<span className="gap">...</span>}
      pageCount={pageCount}
      forcePage={forcePage}
      onPageChange={onPageChange}
      containerClassName={clsx('pagination', className)}
      previousLinkClassName={'previous_page'}
      nextLinkClassName={'next_page'}
      disabledClassName={'disable'}
      pageClassName={'page'}
      activeClassName={'active'}
      activeLinkClassName={'active-link'}
      pageLinkClassName={'link'}
    />
  )
}

export default Paginator

Paginator.propTypes = {
  className: PropTypes.string,
  forcePage: PropTypes.number,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number
}
