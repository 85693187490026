import PropTypes from 'prop-types'
import React from 'react'
import ShortTextCitizenField from '../question_fields/ShortTextCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin } = props
  return (
    <ShortTextCitizenField
      index={index}
      name={name}
      disabled={disabled}
      margin={margin}
      onlyNumbers={question.only_numbers}
      onlyLetters={question.only_letters}
      {...question}
    />
  )
}

const ShortTextQuestion = {
  Citizen,
  name: 'ShortTextQuestion'
}

export default ShortTextQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}
