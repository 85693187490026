import { useCallback } from 'react'
import { useAuth } from '../components/molecules/Auth'

const useHasRolePermissions = () => {
  const {
    user: { permission, role }
  } = useAuth()

  const hasPermission = useCallback(
    (type, action) => {
      if (!(permission && permission[type])) {
        return false
      }

      return permission[type][action] || false
    },
    [permission]
  )

  const hasRole = useCallback(roleName => role === roleName, [role])

  return rolePermissionsList => {
    const matchAtLeastOneCondition = conditionsList =>
      conditionsList.some(rolePermission => {
        if (Array.isArray(rolePermission)) {
          const [roleName, type, action, additionalCondition = true] = rolePermission
          return hasRole(roleName) && hasPermission(type, action) && additionalCondition
        }

        if (typeof rolePermission === 'string') return hasRole(rolePermission)

        if (rolePermission && typeof rolePermission === 'object') {
          const { rolePermission: condition, additionalCondition } = rolePermission
          return matchAtLeastOneCondition([condition]) && additionalCondition
        }

        return false
      })
    return matchAtLeastOneCondition(rolePermissionsList)
  }
}
export default useHasRolePermissions
