import { FormControl } from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import { dataToOptions } from '../../../utils/helpers'
import PublishedFilter from '../filters/PublishedFilter'
import DateRangeField from '../../molecules/fields/DateRangeField'
import FormTypeFilter from '../filters/FormTypeFilter'
import PublishScheduleFilter from '../filters/PublishScheduleFilter'

const fetchCreators = () => axios.get(`/admin/v1/forms/creators.json`).then(res => res.data)

const FormsFilter = () => {
  const { data: creators, status } = useQuery(['creators'], fetchCreators, { refetchOnWindowFocus: false })
  const defaultOption = {
    label: 'Seleccionar usuario',
    value: '',
    className: 'border-b-2 border-gray-600 pb-2 w-full'
  }
  if (status !== 'success') return null
  return (
    <div className="flex gap-10 md:flex-row w-full flex-col py-6 px-0.5">
      <FormControl component="fieldset">
        <FormTypeFilter />
      </FormControl>
      <FormControl component="fieldset">
        <PublishedFilter />
      </FormControl>
      <FormControl>
        <AutocompleteField
          label="Creado por"
          options={creators ? [defaultOption, ...dataToOptions(creators)] : []}
          name="created_by"
          size="full"
          margin="none"
          renderOption={option => <span className={option.className}>{option.label}</span>}
          boxClassName="min-w-60 mt-2"
        />
      </FormControl>
      <FormControl component="fieldset" classes={{ root: 'md:mt-0' }}>
        <DateRangeField name="date" label="Fecha de creación" />
      </FormControl>
      <FormControl component="fieldset">
        <PublishScheduleFilter />
      </FormControl>
    </div>
  )
}

export default FormsFilter
