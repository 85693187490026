import PropTypes from 'prop-types'
import React from 'react'
import DateField from '../../molecules/fields/DateField'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'

const DateCitizenField = ({ index, name, required, payload, disabled = false, margin, disableFutureDate }) => {
  const translationText = useTextValidation()
  return (
    <DateField
      hint={payload?.hint}
      placeholder="DD/MM/AAAA"
      name={name}
      label={`${index}. ${payload?.label}`}
      disabled={disabled}
      validate={validations.mix(
        validations.isDate(),
        required && validations.required(translationText.required),
        disableFutureDate && validations.isNotFutureDate()
      )}
      margin={margin}
      size="2xl"
    />
  )
}

export default DateCitizenField

DateCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool,
  disableFutureDate: PropTypes.bool
}
