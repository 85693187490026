import PropTypes from 'prop-types'
import React from 'react'
import { MenuItem, Select, Chip, Input } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useField } from 'react-final-form'
import ChipDeleteIcon from 'images/chip_delete_icon.svg'
import clsx from 'clsx'
import Error from '../Error'
import { fieldWidthSizes, marginSizes } from '../../../configs/sizesConfig'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export const MultipleSelectField = ({
  name,
  validate,
  label,
  options,
  disabled,
  size = 'xl',
  margin = 'normal',
  ...props
}) => {
  const theme = useTheme()
  const { input } = useField(name, { validate, ...props })

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '20rem'
      }
    }
  }

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    }
  }

  const handleDelete = chipToDelete => () => input.onChange(input.value.filter(v => v !== chipToDelete))

  return (
    <div className={clsx(marginSizes[margin], 'relative js-field-container overflow-hidden')}>
      {label && (
        <label
          className={clsx('block font-bold mb-1')}
          id={`${name}-label`}
          htmlFor={`${name}-input-chip`}
          aria-describedby={`${name}-hint`}
        >
          {label}
        </label>
      )}
      <Select
        className={clsx('w-full block bg-white', fieldWidthSizes[size])}
        id={`${name}-input-chip`}
        labelId={`${name}-label`}
        disabled={disabled}
        multiple
        input={<Input id={`${name}-input`} classes={{ root: 'border-gray-800 border-solid border-2' }} />}
        renderValue={selected => (
          <div className="flex flex-wrap">
            {selected.map(value => (
              <Chip
                key={value}
                label={options.find(name => name.value === value)?.label}
                className="m-1 bg-neutral-200"
                onDelete={handleDelete(value)}
                classes={{ deleteIcon: 'h-3.5 w-3.5 mx-3.5', root: 'h-10' }}
                onMouseDown={event => {
                  event.stopPropagation()
                }}
                deleteIcon={<img src={ChipDeleteIcon} data-testid={`${value}-chip`} alt="chip-delete" />}
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        classes={{ selectMenu: 'pr-0 py-2' }}
        {...input}
        value={input.value || []}
        onChange={e => input.onChange(e.target.value)}
      >
        {options.map(name => (
          <MenuItem key={name.label} value={name.value} style={getStyles(name.label, options, theme)}>
            {name.label}
          </MenuItem>
        ))}
      </Select>
      <Error name={name} className="absolute -bottom-6" />
    </div>
  )
}

MultipleSelectField.propTypes = {
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  size: PropTypes.string,
  validate: PropTypes.func,
  disabled: PropTypes.bool
}
