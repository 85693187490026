import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'

const PhoneCitizenField = ({ name, index, payload, required, disabled = false, margin }) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const translationText = useTextValidation()
  return (
    <TextField
      label={label}
      name={name}
      hint={payload?.hint}
      disabled={disabled}
      validate={validations.mix(
        required && validations.required(translationText.required),
        validations.isPeruvianCellphone(translationText.isPeruvianCellphone)
      )}
      margin={margin}
      size="2xl"
    />
  )
}

export default PhoneCitizenField

PhoneCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
