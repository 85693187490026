import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const Title = ({ el: Component = 'h1', children, className, ...props }) => {
  return (
    <Component className={clsx('title', 'text-3xl md:text-4xl mb-4', className)} {...props}>
      {children}
    </Component>
  )
}

export default Title

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  el: PropTypes.string
}
