import Alert from './Alert'
import { HashLink } from 'react-router-hash-link'
import React from 'react'
import PropTypes from 'prop-types'

const PublishAlert = ({ serviceName, urlToRedirect }) => (
  <Alert className="mt-6">
    Para publicar {serviceName}, primero debes subir la política de privacidad.{' '}
    <HashLink smooth className="font-bold text-base" to={urlToRedirect}>
      Subir Política
    </HashLink>
  </Alert>
)

PublishAlert.propTypes = {
  serviceName: PropTypes.string,
  urlToRedirect: PropTypes.string
}

export default PublishAlert
