import { useQuery } from 'react-query'
import axios from 'axios'

const fetchTasks = (procedureManagementId, originUserTypeId) =>
  axios
    .get(`/admin/v1/procedure_managements/${procedureManagementId}/tasks.json`, {
      params: { origin_user_type_id: originUserTypeId }
    })
    .then(res => res.data)

const useTasks = (procedureManagementId, userTypeId) => {
  return useQuery(['tasks', procedureManagementId, userTypeId], () => fetchTasks(procedureManagementId, userTypeId), {
    refetchOnWindowFocus: false
  })
}
export default useTasks
