import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { formSteps } from '../../../../configs/procedureManagementsConfig'
import axios from 'axios'
import * as uuid from 'uuid'
import { ProcedureManagementForm } from '../../../organisms/procedure_managements/ProcedureManagementForm'

const createProcedureManagement = ({ values, config }) =>
  axios.post('/admin/v1/procedure_managements.json', values, config)

export const ProcedureManagementsNew = () => {
  const history = useHistory()
  const { mutate } = useMutation(createProcedureManagement, {
    onSuccess: procedureManagement => {
      history.push(
        `/gestiones-de-tramites/${procedureManagement.data.id}/tipos-de-usuarios-tareas-conexiones?action=new`
      )
    }
  })

  const initialValues = useMemo(
    () => ({
      steps_attributes: [{ questions_attributes: [{ key: uuid.v4() }] }]
    }),
    []
  )
  return <ProcedureManagementForm initialValues={initialValues} mutate={mutate} formSteps={formSteps} create />
}
