import React from 'react'
import FieldsSeparator from '../../../atoms/FieldsSeparator'
import Container from '../../../atoms/Container'
import Title from '../../../atoms/Title'
import DescriptionCarousel from '../../../molecules/DescriptionCarousel'
import img1 from '../../../../images/procedure_managements/what_is/img_1.png'
import img2 from '../../../../images/procedure_managements/what_is/img_2.png'
import img3 from '../../../../images/procedure_managements/what_is/img_3.png'
import img4 from '../../../../images/procedure_managements/what_is/img_4.png'
import img5 from '../../../../images/procedure_managements/what_is/img_5.png'
import img6 from '../../../../images/procedure_managements/what_is/img_6.png'
import imgMobile1 from '../../../../images/procedure_managements/what_is/img_mobile_1.png'
import imgMobile2 from '../../../../images/procedure_managements/what_is/img_mobile_2.png'
import imgMobile3 from '../../../../images/procedure_managements/what_is/img_mobile_3.png'
import imgMobile4 from '../../../../images/procedure_managements/what_is/img_mobile_4.png'
import imgMobile5 from '../../../../images/procedure_managements/what_is/img_mobile_5.png'
import imgMobile6 from '../../../../images/procedure_managements/what_is/img_mobile_6.png'

const step1ImageAltText =
  'Imagen con un ejemplo del paso 1 de la herramienta de gestión de trámites, donde se presentan los campos que la ciudadanía debe completar para hacer una solicitud de acceso a la información pública.'
const step2ImageAltText =
  'Imagen con un ejemplo del paso 2 de la herramienta de gestión de trámites, donde el personal de la entidad encuentra el listado de todas las solicitudes ingresadas por la ciudadanía y el estado de cada una.'
const step3ImageAltText =
  'Imagen con un ejemplo del paso 3 de la herramienta de gestión de trámites, donde el personal de la entidad encuentra el detalle de la solicitud ingresada y cuenta con un campo para pedir una subsanación a la persona solicitante.'
const step4ImageAltText =
  'Imagen con un ejemplo del paso 4 de la herramienta de gestión de trámites, donde la persona solicitante puede revisar las observaciones enviadas por la entidad y cuenta con campos para ingresar la subsanación.'
const step5ImageAltText =
  'Imagen con un ejemplo del paso 5 de la herramienta de gestión de trámites, donde el personal de la entidad encuentra el detalle de la subsanación y cuenta con un campo para enviar la respuesta final a la persona solicitante.'
const step6ImageAltText =
  'Imagen con un ejemplo del paso 6 de la herramienta de gestión de trámites, donde la persona solicitante puede revisar la respuesta final de la entidad y cuenta con el detalle e historial de su requerimiento.'

const steps = [
  {
    hint: (
      <>
        <span className="font-bold">Ciudadano</span> envía formulario de solicitud de acceso a información pública
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img1} alt={step1ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile1} alt={step1ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <span className="font-bold">Funcionario público </span> recibe solicitud de acceso a información pública
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img2} alt={step2ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile2} alt={step2ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <span className="font-bold">Funcionario público </span> revisa solicitud y envía un pedido de subsanación al
        ciudadano.
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img3} alt={step3ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile3} alt={step3ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <span className="font-bold">Ciudadano </span> recibe pedido de subsanación y envía correcciones al funcionario
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img4} alt={step4ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile4} alt={step4ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <span className="font-bold">Funcionario público </span> revisa correcciones y envía información requerida por el
        ciudadano.
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img5} alt={step5ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile5} alt={step5ImageAltText} />
      </>
    )
  },
  {
    hint: (
      <>
        <span className="font-bold">Ciudadano </span> es notificado sobre la atención satisfactoria de su trámite.
      </>
    ),
    page: (
      <>
        <img className="hidden md:block w-full" src={img6} alt={step6ImageAltText} />
        <img className="md:hidden w-full" src={imgMobile6} alt={step6ImageAltText} />
      </>
    )
  }
]

const ProcedureManagementsWhats = () => {
  return (
    <>
      <Container className="mt-12 px-4 md:px-0">
        <Title aria-describedby="page-description">¿Qué es una gestión de trámite?</Title>
        <p id="page-description" className="mb-6">
          Es una herramienta que permite la administración digital de un servicio o trámite. Al crearla, tu institución
          podrá atender los trámites de forma virtual de inicio a fin, desde que el ciudadano hace la solicitud hasta
          que el funcionario realiza la entrega final del requerimiento.
        </p>
        <FieldsSeparator />
        <p className="mb-8">
          <strong className="font-bold">
            En el ejemplo, se presenta la vista de la herramienta de gestión de trámites para una solicitud de acceso a
            la información pública:
          </strong>
        </p>
      </Container>
      <DescriptionCarousel steps={steps} />
    </>
  )
}
export default ProcedureManagementsWhats
