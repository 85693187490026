import PropTypes from 'prop-types'
import React from 'react'
import SubQuestionNews from './SubQuestionNews'
import useFieldValue from '../../../hooks/useFieldValue'
import SubQuestionsFromTemplate from './SubQuestionsFromTemplate'
import { createdFromTemplate } from '../../../configs/questionTypesAdminConfig'

const SubQuestions = props => {
  const parentQuestion = useFieldValue(props.name)
  const El = createdFromTemplate(parentQuestion) ? SubQuestionsFromTemplate : SubQuestionNews
  return <El {...props} />
}
export default SubQuestions

SubQuestions.propTypes = {
  name: PropTypes.string
}
