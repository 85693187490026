import React from 'react'
import PageHeading from '../../../molecules/PageHeading'
import TutorialCategoriesList from '../../../organisms/onboarding/TutorialCategoriesList'
import UpgradeList from '../../../organisms/onboarding/UpgradeList'

const Help = () => {
  return (
    <>
      <PageHeading
        title={<span className="flex items-center">Centro de ayuda</span>}
        pageDescription="Descubre qué puedes hacer y qué hay de nuevo en Facilita."
      />
      <TutorialCategoriesList className="mb-6 -mt-4" />
      <UpgradeList />
    </>
  )
}
export default Help
