import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { useTranslation } from 'react-i18next'
import * as validations from '../../../utils/validations'

export const FullNameField = ({ baseName, question, questionTranslated }) => {
  const { t } = useTranslation('form')

  const renderTextField = (name, hint, initialValue) => (
    <Field name={name}>
      {({ input }) => {
        if (input.value === '' && name !== `${baseName}.payload.label`) {
          input.onChange(initialValue)
        }
        const isDisabled = name !== `${baseName}.payload.label`

        return (
          <TextField
            label="Etiqueta de la pregunta"
            hint={hint}
            {...input}
            margin="none"
            disabled={isDisabled}
            size="full"
            className="mb-4"
            placeholder="Ingresa la traducción"
            validate={validations.required()}
          />
        )
      }}
    </Field>
  )

  return (
    <div className="grid md:grid gap-4">
      <div>
        {renderTextField(`${baseName}.payload.label`, question.payload.label, questionTranslated?.payload?.label || '')}
        {renderTextField(`${baseName}.payload.name_label`, 'Nombres', t('form_label_names') || 'Nombres')}
        {renderTextField(
          `${baseName}.payload.father_last_name_label`,
          'Apellido paterno',
          t('form_label_father_last_name') || 'Apellido paterno'
        )}
        {renderTextField(
          `${baseName}.payload.mother_last_name_label`,
          'Apellido materno',
          t('form_label_mother_last_name') || 'Apellido materno'
        )}
      </div>
    </div>
  )
}

FullNameField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
