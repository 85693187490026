import { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useParams } from 'react-router-dom'

export const useFetchFormDataTranslations = () => {
  const location = useLocation()
  const { id } = useParams()
  const isProcedureManagement = location.pathname.includes('gestiones-de-tramites')
  const [data, setData] = useState(null)
  const [selectedLocale, setSelectedLocale] = useState('')
  const [createUrl, setCreateUrl] = useState('')
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    show: false,
    locale: '',
    name: ''
  })

  const editUrl = isProcedureManagement
    ? `/gestiones-de-tramites/${id}/formulario/${data?.id}/editar-traduccion`
    : `/formularios/${id}/editar-traduccion`

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = isProcedureManagement ? `/admin/v1/procedure_managements/${id}.json` : `/admin/v1/forms/${id}.json`

      try {
        const response = await axios.get(apiUrl)
        const fetchedData = isProcedureManagement ? response.data.form : response.data
        setData(fetchedData)
      } catch (error) {
        alert(error)
      }
    }

    fetchData()
  }, [id, isProcedureManagement])

  useEffect(() => {
    if (selectedLocale && data) {
      const newUrl = isProcedureManagement
        ? `/gestiones-de-tramites/${id}/formulario/${data.id}/crear-traduccion?locale=${selectedLocale}`
        : `/formularios/${id}/crear-traduccion?locale=${selectedLocale}`
      setCreateUrl(newUrl)
    } else {
      setCreateUrl('')
    }
  }, [selectedLocale, id, data, isProcedureManagement])

  return {
    id,
    data,
    setData,
    selectedLocale,
    setSelectedLocale,
    createUrl,
    editUrl,
    deleteConfirmation,
    setDeleteConfirmation,
    isProcedureManagement
  }
}
