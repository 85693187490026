import PropTypes from 'prop-types'
import { Dialog as DialogUI, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import CloseButton from '../buttons/CloseButton'

const Dialog = ({
  renderTitle,
  renderContent,
  renderActions,
  onClose,
  maxWidth,
  dialogClasses,
  dialogTitleClasses,
  dialogContentClasses,
  dialogActionClasses,
  buttonClose = true
}) => {
  return (
    <DialogUI
      aria-labelledby="dialog-title"
      open
      onClose={onClose}
      maxWidth={maxWidth}
      classes={dialogClasses}
      fullWidth
    >
      <DialogTitle className="pb-0 md:px-9 px-6 md:pt-9 pt-6" classes={dialogTitleClasses}>
        {buttonClose && (
          <div className="text-right">
            <CloseButton onClose={onClose} />
          </div>
        )}
        {renderTitle}
      </DialogTitle>
      <DialogContent className="max-h-96 md:max-h-full md:px-9 px-6 pt-0 pb-4" classes={dialogContentClasses}>
        {renderContent}
      </DialogContent>
      <DialogActions classes={dialogActionClasses} className="pb-9 px-9">
        {renderActions}
      </DialogActions>
    </DialogUI>
  )
}

export default Dialog

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  renderActions: PropTypes.element,
  renderContent: PropTypes.element,
  renderTitle: PropTypes.string,
  maxWidth: PropTypes.string,
  dialogClasses: PropTypes.object,
  dialogTitleClasses: PropTypes.object,
  dialogContentClasses: PropTypes.object,
  dialogActionClasses: PropTypes.object,
  buttonClose: PropTypes.bool
}
