import PropTypes from 'prop-types'
import React from 'react'
import Title from '../../atoms/Title'
import { Button } from '../../atoms/Button'
import SelectField from '../../molecules/fields/SelectField'
import { Field, Form } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import { dataToOptions } from '../../../utils/helpers'
import useInstitution from '../../../hooks/useInstitution'
import { useHistory } from 'react-router-dom'
import useBranchOffice from '../../../hooks/useBranchOffice'
import { isSuccessful } from '../../../hooks/useQuery'
import { required } from '../../../utils/validations'
import OpenInNew from '@material-ui/icons/OpenInNew'

const MigrateBranchOffice = ({ institutionId }) => {
  const { getBranchOffices } = useBranchOffice(institutionId)
  const { data: branchOffices, status } = getBranchOffices()
  const { push } = useHistory()
  const primaryBranches = branchOffices?.filter(branchOffice => branchOffice.primary_branch === true)
  const branchOfficeId = primaryBranches?.length === 1 ? primaryBranches[0].id : ''
  const { migrateRecordsToBranchOffice } = useInstitution()

  const handleFormSubmit = values =>
    migrateRecordsToBranchOffice(values).then(() => {
      const branchOfficeMigrated = branchOffices.find(
        branchOffice => branchOffice.id === parseInt(values.branch_office_id)
      )
      push(`/formularios?migrated=${branchOfficeMigrated.name}`)
    })

  if (!isSuccessful(status)) return null

  return (
    <div className="md:flex md:flex-col gap-12 items-center container mx-auto px-0 md:px-4">
      <div className="pt-16 w-full md:flex md:flex-col px-4 text-left md:text-center mb-6 md:mb-0">
        <Title aria-describedby="page-description">Llegaron las Sedes a Facilita</Title>
        <p id="page-description">Ahora podrás gestionar tus servicios diferenciados por cada sede de tu entidad.</p>
      </div>
      <div className="flex flex-col justify-center items-center px-6 md:px-16 py-6 md:py-10 gap-10 bg-neutral-100 md:w-full mx-4 md:mx-0 mb-6 md:mb-0">
        <div className=" text-center">
          <p className="text-xl md:text-2xl font-bold mb-2">
            Antes de seguir usando Facilita, debes seleccionar a qué sede se asignarán los
            <br /> usuarios, áreas y servicios que existen actualmente.
          </p>
          <p>Descuida, después podrás modificar la asignación de los usuarios de forma individual.</p>
        </div>
        <Form
          onSubmit={handleFormSubmit}
          decorators={[focusOnFirstError]}
          initialValues={{ branch_office_id: branchOfficeId }}
          subscription={{ submitting: true }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="flex flex-col justify-center">
              <Field component="input" className="hidden" name="id" initialValue={institutionId} />
              <SelectField
                name="branch_office_id"
                size="2xl"
                options={dataToOptions(branchOffices)}
                containerClassName="mx-auto min-w-80 mb-10"
                validate={required()}
                emptyLabel="Selecciona una sede"
              />
              <Button type="submit" size="full" disabled={submitting}>
                Asignar usuarios, áreas y servicios a esta sede
              </Button>
            </form>
          )}
        </Form>
      </div>
      <div className="text-center">
        <p>¿No existe la sede donde quieres asignar?</p>
        <Button size="l" variant="text" el="a" target="_blank" href={`${window.sisoli.gobpe_url}/admin2/sedes/new`}>
          <span className="font-bold">
            Crear nueva sede en Gob.pe <OpenInNew className="text-lg" />{' '}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default MigrateBranchOffice

MigrateBranchOffice.propTypes = {
  institutionId: PropTypes.number
}
