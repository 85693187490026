import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { numberFormatter } from '../../../utils/helpers'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'

const SpecialTaskFormEnablePayment = ({ payment_methods: paymentMethods, cost }) => {
  return (
    <div className="bg-neutral-200 p-6 mb-6">
      <Field
        component="input"
        className="hidden"
        name="special_task_data.payment_methods"
        initialValue={paymentMethods}
      />
      {cost ? (
        <div>
          Se le solicitará al ciudadano realizar un pago de{' '}
          <span className="font-bold">{numberFormatter.currency(cost)}</span>
          <Field component="input" className="hidden" name="special_task_data.cost" initialValue={cost} />
        </div>
      ) : (
        <TextField
          leftDecorator={<span className="mr-2">S/ </span>}
          name="special_task_data.cost"
          size="md"
          label="Indica el monto que debe pagar el ciudadano"
          validate={validations.required()}
          margin="none"
        />
      )}
    </div>
  )
}
export default SpecialTaskFormEnablePayment

SpecialTaskFormEnablePayment.propTypes = {
  cost: PropTypes.number,
  payment_methods: PropTypes.object
}
