import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import ReportProblem from '@material-ui/icons/ReportProblem'
import { Button } from '../../atoms/Button'
import React, { useState } from 'react'

const ErrorDialog = ({ children }) => {
  const [open, setOpen] = useState(true)
  return (
    <Dialog open={open} classes={{ paper: 'rounded-lg bg-red-200 p-6' }} maxWidth="md" fullWidth>
      <DialogTitle>
        <ReportProblem className="text-red-750" />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="text" size="" fullWidth={false} onClick={() => setOpen(false)}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ErrorDialog

ErrorDialog.propTypes = {
  children: PropTypes.node
}
