import React from 'react'
import PropTypes from 'prop-types'
import Toggletip from '../../molecules/Toggletip'

const UserTableAllowed = ({ user }) => {
  const hasAllowedForms = user?.allowed_forms.length
  const hasAllowedQuickSearches = user?.allowed_quick_searches.length

  if (!(hasAllowedForms || hasAllowedQuickSearches)) return 'Ninguno'

  return (
    <>
      {hasAllowedForms ? (
        <div className="flex">
          <span className="font-medium">Formularios ({user?.allowed_forms.length})</span>
          <Toggletip
            iconClassName="text-gray-700"
            buttonLabel="Ver formularios"
            toggletipText={
              <div className="p-1">
                {user?.allowed_forms?.map(form => (
                  <a className="block text-sm my-1" href={form.url} target="_blank" key={form.url} rel="noreferrer">
                    {form.url}
                  </a>
                ))}
              </div>
            }
          />
        </div>
      ) : null}
      {hasAllowedQuickSearches ? (
        <div className="flex">
          <span className="font-medium">Consultas rápidas ({user?.allowed_quick_searches.length})</span>
          <Toggletip
            iconClassName="text-gray-700"
            buttonLabel="Ver consultas rápidas"
            toggletipText={
              <div className="p-1">
                {user?.allowed_quick_searches?.map(quickSearch => (
                  <a
                    className="block text-sm my-1"
                    href={quickSearch.url}
                    target="_blank"
                    key={quickSearch.url}
                    rel="noreferrer"
                  >
                    {quickSearch.url}
                  </a>
                ))}
              </div>
            }
          />
        </div>
      ) : null}
    </>
  )
}

export default UserTableAllowed

UserTableAllowed.propTypes = {
  user: PropTypes.object
}
