import PropTypes from 'prop-types'
import React from 'react'
import { FormControl, FormLabel } from '@material-ui/core'
import CheckboxField from '../../molecules/fields/CheckboxField'

const PublishFilter = ({ suffix = '' }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel className="font-bold mr-4 mb-1.5 inline-block text-gray-800 text-base">Estado</FormLabel>
      <CheckboxField name={`status${suffix}`} label="Publicado" value="published" margin="none" />
      <CheckboxField name={`status${suffix}`} label="No publicado" value="no_published" margin="none" />
    </FormControl>
  )
}

export default PublishFilter

PublishFilter.propTypes = {
  suffix: PropTypes.string
}
