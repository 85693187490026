import PropTypes from 'prop-types'
import React from 'react'
import { TabPanel as TabPanelMUI } from '@material-ui/lab'

export const TabPanel = ({ value, children }) => <TabPanelMUI value={value}>{children}</TabPanelMUI>

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
