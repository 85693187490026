import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { Button } from '../../atoms/Button'
import CloseIcon from '@material-ui/icons/Close'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import { PaymentAmountTag } from '../../molecules/PaymentAmountTag'

const SpecialCodesModal = ({ onClose }) => {
  return (
    <Dialog open classes={{ paper: 'rounded-md' }} maxWidth="sm" onClose={onClose}>
      <div className="flex justify-end mr-3 mt-3">
        <Button endIcon={<CloseIcon />} variant="text" type="button" fullWidth={false} onClick={onClose}>
          Cerrar
        </Button>
      </div>
      <DialogTitle classes={{ root: 'pb-3 pt-2' }}>
        <span className="font-bold">Códigos especiales de dinero</span>
      </DialogTitle>
      <DialogContent classes={{ root: 'pt-0' }}>
        <p className="mb-8">
          Utiliza estos comandos cuando requieras colocar montos de dinero. Puedes escribir el monto directamente, con
          las variables del excel ($1, $2, etc.), o en porcentajes de una Remuneración Mínima Vital (RMV) o una Unidad
          Impositiva Tributaria (UIT). Estos montos se actualizarán automáticamente conforme sean dispuestos por el
          Estado.
        </p>
        <div className="mb-6">
          <span className="font-bold mb-6 flex">Ejemplo de uso</span>
          <div className="flex flex-row justify-between font-bold">
            <span>Código</span>
            <span>Resultado visual</span>
          </div>
          <FieldsSeparator className="my-4" />
          <div className="flex flex-row justify-between items-center">
            <span>
              {'{costo: $1}'}
              <br className="mb-1" />
              {'{costo: 24.5}'}
            </span>
            <span className="bg-neutral-200 p-2">
              <PaymentAmountTag amount={24.5} />
            </span>
          </div>
          <FieldsSeparator className="my-4" />
          <div className="flex flex-row justify-between items-center">
            <span>{'{costo: 2%*RMV}'}</span>
            <span className="bg-neutral-200 p-2">
              <PaymentAmountTag amount={18.6} />
            </span>
          </div>
          <FieldsSeparator className="my-4" />
          <div className="flex flex-row justify-between items-center">
            <span>{'{costo: 1*UIT}'}</span>
            <span className="bg-neutral-200 p-2">
              <PaymentAmountTag amount={4200.0} />
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SpecialCodesModal

SpecialCodesModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
