import { objectToOptions } from '../utils/helpers'

const personTypesConfig = {
  natural: {
    name: 'Persona natural',
    defaultDocType: 'dni'
  },
  juridica: {
    name: 'Persona jurídica',
    defaultDocType: 'ruc_juridico'
  },
  'natural-juridica': {
    name: 'Persona natural o jurídica',
    defaultDocType: 'dni'
  }
}

const personTypeOptions = objectToOptions(personTypesConfig)

const getDefaultDocType = personType => personTypesConfig[personType]?.defaultDocType || 'dni'

const isJuridica = personType => personType === 'juridica'

export { personTypeOptions, getDefaultDocType, isJuridica }
