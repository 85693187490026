import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import useUserPermission from '../../../hooks/useUserPermission'
import useUser from '../../../hooks/useUser'
import { useHistory } from 'react-router-dom'
import DialogErrorMessage from './DialogErrorMessage'
import { Button } from '../../atoms/Button'
import ConfirmDialog from '../../molecules/dialogs/ConfirmDialog'

const DeleteUser = ({ user }) => {
  const history = useHistory()
  const { canDelete } = useUserPermission('user')
  const { deleteUser, errorDeleteUser, invalidateUsers } = useUser()
  const [paramsDeleteDialog, setParamsDeleteDialog] = useState()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleDestroy = async user => {
    deleteUser(user.id, {
      onSuccess: () => {
        history.push(`/areas-y-usuarios?action=user_deleted`)
        invalidateUsers(['users', user.id])
      }
    })
  }

  useEffect(() => errorDeleteUser && setParamsDeleteDialog(errorDeleteUser.dialog[0]), [errorDeleteUser])

  return (
    <>
      {canDelete(user.institution_id, user.id) && (
        <Button variant="text" className="flex items-center" size="xs" onClick={() => setOpenDeleteDialog(true)}>
          <DeleteIcon className="mr-2" />
          Eliminar
        </Button>
      )}
      <ConfirmDialog
        primaryConfirmBtn
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={`¿Estás seguro de que quieres eliminar la cuenta del usuario ${user.email}`}
        onConfirm={() => handleDestroy(user)}
        confirmLabel="Sí, eliminar"
        rightButtonProps={{
          className: 'w-full md:max-w-40'
        }}
      >
        <p>
          Si eliminas la cuenta del usuario, este ya no tendrá acceso a Facilita, pero seguirá activo en la plataforma
          Gob.pe.
        </p>
      </ConfirmDialog>
      <DialogErrorMessage onClose={() => setParamsDeleteDialog()} params={paramsDeleteDialog} />
    </>
  )
}

export default DeleteUser

DeleteUser.propTypes = {
  user: PropTypes.object
}
