import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from '../../atoms/Tooltip'
import BaseIconButton from '../../atoms/BaseIconButton'
import { webhooksPropsMap } from '../../../hooks/useAnswerToWebhook'

const WebhookResendButton = ({ onResend, status }) => {
  return (
    <Tooltip title={webhooksPropsMap[status].title}>
      <BaseIconButton onClick={onResend} color="primary" size="small">
        {webhooksPropsMap[status].icon}
      </BaseIconButton>
    </Tooltip>
  )
}

export default WebhookResendButton

WebhookResendButton.propTypes = {
  onResend: PropTypes.func.isRequired,
  status: PropTypes.string
}
