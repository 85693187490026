import React, { Fragment } from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import PropTypes from 'prop-types'
import QuestionsRenderer from './QuestionsRenderer'

const StepsAndQuestions = ({ formData, formDataInSpanish, form }) => {
  if (!formData || !formData.steps_attributes || !formDataInSpanish) {
    return null
  }

  return formDataInSpanish?.steps_attributes.map((step, stepIndex) => {
    const stepTranslated = formData?.steps_attributes[stepIndex]
    const shouldDisplayStepSection = formDataInSpanish?.steps_attributes.length > 1

    return (
      <Fragment key={step.id}>
        {shouldDisplayStepSection && (
          <>
            <div className="justify-between items-center text-white font-bold bg-neutral-500 p-4">
              <div className="text-xl md:text-2xl">Página {stepIndex + 1}</div>
            </div>

            <div className="bg-neutral-300 p-6 mb-6">
              <TextField
                name={`steps_attributes[${stepIndex}].name`}
                initialValue={stepTranslated?.name || ''}
                hint={step?.name}
                label="Nombre de la página"
                margin="none"
                size="full"
                placeholder="Ingresa la traducción."
                validate={validations.required()}
              />
            </div>
          </>
        )}

        {step.questions_attributes.map((question, questionIndex) => {
          return (
            <QuestionsRenderer
              key={question.id}
              step={step}
              stepTranslated={stepTranslated}
              question={question}
              questionIndex={questionIndex}
              stepIndex={stepIndex}
              form={form}
              formDataInSpanish={formDataInSpanish}
            />
          )
        })}
      </Fragment>
    )
  })
}
export default StepsAndQuestions
StepsAndQuestions.propTypes = {
  formData: PropTypes.object,
  formDataInSpanish: PropTypes.object,
  form: PropTypes.shape({
    getState: PropTypes.func.isRequired
  })
}
