import React from 'react'
import PropTypes from 'prop-types'
import BorderFooterImage from '../../../images/frame_footer.svg'

const FooterWrapper = ({ children }) => {
  return (
    <footer className="bg-gray-800">
      <div className="h-7" style={{ backgroundImage: `url(${BorderFooterImage})` }} />
      {children}
      <div className="h-7" style={{ backgroundImage: `url(${BorderFooterImage})` }} />
    </footer>
  )
}

export default FooterWrapper

FooterWrapper.propTypes = {
  children: PropTypes.node
}
