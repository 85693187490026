import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useSubscription from '../../../hooks/useSubscription'
import { GeneralError, Processing, Error } from './StatesModal'

const BulkUpload = ({ action, quickSearchId }) => {
  const { streamData: upload, channel } = useSubscription('BulkLoadChannel', quickSearchId)
  const history = useHistory()
  const onCancel = () => channel.send({ content: 'stop', quick_search_id: quickSearchId })

  useEffect(() => {
    if (upload?.state === 'success') {
      channel.unsubscribe()
      history.push(`/consultas-rapidas/${quickSearchId}/vista-previa?action=${action}`)
    }
  }, [upload?.state])

  const states = {
    processing: <Processing onCancel={onCancel} upload={upload} />,
    error: <Error upload={upload} />,
    general_error: <GeneralError />,
    success: null,
    stop: () => null
  }

  return upload ? states[upload.state] : null
}

export default BulkUpload

BulkUpload.propTypes = {
  action: PropTypes.string,
  quickSearchId: PropTypes.string.isRequired
}
