import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'
import { useAuth } from '../../components/molecules/Auth'

const { admin, coordinator, branch_coordinator: branchCoordinator, collaborator } = roles

const ProcedureManagement = () => {
  const { user } = useAuth()

  const hasRolePermissions = useHasRolePermissions()

  const canSeeAllEnabledProcedureManagements = hasRolePermissions([admin])

  const canCreateOrEditProcedureManagements = hasRolePermissions([admin])

  const canManageEnabledProcedureManagements = hasRolePermissions([coordinator, branchCoordinator])

  const canManageProcedures = hasRolePermissions([collaborator])

  const canChoseDestinyBranchOfficeType =
    hasRolePermissions([coordinator]) &&
    user.institution_has_branch_offices &&
    user.institution_primary_branch?.id === user.branch_office_id

  const canCreateEnabledProcedureManagements =
    (hasRolePermissions([coordinator]) &&
      (!user.institution_has_branch_offices || user.institution_primary_branch?.id === user.branch_office_id)) ||
    hasRolePermissions([branchCoordinator])

  return {
    canSeeAllEnabledProcedureManagements,
    canManageEnabledProcedureManagements,
    canChoseDestinyBranchOfficeType,
    canCreateEnabledProcedureManagements,
    canCreateOrEditProcedureManagements,
    canManageProcedures
  }
}

export default ProcedureManagement
