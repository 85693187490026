import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import { useQuery } from 'react-query'
import axios from 'axios'
import { QuestionsAndSubQuestionsContext } from '../../../utils/contexts'

const fetchInstitutions = id => axios.get(`/v1/procedure_managements/${id}/institutions.json`).then(res => res.data)

const ExternalInstitutionCitizenField = ({ index, payload, required, name, disabled, margin }) => {
  const { procedureManagementId } = useContext(QuestionsAndSubQuestionsContext)
  const { data: institutions, status } = useQuery(
    ['enabled_institutions', procedureManagementId],
    () => fetchInstitutions(procedureManagementId),
    {
      refetchOnWindowFocus: false
    }
  )
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  if (status !== 'success') return null
  const options = institutions.map(institution => ({ value: institution.id, label: institution.name }))

  return (
    <SelectField
      name={name}
      label={label}
      className="block"
      hint={payload?.hint}
      options={options}
      disabled={disabled}
      validate={required && validations.required()}
      containerClassName={margin}
      size="2xl"
    />
  )
}
export default ExternalInstitutionCitizenField

ExternalInstitutionCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
