import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import AutoSaveForm from '../../molecules/AutoSaveForm'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import { dataToOptions } from '../../../utils/helpers'
import useInstitution, { pcmId } from '../../../hooks/useInstitution'
import { isSuccessful } from '../../../hooks/useQuery'
import useSearchParams from '../../../hooks/useSearchParams'
import useFilters from '../../../hooks/useFilters'

const InstitutionFilter = ({ label }) => {
  const searchParams = useSearchParams()
  const defaultFilter = {
    institution_id: pcmId.toString()
  }
  const { getInstitutions } = useInstitution()
  const { data: institutions, status } = getInstitutions()
  const { onFilter, initialFilters } = useFilters('', defaultFilter)

  useEffect(() => {
    if (!searchParams.has('institution_id')) onFilter(defaultFilter, false)
  }, [])

  return (
    isSuccessful(status) && (
      <AutoSaveForm className="md:w-auto w-full" onSubmit={onFilter} initialValues={initialFilters}>
        <label htmlFor={`institution_id-input`} id={`institution_id-label`} className="block mb-1 min-w-25">
          {label}
        </label>
        <AutocompleteField options={dataToOptions(institutions)} name="institution_id" size="2xl" margin="none" />
      </AutoSaveForm>
    )
  )
}

export default InstitutionFilter

InstitutionFilter.propTypes = {
  label: PropTypes.string
}
