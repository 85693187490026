import React, { Fragment } from 'react'
import CheckboxField from './fields/CheckboxField'
import WhenFieldChanges from './WhenFieldChanges'

const FilterQuestion = () => (
  <Fragment>
    <div className="mb-6 md:mb-0">
      <CheckboxField
        name="has_filter_question"
        margin="none"
        label="Después de crear las preguntas de tu formulario, selecciona una de ellas para que se considere como un filtro en el gráfico estadístico de un tema nacional."
      />
      <WhenFieldChanges field="has_filter_question" set="filter_question" to={null} />
    </div>
  </Fragment>
)

export default FilterQuestion
