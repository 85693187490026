import useCrud from '../useCrud'
import { urlBaseForm } from '../useForm'
import { urlBaseQuickSearch } from '../useQuickSearch'

const usePin = id => {
  const { create: togglePinForm } = useCrud(`${urlBaseForm}/${id}/pin`)

  const { create: togglePinQuickSearch } = useCrud(`${urlBaseQuickSearch}/${id}/pin`)

  return {
    togglePinForm,
    togglePinQuickSearch
  }
}
export default usePin
