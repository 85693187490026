import PropTypes from 'prop-types'
import React from 'react'
import SelectField from '../../../molecules/fields/SelectField'
import TextField from '../../../molecules/fields/TextField'
import useFieldValue from '../../../../hooks/useFieldValue'
import * as validations from '../../../../utils/validations'
import WhenFieldChanges from '../../../molecules/WhenFieldChanges'

const SubType = ({ config }) => {
  const subTypeValue = useFieldValue('subtype')
  const options = [
    { value: 'procedure', label: 'Trámites' },
    { value: 'event', label: 'Eventos' },
    { value: 'survey', label: 'Encuestas' },
    { value: 'other', label: 'Otros' }
  ]

  return (
    <>
      <SelectField
        size="2xl"
        label="Tipo de formulario simple"
        hint="Es el tipo de formulario que se creará"
        name="subtype"
        containerClassName="min-w-80 mt-2 md:mt-0"
        options={options}
        margin={subTypeValue === 'other' ? 'normal' : 'none'}
        emptyLabel="Selecciona tipo de formulario simple"
        validate={validations.required()}
        disabled={config.from_template}
      />
      <WhenFieldChanges field={'subtype'} set={`subtype_other`} to={null} />
      {subTypeValue === 'other' && (
        <TextField
          name="subtype_other"
          hint={'Indica de qué tipo es tu formulario. Ejemplo: Contacto, evaluación, etc.'}
          margin="none"
          size="2xl"
          validate={validations.mix(validations.required(), validations.maxLength(50), validations.maxWords(3))}
        />
      )}
    </>
  )
}

export default SubType

SubType.propTypes = {
  config: PropTypes.object
}
