import React from 'react'
import Container from '../../../atoms/Container'
import PageHeading from '../../../molecules/PageHeading'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { useLocation, useParams } from 'react-router-dom'
import FormsFormTranslation from '../../../organisms/forms/FormsFormTranslation'
import FormTranslationInstructions from '../../../organisms/forms/FormTranslationInstructions'
import i18n from 'i18next'
import { useFormTranslation } from '../../../../hooks/useFormTranslation'
import useInitialFormTranslationValues from '../../../../hooks/useInitialFormTranslationValues'
import PropTypes from 'prop-types'
import useLanguageSwitcher from '../../../../hooks/useLanguageSwitcher'
import { useFetchDataTranslations } from '../../../../hooks/useFetchDataTranslations'
import useLanguages from '../../../../hooks/useLanguages'

const FormTranslationComponent = ({ isNew }) => {
  const location = useLocation()
  const isProcedureManagement = location.pathname.includes('gestiones-de-tramites')
  const { id: formId, procedureManagementId } = useParams()
  useLanguageSwitcher()
  const currentLanguage = i18n.language || 'es'
  const { handleSubmit } = useFormTranslation(formId)
  const { language } = useLanguages(currentLanguage)

  const { formDataQuery, formDataInSpanishQuery, procedureManagementDataQuery } = useFetchDataTranslations({
    formId,
    procedureManagementId,
    isNew,
    currentLanguage,
    isProcedureManagement
  })

  const formData = formDataQuery?.data
  const formDataInSpanish = formDataInSpanishQuery?.data

  const initialValues = useInitialFormTranslationValues(
    isNew ? formData : formDataInSpanish,
    isNew ? undefined : formData
  )

  const dataBreadcrumb = isProcedureManagement ? procedureManagementDataQuery?.data : formData

  if (formDataQuery.isLoading || formDataInSpanishQuery.isLoading || procedureManagementDataQuery.isLoading) {
    return null
  }

  if (!language) {
    return null
  }

  return (
    <>
      {dataBreadcrumb && <PageNavigation breadcrumbData={dataBreadcrumb} />}
      <Container>
        <PageHeading title="Traducir formulario" boxTableClasses="pt-4" />
        <div className="bg-neutral-100 rounded md:p-12 p-5">
          <FormTranslationInstructions currentLanguage={language[0].name} />
          <FormsFormTranslation
            onSubmit={handleSubmit}
            initialValues={initialValues}
            formDataInSpanish={!isNew ? formDataInSpanish : formData}
            formData={formData}
            isProcedureManagement={isProcedureManagement}
          />
        </div>
      </Container>
    </>
  )
}

export default FormTranslationComponent

FormTranslationComponent.propTypes = {
  isNew: PropTypes.bool.isRequired
}
