import { useState } from 'react'

const useTab = initialTab => {
  const [tabSelected, setTabSelected] = useState(initialTab)

  const handleTabChange = (_event, newValue) => {
    setTabSelected(newValue)
  }

  return { tabSelected, handleTabChange }
}

export default useTab
