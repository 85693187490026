import { useMediaQuery as useMaterialMediaQuery } from '@material-ui/core'

export const screenSizes = {
  mobile: 'sm',
  tablet: 'md',
  desktop: 'lg'
}

const sizes = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px'
}

const useMediaQuery = size => {
  return useMaterialMediaQuery(`(min-width:${sizes[size]})`)
}

export default useMediaQuery
