import PropTypes from 'prop-types'
import React from 'react'
import EmailField from '../question_config_fields/EmailField'
import * as validations from '../../../utils/validations'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import EmailInfo from '../question_config_fields/EmailInfo'
import TextField from '../../molecules/fields/TextField'

const Form = props => {
  return <EmailField index={props.index} baseName={props.baseName} validate={validations.required()} />
}

const Template = props => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <TextField label={props.question.payload?.label} name={props.name} disabled hint={props.question.payload?.hint} />
      <EmailInfo index={props.index} border />
    </div>
  )
}

const EmailQuestion = {
  selectOption: { value: 'email', label: 'Correo electrónico', icon: <MailOutlineIcon fontSize="small" /> },
  Form,
  Template,
  name: 'EmailQuestion'
}

export default EmailQuestion

Form.propTypes = {
  baseName: PropTypes.string,
  index: PropTypes.number
}

Template.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
