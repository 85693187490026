import PropTypes from 'prop-types'
import React from 'react'
import { FormControl } from '@material-ui/core'
import CheckboxesField from '../../molecules/fields/CheckboxesField'
import Filter from '../../molecules/filters/Filter'
import SelectFieldFilter from '../../molecules/filters/SelectFieldFilter'
import { useParams } from 'react-router-dom'
import DateRangeField from '../../molecules/fields/DateRangeField'
import useEnabledProcedureManagement from '../../../hooks/useEnabledProcedureManagement'
import { isSuccessful } from '../../../hooks/useQuery'

const ProceduresFilter = ({ areaId, states }) => {
  const { id } = useParams()
  const { getAreas } = useEnabledProcedureManagement(id)

  const { data: areas, status: areasStatus } = getAreas(['areas'])
  const filteredAreas = areas?.filter(el => el.id !== areaId) || []

  return (
    isSuccessful(areasStatus) && (
      <Filter>
        <FormControl className="mb-4 w-full" component="fieldset">
          {filteredAreas.length > 1 && (
            <SelectFieldFilter
              label="Área interna"
              className="mb-4"
              options={filteredAreas}
              name="area"
              emptyLabel="Todas las áreas"
            />
          )}
          <CheckboxesField label="Estado" options={states || []} name="state" />
          <DateRangeField name="created_at" label="Fecha de ingreso de la solicitud" />
          <DateRangeField name="expires" label="Fecha de vencimiento de la solicitud" />
        </FormControl>
      </Filter>
    )
  )
}

export default ProceduresFilter

ProceduresFilter.propTypes = {
  areaId: PropTypes.number,
  states: PropTypes.array
}
