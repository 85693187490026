import PropTypes from 'prop-types'
import React from 'react'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import clsx from 'clsx'

const SubtitleCitizenField = ({ payload, position, className = 'mb-4' }) => {
  return (
    <div>
      {position > 0 ? <FieldsSeparator /> : ''}
      <div className={clsx('font-bold text-xl', className)}>{`${payload?.label}`}</div>
    </div>
  )
}

export default SubtitleCitizenField

SubtitleCitizenField.propTypes = {
  className: PropTypes.string,
  payload: PropTypes.object,
  position: PropTypes.number
}
