import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

let finalColor
let finalBgkColor

const sizeMap = {
  xs: 'font-semibold text-xs px-2 py-1',
  lg: 'font-bold text-lg px-4 py-2'
}

const labelTypeMap = {
  NUEVO: 'secondary',
  ATENDIDO: 'success',
  RESUELTO: 'success',
  RECHAZADO: 'neutral',
  'EN PROCESO': 'primary',
  BETA: 'info'
}

export const TagBox = ({ className, label, tagType, size = 'xs' }) => {
  switch (tagType || labelTypeMap[label]) {
    case 'secondary':
      finalColor = 'text-purple-800'
      finalBgkColor = 'bg-purple-500'
      break
    case 'success':
      finalColor = 'text-green-800'
      finalBgkColor = 'bg-green-500'
      break
    case 'neutral':
      finalColor = 'text-neutral-700'
      finalBgkColor = 'bg-gray-200'
      break
    case 'primary':
      finalColor = 'text-blue-700'
      finalBgkColor = 'bg-sky-blue-400'
      break
    case 'info':
      finalColor = 'text-yellow-700'
      finalBgkColor = 'bg-yellow-400'
      break
    default:
      finalColor = 'text-blue-700'
      finalBgkColor = 'bg-sky-blue-400'
  }

  return (
    <span className={clsx(finalColor, finalBgkColor, sizeMap[size], 'inline-block leading-none', className)}>
      {label}
    </span>
  )
}

TagBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  tagType: PropTypes.string,
  size: PropTypes.string
}
