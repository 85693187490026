import { useEffect, useState } from 'react'
import consumer from '../channels/consumer'
import { useAuth } from '../components/molecules/Auth'

const useSubscription = (channelName, id, enabled = true) => {
  const [channel, setChannel] = useState(null)
  const [streamData, setStreamData] = useState(null)
  const { user } = useAuth()
  useEffect(() => {
    if (enabled) {
      setChannel(
        consumer(user.id).subscriptions.create({ channel: channelName, id }, { received: data => setStreamData(data) })
      )
    }
  }, [user?.id, id, enabled])
  return { channel, streamData }
}

export default useSubscription
