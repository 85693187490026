import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import AddIcon from '@material-ui/icons/Add'
import { useFieldArray } from 'react-final-form-arrays'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import useTasks from '../../../hooks/useTasks'
import Error from '../../molecules/Error'
import { getOptionTasks } from '../../../configs/procedureManagementsConfig'
import useFieldValue from '../../../hooks/useFieldValue'

const ArrayTasks = ({ name, userTypeId, disableFirst = false }) => {
  const procedureManagementId = useFieldValue('procedure_management_id')
  const { data: tasks, status } = useTasks(procedureManagementId, userTypeId || 0)
  const { fields } = useFieldArray(name)
  if (status !== 'success') return null
  const selectedTaskIds = fields.value ? fields.value.map(taskId => taskId?.toString()) : []
  return (
    <>
      {fields?.map((name, index) => (
        <div key={index} className="flex items-center mb-4">
          <SelectField
            name={name}
            options={getOptionTasks(tasks, selectedTaskIds, index)}
            containerClassName="flex-1 sm:max-w-lg"
            size="full"
            validate={validations.required()}
            emptyLabel="Seleccionar tarea"
            margin="none"
            disabled={disableFirst && index === 0}
          />
          {!(disableFirst && index === 0) && (
            <IconButton type="button" color="primary" onClick={() => fields.remove(index)}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ))}
      <Button
        variant="text"
        size="s"
        className="font-bold text-lg"
        startIcon={<AddIcon />}
        onClick={() => fields.push()}
      >
        Añadir tarea
      </Button>
      <Error name={fields.name} />
    </>
  )
}
export default ArrayTasks

ArrayTasks.propTypes = {
  disableFirst: PropTypes.bool,
  name: PropTypes.string,
  userTypeId: PropTypes.number
}
