import axios from 'axios'
import { useEffect, useState } from 'react'
import useQuery from '../hooks/useQuery'

const fetchAccesses = (enabledProcedureManagementId, currentId) =>
  axios
    .get(`/admin/v1/enabled_procedure_managements/${enabledProcedureManagementId}/accesses/me.json`, {
      params: { current_id: currentId }
    })
    .then(res => res.data)

const useProcedureManagementAccess = (enabledProcedureManagementId, accessId) => {
  const [currentAccess, setCurrentAccess] = useState(null)
  const {
    data: accesses,
    isLoading: loadingAccesses,
    error
  } = useQuery(
    ['procedure_management_accesses', enabledProcedureManagementId, accessId],
    () => fetchAccesses(enabledProcedureManagementId, accessId),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  )

  useEffect(() => {
    if (!loadingAccesses && !error && accesses) setCurrentAccess(accesses.current)
  }, [loadingAccesses, error, accesses])

  return { accesses, loadingAccesses, currentAccess, error }
}

export default useProcedureManagementAccess
