import useQuery from './useQuery'
import axios from 'axios'

const fetchAreasByUserType = (userTypeId, params) =>
  axios.get(`/admin/v1/user_types/${userTypeId}/areas.json`, { params }).then(res => res.data)

const fetchAreasByBranchOffice = branchOfficeId =>
  axios.get(`/admin/v1/branch_offices/${branchOfficeId}/areas.json`).then(res => res.data)

const fetchAllAreas = () => axios.get('/admin/v1/areas/all.json').then(a => a.data)

const useArea = () => {
  const useGetAreasByUserType = (userTypedId, params) =>
    useQuery(['areas_by_user_type', userTypedId, params], fetchAreasByUserType)

  const useGetAreasByBranchOffice = branchOfficeId =>
    useQuery(['areas_by_branch_office', branchOfficeId], fetchAreasByBranchOffice)

  const useGetAllAreas = () => useQuery(['all_areas'], fetchAllAreas)

  return { useGetAreasByUserType, useGetAreasByBranchOffice, useGetAllAreas }
}
export default useArea
