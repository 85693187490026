import PropTypes from 'prop-types'
import React from 'react'
import * as validations from '../../../utils/validations'
import { Form } from 'react-final-form'
import { Button } from '../../atoms/Button'
import TextField from '../../molecules/fields/TextField'
import SaveButton from '../../molecules/buttons/SaveButton'

const CategoriesForm = ({ onCancel, onFormSubmit, selectedCategory, SaveButtonLabel, TextFieldLabel }) => {
  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={{
        name: selectedCategory ? selectedCategory.name : '',
        id: selectedCategory ? selectedCategory.id : ''
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form
          className="p-6 bg-neutral-300 flex flex-col md:flex-row md:items-center justify-between"
          onSubmit={handleSubmit}
        >
          <TextField
            className="relative"
            rootClassName="flex-grow"
            size="2xl"
            label={TextFieldLabel}
            name="name"
            margin="none"
            validate={validations.required()}
            errorClassName="relative top-0"
          />
          <input type="hidden" name="id" />
          <div className="flex flex-col-reverse md:flex-row md:min-w-80 justify-end">
            <Button
              fullWidth={false}
              className="px-6 my-2 mr-2 md:my-0 md:max-w-56"
              variant="text"
              size="full"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <SaveButton
              label={SaveButtonLabel}
              containerClassName="mt-10 md:m-0 w-full md:w-auto"
              disabled={submitting}
            />
          </div>
        </form>
      )}
    </Form>
  )
}
export default CategoriesForm

CategoriesForm.propTypes = {
  SaveButtonLabel: PropTypes.string,
  TextFieldLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object
}
