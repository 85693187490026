import PropTypes from 'prop-types'
import React from 'react'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import { cloneQuestion } from '../../../configs/questionTypesAdminConfig'
import Tooltip from '../../atoms/Tooltip'

const CopyButton = ({ fields, index }) => {
  return (
    <Tooltip title="Copiar pregunta">
      <IconButton
        type="button"
        color="primary"
        aria-label="Copiar pregunta"
        onClick={() => setTimeout(fields.insert(index + 1, cloneQuestion(fields.value?.[index])))}
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  )
}

export default CopyButton

CopyButton.propTypes = {
  fields: PropTypes.object,
  index: PropTypes.number
}
