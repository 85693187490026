import React from 'react'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import Instructions from '../../molecules/Instructions'
import PropTypes from 'prop-types'

const FormTranslationInstructions = ({ currentLanguage }) => {
  return (
    <>
      <p className="font-bold text-2xl md:mb-6 mb-8">
        Español {'>'} {currentLanguage}
      </p>
      <Instructions
        list={[
          'Ingresa las traducciones al contenido del formulario inicial para el ciudadano.',
          'Si una pregunta es opcional, deberás indicarlo al final de la pregunta entre paréntesis. Ej. “(Opcional)”.'
        ]}
      />
      <FieldsSeparator className="my-8" />
      <p className="text-2xl font-bold">Información y configuración general</p>
    </>
  )
}

export default FormTranslationInstructions

FormTranslationInstructions.propTypes = {
  currentLanguage: PropTypes.string
}
