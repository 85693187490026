import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { errorResendInfo, hasResend, webhookStatusColorMap } from '../../../hooks/useAnswerToWebhook'
import WebhookStatusLabel from '../../molecules/WebhookStatusLabel'
import WebhookResendButton from '../../molecules/buttons/WebhookResendButton'
import IconButton from '../../molecules/buttons/IconButton'
import VisibilityIcon from '../../atoms/icons/Visibility'
import AnswerApiDialog from '../../molecules/dialogs/AnswerApiDialog'
import useToggle from '../../../hooks/useToggle'
import useUserPermission from '../../../hooks/useUserPermission'

const AnswerApiStatus = ({ answerData, onResend }) => {
  const [showDetail, toggleShowDetail] = useToggle()
  const { canSeeAnswerApiDetail } = useUserPermission('form')
  return (
    <div className="bg-blue-300 py-3 px-4 flex flex-col sm:flex-row sm:items-center justify-between">
      <p className="font-semibold">
        Envío a la API:{' '}
        <span className={clsx('inline-flex items-center', webhookStatusColorMap[answerData.webhook_status])}>
          <WebhookStatusLabel
            status={answerData.webhook_status}
            id={`loading-${answerData.id}`}
            infoContent={errorResendInfo.detail}
          />
        </span>
      </p>
      <div className="flex gap-3 items-center justify-center sm:justify-start mt-3 sm:mt-0">
        {hasResend(answerData.webhook_status) && (
          <WebhookResendButton onResend={onResend} status={answerData.webhook_status} />
        )}
        {canSeeAnswerApiDetail && (
          <IconButton size="small" text="Ver detalle de envío" onClick={toggleShowDetail}>
            <VisibilityIcon />
          </IconButton>
        )}
      </div>
      {showDetail && <AnswerApiDialog onResend={onResend} answer={answerData} onClose={toggleShowDetail} />}
    </div>
  )
}

export default AnswerApiStatus

AnswerApiStatus.propTypes = {
  answerData: PropTypes.object,
  onResend: PropTypes.func
}
