import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'

const SubtitleQuestionFields = ({ baseName, question, questionTranslated }) => {
  return (
    <TextField
      label="Etiqueta del subtítulo"
      size="full"
      name={`${baseName}.payload.label`}
      validate={validations.required()}
      margin="none"
      hint={question.payload.label}
      placeholder="Ingresa la traducción."
    />
  )
}
export default SubtitleQuestionFields

SubtitleQuestionFields.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
