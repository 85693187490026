import PropTypes from 'prop-types'
import React from 'react'
import { Paper, Popper, ClickAwayListener } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles({
  paper: {
    'box-shadow': '4px 4px 20px 0px #555869',
    borderRadius: '10px'
  },
  arrow: {
    position: 'absolute',
    top: '-15px',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '15px solid white'
  }
})

const arrowAlignment = {
  center: {
    left: '50%',
    transform: 'translateX(-50%)'
  },
  right: {
    right: 0
  }
}

const Message = ({ anchorEl, onClose, arrowPosition, className, classes, children }) => {
  const innerClasses = useStyles()

  return (
    <Popper
      open={Boolean(anchorEl)}
      className={clsx('z-10', className)}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement="bottom"
    >
      <Paper className={innerClasses.paper}>
        <ClickAwayListener onClickAway={onClose}>
          <div className="relative">
            <span className={clsx(innerClasses.arrow, arrowAlignment[arrowPosition], classes.arrow)} />
            <div className={classes.body}>{children}</div>
          </div>
        </ClickAwayListener>
      </Paper>
    </Popper>
  )
}

export default Message

Message.propTypes = {
  anchorEl: PropTypes.element,
  arrowPosition: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
}
