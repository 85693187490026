import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useField } from 'react-final-form'
import { FormHelperText } from '@material-ui/core'
import PropTypes from 'prop-types'

const ReCAPTCHAField = React.forwardRef(({ name }, ref) => {
  const { input, meta } = useField(name)
  const gotError = meta.touched && meta.error
  return (
    <div className="relative">
      <ReCAPTCHA hl="es-419" ref={ref} sitekey="6LfQRSMaAAAAAJmX7q7ooZVeoOm_7GFb8EQmG1tL" onChange={input.onChange} />
      {gotError ? (
        <FormHelperText error className="absolute -bottom-6">
          {meta.error}
        </FormHelperText>
      ) : null}
    </div>
  )
})

ReCAPTCHAField.displayName = 'ReCAPTCHAField'

export default ReCAPTCHAField

ReCAPTCHAField.propTypes = {
  name: PropTypes.string
}
