import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { LinearProgressBar } from '../../atoms/LinearProgressBar'
import { Button } from '../../atoms/Button'
import React from 'react'
import ErrorDialog from '../../molecules/dialogs/ErrorDialog'

const Processing = ({ upload, onCancel }) => {
  const disabledCancelButton = upload.message === 'Deteniendo' || upload.message === 'Procesando'
  return (
    <Dialog open classes={{ paper: 'rounded-lg' }} maxWidth="lg">
      <DialogTitle>
        <span className="font-bold">Cargando base de datos</span>
      </DialogTitle>
      <DialogContent>
        <p className="mb-8">
          Según el tamaño del archivo, el tiempo de carga puede tardar
          <span className="font-bold"> entre 1 a 5 min. aproximadamente.</span>
        </p>
        <div className="flex align-middle">
          <LinearProgressBar
            percentage={upload.percentage || 0}
            classes={{ root: 'bg-gray-400 h-6 rounded-2xl w-full mr-2' }}
          />
          <p>{upload.message || 'Procesando'}</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className="text-red-750"
          size=""
          fullWidth={false}
          onClick={onCancel}
          disabled={disabledCancelButton}
        >
          Cancelar carga
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Error = ({ upload }) => {
  return (
    <ErrorDialog>
      <>
        <p className="font-bold text-red-750 mb-4 text-xl">Error en la base de datos</p>
        <p className="mb-4">
          La base de datos que has subido tiene
          <span className="font-bold">
            {' '}
            {upload.empty_cell_errors.split(',').length + upload.result_cell_errors.split(',').length} campos vacíos o
            que no concuerdan con ninguno de los resultados creados.{' '}
          </span>
          Por favor, revísalos y vuelve a intentarlo.
        </p>
        <ul className="list-disc ml-8">
          {upload.empty_cell_errors && (
            <li>
              <span className="text-red-750 font-bold">Campos vacíos: </span>
              {upload.empty_cell_errors}.
            </li>
          )}
          {upload.result_cell_errors && (
            <li>
              <span className="text-red-750 font-bold">Campos que no concuerdan con resultados: </span>
              {upload.result_cell_errors}.
            </li>
          )}
        </ul>
      </>
    </ErrorDialog>
  )
}

const GeneralError = () => {
  return (
    <ErrorDialog>
      <>
        <p className="font-bold text-red-750 mb-4 text-xl">No pudimos cargar la base de datos</p>
        <p>Por favor, vuelve a intentarlo en unos minutos.</p>
      </>
    </ErrorDialog>
  )
}

export { Processing, Error, GeneralError }

Processing.propTypes = {
  onCancel: PropTypes.func.isRequired,
  upload: PropTypes.exact({
    message: PropTypes.string,
    percentage: PropTypes.number
  })
}

Error.propTypes = {
  upload: PropTypes.exact({
    empty_cell_errors: PropTypes.string,
    result_cell_errors: PropTypes.string
  })
}
