import PropTypes from 'prop-types'
import React from 'react'

const SectionTitle = ({ title, description, children }) => {
  return (
    <div className="flex flex-col md:flex-row md:justify-between md:items-center pb-6">
      <div className="flex flex-col">
        <h2 className="text-xl font-bold">{title}</h2>
        <p className="mb-2 md:mb-0">{description}</p>
      </div>
      <div className="flex flex-col-reverse md:flex-row content-end md:items-center">{children}</div>
    </div>
  )
}

export default SectionTitle

SectionTitle.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string
}
