import PropTypes from 'prop-types'
import React from 'react'
import TextField from './TextField'
import useTextValidation from '../../../hooks/useTextValidation'
import * as validations from '../../../utils/validations'

const OtherField = ({ name, validate, open }) => {
  const translationText = useTextValidation()

  if (!open) return false
  return (
    <TextField
      size="2xl"
      className="md:min-w-96 mt-2"
      validate={validations.mix(validations.maxLength(100, translationText.maxLenght(100)), validate)}
      name={`${name}.other_text`}
      margin="none"
      placeholder="Ingresa tu respuesta"
      ariaLabel="Ingresa tu respuesta"
    />
  )
}

export default OtherField

OtherField.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  open: PropTypes.bool
}
