import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import TermsEdit from '../../components/pages/admin/terms_translations/TermsEdit'
import LanguageNew from '../../components/pages/admin/terms_translations/LanguageNew'
import TermsBulkUpload from '../../components/pages/admin/terms_translations/TermsBulkUpload'
import useUserPermission from '../useUserPermission'

const useTermsRoute = () => {
  const { canSeeTerms } = useUserPermission('menu')

  return [
    { path: '/traducciones', component: ResourcesTab, hasPermission: canSeeTerms },
    { path: '/traducciones/:id/editar', component: TermsEdit, hasPermission: canSeeTerms },
    { path: '/traducciones/crear', component: LanguageNew, hasPermission: canSeeTerms },
    { path: '/traducciones/carga-masiva', component: TermsBulkUpload, hasPermission: canSeeTerms }
  ]
}

export default useTermsRoute
