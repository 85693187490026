import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import { Button } from '../../atoms/Button'
import OriginAndDestinySection from './OriginAndDestinySection'
import NextEnabledTasksSection from './NextEnabledTasksSection'
import arrayMutators from 'final-form-arrays'
import RulesSection from './RulesSection'
import UpdateStateOtherUsersSection from './UpdateStateOtherUsersSection'
import NextDisabledTasksSection from './NextDisabledTasksSection'
import { clearMutator } from '../../../utils/formMutators'

const ConnectionsAndRulesForm = ({ mutate, task, onClose: close }) => {
  const onSubmit = async values => {
    try {
      await mutate(values)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }
  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ clear: clearMutator, ...arrayMutators }}
      decorators={[focusOnFirstError]}
      initialValues={task}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="flex flex-col h-full">
          <h2 className="header text-xl py-4 px-4 md:px-0">
            Definir conexiones y reglas de <span className="font-bold">{task.name}</span>
          </h2>
          <div className="body md:overflow-y-auto flex-1 bg-neutral-100 p-4 md:p-6">
            <OriginAndDestinySection task={task} />
            <UpdateStateOtherUsersSection task={task} />
            <FieldsSeparator className="my-4" />
            <NextDisabledTasksSection task={task} />
            <FieldsSeparator className="my-4" />
            <NextEnabledTasksSection task={task} />
            <FieldsSeparator className="my-4" />
            <RulesSection task={task} />
          </div>
          <div className="footer flex flex-col-reverse md:flex-row justify-end py-4 px-4 md:px-0">
            <Button variant="secondary" className="md:max-w-56 lg:max-w-72 md:mr-3" size="full" onClick={close}>
              Cerrar
            </Button>
            <Button
              type="submit"
              className="md:max-w-56 lg:max-w-72 mb-3 md:mb-0"
              size="full"
              disabled={submitting || pristine}
            >
              Guardar
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}
export default ConnectionsAndRulesForm

ConnectionsAndRulesForm.propTypes = {
  mutate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object
}
