import React from 'react'
import Container from '../../atoms/Container'
import { ReactSVG as Svg } from 'react-svg'
import { Button } from '../../atoms/Button'
import clockLanding from '../../../images/icons/clock_landing.svg'
import screenLanding from '../../../images/icons/screen_landing.svg'
import pointerLanding from '../../../images/icons/pointer_landing.svg'
import { Link } from 'react-router-dom'
import useHomeStatistics from '../../../hooks/services/useHomeStatistics'
import { isSuccessful } from '../../../hooks/useQuery'

export const Header = () => {
  const { statisticData, status } = useHomeStatistics()
  const institutionCount = isSuccessful(status) ? statisticData.institutions : 0

  return (
    <div className="">
      <section aria-label="Descripción del servicio" className="bg-neutral-100 pt-14 pb-14">
        <Container className="lg:px-36 md:px-10 flex md:flex-row flex-col justify-between items-center px-8 gap-4">
          <div className="mb-0 w-full md:w-5/12">
            <h1 className="font-bold title leading-none mb-4 text-5xl md:text-16">Facilita</h1>
            <p className="text-base md:text-lg leading-tight mb-9">
              Plataforma integral de solicitudes digitales del Estado peruano
            </p>
            <h2 className="mt-4 text-base md:text-xl font-bold mb-6">Digitaliza tus trámites y servicios</h2>
            <div className="flex items-center mb-5 w-80">
              <Svg src={clockLanding} />
              <p className="ml-3 text-base">De manera rápida y sencilla</p>
            </div>
            <div className="flex items-center mb-5 w-80">
              <Svg src={screenLanding} className="" />
              <p className="ml-3 text-base">Sin necesidad de instalación ni mantenimiento</p>
            </div>
            <div className="flex items-center w-72">
              <Svg src={pointerLanding} className="" />
              <p className="ml-3 text-base">Con un diseño práctico e intuitivo</p>
            </div>
          </div>
          <div className="md:w-148 block md:inline-block text-center">
            <h2 className="text-base md:text-xl font-bold mb-6">¡Sé parte de la transformación digital del Perú!</h2>
            <div className="bg-white rounded-xl py-7 px-19">
              <p>
                <span className="text-10 font-extrabold block">{institutionCount}</span>
                Entidades públicas usan Facilita
                <br /> para digitalizar sus procesos.
              </p>
              <Button el={Link} to="/login" className="hover:text-white md:max-w-56 my-5" size="full">
                Ingresa
              </Button>
              <p className="text-center">
                ¿No tienes acceso a Facilita?
                <br />
                <Button
                  el={'a'}
                  to="https://www.gob.pe/10899"
                  variant="text"
                  size="xs"
                  className="inline-block font-bold"
                >
                  Solicítalo
                </Button>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </div>
  )
}
