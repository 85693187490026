import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Dialog from './Dialog'
import { useAnswerToWebhook, hasResend, webhooksPropsMap } from '../../../hooks/useAnswerToWebhook'
import { Button } from '../../atoms/Button'
import { isSuccessful } from '../../../hooks/useQuery'
import AnswerApiTableRow from '../../organisms/answers/AnswerApiTableRow'
import useInvalidateQueries from '../../../hooks/useInvalidateQueries'
import CircularProgress from '../../atoms/icons/CircularProgress'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'

const AnswerApiDialog = ({ answer, onClose, onResend }) => {
  const invalidateQueries = useInvalidateQueries()
  const { getAllAnswerApiLog } = useAnswerToWebhook({ answer, formId: answer.form_id })
  const { data: answerApiLogs, status } = getAllAnswerApiLog(['answerApiLogs', answer.id])

  useEffect(() => {
    invalidateQueries(['answerApiLogs', answer.id])
  }, [answer.webhook_status])

  if (!isSuccessful(status)) return null

  return (
    <Dialog
      dialogClasses={{ paper: 'w-full max-w-full self-end mb-0 mx-0' }}
      dialogTitleClasses={{ root: 'px-6 md:px-12 pt-3' }}
      dialogContentClasses={{ root: 'px-6 md:px-12 py-8' }}
      dialogActionClasses={{ root: 'px-6 md:px-12 py-6 shadow-modal-action-bottom' }}
      onClose={onClose}
      maxWidth={false}
      renderTitle={<p className="font-bold text-2xl">Detalle de envío a la API</p>}
      renderContent={
        <div className="bg-neutral-100 px-4 py-6 md:px-8 md:py-8 overflow-x-auto">
          <Table
            className="w-full"
            values={answerApiLogs.data}
            head={
              <Tr className="text-xs" body={false}>
                <Th>Ruta (URL)</Th>
                <Th className="w-24">Autenticación</Th>
                <Th className="min-w-30">Fecha de envío</Th>
                <Th className="min-w-30">Estado de envío</Th>
                <Th>Estado http de respuesta</Th>
                <Th>Mensaje retornado</Th>
              </Tr>
            }
            body={
              <>
                {answerApiLogs.data?.map(apiLog => (
                  <AnswerApiTableRow key={apiLog.id} apiLog={apiLog} />
                ))}
              </>
            }
          />
        </div>
      }
      renderActions={
        <>
          {hasResend(answer.webhook_status) && (
            <Button
              size="full"
              className="md:max-w-56"
              onClick={onResend}
              endIcon={webhooksPropsMap[answer.webhook_status].icon}
            >
              Reenviar a la API
            </Button>
          )}
          {answer.webhook_status === 'sending' && <CircularProgress ariaLabel="Enviando" />}
        </>
      }
    />
  )
}

export default AnswerApiDialog

AnswerApiDialog.propTypes = {
  answer: PropTypes.object,
  onClose: PropTypes.func,
  onResend: PropTypes.func
}
