import React from 'react'
import { paymentMethods } from '../../../configs/paymentMethodConfigs'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { markForDestroyMutator } from '../../../utils/formMutators'
import { focusOnFirstError } from '../../../utils/decorators'
import Error from '../../molecules/Error'
import SwitchField from '../../molecules/fields/SwitchField'
import Value from '../../molecules/Value'
import PaymentMethodForm from './PaymentMethodForm'
import useInstitution from '../../../hooks/useInstitution'
import useInvalidateQueries from '../../../hooks/useInvalidateQueries'
import { isSuccessful } from '../../../hooks/useQuery'
import { usePageAlertContext } from '../../../context/AlertPageProvider'

const ConfigPaymentMethods = () => {
  const invalidateQueries = useInvalidateQueries()
  const { getInstitution, updateInstitution } = useInstitution()
  const { data: institution, status } = getInstitution()
  const { setPageAlert } = usePageAlertContext()

  const baseName = 'payment_methods'
  const handleSubmit = (values, form) =>
    updateInstitution(values, {
      onSuccess: () => invalidateQueries(['institution', values.id]),
      onError: error => {
        if (error.response.data.payment_transfer_accounts_disable) {
          setPageAlert({
            alert_reason: 'payment_transfer_accounts_disable',
            type: 'error',
            params: error.response.data.payment_transfer_accounts_disable[0]
          })
          form.change(`${baseName}.bank_transfer`, true)
        } else {
          alert('Ocurrió un error al intentar guardar.')
        }
      }
    })
  if (!isSuccessful(status)) return null

  return Object.keys(paymentMethods).map((paymentMethod, index) => (
    <Form
      onSubmit={handleSubmit}
      mutators={{ ...arrayMutators, markForDestroy: markForDestroyMutator }}
      decorators={[focusOnFirstError]}
      initialValues={institution}
      key={index}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <fieldset className="bg-neutral-100 px-6 md:px-8 py-4 mb-0 relative js-field-container">
            <div className="flex items-center justify-between font-bold md:text-xl py-4">
              <div>
                <label htmlFor={`${baseName}.${paymentMethod}-input`}>{paymentMethods[paymentMethod].label}</label>
                <Error
                  className="w-full text-base leading-6 mt-2"
                  name={`${paymentMethods[paymentMethod].errorName}_disable`}
                  showInfoIcon
                />
              </div>
              <SwitchField name={`${baseName}.${paymentMethod}`} onChange={handleSubmit} />
            </div>
            <Value name={`${baseName}.${paymentMethod}`}>
              {({ value: paymentMethodEnabled }) =>
                paymentMethodEnabled ? (
                  <PaymentMethodForm
                    paymentMethod={paymentMethod}
                    kind="admin"
                    onSubmit={handleSubmit}
                    submitting={submitting}
                  />
                ) : (
                  <div>{paymentMethods[paymentMethod].hintInDisabled}</div>
                )
              }
            </Value>
          </fieldset>
        </form>
      )}
    </Form>
  ))
}

export default ConfigPaymentMethods
