import PropTypes from 'prop-types'
import React from 'react'
import { ReactSVG as Svg } from 'react-svg'
import AccountTree from '../../../images/icons/account_tree.svg'

const TaskHistoryLeafButton = ({ leafId, onShowBranch, onShowAll }) => {
  return leafId ? (
    <button
      className="font-bold flex items-center text-blue-700"
      onClick={e => {
        e.stopPropagation()
        onShowAll()
      }}
      type="button"
    >
      <Svg src={AccountTree} className="mr-1" />
      Ver todos los flujos
    </button>
  ) : (
    <button
      className="font-bold text-blue-700"
      onClick={e => {
        e.stopPropagation()
        onShowBranch()
      }}
      type="button"
    >
      Ver tareas de este flujo
    </button>
  )
}
export default TaskHistoryLeafButton

TaskHistoryLeafButton.propTypes = {
  leafId: PropTypes.number,
  onShowAll: PropTypes.func.isRequired,
  onShowBranch: PropTypes.func.isRequired
}
