import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import { HtmlField } from '../../molecules/fields/HtmlField'
import { Button } from '../../atoms/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import UpDownButtons from '../forms/UpDownButtons'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'

const Sections = () => {
  const { fields } = useFieldArray('sections')
  return (
    <>
      {fields.map((name, index) => (
        <div key={index} className="mb-6 grid md:flex">
          <div className="w-full">
            <div className="bg-neutral-200 flex">
              <div className="p-8 w-full">
                <p className="text-2xl font-bold mb-2 flex items-end">Sección {index + 1}:</p>
                <TextField
                  name={`${name}.title`}
                  label="Título de la sección"
                  size="full"
                  validate={validations.required()}
                />
                <HtmlField
                  name={`${name}.hint`}
                  label="Contenido de la sección"
                  validate={validationsAdmin.isRichTextFieldEmpty()}
                  canUploadFiles
                  maxContentLength={0}
                  fullName={name}
                />
              </div>
            </div>
            <div className="flex justify-end bg-white">
              <Button
                type="button"
                variant="text"
                size="xs"
                onClick={() => fields.remove(index)}
                classes={{ root: 'px-0 mr-4' }}
                startIcon={<DeleteIcon />}
                fullWidth={false}
              >
                Borrar
              </Button>
            </div>
          </div>
          <UpDownButtons index={index} fields={fields} element="sección" />
        </div>
      ))}
      <Button
        onClick={() => fields.push()}
        variant="secondary"
        size="full"
        startIcon={<AddIcon />}
        className="w-full md:max-w-56"
      >
        Agregar sección
      </Button>
    </>
  )
}
export default Sections
