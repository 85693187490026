class SelectOptions {
  constructor(allOptions) {
    this.allOptions = allOptions
    this._options = []
  }

  addGroupLabel(groupLabel) {
    this._options.push({ groupLabel })
  }

  addOptionsGroup(codes, key = 'label') {
    codes.map(code => this._options.push({ value: code, label: this.allOptions[code][key] }))
  }

  get options() {
    return this._options
  }
}
export default SelectOptions
