import useCrud from './useCrud'

const useBranchOffices = (institutionId, byType) => {
  const urlBase = '/admin/v1/branch_offices'

  let urlFiltered = `/admin/v1/institutions/${institutionId}/branch_offices`
  if (byType) urlFiltered = `${urlBase}/${byType}`

  const { findById } = useCrud(urlBase)

  const { get } = useCrud(urlFiltered)

  const getBranchOffices = (params = {}) =>
    get(['branch_offices', institutionId, byType, ...Object.values(params)], {
      institution_id: institutionId,
      ...params
    })

  return { get, getBranchOffices, findById }
}
export default useBranchOffices
