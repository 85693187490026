import React, { useContext, useEffect, useState } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { IconButton } from '@material-ui/core'
import { ReactSVG as Svg } from 'react-svg'
import useMediaQuery, { screenSizes } from '../../../hooks/useMediaQuery'
import scrollLeftNavigationIcon from '../../../images/icons/scroll_left_navigation.svg'

const LeftScrollNavigation = () => {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators, initComplete } = useContext(VisibilityContext)
  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible))
  const matchTabletSize = useMediaQuery(screenSizes.tablet)
  const additionalProps = matchTabletSize ? {} : { 'aria-hidden': 'true' }

  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible)
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators])

  return (
    <IconButton
      onClick={() => scrollPrev()}
      classes={{ root: 'hidden md:flex hover:bg-transparent', disabled: 'text-neutral-400' }}
      color="primary"
      disabled={disabled}
      {...additionalProps}
    >
      <Svg src={scrollLeftNavigationIcon} />
    </IconButton>
  )
}

export default LeftScrollNavigation
