import PropTypes from 'prop-types'
import React from 'react'
import { PaymentAmountTag } from '../../molecules/PaymentAmountTag'

const FormStepHeader = ({ state, form, name, isPaymentPage }) => {
  const _name = name || 'Información general del solicitante'
  return (
    form.max_steps > 1 && (
      <div className="md:flex justify-between bg-neutral-500 text-white py-4 px-6 md:px-8 rounded-t">
        <div className="font-bold">
          <div className="text-base md:text-xl leading-6 md:leading-7">
            Paso {state.context.activeStep + 1} de {form.max_steps}
          </div>
          <div className="text-lg md:text-2xl md:leading-6 md:leading-7">{_name}</div>
        </div>
        {isPaymentPage && form.it_costs && (
          <div className="md:flex items-center mt-3 pt-3 md:pt-0 md:mt-0 border-t border-white md:border-none font-medium md:font-bold">
            <div>
              Este trámite tiene un costo de<span className="md:hidden">:</span>
            </div>
            <PaymentAmountTag amount={form.cost} className="md:mx-2" />
          </div>
        )}
      </div>
    )
  )
}

export default FormStepHeader

FormStepHeader.propTypes = {
  form: PropTypes.object,
  isPaymentPage: PropTypes.bool,
  name: PropTypes.string,
  state: PropTypes.object
}
