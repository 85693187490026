import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import Tutorials from '../../components/pages/admin/onboardings/Tutorials'
import TutorialNew from '../../components/pages/admin/onboardings/TutorialNew'
import TutorialEdit from '../../components/pages/admin/onboardings/TutorialEdit'
import UpgradeNew from '../../components/pages/admin/onboardings/UpgradeNew'
import Categories from '../../components/pages/admin/onboardings/Categories'
import UpgradeEdit from '../../components/pages/admin/onboardings/UpgradeEdit'
import Upgrades from '../../components/pages/admin/onboardings/Upgrades'
import TutorialPreview from '../../components/pages/admin/onboardings/TutorialPreview'
import UpgradePreview from '../../components/pages/admin/onboardings/UpgradePreview'
import UpgradeDetail from '../../components/pages/admin/onboardings/UpgradeDetail'
import TutorialDetail from '../../components/pages/admin/onboardings/TutorialDetail'
import NotificationNew from '../../components/pages/admin/onboardings/NotificationNew'
import NotificationEdit from '../../components/pages/admin/onboardings/NotificationEdit'
import useUserPermission from '../useUserPermission'

const useOnboardingsRoute = () => {
  const { canManageHelpCenter, canSeeNotifications } = useUserPermission('menu')

  return [
    { path: '/centro-de-ayuda', component: ResourcesTab, hasPermission: true },
    { path: '/centro-de-ayuda/tutoriales', component: Tutorials, hasPermission: true },
    { path: '/centro-de-ayuda/actualizaciones', component: Upgrades, hasPermission: true },
    { path: '/centro-de-ayuda/categorias', component: Categories, hasPermission: canManageHelpCenter },
    { path: '/centro-de-ayuda/articulo-tutorial/crear', component: TutorialNew, hasPermission: canManageHelpCenter },
    {
      path: '/centro-de-ayuda/articulo-tutorial/:id/editar',
      component: TutorialEdit,
      hasPermission: canManageHelpCenter
    },
    {
      path: '/centro-de-ayuda/articulo-actualizacion/crear',
      component: UpgradeNew,
      hasPermission: canManageHelpCenter
    },
    {
      path: '/centro-de-ayuda/articulo-actualizacion/:id/editar',
      component: UpgradeEdit,
      hasPermission: canManageHelpCenter
    },
    {
      path: '/centro-de-ayuda/articulo-tutorial/:id/vista-previa',
      component: TutorialPreview,
      hasPermission: canManageHelpCenter
    },
    {
      path: '/centro-de-ayuda/articulo-actualizacion/:id/vista-previa',
      component: UpgradePreview,
      hasPermission: canManageHelpCenter
    },
    { path: '/centro-de-ayuda/articulo-actualizacion/:id', component: UpgradeDetail, hasPermission: true },
    { path: '/centro-de-ayuda/articulo-tutorial/:id', component: TutorialDetail, hasPermission: true },
    { path: '/notificaciones', component: ResourcesTab, hasPermission: canSeeNotifications },
    { path: '/notificaciones/crear', component: NotificationNew, hasPermission: canSeeNotifications },
    { path: '/notificaciones/:id/editar', component: NotificationEdit, hasPermission: canSeeNotifications }
  ]
}
export default useOnboardingsRoute
