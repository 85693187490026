import React, { useState } from 'react'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import { quickSearchSteps, headerInfo } from '../../../../configs/quickSearchesConfig'
import FormSection from '../../../molecules/FormSection'
import TextField from '../../../molecules/fields/TextField'
import * as validations from '../../../../utils/validations'
import Value from '../../../molecules/Value'
import { Form as FinalForm } from 'react-final-form'
import { serialize } from 'object-to-formdata'
import arrayMutators from 'final-form-arrays'
import { clearMutator } from '../../../../utils/formMutators'
import { focusOnFirstError } from '../../../../utils/decorators'
import CheckboxField from '../../../molecules/fields/CheckboxField'
import ResultsSection from '../../../organisms/quick_searches/ResultsSection'
import FormsHeader from '../../../molecules/FormsHeader'
import SaveButton from '../../../molecules/buttons/SaveButton'
import { Link, useParams } from 'react-router-dom'
import useQuickSearch from '../../../../hooks/useQuickSearch'
import validation from '../../../../utils/quickSearchesValidations'
import axios from 'axios'
import useSearchParams from '../../../../hooks/useSearchParams'
import BulkUpload from '../../../organisms/quick_searches/BulkUpload'
import SpreadsheetSection from '../../../organisms/quick_searches/SpreadsheetSection'
import { Button } from '../../../atoms/Button'

const clearLabels = ['contact_phone', 'contact_email']
const updateQuickSearch = (id, values, config) =>
  axios.put(`/admin/v1/quick_searches/${id}/update_with_spreadsheet.json`, values, config)

const QuickSearchResults = () => {
  const searchParams = useSearchParams()
  const action = searchParams.get('action')
  const { id } = useParams()
  const [uploadProgress, setUploadProgress] = useState(null)
  const { getQuickSearch } = useQuickSearch()
  const { data, status } = getQuickSearch(id)
  const headerInfoText = headerInfo(data?.name)[action]

  const onSubmit = async values => {
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    try {
      await updateQuickSearch(id, values, config)
    } catch (error) {
      return { col_separator: error.response.data }
    }
  }

  const handleSubmit = async values => {
    const validationStatus = await validation(values)
    if (validationStatus !== 'success') return validationStatus

    const formData = serialize(values, { indices: true })
    return onSubmit(formData)
  }

  if (status !== 'success') return null

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FinalForm
          initialValues={data}
          onSubmit={handleSubmit}
          mutators={{
            ...arrayMutators,
            clear: clearMutator
          }}
          decorators={[focusOnFirstError]}
          subscription={{ submitting: true }}
        >
          {({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              <>
                <FormsHeader
                  title={headerInfoText.title}
                  hint={headerInfoText.hint}
                  steps={quickSearchSteps}
                  activeStep={quickSearchSteps[1]}
                />
                <FormSection title="Información de contacto">
                  <button
                    onClick={() => clearLabels.map(clearLabel => form.mutators.clear(clearLabel))}
                    className="w-max"
                    type="button"
                  >
                    <CheckboxField
                      name="show_contact_info"
                      label="Quiero agregar datos de contacto en las páginas de resultados."
                      margin="none"
                      className="mb-6 md:mb-0"
                    />
                  </button>
                  <Value name="show_contact_info">
                    {({ value }) =>
                      value && (
                        <div className="grid gap-6 grid-rows-1 md:grid-cols-2">
                          <TextField
                            label="Teléfono"
                            name="contact_phone"
                            size="full"
                            margin="none"
                            validate={validations.mix(validations.required(), validations.isNumber())}
                          />
                          <TextField
                            label="Correo"
                            name="contact_email"
                            size="full"
                            margin="none"
                            validate={validations.mix(validations.required(), validations.isEmail())}
                          />
                        </div>
                      )
                    }
                  </Value>
                </FormSection>
                <SpreadsheetSection />
                <ResultsSection />
                <div className="w-full flex justify-end gap-6">
                  <Button variant="secondary" el={Link} to={`/consultas-rapidas/${id}/editar`}>
                    Regresar
                  </Button>
                  <div className="w-full md:max-w-72 justify-end">
                    <SaveButton
                      disabled={submitting}
                      containerClassName="w-full ml-0"
                      label="Guardar y continuar"
                      uploadProgress={uploadProgress}
                    />
                  </div>
                </div>
              </>
            </form>
          )}
        </FinalForm>
        <BulkUpload quickSearchId={id} action={action} />
      </Container>
    </>
  )
}
export default QuickSearchResults
