import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from '../../atoms/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import clsx from 'clsx'

const BaseFormCard = ({ title, body, action }) => {
  const [active, setActive] = useState(false)
  const handleClick = () => {
    setActive(true)
    action()
  }
  return (
    <div
      className={clsx('base-form-card', { active })}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className="base-form-card__title mb-6">{title}</div>
      <div className="h-full base-form-card__body">{body}</div>
      <div className="flex justify-end w-full">
        <Button
          type="button"
          variant="text"
          size="xs"
          endIcon={<ArrowForwardIcon />}
          className="base-form-card__btn font-bold"
        >
          Empecemos
        </Button>
      </div>
    </div>
  )
}
export default BaseFormCard

BaseFormCard.propTypes = {
  action: PropTypes.func,
  body: PropTypes.string,
  title: PropTypes.string
}
