import PropTypes from 'prop-types'
import { Badge, ClickAwayListener, MenuList, Paper, Popper } from '@material-ui/core'
import React, { useLayoutEffect, useRef, useState } from 'react'
import NotificationIcon from '@material-ui/icons/Notifications'
import Close from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { getIcon } from '../../../configs/notificationsConfig'
import clsx from 'clsx'
import useWindowsSize from '../../../hooks/useWindowsSize'
import { ReactSVG as Svg } from 'react-svg'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles({
  badge: {
    backgroundColor: '#278F3E',
    color: 'white'
  },
  paper: {
    marginTop: '20px',
    'box-shadow': '4px 4px 20px 0px #555869',
    borderRadius: '10px',
    width: `350px`
  },
  list: {
    'padding-top': 0,
    'padding-bottom': 0
  },
  menuItem: {
    borderTop: '2px solid #DEE3EA !important'
  },
  arrow: {
    position: 'absolute',
    top: '5px',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '15px solid white'
  },
  circle: {
    top: '5px',
    minWidth: '12px',
    height: '12px',
    background: '#0071E3',
    borderRadius: '50%'
  }
})

const urlDestination = (urlSource, type) => {
  if (type === 'form') return { url: urlSource, target: '_self' }

  let finalUrlDestination = urlSource
  if (!finalUrlDestination.includes('http')) finalUrlDestination = 'http://'.concat(finalUrlDestination)
  const url = new URL(finalUrlDestination)
  const target = window.location.host === url.host ? '_self' : '_blank'
  return { url, target }
}

const getNotificationAriaLabel = notViewed => {
  if (!notViewed) return `Notificaciones, no tienes nuevas notificaciones`
  if (notViewed === 1) return 'Notificaciones, tienes una nueva notificación'
  return `Notificaciones, tienes ${notViewed} nuevas notificaciones`
}

const NotificationsTray = ({
  onViewNotifications = () => {},
  notifications,
  notViewed,
  onNotificationClick = () => {}
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [arrowRef, setArrowRef] = useState(null)
  const [positionPopper, setPositionPopper] = useState(0)
  const popperRef = useRef()
  const { width } = useWindowsSize()
  const classes = useStyles()

  useLayoutEffect(() => {
    setPositionPopper(0)
    const withDiv = popperRef.current?.clientWidth
    withDiv && width < 600 && setPositionPopper((width - withDiv) / 2)
  })

  return (
    <>
      <div className="mr-1 cursor-pointer text-center">
        <IconButton
          aria-label={getNotificationAriaLabel(notViewed)}
          color="inherit"
          onClick={event => {
            setAnchorEl(event.currentTarget)
            onViewNotifications()
          }}
        >
          <Badge classes={{ badge: classes.badge }} badgeContent={notViewed}>
            <NotificationIcon />
          </Badge>
        </IconButton>
      </div>
      <Popper
        id="basic-menu"
        ref={popperRef}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        className="z-9999"
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef
          },
          offset: {
            offset: `-${positionPopper}`
          }
        }}
      >
        {() => (
          <Paper classes={{ root: classes.paper }}>
            <ClickAwayListener
              onClickAway={() => {
                width < 600 ? setTimeout(() => setAnchorEl(null), 200) : setAnchorEl(null)
              }}
              touchEvent="onTouchStart"
            >
              <div>
                <span className={classes.arrow} ref={setArrowRef} />
                <div className="flex justify-between p-4">
                  <div>Notificaciones</div>
                  <div className="text-blue-700">
                    <Close onClick={() => setAnchorEl(null)} className="cursor-pointer" />
                  </div>
                </div>
                <MenuList aria-labelledby={'basic-button'} classes={{ root: classes.list }}>
                  {notifications?.map((notification, index) => {
                    const Element = notification.el
                    const { url, target } = urlDestination(notification.url_destination, notification.type)
                    return (
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null)
                          onNotificationClick(notification)
                        }}
                        classes={{
                          root: clsx(
                            'px-4 py-2 text-base whitespace-normal hover:bg-neutral-200 border-2 border-neutral-300',
                            !notification.viewed && 'bg-blue-300',
                            classes.menuItem
                          )
                        }}
                        key={index}
                      >
                        <Element href={url} to={url} target={target} className="flex text-gray-800 w-full items-center">
                          <div className="w-12 flex justify-center ">
                            <Svg src={getIcon(notification.icon)} className="text-neutral-500" />
                          </div>
                          <div className="justify-between flex w-full items-center">
                            <p
                              className="hint-html-injected ml-4 text-sm"
                              dangerouslySetInnerHTML={{ __html: notification.text }}
                            />
                          </div>
                        </Element>
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </div>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  )
}

export default NotificationsTray

NotificationsTray.propTypes = {
  onNotificationClick: PropTypes.func,
  onViewNotifications: PropTypes.func,
  notViewed: PropTypes.number,
  notifications: PropTypes.array
}
