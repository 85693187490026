import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { Button } from '../../atoms/Button'
import * as validations from '../../../utils/validations'
import { Link, useHistory } from 'react-router-dom'
import Alert from '../../molecules/Alert'
import { isLoading } from '../../../hooks/useQuery'

export const UsersFormValidate = ({ onSubmit, showDetail, facilitaUserId, handleChangeEmail, status }) => {
  const history = useHistory()
  return (
    <Form onSubmit={onSubmit} subscription={{ submitting: true, pristine: true }} autoComplete="off">
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="px-6 py-4 md:py-8 md:pl-8 bg-neutral-100">
            <TextField
              label="Correo electrónico"
              hint="Validar si el correo ya está asociado a una cuenta en Gob.pe o si ya existe en Facilita"
              placeholder="Ingresar correo electrónico"
              name="email"
              onChange={handleChangeEmail}
              validate={validations.mix(validations.isEmail(), validations.required())}
              labelClassName="mb-4 leading-none"
            />
            {showDetail && (
              <Alert type="error" className="md:ml-4 md:w-72 lg:w-125 xl:w-148 w-full mb-6">
                {
                  'Este usuario ya se encuentra registrado en Facilita Perú; si deseas, puedes ver sus datos en Ver detalle de usuario'
                }
              </Alert>
            )}
          </div>
          <div className="flex gap-6 justify-end px-6 md:px-0 md:flex-row flex-col-reverse mt-8">
            <Button
              className="md:max-w-56 mb-4 md:mb-0"
              type="button"
              size="full"
              variant="secondary"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            {showDetail ? (
              <Button
                size="full"
                type="button"
                el={Link}
                to={`/usuarios/${facilitaUserId}/detalles`}
                className="md:max-w-56"
              >
                {'Ver detalle de usuario'}
              </Button>
            ) : (
              <Button size="full" type="submit" className="md:max-w-56" disabled={isLoading(status)}>
                {'Validar'}
              </Button>
            )}
          </div>
        </form>
      )}
    </Form>
  )
}

UsersFormValidate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showDetail: PropTypes.bool,
  status: PropTypes.string,
  facilitaUserId: PropTypes.number,
  handleChangeEmail: PropTypes.func
}
