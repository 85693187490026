import PropTypes from 'prop-types'
import React from 'react'
import TaskForm from './TaskForm'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import useTask from '../../../hooks/useTask'

const updateTask = id => values => axios.put(`/admin/v1/tasks/${id}.json`, values).then(res => res.data)

const TaskFormEdit = ({ userType, taskId, onAfterSave: afterSave, onClose: close }) => {
  const { data: task, status } = useTask(taskId)
  const queryClient = useQueryClient()
  const { mutate } = useMutation(updateTask(taskId), {
    onSuccess: task => {
      queryClient.invalidateQueries(['task', taskId])
      afterSave(task)
    }
  })
  if (status !== 'success') return null
  const initialValues = { ...task.form, ...task }
  return (
    <ModalWithAnimation
      size="full"
      open
      className="md:px-6 h-screen"
      modalClassName="justify-end md:pl-10"
      animation="slideLeft"
    >
      <TaskForm
        title={`Editar tarea para ${userType.name}`}
        buttonLabel="Guardar cambios"
        initialValues={initialValues}
        onSave={mutate}
        onClose={close}
      />
    </ModalWithAnimation>
  )
}
export default TaskFormEdit

TaskFormEdit.propTypes = {
  onAfterSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  taskId: PropTypes.number,
  userType: PropTypes.object
}
