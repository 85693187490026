import PropTypes from 'prop-types'
import React from 'react'
import { Snackbar } from '@material-ui/core'
import useMediaQuery from '../../hooks/useMediaQuery'

const Toast = ({ children, open, onClose, autoHide = false }) => {
  const matchTabletSize = useMediaQuery('md')
  if (!open) return null
  return (
    <Snackbar
      open={open}
      className="shadow-toast"
      anchorOrigin={{
        vertical: matchTabletSize ? 'bottom' : 'top',
        horizontal: 'center'
      }}
      onClose={onClose}
      autoHideDuration={autoHide ? 10000 : null}
    >
      {children}
    </Snackbar>
  )
}

export default Toast

Toast.propTypes = {
  autoHide: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool
}
