import React, { useState } from 'react'
import FileField from '../FileField'
import TextField from '../TextField'
import * as validations from '../../../../utils/validations'
import { isURL, required } from '../../../../utils/validations'
import { Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import DialogBox from './DialogBox'
import { AtomicBlockUtils } from 'draft-js'
import PropTypes from 'prop-types'
import { compressImage } from '../../../../utils/images'
import { imageSize } from '../../../../configs/imageConfig'

const extensions = ['jpg', 'jpeg', 'png']

const uploadCallback = file => {
  return new Promise(resolve => {
    const reader = new window.FileReader()
    reader.onloadend = async () => {
      resolve({ data: { link: reader.result } })
    }
    reader.readAsDataURL(file)
  })
}

const ImageDialogContent = ({ tabSelected, onTabChange, onFileUploadClick, onUploadFileChange }) => (
  <TabContext value={tabSelected}>
    <div className="mb-6 border-3">
      <TabList onChange={onTabChange} classes={{ indicator: 'h-1 bg-blue-700 top-0' }} className="bg-gray-100">
        <Tab label="Insertar URL" value="urlTab" classes={{ selected: 'bg-white text-blue-700 font-bold' }} />
        <Tab label="Cargar imagen" value="fileTab" classes={{ selected: 'bg-white text-blue-700 font-bold' }} />
      </TabList>
      <TabPanel value="urlTab">
        <TextField
          name="url"
          size="full"
          margin="none"
          placeholder="Copia aquí la URL de la imagen"
          validate={validations.mix(required(), isURL())}
        />
      </TabPanel>
      <TabPanel value="fileTab">
        <button onClick={onFileUploadClick} type="button">
          <FileField
            name="file"
            payload={{ hint: 'Peso máximo 2 mb. Solo se aceptan formatos .jpg, .jpeg, .png' }}
            required
            extensions={extensions}
            fileBackground="bg-blue-400"
            onChange={onUploadFileChange}
          />
        </button>
      </TabPanel>
    </div>
  </TabContext>
)

ImageDialogContent.propTypes = {
  tabSelected: PropTypes.string,
  onTabChange: PropTypes.func,
  onFileUploadClick: PropTypes.func,
  onUploadFileChange: PropTypes.func
}

const ImageUploadBox = ({ onClose, onChange, editorState }) => {
  const [href, setHref] = useState('')
  const [fileUpload, setFileUpload] = useState(false)
  const [tabSelected, setTabSelected] = useState('urlTab')

  const handleChangeTab = (event, newValue) => setTabSelected(newValue)

  const handleStopPropagation = event => {
    if (!fileUpload) {
      event.preventDefault()
      event.stopPropagation()
    } else setFileUpload(false)
  }

  const handleUploadFile = file => {
    const fileType = file.type
    uploadCallback(file).then(({ data }) => {
      compressImage(data.link, fileType, imageSize.big * 1.5, setHref)
      setFileUpload(false)
    })
  }

  const handleFileUploadClick = event => {
    setFileUpload(true)
    event.stopPropagation()
  }

  const handleAddFileFromState = async values => {
    let src
    if (tabSelected === 'urlTab') src = values.url
    else src = href
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'MUTABLE', {
        width: '450px',
        alt: values.alt,
        src
      })
      .getLastCreatedEntityKey()
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')
    await onChange(newEditorState)
    onClose()
  }

  return (
    <DialogBox
      maxWidth="sm"
      onStopPropagation={handleStopPropagation}
      onSubmit={handleAddFileFromState}
      titleText={`Insertar imagen`}
      dialogContent={
        <>
          <ImageDialogContent
            onFileUploadClick={handleFileUploadClick}
            onTabChange={handleChangeTab}
            onUploadFileChange={handleUploadFile}
            tabSelected={tabSelected}
          />
          <TextField
            name="alt"
            size="full"
            margin="high"
            label="Agregar texto alternativo"
            hint="Agrega una descripción de la imagen para que las personas con discapacidad visual puedan conocer su contenido mediante lectores de pantalla."
            element="textarea"
            maxLength={500}
            rows={3}
            validate={validations.maxLength(500)}
          />
        </>
      }
      onClose={onClose}
    />
  )
}

ImageUploadBox.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  editorState: PropTypes.node
}

export default ImageUploadBox
