import PropTypes from 'prop-types'
import React from 'react'
import SpecialTaskFormEnablePayment from './SpecialTaskFormEnablePayment'
import SpecialTaskFormExtensionNotice from './SpecialTaskFormExtensionNotice'
import SpecialTaskFormMakePayment from './SpecialTaskFormMakePayment'

const SpecialTaskForm = ({ kind, ...props }) => {
  const taskTypes = {
    enable_payment: SpecialTaskFormEnablePayment,
    extension_notice: SpecialTaskFormExtensionNotice,
    make_payment: SpecialTaskFormMakePayment
  }
  const El = taskTypes[kind]
  return El ? <El {...props} /> : null
}
export default SpecialTaskForm

SpecialTaskForm.propTypes = {
  kind: PropTypes.string.isRequired
}
