import PropTypes from 'prop-types'
import React from 'react'
import CategoriesForm from './CategoriesForm'
import useCategory from '../../../hooks/useCategory'

const CategoriesEdit = ({ onCancel, onFormSubmit, selectedCategory }) => {
  const { updateCategory, invalidateCategories } = useCategory()
  const handleSubmit = async values => {
    try {
      updateCategory(values, {
        onSuccess: () => {
          invalidateCategories()
          onFormSubmit()
        }
      })
    } catch (error) {
      return { name: error.response.data.message }
    }
  }

  return (
    <CategoriesForm
      onFormSubmit={handleSubmit}
      onCancel={onCancel}
      selectedCategory={selectedCategory}
      SaveButtonLabel="Guardar cambios"
      TextFieldLabel="Editar categoría"
    />
  )
}

export default CategoriesEdit

CategoriesEdit.propTypes = {
  onCancel: PropTypes.func,
  onFormSubmit: PropTypes.func,
  selectedCategory: PropTypes.object
}
