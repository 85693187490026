import React from 'react'
import Container from '../../atoms/Container'
import { Link } from 'react-router-dom'

export const CreateForm = () => {
  const cards = [
    {
      title: 'Formularios desde cero',
      body: 'Crea tu formulario, empezando con una página en blanco, e incluye las preguntas que necesites.',
      link: '/formularios/crear'
    },
    {
      title: 'Formularios con plantilla',
      body: 'Usa formularios prediseñados de trámites o servicios, elaborados por el equipo de Facilita, cumpliendo la normativa vigente.',
      link: '/formularios/crear/configuracion'
    },
    {
      title: 'Consultas rápidas',
      body: 'Brinda respuestas inmediatas sobre registros en padrones, acceso a beneficios y más información que ofrece tu entidad.',
      link: '/consultas-rapidas/crear'
    },
    {
      title: 'Gestión de trámites',
      body: 'Gestiona un trámite o servicio desde que recibes la solicitud ciudadana, hasta enviar una respuesta final.',
      link: '/gestiones-de-tramites-habilitadas'
    }
  ]

  return (
    <section className="pt-14 md:pt-20 pb-14 md:pb-24 px-8 md:px-0">
      <Container className="lg:px-36 md:px-10">
        <h2 className="mb-7 md:mb-12 font-bold text-2xl text-center">Tipos de formularios en Facilita</h2>
        <ul className="grid gap-4 grid-cols-2 md:grid-cols-4 md:container pb-2">
          {cards.map(card => (
            <li key={card.title}>
              <Link
                to={card.link}
                className="h-full flex flex-col md:py-8 md:px-8 py-5 px-4 justify-start items-center gap-6 text-black border-solid border-2 border-neutral-400 rounded-lg hover:border-blue-700 shadow-md"
              >
                <h3 className="font-bold text-xl">{card.title}</h3>
                <p className="text-sm">{card.body}</p>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
