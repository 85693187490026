import PropTypes from 'prop-types'
import React, { createContext, useContext, useState } from 'react'
import Paper from '../../molecules/Paper'

const ErrorWithinPaperContext = createContext(null)
export const useErrorWithinPaper = () => useContext(ErrorWithinPaperContext)

export const PaperContainerErrorsHandler = ({ children }) => {
  const [namesWithError, setNamesWithError] = useState([])

  const setError = (name, gotError) => {
    if (gotError) {
      if (!namesWithError.includes(name)) {
        setNamesWithError([...namesWithError, name])
      }
    } else {
      setNamesWithError(oldNamesWithError => oldNamesWithError.filter(v => v !== name))
    }
  }

  return (
    <ErrorWithinPaperContext.Provider value={{ setError }}>
      <Paper depth="2xl" className="border md:w-10/12 lg:w-11/12" namesWithError={namesWithError}>
        {children}
      </Paper>
    </ErrorWithinPaperContext.Provider>
  )
}

PaperContainerErrorsHandler.propTypes = {
  children: PropTypes.node
}
