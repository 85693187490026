import PropTypes from 'prop-types'
import React from 'react'
import { ReactSVG as Svg } from 'react-svg'
import arrowRight from '../../../images/icons/arrow_right_short.svg'

const TaskHistoryOriginDestinyUserLabels = ({ originUserLabel, destinyUserLabel }) => {
  return (
    <div className="flex items-center text-gray-500 mr-10 w-full">
      {destinyUserLabel ? (
        <>
          <span className="max-w-3/4 text-justify">{originUserLabel}</span>
          <Svg src={arrowRight} className="mx-4" />
          <span className="max-w-3/4">{destinyUserLabel}</span>
        </>
      ) : (
        originUserLabel
      )}
    </div>
  )
}
export default TaskHistoryOriginDestinyUserLabels

TaskHistoryOriginDestinyUserLabels.propTypes = {
  destinyUserLabel: PropTypes.string,
  originUserLabel: PropTypes.string
}
