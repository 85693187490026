import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form as FinalForm } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { clearMutator, customMoveMutator, customSwapMutator, markForDestroyMutator } from '../../../utils/formMutators'
import Setup from './Setup'
import { focusOnFirstError } from '../../../utils/decorators'
import SaveButton from '../../molecules/buttons/SaveButton'
import { setupSerializedFormData } from '../../../configs/formsConfig'
import pageMutators, { markForDestroyPageMutator } from '../../../utils/pageMutators'

const Form = ({ template, mutateForm }) => {
  const [uploadProgress, setUploadProgress] = useState(null)
  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  const handleSubmit = async values => {
    const formData = setupSerializedFormData(values)
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    try {
      await mutateForm({ values: formData, config })
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  return (
    <>
      <FinalForm
        initialValues={template}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
          swap: customSwapMutator,
          move: customMoveMutator,
          clear: clearMutator,
          markForDestroy: markForDestroyMutator,
          markForDestroyPage: markForDestroyPageMutator,
          ...pageMutators
        }}
        decorators={[focusOnFirstError]}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="mb-10">
            <Setup />
            <SaveButton uploadProgress={uploadProgress} disabled={submitting} />
          </form>
        )}
      </FinalForm>
    </>
  )
}
export default Form

Form.propTypes = {
  mutateForm: PropTypes.func.isRequired,
  template: PropTypes.object
}
