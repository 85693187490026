import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Container from '../atoms/Container'
import ExportsTable from '../organisms/exports/ExportsTable'
import { Pagination } from '../molecules/Pagination'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { PageNavigation } from '../organisms/navigation/PageNavigation'

const destroyExport = exportId => axios.delete(`/admin/v1/exports/${exportId}.json`)

const Exports = ({ fetchExports, generalData = {}, exportDescription, exportableId = null, filterLabel }) => {
  const location = useLocation()
  const { data: exportsData, status: exportsStatus } = useQuery(
    ['exportaciones', location.search, exportableId],
    () => fetchExports(location.search, exportableId),
    { keepPreviousData: true }
  )
  const queryClient = useQueryClient()
  const { mutate } = useMutation(destroyExport, {
    onSuccess: () => queryClient.invalidateQueries(['exportaciones', location.search]),
    onError: () => window.alert('Ocurrió un error')
  })

  const handleDestroy = async item => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la exportacion ${item.id}?`)) {
      mutate(item.id)
    }
  }

  if (exportsStatus !== 'success') return null
  return (
    <Fragment>
      <PageNavigation breadcrumbData={generalData} />
      <Container className="px-4">
        <div className="my-10 flex">
          <div className="flex-1 md:pr-12">
            <h1 aria-describedby="page-description" className="text-4xl font-bold">
              Historial de exportaciones
            </h1>
            <p id="page-description">{exportDescription}</p>
          </div>
        </div>
        <div className="overflow-x-auto">
          <ExportsTable exports={exportsData.data} onDestroy={handleDestroy} filterLabel={filterLabel} />
        </div>
        <Pagination numPages={exportsData.total_pages} />
      </Container>
    </Fragment>
  )
}

export default Exports

Exports.propTypes = {
  exportDescription: PropTypes.string,
  exportableId: PropTypes.string,
  fetchExports: PropTypes.func,
  filterLabel: PropTypes.object,
  generalData: PropTypes.object
}
