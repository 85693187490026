import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Analytics = () => {
  const location = useLocation()
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'page_view',
        page: location.pathname + location.search
      })
    }
  }, [location])
  return null
}

Analytics.propTypes = {}

export default Analytics
