import PropTypes from 'prop-types'
import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import Value from '../../molecules/Value'

const StepManualEntryName = ({ baseName, size }) => {
  const hasManualEntryName = `${baseName}.has_manual_entry_name`
  const labelName = `${baseName}.manual_entry_name`
  return (
    <div className="mt-4">
      <CheckboxField
        name={hasManualEntryName}
        className="ml-0.5"
        margin="dense"
        label="Quiero que la etiqueta cambie en la vista de Ingresar solicitud manual para el funcionario"
        noYpadding
      />
      <WhenFieldChanges field={hasManualEntryName} set={labelName} to={null} />
      <Value name={hasManualEntryName}>
        {({ value }) =>
          value && (
            <div className="ml-8 flex flex-col items-start rounded bg-neutral-300">
              <TextField
                label="Etiqueta para la vista de Ingresar solicitud manual"
                name={labelName}
                margin="dense"
                validate={validations.required()}
                dataTestid="hintQuestion"
                size={size}
                rootClassName="w-full"
              />
            </div>
          )
        }
      </Value>
    </div>
  )
}

export default StepManualEntryName

StepManualEntryName.propTypes = {
  baseName: PropTypes.string,
  size: PropTypes.string
}
