import PropTypes from 'prop-types'
import React from 'react'
import { TabList as TabListMUI } from '@material-ui/lab'

export const TabList = ({ onChange, className, children }) => {
  return (
    <TabListMUI
      onChange={onChange}
      className={className}
      classes={{ indicator: 'h-1 bg-blue-700 top-0' }}
      variant="fullWidth"
    >
      {children}
    </TabListMUI>
  )
}

TabList.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node
}
