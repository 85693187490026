import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { numberFormatter } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'

export const PaymentAmountTag = ({ amount, className }) => {
  const { t } = useTranslation('currency')
  return (
    <span
      className={clsx(
        'inline-block border-green-600 border border-solid font-semibold bg-green-500 text-green-800 leading-5 text-sm md:text-base p-1',
        className
      )}
    >
      {numberFormatter.currency(amount, t('soles', { ns: 'currency' }))}
    </span>
  )
}

PaymentAmountTag.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}
