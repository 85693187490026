import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import QuestionOptionsSection from './QuestionOptionsSection'
import EmailInfo from './EmailInfo'

const EmailField = ({ baseName, validate, index, stepIndex }) => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <span>
        <TextField
          label="Etiqueta de la pregunta"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validate}
          margin="none"
          errorClassName="relative inset-0"
          initialValue=""
        />
        <QuestionOptionsSection baseName={baseName} margin="none" size="full" />
      </span>
      <EmailInfo index={index} stepIndex={stepIndex} />
    </div>
  )
}

export default EmailField

EmailField.propTypes = {
  baseName: PropTypes.string,
  index: PropTypes.number,
  stepIndex: PropTypes.number,
  validate: PropTypes.func
}
