import PropTypes from 'prop-types'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import UserTypeForm from './UserTypeForm'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'

const fetchUserType = id => axios.get(`/admin/v1/user_types/${id}.json`).then(res => res.data)

const updateUserType = id => values => axios.put(`/admin/v1/user_types/${id}.json`, values).then(res => res.data)

const UserTypeFormEdit = ({ UserTypeId, onAfterSave: afterSave, onClose: close }) => {
  const { data: userType, status } = useQuery(['user_type', UserTypeId], () => fetchUserType(UserTypeId), {
    refetchOnWindowFocus: false
  })
  const { mutate } = useMutation(updateUserType(UserTypeId), {
    onSuccess: userType => afterSave(userType)
  })
  if (status !== 'success') return null
  return (
    <ModalWithAnimation
      size="full"
      open
      className="md:px-6 h-screen"
      modalClassName="justify-end md:pl-10"
      animation="slideLeft"
    >
      <UserTypeForm
        title="Editar tipo de usuario"
        buttonLabel="Guardar cambios"
        initialValues={userType}
        onSave={mutate}
        onClose={close}
      />
    </ModalWithAnimation>
  )
}
export default UserTypeFormEdit

UserTypeFormEdit.propTypes = {
  UserTypeId: PropTypes.number,
  onAfterSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
