import React from 'react'
import { FormControl, FormLabel } from '@material-ui/core'
import CheckboxField from '../../molecules/fields/CheckboxField'
import RolesFilter from '../filters/RolesFilter'

const NotificationsFilter = () => {
  return (
    <div className="md:flex">
      <RolesFilter label="Destinatarios" />
      <FormControl component="fieldset" classes={{ root: 'mt-6 md:pl-14 md:mt-0' }}>
        <FormLabel component="legend" className="font-bold text-gray-800 text-base">
          Estatus
        </FormLabel>
        <CheckboxField name="status" label="Enviado" value="send" margin="none" />
        <CheckboxField name="status" label="Programado" value="scheduled" margin="none" />
      </FormControl>
    </div>
  )
}

export default NotificationsFilter
