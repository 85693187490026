import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import { Button } from '../../atoms/Button'
import React, { useEffect, useState } from 'react'
import useLanguages from '../../../hooks/useLanguages'

const FormStartMessage = () => {
  const { checkFormsInDisabledLanguages } = useLanguages()

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    setOpenModal(checkFormsInDisabledLanguages?.forms_exist_in_disabled_languages)
  }, [checkFormsInDisabledLanguages?.forms_exist_in_disabled_languages])

  const handleAcceptMessage = () => {
    setOpenModal(false)
    localStorage.setItem('showFormStartMessage', 'false')
  }

  return (
    <ModalWithAnimation open={openModal}>
      <h2 className="header font-bold text-3xl mb-4">Cambio en los idiomas disponibles</h2>
      <div className="body">
        <p className="mb-4">Los siguientes idiomas han sido temporalmente deshabilitados en Facilita:</p>
        <p className="font-bold mb-4">Idiomas desahibilitados: [{checkFormsInDisabledLanguages?.message}]</p>
        <p className="mb-4">
          Aunque los idiomas mencionados están ahora deshabilitados, las traducciones previas realizadas en estos
          idiomas seguirán siendo accesibles y funcionarán como hasta ahora. Sin embargo, no será posible crear nuevas
          traducciones en estos idiomas hasta nuevo aviso.
        </p>
        <p className="mb-4">
          De tener consultas, puedes comunicarte con el equipo de soporte en mesadeayuda@gobiernodigital.gob.pe
        </p>
      </div>
      <div className="footer text-center">
        <Button type="button" className="m-auto mt-6" onClick={handleAcceptMessage}>
          Aceptar
        </Button>
      </div>
    </ModalWithAnimation>
  )
}

export default FormStartMessage
