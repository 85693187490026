import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ReactSVG as Svg } from 'react-svg'
import UndoIcon from '../../../images/icons/undo.svg'
import ConfirmDialog from '../../molecules/dialogs/ConfirmDialog'

const TaskHistoryReverseTaskButton = ({ onReverseTask, destinyUserLabel }) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  return (
    <>
      <button
        className="font-bold flex items-center text-blue-700"
        onClick={e => {
          e.stopPropagation()
          setOpenConfirm(true)
        }}
        type="button"
      >
        <Svg src={UndoIcon} className="mr-1" />
        Revertir tarea
      </button>
      <ConfirmDialog
        open={openConfirm}
        title="Revertir envío de tarea"
        onClose={e => {
          e.stopPropagation()
          setOpenConfirm(false)
        }}
        confirmLabel="Revertir tarea"
        onConfirm={e => {
          e.stopPropagation()
          onReverseTask()
        }}
      >
        <>
          Puedes revertir el envío de la tarea hacia el <span className="font-bold">{destinyUserLabel}</span> mientras
          este no haya respondido. Una vez revertida se eliminará lo enviado,{' '}
          <span className="font-bold">¿seguro que deseas revertirla?</span>
        </>
      </ConfirmDialog>
    </>
  )
}
export default TaskHistoryReverseTaskButton

TaskHistoryReverseTaskButton.propTypes = {
  destinyUserLabel: PropTypes.string,
  onReverseTask: PropTypes.func.isRequired
}
