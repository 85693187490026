import PropTypes from 'prop-types'
import React from 'react'
import FileField from '../question_config_fields/FileField'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import PublishIcon from '@material-ui/icons/Publish'
import FileCitizenField from '../question_fields/FileCitizenField'

const Form = props => {
  return <FileField baseName={props.baseName} />
}

const Answer = props => {
  return (
    <BoldLabelAndContent label={props.question.payload?.label} el="li">
      <ul className="list-disc list-inside force-list-unordered ml-4 mt-px">
        {props.answer?.map((file, idx) => (
          <li key={idx}>
            <a href={file.url} target="_blank" key={idx} rel="noreferrer">
              {file.name}
            </a>
          </li>
        ))}
      </ul>
    </BoldLabelAndContent>
  )
}

const TaskResponse = props => {
  return (
    <BoldLabelAndContent label={`${props.question.payload?.label}:`} className="flex flex-col">
      <ul className="list-inside list-disc pl-2">
        {props.answer?.map((file, idx) => (
          <li key={idx}>
            <a href={file.url} target="_blank" rel="noreferrer">
              {file.name}
            </a>
          </li>
        ))}
      </ul>
    </BoldLabelAndContent>
  )
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <FileField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <FileCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const FileQuestion = {
  selectOption: { value: 'file', label: 'Archivo', icon: <PublishIcon fontSize="small" /> },
  Form,
  Template,
  Answer,
  TaskResponse,
  name: 'FileQuestion'
}

export default FileQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Answer.propTypes = {
  answer: PropTypes.array,
  question: PropTypes.object.isRequired
}

TaskResponse.propTypes = {
  answer: PropTypes.array,
  question: PropTypes.object
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
