import createDecorator from 'final-form-submit-listener'
import { findParent } from './dom'

export const focusOnFirstError = createDecorator({
  beforeSubmit: () => firstErrorFocus(),
  afterSubmitFailed: () => firstErrorFocus()
})

const firstErrorFocus = () =>
  setTimeout(() => {
    const allErrors = document.querySelectorAll('.js-error')
    if (allErrors.length) {
      const notHiddenError = Array.from(allErrors).find(error => !findParent(error, 'js-hidden-container'))
      const parent = findParent(notHiddenError, 'js-field-container')
      parent.scrollIntoView({ behavior: 'smooth' })
    }
  }, 100)

export const preventSubmitPrivacyPolicy = createDecorator({
  beforeSubmit: formApi => preventSubmitWithoutPrivacyPolicy(formApi)
})

const preventSubmitWithoutPrivacyPolicy = formApi => {
  const alreadyAsked = formApi.getFieldState('already_asked_submit_without_privacy_policy').value
  const policyPrivacy = formApi.getFieldState('privacy_policy').value
  if (policyPrivacy) return
  if (!alreadyAsked) {
    const valid = formApi.getState().valid
    if (!policyPrivacy && valid) {
      formApi.change('already_asked_submit_without_privacy_policy', true)
      return false
    }
  }
}
