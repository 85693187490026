import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import { LinearProgressBar } from '../../atoms/LinearProgressBar'
import TransferAccountsProcedure from './TransferAccountsProcedure'

export const BankTransferenceFormCitizen = ({ onBack, context, uploadProgress }) => {
  const form = context.form_data
  return (
    <>
      <TransferAccountsProcedure cost={form.cost} paymentTransferAccounts={form.available_payment_transfer_accounts} />
      <div className="flex flex-col-reverse md:flex-row justify-end">
        <div className="md:w-1/3 lg:w-1/4 md:mr-3">
          <Button onClick={onBack} variant="secondary" className="" size="full">
            Regresar
          </Button>
        </div>
        <div className="md:w-1/3 lg:w-1/4 mb-3 md:mb-0">
          <Button type="submit" disabled={uploadProgress !== null} size="full">
            Enviar formulario
          </Button>
          <LinearProgressBar percentage={uploadProgress} />
        </div>
      </div>
    </>
  )
}

BankTransferenceFormCitizen.propTypes = {
  context: PropTypes.object,
  onBack: PropTypes.func,
  uploadProgress: PropTypes.number
}
