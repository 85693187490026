import React from 'react'
import LinkIcon from 'images/link.svg'
import useToggle from '../../../../hooks/useToggle'
import LinkToolBarBox from './LinkToolBarBox'

export const LinkToolBar = props => {
  const [toggleModal, setToggleModal] = useToggle()
  return (
    <button
      className="relative mx-1 h-5 w-6 border-gray-30 border-solid rounded-sm flex justify-center items-center border-px"
      onClick={() => setToggleModal()}
      title="Insertar URL"
      type="button"
    >
      <img src={LinkIcon} alt="" className="cursor-pointer max-h-full" />
      {toggleModal && <LinkToolBarBox onClose={setToggleModal} type="link" {...props} />}
    </button>
  )
}
