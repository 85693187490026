import PropTypes from 'prop-types'
import React from 'react'
import instructionsIcon from '../../images/icons/instructions.svg'

const Instructions = ({ list }) => {
  return (
    <div className="bg-white p-6 my-6 md:flex items-center rounded">
      <img
        src={instructionsIcon}
        aria-hidden="true"
        alt="instructions-icon"
        className="mr-8 mb-4 md:mb-0 w-8 md:w-auto"
      />
      <ul className="list-inside list-disc ml-3 md:ml-0">
        {list.map(instruction => (
          <li key={instruction} dangerouslySetInnerHTML={{ __html: instruction }} />
        ))}
      </ul>
    </div>
  )
}
export default Instructions

Instructions.propTypes = {
  list: PropTypes.array.isRequired
}
