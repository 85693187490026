import PropTypes from 'prop-types'
import React from 'react'
import ConfirmDialog from '../../molecules/dialogs/ConfirmDialog'
import { addYAtTheEndComponent } from '../../../utils/helpers'

const DialogChangeAreaMessage = ({ open, onClose, area, userId, enabledProcedureManagements, onConfirm }) => {
  if (!userId) return null

  const enabledProcedureManagementList = enabledProcedureManagements.map(el => ({
    text: el.name,
    props: {
      href: `/admin/gestiones-de-tramites-habilitadas/${el.id}/areas-y-usuarios?user-id=${userId}`,
      target: '_blank',
      className: 'font-bold'
    }
  }))

  const enabledProcedureManagementLinks = addYAtTheEndComponent(enabledProcedureManagementList, 'a')

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={`Este usuario se encuentra asignado a una Gestión de trámite con su área actual (${area})`}
      rightButton={{
        text: 'No, cancelar',
        onClick: onClose
      }}
      leftButton={{
        text: 'Sí, continuar',
        onClick: onConfirm
      }}
    >
      <p className="text-sm">
        Al cambiarlo de área, el usuario será desasignado del trámite {enabledProcedureManagementLinks}. ¿Deseas
        continuar con el cambio?
      </p>
    </ConfirmDialog>
  )
}

export default DialogChangeAreaMessage

DialogChangeAreaMessage.propTypes = {
  open: PropTypes.bool,
  area: PropTypes.string,
  userId: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  enabledProcedureManagements: PropTypes.array
}
