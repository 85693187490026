import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { TruncatedTextWithTooltip } from '../../molecules/TruncatedTextWithTooltip'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import TranslateButton from '../../molecules/buttons/TranslateButton'

const dateAndUserRow = (date, user) => (
  <>
    <div>{date}</div>
    <div>
      por <span className="font-bold">{user}</span>
    </div>
  </>
)

const ProcedureManagementsTable = ({ procedureManagements = [], onDestroy }) => {
  return (
    <div className="pt-8 pb-2">
      <Table
        className="table-fixed lg:w-full"
        values={procedureManagements}
        head={
          <Tr body={false}>
            <Th className="w-4">ID</Th>
            <Th>Nombre</Th>
            <Th className="w-64">Creación</Th>
            <Th className="w-64">Actualización</Th>
            <Th className="w-36">Publicación</Th>
            <Th>Idiomas</Th>
            <Th>Acciones</Th>
          </Tr>
        }
        body={procedureManagements.map(row => (
          <Tr key={row.id}>
            <Td>{row.id}</Td>
            <Td>
              <TruncatedTextWithTooltip text={row.name}>
                {props => (
                  <Link to={`/gestiones-de-tramites/${row.id}/vista-previa`} {...props}>
                    {row.name}
                  </Link>
                )}
              </TruncatedTextWithTooltip>
            </Td>
            <Td>{dateAndUserRow(row.created_at, row.creator_name)}</Td>
            <Td>{dateAndUserRow(row.updated_at, row.updater_name)}</Td>
            <Td>{row.published ? 'Publicado' : 'No publicado'}</Td>
            <Td>{row.languages}</Td>
            <Td>
              <EditButton to={`/gestiones-de-tramites/${row.id}/editar`} />
              <TranslateButton to={`/gestiones-de-tramites/${row.id}/traducciones`} />
              <DeleteButton onClick={() => onDestroy(row)} />
            </Td>
          </Tr>
        ))}
      />
    </div>
  )
}

export default ProcedureManagementsTable

ProcedureManagementsTable.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  procedureManagements: PropTypes.arrayOf(PropTypes.object)
}
