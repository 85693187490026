import PropTypes from 'prop-types'
import React from 'react'
import LabelInputAndInfo from '../question_config_fields/LabelInputAndInfo'
import TextWithInfoField from '../../molecules/TextWithInfoField'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import InfoIcon from '@material-ui/icons/Info'

const infoContent = 'Al usuario le aparecerá una lista con todas las entidades públicas.'

const Form = props => {
  return <LabelInputAndInfo baseName={props.baseName} infoContent={infoContent} />
}

const Template = props => {
  return <TextWithInfoField payload={props.question.payload} name={props.name} infoContent={infoContent} disabled />
}

const TaskResponse = ({ answer, question }) => (
  <>
    <BoldLabelAndContent label={`${question.payload.label}:`} content={answer.institution} className="mb-0" />
    {answer.branch_office && (
      <BoldLabelAndContent label="Selecciona la sede:" content={answer.branch_office} className="mb-0" />
    )}
    {!answer.enabled_procedure_managements_id && (
      <>
        <BoldLabelAndContent label="Mensaje al ciudadano:" content={answer.citizen_message} />
        <div className="p-6 rounded-lg bg-yellow-400 flex">
          <InfoIcon className="mr-4 text-yellow-800" />
          <p className="text-gray-800">
            Recuerda que la solicitud no ha sido derivada a través de Plataforma de Transparencia y Acceso a Información
            Pública en Facilita.
          </p>
        </div>
      </>
    )}
  </>
)

const ExternalInstitutionTaskQuestion = {
  selectOption: {
    value: 'external_institution_task',
    label: 'Entidad a donde se enviará la solicitud',
    icon: <AccountBalanceOutlinedIcon fontSize="small" />
  },
  TaskResponse,
  Form,
  Template,
  name: 'ExternalInstitutionTaskQuestion'
}

export default ExternalInstitutionTaskQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object
}

TaskResponse.propTypes = {
  answer: PropTypes.object,
  question: PropTypes.object.isRequired
}
