import useCrud from './useCrud'
import axios from 'axios'

export const urlBaseTerms = `/admin/v1/terms.json`

const useTerms = termId => {
  const { findById, update } = useCrud('/admin/v1/terms')
  const getTerm = id => findById(['term', termId])
  const { mutate: updateTerm } = update()

  const importTerms = async file => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.post(`/admin/v1/terms/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    return response
  }

  return { getTerm, updateTerm, importTerms }
}

export default useTerms
