import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { Button } from '../../atoms/Button'
import * as validations from '../../../utils/validations'
import useUserPermission from '../../../hooks/useUserPermission'
import SelectField from '../../molecules/fields/SelectField'
import useBranchOffices from '../../../hooks/useBranchOffice'
import { useAuth } from '../../molecules/Auth'
import { dataToOptions } from '../../../utils/helpers'
import { isSuccessful } from '../../../hooks/useQuery'

export const AreasForm = ({ initialValues = {}, onSubmit, create }) => {
  const { user } = useAuth()
  const { canManageAreas } = useUserPermission('area')
  const { canFilterByBranchOffice } = useUserPermission('user')
  const { getBranchOffices } = useBranchOffices(user.institution_id)
  const { data: branchOffices, status: branchOfficesStatus } = getBranchOffices()

  if (!isSuccessful(branchOfficesStatus)) return null

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      initialValues={initialValues}
      autoComplete="off"
    >
      {({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="px-4 py-4 md:py-8 md:pl-8 bg-neutral-100">
            <TextField
              label="Nombre del área"
              name="name"
              validate={validations.mix(validations.required(), validations.isLetter())}
              labelClassName="mb-4 leading-none"
              disabled={!canManageAreas}
            />
            {canFilterByBranchOffice && (
              <SelectField
                name="branch_office_id"
                size="2xl"
                options={dataToOptions(branchOffices)}
                validate={validations.required()}
                label="Sede en la que se creará el área"
                emptyLabel="Selecciona una sede"
              />
            )}
          </div>
          <div className="flex justify-end">
            <Button
              size="full"
              type="submit"
              className="block mt-12 md:mt-6 text-lg py-3 md:max-w-56"
              disabled={submitting || pristine}
            >
              {create ? 'Crear área' : 'Guardar cambios'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

AreasForm.propTypes = {
  create: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}
