import PropTypes from 'prop-types'
import React from 'react'
import ShortQuestionField from '../question_config_fields/ShortQuestionField'
import * as validations from '../../../utils/validations'
import ShortTextIcon from '@material-ui/icons/ShortText'
import ShortTextCitizenField from '../question_fields/ShortTextCitizenField'

const Form = props => {
  return <ShortQuestionField baseName={props.baseName} validate={validations.required()} />
}

const Template = props => {
  return <ShortTextCitizenField payload={props.question.payload} name={props.name} disabled />
}

const ShortTextQuestion = {
  selectOption: { value: 'short_text', label: 'Texto corto', icon: <ShortTextIcon fontSize="small" /> },
  Form,
  Template,
  name: 'ShortTextQuestion'
}

export default ShortTextQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
