import queryString from 'query-string'
import useSearchParams from '../useSearchParams'
import { roles } from '../../configs/permissionsConfig'

export const useRedirectToGdt = () => {
  const searchParams = useSearchParams()
  const hasRedirectToGdt = searchParams.has('redirect_to_gdt')

  const redirectToGdtId = searchParams.get('redirect_to_gdt')
  const redirectToGdtBranchOfficeId = searchParams.get('gdt_branch_office_id')
  const redirectToGdtAreaId = searchParams.get('gdt_area_id')

  const urlRedirectParams = {
    gdt_area_id: redirectToGdtAreaId,
    gdt_branch_office_id: redirectToGdtBranchOfficeId,
    redirect_to_gdt: redirectToGdtId
  }
  const redirectToGdtUrl = hasRedirectToGdt ? queryString.stringify(urlRedirectParams) : ''

  const initialParams = {
    area_id: redirectToGdtAreaId,
    branch_office_id: redirectToGdtBranchOfficeId,
    role: roles.collaborator
  }
  const gdtInitialValues = hasRedirectToGdt ? initialParams : {}

  return { hasRedirectToGdt, redirectToGdtUrl, redirectToGdtId, gdtInitialValues }
}
