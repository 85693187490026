import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import SelectField from '../../molecules/fields/SelectField'
import Value from '../../molecules/Value'
import FileField from '../../molecules/fields/FileField'
import SwitchField from '../../molecules/fields/SwitchField'
import { HtmlField } from '../../molecules/fields/HtmlField'
import { privacyPolicyExampleTypeOptions, selectsOptions, switches } from '../../../configs/templatesConfig'
import formValidations from '../../../utils/formValidations'
import { getFirstQuestionByType } from '../../../configs/questionTypesAdminConfig'
import useFieldValue from '../../../hooks/useFieldValue'
import CoverImage from '../forms/config_items/CoverImage'

const extensions = ['doc', 'docx']

const Setup = () => {
  const stepsAttributes = useFieldValue('steps_attributes')
  const { question: firstEmailQuestion } = getFirstQuestionByType(stepsAttributes, 'email')
  return (
    <div className="bg-neutral-100 px-4 py-8 md:px-12 md:py-6 mb-6">
      <div className="text-2xl font-bold">Información y configuración general</div>

      <div className="py-8 border-b border-neutral-400">
        <TextField
          name="name"
          label="Nombre del trámite"
          hint="Es el nombre que tendrá el formulario. Este no podrá ser editado por el funcionario público."
          margin="none"
          size="2xl"
          validate={validations.required()}
        />
      </div>
      <div className="pt-8 border-b border-neutral-400">
        <CoverImage />
      </div>
      <div className="pt-8 border-b border-neutral-400">
        <HtmlField
          name="hint"
          label="Indicación general del formulario"
          hint="Es la indicación que se le dará al usuario para que sepa qué acción realizará al completar el formulario."
          placeholder="La recepción de documentos es de 8:00 a. m. a 6:00 p. m. Ingresa tu solicitud y un especialista se comunicará contigo."
          maxContentLength={1000}
          validate={validationsAdmin.isRichTextFieldEmpty()}
        />
      </div>

      {switches.map((item, index) => (
        <div className="flex items-center justify-between py-8 border-b border-neutral-400" key={index}>
          <label htmlFor={`${item.name}-input`} className="font-bold mr-3">
            {item.label}
          </label>
          <SwitchField name={item.name} />
        </div>
      ))}

      {selectsOptions.map((item, index) => (
        <div className="md:flex items-center justify-between py-8 border-b border-neutral-400" key={index}>
          <label htmlFor={`${item.name}-input`} className="font-bold w-full md:w-2/4">
            {item.label}
          </label>
          <SelectField
            name={item.name}
            options={item.options}
            margin="none"
            containerClassName="mt-2 md:mt-0 min-w-60"
            parentClassName="flex justify-end"
            size="w-60"
            errorClassName="relative -mt-6 text-left md:text-right"
            validate={validations.mix(
              validations.required(),
              item.name === 'auto_response' && formValidations.validateAutoResponse(firstEmailQuestion)
            )}
          />
        </div>
      ))}

      <div className="md:flex items-center justify-between py-8">
        <div>
          <label htmlFor="privacy_policy_example_type-input" className="font-bold block">
            ¿Qué ejemplo de política de privacidad deseas que el usuario descargue?
          </label>
          <a className="font-bold" href="/Política%20de%20Privacidad%202024-1.docx" target="_blank">
            Revisa el ejemplo estándar
          </a>
        </div>
        <SelectField
          name={'privacy_policy_example_type'}
          options={privacyPolicyExampleTypeOptions}
          margin="none"
          containerClassName="mt-2 md:mt-0 min-w-60"
          validate={validations.required()}
          emptyLabel="Selecciona el documento"
        />
      </div>

      <Value name="privacy_policy_example_type">
        {({ value: privacyPolicyExampleType }) =>
          privacyPolicyExampleType === 'modified' && (
            <>
              <p>Sube el archivo en formato .doc o .docx</p>
              <FileField name="privacy_policy" className="mt-2" extensions={extensions} required />
            </>
          )
        }
      </Value>
    </div>
  )
}
export default Setup
