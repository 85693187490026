import PropTypes from 'prop-types'
import React from 'react'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'

const ExportsTable = ({ exports = [], onDestroy, filterLabel }) => {
  return (
    <div className="pb-8 pt-2">
      <Table
        spacing="normal"
        className="table-auto w-full"
        values={exports}
        head={
          <Tr body={false}>
            <Th>ID</Th>
            <Th>Creado por</Th>
            <Th>Fecha de creación</Th>
            <Th>Filtros aplicados</Th>
            <Th>Archivo</Th>
            <Th>Acciones</Th>
          </Tr>
        }
        body={exports.map(item => (
          <Tr className="bg-neutral-200" key={item.id}>
            <Td>{item.id}</Td>
            <Td>{item.user}</Td>
            <Td>{item.created_at}</Td>
            <Td>
              {item.search_params
                ? Object.entries(item.search_params).map(([key, value]) => (
                    <div key={key}>
                      {filterLabel[key]}: {value.toString()}
                    </div>
                  ))
                : 'Resultados completos (sin filtros)'}
            </Td>
            <Td>
              {item.url ? (
                <a target="_blank" href={item.url} rel="noreferrer">
                  Descargar
                </a>
              ) : (
                'En proceso'
              )}
            </Td>
            <Td>
              <DeleteButton onClick={() => onDestroy(item)} />
            </Td>
          </Tr>
        ))}
      />
    </div>
  )
}

export default ExportsTable

ExportsTable.propTypes = {
  exports: PropTypes.arrayOf(PropTypes.object),
  filterLabel: PropTypes.object,
  onDestroy: PropTypes.func.isRequired
}
