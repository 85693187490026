import React, { Fragment, useMemo } from 'react'
import { AreasForm } from '../../../organisms/areas_users/AreasForm'
import axios from 'axios'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import Container from '../../../atoms/Container'
import useSearchParams from '../../../../hooks/useSearchParams'
import { getAxiosErrorMessages } from '../../../../utils/helpers'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

export const AreasNew = () => {
  const history = useHistory()

  const handleSubmit = async values => {
    try {
      const res = await axios.post(`/admin/v1/areas.json`, values)
      const urlSaveParams = {
        institution_id: res.data.institution_id,
        branch_office_id: res.data.branch_office_id
      }
      history.push(`/areas-y-usuarios?${queryString.stringify(urlSaveParams)}`)
    } catch (error) {
      if (error.response?.data) {
        return getAxiosErrorMessages(error)
      } else {
        alert('Ocurrió un error al intentar guardar.')
      }
    }
  }

  const searchParams = useSearchParams()
  const branchOfficeId = searchParams.get('branch_office_id')

  const initialValues = useMemo(
    () => ({
      name: '',
      branch_office_id: branchOfficeId
    }),
    []
  )

  return (
    <Fragment>
      <PageNavigation />
      <Container className="py-16 px-6 md:px-0">
        <h1 className="mb-4 font-bold text-2xl">Crear área</h1>
        <AreasForm onSubmit={handleSubmit} initialValues={initialValues} create />
      </Container>
    </Fragment>
  )
}
