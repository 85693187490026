import React, { Fragment, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Container from '../../../atoms/Container'
import { UsersFormValidate } from '../../../organisms/areas_users/UsersFormValidate'
import useUser from '../../../../hooks/useUser'
import { isSuccessful } from '../../../../hooks/useQuery'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { useRedirectToGdt } from '../../../../hooks/procedures_managements/useRedirectToGdt'

const UsersValidate = () => {
  const { getVerifyByEmail } = useUser()
  const [email, setUser] = useState('')
  const { data, status } = getVerifyByEmail(email)
  const [emailValidated, setEmailValidated] = useState(false)
  const { redirectToGdtUrl } = useRedirectToGdt()

  const handleSubmit = async values => {
    setUser(values.email)
    setEmailValidated(true)
  }

  const handleChangeEmail = () => {
    setEmailValidated(false)
  }

  if (isSuccessful(status)) {
    if (data.facilita === false && data.gobpe === false) {
      return <Redirect to={`/usuarios/crear?email=${email}&${redirectToGdtUrl}`} />
    }
    if (data.gobpe && data.facilita === false) {
      return <Redirect to={`/usuarios/detalles-desde-gobpe?email=${email}&${redirectToGdtUrl}`} />
    }
  }

  return (
    <Fragment>
      <PageNavigation />
      <Container className="py-16 md:px-0">
        <h1 className="mb-4 font-bold text-2xl px-6 md:px-0">Crear usuario</h1>
        <UsersFormValidate
          onSubmit={handleSubmit}
          showDetail={emailValidated && data?.facilita}
          facilitaUserId={data?.facilita_user_id}
          handleChangeEmail={handleChangeEmail}
          status={status}
        />
      </Container>
    </Fragment>
  )
}

export default UsersValidate
