import React, { useMemo } from 'react'
import QueryPageForm from '../../../organisms/quick_searches/QueryPageForm'
import useQuickSearch from '../../../../hooks/useQuickSearch'

const QuickSearchNew = () => {
  const { createQuickSearch } = useQuickSearch()

  const initialValues = useMemo(
    () => ({
      results: [{ title: '' }, { title: '' }],
      validation_information: 'none'
    }),
    []
  )

  return <QueryPageForm mutate={createQuickSearch} initialValues={initialValues} action="new" />
}
export default QuickSearchNew
