import PropTypes from 'prop-types'
import React from 'react'
import { PaperContainerErrorsHandler } from './PaperContainerErrorsHandler'
import QuestionComponentMounter from '../question_types_admin/QuestionComponentMounter'
import TitleQuestion from './TitleQuestion'
import { getIndexSubtype } from '../../../configs/questionTypesAdminConfig'
import PreviewModal from './PreviewModal'
import clsx from 'clsx'

const QuestionCardFromTemplate = ({
  baseName,
  index,
  fields,
  parentQuestionIndex,
  questionsPreviousSteps,
  stepIndex
}) => {
  const baseNameIndex = `${baseName}[${index}]`
  const question = fields.value[index]
  const bgColor = question.editable_alternative ? 'bg-white' : 'bg-neutral-100'
  const childrenBorder = parentQuestionIndex && 'border-2 border-neutral-300'
  return (
    <PaperContainerErrorsHandler>
      <div className={clsx('pt-4 shadow-3xl', bgColor, childrenBorder)}>
        <div className="px-4">
          <TitleQuestion
            index={index}
            questions={fields.value}
            parentQuestionIndex={parentQuestionIndex}
            questionsPreviousSteps={questionsPreviousSteps}
          />
        </div>
        <div className="px-4">
          <QuestionComponentMounter
            index={index}
            question={question}
            name={`${baseNameIndex}.question`}
            baseName={baseNameIndex}
            dispatch="Template"
            kind={question.kind}
            stepIndex={stepIndex}
          />
        </div>
        <div
          className={clsx(
            'flex justify-between items-center px-4',
            question.editable_alternative ? 'bg-blue-300' : 'bg-neutral-100'
          )}
        >
          <div className="py-4">
            <p
              dangerouslySetInnerHTML={{
                __html: `Esta pregunta será ${
                  question.required ? '<strong>obligatoria</strong>' : '<strong>opcional</strong>'
                } para completar.`
              }}
            />
            {question.manual_request && <p>Esta pregunta no se mostrará al ciudadano.</p>}
            {question.has_sub_questions && (
              <p>
                Esta pregunta abre <strong>preguntas adicionales</strong> según la alternativa que se escoja.
              </p>
            )}
          </div>
          {question.editable_alternative && (
            <PreviewModal
              index={getIndexSubtype({ questionsPreviousSteps, questions: fields.value, index })}
              question={question}
            />
          )}
        </div>
      </div>
    </PaperContainerErrorsHandler>
  )
}
export default QuestionCardFromTemplate

QuestionCardFromTemplate.propTypes = {
  baseName: PropTypes.string,
  fields: PropTypes.object,
  index: PropTypes.number,
  parentQuestionIndex: PropTypes.number,
  questionsPreviousSteps: PropTypes.array,
  stepIndex: PropTypes.number
}
