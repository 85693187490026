import useCrud from '../useCrud'

const useHomeStatistics = () => {
  const { get: getQuery } = useCrud(`/v1/statistics`)

  const { data: statisticData, status } = getQuery(['landing_statistics'])

  return { statisticData, status }
}
export default useHomeStatistics
