import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import SelectField from '../../molecules/fields/SelectField'
import { Button } from '../../atoms/Button'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import * as validations from '../../../utils/validations'
import useLanguages from '../../../hooks/useLanguages'

export const AddLanguageForm = ({ onSubmit }) => {
  const { languages } = useLanguages('es', 'new')

  const transformedLanguages = languages?.map(language => ({
    value: language.code,
    label: language.name
  }))

  const handleLanguageChange = (e, form) => {
    const language = languages.find(lang => lang.code === e.target.value)
    if (language) {
      form.change('code', language.code)
      form.change('native_name', language.native_name)
    }
  }

  return (
    <div className="bg-gray-150 p-6">
      <h2 className="title text-2xl md:text-2xl mb-4">Agregar idioma</h2>
      <p className="page-description mb-8">Agrega un nuevo idioma para habilitarlo en la plataforma.</p>
      <FieldsSeparator />
      <Form onSubmit={onSubmit} autoComplete="off">
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <SelectField
              name="language.new"
              label="Selecciona el idioma"
              options={transformedLanguages || []}
              onChangeEvent={e => handleLanguageChange(e, form)}
            />
            <Field
              name="code"
              render={({ input }) => (
                <TextField
                  label="Código ISO del idioma"
                  {...input}
                  readOnly
                  className="mt-1 p-2 w-full bg-neutral-300"
                  validate={validations.required()}
                />
              )}
            />
            <Field
              name="native_name"
              render={({ input }) => (
                <TextField
                  label="Nombre del idioma en su idioma nativo"
                  {...input}
                  readOnly
                  className="mt-1 p-2 w-full bg-neutral-300"
                  validate={validations.required()}
                />
              )}
            />
            <div className="flex justify-end md:mt-8">
              <Button
                size="full"
                type="submit"
                className="mt-2 md:mt-auto md:w-45 h-13 lg:max-w-sm text-base md:text-lg font-bold"
              >
                Agregar
              </Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

AddLanguageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
