import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import * as validations from '../../../utils/validations'
import SelectField from '../../molecules/fields/SelectField'
import useBranchOffices from '../../../hooks/useBranchOffice'
import { dataToOptions } from '../../../utils/helpers'
import { useAuth } from '../../molecules/Auth'
import { Field } from 'react-final-form'
import { QuestionsAndSubQuestionsContext } from '../../../utils/contexts'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import useFieldValue from '../../../hooks/useFieldValue'
import useTextValidation from '../../../hooks/useTextValidation'

const ReferToBranchOfficeEnabledProcedureCitizenField = ({ index, payload, name, required, disabled, margin }) => {
  const { user } = useAuth()
  const { procedureManagementId } = useContext(QuestionsAndSubQuestionsContext) || {}
  const { getBranchOffices } = useBranchOffices(user.institution_id, 'allowed_refer_to')
  const branchOfficeId = useFieldValue(`${name}.destiny_branch_office_id`)
  const { status, data: branchOffices } = getBranchOffices({ procedure_management_id: procedureManagementId })
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const translationText = useTextValidation()

  if (status !== 'success') return null
  return (
    <>
      <Field
        component="input"
        className="hidden"
        name={`${name}.origin_branch_office_id`}
        initialValue={user.branch_office_id}
      />
      <SelectField
        name={`${name}.destiny_branch_office_id`}
        label={label}
        hint={payload?.hint}
        options={dataToOptions(branchOffices)}
        disabled={disabled}
        validate={required && validations.required(translationText.required)}
        containerClassName={margin}
        size="2xl"
        emptyLabel="Selecciona una sede"
      />
      <WhenFieldChanges
        field={`${name}.destiny_branch_office_id`}
        set="special_task_data.destiny_branch_office_id"
        to={branchOfficeId}
      />
    </>
  )
}
export default ReferToBranchOfficeEnabledProcedureCitizenField

ReferToBranchOfficeEnabledProcedureCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
