import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { getIndexSubtype, getSubtype } from '../../../configs/questionTypesAdminConfig'
import clsx from 'clsx'
import QuestionNumberField from '../../molecules/fields/QuestionNumberField'
import AcceptCloseModal from '../../molecules/dialogs/AcceptCloseModal'

const sizeMap = {
  s: 'text-base',
  m: 'text-2xl'
}

const TitleQuestion = ({
  questions,
  index,
  parentQuestionIndex,
  questionsPreviousSteps,
  size = 'm',
  sortQuestions,
  fields,
  baseNameIndex
}) => {
  const subtype = getSubtype(questions[index].kind)
  const indexSubtype = getIndexSubtype({ questionsPreviousSteps, questions, index, parentQuestionIndex })
  const [stepToJoin, setStepToJoin] = useState(null)
  const handleAcceptModal = () => {
    stepToJoin && stepToJoin.onAccept()
    setStepToJoin(null)
  }
  return (
    <>
      {sortQuestions ? (
        <>
          <h3 className={clsx('font-bold flex items-center', sizeMap[size])}>
            <span className="flex-grow" aria-label={`${subtype} ${indexSubtype}`}>
              {subtype}
            </span>
            <QuestionNumberField
              fields={fields}
              questions={questions}
              index={index}
              subIndex={indexSubtype.toString()}
              parentQuestionIndex={parentQuestionIndex}
              baseNameIndex={baseNameIndex}
              onChangeUniqueQuestion={setStepToJoin}
            />
          </h3>
          {stepToJoin && (
            <AcceptCloseModal
              title={`Estás moviendo la única pregunta de la Página ${stepToJoin.fromStep}`}
              buttonLabel="Unir páginas"
              onClose={() => setStepToJoin(null)}
              onAccept={handleAcceptModal}
              open
            >
              Al hacer esto, la página {stepToJoin.fromStep} se unirá a la página {stepToJoin.toStep}
            </AcceptCloseModal>
          )}
        </>
      ) : (
        <h3 className={clsx('font-bold', sizeMap[size])}>{`${subtype} ${indexSubtype}`}</h3>
      )}
    </>
  )
}
export default TitleQuestion

TitleQuestion.propTypes = {
  index: PropTypes.number,
  parentQuestionIndex: PropTypes.number,
  questions: PropTypes.array,
  questionsPreviousSteps: PropTypes.array,
  size: PropTypes.string,
  sortQuestions: PropTypes.bool,
  fields: PropTypes.object,
  baseNameIndex: PropTypes.string
}
