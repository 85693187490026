import React from 'react'
import PageHeading from '../../../molecules/PageHeading'
import { getPageHeadingData } from '../../../../configs/feedbaksConfig'
import { BoxTable } from '../../../molecules/BoxTable'
import useFilters from '../../../../hooks/useFilters'
import FeedbacksFilter from '../../../organisms/feedbacks/FeedbacksFilter'
import Card from '../../../organisms/feedbacks/Card'
import AllInstitutionsBranchOfficeFilter from '../../../organisms/filters/AllInstitutionsBranchOfficeFilter'
import useUserPermission from '../../../../hooks/useUserPermission'
import ExportButton from '../../../organisms/exports/ExportButton'

const Feedbacks = () => {
  const {
    initialFilters,
    results: feedbacks,
    onFilter,
    filters
  } = useFilters('/admin/v1/feedbacks.json', { sort: 'desc' })

  const generalPermissions = useUserPermission()
  const pageHeadingData = getPageHeadingData(generalPermissions)

  return (
    <>
      <PageHeading
        title={pageHeadingData.title}
        pageDescription={pageHeadingData.pageDescription}
        boxTableClasses="pb-4"
        filterSection={<AllInstitutionsBranchOfficeFilter byType="by_feedback" />}
      />
      <BoxTable
        initialFilters={initialFilters}
        onFilter={onFilter}
        filters={<FeedbacksFilter />}
        values={feedbacks}
        accordionHasBackground={false}
        paginationLabel="page"
        searchFieldName="search"
        sortByName="sort"
        displayTotal={true}
        displayHeader={false}
        displaySort={true}
        asideFilter={
          <div className="text-center flex md:block flex-wrap justify-center pt-6 md:pt-0 w-full md:w-auto">
            <ExportButton
              title={`Exportar archivo`}
              url={`/feedbacks/exportaciones`}
              filters={filters}
              exportable={{ type: 'feedback' }}
            />
          </div>
        }
      >
        {feedbacks.data.map(feedback => (
          <Card key={feedback.id} feedback={feedback} />
        ))}
      </BoxTable>
    </>
  )
}
export default Feedbacks
