import axios from 'axios'
import queryString from 'query-string'
import useCrud from './useCrud'

const urlBase = '/admin/v1/exports'

const createExport = values => axios.post(`${urlBase}.json`, values)

const useExport = () => {
  const create = async (filters, exportable, onSuccess) => {
    createExport({ ...filters, exportable_type: exportable.type, exportable_id: exportable.id }).then(response => {
      onSuccess && onSuccess(response.data)
    })
  }

  const fetchExportByType = exportableType => (search, exportableId) => {
    const parsedSearch = queryString.parse(search)

    const url = queryString.stringifyUrl({
      url: `${urlBase}.json?exportable_id=${exportableId}&exportable_type=${exportableType}`,
      query: parsedSearch
    })
    return axios.get(url).then(res => res.data)
  }

  const { get: getStatus } = useCrud(`${urlBase}/export_status`)

  return { create, fetchExportByType, getStatus }
}

export default useExport
