import React from 'react'
import PageNew from '../../../organisms/onboarding/PageNew'
import useUpgrade from '../../../../hooks/useUpgrade'

const headerInfo = { title: 'Crear artículo de actualizaciones' }

const UpgradeNew = () => {
  const { createUpgrade } = useUpgrade()
  return <PageNew headerInfo={headerInfo} createArticle={createUpgrade} />
}

export default UpgradeNew
