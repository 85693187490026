import PropTypes from 'prop-types'
import React from 'react'
import DocIdDocTypeField from '../question_config_fields/DocIdDocTypeField'
import { ReactSVG } from 'react-svg'
import DocumentTypeIcon from '../../../images/icons/document_type_icon.svg'
import DocTypeQuestion from './DocTypeQuestion'

const Form = props => {
  return (
    <DocIdDocTypeField
      baseName={props.baseName}
      docTypeInitialValue="Tipo de documento de identidad del solicitante"
      docIdInitialValue="Número de documento de identidad del solicitante"
      disableEditLabels
    />
  )
}

const DocTypeApplicantQuestion = {
  selectOption: {
    value: 'doc_id_type_applicant',
    label: 'Tipo y número de documento de identidad',
    icon: <ReactSVG src={DocumentTypeIcon} />
  },
  Form,
  Template: DocTypeQuestion.Template,
  Answer: DocTypeQuestion.Answer,
  TaskResponse: DocTypeQuestion.TaskResponse,
  name: 'DocTypeApplicantQuestion'
}

export default DocTypeApplicantQuestion

Form.propTypes = {
  baseName: PropTypes.string
}
