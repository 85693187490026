import PropTypes from 'prop-types'
import React from 'react'
import { defaultUserType, getLabelToNext } from '../../../configs/procedureManagementsConfig'
import ArrayTasks from './ArrayTasks'
import useUserTypes from '../../../hooks/useUserTypes'
import useFieldValue from '../../../hooks/useFieldValue'
import { isEnablePayment } from '../../../hooks/useTask'

const NextEnabledTasksSection = ({ task }) => {
  const destinyUserTypeId = useFieldValue('destiny_user_type_id')
  const procedureManagementId = useFieldValue('procedure_management_id')
  const { data: userTypes, status } = useUserTypes(procedureManagementId)
  if (status !== 'success') return null
  const destinyUserTypeName = [defaultUserType, ...userTypes].find(
    ({ id }) => id?.toString() === destinyUserTypeId?.toString()
  )?.name

  return (
    <div className="relative js-field-container">
      {getLabelToNext('enable', task, destinyUserTypeName)}
      <ArrayTasks name="enabled_tasks" userTypeId={destinyUserTypeId} disableFirst={isEnablePayment(task)} />
    </div>
  )
}
export default NextEnabledTasksSection

NextEnabledTasksSection.propTypes = {
  task: PropTypes.object
}
