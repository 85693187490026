import PropTypes from 'prop-types'
import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useField } from 'react-final-form'
import { IconButton } from '@material-ui/core'
import { ReactSVG as Svg } from 'react-svg'
import clsx from 'clsx'
import Error from '../Error'
import { required } from '../../../utils/validations'

const IconSelectorField = ({ options = [], name, ...props }) => {
  const { input } = useField(name, { validate: required(), ...props })
  return (
    <div className="items-center mb-8">
      <RadioGroup name={name} className="inline flex flex-row" {...input}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            className={clsx(
              'border-2 rounded-lg p-2 relative mr-4 h-12 mb-3 md:h-auto',
              input.value === option.value ? 'bg-blue-400 border-blue-700' : 'bg-white'
            )}
            control={<Radio className="opacity-0 absolute p-4 z-50" />}
            classes={{ label: 'text-base w-15 md:w-16 text-center', root: 'ml-0 mr-0' }}
            aria-label={option.value}
            label={
              <IconButton className="-m-0.5" color={input.value === option.value ? 'primary' : 'default'}>
                <Svg src={option.iconSvg} wrapper="span" className="h-5 md:h-8 w-auto md:w-8 md:w-auto m-auto" />
              </IconButton>
            }
          />
        ))}
      </RadioGroup>
      <Error name={name} />
    </div>
  )
}

export default IconSelectorField

IconSelectorField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array
}
