import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from '@material-ui/core'
import clsx from 'clsx'

const sizes = {
  s: 'max-w-xl',
  m: 'max-w-3xl',
  l: 'max-w-280',
  xl: 'max-w-7xl',
  full: 'w-full'
}

const ModalWithAnimation = ({
  open,
  children,
  size = 's',
  modalClassName = 'justify-center',
  className = 'p-4 md:p-8',
  onClose
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={clsx('flex items-center', modalClassName)}
      open={open}
      closeAfterTransition
      onClose={onClose}
    >
      <div className={clsx('overflow-y-auto max-h-screen bg-white border-2 border-black', className, sizes[size])}>
        {children}
      </div>
    </Modal>
  )
}

export default ModalWithAnimation

ModalWithAnimation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  modalClassName: PropTypes.string,
  open: PropTypes.bool,
  size: PropTypes.string,
  onClose: PropTypes.func
}
