import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import Visibility from '@material-ui/icons/Visibility'
import { Link } from 'react-router-dom'
import Alert from '../../molecules/Alert'

const PreviewAndGoToPaymentInfo = ({ text, onPreview }) => {
  return (
    <Alert className="mt-4">
      <div className="flex flex-col">
        <div>{text}</div>
        <div className="flex justify-between mt-4">
          <Button variant="text" size="xs" className="text-sm" startIcon={<Visibility />} onClick={onPreview}>
            Vista previa
          </Button>
          <Button variant="text" size="xxs" className="text-sm" el={Link} to="/medios-de-pago">
            Ir a pagos
          </Button>
        </div>
      </div>
    </Alert>
  )
}
export default PreviewAndGoToPaymentInfo

PreviewAndGoToPaymentInfo.propTypes = {
  onPreview: PropTypes.func,
  text: PropTypes.string
}
