import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { parseDayMonthYear, sortStatDates } from '../utils/helpers'
import { firstFormDate } from '../configs/formsConfig'
import { differenceInDays } from 'date-fns'

const useStatisticChart = (results, initialHeader = ['Años', 'Sin datos'], headerNames = {}) => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)

  const headerData = useMemo(() => {
    if (results && results.stats) {
      const statsKeys = [...Object.keys(results.stats)]
      const headerKeys = statsKeys.filter(stat => results.stats[stat].length > 0)
      const headerFormattedNames = headerKeys.map(name => headerNames[name] || name)
      const headers = [initialHeader[0], ...headerFormattedNames]
      return { headerKeys, headers }
    }
    return { headerKeys: [], headers: initialHeader }
  }, [headerNames, initialHeader, results])

  const chartData = useMemo(() => {
    if (results && results.stats) {
      const { headerKeys, headers } = headerData

      const listKeys = headerKeys.reduce((acc, state) => {
        const listYears = results.stats[state].map(el => el.year)
        return acc.concat(listYears)
      }, [])

      sortStatDates(listKeys)
      const uniqueKeys = [...new Set(listKeys)]

      const allData = uniqueKeys.map(key => {
        const listValues = headerKeys.map(state => {
          const stateSelected = results.stats[state].find(el => el.year === key)
          return stateSelected ? stateSelected.count : 0
        })
        return [key, ...listValues]
      }, [])

      const keyData = allData.length > 0 ? allData : [['Sin datos', 0]]
      const formattedData = [headers, ...keyData]
      return formattedData
    }

    return [initialHeader, ['Sin datos', 0]]
  }, [results, initialHeader, headerData])

  const initialDateValue = urlSearchParams.date_from || firstFormDate
  const initialDate = parseDayMonthYear(initialDateValue)

  const endDateConstructor = new Date()
  endDateConstructor.setDate(endDateConstructor.getDate() + 1)
  const endDate = urlSearchParams.date_to ? parseDayMonthYear(urlSearchParams.date_to) : endDateConstructor
  const difference = differenceInDays(endDate, initialDate)

  let chartTime = 'Años'
  if (difference <= 365) chartTime = 'Meses'
  if (difference <= 30) chartTime = 'Días'

  return { chartData, chartTime }
}

export default useStatisticChart
