import PropTypes from 'prop-types'
import React from 'react'
import Header from '../../molecules/Header'

const CitizenHeader = ({ name, institution, showUserInfo = true }) => {
  return (
    <Header
      serviceName={
        <span>
          <div className="text-sm font-normal">{institution?.name || institution || name}</div>
        </span>
      }
      showUserInfo={showUserInfo}
      slug={institution?.slug}
    />
  )
}

export default CitizenHeader

CitizenHeader.propTypes = {
  institution: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showUserInfo: PropTypes.bool,
  name: PropTypes.string
}
