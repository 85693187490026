import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { Button } from '../../atoms/Button'
import { LinearProgressBar } from '../../atoms/LinearProgressBar'

const SaveButton = ({ label = 'Guardar', disabled = false, uploadProgress = null, containerClassName = '' }) => {
  return (
    <div className={clsx('md:max-w-72 ml-auto', containerClassName)}>
      <Button size="full" type="submit" className="ml-auto" disabled={disabled}>
        {label}
      </Button>
      {uploadProgress !== null && <LinearProgressBar percentage={uploadProgress} />}
    </div>
  )
}
export default SaveButton

SaveButton.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  uploadProgress: PropTypes.number
}
