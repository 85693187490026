import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'

const { admin, coordinator, branch_coordinator: branchCoordinator } = roles

const Area = () => {
  const hasRolePermissions = useHasRolePermissions()

  const canManageAreas = hasRolePermissions([admin, coordinator, branchCoordinator])

  return {
    canManageAreas
  }
}

export default Area
