import React from 'react'
import PagePreview from '../../../organisms/onboarding/PagePreview'
import useUpgrade from '../../../../hooks/useUpgrade'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const updateUpgrade = id => values => axios.put(`/admin/v1/upgrades/${id}.json`, values)

const UpgradePreview = () => {
  const { id } = useParams()
  const { getUpgrade } = useUpgrade()
  const queryClient = useQueryClient()
  const { mutate } = useMutation(updateUpgrade(id), {
    onSuccess: () => queryClient.invalidateQueries('upgrade')
  })
  const edit = { url: `/centro-de-ayuda/articulo-actualizacion/${id}/editar`, buttonLabel: 'Editar actualización' }
  return <PagePreview getArticle={getUpgrade} updateArticle={mutate} edit={edit} />
}

export default UpgradePreview
