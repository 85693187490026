import DateField from '../../molecules/fields/DateField'
import * as validations from '../../../utils/validations'
import Error from '../../molecules/Error'
import React from 'react'
import { useField } from 'react-final-form'

const BirthDateValidation = () => {
  const { meta } = useField('birth_date_validation')
  const showError = !meta.dirtySinceLastSubmit && (meta.error || meta.submitError)
  return (
    <div className="relative">
      <DateField
        label="Fecha de nacimiento"
        name="birth_date_validation"
        placeholder="DD/MM/AAAA"
        validate={validations.mix(validations.required(), validations.isDate())}
        size="2xl"
        classNameLabel="text-lg"
        hideError
        margin="high"
      />
      {showError && <Error name={'birth_date_validation'} className="absolute -bottom-6" />}
    </div>
  )
}

export default BirthDateValidation
