import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'

const PublishedFilter = () => {
  return (
    <>
      <label htmlFor="sort-input" className="font-bold mr-4">
        Estado
      </label>
      <CheckboxField name="status" label="Publicado" value="published" margin="none" />
      <CheckboxField name="status" label="No publicado" value="no_published" margin="none" />
    </>
  )
}

export default PublishedFilter
