import React from 'react'
import { Form } from 'react-final-form'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Button } from '../../../atoms/Button'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  paddingTop: {
    padding: '0!important'
  }
}))

const DialogBox = ({
  onStopPropagation,
  initialValues,
  dialogContent,
  cancelButton,
  titleText,
  onSubmit,
  onClose,
  maxWidth = 'xs'
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open
      maxWidth={maxWidth}
      fullWidth
      onClick={onStopPropagation}
      onClose={onClose}
      classes={{ paper: 'px-8 pt-4 pb-11 rounded-xl' }}
    >
      <Form
        onSubmit={() => onSubmit}
        initialValues={initialValues}
        subscription={{ valid: true, pristine: true, values: true }}
      >
        {({ handleSubmit, form, valid, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle classes={{ root: 'p-0' }}>
              <div className="text-right">
                <Button
                  type="button"
                  variant="text"
                  size="full"
                  className="font-bold mb-2 px-0 w-auto"
                  endIcon={<Close />}
                  onClick={onClose}
                  aria-label="Cerrar"
                >
                  Cerrar
                </Button>
              </div>
              <div className="text-xl mb-6 font-bold">{titleText}</div>
            </DialogTitle>
            <DialogContent classes={{ root: clsx('pl-3', classes.paddingTop) }}>{dialogContent}</DialogContent>
            <DialogActions classes={{ root: 'p-0' }}>
              {cancelButton || (
                <Button variant="secondary" onClick={onClose}>
                  Cancelar
                </Button>
              )}
              <Button
                className="ml-4 text-white"
                aria-label="Insertar"
                onClick={() => onSubmit(values, form, valid)}
                disabled={pristine || !valid}
              >
                {initialValues?.url ? 'Guardar cambios' : 'Insertar'}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default DialogBox

DialogBox.propTypes = {
  cancelButton: PropTypes.node,
  dialogContent: PropTypes.node,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onStopPropagation: PropTypes.func,
  titleText: PropTypes.string,
  maxWidth: PropTypes.string
}
