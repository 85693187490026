import PropTypes from 'prop-types'
import React from 'react'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'

const Answer = props => {
  return (
    <BoldLabelAndContent label={props.question.payload?.label} content={props.answer} el="li">
      {props.children}
    </BoldLabelAndContent>
  )
}

const TaskResponse = props => {
  return (
    <BoldLabelAndContent label={`${props.question.payload?.label}:`} content={props.answer} className="mb-0">
      {props.children}
    </BoldLabelAndContent>
  )
}

const QuestionType = {
  subtype: 'Pregunta',
  Answer,
  TaskResponse,
  Template: () => {
    throw new Error('Componente para plantilla no implementado')
  },
  Form: () => {
    throw new Error('Componente para creador de formularios no implementado')
  }
}

export default QuestionType

Answer.propTypes = {
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  question: PropTypes.object
}

TaskResponse.propTypes = {
  answer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  question: PropTypes.object.isRequired
}
