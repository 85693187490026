import PropTypes from 'prop-types'
import React from 'react'
import { getQuestionTypeObject } from '../../../configs/questionTypesAdminConfig'

const capitalize = s => s[0].toUpperCase() + s.slice(1)

const QuestionComponentMounter = ({ dispatch: unsafeDispatch, kind, ...props }) => {
  if (!kind) return null
  const question = getQuestionTypeObject(kind)
  if (!question) throw new Error('El tipo de pregunta no existe')
  if (!unsafeDispatch) throw new Error('No existe tipo de componente a montar (Form, Template, Answer)')
  const dispatch = capitalize(unsafeDispatch)
  const Component = question[dispatch]
  if (!Component) throw new Error(`El componente no existe para el tipo ${unsafeDispatch}`)
  Component.displayName = `${question.name}.${dispatch}`
  return <Component {...props} />
}

export default QuestionComponentMounter

QuestionComponentMounter.propTypes = {
  dispatch: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired
}
