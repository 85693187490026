import PropTypes from 'prop-types'
import React from 'react'
import CitizenFormContainer from '../citizens/CitizenFormContainer'
import { formValuesPreview } from '../../../configs/templatesConfig'
import { Link } from 'react-router-dom'
import { Button } from '../../atoms/Button'
import useTemplate from '../../../hooks/useTemplate'
import Dialog from '../../molecules/dialogs/Dialog'

const TemplatePreviewModal = ({ templateId, onClose }) => {
  const { data: template, status } = useTemplate(templateId)
  return (
    status === 'success' && (
      <Dialog
        maxWidth="md"
        renderTitle={<p className="header font-bold text-lg">Vista previa</p>}
        renderContent={
          <div className="border-3 border-neutral-300">
            <CitizenFormContainer form={formValuesPreview(template)} isPreview />
          </div>
        }
        renderActions={
          <Button type="button" el={Link} to={'/formularios/crear/plantilla/' + templateId} className="my-2 mr-6">
            Seleccionar plantilla
          </Button>
        }
        onClose={onClose}
      />
    )
  )
}
export default TemplatePreviewModal

TemplatePreviewModal.propTypes = {
  onClose: PropTypes.func,
  templateId: PropTypes.number
}
