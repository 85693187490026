import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { getIndexSubtype } from '../../../configs/questionTypesCitizenConfig'
import { Field } from 'react-final-form'
import QuestionComponentMounter from '../question_types_citizens/QuestionComponentMounter'

export const ChildQuestion = ({ alternativeBaseName, index, questions, parentIndex }) => {
  const question = questions[index]
  const islastChild = questions.length === index + 1

  const setContainerClass = lastChild => (lastChild ? { margin: 'none' } : {})

  return (
    question._destroy !== '1' && (
      <Fragment>
        <Field
          component="input"
          className="hidden"
          name={`${alternativeBaseName}.question_id`}
          initialValue={question.id}
        />
        <QuestionComponentMounter
          key={question.id}
          index={getIndexSubtype({ questions, index, parentQuestionIndex: parentIndex })}
          question={question}
          name={`${alternativeBaseName}.answer`}
          dispatch="Citizen"
          kind={question.kind}
          {...setContainerClass(islastChild)}
        />
      </Fragment>
    )
  )
}

ChildQuestion.propTypes = {
  alternativeBaseName: PropTypes.string,
  index: PropTypes.number,
  parentIndex: PropTypes.number,
  questions: PropTypes.arrayOf(PropTypes.object)
}
