import PropTypes from 'prop-types'
import React from 'react'
import { banks } from '../../../configs/paymentMethodConfigs'
import CopyText from '../../molecules/buttons/CopyText'

export const BankTransferenceInformationCitizen = ({ paymentTransferAccounts }) => {
  return (
    <div className="mb-6 px-4 md:px-8 bg-white bank-transfer-container">
      {paymentTransferAccounts?.map((account, key) => (
        <div className="bank-transfer-account" key={key}>
          <div className="mb-4">
            <h3 className="font-semibold">Nombre de la entidad (razón social)</h3>
            <p>{account.company_name}</p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">RUC</h3>
            <p>{account.ruc}</p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">Banco</h3>
            <p>{banks[account.bank]}</p>
          </div>
          <div className="mb-4 md:flex">
            <div className="mb-2 md:mb-0 md:mr-2">
              <h3 className="font-semibold">Número de cuenta</h3>
              <p>{account.number}</p>
            </div>
            <CopyText copy={account.number} ariaLabel="Copiar número de cuenta" />
          </div>
          <div className="md:flex">
            <div className="mb-2 md:mb-0 md:mr-2">
              <h3 className="font-semibold">Código de cuenta interbancario (CCI)</h3>
              <p>{account.cci_number}</p>
            </div>
            <CopyText copy={account.cci_number} ariaLabel="Copiar código de cuenta interbancario (CCI)" />
          </div>
        </div>
      ))}
    </div>
  )
}

BankTransferenceInformationCitizen.propTypes = {
  paymentTransferAccounts: PropTypes.array.isRequired
}
