import PropTypes from 'prop-types'
import React from 'react'

const BoldLabelAndContent = ({
  label,
  content,
  children = null,
  className = 'mb-3',
  contentClassName,
  el: El = 'div'
}) => (
  <El className={className}>
    <span className="font-extrabold">{label}</span>
    <span className={contentClassName}> {content}</span>
    {children}
  </El>
)

export default BoldLabelAndContent

BoldLabelAndContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  contentClassName: PropTypes.string,
  label: PropTypes.string,
  el: PropTypes.string
}
