import PropTypes from 'prop-types'
import React from 'react'
import { ClickAwayListener, MenuList, Paper, Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useHistory, useLocation } from 'react-router-dom'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import MenuItem from '@material-ui/core/MenuItem'

export const ChildrenTabs = ({
  anchorEl,
  onClose,
  container,
  showSubMenuMobile,
  selectedSubTabs,
  setShowMenuMobile
}) => {
  const history = useHistory()
  const location = useLocation()

  const open = Boolean(anchorEl)
  let computedStyleAnchorEl = null
  if (anchorEl) {
    computedStyleAnchorEl = getComputedStyle(anchorEl)
  }

  const useStyles = makeStyles({
    paper: {
      top: '134px !important',
      'box-shadow': '1px 1px 10px -5px #D3E0EC',
      border: '2px solid #DEE3EA',
      borderTopWidth: '0px',
      width: `calc(${computedStyleAnchorEl?.width} + 1px)`
    },
    list: {
      'padding-top': 0,
      'padding-bottom': 0
    },
    popper: {
      position: 'relative !important',
      transform: 'initial !important'
    }
  })

  const classes = useStyles()

  return (
    <Popper
      id="basic-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      container={container}
      className={clsx('z-10', showSubMenuMobile && classes.popper)}
    >
      {() => (
        <Paper classes={{ root: classes.paper }}>
          <ClickAwayListener onClickAway={onClose}>
            <MenuList aria-labelledby="basic-button" classes={{ root: classes.list }}>
              <button
                onClick={() => {
                  onClose()
                  setShowMenuMobile(true)
                }}
                className="font-bold py-2 px-2 block md:hidden"
              >
                <ChevronLeft className="text-red-800" /> Volver
              </button>
              {selectedSubTabs.map((tab, index) => (
                <MenuItem
                  classes={{
                    root: clsx('p-3 text-base', { 'react-tabs__tab--selected': location.pathname === tab.path })
                  }}
                  onClick={() => {
                    history.push(tab.path)
                    onClose()
                  }}
                  data-testid={tab.name}
                  key={`sub-${index}`}
                >
                  {tab.name}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  )
}

ChildrenTabs.propTypes = {
  anchorEl: PropTypes.object,
  container: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  selectedSubTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  setShowMenuMobile: PropTypes.func.isRequired,
  showSubMenuMobile: PropTypes.bool
}
