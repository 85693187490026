import axios from 'axios'
import useQuery from './useQuery'
import useMutation from './useMutation'

const fetchRecords = (urlBase, params) => () => axios.get(`${urlBase}.json`, { params }).then(res => res.data)

const useCrud = urlBase => {
  const fetchRecord = params => id => axios.get(`${urlBase}/${id}.json`, { params }).then(res => res.data)
  const postRecord = (values, config) => axios.post(`${urlBase}.json`, values, config).then(res => res.data)
  const putRecord = values => axios.put(`${urlBase}/${values.id}.json`, values).then(res => res.data)
  const deleteRecord = params => id => axios.delete(`${urlBase}/${id}.json`, { params })

  const useGet = (queryKey, params, options) => {
    return useQuery(queryKey, fetchRecords(urlBase, params), options)
  }

  const useFindById = (queryKey, params, options) => useQuery(queryKey, fetchRecord(params), options)

  const create = async (values, options = {}, config = {}) => {
    try {
      const data = await postRecord(values, config)
      options.onSuccess && options.onSuccess(data)
    } catch (error) {
      options.onError && options.onError(error)
    }
  }

  const useUpdate = () => useMutation(putRecord)

  const useDestroy = params => useMutation(deleteRecord(params))

  return { get: useGet, findById: useFindById, create, update: useUpdate, destroy: useDestroy }
}

export default useCrud
