import React from 'react'
import PropTypes from 'prop-types'
import SelectFieldFilter from '../../../molecules/filters/SelectFieldFilter'
import { answerStates } from '../../../../configs/answerConfig'
import useFieldValue from '../../../../hooks/useFieldValue'
import useUserTypeStates from '../../../../hooks/useUserTypeStates'
import { CITIZEN_ID } from '../../../../configs/procedureManagementsConfig'
import WhenFieldChanges from '../../../molecules/WhenFieldChanges'

const ReceivedTypeFormFilter = ({ onChange }) => {
  const formTypeValue = useFieldValue('received_request_form_type')
  const { data } = useUserTypeStates(CITIZEN_ID)
  const gdtStates = data?.map(state => ({ code: state.id.toString(), name: state.name }))

  const statesByType = {
    simple: answerStates,
    template: answerStates,
    procedure: gdtStates
  }
  const stateOptions = statesByType[formTypeValue] || []

  const handleChange = () => {
    onChange({ received_request_form_type: formTypeValue })
  }

  return (
    <div>
      <SelectFieldFilter
        className="min-w-60 mb-5 mt-3"
        label="Tipo de formulario"
        name="received_request_form_type"
        margin="none"
        size="full"
        emptyLabel="Selecciona tipo de formulario"
        options={[
          { id: 'simple', name: 'Formularios simples' },
          { id: 'template', name: 'Formularios de plantillas' },
          { id: 'procedure', name: 'Form. de gestión de trámites' }
        ]}
        onChange={handleChange}
      />
      <WhenFieldChanges field={'received_request_form_type'} set={`received_request_status`} to={''} />
      <WhenFieldChanges field={'received_request_form_type'} set={`received_request_form_id`} to={''} />
      {formTypeValue && (
        <SelectFieldFilter
          className="min-w-60 mb-5 mt-3"
          label="Estado de atención"
          name="received_request_status"
          margin="none"
          size="full"
          emptyLabel="Selecciona el estado de atención"
          options={stateOptions}
        />
      )}
    </div>
  )
}

ReceivedTypeFormFilter.propTypes = {
  onChange: PropTypes.func
}

export default ReceivedTypeFormFilter
