import PropTypes from 'prop-types'
import React from 'react'
import PinnedButton from '../../molecules/buttons/PinnedButton'
import useQuickSearch from '../../../hooks/useQuickSearch'
import usePin from '../../../hooks/services/usePin'

const QuickSearchPinnedButton = ({ quickSearch, disabled }) => {
  const { togglePinQuickSearch } = usePin(quickSearch.id)
  const { invalidateQuickSearches } = useQuickSearch(quickSearch.id)

  const handleUpdateQuickSearch = () => {
    togglePinQuickSearch(
      {},
      {
        onSuccess: () => {
          invalidateQuickSearches()
        },
        onError: () => {
          alert('Ocurrió un error al intentar fijar.')
        }
      }
    )
  }
  return (
    <PinnedButton
      type="consulta rápida"
      onClick={handleUpdateQuickSearch}
      pinned={quickSearch.pinned}
      disabled={disabled}
    />
  )
}

export default QuickSearchPinnedButton

QuickSearchPinnedButton.propTypes = {
  quickSearch: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}
