import PropTypes from 'prop-types'
import React, { useReducer, useEffect } from 'react'
import { SelectInput } from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import { useField } from 'react-final-form'
import { MenuItem, Typography } from '@material-ui/core'
import Error from '../../molecules/Error'
import Hint from '../../atoms/Hint'
import clsx from 'clsx'
import { marginSizes } from '../../../configs/sizesConfig'
import { useTranslation } from 'react-i18next'
import useTextValidation from '../../../hooks/useTextValidation'

const reducer = (state, action) => {
  switch (action.type) {
    case 'GOT_REGIONS':
      return {
        ...state,
        regions: action.payload
      }
    case 'GOT_CITIES':
      return {
        ...state,
        cities: action.payload
      }
    case 'GOT_DISTRICTS':
      return {
        ...state,
        districts: action.payload
      }
    case 'CHANGE_REGION':
      return {
        ...state,
        cities: [],
        districts: [],
        selectedCity: '',
        selectedDistrict: '',
        selectedRegion: action.payload
      }
    case 'CHANGE_CITY':
      return {
        ...state,
        districts: [],
        selectedCity: action.payload
      }
    case 'SET_UBIGEO': {
      const LAST_4_DIGITS = /\d{4}$/
      const LAST_2_DIGITS = /\d{2}$/
      return {
        ...state,
        selectedRegion: action.districtCode.replace(LAST_4_DIGITS, '0000'),
        selectedCity: action.districtCode.replace(LAST_2_DIGITS, '00'),
        selectedDistrict: action.districtCode
      }
    }
    default:
      break
  }
}

const initialState = {
  regions: [],
  cities: [],
  districts: [],
  selectedRegion: '',
  selectedCity: '',
  selectedDistrict: ''
}

const CustomizedTypography = ({ text }) => (
  <Typography className="text-base whitespace-normal leading-none pl-4 pr-6 py-2">{text}</Typography>
)

const UbigeoCitizenField = ({ index, payload, name, required, disabled = false, margin = 'normal' }) => {
  const { t } = useTranslation(['form', 'form_field_options'])
  const translationText = useTextValidation()

  const [state, dispatch] = useReducer(reducer, initialState)
  const { input } = useField(name, { validate: required && validations.required(translationText.required) })
  const label = index ? `${index}. ${payload?.label}` : payload?.label

  useEffect(() => {
    if (input.value) {
      dispatch({ type: 'SET_UBIGEO', districtCode: input.value.toString() })
    }
    async function getRegions() {
      const res = await fetch('/v1/ubigeos.json?country=true')
      const json = await res.json()
      dispatch({ type: 'GOT_REGIONS', payload: json })
    }
    getRegions()
  }, [])

  useEffect(() => {
    let didCancel = false
    async function getCities() {
      if (!state.selectedRegion) return
      const res = await fetch(`/v1/ubigeos.json?region_code=${state.selectedRegion}`)
      const json = await res.json()
      if (!didCancel) dispatch({ type: 'GOT_CITIES', payload: json })
    }
    getCities()
    return () => {
      didCancel = true
    }
  }, [state.selectedRegion])

  useEffect(() => {
    let didCancel = false
    async function getDistricts() {
      if (!state.selectedCity) return
      const res = await fetch(`/v1/ubigeos.json?province_code=${state.selectedCity}`)
      const json = await res.json()
      if (!didCancel) dispatch({ type: 'GOT_DISTRICTS', payload: json })
    }
    getDistricts()
    return () => {
      didCancel = true
    }
  }, [state.selectedCity])

  const handleRegionChange = evt => {
    input.onChange('')
    dispatch({ type: 'CHANGE_REGION', payload: evt.target.value })
  }

  const handleCityChange = evt => {
    input.onChange('')
    dispatch({ type: 'CHANGE_CITY', payload: evt.target.value })
  }

  return (
    <fieldset className={clsx('relative js-field-container', marginSizes[margin])}>
      <legend className="font-bold text-base mb-2">{label}</legend>
      {payload?.hint && <Hint hint={payload.hint} name={name} />}
      <div className="grid md:grid-cols-3 gap-4">
        <SelectInput
          label={t('form_label_department')}
          onChange={handleRegionChange}
          value={state.selectedRegion}
          disabled={disabled}
          name={`${name}-region`}
          displayEmpty
        >
          <MenuItem value="">
            <CustomizedTypography text={t('select_departement', { ns: 'form_field_options' })} />
          </MenuItem>
          {state.regions.map(region => (
            <MenuItem value={region.id} key={region.id}>
              <CustomizedTypography text={region.name} />
            </MenuItem>
          ))}
        </SelectInput>
        <SelectInput
          label={t('form_label_province')}
          onChange={handleCityChange}
          value={state.selectedCity}
          disabled={disabled}
          name={`${name}-province`}
          displayEmpty
        >
          <MenuItem value="">
            <CustomizedTypography text={t('select_province', { ns: 'form_field_options' })} />
          </MenuItem>
          {state.cities.map(city => (
            <MenuItem value={city.id} key={city.id}>
              <CustomizedTypography text={city.name} />
            </MenuItem>
          ))}
        </SelectInput>
        <SelectInput
          label={t('form_label_district')}
          {...input}
          disabled={disabled}
          displayEmpty
          name={`${name}-district`}
        >
          <MenuItem value="">
            <CustomizedTypography text={t('select_district', { ns: 'form_field_options' })} />
          </MenuItem>
          {state.districts.map(district => (
            <MenuItem value={district.id} key={district.id}>
              <CustomizedTypography text={district.name} />
            </MenuItem>
          ))}
        </SelectInput>
      </div>
      <Error name={name} />
    </fieldset>
  )
}

export default UbigeoCitizenField

UbigeoCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}

CustomizedTypography.propTypes = {
  text: PropTypes.string
}
