import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useMutation } from 'react-query'
import axios from 'axios'
import UserTypeForm from './UserTypeForm'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import { ProcedureManagementContext } from '../../../utils/contexts'

const createUserType = procedureManagementId => values =>
  axios.post(`/admin/v1/procedure_managements/${procedureManagementId}/user_types.json`, values).then(res => res.data)

const UserTypeFormNew = ({ open, onAfterSave: afterSave, onClose: close }) => {
  const procedureManagementId = useContext(ProcedureManagementContext)
  const { mutate } = useMutation(createUserType(procedureManagementId), {
    onSuccess: userType => afterSave(userType)
  })
  return (
    <ModalWithAnimation
      size="full"
      open={open}
      className="md:px-6 h-screen"
      modalClassName="justify-end md:pl-10"
      animation="slideLeft"
    >
      <UserTypeForm
        title="Crear tipo de usuario"
        buttonLabel="Crear tipo de usuario"
        initialValues={{ procedure_management_id: procedureManagementId }}
        onSave={mutate}
        onClose={close}
      />
    </ModalWithAnimation>
  )
}
export default UserTypeFormNew

UserTypeFormNew.propTypes = {
  onAfterSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
}
