import useCrud from './useCrud'
import axios from 'axios'
import useInvalidateQueries from './useInvalidateQueries'
import { urlToJson } from '../utils/helpers'

export const urlBaseQuickSearch = `/admin/v1/quick_searches`

const useQuickSearch = () => {
  const { findById, create, get: getQuickSearches } = useCrud(`${urlBaseQuickSearch}`)

  const { get: getAllQuickSearchIds } = useCrud(`${urlBaseQuickSearch}/all_quick_searches_ids`)

  const getQuickSearch = id => findById(['institution', id])

  const createQuickSearch = (id, values, options) => create(values, options)

  const updateQuickSearch = async (id, values, config) => {
    const data = await axios.put(`${urlBaseQuickSearch}/${id}.json`, values, config)
    config.onSuccess && config.onSuccess(data)
  }

  const invalidateQueries = useInvalidateQueries()

  const invalidateQuickSearches = () => invalidateQueries([urlToJson(urlBaseQuickSearch)])

  const { get: getCreators } = useCrud(`${urlBaseQuickSearch}/creators`)

  return {
    createQuickSearch,
    getQuickSearch,
    updateQuickSearch,
    getQuickSearches,
    getAllQuickSearchIds,
    getCreators,
    invalidateQuickSearches
  }
}

export default useQuickSearch
