import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import IconButtonMUI from '@material-ui/core/IconButton'

const IconButton = ({ ariaLabel, color, className, onClick, children, size, ...props }, ref) => {
  return (
    <IconButtonMUI
      type="button"
      color={color}
      aria-label={ariaLabel}
      onClick={onClick}
      ref={ref}
      size={size}
      className={className}
      {...props}
    >
      {children}
    </IconButtonMUI>
  )
}

export default forwardRef(IconButton)

IconButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
}
