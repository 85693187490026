import PropTypes from 'prop-types'
import React from 'react'
import ConfirmDialog from '../../molecules/dialogs/ConfirmDialog'
import { Field, useForm } from 'react-final-form'

const NewUserModal = ({ open, onClose, name }) => {
  const { submit } = useForm()
  const handleSubmit = async () => {
    await submit()
  }
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={
        <>
          {'¿Quieres continuar con la creación de la cuenta en la sección "Crear usuario" de Facilita?'}
          <span className="text-base block">(La información registrada hasta el momento será guardada).</span>
        </>
      }
      primaryConfirmBtn
      confirmLabel="Sí, continuar"
      onConfirm={handleSubmit}
    >
      <p>
        Cuando hayas creado la nueva cuenta, se te redireccionará a esta sección para que completes la asignación de
        roles y configuración de tu trámite.
      </p>
      <Field component="input" className="hidden" name={`${name}.to_user_page`} initialValue={true} />
    </ConfirmDialog>
  )
}
export default NewUserModal

NewUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  name: PropTypes.string
}
