import PropTypes from 'prop-types'
import React from 'react'
import ExcelExportIcon from '@material-ui/icons/FileCopy'

const ExcelExport = ({ className }) => <ExcelExportIcon className={className} />
export default ExcelExport

ExcelExport.propTypes = {
  className: PropTypes.string
}
