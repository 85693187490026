import React from 'react'
import { AddLanguageForm } from './AddLanguageForm'
import { EnableLanguagesForm } from './EnableLanguagesForm'
import useLanguages from '../../../hooks/useLanguages'
import { useHistory } from 'react-router-dom'

export const LanguageForm = () => {
  const { createLanguage } = useLanguages()
  const history = useHistory()
  const handleSubmit = async values => {
    try {
      await createLanguage(values, history)
      history.push(`/traducciones?action=language_saved`)
    } catch (error) {
      alert(error)
    }
  }

  const { updateLanguageStatuses } = useLanguages()

  const handleSubmitUpdate = formValues => {
    updateLanguageStatuses(formValues)
      .then(response => {
        history.push(`/traducciones?action=language_saved`)
      })
      .catch(error => {
        alert(error)
      })
  }

  return (
    <>
      <AddLanguageForm onSubmit={handleSubmit} />
      <EnableLanguagesForm onSubmit={handleSubmitUpdate} />
    </>
  )
}
