import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useFilters from '../../../hooks/useFilters'
import ExportButton from '../exports/ExportButton'
import PageBody from '../../molecules/PageBody'
import ProceduresFilter from '../procedure_managements/ProceduresFilter'
import ProceduresDetailsCards from './ProceduresDetailsCards'
import UserTypeFilter from '../procedure_managements/UserTypeFilter'

const optionsOrderBy = [
  { value: 'creation_datetime desc', label: 'Ingreso: más recientes primero' },
  { value: 'creation_datetime asc', label: 'Ingreso: más antiguos primero' },
  { value: 'deadline asc', label: 'Vencimiento: más próximos primero' },
  { value: 'deadline desc', label: 'Vencimiento: más lejanos primero' }
]

const ProcedureBody = ({ defaultFilters, accesses, id, areaId, states }) => {
  const atLeastHaveOneAccess = accesses?.all?.length > 1
  const [stateUpdater, setStateUpdater] = useState()
  const [stateDelay, setStateDelay] = useState(!atLeastHaveOneAccess)

  const {
    onFilter,
    filters,
    initialFilters,
    results: procedures
  } = useFilters(`/admin/v1/enabled_procedure_managements/${id}/procedures.json`, defaultFilters)
  const url = `/gestiones-de-tramites-habilitadas/${id}/exportaciones`

  const handleChangeType = value => {
    setStateDelay(value)
  }

  useEffect(() => {
    const filtersValues = defaultFilters || initialFilters
    if (filtersValues) {
      onFilter(filtersValues, false)
      setStateUpdater(filtersValues.state)
    }
  }, [defaultFilters, initialFilters])

  return (
    <PageBody
      results={stateDelay ? procedures : { total_pages: 0, total_count: 0 }}
      onFilter={onFilter}
      initialFilters={initialFilters}
      searchPlaceHolder="Buscar por nombre, nro. de documento o código"
      asideFilter={<ProceduresFilter areaId={areaId} states={states} />}
      upperFilter={
        atLeastHaveOneAccess && (
          <div className="mx-6 md:mx-0 md:w-1/4">
            <p>Estás viendo las solicitudes de este perfil:</p>
            <UserTypeFilter userTypeList={accesses.all} stateUpdater={stateUpdater} onChange={handleChangeType} />
          </div>
        )
      }
      topRightSide={
        <ExportButton
          title="Exportar solicitudes"
          url={url}
          filters={filters}
          exportable={{ type: 'procedures', id }}
        />
      }
      optionsOrderBy={optionsOrderBy}
      searchContainerClassName="sm:max-w-84 md:max-w-104 mb-4 md:mb-0"
    >
      {stateDelay && <ProceduresDetailsCards procedures={procedures.data} id={id} />}
    </PageBody>
  )
}

ProcedureBody.propTypes = {
  accesses: PropTypes.object,
  areaId: PropTypes.number,
  defaultFilters: PropTypes.object,
  id: PropTypes.string,
  states: PropTypes.array
}

export default ProcedureBody
