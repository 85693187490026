import { useQuery as reactQuery } from 'react-query'

const wrapper =
  axiosFn =>
  ({ queryKey }) =>
    axiosFn(...queryKey.slice(1))

export const isLoading = status => status === 'loading'

export const isSuccessful = status => status === 'success'

export const isError = status => status === 'error'

export const isUnauthorized = error => error?.response?.status === 401

const useQuery = (queryKeys, queryFunction, options) => {
  const { data, status, error } = reactQuery(queryKeys, wrapper(queryFunction), {
    refetchOnWindowFocus: false,
    ...options
  })

  return { data, status, error }
}
export default useQuery
