import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'

const EmailCitizenField = ({ index, name, payload, required, disabled = false, margin }) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const translationText = useTextValidation()
  return (
    <TextField
      hint={payload?.hint}
      label={label}
      name={name}
      disabled={disabled}
      validate={validations.mix(
        required && validations.required(translationText.required),
        validations.notHasBlankSpaces(translationText.notHasBlankSpaces),
        validations.isEmail(translationText.isEmail)
      )}
      margin={margin}
      size="2xl"
    />
  )
}

export default EmailCitizenField

EmailCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
