import React, { Fragment, useState } from 'react'
import CheckboxField from '../components/molecules/fields/CheckboxField'
import { ExampleModal } from '../components/molecules/dialogs/ExampleModal'
import questionKeyDesktop from '../images/icons/question_key.svg'
import questionKeyMobile from '../images/icons/question_key_mobile.svg'
import WhenFieldChanges from '../components/molecules/WhenFieldChanges'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import SelectField from '../components/molecules/fields/SelectField'
import * as validations from '../utils/validations'
import { dataToOptions, getUnselected } from '../utils/helpers'
import { isDefault } from '../hooks/useTask'
import SelectOptions from '../utils/selectOptions'
import * as uuid from 'uuid'
import getByPath from 'lodash.get'
import FilterQuestion from '../components/molecules/FilterQuestion'

const CITIZEN_ID = 1

const privacyPolicyExampleTypeOptions = [
  { value: 'standard', label: 'El ejemplo estándar' },
  { value: 'modified', label: 'Un ejemplo modificado' }
]

const QuestionKey = () => {
  const [showQuestionKeyModal, setShowQuestionKeyModal] = useState(false)

  return (
    <Fragment>
      <div className="mb-6 md:mb-0">
        <CheckboxField
          name="has_key_question"
          margin="none"
          label={
            <>
              Quiero que las respuestas de una de las preguntas aparezcan en el listado de solicitudes de los
              Funcionarios.
              <br className="md:hidden" />
              <button
                className="font-bold md:ml-1 text-blue-700"
                onClick={event => {
                  event.preventDefault()
                  setShowQuestionKeyModal(true)
                }}
                type="button"
              >
                Ver ejemplo
              </button>
            </>
          }
        />
        <WhenFieldChanges field="has_key_question" set="key_question" to={null} />
      </div>
      <ExampleModal
        onClose={() => setShowQuestionKeyModal(false)}
        show={showQuestionKeyModal}
        desktopImage={questionKeyDesktop}
        mobileImage={questionKeyMobile}
        title="Pregunta Clave"
      >
        Al seleccionar una pregunta clave, sus respuestas le aparecerán a los funcionarios en el listado de solicitudes,
        ayudándoles a identificar y diferenciar mejor estas últimas. Por ejemplo, para un trámite de Acceso a la
        Información Pública se escogió {'"'}Detalle de Información{'"'} como pregunta clave.
      </ExampleModal>
    </Fragment>
  )
}

const instructions = [
  'Elige el tipo de pregunta de tu formulario, agrega su contenido y selecciona si es obligatoria u opcional.',
  'Ordena las preguntas de la forma más conveniente.',
  'Si tu formulario es extenso, puedes dividirlo en varias páginas.'
]

const conditionActionOptions = [
  { value: 'has_been_disabled', label: 'se ha deshabilitado' },
  { value: 'has_been_completed', label: 'se ha completado' },
  { value: 'not_completed', label: 'no se ha completado' }
]

const conditionTimeOptions = [
  { value: 'anytime', label: 'en cualquier momento' },
  { value: 'in_x_time', label: "'x' cantidad de días" }
]

const conditionAfterOptions = [
  { value: 'after_citizen_send_procedure', label: 'después de que el ciudadano envía la solicitud' },
  { value: 'after_user_receives_request', label: 'después de que el usuario recibe la solicitud' }
]

const thenActionOptions = [
  { value: 'enable', label: 'habilitar' },
  { value: 'disable', label: 'deshabilitar' },
  { value: 'change_state', label: 'cambiar estado' }
]

const colors = {
  green: { label: 'Verde', bgColor: 'bg-green-500' },
  yellow: { label: 'Amarillo', bgColor: 'bg-yellow-400' },
  orange: { label: 'Anaranjado', bgColor: 'bg-orange-500' },
  red: { label: 'Rojo', bgColor: 'bg-red-300' },
  purple: { label: 'Morado', bgColor: 'bg-indigo-400' },
  blue: { label: 'Azul', bgColor: 'bg-blue-400' }
}

const getColorOptions = () =>
  Object.keys(colors).map(color => ({ value: color, label: colors[color].label, className: getBgColor(color) }))

const getBgColor = color => colors[color]?.bgColor || 'italic'

const getStateOptions = (states, onDestroy) => {
  let addGroupLabel = false
  const stateOptions = []
  for (const state of states) {
    let label = state.name
    if (!state.is_default) {
      if (!addGroupLabel) {
        stateOptions.push({ groupLabel: 'Estados creados:' })
        addGroupLabel = true
      }
      label = (
        <>
          {state.name}
          <IconButton type="button" color="primary" onClick={() => onDestroy(state)} className="p-0 ml-2">
            <DeleteIcon />
          </IconButton>
        </>
      )
    }
    stateOptions.push({
      value: state.id.toString(),
      label,
      className: getBgColor(state.color) + ' flex justify-center'
    })
  }
  return stateOptions
}

const getOptionTasksToRules = (tasks, currentTask) => {
  const options = [{ value: currentTask.id.toString(), label: `Esta tarea (${currentTask.name})` }]
  for (const task of tasks) {
    if (task.id !== currentTask.id) {
      options.push({
        value: task.id.toString(),
        label: (
          <>
            <span className="font-bold">{task.name}</span> por {task.origin_user_type_name}
          </>
        )
      })
    }
  }
  return options
}

const getOptionTasks = (tasks, selectedTasks, index) => {
  const taskWithoutDefaultType = tasks.filter(task => !isDefault(task))
  return dataToOptions(getUnselected(taskWithoutDefaultType, selectedTasks, index))
}

const getOptionUserTypesToUpdateState = (userTypes, selectedUserTypes, index) =>
  dataToOptions(getUnselected(userTypes, selectedUserTypes, index))

const formSteps = {
  CONFIGURATION: {
    label: 'Configuración del trámite',
    title: 'Configuración general del trámite'
  },
  INITIAL_FORM: {
    label: 'Formulario inicial',
    title: 'Crea el formulario que da inicio al trámite'
  },
  USER_TYPES_TASKS_CONNECTIOS: {
    label: 'Tipos de usuario, tareas y conexiones',
    title: 'Crea los tipos de usuario, sus tareas y las conexiones entre tareas'
  },
  PREVIEW: {
    label: 'Vista previa y publicación',
    hint: 'Revisa la gestión del trámite y publícala para que pueda ser utilizada por los funcionarios públicos'
  }
}

const switches = [
  { name: 'sort_questions', label: 'El funcionario podrá mover el orden de las preguntas.' },
  { name: 'add_questions', label: 'El funcionario podrá crear preguntas y páginas.' },
  {
    name: 'edit_delete_questions',
    label: 'El funcionario podrá editar y borrar preguntas.',
    message: 'Si se eliminan campos sobre Nombres, tipo de documento y correo pueden generar errores en su formulario'
  }
]

const selects = {
  it_costs: {
    title: 'Pago junto con el envío del formulario',
    hint: (
      <CheckboxField
        name="it_costs"
        margin="none"
        label="El trámite requiere que el ciudadano realice un pago con el envío de este formulario."
      />
    )
  },
  show_auto_response: {
    title: 'Información para el ciudadano después de enviado el formulario',
    hint: (
      <CheckboxField
        name="show_auto_response"
        margin="none"
        label="Quiero brindar información adicional en la constancia de envio del formulario."
      />
    )
  },
  has_key_question: {
    title: 'Pregunta clave',
    hint: <QuestionKey />
  },
  has_filter_question: {
    title: 'Incluir pregunta en estadísticas de un tema nacional',
    hint: <FilterQuestion />
  },
  sworn_declaration: {
    title: 'Declaración jurada',
    hint: (
      <CheckboxField
        name="sworn_declaration"
        margin="none"
        label="Este formulario tiene carácter de declaración jurada."
      />
    )
  },
  privacy_policy_example_type: {
    title: '',
    hint: (
      <div className="md:flex items-center justify-between">
        <div>
          <label htmlFor="privacy_policy_example_type-input" className="font-bold block">
            ¿Qué ejemplo de política de privacidad deseas que el usuario descargue?
          </label>
          <a className="font-bold" href="/Política%20de%20Privacidad%202024-1.docx" target="_blank">
            Revisa el ejemplo estándar
          </a>
        </div>
        <SelectField
          name={'privacy_policy_example_type'}
          options={privacyPolicyExampleTypeOptions}
          margin="none"
          containerClassName="mt-2 md:mt-0 min-w-60"
          validate={validations.required()}
          emptyLabel="Selecciona el documento"
        />
      </div>
    )
  }
}
const fieldTitle = code => selects[code]

const taskTypes = {
  normal: {
    label: <span className="font-bold">Tarea normal</span>
  },
  enable_payment: {
    label: (
      <>
        <span className="font-bold">Solicitar pago:</span> después de realizada la tarea, automáticamente se le habilita
        al ciudadano una nueva tarea de Realizar pago.
      </>
    )
  },
  extension_notice: {
    label: (
      <>
        <span className="font-bold">Aviso de prórroga:</span> a la tarea se le adicionará una pregunta para agregar días
        al tiempo de atención de la solicitud.
      </>
    )
  },
  refer_branch_office_task: {
    label: (
      <>
        <span className="font-bold">Derivar a otra sede:</span> la solicitud se enviará a la sede seleccionada. Esta
        tarea se mostrará solo a entidades con más de una sede.
      </>
    )
  },
  rectification_request: {
    label: (
      <>
        <span className="font-bold">Pedido de subsanación:</span> después de realizada la tarea, se detiene el tiempo de
        atención de solicitud hasta que el ciudadano responda. Además, se deshabilitan todas las tareas (menos las del
        ciudadano).
      </>
    )
  },
  commentary: {
    label: (
      <>
        <span className="font-bold">Comentario:</span> sirve para dejar anotaciones que sean de utilidad en la gestión
        de la solicitud. Lo anotado se mostrará a todos los funcionarios que tengan la solicitud asignada.
      </>
    )
  },
  correct_observations: {
    label: (
      <>
        <span className="font-bold">Subsanar observaciones:</span> después de realizar la tarea, el tiempo de atención
        de solicitud se reinicia. Además se vuelven a habilitar las tareas deshabilitadas en el Pedido de subsanación
      </>
    )
  },
  refer_to_institution: {
    label: (
      <>
        <span className="font-bold">Derivar a entidad externa:</span> la solicitud se enviará a otra entidad y el tiempo
        de atención se reiniciará.
      </>
    )
  }
}

const getTaskTypeOptions = userTypeId => {
  const selectOptions = new SelectOptions(taskTypes)
  if (isDefaultUserType(userTypeId)) {
    selectOptions.addOptionsGroup(['normal', 'correct_observations'])
  } else {
    selectOptions.addOptionsGroup(['normal'])
    selectOptions.addGroupLabel(<div className="mt-4">Tareas especiales:</div>)
    selectOptions.addOptionsGroup([
      'extension_notice',
      'rectification_request',
      'enable_payment',
      'refer_branch_office_task',
      'refer_to_institution',
      'commentary'
    ])
  }
  return selectOptions.options
}

const disableTypeOptions = [
  {
    groupLabel: <div className="mt-4">Para tareas que se realizan una vez:</div>
  },
  {
    value: 'all_tasks',
    label: 'Todas sus tareas'
  },
  {
    value: 'just_this_task',
    label: 'Solo esta tarea'
  },
  {
    groupLabel: <div className="mt-4">Para tareas que se realizan más de una vez en paralelo:</div>
  },
  {
    value: 'all_tasks_except_this',
    label: 'Todas sus tareas, menos esta'
  },
  {
    value: 'none_task',
    label: 'Ninguna tarea'
  },
  {
    groupLabel: <div className="mt-4">Otros:</div>
  },
  {
    value: 'specify_tasks',
    label: 'Especificar tareas'
  }
]

const amountTypeOptions = [
  { value: 'fixed', label: 'Fijo: siempre es el mismo monto.' },
  { value: 'variable', label: 'Variable: se determina según la solicitud del ciudadano.' }
]

const defaultUserType = { id: 1, name: 'Ciudadano' }

const isDefaultUserType = id => defaultUserType.id.toString() === id.toString()

const getLabelToNext = (action, task, user) => {
  const actionList = {
    enable: 'habilitarán',
    disable: 'deshabilitarán'
  }

  const personToActionList = {
    enable: {
      refer_branch_office_task: ' (de sede que recibe)',
      refer_to_institution: ' (de entidad que recibe)'
    },
    disable: {
      refer_branch_office_task: ' (de sede que envía)',
      refer_to_institution: ' (de entidad que envía)'
    }
  }
  const personToAction = getByPath(personToActionList, `${action}.${task.kind}`, '')

  return (
    <div className="flex min-w-25 mb-2">
      <h3 className="font-bold">
        Al realizarse la tarea de {task.name}, se {actionList[action]} las siguientes tareas {user && 'para el ' + user}
        <span className="font-normal">{personToAction}</span>
        {':'}
      </h3>
    </div>
  )
}

const getRulesTitleAdditionalText = kind => {
  const AdditionalTextByKind = {
    refer_to_institution: '(para entidad que envía)'
  }
  return AdditionalTextByKind[kind] || ''
}

const getUpdateStateOtherUsersText = kind => {
  const otherUsersFromByKind = {
    refer_to_institution: ' de la entidad que envía'
  }
  const otherUsersFrom = otherUsersFromByKind[kind] || ''
  return `Quiero que al completarse la tarea, también se cambie el estado a otros usuarios${otherUsersFrom}.`
}

const getOriginAndDestinySectionLabel = (source, kind) => {
  const baseText = {
    origin: 'Usuario que realiza la tarea y envía el trámite',
    destiny: 'Usuario que recibe el trámite'
  }
  const titleByKind = {
    origin: {
      refer_to_institution: 'Entidad que envía'
    },
    destiny: {
      refer_to_institution: 'Entidad que recibe'
    }
  }
  const titleByKindText = getByPath(titleByKind, `${source}.${kind}`, '')
  return titleByKindText ? (
    <>
      {titleByKindText} <br />
      <span className="font-normal">{baseText[source]}</span>{' '}
    </>
  ) : (
    baseText[source]
  )
}

const defaultQuestionByTaskKind = kind => {
  const questions = {
    refer_to_institution: {
      key: uuid.v4(),
      kind: 'external_institution_task',
      required: true,
      payload: {
        label: 'Selecciona la entidad a la cual se derivará la solicitud'
      }
    },
    refer_branch_office_task: {
      key: uuid.v4(),
      kind: 'refer_branch_office',
      required: true,
      payload: {
        label: 'Sede a la que derivarás la solicitud:'
      }
    }
  }
  return questions[kind]
}

export {
  conditionActionOptions,
  conditionTimeOptions,
  conditionAfterOptions,
  thenActionOptions,
  getColorOptions,
  getBgColor,
  getStateOptions,
  getOptionTasksToRules,
  getOptionTasks,
  getOptionUserTypesToUpdateState,
  formSteps,
  getTaskTypeOptions,
  disableTypeOptions,
  amountTypeOptions,
  defaultUserType,
  isDefaultUserType,
  fieldTitle,
  instructions,
  getLabelToNext,
  getRulesTitleAdditionalText,
  getUpdateStateOtherUsersText,
  getOriginAndDestinySectionLabel,
  defaultQuestionByTaskKind,
  switches,
  CITIZEN_ID
}
