import PropTypes from 'prop-types'
import React from 'react'

const ManualEntryPayment = ({ answer }) => {
  return (
    <>
      <span className="font-extrabold inline-block">Pago: </span> Ingreso Manual
      <div className="mt-1">
        Comprobante:
        <a className="ml-2" href={answer.bank_transfer_receipt_url} target="_blank" rel="noreferrer">
          {answer.bank_transfer_receipt}
        </a>
      </div>
    </>
  )
}
export default ManualEntryPayment

ManualEntryPayment.propTypes = {
  answer: PropTypes.object.isRequired
}
