import React, { Fragment } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Container from '../atoms/Container'
import { useLocation, Link } from 'react-router-dom'
import { Button } from '../atoms/Button'
import EditIcon from '@material-ui/icons/Edit'
import PublishStatusSection from '../organisms/forms/PublishStatusSection'
import CopyText from '../molecules/buttons/CopyText'
import queryString from 'query-string'
import Alert from '../molecules/Alert'
import PropTypes from 'prop-types'
import { PageNavigation } from '../organisms/navigation/PageNavigation'

const Preview = ({ steps, children, data, urlToCopy, publishedUpdate, informationText, edit, publishAlert = null }) => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)

  return (
    <Fragment>
      <PageNavigation breadcrumbData={data} />
      <Container className="py-16">
        {urlSearchParams.action === 'new' ? (
          steps
        ) : (
          <h1 aria-describedby="page-description" className="title mb-8 mx-4 md:mx-0">
            Vista previa: {data.name}
          </h1>
        )}
        {urlSearchParams.action === 'edit' && (
          <Alert type="success" className="mb-8">
            Guardaste los cambios con éxito.
          </Alert>
        )}
        <section className="mb-12 box-content border-2 border-neutral-400 mx-4 md:mx-0">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div className="flex flex-col justify-center p-8">
              <p className="font-bold text-xl mb-2">{informationText.linkTitle}</p>
              <p className="mb-2">{informationText.linkHint}</p>
              {urlToCopy && (
                <div className="flex flex-col md:flex-row items-start md:items-center">
                  <div className="bg-sky-blue-400 py-2 px-4 flex-1 mr-4 mb-2 md:mb-0 w-full">
                    <span className="text-blue-700 font-bold">{urlToCopy}</span>
                  </div>
                  <CopyText copy={urlToCopy} size="s" />
                </div>
              )}
            </div>
            <div className="flex items-center justify-center bg-neutral-100 p-6 md:p-4 flex-wrap">
              <PublishStatusSection canChangeState={edit} mutate={publishedUpdate} item={data} />
              {publishAlert}
            </div>
          </div>
        </section>
        <section>
          <div className="w-fit mb-6 mx-4 md:mx-0">
            <h2 className="font-bold text-2xl mb-2 text-primary flex items-center w-fit">
              <VisibilityIcon className="mr-4" />
              {informationText.previewTitle}
            </h2>
            {informationText.previewHint && <p>{informationText.previewHint}</p>}
          </div>
          <div className="border-4 border-neutral-300 mb-8">{children}</div>
          {edit && (
            <div className="flex justify-center md:justify-end">
              <Button
                size="l"
                variant="secondary"
                el={Link}
                to={edit.url}
                className="bg-neutral-200"
                startIcon={<EditIcon />}
              >
                {edit.buttonLabel}
              </Button>
            </div>
          )}
        </section>
      </Container>
    </Fragment>
  )
}

Preview.propTypes = {
  steps: PropTypes.element,
  children: PropTypes.node,
  data: PropTypes.object,
  urlToCopy: PropTypes.string,
  publishedUpdate: PropTypes.func,
  informationText: PropTypes.exact({
    linkTitle: PropTypes.string,
    linkHint: PropTypes.string,
    previewTitle: PropTypes.string,
    previewHint: PropTypes.string
  }),
  edit: PropTypes.object,
  publishAlert: PropTypes.node
}

export default Preview
