import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '../../atoms/Button'

export const PaymentTransferDisableErrorMessage = ({ params }) => {
  const { forms } = params
  const hasGdt = forms.find(form => form.formable_type === 'ProcedureManagement::Enabled')
  const hasForm = forms.find(form => form.formable_type === null)

  if (hasGdt && hasForm)
    return (
      <>
        <p className="text-sm">
          Este método de pago está habilitado en uno o más formularios simples, o de gestión de trámites.{' '}
          <span className="font-bold">Para desactivarlo</span>, primero, debes deshabilitarlo en la sección{' '}
          <span className="font-bold">Configuración</span> de cada formulario.
        </p>
        <p className="text-sm">
          <span className="font-bold">Importante:</span> Para los formularios de gestión de trámites, dirígete a la
          pestaña <span className="font-bold">Gestión de trámites</span> y busca los formularios que tienen habilitado
          el medio de pago <span className="font-bold">Transferencia bancaria</span> para deshabilitarlo.
        </p>
        <Button el={Link} to="/formularios" variant="text" className="text-sm" size="m">
          Ir a formularios simples
        </Button>
      </>
    )

  if (hasGdt)
    return (
      <>
        <p>
          Este método de pago está habilitado en uno o más formularios de gestión de trámites.{' '}
          <span className="font-bold">Para desactivarlo</span>, primero, debes deshabilitarlo en la sección{' '}
          <span className="font-bold">Configuración</span> de cada formulario.
        </p>
        <Button el={Link} to="/gestiones-de-tramites-habilitadas" variant="text" className="text-sm" size="m">
          Ir a gestión de trámites
        </Button>
      </>
    )

  if (hasForm)
    return (
      <>
        <p>
          Este método de pago está habilitado en uno o más formularios simples.{' '}
          <span className="font-bold">Para desactivarlo</span>, primero, debes deshabilitarlo en la sección{' '}
          <span className="font-bold">Configuración</span> de cada formulario.
        </p>
        <Button el={Link} to="/formularios" variant="text" className="text-sm" size="m">
          Ir a formularios simples
        </Button>
      </>
    )
}

PaymentTransferDisableErrorMessage.propTypes = {
  params: PropTypes.array
}
