import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'

const urgencyStyle = {
  none: '',
  medium: 'text-yellow-800 font-bold',
  high: 'text-red-700 font-bold'
}

const getExpirationText = (days, deadline) => {
  if (days > 0) {
    const daysText = days > 1 ? 'días' : 'día'
    return { label: 'Vence en', expiredText: `${days} ${daysText} (${deadline})` }
  }

  return { label: 'Venció el', expiredText: deadline }
}

const ExpirationDaysText = ({ expiration, className }) => {
  const { limit_days: days, urgency, deadline } = expiration
  const { label, expiredText } = getExpirationText(days, deadline)
  return (
    <BoldLabelAndContent
      el="p"
      label={label}
      content={expiredText}
      className={className}
      contentClassName={clsx(urgencyStyle[urgency], 'ml-1 inline-block')}
    />
  )
}
export default ExpirationDaysText

ExpirationDaysText.propTypes = {
  className: PropTypes.string,
  expiration: PropTypes.object.isRequired
}
