import React, { Fragment } from 'react'
import Container from '../../../atoms/Container'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { TermsForm } from '../../../organisms/terms_translations/TermsForm'
import { useParams, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import useTerms from '../../../../hooks/useTerms'
import { useQueryClient } from 'react-query'

const TermsEdit = ({ title = 'Editar Término' }) => {
  const { id: termId } = useParams()
  const history = useHistory()
  const { getTerm, updateTerm } = useTerms(termId)
  const { data: termData, status } = getTerm(termId)
  const queryClient = useQueryClient()
  const handleTermUpdate = async values => {
    try {
      await updateTerm(values)
      await queryClient.invalidateQueries(['term', termId])
      history.push(`/traducciones?action=term_saved`)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  if (status !== 'success') return null

  return (
    <Fragment>
      <PageNavigation />
      <Container className="md:pt-16 pt-8 md:pb-10 pb-8 px-5 md:px-0">
        <h1 className="title text-3xl md:text-4xl mb-8">{title}</h1>
        <TermsForm onSubmit={handleTermUpdate} initialValues={termData} />
      </Container>
    </Fragment>
  )
}

TermsEdit.propTypes = {
  title: PropTypes.string
}
export default TermsEdit
