import PropTypes from 'prop-types'
import React from 'react'
import { Info, CheckCircle, Close, Warning } from '@material-ui/icons'
import clsx from 'clsx'
import { ReactSVG as Svg } from 'react-svg'
import preventiveIcon from '../../images/icons/preventive_icon.svg'
import { CircularProgress, IconButton } from '@material-ui/core'
import useToggle from '../../hooks/useToggle'

const config = {
  info: {
    bgColor: 'bg-blue-300',
    borderColor: 'border-blue-700',
    textColor: 'border-gray-800',
    iconCloseColor: 'text-blue-800',
    icon: <Info className="text-blue-800" />
  },
  info_yellow: {
    bgColor: 'bg-yellow-300',
    borderColor: 'border-yellow-700',
    textColor: 'border-gray-800',
    iconCloseColor: 'text-yellow-800',
    icon: <Info className="text-yellow-600" />
  },
  warning: {
    bgColor: 'bg-yellow-300',
    borderColor: 'border-yellow-800',
    textColor: 'border-gray-800',
    iconCloseColor: 'text-yellow-800',
    icon: <Svg aria-hidden src={preventiveIcon} className="text-yellow-800" />
  },
  success: {
    bgColor: 'bg-green-400',
    borderColor: 'border-green-800',
    textColor: 'border-gray-800',
    iconCloseColor: 'text-green-800',
    icon: <CheckCircle className="text-green-800" />
  },
  error: {
    bgColor: 'bg-red-200',
    borderColor: 'border-red-700',
    textColor: 'text-red-800',
    iconCloseColor: 'text-red-700',
    icon: <Warning className="text-red-800" />
  },
  loading: {
    bgColor: 'bg-blue-400',
    icon: <CircularProgress aria-hidden size="24px" style={{ minWidth: '24px' }} />
  }
}

const Alert = ({
  children,
  type = 'warning',
  hideIcon = false,
  className,
  border = false,
  showClose = false,
  showTextClose = false,
  showIconClose = true,
  actionText,
  actionEl: ActionEl = 'button',
  actionProps,
  onClose,
  iconCloseColorClassName,
  iconButtonClassName,
  actionTextClassName,
  displayPositionClassName
}) => {
  const [close, setClose] = useToggle(false)
  const { bgColor, borderColor = '', textColor = '', icon, iconCloseColor } = config[type]
  const handleClose = () => {
    setClose()
    onClose()
  }

  if (close) return null
  return (
    <div
      className={clsx(
        bgColor,
        displayPositionClassName || 'p-4 flex justify-between flex-row rounded relative self-start items-center',
        className,
        border && 'border-2',
        borderColor
      )}
      role="alert"
      aria-live="polite"
    >
      <div className={clsx('flex gap-3.5 flex-row')}>
        {!hideIcon && icon}
        <span className={clsx('font-medium mt-0.5 leading-6', textColor)}>{children}</span>
      </div>
      <div className={clsx('static top-3 right-6 ml-auto', actionTextClassName)}>
        {actionText && (
          <ActionEl {...actionProps} className="text-blue-700 font-medium leading-6 md:leading-4">
            {actionText}
          </ActionEl>
        )}
        {showClose && (
          <IconButton onClick={handleClose} aria-label="Cerrar" size="small" className={iconButtonClassName}>
            {showTextClose && (
              <span className="text-sm font-bold mr-2 ml-2 mt-0.5 hidden md:block text-blue-700">Cerrar</span>
            )}
            {showIconClose && <Close className={iconCloseColorClassName || iconCloseColor} fontSize="small" />}
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default Alert

Alert.propTypes = {
  actionText: PropTypes.symbol,
  actionProps: PropTypes.object,
  actionEl: PropTypes.element,
  actionTextClassName: PropTypes.string,
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  iconCloseColorClassName: PropTypes.string,
  iconButtonClassName: PropTypes.string,
  displayPositionClassName: PropTypes.string,
  hideIcon: PropTypes.bool,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  showTextClose: PropTypes.bool,
  showIconClose: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(config))
}
