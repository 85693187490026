import React, { Fragment, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { Button } from '../../atoms/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import { Close } from '@material-ui/icons'
import NotificationsTray from './NotificationsTray'
import useFieldValue from '../../../hooks/useFieldValue'
import Tooltip from '../../atoms/Tooltip'

const PreviewModal = () => {
  const [open, setOpen] = useState(false)
  const textField = useFieldValue('text')
  const iconField = useFieldValue('icon')
  const urlDestination = useFieldValue('url_destination')
  return (
    <Fragment>
      <Tooltip title="Vista previa">
        <IconButton
          type="button"
          color="primary"
          onClick={() => setOpen(!open)}
          classes={{ root: 'p-0' }}
          disabled={false}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Button
        type="button"
        variant="text"
        size="xss"
        classes={{ root: 'p-0 justify-end' }}
        onClick={() => setOpen(!open)}
      >
        Vista previa
      </Button>
      <Dialog
        aria-labelledby="dialog-title"
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        open={open}
        classes={{ paper: 'px-8 py-6 w-3/5 h-1/2' }}
      >
        <DialogActions classes={{ root: 'px-0 text-right' }}>
          <Button
            variant="text"
            type="button"
            fullWidth={false}
            onClick={() => setOpen(false)}
            className="font-bold text-base p-0"
            endIcon={<Close />}
          >
            Cerrar
          </Button>
        </DialogActions>
        <DialogTitle id="dialog-title" classes={{ root: 'p-0 text-left mb-6' }}>
          <span className="text-xl font-bold block">Vista previa</span>
        </DialogTitle>
        <DialogContent classes={{ root: 'p-0 text-white border-2 border-solid border-neutral-400 h-44' }}>
          <div className="bg-red-800 h-16">
            <NotificationsTray
              notifications={[
                {
                  id: 1,
                  url_destination: urlDestination || 'www.example.com',
                  icon: iconField,
                  text: textField
                }
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default PreviewModal
