import PropTypes from 'prop-types'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Tooltip as TooltipMUI } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const classesByType = {
  light: {
    tooltip: 'bg-white text-gray-800 shadow-md border border-solid border-gray-200',
    arrowColor: '#fff'
  },
  default: {
    tooltip: 'bg-black',
    arrowColor: '#000000'
  }
}

const useStyles = type =>
  makeStyles(() => {
    const arrowColor = classesByType[type].arrowColor
    return {
      arrow: {
        '&:before': {
          borderRadius: '2px'
        },
        color: arrowColor
      }
    }
  })

const classesBySize = {
  sm: 'py-1 px-2 text-sm',
  md: 'py-2 px-3 font-bold'
}

const Tooltip = (
  { title, children, size = 'md', placement = 'bottom', type = 'default', classes, disableHoverListener, ...props },
  ref
) => {
  const [open, setOpen] = useState(false)
  const _classes = useStyles(type)()

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  useImperativeHandle(ref, () => ({
    openTooltip: () => handleOpen()
  }))

  return (
    <TooltipMUI
      placement={placement}
      title={title}
      classes={{
        tooltip: clsx('rounded text-xs pointer-events-auto', classesByType[type].tooltip, classesBySize[size]),
        arrow: _classes.arrow,
        ...classes
      }}
      arrow
      disableHoverListener={disableHoverListener}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      {...props}
    >
      {children}
    </TooltipMUI>
  )
}

export default forwardRef(Tooltip)

Tooltip.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  disableHoverListener: PropTypes.bool,
  placement: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.node
}
