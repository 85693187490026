import PropTypes from 'prop-types'
import React from 'react'
import * as validations from '../../../utils/validations'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import useCountry from '../../../hooks/useCountry'
import { dataToOptions } from '../../../utils/helpers'
import useTextValidation from '../../../hooks/useTextValidation'

const countryToFlag = isoCode => {
  return isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
}

const CountryCitizenField = ({ index, name, disabled, payload, margin, required }) => {
  const translationText = useTextValidation()
  const { countries } = useCountry()
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const countriesOptions = dataToOptions(countries)
  return (
    <AutocompleteField
      label={label}
      size="2xl"
      name={name}
      hint={payload?.hint}
      disabled={disabled}
      validate={required && validations.required(translationText.required)}
      margin={margin}
      options={countriesOptions}
      renderOption={option => (
        <>
          <span className="mr-2 text-lg">{countryToFlag(option.value)}</span>
          <span className="text-base">
            {option.label} ({option.value})
          </span>
        </>
      )}
    />
  )
}
export default CountryCitizenField

CountryCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
