import React from 'react'

const TwitterIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M11.0237 7.46187L17.3304 0.230957H15.8359L10.3598 6.50946L5.98604 0.230957H0.941406L7.55541 9.72516L0.941406 17.3079H2.43598L8.21893 10.6776L12.838 17.3079H17.8826L11.0233 7.46187H11.0237ZM8.97668 9.80881L8.30654 8.8634L2.9745 1.34068H5.27009L9.5731 7.41175L10.2432 8.35716L15.8367 16.2486H13.5411L8.97668 9.80917V9.80881Z"
        fill="white"
      ></path>
    </svg>
  )
}

export default TwitterIcon
