import { useQuery } from 'react-query'
import axios from 'axios'

const getTermsCategories = async () => {
  const { data } = await axios.get(`/admin/v1/terms/categories.json`)
  return data
}

const getTermsExternalCategories = async () => {
  const { data } = await axios.get(`/admin/v1/terms/external_categories.json`)
  return data
}

export const useTermsCategories = () => {
  return useQuery('categories', getTermsCategories)
}

export const useTermsExternalCategories = () => {
  return useQuery('externalCategories', getTermsExternalCategories)
}
