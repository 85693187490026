import { parseToXB } from './helpers'
import getByPath from 'lodash.get'
import DateFnsUtils from '@date-io/date-fns'
import { getFilteredByMimeType } from '../configs/fileTypesConfig'

export const mix =
  (...args) =>
  (value = null, values = null) =>
    args.reduce((a, b) => (b && b(value, values)) || a, undefined)

export const asyncMix =
  (...args) =>
  (value = null, values = null) =>
    args.reduce(async (a, b) => {
      return (b && (await b(value, values))) || a
    }, undefined)

export const maxLength =
  (length, message = `Ingresar máximo ${length} caracteres.`) =>
  value =>
    value ? ((value || '').length > length ? message : undefined) : undefined

export const minLength =
  (length, message = `Ingresar mínimo ${length} caracteres.`) =>
  value =>
    value ? ((value || '').length < length ? message : undefined) : undefined

export const length =
  (length, message = `Ingresar ${length} caracteres.`) =>
  value =>
    value ? ((value || '').length !== length ? message : undefined) : undefined

export const maxWords =
  (length, message = `Ingresar máximo ${length} palabras.`) =>
  value =>
    value ? (value.split(' ').length <= length ? undefined : message) : undefined

export const required =
  (message = 'Campo requerido.') =>
  value => {
    if (typeof value === 'string') {
      value = value.replace(/\s+/g, '')
    }
    return value || value === 0 ? undefined : message
  }

export const isEmail =
  (message = 'No es un email válido.') =>
  value => {
    const EMAIL_REGEX = /^[\w-.]+@([a-zA-Z0-9-]+\.)+([a-zA-Z-]){2,4}$/
    return value ? (EMAIL_REGEX.test(value.trim()) ? undefined : message) : undefined
  }

export const isPhone =
  (message = 'No es un teléfono válido.') =>
  value => {
    const PHONE_REGEX = /^\+?[0-9]{1,3}-?[0-9]{5,12}$/
    return value ? (PHONE_REGEX.test(value.trim()) ? undefined : message) : undefined
  }

export const isPeruvianCellphone =
  (message = 'No es un teléfono válido.') =>
  value => {
    const PERUVIAN_PHONE_REGEX = /^9[0-9]{8}$/
    return value ? (PERUVIAN_PHONE_REGEX.test(value) ? undefined : message) : undefined
  }

export const isNumber =
  (message = 'Solo se permiten números.') =>
  value => {
    const NUMBER_REGEX = /^\d+$/
    return value ? (NUMBER_REGEX.test(value) ? undefined : message) : undefined
  }

export const isAlphanumeric =
  (message = 'Solo se permiten letras y números.') =>
  value => {
    const ALPHANUMERIC_REGEX = /^[A-Za-z0-9]+$/
    return value ? (ALPHANUMERIC_REGEX.test(value) ? undefined : message) : undefined
  }

export const requiredArray =
  (message = 'Campo requerido.', maxLength = 1) =>
  value =>
    value && value.filter(v => !v?._destroy).length >= maxLength ? undefined : message

export const isDate =
  (message = 'Ingresa una fecha válida.') =>
  value =>
    value === null ? undefined : value instanceof Date && Number.isNaN(value?.getTime()) ? message : undefined

export const isNotFutureDate =
  (message = 'No puedes ingresar una fecha posterior a la actual.') =>
  value => {
    const dateFns = new DateFnsUtils()
    const today = new Date()
    return value === null ? undefined : dateFns.isAfterDay(value, today) ? message : undefined
  }

export const editPassword =
  (message = 'Si cambias tu contraseña, ingresar mínimo 8 caracteres.') =>
  value =>
    !value ? undefined : value.length >= 8 ? undefined : message

const URL_REGEX = new RegExp(
  '^(http(s)?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|localhost|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '((\\?|\\#)[;&a-z\\d%\\[\\]_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
)
export const isURL =
  (message = 'No es una URL válida.') =>
  value =>
    value ? (URL_REGEX.test(value) ? undefined : message) : undefined

const IFRAME_REGEX = /(?:<iframe>]*)|(iframe>)$/
export const isIframe =
  (message = 'URL no es de un vídeo incorporado.') =>
  value =>
    value ? (IFRAME_REGEX.test(value) ? undefined : message) : undefined

export const maxFileSize =
  (maxSize, prefix, message = `Has sobrepasado el límite de ${maxSize} ${prefix}.`, messageMultiple = message) =>
  value => {
    if (!value || value.saved) return undefined

    const isArray = Array.isArray(value)
    const totalSize = isArray
      ? value.reduce((acc, v) => acc + parseToXB(v.size, prefix), 0)
      : parseToXB(value.size, prefix)
    if (totalSize > maxSize) {
      return isArray > 1 ? messageMultiple : message
    }
    return undefined
  }

export const maxImageDimensions =
  (maxWidth, maxHeight, message = `Has sobrepasado el límite de ${maxWidth}px de ancho y ${maxHeight}px de alto.`) =>
  async value => {
    if (!value || value.saved) return undefined

    let valid = true

    const checkDimensions = file =>
      new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = function (e) {
          const img = new Image()
          img.src = e.target.result

          img.onload = function () {
            const width = img.width
            const height = img.height
            if (width > maxWidth || height > maxHeight) {
              valid = false
            }
            resolve()
          }
        }
        reader.readAsDataURL(file)
      })

    await checkDimensions(value)

    return !valid ? message : undefined
  }

export const minImageDimensions =
  (
    maxWidth,
    maxHeight,
    message = `Las dimensiones mínimas permitidas son ${maxWidth}px de ancho y ${maxHeight}px de alto.`
  ) =>
  async value => {
    if (!value || value.saved) return undefined

    let valid = true

    const checkDimensions = file =>
      new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = function (e) {
          const img = new Image()
          img.src = e.target.result

          img.onload = function () {
            const width = img.width
            const height = img.height
            if (width < maxWidth || height < maxHeight) {
              valid = false
            }
            resolve()
          }
        }
        reader.readAsDataURL(file)
      })

    await checkDimensions(value)

    return !valid ? message : undefined
  }

export const isValidFileMimeType =
  (mimeType, message = 'Los archivos seleccionados no cumplen con el formato solicitado.') =>
  value => {
    if (!value || value.saved) return undefined

    const isArray = Array.isArray(value)
    const valueFiles = isArray ? value : [value]
    return valueFiles.length === getFilteredByMimeType(valueFiles, mimeType).length ? undefined : message
  }

export const isLetter =
  (message = 'Solo se permite letras.') =>
  value => {
    const LETTER = /^([a-zA-ZÀ-ÿ\u00f1\u00d1 ])+$/
    return value ? (LETTER.test(value) ? undefined : message) : undefined
  }

export const startWith =
  (searchString, message = `Debe empezar con ${searchString}.`) =>
  value =>
    value ? (value.startsWith(searchString) ? undefined : message) : undefined

export const lessThan =
  (path, message = `No puede ser mayor o igual que ${path}.`) =>
  (value, values) => {
    const toCompare = getByPath(values, path)
    return value && toCompare && parseInt(value) >= parseInt(toCompare) ? message : undefined
  }

export const uniqueAlternativeLabel =
  (path, message = `Las alternativas no pueden repetirse; elimina o cambia las que no cumplan esta condición.`) =>
  (value, values) => {
    const alternatives = getByPath(values, path)
    const equalsAlternativeCount = alternatives?.reduce((accumulator, alternative) => {
      return alternative?.label === value ? ++accumulator : accumulator
    }, 0)
    return equalsAlternativeCount > 1 ? message : undefined
  }

export const notHasBlankSpaces =
  (message = `No puede contener espacios en blanco.`) =>
  value => {
    const BLANK_SPACES = /\s+/
    return value ? (BLANK_SPACES.test(value) ? message : undefined) : undefined
  }

export const validateOnePerCol =
  (message = 'No puedes marcar más de una opción por columna.') =>
  values => {
    const selectedCols = values ? values.map(el => el.split('_')[1]).filter(el => el) : []
    const uniqueCols = [...new Set(selectedCols)]
    return selectedCols.length === uniqueCols.length ? undefined : message
  }

export const validateOnePerRow =
  (length, message = 'Debes marcar al menos una opción por fila.') =>
  values => {
    const selectedRows = values ? values.map(el => el.split('_')[0]).filter(el => el) : []
    const uniqueRows = [...new Set(selectedRows)]
    return uniqueRows.length === length ? undefined : message
  }
