import React from 'react'
import useStatData from '../../../hooks/useStatData'
import FilterForm from './filters/FilterForm'
import ChartDisplay from './charts/ChartDisplay'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { SelectInput } from '../../molecules/fields/SelectField'
import { useAllowedRoles } from '../../../hooks/useAllowedRoles'
import determineMainRole from '../../../utils/currentRole'
import PropTypes from 'prop-types'

const ChartSection = ({ selectedIndicator, changeIndicator }) => {
  const { initialFilters, fetchFilteredData } = useStatData()
  const allowedRoles = useAllowedRoles()
  const currentRole = determineMainRole(allowedRoles)

  const indicatorOptions = [
    { id: 'users', name: 'Usuarios registrados' },
    { id: 'institutions', name: 'Entidades registradas' },
    { id: 'forms', name: 'Formularios creados' },
    { id: 'answers', name: 'Respuestas recibidas' },
    { id: 'receivedRequests', name: 'Estados de solicitud' }
  ]

  const accessRules = {
    admin: ['users', 'institutions', 'forms', 'answers', 'receivedRequests'],
    coordinator: ['users', 'forms', 'answers', 'receivedRequests'],
    branch_coordinator: ['users', 'forms', 'answers', 'receivedRequests'],
    collaborator: ['forms', 'answers', 'receivedRequests']
  }

  const getAvailableIndicators = role => {
    return indicatorOptions.filter(option => accessRules[role].includes(option.id))
  }

  const availableIndicators = getAvailableIndicators(currentRole)

  return (
    <div className="chart-section" id="chart-section">
      <div className="py-8 px-6 bg-neutral-100 flex items-center border-b border-gray-350 border-solid flex-wrap md:flex-nowrap">
        <div className="flex flex-col pb-4 md:pb-0">
          <div className="w-72">
            <SelectInput value={selectedIndicator} onChange={e => changeIndicator(e.target.value)} label="Indicador">
              {availableIndicators.map(option => (
                <MenuItem value={option.id} key={option.id}>
                  <Typography className="text-base whitespace-normal leading-none pl-6 pr-6 py-2">
                    {option.name}
                  </Typography>
                </MenuItem>
              ))}
            </SelectInput>
          </div>
        </div>
      </div>
      <FilterForm
        initialFilters={initialFilters}
        fetchFilteredData={fetchFilteredData}
        selectedIndicator={selectedIndicator}
        accessRules={accessRules[currentRole]}
      />

      <ChartDisplay selectedIndicator={selectedIndicator} accessRules={accessRules[currentRole]} />
    </div>
  )
}

ChartSection.propTypes = {
  changeIndicator: PropTypes.func.isRequired,
  selectedIndicator: PropTypes.string.isRequired
}
export default ChartSection
