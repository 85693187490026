import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const fetchAnswer = (formId, answerId) =>
  axios.get(`/admin/v1/forms/${formId}/answers/${answerId}.json`).then(res => res.data)

export const useAnswer = () => {
  const { form_id: formId, answer_id: answerId } = useParams()
  return useQuery([formId, answerId, 'answer'], () => fetchAnswer(formId, answerId))
}
