import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'

const { admin } = roles

const Feedback = () => {
  const hasRolePermissions = useHasRolePermissions()

  const canSeeSenderType = hasRolePermissions([admin])
  const canSeeServiceType = hasRolePermissions([admin])

  return { canSeeSenderType, canSeeServiceType }
}

export default Feedback
