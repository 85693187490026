import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import CheckboxField from './CheckboxField'
import useFieldValue from '../../../hooks/useFieldValue'
import ToastAlert from '../ToastAlert'

const ShortTextAnswerType = ({ name }) => {
  const onlyNumbers = useFieldValue(`${name}.only_numbers`)
  const onlyLetters = useFieldValue(`${name}.only_letters`)
  const [showAlert, setShowAlert] = useState(false)
  const firstUpdate = useRef(true)
  const alertText = onlyNumbers
    ? 'Ahora solo se aceptarán respuestas numéricas'
    : 'Ahora solo se aceptarán respuestas de texto'
  const handleChange = value => {
    setShowAlert(value)
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    handleChange(Boolean(onlyNumbers || onlyLetters))
  }, [onlyNumbers, onlyLetters])

  return (
    <div className={clsx('flex items-center pl-6 border-l border-solid border-neutral-500')}>
      <p className="font-bold">Formato de respuesta</p>
      <CheckboxField
        name={`${name}.only_numbers`}
        margin="none"
        label="Permitir solo números"
        className="ml-4"
        disabled={onlyLetters}
        onChange={handleChange}
      />
      <CheckboxField
        name={`${name}.only_letters`}
        margin="none"
        label="Permitir solo letras"
        className="ml-4"
        disabled={onlyNumbers}
        onChange={handleChange}
      />
      <ToastAlert open={showAlert} onClose={() => handleChange(false)} alertText={alertText} type="success" />
    </div>
  )
}

export default ShortTextAnswerType

ShortTextAnswerType.propTypes = {
  name: PropTypes.string
}
