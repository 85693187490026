import PropTypes from 'prop-types'
import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ExpandMore = ({ className }) => <ExpandMoreIcon className={className} />

export default ExpandMore

ExpandMore.propTypes = {
  className: PropTypes.string
}
