import React from 'react'
import useFieldValue from '../../../../hooks/useFieldValue'
import useForm from '../../../../hooks/useForm'
import { isSuccessful } from '../../../../hooks/useQuery'
import AutocompleteField from '../../../molecules/fields/AutocompleteField'

const FormListFilters = () => {
  const formTypeValue = useFieldValue('received_request_form_type')
  const { getAllForm } = useForm()
  const { data: formData, status } = getAllForm(['all_forms', formTypeValue], { type: formTypeValue })

  if (!isSuccessful(status)) return null

  const options = formData?.data.map(el => ({ label: el.name, value: el.id }))

  return (
    <div>
      <AutocompleteField
        options={options}
        name="received_request_form_id"
        size="2xl"
        margin="none"
        emptyLabel="Selecciona el formulario"
        label="Formularios"
        labelClassName="mb-3"
      />
    </div>
  )
}
export default FormListFilters
