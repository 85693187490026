import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import Container from '../../../atoms/Container'
import { Link, useParams } from 'react-router-dom'
import useUser from '../../../../hooks/useUser'
import { Button } from '../../../atoms/Button'
import useUserPermission from '../../../../hooks/useUserPermission'
import PropTypes from 'prop-types'
import DeleteUser from '../../../organisms/areas_users/DeleteUser'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const AdditionalPermissions = ({ permissions }) => (
  <div className="bg-neutral-100 p-6 rounded-lg w-full">
    <p className="font-bold">Permisos adicionales:</p>
    <ul className="list-disc ml-6">
      {permissions.map((permission, index) => (
        <li key={index}>
          {permission.label}:<span className="font-bold ml-1">{permission.permission ? 'Sí' : 'No'}</span>
        </li>
      ))}
    </ul>
  </div>
)

AdditionalPermissions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.object)
}

const UsersDetail = () => {
  const { id } = useParams()
  const { getUser } = useUser()
  const { data: user, status } = getUser(id)
  const { canEdit, getRoleName, canShowAllowedData } = useUserPermission('user')

  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="py-8 md:py-16 px-4 md:px-0">
        <div>
          <div className="text-gray-800 flex flex-col md:flex-row md:justify-between mb-10 md:items-end gap-4">
            <div>
              <p className="font-bold text-4xl">{user.name}</p>
            </div>
            <div className="flex text-blue-700 flex-row justify-center items-center gap-4">
              {canEdit(user.institution_id) && (
                <Button variant="text" el={Link} to={`/usuarios/${user.id}/editar`} size="xs" className="flex">
                  <EditIcon className="mr-2" />
                  Editar
                </Button>
              )}
              <DeleteUser user={user} />
            </div>
          </div>
          <div className="bg-neutral-100 flex flex-col items-start p-8 gap-8 mb-4">
            <h2 className="font-bold text-xl">Datos de usuario</h2>
            <div className="bg-white flex flex-col items-start p-8 gap-8 w-full">
              <div className="flex md:flex-row flex-col items-start p-0 gap-2 w-full">
                <p className="font-bold w-68">Correo electrónico (usuario)</p>
                <p>{user.email}</p>
              </div>
              <hr className="w-full" />
              <div className="flex md:flex-row flex-col items-start p-0 gap-2 w-full">
                <p className="font-bold w-68">Nombres</p>
                <p>{user.name}</p>
              </div>
              <hr className="w-full" />
              <div className="flex md:flex-row flex-col items-start p-0 gap-2 w-full">
                <p className="font-bold w-68">DNI</p>
                <p>{user.document_number}</p>
              </div>
              <hr className="w-full" />
              <div className="flex md:flex-row flex-col items-start p-0 gap-2 w-full">
                <p className="font-bold w-68">Contraseña</p>
                <p>*************</p>
              </div>
            </div>
          </div>
          <div className="bg-neutral-200 flex flex-col items-start p-8 gap-8">
            <h2 className="font-bold text-xl">Datos requeridos por Facilita</h2>
            <div className="bg-white flex flex-col items-start p-8 gap-8 w-full">
              {user.branch_office_name ? (
                <div className="flex md:flex-row flex-col items-start p-0 gap-2 w-full">
                  <p className="font-bold w-68">Sede</p>
                  <p>{user.branch_office_name}</p>
                </div>
              ) : null}
              <hr className="w-full" />
              <div className="flex md:flex-row flex-col items-start p-0 gap-2 w-full">
                <p className="font-bold w-68">Área interna</p>
                <p>{user.area}</p>
              </div>
              <hr className="w-full" />
              <div className="flex md:flex-row flex-col items-start p-0 gap-2 w-full">
                <p className="font-bold w-68">Tipo de perfil</p>
                <p>{getRoleName(user)}</p>
                {canShowAllowedData(user.role) && (
                  <AdditionalPermissions
                    permissions={[{ label: 'Administrar sus permisos', permission: user.has_manage_permission }]}
                  />
                )}
              </div>
              {canShowAllowedData(user.role) && (
                <>
                  <div className="flex flex-col items-start p-0 gap-2 w-full">
                    <p className="font-bold">Formularios asignados</p>
                    <ul className="list-disc ml-6">
                      {user.allowed_form_names?.map((form, index) => (
                        <li key={index}>{form}</li>
                      ))}
                    </ul>
                    <AdditionalPermissions
                      permissions={[{ label: 'Crear y editar formularios', permission: user.has_form_permission }]}
                    />
                  </div>
                  <div className="flex flex-col items-start p-0 gap-2 w-full">
                    <p className="font-bold">Consultas rápidas asignadas</p>
                    <ul className="list-disc ml-6">
                      {user.allowed_quick_search_names?.map((quickSearch, index) => (
                        <li key={index}>
                          <span>{quickSearch}</span>
                        </li>
                      ))}
                    </ul>
                    <AdditionalPermissions
                      permissions={[
                        {
                          label: 'Crear y editar consultas rápidas',
                          permission: user.has_quick_search_permission
                        }
                      ]}
                    />
                  </div>
                  {user.enabled_procedure_managements.length ? (
                    <div className="flex flex-col items-start p-0 gap-2 mb-2">
                      <p className="font-bold">Gestión de trámites asignados</p>
                      <ul className="list-disc ml-6">
                        {user.enabled_procedure_managements?.map((enabledProcedureManagement, index) => (
                          <li key={index}>
                            <span>{enabledProcedureManagement.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button variant="secondary" className="md:max-w-56 md:mr-3" size="full" el={Link} to={`/areas-y-usuarios`}>
            {'Regresar'}
          </Button>
        </div>
      </Container>
    </>
  )
}

export default UsersDetail
