import React from 'react'
import PropTypes from 'prop-types'
import imageError from '../../images/svg_losentimos.svg'
import { Button } from '../atoms/Button'
import { Link } from 'react-router-dom'

const ErrorAccessPage = ({ importantText, description, textButton, linkButton }) => {
  return (
    <div className="grid">
      <div className="flex flex-col items-center pt-20 text-center px-6 md:px-4">
        <img src={imageError} className="mb-4 w-45 md:w-80" alt="" />
        <p className="font-bold text-xl md:text-2xl mb-2">{importantText}</p>
        <p className="text-base md:text-xl max-w-xl mb-10">{description}</p>
        {textButton && (
          <Button el={Link} variant="secondary" to={linkButton}>
            {textButton}
          </Button>
        )}
      </div>
    </div>
  )
}
ErrorAccessPage.propTypes = {
  importantText: PropTypes.string,
  description: PropTypes.string,
  textButton: PropTypes.string,
  linkButton: PropTypes.string
}
export default ErrorAccessPage
