export const isFirst = (fieldsValue, currentIndex) => {
  const actives = getActives(fieldsValue)
  return actives[0].realIndex === currentIndex
}

export const isLast = (fieldsValue, currentIndex) => {
  const actives = getActives(fieldsValue)
  return actives[actives.length - 1].realIndex === currentIndex
}

export const getActives = fieldsValue => {
  const withRealIndex = fieldsValue.map((el, i) => ({ ...el, realIndex: i }))
  return withRealIndex.filter(v => !v?._destroy)
}

export const getIndexToShow = (fieldsValue, currentIndex) => getActiveIndex(fieldsValue, currentIndex) + 1

const getActiveIndex = (fieldsValue, currentIndex) =>
  getActives(fieldsValue).findIndex(active => active.realIndex === currentIndex)

export const getPrevActiveIndex = (fieldsValue, currentIndex) => {
  const actives = getActives(fieldsValue)
  const indexActive = getActiveIndex(fieldsValue, currentIndex)
  return actives[indexActive - 1]?.realIndex
}

export const getNextActiveIndex = (fieldsValue, currentIndex) => {
  const actives = getActives(fieldsValue)
  const indexActive = getActiveIndex(fieldsValue, currentIndex)
  return actives[indexActive + 1]?.realIndex
}
