import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import FormSection from '../../molecules/FormSection'
import Error from '../../molecules/Error'
import SwitchField from '../../molecules/fields/SwitchField'
import { switches } from '../../../configs/procedureManagementsConfig'

export const GeneralConfigurationStep = () => {
  return (
    <FormSection>
      <TextField
        name="procedure_management_attributes.name"
        label="Nombre del trámite"
        margin="none"
        size="3xl"
        validate={validations.required()}
      />
      <FieldsSeparator />
      <TextField
        name="procedure_management_attributes.expiration_days.max"
        label="Cantidad de días para atender la solicitud"
        hint={
          <span className=" mb-4 inline-block">
            Es la cantidad de días totales que se tiene para atender la solicitud del ciudadano desde que este la
            realiza. Por ejemplo, un trámite de Acceso a Información Pública debe atenderse en un máximo de 10 días
            hábiles (sin considerar prórrogas).
          </span>
        }
        size="sm"
        rigthTextDecorator="días"
        placeholder="Ejm: 10"
        margin="dense"
        className="max-w-28"
        validate={validations.mix(validations.isNumber(), validations.required())}
      />
      <div className="p-6 bg-neutral-100 border border-neutral-400">
        <p className="mb-6">
          Define el número de días que determinará el nivel de urgencia de la solicitud; esto ayudará al funcionario a
          saber cuáles debe atender con prioridad:
        </p>
        <ul className="list-outside list-disc">
          <li className="ml-7 mb-5">
            <div className="flex items-center flex-wrap md:flex-nowrap">
              <div className="w-full md:w-auto mb-1">
                <span className="text-yellow-800 font-bold mr-1">Urgencia media:</span> faltando
              </div>
              <TextField
                name="procedure_management_attributes.expiration_days.medium_urgency"
                size="sm"
                rigthTextDecorator="días"
                placeholder="Ejm: 5"
                rootClassName="ml-0 md:ml-4 max-w-64"
                margin="none"
                ariaLabel="urgencia media faltando"
                validate={validations.mix(
                  validations.required(),
                  validations.isNumber(),
                  validations.lessThan(
                    'procedure_management_attributes.expiration_days.max',
                    'Cantidad de días de urgencia media no puede ser mayor o igual al de días totales para atender solicitud.'
                  )
                )}
                hideError
              />
            </div>
            <Error name="procedure_management_attributes.expiration_days.medium_urgency" />
          </li>
          <li className="ml-7">
            <div className="flex items-center flex-wrap md:flex-nowrap">
              <div className="w-full md:w-auto mb-1">
                <span className="text-red-700 font-bold mr-1">Urgencia alta:</span> faltando
              </div>
              <TextField
                name="procedure_management_attributes.expiration_days.high_urgency"
                size="sm"
                rigthTextDecorator="días"
                placeholder="Ejm: 3"
                rootClassName="ml-0 md:ml-4 max-w-64"
                margin="none"
                ariaLabel="urgencia alta faltando"
                validate={validations.mix(
                  validations.required(),
                  validations.isNumber(),
                  validations.lessThan(
                    'procedure_management_attributes.expiration_days.medium_urgency',
                    'Cantidad de días de urgencia alta no puede ser mayor o igual al de días de urgencia media.'
                  ),
                  validations.lessThan(
                    'procedure_management_attributes.expiration_days.max',
                    'Cantidad de días de urgencia alta no puede ser mayor o igual al de días totales para atender solicitud.'
                  )
                )}
                hideError
              />
            </div>
            <Error name="procedure_management_attributes.expiration_days.high_urgency" />
          </li>
        </ul>
      </div>
      <FieldsSeparator />
      {switches.map((item, index) => (
        <div className="flex items-center justify-between py-8 border-b border-neutral-400" key={index}>
          <label htmlFor={`procedure_management_attributes.${item.name}-input`} className="font-bold mr-3">
            {item.label}
            {item.message && (
              <>
                <br />
                <span className="font-normal text-sm">{item.message}</span>
              </>
            )}
          </label>
          <SwitchField name={`procedure_management_attributes.${item.name}`} />
        </div>
      ))}
    </FormSection>
  )
}
