import PropTypes from 'prop-types'
import React, { useEffect, useState, useMemo, useContext } from 'react'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import useInstitution from '../../../hooks/useInstitution'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import TextField from '../../molecules/fields/TextField'
import InfoIcon from '@material-ui/icons/Info'
import useFieldValue from '../../../hooks/useFieldValue'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import { Field, useForm } from 'react-final-form'
import { useAuth } from '../../molecules/Auth'
import useBranchOffices from '../../../hooks/useBranchOffice'
import { dataToOptions } from '../../../utils/helpers'
import { isSuccessful } from '../../../hooks/useQuery'
import { QuestionsAndSubQuestionsContext } from '../../../utils/contexts'

const InstitutionToReferCitizenField = ({ index, payload, required, name, disabled }) => {
  const { change } = useForm()
  const { user } = useAuth()
  const fieldsValue = useFieldValue('answers')
  const [backupFields, setBackupFields] = useState({})
  const [fieldsDisabled, setFieldsDisabled] = useState(false)
  const { citizenEmail, onDestinyInstitutionChange, procedureManagementId } =
    useContext(QuestionsAndSubQuestionsContext) || {}

  const { useGetAll, useGetInstitutionWithEnabledByProcedure } = useInstitution()
  const { data: institutions } = useGetAll(['all_institutions'])
  const options = useMemo(
    () => dataToOptions(institutions?.filter(institution => institution.id !== user.institution_id)),
    [institutions]
  )

  const institutionId = useFieldValue(`${name}.destiny_institution_id`)
  const branchOfficeId = useFieldValue(`${name}.destiny_branch_office_id`)
  const { data: selectedInstitution, status: statusSelected } = useGetInstitutionWithEnabledByProcedure(
    institutionId,
    procedureManagementId
  )

  const { get: getBranchOffices } = useBranchOffices(institutionId, 'allowed_refer_to')
  const { data: branchOffices } = getBranchOffices(
    ['branch_offices', institutionId, procedureManagementId],
    { institution_id: institutionId, procedure_management_id: procedureManagementId },
    { enabled: Boolean(selectedInstitution?.enabled_procedure_management) }
  )

  const handleBranchChange = branchOfficeId => {
    const selectedBranchOffice = branchOffices?.find(el => el.id === parseInt(branchOfficeId))
    change(`${name}.enabled_procedure_managements_id`, selectedBranchOffice?.enabled_procedure_managements_id)
  }

  useEffect(() => {
    if (!selectedInstitution) return

    if (!selectedInstitution?.enabled_procedure_management) {
      onDestinyInstitutionChange && onDestinyInstitutionChange('external_institution_task', 'disable_questions')
      change('visible_index', [index])
    } else {
      onDestinyInstitutionChange && onDestinyInstitutionChange('external_institution_task', 'enable_questions')
      change('visible_index', null)
    }
    change(`${name}.enabled_procedure_managements_id`, selectedInstitution?.enabled_procedure_management?.id)

    if (institutionId && !selectedInstitution?.enabled_procedure_management && !fieldsDisabled && fieldsValue) {
      const backup = fieldsValue.map(el => ({
        question_id: el.question_id
      }))
      setBackupFields({ index, fieldsValue: backup })
      change('answers', [fieldsValue[index - 1]])
      setFieldsDisabled(true)
    }
    if (institutionId && selectedInstitution?.enabled_procedure_management?.id && fieldsDisabled) {
      const restoreFields = [...backupFields.fieldsValue]
      restoreFields[backupFields.index - 1] = fieldsValue[0]
      change('answers', restoreFields)
      setFieldsDisabled(false)
    }
  }, [selectedInstitution])

  const label = index ? `${index}. ${payload?.label}` : payload?.label

  const contactMediumMessage = citizenEmail && 'correo del'

  return (
    <>
      <label
        htmlFor={`${name}.destiny_institution_id-input`}
        id={`${name}.destiny_institution_id-label`}
        className="block font-bold mb-1 min-w-25"
      >
        {label}
      </label>
      <div className="flex flex-col md:flex-row">
        <AutocompleteField
          name={`${name}.destiny_institution_id`}
          hint={payload?.hint}
          options={options || []}
          disabled={disabled}
          validate={required && validations.required()}
          className="md:mr-4 flex-grow"
          emptyLabel="Selecciona una entidad"
          size="2xl"
        />
        {selectedInstitution?.has_branch_offices &&
          selectedInstitution?.enabled_procedure_management &&
          selectedInstitution?.enabled_procedure_management.destiny_branch_office_type === 'all' &&
          isSuccessful(statusSelected) && (
            <SelectField
              name={`${name}.destiny_branch_office_id`}
              hint={payload?.hint}
              options={dataToOptions(branchOffices)}
              disabled={disabled}
              validate={required && validations.required()}
              containerClassName="flex-grow"
              onChange={handleBranchChange}
              emptyLabel="Selecciona una sede"
              size="2xl"
            />
          )}
      </div>
      <Field component="input" className="hidden" name={`${name}.enabled_procedure_managements_id`} />
      <Field
        component="input"
        className="hidden"
        name={`${name}.origin_institution_id`}
        initialValue={user.institution_id}
      />
      <WhenFieldChanges field={`${name}.destiny_institution_id`} set={`${name}.destiny_branch_office_id`} to={null} />
      <WhenFieldChanges field={`${name}.destiny_institution_id`} set={`${name}.citizen_message`} to={null} />
      <WhenFieldChanges
        field={`${name}.destiny_institution_id`}
        set="special_task_data.destiny_institution_id"
        to={institutionId}
      />
      <WhenFieldChanges
        field={`${name}.destiny_branch_office_id`}
        set="special_task_data.destiny_branch_office_id"
        to={branchOfficeId}
      />
      {institutionId && !selectedInstitution?.enabled_procedure_management?.id && isSuccessful(statusSelected) && (
        <div className="p-6 mb-6 rounded-lg bg-yellow-400">
          <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 text-gray-800">
            <InfoIcon />
            <p className="flex-grow">
              <span>
                No es posible derivar la solicitud a través de Plataforma de Transparencia y Acceso a Información
                Pública en Facilita debido a que{' '}
              </span>{' '}
              <strong>{selectedInstitution?.name} no lo/la tiene habilitado/a.</strong>
            </p>
          </div>
          <div className="pt-6 mt-6 border-t border-solid border-yellow-800">
            <p className="font-bold text-gray-800">Pasos a seguir:</p>
            <ol className="list-decimal ml-4">
              <li className="my-4">
                En caso el trámite lo requiera, realiza la derivación a través de la Mesa de Partes de la entidad.
              </li>
              <li className="my-4">Archiva la solicitud y envíale un mensaje al {contactMediumMessage} ciudadano:</li>
            </ol>
            <div>
              <TextField
                name={`${name}.citizen_message`}
                ariaLabel="Escribe un mensaje para el ciudadano..."
                placeholder="Escribe un mensaje para el ciudadano..."
                size="full"
                element="textarea"
                maxLength={600}
                rows={5}
                validate={validations.required()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default InstitutionToReferCitizenField

InstitutionToReferCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
