import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SizeOptionsImage from './SizeOptionsImage'

const ImageComponent = ({ config, block, editorState }) => {
  const contentState = config.getEditorState().getCurrentContent()
  const entity = contentState.getEntity(block.getEntityAt(0))
  const { src, width, alt } = entity.getData()
  const [hovered, setHovered] = useState(false)
  const [dimensions, setDimensions] = useState(width)

  const handleChangeDimensions = async value => {
    await contentState.replaceEntityData(block.getEntityAt(0), {
      src,
      width: `${parseInt(value)}px`,
      alt
    })
    setDimensions(value)
    editorState.current.editor.blur()
    editorState.current.editor.focus()
  }
  return (
    <div className="flex" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <div className="flex justify-center relative">
        {entity?.type === 'IMAGE' ? (
          <>
            <img src={src} alt={alt} style={{ width: dimensions }} />
            {hovered && <SizeOptionsImage onChangeDimensions={handleChangeDimensions} dimensions={dimensions} />}
          </>
        ) : (
          <iframe
            src={src}
            frameBorder="2"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full md:w-450px h-full md:h-450px"
            title="injected-image"
          />
        )}
      </div>
    </div>
  )
}

const BlockRenderFunction = (block, config, editorState) => {
  if (block.getType() === 'atomic') {
    return {
      component: () => <ImageComponent config={config} block={block} editorState={editorState} />,
      editable: false
    }
  }
  return undefined
}

export default BlockRenderFunction

ImageComponent.propTypes = {
  config: PropTypes.object,
  block: PropTypes.object,
  editorState: PropTypes.object
}
