import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import { Button } from '../../atoms/Button'
import { Close } from '@material-ui/icons'
import useTasks from '../../../hooks/useTasks'
import { ProcedureManagementContext } from '../../../utils/contexts'
import useProcedureManagement from '../../../hooks/useProcedureManagement'

const TaskByUserTypeModal = ({ userType, onClose }) => {
  const procedureManagementId = useContext(ProcedureManagementContext)
  const { data: procedureManagement, status: statusProcedureManagement } = useProcedureManagement(procedureManagementId)
  const { data: tasks, status: statusTasks } = useTasks(procedureManagementId, userType.id)
  if (statusProcedureManagement !== 'success' || statusTasks !== 'success') return null
  return (
    <ModalWithAnimation size="l" open>
      <div className="text-right">
        <Button type="button" variant="text" size="xxs" className="font-bold" endIcon={<Close />} onClick={onClose}>
          Cerrar
        </Button>
      </div>
      <div className="w-full">
        <div className="header font-bold text-xl mb-2">{userType.name}</div>
        <div className="body overflow-y-auto max-h-96 md:max-h-168">
          <div className="mb-6">
            Estas son las tareas que podrá realizar este tipo de usuario para el trámite de {procedureManagement.name}:
          </div>
          <div className="grid md:grid-cols-3 gap-4 lg:px-20">
            {tasks.map((task, index) => (
              <div
                key={index}
                className="flex text-center items-center justify-center bg-indigo-700 text-white font-bold rounded h-16 px-4"
              >
                {task.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalWithAnimation>
  )
}
export default TaskByUserTypeModal

TaskByUserTypeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  userType: PropTypes.object
}
