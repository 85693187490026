import React, { useState } from 'react'
import PageHeading from '../../../molecules/PageHeading'
import StatCardsWrapper from '../../../organisms/statistics/StatCardsWrapper'
import ChartSection from '../../../organisms/statistics/ChartSection'
import DownloadableResourcesSection from '../../../organisms/statistics/DownloadableResourcesSection'
import useStatData from '../../../../hooks/useStatData'
import { Tab, TabContext, TabList, TabPanel } from '../../../atoms/tabs'
import GeneralFilters from '../../../organisms/statistics/filters/GeneralFilters'

const tabStyle = { selected: 'bg-white text-blue-700 font-bold' }

const Statistics = () => {
  const [tabSelected, setTabSelected] = useState('generalTab')
  const { selectedIndicator, changeIndicator } = useStatData()

  const handleTabChange = (_event, newValue) => {
    setTabSelected(newValue)
  }

  const handleShowMoreDetail = indicator => {
    changeIndicator(indicator)
    setTabSelected('indicatorsTab')
  }

  return (
    <>
      <PageHeading title="Estadísticas" pageDescription="Conoce los principales indicadores de uso de la plataforma." />
      <TabContext value={tabSelected}>
        <GeneralFilters />
        <div className="mb-6 border-3">
          <div className="w-full bg-neutral-100">
            <TabList onChange={handleTabChange} className="bg-neutral-100 max-w-lg">
              <Tab label="Resumen general" value="generalTab" classes={tabStyle} />
              <Tab label="Indicadores" value="indicatorsTab" classes={tabStyle} />
              <Tab label="Descargas" value="downloadTab" classes={tabStyle} />
            </TabList>
          </div>
          <TabPanel value="generalTab">
            <StatCardsWrapper onShowMoreDetail={handleShowMoreDetail} />
          </TabPanel>
          <TabPanel value="indicatorsTab">
            <ChartSection selectedIndicator={selectedIndicator} changeIndicator={handleShowMoreDetail} />
          </TabPanel>
          <TabPanel value="downloadTab">
            <DownloadableResourcesSection />
          </TabPanel>
        </div>
      </TabContext>
    </>
  )
}
export default Statistics
