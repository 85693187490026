import PropTypes from 'prop-types'
import React from 'react'

const TimelineStateMessage = ({ label, message }) => {
  return (
    <p className="break-words mt-1 mb-6">
      <span className="font-bold">{label}</span>
      <br />
      {message}
    </p>
  )
}

export default TimelineStateMessage

TimelineStateMessage.propTypes = {
  label: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}
