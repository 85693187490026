import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from '../../molecules/Breadcrumb'
import MenuList from './MenuList'

export const PageNavigation = ({ breadcrumbData, breadcrumbClassName }) => {
  return (
    <>
      <MenuList />
      <Breadcrumb data={breadcrumbData} className={breadcrumbClassName} />
    </>
  )
}

PageNavigation.propTypes = {
  breadcrumbData: PropTypes.object,
  breadcrumbClassName: PropTypes.string
}
