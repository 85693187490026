import React from 'react'
import ErrorAccessPage from '../../templates/ErrorAccessPage'
import CitizenHeader from '../../organisms/question_fields/CitizenHeader'

const CitizenNotFound = () => {
  return (
    <>
      <CitizenHeader name="Facilita Perú" />
      <ErrorAccessPage
        importantText="Lo sentimos, la página no existe."
        description="Puede que hayas escrito mal la dirección, que la hayan cambiado o eliminado."
      />
    </>
  )
}

export default CitizenNotFound
