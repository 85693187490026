import PropTypes from 'prop-types'
import React from 'react'
import { ExampleModal } from '../../molecules/dialogs/ExampleModal'
import userExamples from '../../../images/enabled_procedure_managements/user_examples.png'
import userExamplesMobile from '../../../images/enabled_procedure_managements/user_examples_mobile.png'

const UserExamplesModal = ({ onClose }) => {
  return (
    <ExampleModal
      onClose={onClose}
      show
      desktopImage={userExamples}
      mobileImage={userExamplesMobile}
      title="Cuentas de los usuarios involucrados"
    >
      Son las personas dentro de tu institución que están involucradas en la gestión del trámite. Agrega las cuentas si
      ya las has creado previamente o creálas. Por ejemplo: para un trámite de Denuncias, en la Oficina de Integridad
      Institucional hay dos personas que se encargarán de gestionar dicho trámite:
    </ExampleModal>
  )
}
export default UserExamplesModal

UserExamplesModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
