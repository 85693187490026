import PropTypes from 'prop-types'
import React from 'react'

export const TextButton = ({ children, onClick }) => (
  <button type="button" onClick={onClick} className="font-bold text-blue-700 leading-none">
    {children}
  </button>
)

TextButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
}
