import PropTypes from 'prop-types'
import React from 'react'
import CheckboxField from './CheckboxField'
import { useField } from 'react-final-form'
import Hint from '../../atoms/Hint'
import Error from '../Error'

const CheckboxDoubleField = ({ label, name, hint, validate, cols = [], rows = [], disabled = false }) => {
  useField(name, { validate })
  return (
    <>
      <legend className="font-bold mb-2 sticky left-0 w-legend md:w-full">{label}</legend>
      {hint && <Hint hint={hint} name={name} className="sticky left-0 w-legend md:w-full" />}
      <table className="w-full">
        <tbody>
          <tr>
            <td className="bg-neutral-200 sticky left-0 w-1/2"></td>
            {cols.map(col => (
              <td className="text-center" key={col?.label}>
                {col?.label}
              </td>
            ))}
          </tr>
          {rows.map((row, indexRow) => (
            <tr key={row?.label}>
              <td className="sticky left-0 z-10 w-1/2">
                <div className="bg-neutral-200 md:pl-4 my-1 -mr-0.5 flex items-center">{row?.label}</div>
              </td>
              {cols.map((col, indexCol) => (
                <td key={`${row?.label}_${col?.label}`}>
                  <div className="bg-neutral-200 h-11 my-1 -mr-0.5 flex items-center justify-center">
                    <CheckboxField
                      name={name}
                      margin="none"
                      className="ml-2"
                      value={`${indexRow}_${indexCol}_${col?.label}`}
                      disabled={disabled}
                      inputProps={{ 'aria-label': `Fila ${row?.label}, Columna ${col?.label}` }}
                      hideError
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Error className="relative -bottom-0" name={name} />
    </>
  )
}

export default CheckboxDoubleField

CheckboxDoubleField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  hint: PropTypes.string,
  validate: PropTypes.func,
  cols: PropTypes.array,
  rows: PropTypes.array,
  disabled: PropTypes.bool
}
