import PropTypes from 'prop-types'
import React from 'react'
import TranslateIcon from '../../../images/icons/translate.svg'
import IconButton from './IconButton'
import { ReactSVG as Svg } from 'react-svg'

const TranslateButton = ({ to }) => {
  return (
    <IconButton text="Administrar traducciones" component="Link" to={to}>
      <Svg src={TranslateIcon} />
    </IconButton>
  )
}

TranslateButton.propTypes = {
  to: PropTypes.string.isRequired
}

export default TranslateButton
