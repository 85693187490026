import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

const getUrlParameter = name => {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.href)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

const languageFromUrl = getUrlParameter('locale') || 'es'

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: languageFromUrl,
    fallbackLng: 'es',
    ns: ['form_field_options', 'general', 'button'],
    defaultNS: 'all',
    debug: false,
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/v1/translations.json?locale={{lng}}&category={{ns}}'
    }
  })
  .then(() => {})
