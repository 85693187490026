import React from 'react'
import PropTypes from 'prop-types'
import { ReactSVG as Svg } from 'react-svg'
import MenuIcon from '../../../images/icons/hamburger_menu.svg'

const MenuButton = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      <Svg src={MenuIcon} className="text-red-800" />
    </button>
  )
}

export default MenuButton

MenuButton.propTypes = {
  onClick: PropTypes.func
}
