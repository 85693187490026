const determineMainRole = roles => {
  if (roles.some(role => role.code === 'admin')) {
    return 'admin'
  } else if (roles.some(role => role.code === 'coordinator')) {
    return 'coordinator'
  } else if (roles.some(role => role.code === 'branch_coordinator')) {
    return 'branch_coordinator'
  } else if (roles.some(role => role.code === 'collaborator')) {
    return 'collaborator'
  }
  return null
}
export default determineMainRole
