import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import DetailGeneralInfo from '../../../organisms/procedures/DetailGeneralInfo'
import TaskHistory from '../../../organisms/procedures/TaskHistory'
import DetailSelectTaskExecute from '../../../organisms/procedures/DetailSelectTaskExecute'
import DetailTaskExecutionsForm from '../../../organisms/procedures/DetailTaskExecutionsForm'
import useSearchParams from '../../../../hooks/useSearchParams'
import useProcedure from '../../../../hooks/useProcedure'
import useProcedureManagementAccess from '../../../../hooks/useProcedureManagementAccess'
import useTaskExecutions from '../../../../hooks/useTaskExecutions'
import { isSuccessful, isUnauthorized } from '../../../../hooks/useQuery'
import ErrorAccessPage from '../../../templates/ErrorAccessPage'

const ProceduresDetail = () => {
  const { procedure_id: id, enabled_procedure_management_id: enabledProcedureManagementId } = useParams()
  const searchParams = useSearchParams()
  const accessId = searchParams.get('access_id')
  const { currentAccess, error } = useProcedureManagementAccess(enabledProcedureManagementId, accessId)
  const [selectedLeafId, setSelectedLeafId] = useState()
  const { findById, invalidateProcedureQueries } = useProcedure({ id })
  const { status, data: procedure } = findById(
    ['procedure', id, selectedLeafId, accessId],
    { access_id: currentAccess?.id || accessId, leaf_id: selectedLeafId },
    { refetchOnWindowFocus: true }
  )
  const [selectedTaskId, setSelectedTaskId] = useState('')
  const { deleteTaskExecution } = useTaskExecutions(id, currentAccess?.id)
  const handleDeleteTaskExecution = async id => {
    await deleteTaskExecution(id, {
      onSuccess: () => invalidateProcedureQueries(),
      onError: () => alert('Ocurrió un error al intentar guardar.')
    })
  }

  if (isUnauthorized(error)) {
    return (
      <ErrorAccessPage
        importantText="Lo sentimos, pero no tienes acceso para visualizar este contenido"
        description="Si deseas, puedes consultar otros contenidos desde el menú principal o regresar a la página de inicio"
        textButton="Ir a la página de inicio"
        linkButton="/formularios?action=access_error"
      />
    )
  }
  if (!isSuccessful(status)) return null

  const contactMedium = (procedure.citizen_email && 'correo') || (procedure.citizen_phone && 'SMS') || ''
  return (
    <>
      <PageNavigation
        breadcrumbData={{ enabled_procedure_management_id: enabledProcedureManagementId, name: procedure.name }}
      />
      <Container className="py-12">
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="w-full md:w-5/12 lg:w-3/12">
            <DetailGeneralInfo procedure={procedure} />
          </div>
          <div className="w-full md:w-7/12 lg:w-9/12">
            <TaskHistory
              taskExecutions={procedure.task_executions}
              taskRuleExecutions={procedure.task_rule_executions}
              selectedLeafId={selectedLeafId}
              setSelectedLeafId={setSelectedLeafId}
              onReverseTask={handleDeleteTaskExecution}
              onUpdatePaymentState={() => invalidateProcedureQueries()}
              contactMedium={contactMedium}
            />
            {procedure.enabled_tasks.length > 0 && (
              <DetailSelectTaskExecute
                enabledTasks={procedure.enabled_tasks}
                value={selectedTaskId}
                onSelect={setSelectedTaskId}
              />
            )}
          </div>
        </div>
        {selectedTaskId !== '' && (
          <DetailTaskExecutionsForm
            key={selectedTaskId}
            procedure={procedure}
            taskId={selectedTaskId}
            accessId={currentAccess?.id}
            onAfterSave={() => {
              setSelectedTaskId('')
              invalidateProcedureQueries()
            }}
          />
        )}
      </Container>
    </>
  )
}
export default ProceduresDetail
