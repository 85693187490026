import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import QuestionOptionsSection from './QuestionOptionsSection'
import SelectField from '../../molecules/fields/SelectField'

const ParagraphField = ({ baseName, validate }) => {
  const options = Object.entries(window.sisoli.paragraphLimits).map(([value, label]) => ({ value, label }))

  return (
    <>
      <div className="flex items-baseline flex-wrap">
        <TextField
          label="Etiqueta de la pregunta"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validate}
          margin="none"
          rootClassName="flex-1 min-w-48 sm:mr-4"
          errorClassName="relative inset-0"
          initialValue=""
        />
        <SelectField
          name={`${baseName}.payload.limit`}
          validate={validate}
          label="Cantidad máxima de caracteres"
          margin="none"
          size="xl"
          options={options}
        />
      </div>
      <QuestionOptionsSection baseName={baseName} margin="none" size="full" />
    </>
  )
}

export default ParagraphField

ParagraphField.propTypes = {
  baseName: PropTypes.string,
  validate: PropTypes.func
}
