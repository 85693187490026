import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import Alert from './Alert'
import ScrollToTop from './ScrollToTop'

const ActionAlert = ({ type, status, content }) => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)

  return (
    <>
      {urlSearchParams.action === type && (
        <Alert type={status} className="mt-10">
          <ScrollToTop />
          {content}
        </Alert>
      )}
    </>
  )
}

export default ActionAlert

ActionAlert.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
  content: PropTypes.node
}
