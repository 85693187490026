import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'

const { collaborator } = roles

const Breadcrumb = () => {
  const hasRolePermissions = useHasRolePermissions()

  const urlCantAccess = ['/formularios/:id/vista-previa', '/gestiones-de-tramites-habilitadas/:id/vista-previa']
  const canAccess = (mainRoute, key) =>
    !(hasRolePermissions([collaborator]) && urlCantAccess.includes(mainRoute) && key === 2)

  return { canAccess }
}

export default Breadcrumb
