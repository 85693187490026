import React from 'react'
import { getIndexSubtype, getQuestionsPreviousSteps } from '../../../configs/questionTypesCitizenConfig'
import clsx from 'clsx'
import QuestionComponentMounter from '../question_types_citizens/QuestionComponentMounter'
import { ChildrenQuestions } from './ChildrenQuestions'
import { Field, useForm } from 'react-final-form'
import useFieldValue from '../../../hooks/useFieldValue'

const clearPreviousAnswers = (mutators, index, alternative) =>
  mutators.clear(`answers.${index}.sub_answers_attributes.${alternative}`)

const QuestionsAndSubQuestions = ({ questions, stepsAttributes, activeStep, ...props }) => {
  const { mutators } = useForm()
  const visibleIndex = useFieldValue('visible_index')
  const questionsPreviousSteps = getQuestionsPreviousSteps(stepsAttributes, activeStep)
  const subTitleFirstIndex = questions.findIndex(question => question.kind === 'subtitle')
  const itemAboveIsSubtitle = (index, question) =>
    subTitleFirstIndex !== -1 && index > subTitleFirstIndex && question.kind !== 'subtitle'
  const questionMapped = visibleIndex ? questions.filter((_el, index) => visibleIndex.includes(index + 1)) : questions

  return questionMapped.map((question, questionIndex) => {
    const indexSubType = getIndexSubtype({ questionsPreviousSteps, questions, index: questionIndex })
    const index = questionsPreviousSteps.length + questionIndex
    return (
      <div key={question.id} className={clsx(itemAboveIsSubtitle(index, question) ? 'ml-6' : '')}>
        <QuestionComponentMounter
          index={indexSubType}
          question={question}
          name={`answers.${index}.answer`}
          dispatch="Citizen"
          kind={question.kind}
          onChange={() => {
            question.has_sub_questions &&
              question.payload.alternatives.map(a => a.id).forEach(al => clearPreviousAnswers(mutators, index, al))
          }}
          margin={question.has_sub_questions ? 'none' : 'high'}
          {...props}
        />
        {question.has_sub_questions && (
          <div className="mb-8">
            <ChildrenQuestions
              baseName={`answers.${index}`}
              question={question}
              parentIndex={index}
              parentIndexSubType={indexSubType}
            />
          </div>
        )}
        <Field component="input" className="hidden" name={`answers.${index}.question_id`} defaultValue={question.id} />
      </div>
    )
  })
}
export default QuestionsAndSubQuestions
