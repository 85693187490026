import PropTypes from 'prop-types'
import React from 'react'
import SearchField from './SearchField'
import clsx from 'clsx'
import { Pagination } from './Pagination'
import SortBy from './SortBy'
import AutoSaveForm from './AutoSaveForm'

const PageBody = ({
  asideFilter,
  results = {},
  optionsOrderBy,
  children,
  onFilter,
  initialFilters,
  searchPlaceHolder,
  topRightSide,
  searchContainerClassName,
  upperFilter
}) => {
  return (
    <AutoSaveForm onSubmit={onFilter} initialValues={initialFilters}>
      {upperFilter}
      <div className={clsx(asideFilter && 'md:flex gap-6')}>
        <div className={clsx('md:bg-neutral-100 md:pt-6 px-6', asideFilter && 'md:w-1/4')}>{asideFilter}</div>
        <div className={clsx('bg-neutral-100 pt-6 px-6 pb-4', asideFilter && 'md:w-3/4')}>
          <div className="grid justify-items-start md:flex md:justify-between items-center">
            <SearchField placeholder={searchPlaceHolder} containerClassName={searchContainerClassName} />
            {topRightSide}
          </div>
          <hr className={clsx('h-2px bg-gray-350 ', topRightSide ? 'mt-2' : 'mt-8')} />
          <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6 mt-4">
            <h2 className="mb-4 md:mb-0 text-xl font-bold">Resultados encontrados ({results.total_count})</h2>
            <SortBy options={optionsOrderBy} disabled={results.total_count === 0} />
          </div>
          <div className="overflow-x-auto">{children}</div>
          <div className="flex justify-center">
            {results.total_count > 0 && results.total_pages && <Pagination numPages={results.total_pages} />}
          </div>
        </div>
      </div>
    </AutoSaveForm>
  )
}

export default PageBody

PageBody.propTypes = {
  asideFilter: PropTypes.element,
  children: PropTypes.node,
  initialFilters: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
  optionsOrderBy: PropTypes.array,
  results: PropTypes.object,
  searchContainerClassName: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  topRightSide: PropTypes.element,
  upperFilter: PropTypes.oneOfType([PropTypes.element, PropTypes.bool])
}
