import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import clsx from 'clsx'
import { Button } from '../../atoms/Button'
import { Close } from '@material-ui/icons'
import LoadAlternatives from '../../../images/load_alternatives.png'
import FileField from '../../molecules/fields/FileField'
import { LinearProgressBar } from '../../atoms/LinearProgressBar'
import * as uuid from 'uuid'
import useFieldValue from '../../../hooks/useFieldValue'
import { useForm } from 'react-final-form'
import { readFile, sortArray } from '../../../utils/excelUtils'

const extensions = ['xlsx', 'csv', 'tsc']

const ModalUploadAlternatives = ({ baseName, setOpenModalUploadAlternatives }) => {
  const { mutators } = useForm()
  const [arrayRows, setArrayRows] = useState([])
  const [percentageUpload, setPercentageUpload] = useState(0)
  const file = useFieldValue('spreadsheet_alternatives')

  useEffect(() => {
    if (arrayRows.length > 0) {
      const array = []
      arrayRows.forEach((arrayRow, index) => {
        setPercentageUpload(Math.round(((index + 1) / arrayRows.length) * 100))
        array.push({ id: uuid.v4(), label: arrayRow[0] })
      })
      mutators.concat(`${baseName}.payload.alternatives`, array.sort(sortArray))
      setTimeout(() => {
        setOpenModalUploadAlternatives(false)
        setArrayRows([])
        mutators.clear('spreadsheet_alternatives')
      }, 1000)
    }
  }, [arrayRows])

  return (
    <Dialog
      open
      onClose={() => setOpenModalUploadAlternatives(false)}
      classes={{ paper: clsx('px-8 pt-4 pb-11', arrayRows.length !== 0 && 'rounded-lg') }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle classes={{ root: clsx('p-0', arrayRows.length !== 0 && 'mt-4 mb-6') }}>
        {arrayRows.length === 0 ? (
          <div className="text-right">
            <Button
              type="button"
              variant="text"
              size="full"
              className="font-bold mb-0 px-0 w-auto"
              endIcon={<Close />}
              onClick={() => setOpenModalUploadAlternatives(false)}
            >
              Cerrar
            </Button>
          </div>
        ) : (
          <span className="font-bold text-xl">Cargando excel</span>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: 'md:pl-3 md:p-0' }}>
        {arrayRows.length === 0 ? (
          <>
            <p className="header font-bold text-lg mb-2">Cargar alternativas en excel</p>
            <p className="mb-5">
              Si requieres agregar una gran cantidad de alternativas puedes añadirlas cargando un excel.
              <span className="font-bold"> Ejemplo:</span>
            </p>
            <img className="mb-7" src={LoadAlternatives} alt="Carga de imagen" />
            <FileField
              payload={{
                label: 'Sube tu excel',
                hint: 'Solo se aceptan formatos .xlsx, .csv, .tsv'
              }}
              name="spreadsheet_alternatives"
              extensions={extensions}
              fileBackground="bg-blue-400"
            />
          </>
        ) : (
          <div className="flex align-middle">
            <LinearProgressBar
              percentage={percentageUpload}
              classes={{ root: 'bg-neutral-400 h-6 rounded-2xl w-full mr-2 mb-5' }}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions classes={{ root: 'p-0' }}>
        {arrayRows.length === 0 ? (
          <div className="w-full justify-end flex flex-col-reverse md:flex-row">
            <Button
              variant="secondary"
              onClick={() => setOpenModalUploadAlternatives(false)}
              size="full"
              className="md:max-w-56"
            >
              Cancelar
            </Button>
            <Button
              className="md:ml-4 ml-0 mb-4 md:mb-0 text-white md:max-w-56"
              onClick={() => readFile(file, setArrayRows)}
              disabled={!file}
              size="full"
            >
              Cargar excel
            </Button>
          </div>
        ) : (
          <Button variant="text" className="text-red-800 -mb-4" size="" fullWidth={false}>
            Cancelar carga
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ModalUploadAlternatives

ModalUploadAlternatives.propTypes = {
  baseName: PropTypes.string,
  setOpenModalUploadAlternatives: PropTypes.func.isRequired
}
