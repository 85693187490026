import { FullNameField } from '../components/organisms/question_config_translations_fields/FullNameField'
import EmailField from '../components/organisms/question_config_translations_fields/EmailField'
import DocIdDocTypeField from '../components/organisms/question_config_translations_fields/DocIdDocTypeField'
import UbigeoField from '../components/organisms/question_config_translations_fields/UbigeoField'
import ParagraphField from '../components/organisms/question_config_translations_fields/ParagraphField'
import RadioField from '../components/organisms/question_config_translations_fields/RadioField'
import RadioDoubleField from '../components/organisms/question_config_translations_fields/RadioDoubleField'
import { FullNameApplicantField } from '../components/organisms/question_config_translations_fields/FullNameApplicantField'
import ShortQuestionField from '../components/organisms/question_config_translations_fields/ShortQuestionField'
import DateField from '../components/organisms/question_config_translations_fields/DateField'
import CheckboxesField from '../components/organisms/question_config_translations_fields/CheckboxesField'
import CheckboxDoubleField from '../components/organisms/question_config_translations_fields/CheckboxDoubleField'
import FileField from '../components/organisms/question_config_translations_fields/FileField'
import SubtitleQuestionFields from '../components/organisms/question_config_translations_fields/SubtitleQuestionFields'
import DropdownField from '../components/organisms/question_config_translations_fields/DropdownField'
import PhoneField from '../components/organisms/question_config_translations_fields/PhoneField'
import HourField from '../components/organisms/question_config_translations_fields/HourField'
import AddressField from '../components/organisms/question_config_translations_fields/AddressField'
import CountryField from '../components/organisms/question_config_translations_fields/CountryField'
import DocIdDocTypeApplicantField from '../components/organisms/question_config_translations_fields/DocIdDocTypeApplicantField'
import EmailApplicantField from '../components/organisms/question_config_translations_fields/EmailApplicantField'

const questionTypesTranslateConfig = {
  full_name_applicant: FullNameApplicantField,
  email_phone_applicant: EmailApplicantField,
  email: EmailField,
  full_name: FullNameField,
  doc_id_type_applicant: DocIdDocTypeApplicantField,
  doc_id_type: DocIdDocTypeField,
  ubigeo: UbigeoField,
  paragraph: ParagraphField,
  radio: RadioField,
  radio_double: RadioDoubleField,
  short_text: ShortQuestionField,
  date: DateField,
  checkboxes: CheckboxesField,
  checkboxes_double: CheckboxDoubleField,
  file: FileField,
  subtitle: SubtitleQuestionFields,
  dropdown: DropdownField,
  phone: PhoneField,
  hour: HourField,
  address: AddressField,
  country: CountryField
}

export default questionTypesTranslateConfig
