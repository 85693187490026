import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { Button } from '../../atoms/Button'
import { Form } from 'react-final-form'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import { ChildrenQuestions } from '../question_fields/ChildrenQuestions'
import { clearMutator } from '../../../utils/formMutators'
import QuestionComponentMounter from '../question_types_citizens/QuestionComponentMounter'
import { Close } from '@material-ui/icons'
import Tooltip from '../../atoms/Tooltip'

const previewVisibilityConditions = question => [
  { questions: ['email_phone_applicant'], condition: !question?.payload?.contact_medium },
  { questions: ['doc_id_type', 'doc_id_type_applicant'], condition: !question?.payload?.person_type }
]

const getAllChildQuestions = question => {
  const arrayQuestionsAndChildQuestions = [question]
  Object.keys(question.children_attributes || {}).forEach(key => {
    if (Array.isArray(question.children_attributes[key])) {
      arrayQuestionsAndChildQuestions.push(...question.children_attributes[key])
    }
  })
  return arrayQuestionsAndChildQuestions
}

const PreviewModal = ({ question, index }) => {
  const [open, setOpen] = useState(false)

  const previewVisibilityDisabled =
    getAllChildQuestions(question).filter(question =>
      previewVisibilityConditions(question).find(el => el.questions.includes(question.kind) && el.condition)
    ).length > 0

  return (
    <Fragment>
      <Tooltip title="Vista previa">
        <IconButton
          type="button"
          color="primary"
          onClick={() => setOpen(!open)}
          classes={{ root: 'py-0' }}
          disabled={previewVisibilityDisabled}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        aria-labelledby="dialog-title"
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        open={open}
        classes={{ paper: 'px-8 py-6' }}
      >
        <DialogActions classes={{ root: 'px-0 text-right' }}>
          <Button
            variant="text"
            type="button"
            fullWidth={false}
            onClick={() => setOpen(false)}
            className="font-bold text-base p-0"
            endIcon={<Close />}
          >
            Cerrar
          </Button>
        </DialogActions>
        <DialogTitle id="dialog-title" classes={{ root: 'p-0 text-left mb-6' }}>
          <span className="text-xl font-bold block">Vista previa de pregunta</span>
        </DialogTitle>
        <DialogContent classes={{ root: 'p-0 pb-4' }}>
          {open ? (
            question?.kind ? (
              <Form onSubmit={() => {}} mutators={{ clear: clearMutator }}>
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="pl-1">
                    <QuestionComponentMounter
                      index={index}
                      question={question}
                      name={`answers.${index}.answer`}
                      kind={question?.kind}
                      dispatch="Citizen"
                    />
                    {question.has_sub_questions && (
                      <ChildrenQuestions baseName={`answers.${index}`} question={question} parentIndex={index} />
                    )}
                  </form>
                )}
              </Form>
            ) : (
              'No hay información suficiente para generar vista previa'
            )
          ) : null}
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default PreviewModal

PreviewModal.propTypes = {
  index: PropTypes.number,
  question: PropTypes.object.isRequired
}
