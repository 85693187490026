import React from 'react'

const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" aria-hidden="true">
    <g>
      <g>
        <path
          fill="#fff"
          d="M28.098 13.793C28.098 6.261 21.993.155 14.46.155 6.928.155.822 6.261.822 13.793c0 6.807 4.987 12.45 11.507 13.472v-9.53H8.867v-3.942h3.462V10.79c0-3.419 2.037-5.307 5.152-5.307 1.492 0 3.053.267 3.053.267v3.356h-1.72c-1.694 0-2.223 1.05-2.223 2.13v2.558h3.783l-.605 3.942H16.59v9.53c6.52-1.022 11.507-6.665 11.507-13.472z"
        ></path>
      </g>
    </g>
  </svg>
)

export default FacebookIcon
