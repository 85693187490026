import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  borderSpacingYSm: { borderSpacing: '0 0.5rem' },
  borderSpacingYNormal: { borderSpacing: '0 1rem' }
}))

const Table = ({ className, head, body, spacing = 'sm', values, notValues, ...props }) => {
  const classes = useStyles()
  const borderSpacing = {
    sm: classes.borderSpacingYSm,
    normal: classes.borderSpacingYNormal
  }

  return (
    <table className={clsx('border-separate', borderSpacing[spacing], className)} {...props}>
      {values?.length > 0 ? (
        <>
          {head && <thead className="text-left">{head}</thead>}
          {body && <tbody>{body}</tbody>}
        </>
      ) : (
        <tbody>{notValues}</tbody>
      )}
    </table>
  )
}

export default Table

Table.propTypes = {
  head: PropTypes.element,
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  values: PropTypes.arrayOf(PropTypes.object),
  notValues: PropTypes.node,
  className: PropTypes.string,
  spacing: PropTypes.string
}
