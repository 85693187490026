import PropTypes from 'prop-types'
import React from 'react'
import Toast from '../atoms/Toast'
import Alert from './Alert'

const ToastAlert = ({ open, alertText, onClose, type }) => {
  return (
    <Toast open={Boolean(open)} onClose={onClose} autoHide>
      <Alert type={type} className="text-sm p-6" onClose={onClose} showClose showTextClose showIconClose={false}>
        {alertText}
      </Alert>
    </Toast>
  )
}

export default ToastAlert

ToastAlert.propTypes = {
  open: PropTypes.bool,
  alertText: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string
}
