import React from 'react'
import { roles as rolList } from './permissionsConfig'
import { roles } from './usersConfig'

const informationText = {
  linkTitle: 'Publicación',
  linkHint: (
    <>
      Una vez publicado el artículo, los funcionarios podrán verlo desde la sección{' '}
      <span className="font-bold">Centro de Ayuda</span>.
    </>
  ),
  previewTitle: 'Este es el artículo que verán los funcionarios:'
}

const articleSteps = [{ label: 'Redacta el contenido' }, { label: 'Vista previa y publicación' }]

const checkboxPermissions = () => roles.reverse().filter(el => el.code !== rolList.admin)

export { articleSteps, informationText, checkboxPermissions }
