import React from 'react'
import Feedbacks from '../components/pages/admin/feedbacks/Feedbacks'
import Templates from '../components/pages/admin/templates/Templates'
import PaymentMethodsConfiguration from '../components/pages/admin/PaymentMethodsConfiguration'
import QuickSearch from '../components/pages/admin/quick_searches/QuickSearch'
import HelpCenter from '../components/pages/admin/onboardings/HelpCenter'
import Help from '../components/pages/admin/onboardings/Help'
import { AreasAndUsers } from '../components/pages/admin/areas_users/AreasAndUsers'
import EnabledProcedureManagements from '../components/pages/admin/procedures_managements/EnabledProcedureManagements'
import ProcedureManagements from '../components/pages/admin/procedures_managements/ProcedureManagements'
import { Notifications } from '../components/pages/admin/onboardings/Notifications'
import Forms from '../components/pages/admin/forms/Forms'
import BranchOffices from '../components/pages/admin/BranchOffices'
import Institutions from '../components/pages/admin/institutions/Institutions'
import Terms from '../components/pages/admin/terms_translations/Terms'
import Statistics from '../components/pages/admin/statistics/Statistics'
import { Announcements } from '../components/pages/admin/announcements/Announcements'

const getTabsTree = ({
  canSeeTemplates,
  canSeeProcedureManagements,
  canSeeEnabledProcedureManagements,
  canSeePaymentMethods,
  canSeeAreasAndUsers,
  canManageHelpCenter,
  canSeeNotifications,
  canSeeBranchOffices,
  canSeeAllEnabledProcedureManagements,
  canSeeInstitutionInfo,
  canSeeTerms,
  canSeeStatistics,
  canSeeAnnouncements
}) => {
  return [
    { name: 'Formularios', path: '/formularios', component: <Forms />, visible: true },
    { name: 'Consultas rápidas', path: '/consultas-rapidas', component: <QuickSearch />, visible: true },
    {
      name: 'Gestión de trámites',
      path: '',
      component: null,
      subMenu: [
        {
          name: 'Trámites creados',
          path: '/gestiones-de-tramites',
          component: <ProcedureManagements />,
          visible: canSeeProcedureManagements,
          isChildTab: true
        },
        {
          name: 'Trámites habilitados',
          path: '/gestiones-de-tramites-habilitadas',
          component: <EnabledProcedureManagements />,
          visible: canSeeAllEnabledProcedureManagements,
          isChildTab: true
        }
      ]
    },
    {
      name: 'Gestión de trámites',
      path: '/gestiones-de-tramites-habilitadas',
      component: <EnabledProcedureManagements />,
      visible: canSeeEnabledProcedureManagements
    },
    {
      name: 'Configuración',
      path: '',
      component: null,
      subMenu: [
        {
          path: '/areas-y-usuarios',
          relatedPaths: ['/areas', '/usuarios'],
          name: 'Áreas y usuarios',
          component: <AreasAndUsers />,
          visible: canSeeAreasAndUsers,
          isChildTab: true
        },
        {
          path: '/estadisticas',
          name: 'Estadísticas',
          component: <Statistics />,
          visible: canSeeStatistics,
          isChildTab: true
        },
        {
          path: '/feedbacks',
          name: 'Feedback',
          component: <Feedbacks />,
          visible: true,
          isChildTab: true
        },
        {
          path: '/medios-de-pago',
          name: 'Medios de pago',
          component: <PaymentMethodsConfiguration />,
          visible: canSeePaymentMethods,
          isChildTab: true
        },
        {
          path: '/sedes',
          name: 'Sedes',
          component: <BranchOffices />,
          visible: canSeeBranchOffices,
          isChildTab: true
        }
      ]
    },
    {
      name: 'Soporte',
      path: '',
      component: null,
      subMenu: [
        {
          path: '/avisos',
          name: 'Avisos',
          component: <Announcements />,
          visible: canSeeAnnouncements,
          isChildTab: true
        },
        {
          path: '/entidades',
          name: 'Entidades',
          component: <Institutions />,
          visible: canSeeInstitutionInfo,
          isChildTab: true
        },
        {
          path: '/notificaciones',
          name: 'Notificaciones',
          component: <Notifications />,
          visible: canSeeNotifications,
          isChildTab: true
        },
        {
          path: '/plantillas',
          name: 'Plantillas',
          component: <Templates />,
          visible: canSeeTemplates,
          isChildTab: true
        },
        {
          path: '/traducciones',
          name: 'Traducciones',
          component: <Terms />,
          visible: canSeeTerms,
          isChildTab: true
        }
      ]
    },
    {
      name: 'Ayuda',
      path: '',
      component: null,
      subMenu: [
        {
          path: '/centro-de-ayuda',
          name: 'Centro de ayuda',
          component: canManageHelpCenter ? <HelpCenter /> : <Help />,
          visible: true,
          isChildTab: true
        }
      ]
    }
  ]
}
const flatTabsTreeAndFilterVisible = tabs => {
  let allTabs = [...tabs]
  tabs.forEach(tab => {
    if (tab.subMenu?.length) {
      allTabs = [...allTabs, ...tab.subMenu]
    }
  })
  return allTabs.filter(tab =>
    isParentTab(tab) ? tab.subMenu.filter(subTab => subTab.visible).length > 0 : tab.visible
  )
}

const getSubTabsSelectedAndVisible = (tabsTree, parentTabSel) =>
  tabsTree.find(menu => menu.name === parentTabSel)?.subMenu.filter(subTab => subTab.visible)

const isParentTab = tab => Boolean(tab.subMenu?.length)
const hasChildTabSelected = (tabs, pathname) => tabs.subMenu?.find(child => child.path === pathname)
const isLastParentTab = (index, tabs) => index + 1 === tabs.filter(tab => !tab.isChildTab).length

export {
  getTabsTree,
  flatTabsTreeAndFilterVisible,
  getSubTabsSelectedAndVisible,
  isParentTab,
  hasChildTabSelected,
  isLastParentTab
}
