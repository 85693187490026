import PropTypes from 'prop-types'
import React from 'react'
import TaskExecutionsForm from './TaskExecutionsForm'
import useTask from '../../../hooks/useTask'
import useTaskExecutions from '../../../hooks/useTaskExecutions'

const DetailTaskExecutionsForm = ({ procedure, taskId, accessId, onAfterSave }) => {
  const { createTaskExecution } = useTaskExecutions(procedure.id, accessId)
  const onSubmit = async values => await createTaskExecution({ ...values, task_id: taskId }, { onSuccess: onAfterSave })

  const { data: task, status } = useTask(taskId)
  if (status !== 'success') return null
  const form = { ...task.form, payment_methods: procedure.payment_methods }
  return (
    <TaskExecutionsForm
      form={form}
      onSubmit={onSubmit}
      kind={task.kind}
      destinyUserTypeId={task.destiny_user_type_id}
      citizenEmail={procedure.citizen_email}
    />
  )
}
export default DetailTaskExecutionsForm

DetailTaskExecutionsForm.propTypes = {
  accessId: PropTypes.number,
  onAfterSave: PropTypes.func,
  procedure: PropTypes.object.isRequired,
  taskId: PropTypes.number
}
