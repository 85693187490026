import xlsx from 'xlsx'

const requiredCols = [0]
let emptyCellErrors = []
let letterWithoutResultErrors = []
let incorrectDateFormatErrors = []
let totalErrorsCount = 0

const alphabet = () => {
  const alpha = Array.from(Array(27)).map((e, i) => i + 65)
  return alpha.map(x => String.fromCharCode(x))
}

const getSpreadsheetRows = readerResult => {
  const spreadsheet = xlsx.read(readerResult, { raw: true, type: 'binary' })
  const worksheet = spreadsheet.Sheets[spreadsheet.SheetNames[0]]
  return xlsx.utils.sheet_to_json(worksheet, { header: 1, raw: false })
}

const partialAlphabet = length => {
  const alpha = Array.from(Array(length - 1)).map((e, i) => i + 65)
  return alpha.map(x => String.fromCharCode(x))
}

const emptyCellInRequiredColEvaluate = (arrayRow, index) => {
  const searchEmptyIndex = arrayRow.findIndex(valueCell => valueCell === undefined || valueCell === null)
  const findEmptyCells = requiredCols.findIndex(index => index === searchEmptyIndex)
  if (findEmptyCells >= 0) emptyCellErrors.push(`${alphabet()[searchEmptyIndex]}${index + 1}`)
}

const letterWithoutResultEvaluate = (lengthResults, field, index, resultColumn) => {
  const findLetter = partialAlphabet(lengthResults).find(letter => letter === field)
  if (!findLetter) letterWithoutResultErrors.push(`${alphabet()[resultColumn]}${index + 1}`)
}

const incorrectDateFormatEvaluate = (field, index) => {
  const regex = /^(0[0-9]|[1-9]|[1-2][0-9]|3[0-1])(\/)(0[0-9]|[1-9]|1[0-2])\2(\d{2}|\d{4})$/ // format DD/MM/AA
  if (!regex.test(field)) incorrectDateFormatErrors.push(`D${index + 1}`)
}

const evaluateSpreadsheetRows = (spreadsheetRows, formValues, resultsColumn) => {
  spreadsheetRows.forEach((row, index) => {
    const isFirstAndIsEmpty = index === 0
    if (isFirstAndIsEmpty) {
      emptyCellErrors = []
      letterWithoutResultErrors = []
      incorrectDateFormatErrors = []
      totalErrorsCount = 0
    } else if (row.length > 0) {
      letterWithoutResultEvaluate(formValues.results.length, row[resultsColumn], index, resultsColumn)
      emptyCellInRequiredColEvaluate(row, index)
      if (formValues.validation_type === 'birth_date') incorrectDateFormatEvaluate(row[3], index)
    }
  })
  totalErrorsCount = emptyCellErrors.length + letterWithoutResultErrors.length + incorrectDateFormatErrors.length
}

const SpreadsheetValidation = async values => {
  if (!values.spreadsheet.url) {
    const resultsColumn = values.validation_type === 'none' ? 3 : 4
    requiredCols.push(resultsColumn)
    const spreadsheetHasError = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsBinaryString(values.spreadsheet)
      reader.onload = () => evaluateSpreadsheetRows(getSpreadsheetRows(reader.result), values, resultsColumn)
      reader.onloadend = () => resolve(totalErrorsCount > 0)
      reader.onerror = () => {
        reject(new Error('No se pudo leer el archivo Excel'))
      }
    })
    if (spreadsheetHasError)
      return {
        spreadsheet: `?totalErrors=${totalErrorsCount}&emptyCellErrors=${emptyCellErrors
          .slice(0, 49)
          .join(', ')}&totalEmptyCellErrors=${emptyCellErrors.length}&resultLetterErrors=${letterWithoutResultErrors
          .slice(0, 49)
          .join(', ')}&totalResultLetterErrors=${
          letterWithoutResultErrors.length
        }&dateFormatErrors=${incorrectDateFormatErrors.slice(0, 49).join(', ')}&totalDateFormatErrors=${
          incorrectDateFormatErrors.length
        }`
      }
    else return 'success'
  } else return 'success'
}

export default SpreadsheetValidation
