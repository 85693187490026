import React from 'react'
import { FormSpy } from 'react-final-form'
import equal from 'deep-equal'
import diff from 'object-diff'

class AutoSave extends React.Component {
  constructor(props) {
    super(props)
    this.state = { values: props.values, submitting: false }
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(this.save, this.props.debounce)
  }

  save = async () => {
    if (this.promise) {
      await this.promise
    }
    const { values, save, valid } = this.props

    const equals = equal(this.state.values, values)
    if (!equals && valid) {
      // values have changed
      const keysToRestartObj = this.getKeysToClear(this.state.values, values)
      const valueChanged = diff(this.state.values, { ...values, ...keysToRestartObj })
      this.setState({ submitting: true, values })
      this.promise = save(valueChanged)
      await this.promise
      delete this.promise
      this.setState({ submitting: false })
    }
  }

  getKeysToClear(previous, current) {
    const previousKeys = Object.keys(previous)
    const currentKeys = Object.keys(current)
    const keysToRestart = previousKeys.filter(key => !currentKeys.includes(key))
    const keysToReset = currentKeys.filter(key => current[key] === null)
    const keysToRestartObj = {}
    for (const key of keysToRestart) {
      keysToRestartObj[key] = ''
    }
    for (const key of keysToReset) {
      keysToRestartObj[key] = ''
    }
    return keysToRestartObj
  }

  render() {
    // This component doesn't have to render anything, but it can render
    // submitting state.
    return null
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
export default props => <FormSpy {...props} subscription={{ values: true, valid: true }} component={AutoSave} />
