import PropTypes from 'prop-types'
import React from 'react'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'

const minsToHour = min => String(parseInt(min / 60)).padStart(2, '0') + ':' + String(min % 60).padEnd(2, '0')
const options = [...Array(48).keys()]
  .map(o => o * 30)
  .map(minsToHour)
  .map(o => ({ value: o, label: o }))
const HourCitizenField = ({ name, index, payload, required, disabled = false, margin }) => {
  const translationText = useTextValidation()
  return (
    <SelectField
      name={name}
      label={`${index}. ${payload?.label}`}
      hint={payload?.hint}
      options={options}
      disabled={disabled}
      validate={required && validations.required(translationText.required)}
      margin={margin}
      size="2xl"
      emptyLabel="Selecciona una hora"
    />
  )
}

export default HourCitizenField

HourCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
