import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'

const FormTypeFilter = () => {
  return (
    <>
      <label htmlFor="sort-input" className="font-bold mr-4">
        Clase de formulario
      </label>
      <CheckboxField name="form_type" label="Simple" value="simple" margin="none" />
      <CheckboxField name="form_type" label="Plantilla" value="from_template" margin="none" />
    </>
  )
}

export default FormTypeFilter
