import PropTypes from 'prop-types'
import React from 'react'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'

const ReplyAll = ({ className }) => <ReplyAllIcon className={className} />

export default ReplyAll

ReplyAll.propTypes = {
  className: PropTypes.string
}
