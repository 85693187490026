import React from 'react'
import PropTypes from 'prop-types'
import RadioDoubleCitizenField from '../question_fields/RadioDoubleCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin, onChange } = props
  return (
    <RadioDoubleCitizenField
      index={index}
      {...question}
      maxOneCol={question.max_one_col}
      requiredOneRow={question.required_one_row}
      name={name}
      disabled={disabled}
      margin={margin}
      onChange={onChange}
    />
  )
}

const RadioDoubleQuestion = {
  Citizen,
  name: 'RadioDoubleQuestion'
}

export default RadioDoubleQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  name: PropTypes.string,
  question: PropTypes.object,
  margin: PropTypes.string,
  onChange: PropTypes.func
}
