import PropTypes from 'prop-types'
import React from 'react'
import InfoIcon from '@material-ui/icons/Info'

const Info = ({ className }) => <InfoIcon className={className} />

export default Info

Info.propTypes = {
  className: PropTypes.string
}
