import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const FieldsSeparator = ({ className = 'my-6' }) => {
  return <hr className={clsx('h-0.25 bg-neutral-400', className)} />
}
export default FieldsSeparator

FieldsSeparator.propTypes = {
  className: PropTypes.string
}
