import useCrud from './useCrud'

export const urlBase = '/admin/v1/upgrades'

const useUpgrade = () => {
  const { get: getUpgrades, destroy, update, findById, create: createUpgrade } = useCrud(urlBase)

  const getUpgrade = id => findById(['upgrade', id])

  const { get: getCreators } = useCrud(`${urlBase}/creators`)

  const { mutate: updateUpgrade } = update()

  const { mutate: deleteUpgrade } = destroy()

  return { getCreators, deleteUpgrade, getUpgrades, createUpgrade, getUpgrade, updateUpgrade }
}

export default useUpgrade
