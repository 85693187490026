import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from './Auth'

export const DetectRouteChange = () => {
  const history = useHistory()
  const { getUser } = useAuth()

  useEffect(() => {
    history.listen(() => {
      getUser()
    })
  }, [])

  return null
}
