import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import globeIcon from '../../../images/icons/globe.svg'
import { useTranslation } from 'react-i18next'
import { SelectInput } from '../../molecules/fields/SelectField'
import { InputAdornment, MenuItem, Typography } from '@material-ui/core'
import i18n from 'i18next'

const LanguageSelector = ({ languages, onChange }) => {
  const { t } = useTranslation('form_field_options')
  if (languages.length <= 1) {
    return null
  }
  return (
    <Fragment>
      <div className="relative">
        <p className="font-bold mb-2">{t('select_label_read_it_in')}</p>
        <div className="inline-block relative md:w-56 w-full">
          <SelectInput
            onChange={onChange}
            value={i18n.language}
            startAdornment={
              <InputAdornment position="start">
                <globeIcon />
              </InputAdornment>
            }
          >
            {languages.map(language => (
              <MenuItem value={language.locale} key={language.locale}>
                <Typography className="text-base whitespace-normal leading-none pl-12 pr-6 py-2">
                  {language.name}
                </Typography>
              </MenuItem>
            ))}
          </SelectInput>
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-4 text-gray-700">
            <img src={globeIcon} className="fill-current h-4 w-4" alt="" />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
}

export default LanguageSelector
