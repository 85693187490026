import { BankTransferenceFormCitizen } from '../components/organisms/payments/BankTransferenceFormCitizen'
import TransferAccounts from '../components/organisms/payments/TransferAccounts'
import TransferAccountsProcedure from '../components/organisms/payments/TransferAccountsProcedure'
import TransferAccountsTaskExecutionAnswer from '../components/organisms/payments/TransferAccountsTaskExecutionAnswer'

const banks = {
  bcp: 'BCP',
  interbank: 'Interbank',
  bbva: 'BBVA Continental',
  scotiabank: 'Scotiabank',
  banco_nacion: 'Banco de la Nación',
  caja_huancayo: 'Caja Huancayo'
}

const paymentMethods = {
  bank_transfer: {
    name: 'Transferencia bancaria',
    label: 'Transferencia bancaria',
    short_label: 'Transferencia',
    hintInDisabled: 'Podrás agregar las cuentas bancarias donde los ciudadanos realizarán transferencias y depósitos.',
    citizen: () => BankTransferenceFormCitizen,
    admin: () => TransferAccounts,
    procedure: () => TransferAccountsProcedure,
    taskExecutionAnswer: () => TransferAccountsTaskExecutionAnswer,
    errorName: 'payment_transfer_accounts',
    states: {
      approved: 'Aceptado',
      rejected: 'Rechazado',
      pending: 'Pendiente',
      total: 'total'
    },
    optionsToChangeStatus: [
      { value: 'rejected', label: 'Rechazado' },
      { value: 'approved', label: 'Aprobado' }
    ]
  }
}

const getOptionsToChangeStatus = answer => paymentMethods[answer.selected_kind_payment].optionsToChangeStatus

const getStatusLabel = answer =>
  getOptionsToChangeStatus(answer).find(option => option.value === answer.payment_status)?.label

const getBankOptions = Object.keys(banks).map(code => ({ value: code, label: banks[code] }))

export { banks, getBankOptions, paymentMethods, getStatusLabel, getOptionsToChangeStatus }
