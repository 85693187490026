import React from 'react'
import NotFoundImage from '../../../images/page_not_found.svg'
import { Button } from '../../atoms/Button'
import CitizenHeader from '../question_fields/CitizenHeader'
import PropTypes from 'prop-types'

const getTexts = data => {
  const slug = data.slug ? 'hasSlug' : 'hasNotSlug'
  const published = data.unpublished_date ? 'wasPublished' : 'notPublished'
  const publishedTexts = {
    wasPublished: {
      QuickSearch: `Lo sentimos, esta página estuvo activa hasta el ${data.unpublished_date}.`,
      Form: `Lo sentimos, este formulario estuvo activo hasta el ${data.unpublished_date}.`
    },
    notPublished: {
      QuickSearch: 'Lo sentimos, esta página no está habilitada.',
      Form: 'Lo sentimos, este formulario no está habilitado.'
    }
  }
  const slugTexts = {
    hasSlug: {
      description: `Para más información sobre trámites o servicios de ${data.name}, puedes ingresar a su página institucional.`,
      redirectLocationPath: `https://www.gob.pe/${data.slug}`,
      button: 'Ir a la página institucional',
      buttonSize: 'l'
    },
    hasNotSlug: {
      description: 'Para más información sobre trámites o servicios puedes ingresar a Gob.pe',
      redirectLocationPath: 'https://www.gob.pe',
      button: 'Ir a Gob.pe',
      buttonSize: 'm'
    }
  }
  return { importantText: publishedTexts[published][data.type], texts: slugTexts[slug] }
}

const NotFound = ({ data }) => {
  return (
    <div className="grid">
      <CitizenHeader institution={data} name={data.form_name} />
      <div className="flex flex-col items-center pt-20 text-center px-6 md:px-4">
        <img src={NotFoundImage} className="mb-2 md:mb-4 w-45 md:w-80" alt="" />
        <p className="font-bold text-xl mb-2">{getTexts(data).importantText}</p>
        <p className="text-base max-w-xl mb-10">{getTexts(data).texts.description}</p>
        <Button
          el="a"
          variant="secondary"
          size={getTexts(data).texts.buttonSize}
          href={getTexts(data).texts.redirectLocationPath}
        >
          {getTexts(data).texts.button}
        </Button>
      </div>
    </div>
  )
}

NotFound.propTypes = {
  data: PropTypes.exact({
    form_name: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    unpublished_date: PropTypes.string
  })
}

export default NotFound
