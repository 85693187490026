import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import SwitchField from '../../molecules/fields/SwitchField'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import useLanguages from '../../../hooks/useLanguages'
import { Button } from '../../atoms/Button'
import Dialog from '../../molecules/dialogs/Dialog'

export const EnableLanguagesForm = ({ onSubmit }) => {
  const { languages: enabledLanguages } = useLanguages('es', 'enabled')
  const { languages: disabledLanguages } = useLanguages('es', 'disabled')

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [submitAction, setSubmitAction] = useState(null)

  const handleOpenDialog = action => {
    setIsDialogOpen(true)
    setSubmitAction(() => action)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleConfirmDialog = () => {
    handleCloseDialog()
    submitAction && submitAction()
  }

  return (
    <>
      {isDialogOpen && (
        <Dialog
          maxWidth="sm"
          buttonClose={false}
          renderTitle={<p className="header font-bold text-lg">¿Deseas guardar los cambios realizados?</p>}
          renderContent={
            <p className="py-3">
              Si deshabilitas un idioma, esto afectará a todos los formularios que tengan una versión guardada en dicho
              idioma. Este cambio no afectará las versiones en español de los formularios.
            </p>
          }
          renderActions={
            <Fragment>
              <Button
                size="full"
                className="md:w-40 w-full md:mr-4 mr-0 md:mb-0 mb-4"
                onClick={handleConfirmDialog}
                variant="secondary"
              >
                Si, guardar
              </Button>
              <Button size="full" className="md:w-40 w-full" onClick={handleCloseDialog}>
                No, cancelar
              </Button>
            </Fragment>
          }
          onClose={handleCloseDialog}
        />
      )}

      <div className="mt-4 bg-gray-150 p-6">
        <h2 className="title text-2xl md:text-2xl mb-4">Habilitar idiomas</h2>
        <p className="page-description mb-4">Habilita o deshabilita los idiomas disponibles en la plataforma.</p>
        <FieldsSeparator />
        <h3 className="font-bold mb-4">Idiomas registrados en Facilita</h3>
        <Form onSubmit={values => handleOpenDialog(() => onSubmit(values))} autoComplete="off">
          {({ handleSubmit }) => (
            <form
              onSubmit={e => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              {enabledLanguages?.map(
                language =>
                  language.code !== 'es' && (
                    <SwitchField
                      key={language.code}
                      name={language.code}
                      parentClassName="flex mr-10 md:max-w-64 mb-4 md:mb-0 items-center my-3"
                      labelClassName="ml-2"
                      label={language.name}
                      initialValue={true}
                    />
                  )
              )}
              {disabledLanguages?.map(
                language =>
                  language.code !== 'es' && (
                    <SwitchField
                      key={language.code}
                      name={language.code}
                      parentClassName="flex mr-10 md:max-w-64 mb-4 md:mb-0 items-center my-3"
                      labelClassName="ml-2"
                      label={language.name}
                    />
                  )
              )}

              <div className="flex justify-end md:mt-8">
                <Button
                  size="full"
                  type="submit"
                  className="mt-2 md:mt-auto md:w-45 h-13 lg:max-w-sm text-base md:text-lg font-bold"
                >
                  Guardar
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </>
  )
}

EnableLanguagesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
