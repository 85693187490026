import PropTypes from 'prop-types'
import React from 'react'
import useFieldValue from '../../../hooks/useFieldValue'
import SwitchField from '../../molecules/fields/SwitchField'

const EditableAlternativesSwitch = ({ baseName }) => {
  const kind = useFieldValue(`${baseName}.kind`)
  return (
    <SwitchField
      name={`${baseName}.editable_alternative`}
      label={
        <span>
          {kind === 'file' ? (
            <>
              Descripción y<br /> alternativas editables
            </>
          ) : (
            <>
              Alternativas <br /> editables
            </>
          )}
        </span>
      }
    />
  )
}
export default EditableAlternativesSwitch

EditableAlternativesSwitch.propTypes = {
  baseName: PropTypes.string
}
