import PropTypes from 'prop-types'
import React from 'react'
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar'
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton'
import UnfoldMore from '@material-ui/icons/UnfoldMoreOutlined'
import esLocale from 'date-fns/locale/es'
import format from 'date-fns/format'

const DateFieldToolbar = function ({ date, isLandscape, openView, setOpenView, title }) {
  const dateEs = format(date, 'EEE, dd MMM', { locale: esLocale })
  const isDateView = openView === 'date'
  const isYearView = openView === 'year'

  return (
    <PickerToolbar
      className="flex flex-col items-start bg-blue-700 datepicker-toolbar"
      title={title}
      isLandscape={isLandscape}
    >
      <ToolbarButton
        onClick={() => {
          setOpenView('year')
        }}
        variant="h6"
        label={date.getFullYear().toString()}
        selected={isYearView}
        endIcon={<UnfoldMore className="text-white" />}
        className="hover:bg-black hover:bg-opacity-30 hover:text-white px-2 rounded-lg -ml-2"
      />
      <ToolbarButton
        onClick={() => setOpenView('date')}
        variant="h4"
        selected={isDateView}
        label={dateEs}
        className="text-4xl"
      />
    </PickerToolbar>
  )
}

export default DateFieldToolbar

DateFieldToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  isLandscape: PropTypes.bool,
  openView: PropTypes.string,
  setOpenView: PropTypes.func,
  title: PropTypes.string
}
