import PropTypes from 'prop-types'
import React from 'react'
import FileField from '../../molecules/fields/FileField'
import { PaymentAmountTag } from '../../molecules/PaymentAmountTag'

const ManualEntryPayment = ({ cost }) => {
  return (
    <>
      <div className="md:flex flex-row justify-center items-center bg-white rounded border-solid p-6 mb-6 font-bold">
        <p>
          Este trámite tiene un costo de <PaymentAmountTag amount={cost} />
        </p>
      </div>
      <FileField
        payload={{
          label: '1. Sube el comprobante de pago',
          hint: 'Adjunta tu comprobante de pago. Puedes subir un archivo PDF o una fotografía de hasta 10MB. Si subes una imagen, toda la información debe ser legible.'
        }}
        name="file"
        extensions={['pdf']}
      />
    </>
  )
}

export default ManualEntryPayment

ManualEntryPayment.propTypes = {
  cost: PropTypes.number
}
