import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-scroll'
import { Button } from '../../atoms/Button'
import React, { useState } from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'

const NavigateButtons = ({ isPreview, positionRight, sections }) => {
  const matchTabletSize = useMediaQuery('md')
  const [sectionActive, setSectionActive] = useState(0)
  const spaceToRight = isPreview ? 36 : positionRight
  return (
    <div
      className={clsx('grid top-32', isPreview ? 'md:absolute ' : 'md:fixed ')}
      style={{
        right: spaceToRight,
        width: matchTabletSize ? `calc(25% - ${spaceToRight}px)` : '100%'
      }}
    >
      {sections?.map((section, index) => (
        <Link
          to={index.toString()}
          key={index}
          spy={true}
          smooth={true}
          duration={250}
          onSetActive={value => setSectionActive(parseInt(value))}
          offset={isPreview ? 0 : -80}
          activeClass="no-underline"
          containerId={isPreview && 'containerDiv'}
        >
          <Button
            variant={sectionActive === index ? 'primary' : 'text'}
            classes={{ label: 'text-left block' }}
            onClick={() => setSectionActive(index)}
            className="md:max-w-18"
            size="full"
          >
            {section.title}
          </Button>
        </Link>
      ))}
    </div>
  )
}

export default NavigateButtons

NavigateButtons.propTypes = {
  isPreview: PropTypes.bool,
  positionRight: PropTypes.number,
  sections: PropTypes.array
}
