import PropTypes from 'prop-types'
import React from 'react'
import { Close as CloseIcon } from '@material-ui/icons'

const Close = ({ className }) => <CloseIcon className={className} />

export default Close

Close.propTypes = {
  className: PropTypes.string
}
