import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useQueryClient } from 'react-query'
import axios from 'axios'
import TaskPreview from './TaskPreview'
import clsx from 'clsx'
import ConnectionsAndRulesModal from './ConnectionsAndRulesModal'
import { isDefault, isMakePayment, isCommentary } from '../../../hooks/useTask'
import { ProcedureManagementContext } from '../../../utils/contexts'

const ConceptualMapTask = ({ task, onEdit: edit }) => {
  const procedureManagementId = useContext(ProcedureManagementContext)
  const [openPreview, setOpenPreview] = useState(false)
  const [openConnectionsAndRules, setOpenConnectionsAndRules] = useState(false)
  const queryClient = useQueryClient()
  const handleDestroy = async () => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la tarea ${task.name}?`)) {
      try {
        await axios.delete(`/admin/v1/procedure_managements/${procedureManagementId}/tasks/${task.id}.json`)
        queryClient.invalidateQueries(['tasks', procedureManagementId])
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }
  return (
    <>
      <div className="border border-neutral-300 h-10 w-0 ml-6" />
      <div className="flex flex-col">
        <div
          className={clsx(
            'flex gap-3 justify-between bg-blue-300 border border-neutral-300 px-6 py-3',
            isCommentary(task) ? 'rounded-lg' : 'rounded-t-lg'
          )}
        >
          <div className="leading-5 font-bold">{task.name}</div>
          <div className="flex gap-3">
            <IconButton color="primary" className="p-0" onClick={() => setOpenPreview(true)}>
              <VisibilityIcon fontSize="small" />
            </IconButton>
            {!(isDefault(task) || isMakePayment(task)) && (
              <>
                <IconButton color="primary" className="p-0" onClick={edit} aria-label="Editar tarea">
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton color="primary" className="p-0" onClick={handleDestroy} aria-label="Eliminar tarea">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </div>
        </div>
        {!isCommentary(task) && (
          <div
            className={clsx(
              'flex items-center rounded-b-lg border border-neutral-300 px-6 py-3',
              task.has_connections ? 'bg-green-500' : 'bg-white'
            )}
          >
            <button
              className={clsx('font-bold text-blue-700 flex-1', !task.has_connections && 'text-center')}
              onClick={() => setOpenConnectionsAndRules(true)}
              type="button"
            >
              {task.has_connections ? 'Conexiones y reglas definidas' : 'Definir conexiones y reglas'}
            </button>
            {task.has_connections && (
              <IconButton color="primary" onClick={() => setOpenConnectionsAndRules(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )}
      </div>
      {openPreview && <TaskPreview taskId={task.id} onClose={() => setOpenPreview(false)} />}
      {openConnectionsAndRules && (
        <ConnectionsAndRulesModal
          taskId={task.id}
          onClose={() => setOpenConnectionsAndRules(false)}
          onAfterSave={() => {
            queryClient.invalidateQueries(['tasks', procedureManagementId])
            setOpenConnectionsAndRules(false)
          }}
        />
      )}
    </>
  )
}
export default ConceptualMapTask

ConceptualMapTask.propTypes = {
  onEdit: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired
}
