import React from 'react'
import Container from '../../../atoms/Container'
import { UsersForm } from '../../../organisms/areas_users/UsersForm'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { useAuth } from '../../../molecules/Auth'
import PropTypes from 'prop-types'
import DeleteUser from '../../../organisms/areas_users/DeleteUser'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const editUser = userId => values => axios.put(`/admin/v1/users/${userId}.json`, values)
const fetchUser = id => axios.get(`/admin/v1/users/${id}.json`).then(a => a.data)

const UsersEdit = ({ title = 'Editar usuario' }) => {
  const { id: userId } = useParams()
  const { user } = useAuth()
  const _userId = userId || user.id
  const { data: userData, status } = useQuery(['user', _userId], () => fetchUser(_userId), {
    refetchOnWindowFocus: false
  })
  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="py-12 md:px-0">
        <div className="flex flex-col md:flex-row px-6 md:px-0 justify-between mb-4 md:items-center gap-4">
          <h1 className="text-2xl font-bold">{title}</h1>
          <DeleteUser user={userData} />
        </div>
        <UsersForm initialValues={userData} onSave={editUser(_userId)} isExternal={Boolean(userId)} edit />
      </Container>
    </>
  )
}

UsersEdit.propTypes = {
  title: PropTypes.string
}

export default UsersEdit
