import axios from 'axios'
import { useQuery } from 'react-query'

const getCountries = () => axios.get(`/v1/countries.json`).then(res => res.data)

const useCountry = () => {
  const { data: countries, isLoading } = useQuery(['countries'], getCountries, {
    refetchOnWindowFocus: false,
    initialData: []
  })

  return { countries, isLoading }
}

export default useCountry
