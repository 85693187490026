import React, { useContext, useState } from 'react'
import ConceptualMapUserType from './ConceptualMapUserType'
import { Button } from '../../atoms/Button'
import AddIcon from '@material-ui/icons/Add'
import useUserTypes from '../../../hooks/useUserTypes'
import { useQueryClient } from 'react-query'
import UserTypeFormNew from './UserTypeFormNew'
import UserTypeFormEdit from './UserTypeFormEdit'
import { ProcedureManagementContext } from '../../../utils/contexts'

const ConceptualMap = () => {
  const [newUserType, setNewUserType] = useState(false)
  const [userTypeToEdit, setUserTypeToEdit] = useState(null)
  const procedureManagementId = useContext(ProcedureManagementContext)
  const { data: userTypes, status } = useUserTypes(procedureManagementId)
  const queryClient = useQueryClient()
  if (status !== 'success') return null
  return (
    <>
      <div className="flex flex-col md:flex-row gap-12">
        <ConceptualMapUserType />
        {userTypes.map((userType, index) => (
          <ConceptualMapUserType userType={userType} key={index} onEdit={() => setUserTypeToEdit(userType)} />
        ))}
        <Button
          size="full"
          className="mb-auto min-h-16 md:min-w-60 md:max-w-72"
          variant="secondary"
          startIcon={<AddIcon />}
          onClick={() => setNewUserType(true)}
        >
          Crear tipo de usuario
        </Button>
        <div>&nbsp;</div>
      </div>
      <UserTypeFormNew
        open={newUserType}
        onAfterSave={() => {
          queryClient.invalidateQueries(['user_types', procedureManagementId])
          setNewUserType(false)
        }}
        onClose={() => setNewUserType(false)}
      />
      {userTypeToEdit !== null && (
        <UserTypeFormEdit
          UserTypeId={userTypeToEdit.id}
          onAfterSave={() => {
            queryClient.invalidateQueries(['user_types', procedureManagementId])
            setUserTypeToEdit(null)
          }}
          onClose={() => setUserTypeToEdit(null)}
        />
      )}
    </>
  )
}
export default ConceptualMap
