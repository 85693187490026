import PropTypes from 'prop-types'
import React from 'react'
import DateCitizenField from '../question_fields/DateCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin } = props
  return (
    <DateCitizenField
      index={index}
      {...question}
      disableFutureDate={question.disable_future_date}
      name={name}
      disabled={disabled}
      margin={margin}
    />
  )
}

const DateQuestion = {
  Citizen,
  name: 'DateQuestion'
}

export default DateQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}
