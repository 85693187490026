import { useEffect, useRef } from 'react'
import { isSuccessful } from './useQuery'

export const useCallbackOnFirstSuccess = (status, callback) => {
  const isLoaded = useRef()
  useEffect(() => {
    if (isSuccessful(status) && !isLoaded.current) {
      isLoaded.current = true
      callback && callback()
    }
  }, [status])
  return isLoaded
}
