import useQuery from './useQuery'
import axios from 'axios'
import { useAuth } from '../components/molecules/Auth'
import useCrud from './useCrud'
import useSearchParams from './useSearchParams'

export const urlFetch = '/admin/v1/institutions'
const fetchRecords = (urlBase, params) => () => axios.get(`${urlBase}.json`, { params }).then(res => res.data)

const fetchPaymentAnswersStatus = id => axios.get(`${urlFetch}/${id}/payment_answers_status.json`).then(a => a.data)

const fetchInstitutionsByType = type => () => axios.get(`/admin/v1/institutions/${type}.json`).then(res => res.data)

const fetchInstitutionWithEnabledByProcedure = (id, procedureManagementId) => () =>
  axios
    .get(`${urlFetch}/${id}/with_enabled_by_procedure_management.json?procedure_management_id=${procedureManagementId}`)
    .then(res => res.data)

const fetchMigrateRecordsToBranchOfficeAxios = values =>
  axios.put(`/admin/v1/institutions/${values.id}/migrate_records_to_branch_office`, values).then(res => res.data)

export const pcmId = 527

export const isPCM = institution => institution?.id === pcmId

const useGetInstitutionWithEnabledByProcedure = (id, procedureManagementId) =>
  useQuery(
    [`institutions_${id}_with_enabled_by_${procedureManagementId}`],
    fetchInstitutionWithEnabledByProcedure(id, procedureManagementId),
    { enabled: Boolean(id) }
  )

const useInstitution = () => {
  const { user } = useAuth()
  const searchParams = useSearchParams()

  const { update, findById, get } = useCrud(urlFetch)

  const useGetAll = (queryKey, params, options) => useQuery(queryKey, fetchRecords(`${urlFetch}/all`, params), options)

  const getInstitution = institutionId => {
    const id = institutionId || user.institution_id
    return findById(['institution', id])
  }

  const getInstitutions = institutionType =>
    get(['institutions', institutionType], { institution_type: institutionType })

  const { mutate: updateInstitution } = update()

  const useGetPaymentAnswersStatus = id => useQuery(['institution_payment_answers', id], fetchPaymentAnswersStatus)

  const useGetInstitutionByType = byType => useQuery([`institutions_by_${byType}`], fetchInstitutionsByType(byType))

  const querySameInstitution =
    searchParams.get('institution_id') === null ||
    searchParams.get('institution_id') === user?.institution_id?.toString()

  const migrateRecordsToBranchOffice = async values => await fetchMigrateRecordsToBranchOfficeAxios(values)

  const ownInstitution = institutionId => institutionId === user.institution_id

  return {
    useGetAll,
    getInstitution,
    getInstitutions,
    updateInstitution,
    useGetPaymentAnswersStatus,
    querySameInstitution,
    useGetInstitutionByType,
    useGetInstitutionWithEnabledByProcedure,
    migrateRecordsToBranchOffice,
    ownInstitution
  }
}

export default useInstitution
