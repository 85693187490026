import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import Link from '@material-ui/core/Link'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import useExport from '../../../hooks/useExport'
import clsx from 'clsx'
import { isSuccessful } from '../../../hooks/useQuery'

const ExportButton = ({ title, url, filters, exportable, className }) => {
  const history = useHistory()
  const { create, getStatus } = useExport()
  const { data, status } = getStatus(
    ['export_status', exportable.type, exportable.id],
    { exportable_id: exportable.id, exportable_type: exportable.type },
    { refetchOnWindowFocus: true }
  )
  const handleExport = async e => {
    e.preventDefault()
    create(filters, exportable, () => history.push(url))
  }

  const filterExport = { ...filters }
  delete filterExport.page
  delete filterExport.sort

  const hasEnabledExport =
    isSuccessful(status) && (data.enable || (data.enable_with_filter && Object.keys(filterExport).length > 0))

  return (
    <div className={clsx('flex justify-between items-center flex-col w-full md:w-max', className)}>
      <Button
        className="flex mb-4 md:mb-2 w-full md:max-w-56"
        size="full"
        onClick={handleExport}
        el="a"
        variant="secondary"
        disabled={!hasEnabledExport}
      >
        {title}
      </Button>
      <Link component={RouterLink} to={url} className="font-medium">
        Historial de exportaciones
      </Link>
    </div>
  )
}

export default ExportButton

ExportButton.propTypes = {
  className: PropTypes.string,
  exportable: PropTypes.object,
  filters: PropTypes.object,
  title: PropTypes.node,
  url: PropTypes.string
}
