import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'
import { useAuth } from './Auth'
import Login from '../pages/citizens/Login'
import MigrateBranchOffice from '../organisms/branch_offices/MigrateBranchOffice'
import { isLoading, isSuccessful } from '../../hooks/useQuery'

const PrivateRoute = ({ children, ...rest }) => {
  const { status, user } = useAuth()

  if (isLoading(status)) return <Route {...rest}>{null}</Route>

  if (status === 'failed')
    return (
      <Route {...rest}>
        <Login />
      </Route>
    )

  if (user?.institution_need_migration && user?.role === 'coordinator')
    return <MigrateBranchOffice institutionId={user?.institution_id} />

  if (isSuccessful(status)) return <Route {...rest}>{children}</Route>

  return null
}

export default PrivateRoute

PrivateRoute.propTypes = {
  children: PropTypes.node
}
