import { useQuery } from 'react-query'
import axios from 'axios'

const fetchStates = procedureManagementId =>
  axios.get(`/admin/v1/procedure_managements/${procedureManagementId}/states.json`).then(res => res.data)

const useStates = procedureManagementId => {
  return useQuery(['states', procedureManagementId], () => fetchStates(procedureManagementId), {
    refetchOnWindowFocus: false
  })
}
export default useStates
