import React, { Fragment } from 'react'
import ActionAlert from '../../molecules/ActionAlert'

const actionTypes = [
  { type: 'quick_search_deleted_success', status: 'success', content: 'Consulta rápida eliminada' },
  {
    type: 'quick_search_deleted_error',
    status: 'error',
    content: 'No se puede eliminar una consulta rápida con consultas, solo puedes despublicarla.'
  }
]

const QuickSearchAlert = () => {
  return (
    <Fragment>
      {actionTypes.map(actionType => (
        <ActionAlert
          key={actionType.type}
          type={actionType.type}
          status={actionType.status}
          content={actionType.content}
        />
      ))}
    </Fragment>
  )
}

export default QuickSearchAlert
