import React from 'react'
import FileField from '../../../molecules/fields/FileField'
import * as validations from '../../../../utils/validations'

const extensions = ['jpg', 'png', 'jpeg']

const CoverImage = () => {
  const validation = validations.asyncMix(
    validations.isValidFileMimeType(['images']),
    validations.maxFileSize(2, 'MB'),
    validations.maxImageDimensions(1400, 500),
    validations.minImageDimensions(1300, 400)
  )

  return (
    <div>
      <p className="font-bold mb-1">Imagen de portada</p>
      <p className="mb-2">
        La imagen debe tener un tamaño mínimo de 1300 (ancho) x 400 (alto) píxeles y un tamaño máximo de 1400 (ancho) x
        500 (alto) píxeles. <br /> Además, debe ser de formato jpg, png o jpeg y pesar como máximo 2 MB.
      </p>
      <FileField
        name="cover_image"
        ariaLabel="Selecciona la imagen de portada"
        extensions={extensions}
        validate={validation}
        showFileTypeValidation
      />
    </div>
  )
}

export default CoverImage
