import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import SelectField from '../../molecules/fields/SelectField'
import { dataToOptions } from '../../../utils/helpers'
import * as validations from '../../../utils/validations'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import NewUserModal from './NewUserModal'
import useFieldValue from '../../../hooks/useFieldValue'
import { ScrollToElement } from '../../molecules/ScrollToElement'
import useSearchParams from '../../../hooks/useSearchParams'

const UserSection = ({ name, onRemove, users }) => {
  const searchParams = useSearchParams()
  const [openUserFormModal, setOpenUserFormModal] = useState(false)
  const userId = useFieldValue(`${name}.user_id`)
  const userScrollTo = searchParams.get('user-id')
  const initialUserId = useRef(userId)
  const userOptions = [
    {
      value: null,
      label: (
        <button
          className="flex items-center text-blue-700 font-bold"
          onClick={() => setOpenUserFormModal(true)}
          type="button"
        >
          <AddIcon />
          Crear nueva cuenta de usuario
        </button>
      )
    },
    ...dataToOptions(users)
  ]
  return (
    <>
      {userScrollTo === initialUserId.current && <ScrollToElement elementId={`initial_user_${userScrollTo}`} />}
      <div className="flex items-center mb-6 gap-3" id={`initial_user_${initialUserId.current}`}>
        <SelectField
          name={`${name}.user_id`}
          options={userOptions}
          containerClassName="flex-1 sm:max-w-sm"
          size="full"
          validate={!openUserFormModal ? validations.required() : () => {}}
          emptyLabel="Selecciona una cuenta de usuario"
          margin="none"
          key={openUserFormModal}
        />
        <IconButton type="button" color="primary" className="p-0" onClick={onRemove}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <NewUserModal open={openUserFormModal} onClose={() => setOpenUserFormModal(false)} name={name} />
    </>
  )
}
export default UserSection

UserSection.propTypes = {
  name: PropTypes.string,
  onRemove: PropTypes.func,
  users: PropTypes.array
}
