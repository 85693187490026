import React, { Fragment } from 'react'
import FormSection from '../../molecules/FormSection'
import Instructions from '../../molecules/Instructions'
import { InitialFormSetup } from './InitialFormSetup'
import { instructions } from '../../../configs/procedureManagementsConfig'
import StepsFields from '../forms/StepsFields'
import FieldsSeparator from '../../atoms/FieldsSeparator'

export const InitialForm = () => {
  return (
    <>
      <InitialFormSetup />
      <FormSection title="Preguntas del formulario">
        <Instructions list={instructions} />
        <FieldsSeparator />
        <StepsFields />
      </FormSection>
    </>
  )
}
