import React, { Fragment } from 'react'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add'
import FormsTable from '../../../organisms/forms/FormsTable'
import { useQueryClient } from 'react-query'
import useFilters from '../../../../hooks/useFilters'
import FormsFilter from '../../../organisms/forms/FormsFilter'
import FormStartMessage from '../../../organisms/forms/FormStartMessage'
import PageHeading from '../../../molecules/PageHeading'
import useUserPermission from '../../../../hooks/useUserPermission'
import { getPageDescription } from '../../../../configs/formsConfig'
import Alert from '../../../molecules/Alert'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { urlBaseForm } from '../../../../hooks/useForm'
import { urlToJson } from '../../../../utils/helpers'
import AllInstitutionsBranchOfficeFilter from '../../../organisms/filters/AllInstitutionsBranchOfficeFilter'
import { BoxTable } from '../../../molecules/BoxTable'
import FormAlert from '../../../organisms/forms/FormAlert'
import { useUrlActionRecord } from '../../../../hooks/useUrlActionRecord'

const Forms = () => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)
  const { canCreate, showStartMessage } = useUserPermission('form')
  const urlFetch = urlToJson(urlBaseForm)
  const { onFilter, initialFilters, results: forms } = useFilters(urlFetch, { sort: 'create_desc' })
  const { handleAction } = useUrlActionRecord({ type: 'form' })

  const queryClient = useQueryClient()
  const handleDestroy = async form => {
    if (window.confirm(`¿Estás seguro que deseas eliminar el formulario ${form.name}?`)) {
      try {
        if (form.answers > 0) {
          handleAction('deleted', 'error')
          return false
        } else {
          await axios.delete(`/admin/v1/forms/${form.id}.json`)
          queryClient.invalidateQueries(urlFetch)
          handleAction('deleted', 'success')
        }
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }
  const options = [
    { value: 'create_desc', label: 'Creación: más recientes primero' },
    { value: 'create_asc', label: 'Creación: más antiguos primero' },
    { value: 'update_desc', label: 'Actualización: más recientes primero' },
    { value: 'update_asc', label: 'Actualización: más antiguos primero' }
  ]

  const generalPermissions = useUserPermission()
  const pageDescription = getPageDescription(generalPermissions)

  return (
    <Fragment>
      <FormAlert />
      <PageHeading
        title="Formularios"
        pageDescription={pageDescription}
        button={
          canCreate && {
            name: 'Crear formulario',
            link: '/formularios/crear/configuracion',
            icon: <AddIcon />
          }
        }
        filterSection={<AllInstitutionsBranchOfficeFilter byType="by_form" />}
      />
      {urlSearchParams.action === 'access_error' && (
        <Alert type="info_yellow" className="mb-4 md:mt-8 w-full">
          Importante: Recuerda que si compartes los enlaces de los formularios con otras personas, no podrán acceder a
          ellos ni visualizar su contenido, salvo que se les hayan sido asignados.
        </Alert>
      )}
      <BoxTable
        filters={<FormsFilter />}
        onFilter={onFilter}
        initialFilters={initialFilters}
        sortOptions={options}
        values={forms}
        displayTotal
        displaySort
        displayHeader={false}
        accordionHasBackground={false}
      >
        <FormsTable forms={forms.data} onDestroy={handleDestroy} pinnedCount={forms.total_forms_pinned} />
      </BoxTable>
      {showStartMessage && <FormStartMessage />}
    </Fragment>
  )
}

export default Forms
