import PropTypes from 'prop-types'
import React from 'react'
import ReferToBranchOfficeEnabledProcedureCitizenField from '../procedure_managements/ReferToBranchOfficeEnabledProcedureCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin } = props
  return (
    <ReferToBranchOfficeEnabledProcedureCitizenField
      index={index}
      {...question}
      name={name}
      disabled={disabled}
      margin={margin}
    />
  )
}

const BranchOfficeToReferQuestion = {
  Citizen,
  name: 'ReferToBranchOfficeProcedureManagementQuestion'
}

export default BranchOfficeToReferQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}
