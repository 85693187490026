import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Error from '../Error'
import clsx from 'clsx'
import useMediaQuery from '../../../hooks/useMediaQuery'

const RadioFieldForConditions = ({ name, label, validate, options = [], row = false, ...props }) => {
  const { input } = useField(name, { validate, ...props })
  const isMobile = useMediaQuery('md')

  return (
    <div className="relative">
      <fieldset className={clsx('js-field-container', 'md:w-36 ml-4')}>
        <RadioGroup aria-label={label} name={name} {...input} row={row} className="gap-x-6 my-4 md:my-0">
          {options.map((o, index) => (
            <FormControlLabel
              key={index}
              value={o.value.toString()}
              control={<Radio inputProps={{ 'aria-label': `${o.label}` }} className="py-1 px-3" />}
              classes={{ label: 'text-base' }}
              label={!isMobile ? `${o.label}` : ''}
            />
          ))}
        </RadioGroup>
        <Error name={name} />
      </fieldset>
    </div>
  )
}

export default RadioFieldForConditions

RadioFieldForConditions.propTypes = {
  row: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  validate: PropTypes.func
}
