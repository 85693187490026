import PropTypes from 'prop-types'
import React from 'react'
import { TagBox } from '../../molecules/TagBox'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { IconButton } from '@material-ui/core'
import {
  errorResendInfo,
  hasResend,
  useAnswerToWebhook,
  webhookStatusColorMap
} from '../../../hooks/useAnswerToWebhook'
import WebhookStatusLabel from '../../molecules/WebhookStatusLabel'
import WebhookResendButton from '../../molecules/buttons/WebhookResendButton'
import DownloadPDFButton from './DownloadPDFButton'
import { paymentMethods } from '../../../configs/paymentMethodConfigs'
import Tooltip from '../../atoms/Tooltip'
import Tr from '../../atoms/table/Tr'
import Td from '../../atoms/table/Td'

const AnswerRow = ({ answer: answerFromServer, form, formHasCost }) => {
  const { answer, onResend } = useAnswerToWebhook({ answer: answerFromServer, formId: form.id })

  return (
    <Tr ariaDescribedby={`loading-${answer.id}`} ariaBusy={answer.webhook_status === 'sending'}>
      <Td>{answer.id}</Td>
      <Td>{answer.hashid}</Td>
      <Td>{answer.document_number}</Td>
      <Td>{answer.created_at}</Td>
      {form.has_webhook && (
        <Td className={webhookStatusColorMap[answer.webhook_status]}>
          <div className="flex items-center font-semibold gap-0.5">
            <WebhookStatusLabel
              status={answer.webhook_status}
              id={`loading-${answer.id}`}
              infoContent={errorResendInfo.list}
            />
          </div>
        </Td>
      )}
      {formHasCost ? (
        answer.payment_status ? (
          <Td>
            {paymentMethods[answer.selected_kind_payment].name} (
            {paymentMethods[answer.selected_kind_payment].states[answer.payment_status]})
          </Td>
        ) : (
          <Td />
        )
      ) : null}
      <Td>
        <TagBox label={answer.status.label} />
      </Td>
      <Td className="flex justify-start">
        <Link className="" to={`/formularios/${form.id}/respuestas/${answer.id}`}>
          <Tooltip title="Ver respuesta">
            <IconButton className="text-blue-700">
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <DownloadPDFButton formId={form.id} answerId={answer.id} />
        {form.has_webhook && hasResend(answer.webhook_status) && (
          <WebhookResendButton onResend={onResend} status={answer.webhook_status} />
        )}
      </Td>
    </Tr>
  )
}

export default AnswerRow

AnswerRow.propTypes = {
  answer: PropTypes.object,
  form: PropTypes.object,
  formHasCost: PropTypes.bool
}
