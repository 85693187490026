import PropTypes from 'prop-types'
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AccessSection from './AccessSection'

const SelectedAreaSection = ({ area, onRemove, baseName }) => {
  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-center gap-3 justify-between bg-indigo-700 rounded-t-lg border border-indigo-700 p-6 text-white">
        <div className="leading-5 font-bold text-xl">{area?.name}</div>
        <IconButton color="inherit" className="p-0" onClick={onRemove}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <div className="rounded-b-lg border-2 border-indigo-700 p-6">
        <AccessSection baseName={baseName} areaID={area?.id} />
      </div>
    </div>
  )
}
export default SelectedAreaSection

SelectedAreaSection.propTypes = {
  area: PropTypes.object,
  baseName: PropTypes.string,
  onRemove: PropTypes.func
}
