import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import useUserPermission from '../../../hooks/useUserPermission'

const Card = ({ feedback }) => {
  const { canSeeSenderType, canFilterByInstitution } = useUserPermission('feedback')
  return (
    <div className="w-full p-6 mb-6 border-2 border-neutral-400 bg-white rounded-lg">
      <div className="flex justify-between border-b border-neutral-400 pb-4 mb-4">
        <div className="md:w-2/3 flex items-center pb-3 md:pb-0 w-1/2">
          <span className="font-bold mr-8 w-24 text-xl">ID: {feedback.id}</span>
        </div>
        <div className="text-right font-bold pb-3 md:pb-0 md:w-auto w-1/2">
          {canSeeSenderType && (
            <div
              className={clsx(
                'text-base py-2 px-4 text-center mb-2 md:mb-4',
                feedback.user_id
                  ? 'bg-blue-400 text-blue-700 border-blue-600 border rounded-sm'
                  : 'bg-green-500 text-green-800 border-green-700 border rounded-sm'
              )}
            >
              {feedback.user_id ? 'ENTIDAD PÚBLICA' : 'CIUDADANO'}
            </div>
          )}
          <div className="px-2">{feedback.created_at}</div>
        </div>
      </div>
      <div className="md:w-2/3 pb-3 md:pb-0">
        {canFilterByInstitution && (
          <BoldLabelAndContent label="Entidad:" content={feedback.institution_name} className="mb-0" />
        )}
        {feedback.user_name && (
          <BoldLabelAndContent
            label="Usuario:"
            content={<a href={feedback.user_link}>{feedback.user_name}</a>}
            className="mb-0"
          />
        )}
        {feedback.form_name && (
          <BoldLabelAndContent
            label="Formulario:"
            content={<a href={feedback.form_link}>{feedback.form_name}</a>}
            className="mb-0"
          />
        )}
        <BoldLabelAndContent label="Tema:" content={feedback.reason_description} className="mb-0" />
        <BoldLabelAndContent label="Descripción:" content={feedback.comment} className="mb-0" />
        {feedback.contact_the_sender_text && (
          <BoldLabelAndContent
            label={feedback.contact_the_sender_text}
            content="quiere ser contactado para mejorar Gob.pe"
            className="mb-0"
          />
        )}
        {feedback.sender_name && (
          <BoldLabelAndContent label="Nombres y apellidos:" content={feedback.sender_name} className="mb-0" />
        )}
        {feedback.sender_email && (
          <BoldLabelAndContent label="Correo electrónico:" content={feedback.sender_email} className="mb-0" />
        )}
        {feedback.phone && (
          <BoldLabelAndContent label="Número de teléfono:" content={feedback.phone} className="mb-0" />
        )}
        <BoldLabelAndContent label="Info. del dispositivo:" content={feedback.device_info} className="mb-0" />
      </div>
    </div>
  )
}

export default Card

Card.propTypes = {
  feedback: PropTypes.object
}
