import React, { Fragment } from 'react'
import Container from '../../../atoms/Container'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { LanguageForm } from '../../../organisms/terms_translations/LanguageForm'

const LanguageNew = () => {
  return (
    <Fragment>
      <PageNavigation />
      <Container className="md:pt-14 pt-8 md:pb-16 pb-0 px-5 md:px-0">
        <h1 className="title text-3xl md:text-4xl mb-10">Administrar idioma</h1>
        <LanguageForm />
      </Container>
    </Fragment>
  )
}

export default LanguageNew
