import PropTypes from 'prop-types'
import React from 'react'
import AddressCitizenField from '../question_fields/AddressCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false } = props
  return <AddressCitizenField index={index} {...question} name={name} disabled={disabled} />
}

const AddressQuestion = {
  Citizen,
  name: 'AddressQuestion'
}

export default AddressQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  name: PropTypes.string,
  question: PropTypes.object
}
