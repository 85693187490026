import React from 'react'
import { useParams } from 'react-router-dom'
import QueryPageForm from '../../../organisms/quick_searches/QueryPageForm'
import useQuickSearch from '../../../../hooks/useQuickSearch'

const QuickSearchEdit = () => {
  const { id: quickSearchId } = useParams()
  const { getQuickSearch, updateQuickSearch } = useQuickSearch()
  const { data: quickSearchData, status } = getQuickSearch(quickSearchId)

  if (status !== 'success') return null
  return <QueryPageForm initialValues={quickSearchData} mutate={updateQuickSearch} action="edit" />
}
export default QuickSearchEdit
