import React from 'react'
import { StylesProvider, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 0
  },
  palette: {
    primary: {
      main: '#0056ac'
    },
    secondary: {
      main: '#26292e'
    }
  }
})

const Theme = ({ ...props }) => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme} {...props} />
    </StylesProvider>
  )
}

export default Theme
