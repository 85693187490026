import PropTypes from 'prop-types'
import React from 'react'
import { FormHelperText, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

const useErrorStyles = makeStyles(() => ({
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: '#CF000B'
    }
  }
}))

const ErrorValidationMessage = ({ showInfoIcon, error, className }) => {
  const classes = useErrorStyles()

  return (
    <FormHelperText error className={`${className} js-error flex flex-col md:flex-row`} classes={classes}>
      {showInfoIcon && <InfoIcon className="mr-2 mb-2 md:mb-0" />}
      {error}
    </FormHelperText>
  )
}

export default ErrorValidationMessage

ErrorValidationMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  showInfoIcon: PropTypes.bool
}
