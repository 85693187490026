import clsx from 'clsx'
import React from 'react'
import equal from 'deep-equal'
import { addYAtTheEnd } from '../../../utils/helpers'

const DetailGeneralRules = ({ rules }) => {
  const rulesFormatted = rules.reduce((prev, current) => {
    const ruleFormat = {
      date: current.date,
      hour: current.hour,
      state: current.state,
      action: current.action
    }
    const exist = prev.find(el => {
      ruleFormat.task = el.task
      return equal(ruleFormat, el)
    })
    if (exist) {
      exist.task.push(current.task)
    } else {
      ruleFormat.task = [current.task]
      prev.push(ruleFormat)
    }
    return prev
  }, [])

  return rulesFormatted.map((rule, index) => (
    <div className={clsx('bg-yellow-400 p-4 ', index === 0 ? 'mt-2' : 'mt-4')} key={index}>
      <span>Tienes </span>
      <span className="font-extrabold">
        {' '}
        hasta el {rule.date} a las {rule.hour} hrs.
      </span>
      <span> para {addYAtTheEnd(rule.task)}. </span>
      {rule.action === 'disable' && (
        <>
          Pasada esta fecha{' '}
          {rule.task.length > 1 ? (
            <>
              las tareas se <span className="font-extrabold"> deshabilitarán.</span>
            </>
          ) : (
            <>
              la tarea se <span className="font-extrabold"> deshabilitará.</span>
            </>
          )}
        </>
      )}
      {rule.action === 'change_state' && (
        <>
          Pasada esta fecha tu solicitud <span className="font-extrabold"> será {rule.state}.</span>
        </>
      )}
    </div>
  ))
}

export default DetailGeneralRules
