import PropTypes from 'prop-types'
import React from 'react'
import { Tab as TabMUI } from '@material-ui/core'

const classesStyle = (classes, style) => {
  const baseStyles = {
    blue: { selected: 'bg-white text-blue-700 font-bold' }
  }
  return style ? { ...baseStyles[style], ...classes } : classes
}

export const Tab = ({ label, value, classes = {}, onChange, style, ...props }) => (
  <TabMUI label={label} value={value} classes={classesStyle(classes, style)} onChange={onChange} {...props} />
)

Tab.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.string,
  onChange: PropTypes.func
}
