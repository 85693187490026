import PropTypes from 'prop-types'
import React from 'react'
import { paymentMethods } from '../../../configs/paymentMethodConfigs'

const PaymentMethodForm = ({ paymentMethod, kind, ...prop }) => {
  const El = paymentMethods[paymentMethod][kind]()
  return El && <El {...prop} />
}

export default PaymentMethodForm

PaymentMethodForm.propTypes = {
  kind: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired
}
