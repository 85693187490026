import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router-dom'
import FormsHeader from '../../molecules/FormsHeader'
import Preview from '../../templates/Preview'
import FunctionaryPage from './FunctionaryPage'
import { articleSteps, informationText } from '../../../configs/onboardingConfig'

const PagePreview = ({ getArticle, updateArticle, edit }) => {
  const { id } = useParams()
  const { data: article, status } = getArticle(id)

  if (status !== 'success') return null

  return (
    <Preview
      steps={<FormsHeader title={articleSteps[1].label} steps={articleSteps} activeStep={articleSteps[1]} />}
      data={article}
      publishedUpdate={updateArticle}
      informationText={informationText}
      edit={edit}
    >
      <FunctionaryPage getArticle={getArticle} isPreview={true} />
    </Preview>
  )
}

export default PagePreview

PagePreview.propTypes = {
  edit: PropTypes.object,
  getArticle: PropTypes.func.isRequired,
  updateArticle: PropTypes.func.isRequired
}
