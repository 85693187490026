import PropTypes from 'prop-types'
import React from 'react'
import { FormControl, FormLabel } from '@material-ui/core'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { useAllowedRoles } from '../../../hooks/useAllowedRoles'

const RolesFilter = ({ label, name }) => {
  const allowedRoles = useAllowedRoles()
  return (
    <FormControl component="fieldset" classes={{ root: 'block md:flex' }}>
      <FormLabel component="legend" className="font-bold text-gray-800 text-base">
        {label}
      </FormLabel>
      {allowedRoles.map(rol => (
        <CheckboxField name={name || 'roles'} label={rol.name} key={rol.code} value={rol.code} margin="none" />
      ))}
    </FormControl>
  )
}

export default RolesFilter

RolesFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string
}
