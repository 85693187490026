import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { marginSizes } from '../../../configs/sizesConfig'
import * as validations from '../../../utils/validations'
import RadioDoubleField from '../../molecules/fields/RadioDoubleField'
import useTextValidation from '../../../hooks/useTextValidation'

const RadioDoubleCitizenField = ({
  name,
  index,
  payload,
  required,
  requiredOneRow,
  maxOneCol,
  disabled = true,
  margin
}) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const translationText = useTextValidation()
  const validate = validations.mix(
    required && validations.required(translationText.required),
    requiredOneRow && validations.validateOnePerRow(payload?.rows.length, translationText.requiredOnePerRow),
    maxOneCol && validations.validateOnePerCol(translationText.maxOneCol)
  )

  return (
    <div className={clsx(marginSizes[margin], 'relative overflow-x-auto')}>
      <fieldset className="js-field-container">
        <RadioDoubleField
          hint={payload.hint}
          label={label}
          name={name}
          validate={validate}
          cols={payload.cols}
          rows={payload.rows}
          disabled={disabled}
        />
      </fieldset>
    </div>
  )
}

export default RadioDoubleCitizenField

RadioDoubleCitizenField.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  payload: PropTypes.object,
  required: PropTypes.bool,
  requiredOneRow: PropTypes.bool,
  maxOneCol: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.string
}
