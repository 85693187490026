import useCrud from './useCrud'
import useInvalidateQueries from './useInvalidateQueries'

const useCategory = () => {
  const { get, create: createCategory, destroy, update } = useCrud('/admin/v1/categories')
  const invalidateQueries = useInvalidateQueries()

  const getCategories = () => get(['categories'])

  const { mutate: destroyCategory } = destroy()

  const { mutate: updateCategory } = update()

  const invalidateCategories = () => invalidateQueries(['categories'])

  const { get: getCategoriesWithTutorial } = useCrud(`/admin/v1/categories/with_tutorial`)

  return {
    getCategories,
    getCategoriesWithTutorial,
    invalidateCategories,
    createCategory,
    destroyCategory,
    updateCategory
  }
}

export default useCategory
