const trackLoginEvent = (id, timestamp) => {
  if (!verifyAnalytics()) return

  window.dataLayer.push({
    event_name: 'Iniciar sesión',
    user_id: id,
    time_stamp: timestamp
  })
}

const verifyAnalytics = () => {
  return Boolean(window.dataLayer)
}

export { trackLoginEvent }
