import React from 'react'
import Alert from '../../molecules/Alert'
import useFieldValue from '../../../hooks/useFieldValue'
import { getFirstQuestionByType } from '../../../configs/questionTypesAdminConfig'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'

const InfoAutoResponseFromTemplate = () => {
  const stepsAttributes = useFieldValue('steps_attributes')
  const autoResponse = useFieldValue('auto_response')
  const firstEmailField = getFirstQuestionByType(stepsAttributes, 'email')
  const indexForEmailInfo = firstEmailField?.questionIndex + 1
  return (
    <>
      <WhenFieldChanges
        field="auto_response"
        set={`steps_attributes[${firstEmailField.stepIndex}].questions_attributes[${firstEmailField.questionIndex}].required`}
        to={autoResponse}
      />
      {autoResponse && (
        <Alert className="ml-4 my-6">
          Al seleccionar esta opción, la Pregunta {indexForEmailInfo} de Correo electrónico ha cambiado a obligatoria.
        </Alert>
      )}
    </>
  )
}
export default InfoAutoResponseFromTemplate
