import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const Hint = ({ hint, name, className }) => {
  return (
    <p id={`${name}-hint`} className={clsx('break-words mb-2', className)}>
      {hint}
    </p>
  )
}

export default Hint

Hint.propTypes = {
  hint: PropTypes.node,
  name: PropTypes.string,
  className: PropTypes.string
}
