import React, { Fragment } from 'react'
import Container from '../../../atoms/Container'
import useFilters from '../../../../hooks/useFilters'
import PageHeading from '../../../molecules/PageHeading'
import TermsTable from '../../../organisms/terms_translations/TermsTable'
import AddIcon from '@material-ui/icons/Add'
import queryString from 'query-string'
import { Link, useLocation } from 'react-router-dom'
import SelectFieldFilter from '../../../molecules/filters/SelectFieldFilter'
import { BoxTable } from '../../../molecules/BoxTable'
import useLanguages from '../../../../hooks/useLanguages'
import { urlBaseTerms } from '../../../../hooks/useTerms'
import { useTermsCategories, useTermsExternalCategories } from '../../../../hooks/useTermsCategories'
import { Button } from '../../../atoms/Button'
import renderAlert from '../../../organisms/terms_translations/Alerts'

const Terms = () => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)
  const { languages } = useLanguages('es', 'enabled')
  const { data: categoriesData } = useTermsCategories()
  const { data: externalCategoriesData } = useTermsExternalCategories()

  const { onFilter, initialFilters, results: terms } = useFilters(urlBaseTerms, { sort: 'desc' })

  return (
    <Fragment>
      {renderAlert(urlSearchParams.action)}

      <Container>
        <PageHeading
          title="Traducciones de contenido estático"
          pageDescription="Agrega versiones del contenido estático en otros idiomas."
          headingRightClass="md:w-1/3 md:justify-end md:flex m:flex-wrap"
          boxTableClasses="md:pt-14 md:pb-10 pt-0 pb-12"
          customButton={
            <>
              <Button
                className="mt-6 md:mt-auto md:w-56 h-13 lg:max-w-sm text-base font-bold"
                size="full"
                el={Link}
                to="/traducciones/crear"
                variant="secondary"
                startIcon={<AddIcon />}
              >
                Administrar idiomas
              </Button>
              <Button
                className="mt-6 md:mt-auto md:w-48 h-13 lg:max-w-sm text-base font-bold ml-6"
                size="full"
                el={Link}
                to="/traducciones/carga-masiva"
                variant="primary"
              >
                Subir carga masiva
              </Button>
            </>
          }
        />
        <BoxTable
          initialFilters={initialFilters}
          onFilter={onFilter}
          values={terms}
          searchPlaceholder="Buscar por contenido o identificador"
          displayHeader={false}
          displayExpandedFilter={true}
          filters={
            <div className="md:flex flex-row items-start md:py-6 gap-10 mt-5 md:mt-0 w-full">
              <div className="mb-6 md:mb-0 md:w-70">
                <SelectFieldFilter
                  className="mt-3 rounded"
                  label="Sección del contenido"
                  options={categoriesData || []}
                  name="category"
                />
              </div>
              <div className="mb-6 md:mb-0 md:w-72">
                <SelectFieldFilter
                  className="mt-3 md:w-70 rounded"
                  label="Idioma"
                  options={languages || []}
                  name="language"
                />
              </div>
              <div className="mb-6 md:mb-0 md:w-70">
                <SelectFieldFilter
                  className="mt-3 rounded"
                  label="Contenido externo"
                  options={externalCategoriesData || []}
                  name="external_category"
                />
              </div>
            </div>
          }
          displayTotal
        >
          <TermsTable terms={terms.data} />
        </BoxTable>
      </Container>
    </Fragment>
  )
}

export default Terms
