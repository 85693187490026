import { useQuery } from 'react-query'
import axios from 'axios'

const fetchUserTypes = procedureManagementId =>
  axios.get(`/admin/v1/procedure_managements/${procedureManagementId}/user_types.json`).then(res => res.data)

const useUserTypes = procedureManagementId => {
  return useQuery(['user_types', procedureManagementId], () => fetchUserTypes(procedureManagementId), {
    refetchOnWindowFocus: false
  })
}
export default useUserTypes
