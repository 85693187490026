import React from 'react'
import ReceivedTypeFormFilter from './ReceivedTypeFormFilter'
import PropTypes from 'prop-types'
import FormListFilters from './FormListFilters'

const ReceivedRequestsFilters = ({ onChange }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div>
        <ReceivedTypeFormFilter onChange={onChange} />
      </div>
      <div>
        <FormListFilters />
      </div>
    </div>
  )
}

ReceivedRequestsFilters.propTypes = {
  onChange: PropTypes.func
}

export default ReceivedRequestsFilters
