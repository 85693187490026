import PropTypes from 'prop-types'
import React from 'react'
import Container from '../../atoms/Container'
import Form from './Form'
import { PageNavigation } from '../navigation/PageNavigation'

const PageEdit = ({ headerInfo, updateArticle, initialValues }) => {
  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <Form
          headerInfo={headerInfo}
          initialValues={initialValues}
          mutateForm={updateArticle}
          isTutorial={initialValues.type === 'Tutorial'}
          edit
        />
      </Container>
    </>
  )
}
export default PageEdit

PageEdit.propTypes = {
  headerInfo: PropTypes.object,
  initialValues: PropTypes.object,
  updateArticle: PropTypes.func
}
