import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { Button } from '../../atoms/Button'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import CheckboxField from '../../molecules/fields/CheckboxField'
import Error from '../../molecules/Error'
import { useQuery } from 'react-query'
import axios from 'axios'
import { getLabel, getQuestionsPreviousSteps } from '../../../configs/questionTypesAdminConfig'

const fetchInitialForm = procedureManagementId =>
  axios.get(`/admin/v1/procedure_managements/${procedureManagementId}/initial_form.json`).then(res => res.data)

const getAllQuestions = initialForm =>
  getQuestionsPreviousSteps(initialForm.steps_attributes, initialForm.steps_attributes.length)

const UserTypeForm = ({ title, buttonLabel, initialValues: initValues, onSave: save, onClose: close }) => {
  const { data: initialForm, status } = useQuery(
    ['initialForm', initValues.procedure_management_id],
    () => fetchInitialForm(initValues.procedure_management_id),
    { refetchOnWindowFocus: false }
  )
  const onSubmit = async values => {
    try {
      await save(values)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }
  if (status !== 'success') return null
  const initialValues = {
    visible_info: getAllQuestions(initialForm).map(question => question.id.toString()),
    ...initValues
  }
  return (
    <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues}>
      {({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="flex flex-col h-full">
          <h2 className="header text-xl py-4 px-4 md:px-0 font-bold">{title}</h2>
          <div className="body flex-1 bg-neutral-100 p-4 md:p-6">
            <TextField
              name="name"
              label="Nombre del tipo de usuario"
              margin="none"
              size="2xl"
              validate={validations.required()}
            />
            <FieldsSeparator />
            <TextField
              element="textarea"
              name="hint"
              label="Áreas internas a las que pertenece este tipo de usuario (opcional)"
              hint="Esta información le aparecerá como sugerencia al funcionario coordinador de Facilita de cada entidad al momento de crear sus áreas internas en la habilitación de su gestión de trámite."
              placeholder="Ejemplo: Los funcionarios revisores de denuncias trabajan en las áreas de Procuradoría y Secretaría"
              margin="none"
              size="full"
            />
            <FieldsSeparator />
            <label
              className="block font-bold"
              id={`visible_info-label`}
              htmlFor={`visible_info-input`}
              aria-describedby={`visible_info-hint`}
            >
              Indica la información del ciudadano que será visible para este tipo de usuario
            </label>
            <div className="relative">
              <div>
                {getAllQuestions(initialForm).map((question, index) => (
                  <CheckboxField
                    name="visible_info[]"
                    label={getLabel(question)}
                    value={question.id.toString()}
                    key={index}
                    margin="none"
                    hideError
                  />
                ))}
              </div>
              <Error className="absolute -bottom-4" name={''} />
            </div>
            <FieldsSeparator />
            <p className="block font-bold">Ingreso de solicitudes manuales</p>
            <CheckboxField
              margin="none"
              name="permissions"
              value="can_manual_entry"
              label="Quiero que este tipo de usuario pueda ingresar solicitudes de los ciudadanos de forma manual."
            />
          </div>
          <div className="footer flex flex-col-reverse md:flex-row justify-end py-4 px-4 md:px-0">
            <Button variant="secondary" className="md:max-w-56 lg:max-w-72 md:mr-3" size="full" onClick={close}>
              Cerrar
            </Button>
            <Button
              type="submit"
              className="md:max-w-56 lg:max-w-72 mb-3 md:mb-0"
              size="full"
              disabled={submitting || pristine}
            >
              {buttonLabel}
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}
export default UserTypeForm

UserTypeForm.propTypes = {
  buttonLabel: PropTypes.string,
  initialValues: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string
}
