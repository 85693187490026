import { Form } from 'react-final-form'
import TextField from '../components/molecules/fields/TextField'
import CheckboxField from '../components/molecules/fields/CheckboxField'
import { Button } from '../components/atoms/Button'
import React from 'react'
import { ReactSVG as Svg } from 'react-svg'
import sampleLogo from '../images/icons/sample_logo.svg'
import antispamEnable from '../../assets/images/antispam_enable.svg'
import CitizenHeader from '../components/organisms/question_fields/CitizenHeader'

const handleSubmit = () => {}

const step1 = (
  <>
    <div className="border-2 border-neutral-400 bg-white">
      <CitizenHeader
        name="Ejemplo: Consulta de padrón de beneficiarios de canasta básica familiar"
        showUserInfo={false}
      />
      <div className="flex-1 md:flex h-full py-12">
        <div className="md:w-1/2 p-4 md:px-6 pb-0">
          <section className="lg:max-w-md md:m-auto" aria-labelledby="bienvenida-label">
            <Svg src={sampleLogo} />
            <h1 id="nombre-label" className="font-bold text-3xl md:text-4xl mb-4">
              Ejemplo: Consulta el padrón de beneficiarios de canasta básica familiar
            </h1>
            <p className="md:mb-4 font-bold">Ingresa tus datos para saber si estás en el padrón</p>
            <p>
              Si necesitas más información, ingresa a la <strong className="text-blue-700">página de campaña.</strong>
            </p>
          </section>
        </div>
        <Form onSubmit={handleSubmit} initialValues={{ doc_number: '12345678' }}>
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="grid md:w-1/2 md:px-6 pt-8 md:pt-0 relative md:top-0 items-center justify-center"
              aria-labelledby="consulta rapida"
            >
              <div className="p-10 md:p-6 lg:p-8 lg:max-w-md bg-neutral-100 mb-10">
                <TextField name="doc_number" label="Número de DNI" size="full" labelClassName="text-xl" readOnly />
                <img src={antispamEnable} alt="captcha" />
                <CheckboxField
                  margin="dense"
                  className="mb-6"
                  name="privacy_policy"
                  label={
                    <p>
                      Acepto <span className="text-blue-700 font-bold cursor-pointer">la política de privacidad</span>
                    </p>
                  }
                  readOnly
                />
              </div>
              <Button className="ml-2 md:ml-0">Consultar</Button>
            </form>
          )}
        </Form>
      </div>
    </div>
  </>
)

const step2 = (
  <>
    <div className="border-2 border-neutral-400 bg-white">
      <CitizenHeader
        name="Ejemplo: Consulta de padrón de beneficiarios de canasta básica familiar"
        showUserInfo={false}
      />
      <div className="p-12 md:px-24">
        <p className="text-xl mb-6">
          Resultado para el DNI: <strong className="font-bold">12345678</strong>
        </p>
        <p className="text-2xl mb-2">¡Hola, Laura Andrea!</p>
        <strong className="text-xl font-bold mb-6 break-words block max-w-lg">Sí te encuentras en el padrón</strong>
        <div className="mt-6 mb-12 break-words max-w-lg">
          <div>
            <p>Puedes recoger tu canasta básica familiar en:</p>
            <ul className="list-disc ml-6">
              <li>
                <strong className="font-bold">Lugar: </strong> Campo de Marte
              </li>
              <li>
                <strong className="font-bold">Fecha: </strong> 01/02/21
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
)

const step3 = (
  <>
    <div className="border-2 border-neutral-400 bg-white">
      <CitizenHeader
        name="Ejemplo: Consulta de padrón de beneficiarios de canasta básica familiar"
        showUserInfo={false}
      />
      <div className="p-12 md:px-24">
        <p className="text-xl mb-6">
          Resultado para el DNI: <strong className="font-bold">12345678</strong>
        </p>
        <p className="text-2xl mb-2">¡Hola!</p>
        <strong className="text-xl font-bold mb-6 break-words block max-w-lg">No te encuentras en el padrón</strong>
        <div className="mt-4 mb-12 break-words max-w-lg">
          <p>
            No hemos encontrado tu documento de identidad en el padrón de beneficiarios.
            <br /> Para más información por favor comunícate al siguiente correo:
            <br /> <strong className="text-blue-700">ejemplo@correo.com</strong>
          </p>
        </div>
      </div>
    </div>
  </>
)

export { step1, step2, step3 }
