import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Td = ({ className, children, colSpan }) => {
  return (
    <td className={clsx('px-4 py-2', className)} colSpan={colSpan}>
      {children}
    </td>
  )
}

export default Td

Td.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colSpan: PropTypes.string
}
