import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import QuestionOptionsSection from './QuestionOptionsSection'
import Alert from '../../molecules/Alert'
import * as validations from '../../../utils/validations'

const LabelInputAndInfo = ({ baseName, infoContent }) => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <span>
        <TextField
          label="Etiqueta de la pregunta"
          margin="mb-0"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validations.required()}
          errorClassName="relative inset-0"
        />
        <QuestionOptionsSection baseName={baseName} size="full" />
      </span>
      <Alert type="info">{infoContent}</Alert>
    </div>
  )
}
export default LabelInputAndInfo

LabelInputAndInfo.propTypes = {
  baseName: PropTypes.string,
  infoContent: PropTypes.node
}
