import React from 'react'
import OndemandVideoRounded from '@material-ui/icons/OndemandVideoRounded'
import VideoUploadBox from './VideoUploadBox'
import useToggle from '../../../../hooks/useToggle'

const VideoToolBar = props => {
  const [toggleModal, setToggleModal] = useToggle()
  return (
    <button
      className="relative mx-1 h-5 w-6 border-gray-30 border-solid rounded-sm flex justify-center items-center border-px"
      onClick={() => setToggleModal()}
      title="Insertar video"
      type="button"
    >
      <OndemandVideoRounded className="cursor-pointer text-lg " />
      {toggleModal && <VideoUploadBox onClose={setToggleModal} {...props} />}
    </button>
  )
}

export default VideoToolBar
