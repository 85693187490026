import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const Container = ({ className, ...props }) => {
  return <div className={clsx('container mx-auto px-0 md:px-4', className)} {...props} />
}

export default Container

Container.propTypes = {
  className: PropTypes.string
}
