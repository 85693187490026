import React from 'react'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import EditIcon from '@material-ui/icons/Edit'
import PersonIcon from '@material-ui/icons/Person'
import Container from '../../../atoms/Container'
import useInstitution from '../../../../hooks/useInstitution'
import { Link, useParams } from 'react-router-dom'

const InstitutionDetails = () => {
  const { id } = useParams()
  const { getInstitution } = useInstitution()
  const { data: institution, status } = getInstitution(id)

  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="py-8 md:py-16 px-4 md:px-0">
        <div>
          <div className="text-gray-800 flex flex-row justify-between mb-10 items-end gap-10 font-bold">
            <div>
              <p className="text-xl mb-2">Entidad</p>
              <p className="text-4xl">{institution.name}</p>
            </div>
            <div className="text-blue-700 flex flex-row justify-center items-center gap-6">
              <a
                href={`${window.sisoli.gobpe_url}/admin2/instituciones/${institution.slug}/edit`}
                target="_blank"
                className="flex items-center"
                rel="noreferrer"
              >
                <EditIcon className="mr-2" />
                Editar en gob.pe
              </a>
              <Link to={`/areas-y-usuarios?institution_id=${institution.id}`} className="flex items-center">
                <PersonIcon className="mr-2" />
                Ver usuarios
              </Link>
            </div>
          </div>
          <div className="bg-neutral-100 flex flex-col items-start p-8 gap-8">
            <div className="flex flex-col items-start p-0 gap-2">
              <p className="font-bold">Nombre de la entidad</p>
              <p>{institution.name}</p>
            </div>
            <div className="flex flex-col items-start p-0 gap-2">
              <p className="font-bold">Tipo de Entidad</p>
              <p>{institution.institution_type_name}</p>
            </div>
            <div className="flex flex-col items-start p-0 gap-2">
              <p className="font-bold">Ubicación</p>
              <div className="flex flex-col">
                <p>Departamento: {institution.location?.region}</p>
                <p>Provincia: {institution.location?.province}</p>
                <p>Distrito: {institution.location?.district}</p>
              </div>
            </div>
            <div className="flex flex-col items-start p-0 gap-2">
              <p className="font-bold">Fecha de incorporación</p>
              <p>{institution.incorporation_date}</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default InstitutionDetails
