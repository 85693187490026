import React, { useState } from 'react'
import TextField from '../TextField'
import * as validations from '../../../../utils/validations'
import { isIframe, required } from '../../../../utils/validations'
import { AtomicBlockUtils } from 'draft-js'
import DialogBox from './DialogBox'
import PropTypes from 'prop-types'

const VideoUploadBox = props => {
  const { onClose, onChange, editorState } = props
  const [fileUpload, setFileUpload] = useState(false)

  const handleStopPropagation = event => {
    if (!fileUpload) {
      event.preventDefault()
      event.stopPropagation()
    } else setFileUpload(false)
  }

  const handleAddVideoUrl = async values => {
    const src = values.url
    const parser = new DOMParser()
    const doc = parser.parseFromString(src, 'text/html')
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('EMBEDDED_LINK', 'MUTABLE', {
        height: '450px',
        width: '450px',
        src: doc.activeElement.firstChild.attributes.src.value
      })
      .getLastCreatedEntityKey()
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')
    await onChange(newEditorState)
    onClose()
  }

  return (
    <DialogBox
      onStopPropagation={handleStopPropagation}
      onSubmit={handleAddVideoUrl}
      titleText={`Insertar `}
      dialogContent={
        <TextField
          name="url"
          size="full"
          validate={validations.mix(required(), isIframe())}
          placeholder="URL incorporada del video"
          element="textarea"
          rows={5}
          className="resize-none"
        />
      }
      onClose={onClose}
    />
  )
}

VideoUploadBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  editorState: PropTypes.node
}

export default VideoUploadBox
