import * as validations from '../utils/validations'
import { useTranslation } from 'react-i18next'

const documentTypesConfig = {
  dni: {
    name: 'document_type_id_card',
    type: 'natural',
    validation: required => getValidation(8, 8, required, validations.isNumber())
  },
  ce: {
    name: 'document_type_foreign_resident_card',
    type: 'natural',
    validation: required => getValidation(9, 12, required, validations.isAlphanumeric())
  },
  pasaporte: {
    name: 'document_type_passport',
    type: 'natural',
    validation: required => getValidation(7, 12, required, validations.isAlphanumeric())
  },
  ruc_natural: {
    name: 'document_type_ruc_10_for_individuals',
    type: 'natural',
    validation: required => getValidation(11, 11, required, validations.isNumber(), validations.startWith('10'))
  },
  ruc_juridico: {
    name: 'document_type_ruc_20_for_legal_entities',
    type: 'juridica',
    validation: required => getValidation(11, 11, required, validations.isNumber(), validations.startWith('20'))
  },
  other_document: {
    name: 'document_type_others',
    type: 'natural',
    validation: required => validations.mix(validations.notHasBlankSpaces(), required && validations.required())
  }
}

const getValidation = (min, max, required, ...valid) =>
  validations.mix(
    validations.minLength(min),
    validations.maxLength(max),
    required && validations.required(),
    validations.notHasBlankSpaces(),
    ...valid
  )

const getValidationByType = (docType, required) => documentTypesConfig[docType]?.validation(required)

const useDocumentTypeOptions = (type = 'natural') => {
  const { t } = useTranslation('form_field_options')

  return Object.keys(documentTypesConfig).reduce((options, docType) => {
    if (documentTypesConfig[docType].type === type || type === 'natural-juridica') {
      options = options.concat({ value: docType, label: t(documentTypesConfig[docType].name) })
    }
    return options
  }, [])
}

export { useDocumentTypeOptions, getValidationByType }
