import PropTypes from 'prop-types'
import CheckboxField from '../../molecules/fields/CheckboxField'
import Value from '../../molecules/Value'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import React from 'react'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'

const Hint = ({ baseName }) => {
  return (
    <div className="w-full">
      <CheckboxField
        className="ml-0.5"
        name={`${baseName}.has_hint`}
        margin="dense"
        label="Agregar descripción"
        noYpadding
      />
      <WhenFieldChanges field={`${baseName}.has_hint`} set={`${baseName}.hint`} to={null} />
      <Value name={`${baseName}.has_hint`}>
        {({ value }) =>
          value && (
            <TextField
              name={`${baseName}.hint`}
              margin="dense"
              size="full"
              validate={validations.required()}
              maxLength={150}
              element="textarea"
              dataTestid="hintQuestion"
            />
          )
        }
      </Value>
    </div>
  )
}

export default Hint

Hint.propTypes = {
  baseName: PropTypes.string
}
