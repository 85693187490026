import React from 'react'
import { roles as rolList } from './permissionsConfig'

const { admin, coordinator, branch_coordinator: branchCoordinator, collaborator } = rolList

const roles = [
  {
    code: admin,
    name: 'Administrador',
    pluralName: 'Administradores'
  },
  {
    code: coordinator,
    name: 'Coordinador de entidad',
    pluralName: 'Coordinadores de entidad'
  },
  {
    code: branchCoordinator,
    name: 'Coordinador de sede',
    pluralName: 'Coordinadores de sede'
  },
  {
    code: collaborator,
    name: 'Colaborador',
    pluralName: 'Colaboradores'
  }
]

const getRolDetails = rol => roles.find(el => el.code === rol)

const getPageDescription = ({
  canFilterByInstitution,
  canFilterByBranchOffice,
  canFilterAndHasNotBranchOffices,
  canSeeBranchRecords
}) => {
  if (canFilterByInstitution) return 'Selecciona la entidad y sede para ver sus áreas y usuarios.'

  if (canFilterByBranchOffice) return 'Selecciona la sede cuyas áreas y usuarios quieres ver.'

  if (canFilterAndHasNotBranchOffices)
    return 'Estas son las áreas y usuarios que tienen acceso a los servicios de tu institución.'

  if (canSeeBranchRecords) return 'Estas son las áreas y usuarios que tienen acceso a los servicios de tu sede.'
}

const getTypeDescription = role => {
  if (role === coordinator)
    return (
      <span>
        El
        <strong> coordinador de entidad </strong>
        puede ver, crear y editar todos los formularios, gestiones de trámites y consultas rápidas de su entidad.
        Además, puede crear nuevos usuarios y administrar los permisos de todas las cuentas.
      </span>
    )

  if (role === branchCoordinator)
    return (
      <span>
        El
        <strong> coordinador de sede </strong>
        puede ver, crear y editar todos los formularios, gestiones de trámites y consultas rápidas de su sede. Además,
        puede crear nuevos usuarios de perfil colaborador y administrar sus permisos.
      </span>
    )
}

const userActions = {
  user_created: {
    title: 'Cuenta creada exitosamente. ',
    body: 'Se le ha enviado al funcionario un correo de bienvenida con sus credenciales de inicio de sesión.'
  },
  user_updated: {
    title: 'Edición de usuario realizada con éxito.'
  },
  user_deleted: {
    title: 'Usuario eliminado exitosamente'
  }
}

export { roles, getPageDescription, getTypeDescription, getRolDetails, userActions }
