import React from 'react'
import Container from '../../atoms/Container'
import whatCanDoImage0 from '../../../images/what_can_do_0.svg'
import whatCanDoImage1 from '../../../images/what_can_do_1.svg'
import whatCanDoImage2 from '../../../images/what_can_do_2.svg'

export const WhatCanIDo = () => {
  const cards = [
    {
      body: 'Crear formularios y consultas rápidas para atender a la ciudadanía',
      img: whatCanDoImage0
    },
    {
      body: 'Gestionar y hacer seguimiento a las solicitudes ciudadanas',
      img: whatCanDoImage1
    },
    {
      body: 'Revisar indicadores sobre las solicitudes gestionadas en Facilita',
      img: whatCanDoImage2
    }
  ]

  return (
    <section className="pt-14 md:pt-20 pb-14 md:pb-24">
      <Container>
        <h2 className="mb-7 md:mb-12 font-bold text-2xl text-center">¿Qué puedo hacer con Facilita?</h2>
        <ul className="flex flex-nowrap container-cards md:container md:justify-center overflow-x-auto md:overflow-hidden pb-2 lg:px-64">
          {cards.map(card => (
            <li key={card.body} className="flex flex-col md:px-12 px-6 text-center justify-center items-center gap-4">
              <img className="block w-20" src={card.img} alt="" loading="lazy" />
              <p className="text-base">{card.body}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
