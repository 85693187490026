import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import Visibility from '@material-ui/icons/Visibility'
import { Link } from 'react-router-dom'

const SelectAndPreviewCard = ({ item, createPath, onPreview, footer }) => {
  return (
    <div
      className="flex flex-col rounded max-w-sm bg-white p-4 md:p-6 w-full"
      data-testid={`SelectAndPreviewCard-${item.id}`}
    >
      <div className="font-bold mb-4">{item.name}</div>
      <hr />
      {footer || (
        <div className="flex justify-between mt-4">
          <Button
            type="button"
            variant="text"
            size="xs"
            className="text-sm"
            startIcon={<Visibility />}
            onClick={() => onPreview(item)}
          >
            Vista previa
          </Button>
          <Button type="button" variant="secondary" size="xs" className="text-sm" el={Link} to={createPath + item.id}>
            Seleccionar
          </Button>
        </div>
      )}
    </div>
  )
}
export default SelectAndPreviewCard

SelectAndPreviewCard.propTypes = {
  createPath: PropTypes.string,
  footer: PropTypes.element,
  item: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string,
    legal_conditions: PropTypes.bool
  }),
  onPreview: PropTypes.func.isRequired
}
