import React from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import FormsHeader from '../../../molecules/FormsHeader'
import axios from 'axios'
import { formSteps } from '../../../../configs/procedureManagementsConfig'
import useUserTypes from '../../../../hooks/useUserTypes'
import Preview from '../../../templates/Preview'
import TabContextPreview from '../../../organisms/procedure_managements/TabContextPreview'
import i18n from 'i18next'

const fetchProcedureManagement = (procedureManagementId, locale = 'es') =>
  axios.get(`/admin/v1/procedure_managements/${procedureManagementId}.json?locale=${locale}`).then(a => a.data)

const updatePublished = id => values => axios.put(`/admin/v1/procedure_managements/${id}.json`, values)

const ProcedureManagementsPreview = () => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { data: procedureManagement, status } = useQuery(
    ['procedure_management', id, i18n.language],
    () => fetchProcedureManagement(id, i18n.language),
    { refetchOnWindowFocus: false }
  )
  const { data: userTypes, status: statusUserTypes } = useUserTypes(id)
  const { mutate } = useMutation(updatePublished(id), {
    onSuccess: () => queryClient.invalidateQueries('procedure_management')
  })
  if (status !== 'success' || statusUserTypes !== 'success') return null
  return (
    <Preview
      steps={
        <FormsHeader
          title="Creador de gestión de trámite"
          hint="Crear una nueva gestión de trámite para las instituciones usuarias de Facilita."
          size="l"
          steps={formSteps}
          activeStep={formSteps.PREVIEW}
        />
      }
      data={procedureManagement}
      publishedUpdate={mutate}
      informationText={{
        linkTitle: 'Publicación',
        linkHint:
          'Una vez publicada tu gestión de trámite, esta podrá ser habilitada desde la sección Gestión de trámites por los funcionarios públicos Coordinadores de cada institución.',
        previewTitle: 'Este es un ejemplo de la gestión de trámite que verán los usuarios'
      }}
      edit={{
        url: `/gestiones-de-tramites/${id}/editar`,
        buttonLabel: 'Editar gestión de trámite'
      }}
    >
      <TabContextPreview userTypes={userTypes} form={procedureManagement.form} />
    </Preview>
  )
}
export default ProcedureManagementsPreview
