import PropTypes from 'prop-types'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { banks } from '../../../configs/paymentMethodConfigs'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import editIcon from '../../../images/icons/edit.svg'
import { ReactSVG as Svg } from 'react-svg'

const TransferAccountReadonly = ({ index, transferAccount, handleEdit, handleDelete, submitting }) => {
  return (
    <fieldset className="bg-neutral-200 p-4 md:p-8 mb-4">
      <div className="font-bold text-xl mb-2">Cuenta {index}</div>
      <div className="md:flex justify-between items-center">
        <ul className="list-inside list-disc mb-4 md:mb-0">
          <BoldLabelAndContent
            label="Entidad financiera:"
            content={banks[transferAccount.bank]}
            el="li"
            className="mb-0 max-w-48 md:max-w-none"
          />
          <BoldLabelAndContent
            label="Número de cuenta:"
            content={transferAccount.number}
            el="li"
            className="mb-0 max-w-48 md:max-w-none"
          />
          <BoldLabelAndContent
            label="Código de cuenta interbancario (CCI):"
            content={transferAccount.cci_number}
            el="li"
            className="mb-0 max-w-48 md:max-w-none"
          />
          <BoldLabelAndContent
            label="Razón social:"
            content={transferAccount.company_name}
            el="li"
            className="mb-0 max-w-32 md:max-w-none"
          />
          <BoldLabelAndContent
            label="Número de RUC:"
            content={transferAccount.ruc}
            el="li"
            className="mb-0 max-w-48 md:max-w-none"
          />
        </ul>
        <div className="grid grid-cols-2 gap-2">
          <IconButton
            type="button"
            color="primary"
            onClick={handleEdit}
            classes={{ root: 'bg-white rounded-none w-full md:w-12 md:h-12' }}
            disabled={submitting}
          >
            <Svg src={editIcon} />
          </IconButton>
          <IconButton
            type="button"
            color="primary"
            onClick={handleDelete}
            classes={{ root: 'bg-white rounded-none w-full md:w-12 md:h-12' }}
            disabled={submitting}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </fieldset>
  )
}

export default TransferAccountReadonly

TransferAccountReadonly.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  index: PropTypes.number,
  submitting: PropTypes.bool,
  transferAccount: PropTypes.object
}
