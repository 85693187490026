import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import SectionTitle from './SectionTitle'
import CardDescription from './CardDescription'
import useUpgrade from '../../../hooks/useUpgrade'

const UpgradeList = ({ className }) => {
  const { getUpgrades } = useUpgrade()
  const { data: lastUpgrades } = getUpgrades(['lastUpgrades'], { per_u: 6 })

  return (
    <div className={clsx('bg-neutral-100 p-6', className)}>
      <div className="mb-6 border-solid border-b-2 border-neutral-400">
        <SectionTitle title="Actualizaciones" description="¿Qué hay de nuevo en Facilita?">
          <Button
            className="md:w-37.5"
            size="full"
            fullWidth={false}
            variant="secondary"
            component={Link}
            to={`/centro-de-ayuda/actualizaciones`}
          >
            Ver todo
          </Button>
        </SectionTitle>
      </div>
      <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-3">
        {lastUpgrades?.data.map(upgrade => (
          <CardDescription
            key={upgrade.id}
            to={upgrade.item_url}
            name={upgrade.name}
            description={upgrade.description}
            descriptionLines={5}
          />
        ))}
      </div>
    </div>
  )
}

export default UpgradeList

UpgradeList.propTypes = {
  className: PropTypes.string
}
