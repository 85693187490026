import React, { useContext, useEffect, useState } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { IconButton } from '@material-ui/core'
import { ReactSVG as Svg } from 'react-svg'
import useMediaQuery, { screenSizes } from '../../../hooks/useMediaQuery'
import scrollRightNavigationIcon from '../../../images/icons/scroll_right_navigation.svg'

const RightScrollNavigation = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } = useContext(VisibilityContext)
  const [disabled, setDisabled] = useState(!visibleItemsWithoutSeparators.length && isLastItemVisible)
  const matchTabletSize = useMediaQuery(screenSizes.tablet)
  const additionalProps = matchTabletSize ? {} : { 'aria-hidden': 'true' }

  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible)
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators])

  return (
    <IconButton
      onClick={() => scrollNext()}
      classes={{ root: 'hidden md:flex hover:bg-transparent', disabled: 'text-neutral-400' }}
      color="primary"
      disabled={disabled}
      {...additionalProps}
    >
      <Svg src={scrollRightNavigationIcon} />
    </IconButton>
  )
}

export default RightScrollNavigation
