import { useQuery } from 'react-query'
import axios from 'axios'

const fetchForm = async (formId, language = 'es') => {
  try {
    const response = await axios.get(`/admin/v1/forms/${formId}.json?locale=${language}`)
    return response.data
  } catch (error) {
    alert(error)
  }
}

const fetchProcedureManagement = async procedureManagementId => {
  try {
    const response = await axios.get(`/admin/v1/procedure_managements/${procedureManagementId}.json`)
    return response.data
  } catch (error) {
    alert(error)
  }
}

export const useFetchDataTranslations = ({
  formId,
  procedureManagementId,
  isNew,
  currentLanguage,
  isProcedureManagement
}) => {
  const formDataQuery = useQuery(
    ['form', formId, isNew ? 'default' : currentLanguage],
    () => fetchForm(formId, isNew ? undefined : currentLanguage),
    {
      onError: error => alert(error.message)
    }
  )

  const formDataInSpanishQuery = useQuery(['form', formId, 'es'], () => fetchForm(formId, 'es'), {
    enabled: !isNew,
    onError: error => alert(error.message)
  })

  const procedureManagementDataQuery = useQuery(
    ['procedureManagement', procedureManagementId],
    () => fetchProcedureManagement(procedureManagementId),
    {
      enabled: isProcedureManagement,
      onError: error => alert(error.message)
    }
  )

  return { formDataQuery, formDataInSpanishQuery, procedureManagementDataQuery }
}
