import PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as validations from '../../../utils/validations'
import TextWithAlternativesField from './TextWithAlternativesField'
import ModalUploadAlternatives from './ModalUploadAlternatives'
import { Button } from '../../atoms/Button'
import DropdownIndicationText from './DropdownIndicationText'

const DropdownField = ({ baseName, label }) => {
  const [openModalUploadAlternatives, setOpenModalUploadAlternatives] = useState(false)

  return (
    <>
      <TextWithAlternativesField
        baseName={baseName}
        label={label}
        alternativeValidations={validations.requiredArray('Ingresa al menos dos opciones', 2)}
        asideAlternative={
          <legend className="font-bold flex items-center">
            Alternativas
            {setOpenModalUploadAlternatives && (
              <Button className="ml-4 p-0" variant="text" onClick={() => setOpenModalUploadAlternatives(true)}>
                Cargar excel
              </Button>
            )}
          </legend>
        }
        additionalOption={<DropdownIndicationText baseName={`${baseName}.payload`} />}
      />
      {openModalUploadAlternatives && (
        <ModalUploadAlternatives setOpenModalUploadAlternatives={setOpenModalUploadAlternatives} baseName={baseName} />
      )}
    </>
  )
}

export default DropdownField

DropdownField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string
}
