import React, { useMemo, useEffect, useState } from 'react'
import Container from '../../../atoms/Container'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { useParams } from 'react-router-dom'
import FormsCondition from '../../../organisms/forms/FormsCondition'
import { initialValuesFromTemplate } from '../../../../configs/formsConfig'
import HeaderCondition from '../../../organisms/forms/HeaderCondition'
import useTemplate from '../../../../hooks/useTemplate'
import PropTypes from 'prop-types'

const FormsNewFromTemplateCondition = ({ templateId, isPreview = false }) => {
  const { template_id: paramTemplateId } = useParams()
  if (paramTemplateId) templateId = paramTemplateId

  const { data: template, status: templateStatus } = useTemplate(templateId)
  const [templateName, setTemplateName] = useState('')

  const fetchTemplateName = async () => {
    if (templateStatus === 'success' && template) {
      const templateName = await template.name
      setTemplateName(templateName)
    }
  }

  useEffect(() => {
    fetchTemplateName()
  }, [templateStatus, template])

  const initialValues = useMemo(() => {
    if (templateStatus === 'success') return initialValuesFromTemplate(template)
  }, [templateStatus])

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <HeaderCondition
          headerParams={{
            templateName
          }}
        />
        <FormsCondition
          initialValues={initialValues}
          formParams={{
            templateName
          }}
        />
      </Container>
    </>
  )
}

FormsNewFromTemplateCondition.propTypes = {
  templateId: PropTypes.string,
  isPreview: PropTypes.bool
}

export default FormsNewFromTemplateCondition
