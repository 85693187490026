import PropTypes from 'prop-types'
import React from 'react'
import ParagraphField from '../question_config_fields/ParagraphField'
import * as validations from '../../../utils/validations'
import SubjectIcon from '@material-ui/icons/Subject'
import ParagraphCitizenField from '../question_fields/ParagraphCitizenField'

const Form = props => {
  return <ParagraphField baseName={props.baseName} validate={validations.required()} />
}

const Template = props => {
  return <ParagraphCitizenField payload={props.question.payload} name={props.name} disabled />
}

const ParagraphQuestion = {
  selectOption: { value: 'paragraph', label: 'Párrafo', icon: <SubjectIcon fontSize="small" /> },
  Form,
  Template,
  name: 'ParagraphQuestion'
}

export default ParagraphQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
