import PropTypes from 'prop-types'
import React from 'react'
import TaskHistoryOriginDestinyUserLabels from './TaskHistoryOriginDestinyUserLabels'

const TaskHistoryOriginDestinyByTaskKind = ({ taskExecution }) => (
  <div className="flex items-center text-gray-500">
    {taskExecution.origin_destiny_info ? (
      <>
        Derivada desde <span className="m-1 font-bold">{taskExecution.origin_destiny_info.origin}</span> hacia{' '}
        <span className="m-1 font-bold">{taskExecution.origin_destiny_info.destiny}</span>
      </>
    ) : (
      <TaskHistoryOriginDestinyUserLabels
        originUserLabel={taskExecution.origin_user_label}
        destinyUserLabel={taskExecution.destiny_user_label}
      />
    )}
  </div>
)

export default TaskHistoryOriginDestinyByTaskKind

TaskHistoryOriginDestinyByTaskKind.propTypes = {
  taskExecution: PropTypes.object.isRequired
}
