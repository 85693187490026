import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import ButtonMui from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'

const styleByVariant = {
  primary: 'focus:bg-yellow-500 focus:text-blue-700 focus:outline-focus'
}

const useStyles = makeStyles(() => ({
  disabledButton: {
    backgroundColor: '#C5CDDA!important',
    color: '#8A94A2!important',
    borderWidth: '2px!important'
  }
}))

const typeButton = {
  primary: 'contained',
  secondary: 'outlined',
  alternative: 'bg-blue-gray-600 hover:bg-neutral-700 text-white',
  text: 'text'
}

const sizeMap = {
  xxs: 'max-w-24',
  xss: 'max-w-28',
  xs: 'max-w-32',
  s: 'max-w-40',
  m: 'max-w-56',
  l: 'max-w-72',
  xl: 'max-w-84',
  full: ''
}

export const Button = ({
  el: El = 'button',
  variant = 'primary',
  className,
  size = 'm',
  spaceSize = 'large',
  color = 'primary',
  fullWidth = true,
  classes = {},
  type = 'button',
  ...props
}) => {
  const _classes = useStyles()
  return (
    <ButtonMui
      component={El}
      variant={typeButton[variant]}
      color={color}
      fullWidth={fullWidth}
      size={spaceSize}
      classes={{
        outlined: 'border-2 bg-white hover:border-2',
        outlinedPrimary: 'border-blue-700',
        outlinedSecondary: 'border-red-800 text-red-800 hover:border-red-800 hover:bg-white',
        outlinedSizeLarge: 'px-2',
        label: 'text-center',
        disabled: clsx(variant !== 'text' && _classes.disabledButton),
        ...classes
      }}
      className={clsx(sizeMap[size], 'hover:border-2', 'font-lg rounded', styleByVariant[variant], className)}
      type={type}
      {...props}
    />
  )
}

Button.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.string,
  el: PropTypes.elementType,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  spaceSize: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string
}
