import PropTypes from 'prop-types'
import React from 'react'
import { ReactSVG } from 'react-svg'
import subtitleIcon from '../../../images/icons/subtitle.svg'
import SubtitleQuestionFields from '../question_config_fields/SubtitleQuestionFields'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import SubtitleCitizenField from '../question_fields/SubtitleCitizenField'

const Form = props => {
  return <SubtitleQuestionFields baseName={props.baseName} />
}

const Answer = props => {
  return <BoldLabelAndContent label={props.question.payload?.label} content={props.answer} className="mb-3 mt-8" />
}

const Template = props => {
  return <SubtitleCitizenField payload={props.question.payload} className="pb-4" />
}

const SubtitleQuestion = {
  selectOption: { value: 'subtitle', label: 'Subtítulo', icon: <ReactSVG src={subtitleIcon} /> },
  Form,
  Template,
  Answer,
  TaskResponse: Answer,
  name: 'SubtitleQuestion',
  subtype: 'Subtítulo'
}

export default SubtitleQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Answer.propTypes = {
  answer: PropTypes.object,
  question: PropTypes.object.isRequired
}

Template.propTypes = {
  question: PropTypes.object.isRequired
}
