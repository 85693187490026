import PropTypes from 'prop-types'
import React from 'react'
import { numberFormatter, addYAtTheEnd } from '../../../utils/helpers'
import { BankTransferenceInformationCitizen } from './BankTransferenceInformationCitizen'
import FileField from '../../molecules/fields/FileField'

const extensions = ['pdf', 'jpeg', 'jpg', 'png', 'tif', 'bmp']

const TransferAccountsProcedure = ({ cost, paymentTransferAccounts }) => {
  return (
    <>
      <h3 className="font-semibold mb-1">2. Información para el pago</h3>
      <p className="mb-4 mb-2">Realiza el pago de {numberFormatter.currency(cost)} a la siguiente cuenta bancaria:</p>
      <BankTransferenceInformationCitizen paymentTransferAccounts={paymentTransferAccounts} />
      <h3 className="font-semibold mb-1">3. Comprobante de pago</h3>
      <p className="mb-4 mb-2">
        Carga tu comprobante de pago, considerando que la información debe ser legible. Solo se aceptan formatos{' '}
        {addYAtTheEnd(extensions)}. Peso máximo: 10 MB.
      </p>
      <FileField
        name="bank_transfer_payment_receipt"
        className="mb-6"
        required
        ariaLabel="Comprobante de pago"
        extensions={extensions}
      />
    </>
  )
}
export default TransferAccountsProcedure

TransferAccountsProcedure.propTypes = {
  cost: PropTypes.number,
  paymentTransferAccounts: PropTypes.array
}
