import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import * as validations from '../../../utils/validations'
import TextField from '../../molecules/fields/TextField'
import { Button } from '../../atoms/Button'
import useCountDown from '../../../hooks/useCountDown'
import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { secondsToMinutes } from '../../../utils/helpers'
import { useTranslation } from 'react-i18next'
import useTextValidation from '../../../hooks/useTextValidation'

const sendValidationCode = (answerCode, type) =>
  axios.post(`/v1/answers/${answerCode}/send_validation_code.json`, { type, sisoli: 'sisoli' }).then(res => res.data)

const ValidateEmailOrPhoneForm = ({ answer, title, textFieldLabel, onBack, submitLabel, onSubmit, type }) => {
  const { t } = useTranslation(['page_tracking', 'button'])
  const translationText = useTextValidation()
  const queryCache = useQueryClient()
  const [isCountdownVisible, setIsCountdownVisible] = useState(true)
  const [resend, setResend] = useState(false)

  const { data: validationCode, status } = useQuery(
    ['validation_code', answer.hashid, type],
    () => sendValidationCode(answer.hashid, type),
    { refetchOnWindowFocus: false }
  )

  if (status !== 'success') return null
  const emailPhoneApplicantResponse = answer.email_phone_applicant_response

  const handleFormSubmit = values => {
    return onSubmit(validationCode.key, values.validation_code)
  }

  const handleResendValidationCode = () => {
    queryCache.invalidateQueries(['validation_code', answer.hashid])
    setIsCountdownVisible(true)
    setResend(true)
  }
  return (
    <div className="flex flex-col md:max-w-144 m-auto">
      {title && <div className="font-bold text-2xl mb-6">{title}</div>}
      <Form onSubmit={handleFormSubmit}>
        {({ handleSubmit, submitting }) => (
          <form className="bg-neutral-100 p-6" onSubmit={handleSubmit}>
            <TextField
              name="validation_code"
              label={
                <div className="font-normal">
                  {textFieldLabel} <span className="font-bold">{emailPhoneApplicantResponse.value}</span>
                </div>
              }
              size="full"
              validate={validations.mix(
                validations.required(translationText.required),
                validations.isNumber(translationText.isNumber),
                validations.length(6, translationText.lenght(6))
              )}
              className="text-3xl text-center tracking-widest font-bold"
              maxLength={6}
            />
            <p className="mt-4">{t('text_didnt_you_get_code')}</p>
            <div className="mb-4">
              {isCountdownVisible ? (
                <Countdown onEndTime={() => setIsCountdownVisible(false)} />
              ) : (
                <button className="font-bold text-blue-700" onClick={handleResendValidationCode} type="button">
                  {t('text_send_it_again')}
                </button>
              )}
            </div>
            {resend && (
              <p className="flex items-center mb-4 text-green-700 font-bold">
                <CheckCircleOutlineIcon color="inherit" fontSize="small" className="mr-2" /> {t('text_code_sent')}
              </p>
            )}
            <div className="flex flex-col-reverse md:flex-row justify-end">
              <div className="md:w-1/2 md:mr-3">
                <Button onClick={onBack} variant="secondary" className="" size="full">
                  {t('button_back', { ns: 'button' })}
                </Button>
              </div>
              <div className="md:w-1/2 mb-3 md:mb-0">
                <Button disabled={submitting} size="full" type="submit">
                  {submitLabel}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}
export default ValidateEmailOrPhoneForm

const Countdown = ({ onEndTime }) => {
  const timeLeft = useCountDown(60, onEndTime)
  const { t } = useTranslation('page_tracking')
  return (
    <>
      {t('text_you_can_send_it_again_in')} <span className="font-bold">{secondsToMinutes(timeLeft)}</span>
    </>
  )
}

ValidateEmailOrPhoneForm.propTypes = {
  answer: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  textFieldLabel: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
}

Countdown.propTypes = {
  onEndTime: PropTypes.func.isRequired
}
