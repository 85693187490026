import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import SelectField from '../../molecules/fields/SelectField'
import { HtmlField } from '../../molecules/fields/HtmlField'
import ScrollToTop from '../../molecules/ScrollToTop'
import FormSection from '../../molecules/FormSection'
import CheckboxField from '../../molecules/fields/CheckboxField'
import SaveButton from '../../molecules/buttons/SaveButton'
import { Form as FinalForm } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import FormsHeader from '../../molecules/FormsHeader'
import arrayMutators from 'final-form-arrays'
import { dataToOptions } from '../../../utils/helpers'
import { FORM_ERROR } from 'final-form'
import { FormControl, FormLabel } from '@material-ui/core'
import Sections from './Sections'
import { useHistory } from 'react-router-dom'
import { customSwapMutator } from '../../../utils/formMutators'
import useCategory from '../../../hooks/useCategory'
import Error from '../../molecules/Error'
import { checkboxPermissions } from '../../../configs/onboardingConfig'

const tutorialSteps = [{ label: 'Redacta el contenido' }, { label: 'Vista previa y publicación' }]

const Form = ({ headerInfo, initialValues, mutateForm, isTutorial, edit = false }) => {
  const history = useHistory()
  const [uploadProgress, setUploadProgress] = useState(null)
  const { getCategories } = useCategory()
  const { data: categories } = getCategories()

  useEffect(() => {
    if (uploadProgress === 100) setTimeout(() => setUploadProgress(null), 1000)
  }, [uploadProgress])

  const handleSubmit = async values => {
    try {
      await mutateForm(
        values,
        {
          onSuccess: data => history.push(`${data.url}/vista-previa?action=${edit ? 'edit' : 'new'}`)
        },
        {
          onUploadProgress: progressEvent =>
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
      )
    } catch (error) {
      if (error.response.data.error) {
        return { [FORM_ERROR]: error.response.data.error }
      }
    }
  }

  return (
    <>
      <FormsHeader
        title={headerInfo.title}
        hint={headerInfo.hint}
        steps={tutorialSteps}
        activeStep={tutorialSteps[0]}
      />
      <FinalForm
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
          swap: customSwapMutator
        }}
        decorators={[focusOnFirstError]}
        initialValues={initialValues}
        subscription={{ submitting: true }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <FormSection title="Definiciones generales">
              <TextField
                name="name"
                label={`Nombre del ${isTutorial ? 'tutorial' : 'artículo'}`}
                size="2xl"
                validate={validations.required()}
              />
              <HtmlField
                name="description"
                label="Descripción general"
                validate={validationsAdmin.isRichTextFieldEmpty()}
                maxContentLength={0}
              />
              {isTutorial && (
                <>
                  <SelectField
                    name="category_id"
                    label="Categoría"
                    options={dataToOptions(categories)}
                    size="2xl"
                    validate={validations.required()}
                  />
                  <FormControl>
                    <FormLabel component="label" className="font-bold text-gray-800 text-base mb-1 min-w-100">
                      Funcionarios que podrán ver el tutorial
                    </FormLabel>
                    {checkboxPermissions().map(permission => (
                      <CheckboxField
                        key={permission.code}
                        margin="none"
                        name="permissions"
                        value={permission.code}
                        label={permission.pluralName}
                        validate={validations.requiredArray('Ingresa al menos una opción.', 1)}
                        hideError
                      />
                    ))}
                    <Error name="permissions" />
                  </FormControl>
                </>
              )}
            </FormSection>
            <FormSection title="Redacta el contenido por secciones">
              <Sections />
            </FormSection>
            <SaveButton label="Guardar y ver vista previa" disabled={submitting} uploadProgress={uploadProgress} />
            <ScrollToTop />
          </form>
        )}
      </FinalForm>
    </>
  )
}
export default Form

Form.propTypes = {
  edit: PropTypes.bool,
  headerInfo: PropTypes.object,
  initialValues: PropTypes.object,
  isTutorial: PropTypes.bool,
  mutateForm: PropTypes.func.isRequired
}
