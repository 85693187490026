import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import ConnectionsAndRulesForm from './ConnectionsAndRulesForm'
import { ProcedureManagementContext } from '../../../utils/contexts'

const fetchTask = (procedureManagementId, id) =>
  axios.get(`/admin/v1/procedure_managements/${procedureManagementId}/tasks/${id}.json`).then(res => res.data)
const updateTask = (procedureManagementId, id) => values =>
  axios.put(`/admin/v1/procedure_managements/${procedureManagementId}/tasks/${id}.json`, values).then(res => res.data)

const ConnectionsAndRulesModal = ({ taskId, onClose: close, onAfterSave: afterSave }) => {
  const procedureManagementId = useContext(ProcedureManagementContext)
  const { data: task, status } = useQuery(
    ['task', procedureManagementId, taskId],
    () => fetchTask(procedureManagementId, taskId),
    {
      refetchOnWindowFocus: false
    }
  )
  const queryClient = useQueryClient()
  const { mutate } = useMutation(updateTask(procedureManagementId, taskId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['task', procedureManagementId, taskId])
      afterSave()
    }
  })
  if (status !== 'success') return null
  return (
    <ModalWithAnimation
      size="full"
      open
      className="md:px-6 h-screen"
      modalClassName="justify-end md:pl-10"
      animation="slideLeft"
    >
      <ConnectionsAndRulesForm mutate={mutate} task={task} onClose={close} />
    </ModalWithAnimation>
  )
}
export default ConnectionsAndRulesModal

ConnectionsAndRulesModal.propTypes = {
  onAfterSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  taskId: PropTypes.number
}
