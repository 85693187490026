import PropTypes from 'prop-types'
import React from 'react'
import SelectField from '../../molecules/fields/SelectField'
import {
  getLabel,
  getQuestionsPreviousSteps,
  questionTypesGroupInclude
} from '../../../configs/questionTypesAdminConfig'
import * as validations from '../../../utils/validations'
import { getIndexSubtype } from '../../../configs/questionTypesCitizenConfig'

export const KeyQuestion = ({ steps }) => {
  const getKeyQuestionOptions = () => {
    const keyQuestionOptions = []
    steps.forEach((step, stepIndex) => {
      const questions = step.questions_attributes
      const questionsPreviousSteps = getQuestionsPreviousSteps(steps, stepIndex)
      questions.forEach((question, index) => {
        if (questionTypesGroupInclude('general', question.kind) && !question._destroy) {
          const position = getIndexSubtype({ questionsPreviousSteps, questions, index })
          const label = `${position}. ${getLabel(question)}`
          keyQuestionOptions.push({ value: question.key, label })
        }
      })
    })
    return keyQuestionOptions
  }

  return (
    <SelectField
      name="key_question"
      options={getKeyQuestionOptions()}
      containerClassName="mt-2"
      defaultSelection="Selecciona una pregunta"
      validate={validations.required()}
    />
  )
}

KeyQuestion.propTypes = {
  steps: PropTypes.array.isRequired
}
