import PropTypes from 'prop-types'
import React from 'react'
import ModalWithAnimation from './ModalWithAnimation'
import { Button } from '../../atoms/Button'
import { Close } from '@material-ui/icons'

const AcceptCloseModal = ({ title, buttonLabel = 'Aceptar', onAccept, onClose, children, open }) => {
  return (
    <ModalWithAnimation size="m" open={open}>
      <div className="text-right">
        <Button
          type="button"
          variant="text"
          size="xs"
          className="font-bold -right-6"
          endIcon={<Close />}
          onClick={onClose}
        >
          Cerrar
        </Button>
      </div>
      <div className="w-full">
        <h2 className="header font-bold text-xl mb-4 text-center">{title}</h2>
        <div className="body overflow-y-auto max-h-96 md:max-h-168 mb-6 flex justify-center text-center px-6 md:px-0">
          {children}
        </div>
        <div className="footer flex justify-center">
          <Button type="button" size="m" onClick={onAccept} data-testid="confirm-button">
            {buttonLabel}
          </Button>
        </div>
      </div>
    </ModalWithAnimation>
  )
}
export default AcceptCloseModal

AcceptCloseModal.propTypes = {
  buttonLabel: PropTypes.string,
  children: PropTypes.node,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string
}
