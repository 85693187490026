import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'

const SearchField = ({
  placeholder = 'Buscar por nombre o ID',
  containerClassName = 'sm:max-w-95 md:w-95',
  name = 'search'
}) => {
  const { input } = useField(name, { parse: a => a })
  const handleChange = () => input.onChange(input.value)

  return (
    <div
      className={clsx(
        'border-2 border-gray-800 w-full flex justify-between focus-within:border-yellow-500  rounded',
        containerClassName
      )}
    >
      <input
        placeholder={placeholder}
        {...input}
        className="text-sm md:text-base pl-2 w-full focus:outline-none placeholder-gray-500 h-12"
      />
      <button type="button" onClick={handleChange} className="p-1 bg-white">
        <SearchIcon className="bg-gray-800 text-white p-1 border border-white text-4xl rounded" />
      </button>
    </div>
  )
}

export default SearchField

SearchField.propTypes = {
  containerClassName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string
}
