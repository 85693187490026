import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import * as validations from '../../../utils/validations'
import TextField from '../../molecules/fields/TextField'
import SelectField from '../../molecules/fields/SelectField'
import { Button } from '../../atoms/Button'
import { Form } from 'react-final-form'
import { useMutation } from 'react-query'
import axios from 'axios'
import { getColorOptions } from '../../../configs/procedureManagementsConfig'
import { ProcedureManagementContext } from '../../../utils/contexts'

const createSate = procedureManagementId => values =>
  axios.post(`/admin/v1/procedure_managements/${procedureManagementId}/states.json`, values).then(res => res.data)

const StatesForm = ({ onClose: close, onCreate }) => {
  const procedureManagementId = useContext(ProcedureManagementContext)
  const { mutate } = useMutation(createSate(procedureManagementId), {
    onSuccess: state => onCreate(state)
  })

  const save = values => mutate(values)
  return (
    <Form onSubmit={save}>
      {({ handleSubmit, values, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div className="bg-neutral-200 p-4">
            <div className="font-bold text-xl mb-2">Crear nuevo estado</div>
            <TextField
              name="name"
              label="Nombre del estado"
              margin="dense"
              size="full"
              validate={validations.required()}
            />
            <SelectField
              name="color"
              label="Color de etiqueta"
              options={getColorOptions()}
              size="full"
              validate={validations.required()}
              emptyLabel="Selecciona un color"
            />
            <div className="flex flex-col-reverse lg:flex-row justify-end">
              <Button variant="text" className="md:max-w-8 md:mr-3" size="full" onClick={() => close()}>
                Cancelar
              </Button>
              <Button className="md:max-w-12 mb-3 md:mb-0" size="full" onClick={() => save(values)} disabled={invalid}>
                Guardar estado
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}
export default StatesForm

StatesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
}
