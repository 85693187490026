import PropTypes from 'prop-types'
import React from 'react'
import TitleQuestion from './TitleQuestion'
import QuestionComponentMounter from '../question_types_admin/QuestionComponentMounter'
import { PaperContainerErrorsHandler } from './PaperContainerErrorsHandler'

const QuestionCardSubtitleFromTemplate = ({
  baseName,
  index,
  fields,
  parentQuestionIndex,
  questionsPreviousSteps,
  stepIndex
}) => {
  const baseNameIndex = `${baseName}[${index}]`
  const question = fields.value[index]
  return (
    <PaperContainerErrorsHandler>
      <div className="pt-4 bg-neutral-300">
        <div className="px-4">
          <TitleQuestion
            index={index}
            questions={fields.value}
            parentQuestionIndex={parentQuestionIndex}
            questionsPreviousSteps={questionsPreviousSteps}
            size="s"
          />
        </div>
        <div className="px-4">
          <QuestionComponentMounter
            index={index}
            question={question}
            name={`${baseNameIndex}.question`}
            baseName={baseNameIndex}
            dispatch="Template"
            kind={question.kind}
            stepIndex={stepIndex}
          />
        </div>
      </div>
    </PaperContainerErrorsHandler>
  )
}
export default QuestionCardSubtitleFromTemplate

QuestionCardSubtitleFromTemplate.propTypes = {
  baseName: PropTypes.string,
  fields: PropTypes.exact({
    value: PropTypes.array
  }),
  index: PropTypes.number,
  parentQuestionIndex: PropTypes.number,
  questionsPreviousSteps: PropTypes.array,
  stepIndex: PropTypes.number
}
