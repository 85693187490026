import React from 'react'
import useSubscription from '../../../hooks/useSubscription'
import NotificationsTray from './NotificationsTray'
import { Link } from 'react-router-dom'

const NotificationsTraySocket = () => {
  const { streamData, channel } = useSubscription('NotificationsChannel')
  const notifications = (streamData?.notifications || []).map(notification => ({
    ...notification,
    type: 'notification',
    el: 'a'
  }))
  const formNotifications = (streamData?.form_to_notify || []).map(notification => ({
    ...notification,
    type: 'form',
    el: Link
  }))

  const orderedNotifications = [...notifications, ...formNotifications].sort(
    (a, b) => new Date(b.date_send) - new Date(a.date_send)
  )

  const handleViewNotifications = () => {
    channel.send({ action: 'view_notifications', notifications: streamData.notifications })
  }

  const handleNotificationClick = notification => {
    if (notification.type === 'form') {
      channel.send({ action: 'click_form_notification', notification_id: notification.id })
    } else if (notification.type === 'notification') {
      channel.send({ action: 'click_notification', notificationId: notification.id })
    }
  }

  return (
    <NotificationsTray
      onViewNotifications={handleViewNotifications}
      onNotificationClick={handleNotificationClick}
      notifications={orderedNotifications}
      notViewed={streamData?.not_viewed}
    />
  )
}

export default NotificationsTraySocket
