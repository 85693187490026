import React from 'react'
import { useAnswer } from '../../../hooks/useAnswer'
import TransferAccountsShow from '../payments/TransferAccountsShow'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

export const BankTransferPaymentAnswer = () => {
  const { form_id: formId, answer_id: answerId } = useParams()
  const queryClient = useQueryClient()
  const { data: answer, isLoading } = useAnswer()
  if (isLoading) return null
  return (
    <li className="flex">
      <TransferAccountsShow
        answer={answer}
        onUpdateState={() => queryClient.invalidateQueries([formId, answerId, 'answer'])}
        rejectedMessage="Debido a que el pago ha sido rechazado, deberás ponerte en contacto con el usuario para informarle
                      el motivo, y darle las indicaciones para que subsane el error."
        btnLabelReject="Enviar notificación de rechazo por correo"
      />
    </li>
  )
}
