import { useQuery } from 'react-query'
import axios from 'axios'

const fetchProcedureManagement = id => axios.get(`/admin/v1/procedure_managements/${id}.json`).then(a => a.data)

const useProcedureManagement = procedureManagementId => {
  return useQuery('procedure_management', () => fetchProcedureManagement(procedureManagementId), {
    refetchOnWindowFocus: false
  })
}
export default useProcedureManagement
