import React from 'react'
import FieldsSeparator from '../../../atoms/FieldsSeparator'
import Container from '../../../atoms/Container'
import Title from '../../../atoms/Title'
import { step1, step2, step3 } from '../../../../utils/quickSearchesSamples'
import DescriptionCarousel from '../../../molecules/DescriptionCarousel'

const steps = [
  {
    hint: (
      <>
        Ciudadano <span className="font-bold">coloca su DNI</span> para verificar si ha sido beneficiado.
      </>
    ),
    page: step1
  },
  {
    hint: (
      <>
        Ciudadano <span className="font-bold">recibe respuesta afirmativa,</span> con fecha y lugar de recojo
      </>
    ),
    page: step2
  },
  {
    hint: (
      <>
        Ciudadano <span className="font-bold">recibe respuesta negativa.</span>
      </>
    ),
    page: step3
  }
]

const QuickSearchExample = () => {
  return (
    <>
      <Container className="mt-12 px-4 md:px-0">
        <Title aria-describedby="page-description">¿Qué es una consulta rápida?</Title>
        <p id="page-description" className="mb-6">
          Es una página en la que el ciudadano puede realizar consultas sobre temas puntuales que tu entidad pone a su
          disposición. Para ello, se le solicita ingresar un documento de identidad y, si lo deseas, un dato de
          validación. Por ejemplo, puede consultar si se encuentra en algún padrón, si es beneficiario de un bono o si
          tiene una deuda pendiente; así como resultados de concursos, convocatorias de trabajo, etc.
        </p>
        <FieldsSeparator />
        <p className="mb-8">
          <strong className="font-bold">
            Ejemplo: página para consultar un padrón de beneficiarios de una canasta básica familiar:{' '}
          </strong>
        </p>
      </Container>
      <DescriptionCarousel steps={steps} />
    </>
  )
}
export default QuickSearchExample
