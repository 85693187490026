import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

const HiddenField = ({ name, value }) => (
  <Field name={name}>
    {({ input }) => {
      input.onChange(value)
      return <input type="hidden" {...input} />
    }}
  </Field>
)

HiddenField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string
}

export default HiddenField
