import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import SelectField from '../../molecules/fields/SelectField'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { useQueryClient } from 'react-query'
import * as validations from '../../../utils/validations'
import StatesForm from './StatesForm'
import { useForm } from 'react-final-form'
import useStates from '../../../hooks/useStates'
import useFieldValue from '../../../hooks/useFieldValue'
import Error from '../../molecules/Error'
import { getStateOptions } from '../../../configs/procedureManagementsConfig'
import axios from 'axios'

const StateSection = ({ label, name }) => {
  const { change } = useForm()
  const [state, setState] = useState('')
  const procedureManagementId = useFieldValue('procedure_management_id')
  const taskId = useFieldValue('id')
  const { data: states, status } = useStates(procedureManagementId)
  const clear = () => {
    setState('')
    change(name, null)
  }
  const stateValue = useFieldValue(name)
  useEffect(() => {
    stateValue ? setState('select') : setState('')
  }, [])
  const queryClient = useQueryClient()
  const handleDestroy = async state => {
    if (window.confirm(`¿Estás seguro que deseas eliminar el estado ${state.name}?`)) {
      try {
        await axios.delete(`/admin/v1/procedure_managements/${procedureManagementId}/states/${state.id}.json`, {
          data: { taskId }
        })
        queryClient.invalidateQueries(['states', procedureManagementId])
        change(name, null)
      } catch (error) {
        window.alert('No es posible borrar este estado debido a que está siendo usado en otras tareas')
      }
    }
  }

  if (status !== 'success') return null
  return (
    <>
      <div className="relative js-field-container px-4 md:px-6 pb-4">
        <div className="flex items-end">
          <SelectField
            label={label}
            name={name}
            options={getStateOptions(states, handleDestroy)}
            containerClassName="flex-1"
            className={state !== 'select' ? 'hidden' : null}
            size="full"
            validate={validations.required('Selecciona un estado')}
            emptyLabel="Selecciona un estado"
            hideError
            margin="none"
          />
          <IconButton type="button" color="primary" onClick={clear} className={state !== 'select' ? 'hidden' : null}>
            <DeleteIcon />
          </IconButton>
        </div>
        {state === '' && (
          <>
            <button className="font-bold mb-2 block text-blue-700" onClick={() => setState('select')} type="button">
              Seleccionar estado
            </button>
            <button className="font-bold text-blue-700" onClick={() => setState('new')} type="button">
              Crear nuevo estado
            </button>
          </>
        )}
        <Error name={name} />
      </div>
      {state === 'new' && (
        <StatesForm
          onClose={clear}
          onCreate={state => {
            queryClient.invalidateQueries(['states', procedureManagementId])
            change(name, state.id)
            setState('select')
          }}
        />
      )}
    </>
  )
}
export default StateSection

StateSection.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string
}
