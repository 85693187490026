import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import SectionTitle from './SectionTitle'
import useCategory from '../../../hooks/useCategory'

const TutorialCategoriesList = ({ className }) => {
  const { getCategories } = useCategory()
  const { data: categories } = getCategories()

  return (
    <div className={clsx('bg-neutral-100 p-6', className)}>
      <div className="mb-6 border-solid border-b-2 border-neutral-400">
        <SectionTitle title="Tutoriales" description="¿Qué puedo hacer en Facilita?">
          <Button
            className="md:w-37.5"
            size="full"
            fullWidth={false}
            variant="secondary"
            component={Link}
            to={`/centro-de-ayuda/tutoriales`}
          >
            Ver todo
          </Button>
        </SectionTitle>
      </div>
      <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-3">
        {categories?.map(category => (
          <Link
            key={category.id}
            to={`/centro-de-ayuda/tutoriales?category_t[]=${category.id}&filter_open=true`}
            className="text-center border-solid border-2 border-neutral-400 md:text-lg rounded-lg leading-13.5 md:leading-20 bg-white font-bold"
          >
            {category.name}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default TutorialCategoriesList

TutorialCategoriesList.propTypes = {
  className: PropTypes.string
}
