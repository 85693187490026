import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import clsx from 'clsx'

const Paper = ({ className, depth = 'xl', namesWithError, ...props }) => {
  const paperEl = useRef(null)

  return (
    <div
      ref={paperEl}
      className={clsx(
        `shadow-sm hover:shadow-${depth} rounded`,
        className,
        namesWithError.length > 0 && 'border-2 border-solid border-red-700'
      )}
      {...props}
    />
  )
}

export default Paper

Paper.propTypes = {
  className: PropTypes.string,
  depth: PropTypes.string,
  namesWithError: PropTypes.array.isRequired
}
