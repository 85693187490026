import React from 'react'
import PropTypes from 'prop-types'
import CopyText from './buttons/CopyText'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: { textWrap: 'wrap' }
}))

const CodeBlock = ({ text }) => {
  const classes = useStyles()
  return (
    <div className="bg-neutral-800 p-3 relative w-full min-h-16">
      <CopyText
        copy={text}
        mode="dark"
        size="s"
        ariaLabel="Copiar"
        className="px-3 py-2 absolute top-3 right-3 text-sm"
        iconClassName="text-sm"
      />
      <pre className={clsx('text-white font-normal break-all mt-6 md:mt-0 md:mr-24', classes.root)}>
        <code>{text}</code>
      </pre>
    </div>
  )
}

export default CodeBlock

CodeBlock.propTypes = {
  text: PropTypes.string
}
