import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Header from '../components/molecules/Header'
import '../stylesheets/application'
import Home from '../components/pages/citizens/Home'
import Login from '../components/pages/citizens/Login'
import Theme from '../components/molecules/Theme'
import Analytics from '../components/molecules/Analytics'
import Auth, { useAuth } from '../components/molecules/Auth'
import PrivateRoute from '../components/molecules/PrivateRoute'
import Feedback from '../components/organisms/feedbacks/Feedback'
import ScrollToTop from '../components/molecules/ScrollToTop'
import { DetectRouteChange } from '../components/molecules/DetectRouteChange'
import { QueryClient, QueryClientProvider } from 'react-query'
import AlertContainer from '../components/organisms/forms/AlertContainer'
import areasUsersRoute from '../hooks/routes/useAreasUsersRoute'
import feedbacksRoute from '../hooks/routes/useFeedbacksRoute'
import formsRoute from '../hooks/routes/useFormsRoute'
import termsRoute from '../hooks/routes/useTermsRoute'
import institutionsRoute from '../hooks/routes/useInstitutionsRoute'
import onboardingsRoute from '../hooks/routes/useOnboardingsRoute'
import proceduresManagementsRoute from '../hooks/routes/useProceduresManagementsRoute'
import quickSearchesRoute from '../hooks/routes/useQuickSearchesRoute'
import templatesRoute from '../hooks/routes/useTemplatesRoute'
import statisticsRoute from '../hooks/routes/useStatisticsRoute'
import paymentRoute from '../hooks/routes/usePaymentRoute'
import branchOfficeRoute from '../hooks/routes/useBranchOfficeRoute'
import announcementsRoute from '../hooks/routes/useAnnouncementsRoute'
import '../configs/i18n'
import AdminNotFound from '../components/pages/admin/AdminNotFound'
import AdminNotPermitted from '../components/pages/admin/AdminNotPermitted'
import InternalFooter from '../components/organisms/footer/InternalFooter'

require('@rails/activestorage').start()
require('channels')

const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = csrf

const queryClient = new QueryClient()

const App = () => {
  const auth = useAuth()

  return (
    <>
      <Analytics />
      <ScrollToTop />
      <DetectRouteChange />
      <ReactQueryDevtools initialIsOpen={false} />
      <Header />
      <AlertContainer>
        <main className="flex-1">
          <Switch>
            {auth &&
              [
                ...areasUsersRoute(),
                ...feedbacksRoute(),
                ...formsRoute(),
                ...termsRoute(),
                ...institutionsRoute(),
                ...onboardingsRoute(),
                ...proceduresManagementsRoute(),
                ...quickSearchesRoute(),
                ...templatesRoute(),
                ...statisticsRoute(),
                ...paymentRoute(),
                ...branchOfficeRoute(),
                ...announcementsRoute()
              ].map(({ path, component: Element, props, hasPermission, hasFeedback = false }) => {
                return (
                  <PrivateRoute exact path={path} key={path}>
                    {hasPermission ? <Element {...props} /> : <AdminNotPermitted />}
                    {hasFeedback && <Feedback senderType="public_entity" />}
                    <InternalFooter />
                  </PrivateRoute>
                )
              })}
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/*">
              <AdminNotFound />
            </Route>
          </Switch>
        </main>
      </AlertContainer>
    </>
  )
}

const AppWrapper = () => {
  return (
    <Theme>
      <Auth>
        <Router basename="admin">
          <App />
        </Router>
      </Auth>
    </Theme>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<div></div>}>
        <AppWrapper />
      </Suspense>
    </QueryClientProvider>,
    document.getElementById('root')
  )
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
