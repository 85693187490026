import { useAuth } from '../../components/molecules/Auth'
import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'

const { admin, coordinator, branch_coordinator: branchCoordinator } = roles

const General = () => {
  const { user } = useAuth()
  const hasRolePermissions = useHasRolePermissions()

  const canFilterByInstitution = hasRolePermissions([admin])
  const canFilterByBranchOffice = hasRolePermissions([
    admin,
    { rolePermission: coordinator, additionalCondition: user?.institution_has_branch_offices }
  ])
  const canFilterAndHasNotBranchOffices = hasRolePermissions([coordinator]) && !user?.institution_has_branch_offices
  const canSeeBranchRecords = hasRolePermissions([branchCoordinator])

  return { canFilterByInstitution, canFilterByBranchOffice, canFilterAndHasNotBranchOffices, canSeeBranchRecords }
}

export default General
