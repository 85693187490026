import PropTypes from 'prop-types'
import React from 'react'
import Header from '../Header'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { Button } from '../../atoms/Button'
import filterIcon from '../../../images/icons/filter.svg'
import { ReactSVG as Svg } from 'react-svg'
import useToggle from '../../../hooks/useToggle'
import { Dialog } from '@material-ui/core'

const FilterMobile = props => {
  const [toggleFilter, settoggleFilter] = useToggle(false)
  return (
    <div className="md:hidden">
      <button type="button" onClick={() => settoggleFilter()} className="mb-6 text-blue-700 font-bold flex">
        <Svg src={filterIcon} className="mt-1 mr-2" />
        Filtros de búsqueda
      </button>
      <Dialog open={toggleFilter} onClose={() => settoggleFilter()} className="md:hidden" fullScreen>
        <div className="bg-white font-bold">
          <Header />
          <button onClick={() => settoggleFilter()} className="text-blue-700 font-bold py-2 px-6">
            <ChevronLeft /> Atrás
          </button>
          <div className="bg-neutral-100 p-6">{props.children}</div>
          <div className="flex justify-center ">
            <Button className="max-w-xs my-4" onClick={() => settoggleFilter()}>
              Mostrar Resultados
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

const Filter = ({ children }) => {
  return (
    <aside className="text-gray-800 text-base">
      <div className="hidden md:block">
        <h3 className="font-bold mb-4 flex items-center border-gray-800 pb-3 md:text-xl">Filtros de búsqueda</h3>
        {children}
      </div>
      <FilterMobile>{children}</FilterMobile>
    </aside>
  )
}

export default Filter

FilterMobile.propTypes = {
  children: PropTypes.node
}

Filter.propTypes = {
  children: PropTypes.node
}
