import PropTypes from 'prop-types'
import React from 'react'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioCitizenField from '../question_fields/RadioCitizenField'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import RadioDoubleField from '../../organisms/question_config_fields/RadioDoubleField'

const Form = props => {
  return <RadioDoubleField baseName={props.baseName} />
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <RadioDoubleField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <RadioCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const Answer = props => {
  const content = props.answer?.map((item, key) => <p key={key}>{item}</p>)
  return <BoldLabelAndContent label={`${props.question.label}:`} content={content} el="li" />
}

const RadioDoubleQuestion = {
  selectOption: {
    value: 'radio_double',
    label: 'Matriz con botones (selección única)',
    icon: <RadioButtonCheckedIcon fontSize="small" />
  },
  Form,
  Template,
  Answer,
  name: 'RadioDoubleQuestion'
}

export default RadioDoubleQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}

Answer.propTypes = {
  answer: PropTypes.array,
  question: PropTypes.object.isRequired
}
