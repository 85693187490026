import useFieldValue from './useFieldValue'
import { getQuestionsPreviousSteps } from '../configs/questionTypesAdminConfig'
import { isLast as isLastField, isFirst as isFirstField, getIndexToShow } from '../utils/destroyableFields'

const useCurrentStep = index => {
  const stepsValue = useFieldValue('steps_attributes')
  const questionsPrevious = index !== undefined ? getQuestionsPreviousSteps(stepsValue, index) : []
  const isFirst = index !== undefined ? isFirstField(stepsValue, index) : true
  const isLast = index !== undefined ? isLastField(stepsValue, index) : true
  const indexToShow = getIndexToShow(stepsValue, index)

  return { index, indexToShow, questionsPrevious, isFirst, isLast }
}
export default useCurrentStep
