import PropTypes from 'prop-types'
import React from 'react'
import { TagBox } from '../../molecules/TagBox'
import { Button } from '../../atoms/Button'
import useUser, { urlFetch } from '../../../hooks/useUser'
import useMutation from '../../../hooks/useMutation'
import { urlToJson } from '../../../utils/helpers'

const enabledText = {
  true: { label: 'Activo', tagType: 'success', changeText: 'Inactivar' },
  false: { label: 'Inactivo', tagType: 'neutral', changeText: 'Activar' }
}

const UserStatus = ({ user }) => {
  const status = enabledText[user.enabled]
  const { updateEnabledUser, invalidateUsers } = useUser()
  const { mutate: handleEnabledChange } = useMutation(updateEnabledUser(user.id, !user.enabled), {
    onSuccess: () => {
      invalidateUsers(urlToJson(urlFetch))
    }
  })

  return (
    <div className="flex md:flex-row flex-col md:items-center items-start gap-2">
      <TagBox className="text-sm" label={status.label} tagType={status.tagType} />
      <Button variant="text" className="text-sm w-fit" spaceSize="small" size="full" onClick={handleEnabledChange}>
        {status.changeText}
      </Button>
    </div>
  )
}

export default UserStatus

UserStatus.propTypes = {
  user: PropTypes.object
}
