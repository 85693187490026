import PropTypes from 'prop-types'
import React, { useContext, useMemo, useState } from 'react'
import { Button } from '../../atoms/Button'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import AddIcon from '@material-ui/icons/Add'
import TaskForm from './TaskForm'
import axios from 'axios'
import { useMutation } from 'react-query'
import * as uuid from 'uuid'
import { ProcedureManagementContext } from '../../../utils/contexts'

const createTask = procedureManagementId => values =>
  axios.post(`/admin/v1/procedure_managements/${procedureManagementId}/tasks.json`, values).then(res => res.data)

const TaskFormNew = ({ userType, onAfterSave: afterSave }) => {
  const procedureManagementId = useContext(ProcedureManagementContext)
  const [openTaskModal, setOpenTaskModal] = useState(false)
  const { mutate } = useMutation(createTask(procedureManagementId), {
    onSuccess: task => {
      setOpenTaskModal(false)
      afterSave(task)
    }
  })
  const initialValues = useMemo(
    () => ({
      procedure_management_id: procedureManagementId,
      origin_user_type_id: userType.id,
      steps_attributes: [{ questions_attributes: [{ key: uuid.v4() }] }],
      kind: 'normal'
    }),
    []
  )
  return (
    <div className="text-center">
      <Button size="l" variant="text" startIcon={<AddIcon />} onClick={() => setOpenTaskModal(true)}>
        Crear tarea
      </Button>
      <ModalWithAnimation
        size="full"
        open={openTaskModal}
        className="md:px-6 h-screen"
        modalClassName="justify-end md:pl-10"
        animation="slideLeft"
      >
        <TaskForm
          title={`Crear nueva tarea para ${userType.name}`}
          buttonLabel="Crear tarea"
          initialValues={initialValues}
          onSave={mutate}
          onClose={() => setOpenTaskModal(false)}
        />
      </ModalWithAnimation>
    </div>
  )
}
export default TaskFormNew

TaskFormNew.propTypes = {
  onAfterSave: PropTypes.func.isRequired,
  userType: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string
  })
}
