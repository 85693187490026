import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const useUrlActionRecord = ({ type }) => {
  const location = useLocation()
  const history = useHistory()

  const handleAction = (method, status) => {
    const filters = queryString.parse(location.search, { arrayFormat: 'bracket' })
    const newFilters = { ...filters, action: `${type}_${method}_${status}` }
    const path = { search: queryString.stringify(newFilters, { arrayFormat: 'bracket' }) }
    history.replace(path)
  }

  return { handleAction }
}
