import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import AcceptCloseModal from '../../molecules/dialogs/AcceptCloseModal'
import UpDownSteps from './UpDownSteps'
import { getIndexToShow } from '../../../utils/destroyableFields'
import { useForm } from 'react-final-form'

const StepsMove = ({ fieldToMove, onClose, open }) => {
  const { change } = useForm()
  const handleSave = steps => {
    change('steps_attributes', steps)
    onClose()
  }
  const [steps, setSteps] = useState(fieldToMove)
  useEffect(() => {
    setSteps(fieldToMove)
  }, [fieldToMove])
  return (
    steps && (
      <AcceptCloseModal
        title="Reordernar las páginas"
        buttonLabel="Guardar cambios"
        onClose={onClose}
        onAccept={() => handleSave(steps)}
        open={open}
      >
        <div className="flex flex-col">
          {steps.map(
            (step, index) =>
              !step._destroy && (
                <div className="flex mb-6" key={index}>
                  <div className="flex flex-col md:flex-row">
                    <div className="bg-neutral-500 text-base md:text-xl font-bold text-white px-6 py-4 items-center justify-center">
                      Página {getIndexToShow(steps, index)}
                    </div>
                    <div className="flex items-center bg-neutral-200 text-sm font-bold px-6 py-4 min-w-64 md:min-w-80">
                      {step.name || 'Sin nombre'}
                    </div>
                  </div>
                  <UpDownSteps steps={steps} index={index} setSteps={setSteps} />
                </div>
              )
          )}
        </div>
      </AcceptCloseModal>
    )
  )
}
export default StepsMove

StepsMove.propTypes = {
  fieldToMove: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
}
