import PropTypes from 'prop-types'
import React from 'react'

const HeaderCondition = ({ headerParams }) => {
  return (
    <div className="mb-6 mx-4 md:mx-0">
      <h1 aria-describedby="page-description" className="title mb-4">
        Condiciones para poder crear formularios de registro civil
      </h1>
      <p className="mb-10" id="page-description">
        Para usar esta plantilla y crear tu formulario &ldquo;{headerParams.templateName}&ldquo;, debes indicar si tu
        entidad cumple con los siguientes criterios:
      </p>
    </div>
  )
}

export default HeaderCondition

HeaderCondition.propTypes = {
  headerParams: PropTypes.exact({
    templateName: PropTypes.string
  }).isRequired
}
