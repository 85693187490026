import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Form } from 'react-final-form'
import { Button } from '../../atoms/Button'
import Alert from '../../molecules/Alert'
import * as validations from '../../../utils/validations'
import TextField from '../../molecules/fields/TextField'
import SelectField from '../../molecules/fields/SelectField'
import { useQuery } from 'react-query'
import Axios from 'axios'
import { PasswordField } from '../../molecules/fields/PasswordField'
import { useHistory } from 'react-router-dom'
import { FormHelperText } from '@material-ui/core'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { useAuth } from '../../molecules/Auth'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import Value from '../../molecules/Value'
import { focusOnFirstError } from '../../../utils/decorators'
import { dataToOptions, getAxiosErrorMessages } from '../../../utils/helpers'
import { FORM_ERROR } from 'final-form'
import useBranchOffices from '../../../hooks/useBranchOffice'
import useUserPermission from '../../../hooks/useUserPermission'
import { getTypeDescription } from '../../../configs/usersConfig'
import useArea from '../../../hooks/useArea'
import useSearchParams from '../../../hooks/useSearchParams'
import queryString from 'query-string'
import DialogErrorMessage from './DialogErrorMessage'
import DialogChangeAreaMessage from './DialogChangeAreaMessage'
import useToggle from '../../../hooks/useToggle'
import { useAllowedRoles } from '../../../hooks/useAllowedRoles'
import ValidateButton from './ValidateButton'
import clsx from 'clsx'
import UsersFormAssigned from './UsersFormAssigned'
import { useRedirectToGdt } from '../../../hooks/procedures_managements/useRedirectToGdt'

const fetchChangeAreaErrors = id => Axios.get(`/admin/v1/users/${id}/change_area_errors.json`).then(a => a.data)

const parseValueLabel = arr => arr?.map(el => ({ value: el.id, label: el.name }))

export const UsersForm = ({ initialValues, onSave, create, edit, isExternal = true }) => {
  const { user } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()
  const [paramsDialog, setParamsDialog] = useState(null)
  const [handleConfirmDialogSave, setHandleConfirmDialogSave] = useState()
  const [emailValidated, setEmailValidated] = useState(Boolean(initialValues.email))
  const [openDialogChangeAreaMessage, toggleDialogChangeAreaMessage] = useToggle()
  const { redirectToGdtId, hasRedirectToGdt, gdtInitialValues } = useRedirectToGdt()

  const paramsBranchOfficeId = searchParams.get('branch_office_id')
  const initialFormValues = { branch_office_id: paramsBranchOfficeId, ...gdtInitialValues, ...initialValues }
  const {
    canEditManagePermission,
    canEditProfileType,
    canShowAllowedData,
    canEditEmail,
    canFilterByBranchOffice,
    canEditPassword
  } = useUserPermission('user')
  const { canManageAreas } = useUserPermission('area')
  const { getBranchOffices } = useBranchOffices(user.institution_id)
  const { data: branchOffices, status: branchOfficesStatus } = getBranchOffices()
  const [branchOfficeId, setBranchOfficeId] = useState(initialFormValues?.branch_office_id)
  const { useGetAreasByBranchOffice, useGetAllAreas } = useArea()
  const areasByBranchOffice = useGetAreasByBranchOffice(branchOfficeId)
  const allAreas = useGetAllAreas()
  const { data: areas } = canFilterByBranchOffice ? areasByBranchOffice : allAreas
  const allowedRoles = useAllowedRoles()
  const onSubmit = async values => {
    if (initialFormValues.enabled_procedure_managements?.length > 0 && values.area_id !== initialFormValues.area_id) {
      toggleDialogChangeAreaMessage()
      setHandleConfirmDialogSave(() => () => handleSave(values))
    } else {
      return await handleSave(values)
    }
  }

  const handleSave = async values => {
    try {
      await onSave(values)
      const urlSaveParams = {
        action: create ? 'user_created' : 'user_updated',
        institution_id: user.institution_id,
        branch_office_id: values.branch_office_id
      }

      let urlAfterSave = '/formularios?action=user_saved'
      if (isExternal) urlAfterSave = `/areas-y-usuarios?${queryString.stringify(urlSaveParams)}`
      if (hasRedirectToGdt) urlAfterSave = `/gestiones-de-tramites-habilitadas/${redirectToGdtId}/areas-y-usuarios`

      history.push(urlAfterSave)
    } catch (error) {
      if (error.response.data.dialog) {
        setParamsDialog(error.response.data.dialog[0])
      } else if (error.response.data.error) {
        return { [FORM_ERROR]: error.response.data.error }
      } else {
        return getAxiosErrorMessages(error)
      }
    }
  }

  const parsedAreas = parseValueLabel(areas)

  const { data: changeAreaErrors = [] } = useQuery(['change_area_errors', initialValues?.id], () =>
    fetchChangeAreaErrors(initialValues?.id)
  )

  const handleChangeArea = change => {
    if (changeAreaErrors.dialog) {
      change('area_id', initialValues.area_id)
      setParamsDialog(changeAreaErrors.dialog[0])
    }
  }

  if (branchOfficesStatus !== 'success') return null

  return (
    <>
      <DialogChangeAreaMessage
        open={openDialogChangeAreaMessage}
        onClose={toggleDialogChangeAreaMessage}
        onConfirm={handleConfirmDialogSave}
        enabledProcedureManagements={initialFormValues.enabled_procedure_managements}
        area={initialFormValues.area}
        userId={initialFormValues.id}
      />
      {paramsDialog !== null && <DialogErrorMessage onClose={() => setParamsDialog(null)} params={paramsDialog} />}
      <Form onSubmit={onSubmit} initialValues={initialFormValues} decorators={[focusOnFirstError]}>
        {({ handleSubmit, submitError, submitting, form }) => (
          <form onSubmit={handleSubmit} className="flex flex-col h-full">
            <div className="flex-1 md:overflow-y-auto">
              <div className="py-6 md:py-8 px-6 md:px-8 bg-neutral-100 mb-4">
                <h2 className="mb-4 font-bold text-xl">Datos de usuario</h2>
                <div className="py-6 md:py-8 px-6 md:px-8 bg-white">
                  <div className={clsx('flex items-start md:flex-row flex-col', emailValidated && 'justify-between')}>
                    <TextField
                      label={`Correo electrónico (${isExternal ? 'este será su usuario' : 'usuario'})`}
                      name="email"
                      disabled={!canEditEmail}
                      validate={validations.mix(validations.isEmail(), validations.required())}
                      size="2xl"
                      rootClassName="sm:max-w-sm w-full"
                      onChange={() => {
                        create && setEmailValidated(false)
                      }}
                    />
                    {create && (
                      <Value name="email">
                        {({ value: email }) => (
                          <ValidateButton
                            email={email}
                            emailValidated={emailValidated}
                            onValidate={() => {
                              setEmailValidated(true)
                            }}
                            onCancel={() => {
                              setEmailValidated(true)
                            }}
                          />
                        )}
                      </Value>
                    )}
                  </div>
                  <TextField
                    label="Nombre y apellidos"
                    name="name"
                    size="2xl"
                    validate={validations.mix(validations.required(), validations.isLetter())}
                    disabled={!emailValidated}
                  />
                  <TextField
                    label="DNI"
                    size="2xl"
                    name="document_number"
                    validate={validations.mix(
                      validations.minLength(8),
                      validations.maxLength(8),
                      validations.isNumber(),
                      validations.required()
                    )}
                    disabled={!emailValidated}
                  />
                  {!edit ? (
                    <PasswordField
                      label="Contraseña"
                      hint={
                        create &&
                        'Coloca una contraseña temporal; esta deberá ser modificada de manera obligatoria por el funcionario en su primer inicio de sesión.'
                      }
                      name="password"
                      margin="none"
                      validate={
                        create
                          ? validations.mix(validations.minLength(8), validations.required())
                          : validations.editPassword()
                      }
                      autoComplete="off"
                      size="2xl"
                      disabled={!emailValidated}
                    />
                  ) : (
                    <>
                      <TextField
                        label="Contraseña"
                        hint={
                          create &&
                          'Si cambias la contraseña, esta deberá ser modificada nuevamente de manera obligatoria por el funcionario en su primer inicio de sesión'
                        }
                        name="password_old"
                        margin="none"
                        placeholder="********"
                        size="2xl"
                        disabled={true}
                      />
                      {(canEditPassword || user.id === initialFormValues.id) && (
                        <>
                          <CheckboxField name="change_password" label="Cambiar contraseña" />
                          <Value name="change_password">
                            {({ value: changePassword }) =>
                              changePassword && (
                                <PasswordField
                                  label="Nueva contraseña"
                                  name="password"
                                  margin="none"
                                  validate={
                                    create
                                      ? validations.mix(validations.minLength(8), validations.required())
                                      : validations.editPassword()
                                  }
                                  autoComplete="off"
                                  size="2xl"
                                />
                              )
                            }
                          </Value>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="py-6 md:py-8 px-6 md:px-8 bg-neutral-100">
                <h2 className="mb-4 font-bold text-xl">Datos requeridos por Facilita</h2>
                <div className="py-6 md:py-8 px-6 md:px-8 bg-white">
                  {canFilterByBranchOffice && (
                    <SelectField
                      name="branch_office_id"
                      size="2xl"
                      options={dataToOptions(branchOffices)}
                      validate={validations.required()}
                      onChange={value => setBranchOfficeId(value)}
                      label="Sede"
                      disabled={!emailValidated}
                    />
                  )}
                  <SelectField
                    name="area_id"
                    size="2xl"
                    options={parsedAreas}
                    label="Área interna"
                    disabled={!emailValidated || !canManageAreas}
                    validate={user.institution_has_enabled_procedures ? validations.required() : null}
                    onChange={() => handleChangeArea(form.change)}
                  />
                  <div className="flex flex-col md:flex-row">
                    <SelectField
                      name="role"
                      label="Tipo de perfil"
                      className="block md:w-96"
                      size="2xl"
                      options={dataToOptions(allowedRoles)}
                      validate={validations.required()}
                      disabled={!emailValidated || !canEditProfileType}
                      margin={canEditManagePermission ? 'lowdense' : 'normal'}
                    />
                    <Value name="role">
                      {({ value }) => {
                        const typeDescription = getTypeDescription(value)
                        return typeDescription ? (
                          <Alert
                            type="info"
                            className="md:ml-4 md:mt-8 md:w-72 lg:w-125 xl:w-148 w-full"
                            border
                            hideIcon
                          >
                            {typeDescription}
                          </Alert>
                        ) : null
                      }}
                    </Value>
                  </div>
                  <WhenFieldChanges field="role" set="allowed_forms" to={null} />
                  <Value name="role">
                    {({ value: role }) =>
                      canShowAllowedData(role) && (
                        <Fragment>
                          {canEditManagePermission && (
                            <CheckboxField
                              name="permission.general.manage_permission"
                              label="Puede administrar sus permisos"
                              disabled={!emailValidated}
                            />
                          )}
                          <UsersFormAssigned emailEnabled={emailValidated} initialValues={initialValues} />
                        </Fragment>
                      )
                    }
                  </Value>
                  {submitError ? <FormHelperText error>{submitError}</FormHelperText> : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row justify-end py-4 md:px-0 px-4">
              <Button variant="secondary" className="md:max-w-56 md:mr-3" size="full" onClick={() => history.goBack()}>
                Regresar
              </Button>
              <Button type="submit" className="md:max-w-56 mb-3 md:mb-0" size="full" disabled={submitting}>
                {create ? 'Crear usuario' : 'Guardar cambios'}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  )
}

UsersForm.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
  initialValues: PropTypes.object,
  isExternal: PropTypes.bool,
  onSave: PropTypes.func.isRequired
}
