import { FormControl, FormLabel } from '@material-ui/core'
import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'
import DateRangeField from '../../molecules/fields/DateRangeField'
import SelectField from '../../molecules/fields/SelectField'
import useInstitutionType from '../../../hooks/useInstitutionType'
import { dataToOptions } from '../../../utils/helpers'
import useUbigeos from '../../../hooks/useUbigeos'
import useFieldValue from '../../../hooks/useFieldValue'
import { useForm } from 'react-final-form'

const InstitutionsFilter = () => {
  const { change } = useForm()
  const regionId = useFieldValue('region')
  const provinceId = useFieldValue('province')
  const { getInstitutionTypes } = useInstitutionType()
  const { data: institutionTypes } = getInstitutionTypes()
  const { getRegions, getProvinces, getDistricts } = useUbigeos()
  const { data: regions } = getRegions()
  const { data: provinces } = getProvinces(regionId)
  const { data: districts } = getDistricts(provinceId)

  return (
    <div className="md:flex flex-row items-start md:py-6 gap-10 mt-5 md:mt-0">
      <div className="mb-6 md:mb-0">
        <SelectField
          className="min-w-60"
          label="Tipo de entidad"
          name="institution_type"
          margin="none"
          size="full"
          options={dataToOptions(institutionTypes)}
        />
      </div>
      <div className="mb-6 md:mb-0">
        <SelectField
          label="Ubicación"
          options={dataToOptions(regions)}
          name="region"
          margin="lowdense"
          className="min-w-60"
          onChange={() => {
            change('province', '')
            change('district', '')
          }}
          size="full"
          emptyLabel="Selecciona un departamento"
        />
        <SelectField
          options={dataToOptions(provinces)}
          name="province"
          margin="lowdense"
          className="min-w-60"
          onChange={() => change('district', '')}
          size="full"
          emptyLabel="Selecciona una provincia"
        />
        <SelectField
          options={dataToOptions(districts)}
          name="district"
          margin="lowdense"
          className="min-w-60"
          size="full"
          emptyLabel="Selecciona un distrito"
        />
      </div>
      <FormControl component="fieldset" classes={{ root: 'md:mt-0 mb-6 md:mb-0' }}>
        <FormLabel component="legend" className="font-bold text-gray-800 text-base">
          Tipo de servicio que usan
        </FormLabel>
        <CheckboxField name="has" label="Formularios" value="form" margin="none" className="ml-0.5" />
        <CheckboxField name="has" label="Consultas rápidas" value="quick_search" margin="none" className="ml-0.5" />
        <CheckboxField
          name="has"
          label="Gestión de trámites"
          value="enabled_procedure_managements"
          margin="none"
          className="ml-0.5"
        />
      </FormControl>
      <FormControl component="fieldset" classes={{ root: 'md:mt-0 mb-6 md:mb-0' }}>
        <FormLabel component="legend" className="font-bold text-gray-800 text-base">
          Tipo de servicio que no usan
        </FormLabel>
        <CheckboxField name="has_not" label="Formularios" value="form" margin="none" className="ml-0.5" />
        <CheckboxField name="has_not" label="Consultas rápidas" value="quick_search" margin="none" className="ml-0.5" />
        <CheckboxField
          name="has_not"
          label="Gestión de trámites"
          value="enabled_procedure_managements"
          margin="none"
          className="ml-0.5"
        />
      </FormControl>
      <DateRangeField label="Fecha de incorporación" name="incorporation_date" margin="lowdense" />
    </div>
  )
}

export default InstitutionsFilter
