import React from 'react'
import useInstitution from '../../../hooks/useInstitution'
import PropTypes from 'prop-types'
import { useBranchOfficeFilter } from '../../../hooks/useBranchOfficeFilter'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'

export const AreasTable = ({ internalAreas = [], onDestroy }) => {
  const { querySameInstitution } = useInstitution()
  const showBranchOfficeColumn = useBranchOfficeFilter(['all_branches'])
  const showActions = area => {
    return (
      <td className="w-1/2 text-right">
        <EditButton to={`/areas/${area.id}/editar`} />
        <DeleteButton onClick={() => onDestroy(area)} />
      </td>
    )
  }

  return (
    <div className="pt-8 pb-2">
      <Table
        spacing="normal"
        className="table-fixed w-full"
        values={internalAreas}
        head={
          <Tr body={false}>
            <Th className="w-1/2 text-left">Nombre</Th>
            {showBranchOfficeColumn && <Th className="w-3/12">Sedes</Th>}
            {querySameInstitution && (
              <Th className="w-1/2 text-right">
                <span className="pr-7 md:pr-5">Acciones</span>
              </Th>
            )}
          </Tr>
        }
        body={internalAreas.map(area => (
          <Tr key={area.id}>
            <Td className="w-1/2 text-left text-lg">{area.name}</Td>
            {showBranchOfficeColumn && <Td className="text-lg">{area.branch_office_name}</Td>}
            {querySameInstitution && showActions(area)}
          </Tr>
        ))}
      />
    </div>
  )
}

AreasTable.propTypes = {
  internalAreas: PropTypes.array,
  onDestroy: PropTypes.func.isRequired
}
