import React from 'react'
import { useParams } from 'react-router-dom'
import useTutorial from '../../../../hooks/useTutorial'
import PageEdit from '../../../organisms/onboarding/PageEdit'

const headerInfo = { title: 'Editar artículo de tutorial' }

const TutorialEdit = () => {
  const { id: tutorialId } = useParams()
  const { updateTutorial, getTutorial } = useTutorial()
  const { data: initialValues, status } = getTutorial(tutorialId)
  if (status !== 'success') return null
  return <PageEdit headerInfo={headerInfo} updateArticle={updateTutorial} initialValues={initialValues} />
}
export default TutorialEdit
