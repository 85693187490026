import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

const ScrollToTop = ({ trigger }) => {
  const { pathname } = useLocation()
  const finalTrigger = trigger || pathname
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [finalTrigger])

  return null
}

export default ScrollToTop

ScrollToTop.propTypes = {
  trigger: PropTypes.number
}
