import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

const CardDescription = ({ to, name, description, descriptionLines }) => {
  return (
    <Link
      to={to}
      className="border-solid border-2 border-neutral-400 md:text-lg rounded-t-lg relative bg-white p-6 break-all"
      data-testid="card-description"
    >
      <p className="font-bold mb-2">{name}</p>
      <HTMLEllipsis
        className="text-gray-800 text-sm hint-html-injected"
        unsafeHTML={description}
        maxLine={descriptionLines}
        ellipsis="..."
        basedOn="letters"
      />
      <i className="bg-gray-800 h-1 -bottom-1 absolute -left-px -right-px rounded-b" />
    </Link>
  )
}

export default CardDescription

CardDescription.propTypes = {
  description: PropTypes.string,
  descriptionLines: PropTypes.number,
  name: PropTypes.string,
  to: PropTypes.string
}
