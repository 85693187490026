import PropTypes from 'prop-types'
import { paymentMethods as configuredPaymentMethods } from '../../../configs/paymentMethodConfigs'
import React from 'react'
import useInstitution from '../../../hooks/useInstitution'

const SummaryPayments = ({ institutionID, paymentMethods }) => {
  const { useGetPaymentAnswersStatus } = useInstitution()
  const { data: paymentAnswersStatus, status } = useGetPaymentAnswersStatus(institutionID)
  const paymentStates = payment => paymentAnswersStatus?.find(value => value.payment_method === payment)?.states
  const getStateCount = (payment, state) => {
    const total = paymentStates(payment)?.find(value => value.state === state)?.count
    return total || 0
  }

  if (status !== 'success') return null
  return (
    <>
      <hr className="h-2px bg-neutral-400 mb-8 md:mb-2 mt-8" />
      <div className="md:p-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {Object.keys(configuredPaymentMethods).map((paymentMethod, index) => (
            <div className="bg-white p-6" key={index}>
              <div className="flex justify-between pb-2">
                <label className="font-bold">{configuredPaymentMethods[paymentMethod]?.label}</label>
                {paymentMethods[paymentMethod] ? 'Habilitado' : 'Deshabilitado'}
              </div>
              <hr className="h-2px bg-neutral-400 mt-2 mb-3" />
              <div className="grid grid-cols-1">
                <p className="font-bold mb-1">
                  Total transacciones:{' '}
                  {getStateCount(paymentMethod, configuredPaymentMethods[paymentMethod].states.total)}
                </p>
                <p className="mb-1">
                  Aprobadas: {getStateCount(paymentMethod, configuredPaymentMethods[paymentMethod].states.aprobado)}
                </p>
                <p className="mb-1">
                  Rechazadas: {getStateCount(paymentMethod, configuredPaymentMethods[paymentMethod].states.rechazado)}
                </p>
                {configuredPaymentMethods[paymentMethod].states.pendiente && (
                  <p>
                    Pendientes: {getStateCount(paymentMethod, configuredPaymentMethods[paymentMethod].states.pendiente)}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SummaryPayments

SummaryPayments.propTypes = {
  institutionID: PropTypes.number,
  paymentMethods: PropTypes.object
}
