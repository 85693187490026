import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'

const ErrorMessageListItem = ({ message, errorsCount, errors }) => {
  return (
    <li>
      {message}
      <span className="font-bold">
        {errors}
        {parseInt(errorsCount) > 50 ? '...' : '.'}
      </span>
    </li>
  )
}

const SpreadsheetError = error => {
  const {
    totalErrors,
    emptyCellErrors,
    totalEmptyCellErrors,
    resultLetterErrors,
    totalResultLetterErrors,
    dateFormatErrors,
    totalDateFormatErrors
  } = queryString.parse(error, {
    arrayFormat: 'bracket'
  })

  return totalErrors ? (
    <span>
      <span>
        La base de datos que has subido tiene{' '}
        <span className="font-bold">
          {totalErrors > 1 ? `${totalErrors} campos con errores. ` : '1 campo con error. '}
        </span>
        Por favor, revísalos y vuelve a intentarlo.
      </span>
      {totalErrors >= 1 && (
        <ul className="list-disc ml-6">
          {emptyCellErrors && (
            <ErrorMessageListItem
              message="Campos vacíos: "
              errorsCount={totalEmptyCellErrors}
              errors={emptyCellErrors}
            />
          )}
          {resultLetterErrors && (
            <ErrorMessageListItem
              message="Campos que no concuerdan con los resultados creados: "
              errorsCount={totalResultLetterErrors}
              errors={resultLetterErrors}
            />
          )}
          {dateFormatErrors && (
            <ErrorMessageListItem
              message="Campos de fecha con formato (DD/MM/AAAA) incorrecto: "
              errorsCount={totalDateFormatErrors}
              errors={dateFormatErrors}
            />
          )}
        </ul>
      )}
    </span>
  ) : (
    error
  )
}

export default SpreadsheetError

ErrorMessageListItem.propTypes = {
  errors: PropTypes.string,
  errorsCount: PropTypes.number.isRequired,
  message: PropTypes.string
}
