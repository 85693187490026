import PropTypes from 'prop-types'
import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'
import useFieldValue from '../../../hooks/useFieldValue'
import useStates from '../../../hooks/useStates'
import useUserTypes from '../../../hooks/useUserTypes'
import { useFieldArray } from 'react-final-form-arrays'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { dataToOptions } from '../../../utils/helpers'
import Error from '../../molecules/Error'
import { useForm } from 'react-final-form'
import {
  getBgColor,
  getOptionUserTypesToUpdateState,
  defaultUserType,
  getUpdateStateOtherUsersText
} from '../../../configs/procedureManagementsConfig'

const UpdateStateOtherUsersSection = ({ task }) => {
  const { change } = useForm()
  let userTypes = []
  const updateStateOtherUsers = useFieldValue('update_state_other_users')
  const procedureManagementId = useFieldValue('procedure_management_id')
  const { data: _userTypes, status: statusUserType } = useUserTypes(procedureManagementId)
  userTypes = _userTypes
  const autoResponseText = useFieldValue('auto_response_text')
  const { data: states, status: statusState } = useStates(procedureManagementId)
  const { fields } = useFieldArray('update_states_by_user_types', {
    validate: (value, values) =>
      values.update_state_other_users && validations.requiredArray('Agrega al menos un usuario', 1)(value)
  })
  const originUserTypeId = useFieldValue('origin_user_type_id')
  const destinyUserTypeId = useFieldValue('destiny_user_type_id')
  const selectedUserTypes = fields.value ? fields.value.map(field => field?.user_type_id) : []
  const selectedUserTypeIds = [...selectedUserTypes, originUserTypeId.toString(), destinyUserTypeId.toString()]
  if (statusUserType !== 'success' || statusState !== 'success') return null
  if (autoResponseText) userTypes = [defaultUserType, ...userTypes]
  return (
    <>
      <button onClick={() => change('update_states_by_user_types', null)} type="button">
        <CheckboxField name="update_state_other_users" margin="dense" label={getUpdateStateOtherUsersText(task.kind)} />
      </button>
      {updateStateOtherUsers && (
        <div className="relative js-field-container bg-neutral-200 p-4 md:p-6">
          {fields.map((name, index) => (
            <div
              className="flex flex-col md:flex-row gap-3 items-start md:items-center mb-6 w-full lg:w-7/12"
              key={index}
            >
              <div>Para</div>
              <SelectField
                name={`${name}.user_type_id`}
                options={getOptionUserTypesToUpdateState(userTypes, selectedUserTypeIds, index)}
                containerClassName="w-full md:flex-1"
                size="full"
                validate={validations.required()}
                emptyLabel="Selecciona el usuario"
                margin="none"
              />
              <div>cambiar a</div>
              <SelectField
                name={`${name}.state_id`}
                options={dataToOptions(states, stateOption => getBgColor(stateOption.color))}
                containerClassName="w-full md:flex-1"
                size="full"
                validate={validations.required()}
                emptyLabel="Selecciona el estado"
                margin="none"
              />
              <IconButton type="button" color="primary" onClick={() => fields.remove(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <button
            className="flex items-center font-bold text-lg text-blue-700"
            onClick={() => fields.push()}
            type="button"
          >
            <AddIcon className="w-6 mr-2" />
            Añadir usuario
          </button>
          <Error name={fields.name} />
        </div>
      )}
    </>
  )
}
export default UpdateStateOtherUsersSection

UpdateStateOtherUsersSection.propTypes = {
  task: PropTypes.object
}
