import React from 'react'
import Container from '../../../atoms/Container'
import { UsersForm } from '../../../organisms/areas_users/UsersForm'
import Axios from 'axios'
import useSearchParams from '../../../../hooks/useSearchParams'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const createUser = values => Axios.post(`/admin/v1/users.json`, values)

const UsersNew = () => {
  const searchParams = useSearchParams()
  const email = searchParams.get('email')
  const initialValues = { email }

  return (
    <>
      <PageNavigation />
      <Container className="py-12 md:px-0">
        <h1 className="mb-4 font-bold text-2xl px-6 md:px-0">Crear usuario</h1>
        <UsersForm onSave={createUser} initialValues={initialValues} create />
      </Container>
    </>
  )
}

export default UsersNew
