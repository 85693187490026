import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Th = ({ className, children }) => {
  return <th className={clsx('px-2 md:px-4 py-2', className)}>{children}</th>
}

export default Th

Th.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
