import PropTypes from 'prop-types'
import React from 'react'
import { MenuItem, Typography } from '@material-ui/core'
import { SelectInput } from '../../molecules/fields/SelectField'
import { Button } from '../../atoms/Button'

const DetailSelectTaskExecute = ({ enabledTasks, value, onSelect: select }) => {
  return enabledTasks.length === 1 ? (
    <div className="w-full flex justify-end">
      <Button onClick={() => select(enabledTasks[0].id)} size="full" className="md:max-w-72">
        {enabledTasks[0].name}
      </Button>
    </div>
  ) : (
    <SelectInput onChange={evt => select(evt.target.value)} size="full" value={value} color="blue">
      <MenuItem value="" className="p-0">
        <Typography component="div" className="text-base whitespace-normal pl-4 pr-6 py-2 w-full font-bold">
          Selecciona la tarea que deseas realizar
        </Typography>
      </MenuItem>
      {enabledTasks.map((enabledTask, index) => (
        <MenuItem value={enabledTask.id} key={index} className="p-0">
          <Typography component="div" className="text-base whitespace-normal pl-4 pr-6 py-2 w-full font-bold">
            {enabledTask.name}
          </Typography>
        </MenuItem>
      ))}
    </SelectInput>
  )
}
export default DetailSelectTaskExecute

DetailSelectTaskExecute.propTypes = {
  enabledTasks: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
