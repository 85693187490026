import PropTypes from 'prop-types'
import React from 'react'
import IconButton from './IconButton'
import PinnedIcon from '../../atoms/icons/PinnedIcon'
import clsx from 'clsx'

const PinnedButton = ({ onClick, disabled, pinned, type }) => {
  const textButton = pinned ? `Desfijar ${type}` : `Fijar ${type}`
  const disabledText = 'Se permite hasta 10 registros fijados'
  return (
    <IconButton
      text={disabled ? disabledText : textButton}
      onClick={!disabled && onClick}
      className={clsx(disabled && 'opacity-50 cursor-default')}
    >
      <PinnedIcon pinned={pinned} />
    </IconButton>
  )
}

export default PinnedButton

PinnedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  pinned: PropTypes.bool,
  type: PropTypes.string
}
