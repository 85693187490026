import PropTypes from 'prop-types'
import React from 'react'
import TransferAccountsShow from './TransferAccountsShow'

const TransferAccountsTaskExecutionAnswer = ({ answer, hideConfirmForm, onUpdateState }) => {
  return (
    <TransferAccountsShow
      answer={answer}
      hideConfirmForm={hideConfirmForm}
      onUpdateState={onUpdateState}
      rejectedMessage="Envíale otra solicitud de pago al ciudadano indicándole los motivos del rechazo"
    />
  )
}
export default TransferAccountsTaskExecutionAnswer

TransferAccountsTaskExecutionAnswer.propTypes = {
  answer: PropTypes.object,
  hideConfirmForm: PropTypes.bool,
  onUpdateState: PropTypes.func.isRequired
}
