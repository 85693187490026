import PropTypes from 'prop-types'
import React from 'react'
import RadioFieldForConditions from '../../molecules/fields/RadioFieldForConditions'
import * as validations from '../../../utils/validations'

const QuestionCondition = ({ name, label }) => {
  const options = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ]

  return (
    <>
      <p className="md:flex md:items-center md:justify-between my-3">
        {label}
        <RadioFieldForConditions name={name} options={options} validate={validations.required()} label={label} row />
      </p>
    </>
  )
}
export default QuestionCondition

QuestionCondition.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}
