import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SelectField from '../../molecules/fields/SelectField'
import { dataToOptions } from '../../../utils/helpers'
import * as validations from '../../../utils/validations'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { useQueryClient } from 'react-query'
import useFieldValue from '../../../hooks/useFieldValue'
import NewAreaModal from './NewAreaModal'
import { useForm } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import SelectedAreaSection from './SelectedAreaSection'

const AreaSection = ({ name, onRemove, areas }) => {
  const [openAreaFormModal, setOpenAreaFormModal] = useState(false)
  const { change } = useForm()
  const queryCache = useQueryClient()
  const selected = useFieldValue(name)
  const area = areas.find(a => a.id.toString() === selected.id?.toString())
  const areaOptions = [
    {
      value: null,
      label: (
        <button
          className="flex items-center text-blue-700 font-bold"
          onClick={() => setOpenAreaFormModal(true)}
          type="button"
        >
          <AddIcon />
          Crear nueva área
        </button>
      )
    },
    ...dataToOptions(areas)
  ]
  return (
    <>
      {selected?.id ? (
        <SelectedAreaSection area={area} onRemove={onRemove} baseName={name} />
      ) : (
        <div className="flex items-center mb-4">
          <SelectField
            name={`${name}.id`}
            options={areaOptions}
            containerClassName="flex-1 sm:max-w-sm"
            size="full"
            validate={validations.required()}
            emptyLabel="Selecciona un área"
            margin="none"
          />
          <IconButton type="button" color="primary" onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      <NewAreaModal
        open={openAreaFormModal}
        onSave={newArea => {
          queryCache.invalidateQueries('areas')
          setOpenAreaFormModal(false)
          change(`${name}.id`, newArea.id)
        }}
        onClose={() => setOpenAreaFormModal(false)}
      />
    </>
  )
}
export default AreaSection

AreaSection.propTypes = {
  areas: PropTypes.array,
  name: PropTypes.string,
  onRemove: PropTypes.func.isRequired
}
