import useCrud from './useCrud'
import useInvalidateQueries from './useInvalidateQueries'

const useNotification = () => {
  const { update, findById, create: createNotification, destroy } = useCrud('/admin/v1/notifications')
  const invalidateQueries = useInvalidateQueries()
  const getNotification = id => findById(['notification', id])

  const { mutate: updateNotification } = update()

  const { mutate: destroyNotification } = destroy()

  const invalidateNotifications = () => invalidateQueries(['notifications'])

  return { getNotification, updateNotification, createNotification, destroyNotification, invalidateNotifications }
}

export default useNotification
