import PropTypes from 'prop-types'
import React from 'react'
import Alert from '../../molecules/Alert'
import { FormSpy } from 'react-final-form'
import { isFirstQuestionByType } from '../../../configs/questionTypesAdminConfig'

const selectBulletsToShow = (autoResponse, itCosts) => {
  if (autoResponse && itCosts) {
    return <li>A este correo se enviarán la respuesta automática y la confirmación de recepción de pago.</li>
  } else if (autoResponse) {
    return <li>A este correo se enviará la respuesta automática.</li>
  } else if (itCosts) {
    return <li>A este correo se enviará la confirmación de recepción de pago.</li>
  }
}

const EmailInfo = ({ index, stepIndex, border = false }) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const isFirstEmailQuestion = isFirstQuestionByType(values.steps_attributes, 'email', index, stepIndex)
        const autoResponse = values.auto_response === 'yes' || values.auto_response === true
        const itCosts = values.it_costs === 'yes' || values.it_costs === true
        return (
          <Alert hideIcon={(autoResponse || itCosts) && isFirstEmailQuestion} type="info" border={border}>
            <ul className={(autoResponse || itCosts) && isFirstEmailQuestion ? 'ml-4 list-disc' : ''}>
              <li>Se validará que la respuesta tenga formato de correo electrónico.</li>
              {isFirstEmailQuestion && selectBulletsToShow(autoResponse, itCosts)}
            </ul>
          </Alert>
        )
      }}
    </FormSpy>
  )
}
export default EmailInfo

EmailInfo.propTypes = {
  border: PropTypes.bool,
  index: PropTypes.number,
  stepIndex: PropTypes.number
}
