import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from './fields/SelectField'
import { MenuItem, Typography } from '@material-ui/core'

export const LanguageSelect = ({ languages, onLocaleChange, value = '', disabled = false }) => {
  return (
    <SelectInput onChange={onLocaleChange} value={value} disabled={disabled}>
      <MenuItem value="">
        <Typography className="text-base whitespace-normal leading-none pl-4 pr-6 py-2">
          Agrega un idioma adicional
        </Typography>
      </MenuItem>
      {languages.map(language => (
        <MenuItem value={language.code || language.locale} key={language.code || language.locale}>
          <Typography className="text-base whitespace-normal leading-none pl-4 pr-6 py-2">{language.name}</Typography>
        </MenuItem>
      ))}
    </SelectInput>
  )
}

LanguageSelect.propTypes = {
  languages: PropTypes.array.isRequired,
  onLocaleChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool
}
