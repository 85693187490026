import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import SelectField from '../../molecules/fields/SelectField'
import Value from '../../molecules/Value'
import { Button } from '../../atoms/Button'
import { useMutation } from 'react-query'
import axios from 'axios'
import { getOptionsToChangeStatus, getStatusLabel, paymentMethods } from '../../../configs/paymentMethodConfigs'

const mutateAnswer = (formId, answerId) => values =>
  axios.put(`/admin/v1/forms/${formId}/answers/${answerId}/update_payment_confirmation_status.json`, values)

const TransferAccountsShow = ({
  answer,
  onUpdateState,
  btnLabelReject = 'Confirmar estado',
  rejectedMessage,
  hideConfirmForm = false
}) => {
  const viewByPaymentState = answer => {
    const states = {
      pending: (
        <>
          <Form onSubmit={handleSubmit} subscription={{ pristine: true, submitting: true }}>
            {({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit}>
                <SelectField
                  name="payment_status"
                  className="mt-1"
                  margin="dense"
                  size="full"
                  options={getOptionsToChangeStatus(answer)}
                />
                <Value name="payment_status">
                  {({ value: confirmationStatus }) => (
                    <Button
                      disabled={pristine || status === 'loading' || status === 'success'}
                      size="full"
                      type="submit"
                    >
                      {confirmationStatus === 'RECHAZADO' ? btnLabelReject : 'Confirmar estado'}
                    </Button>
                  )}
                </Value>
              </form>
            )}
          </Form>
        </>
      ),
      approved: (
        <>
          <span className="ml-1 mb-2 inline-block">{getStatusLabel(answer)}</span>
        </>
      ),
      rejected: (
        <>
          <span className="ml-1 mb-2 inline-block">{getStatusLabel(answer)}</span>
          <p className="mt-3">{rejectedMessage}</p>
        </>
      )
    }
    return states[answer.payment_status]
  }

  const { mutate, status } = useMutation(mutateAnswer(answer.form_id, answer.id))
  const handleSubmit = async values =>
    await mutate(values, {
      onSuccess: () => onUpdateState()
    })
  return (
    <div className="flex">
      <div className="font-extrabold mr-3">Pago:</div>
      <div>
        {paymentMethods[answer.selected_kind_payment].short_label}
        <div className="mt-1">
          Comprobante:
          <a className="ml-2" href={answer.bank_transfer_receipt_url} target="_blank" rel="noreferrer">
            {answer.bank_transfer_receipt}
          </a>
        </div>
        {!hideConfirmForm && <div className="mt-1 sm:max-w-xs">Estado de pago: {viewByPaymentState(answer)}</div>}
      </div>
    </div>
  )
}
export default TransferAccountsShow

TransferAccountsShow.propTypes = {
  answer: PropTypes.object,
  btnLabelReject: PropTypes.string,
  hideConfirmForm: PropTypes.bool,
  onUpdateState: PropTypes.func.isRequired,
  rejectedMessage: PropTypes.string
}
