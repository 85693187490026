/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
import PropTypes from 'prop-types'
import clsx from 'clsx'
import React from 'react'
import { imageSize } from '../../../../configs/imageConfig'

const options = [
  { dimension: `${imageSize.small}px`, name: 'Pequeño' },
  { dimension: `${imageSize.medium}px`, name: 'Mediano' },
  { dimension: `${imageSize.big}px`, name: 'Grande' }
]

const SizeOptionsImage = ({ onChangeDimensions, dimensions }) => {
  return (
    <div className="flex bg-white absolute bottom-0 left-0 right-0 text-blue-700 text-center">
      {options.map((option, index) => (
        <div
          key={index}
          role="button"
          onClick={() => onChangeDimensions(option.dimension)}
          className={clsx(
            'w-full p-1 border-2 border-solid border-blue-700 -ml-0.5 cursor-pointer',
            dimensions === option.dimension && 'text-white bg-blue-700'
          )}
        >
          {option.name}
        </div>
      ))}
    </div>
  )
}

export default SizeOptionsImage

SizeOptionsImage.propTypes = {
  dimensions: PropTypes.string,
  onChangeDimensions: PropTypes.func
}
