import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import i18n from 'i18next'

const useLanguageSwitcher = () => {
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const locale = params.get('locale')
    if (locale) {
      i18n.changeLanguage(locale)
    }
  }, [location])
}

export default useLanguageSwitcher
