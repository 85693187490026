import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { formSteps } from '../../../../configs/enabledProcedureManagementsConfig.js'
import Container from '../../../atoms/Container'
import FormsHeader from '../../../molecules/FormsHeader'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import EnabledProcedureManagementsAreasAndUsersForm from '../../../organisms/enabled_procedure_managements/EnabledProcedureManagementsAreasAndUsersForm'
import AreaExamplesModal from '../../../organisms/enabled_procedure_managements/AreaExamplesModal'
import UserExamplesModal from '../../../organisms/enabled_procedure_managements/UserExamplesModal'
import { ProcedureManagementContext } from '../../../../utils/contexts'

const fetchEnabledProcedureManagement = id =>
  axios.get(`/admin/v1/enabled_procedure_managements/${id}.json`).then(a => a.data)
const updateEnabledProcedureManagement = id => values =>
  axios.put(`/admin/v1/enabled_procedure_managements/${id}.json`, values)

const EnabledProcedureManagementsAreasAndUsers = () => {
  const { id } = useParams()
  const [showAreaExamples, setShowAreaExamples] = useState(false)
  const [showUserExamples, setShowUserExamples] = useState(false)
  const { data: enabledProcedureManagement, status } = useQuery(
    ['enabled_procedure_management', id],
    () => fetchEnabledProcedureManagement(id),
    { refetchOnWindowFocus: false }
  )
  const history = useHistory()
  const { mutate } = useMutation(updateEnabledProcedureManagement(id))
  const handleSave = (values, redirectToUserCreate, redirectToUserCreateParams) => {
    if (redirectToUserCreate) {
      const urlRedirectParams = {
        gdt_area_id: redirectToUserCreateParams.area_id,
        gdt_branch_office_id: redirectToUserCreateParams.branch_office_id,
        redirect_to_gdt: id
      }
      mutate(values, {
        onSuccess: () => {
          history.push(`/usuarios/validar?${queryString.stringify(urlRedirectParams)}`)
        }
      })
    } else {
      mutate(values, {
        onSuccess: () => {
          history.push(`/gestiones-de-tramites-habilitadas/${id}/vista-previa`)
        }
      })
    }
  }
  if (status !== 'success') return null
  const initialValues = {
    user_types_attributes: enabledProcedureManagement.user_types_attributes,
    branch_office_id: enabledProcedureManagement.branch_office_id,
    institution_id: enabledProcedureManagement.institution_id
  }
  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FormsHeader
          title="Habilitar una gestión de trámite"
          hint="Configura la gestión de trámite que deseas habilitar para tu institución."
          steps={formSteps}
          activeStep={formSteps.AREA_AND_USERS}
          size="l"
          description={
            <ul className="list-decimal list-inside mt-4">
              <li className="mb-3 md:mb-1">
                Para cada tipo de usuario, agrega o crea las <span className="font-bold">áreas internas</span>{' '}
                involucradas en la gestión del trámite.
                <button
                  className="text-blue-700 font-bold ml-1"
                  onClick={() => setShowAreaExamples(true)}
                  type="button"
                >
                  Ver ejemplo
                </button>
              </li>
              <li className="mb-3 md:mb-1">
                Para cada área interna, agrega o crea las{' '}
                <span className="font-bold mr-1">cuentas de los funcionarios involucrados</span>
                en la gestión del trámite.
                <button
                  className="text-blue-700 font-bold ml-1"
                  onClick={() => setShowUserExamples(true)}
                  type="button"
                >
                  Ver ejemplo
                </button>
              </li>
            </ul>
          }
        />
        <ProcedureManagementContext.Provider value={enabledProcedureManagement.procedure_management_id}>
          <EnabledProcedureManagementsAreasAndUsersForm
            enabledProcedureManagementId={id}
            initialValues={initialValues}
            onSave={handleSave}
          />
        </ProcedureManagementContext.Provider>
      </Container>
      {showAreaExamples && <AreaExamplesModal onClose={() => setShowAreaExamples(false)} />}
      {showUserExamples && <UserExamplesModal onClose={() => setShowUserExamples(false)} />}
    </>
  )
}
export default EnabledProcedureManagementsAreasAndUsers
