import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { Button } from '../../atoms/Button'
import CloseIcon from '@material-ui/icons/Close'
import blockNotas from '../../../images/quick_search/block-notas.png'

const CsvSeparatorModal = ({ onClose }) => {
  return (
    <Dialog open classes={{ paper: 'rounded-lg' }} maxWidth="sm" onClose={onClose}>
      <div className="flex justify-end mr-3 mt-3">
        <Button endIcon={<CloseIcon />} variant="text" type="button" fullWidth={false} onClick={onClose}>
          Cerrar
        </Button>
      </div>
      <DialogTitle classes={{ root: 'pb-0' }}>
        <span className="font-bold">Tipo de separador del .csv</span>
      </DialogTitle>
      <DialogContent classes={{ root: 'pt-0' }}>
        <p className="mb-8">
          Para ver el tipo de caracter que tiene tu archivo .csv, sigue los siguientes pasos:
          <br />
          <br />
          1. Abre el archivo con un editor de textos (ejm: Bloc de notas) <br />
          2. Verifica el caracter que separa la información de las columnas: <br />
          <br />
          <img src={blockNotas} alt="block-notas" className="w-full" />
        </p>
      </DialogContent>
    </Dialog>
  )
}

export default CsvSeparatorModal

CsvSeparatorModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
