import React from 'react'
import PageHeading from '../../../molecules/PageHeading'
import useFilters from '../../../../hooks/useFilters'
import { useAuth } from '../../../molecules/Auth'
import ArticleTable from '../../../organisms/onboarding/ArticleTable'
import ArticleFilter from '../../../organisms/onboarding/ArticleFilter'
import useTutorial, { urlBase as urlFetchTutorial } from '../../../../hooks/useTutorial'
import useUpgrade, { urlBase as urlFetchUpgrade } from '../../../../hooks/useUpgrade'
import useInvalidateQueries from '../../../../hooks/useInvalidateQueries'
import { BoxTable } from '../../../molecules/BoxTable'
import { urlToJson } from '../../../../utils/helpers'

const HelpCenter = () => {
  const { user } = useAuth()
  const { deleteTutorial } = useTutorial()
  const { deleteUpgrade } = useUpgrade()
  const invalidateQueries = useInvalidateQueries()

  const {
    initialFilters: initialFiltersTutorials,
    results: tutorials,
    onFilter: onFilterTutorial
  } = useFilters(urlToJson(urlFetchTutorial), { sort_t: 'create_desc' }, { pageName: 'page_t' })
  const {
    initialFilters: initialFiltersUpgrades,
    results: upgrades,
    onFilter: onFilterUpgrade
  } = useFilters(urlToJson(urlFetchUpgrade), { sort_u: 'create_desc' }, { pageName: 'page_u' })

  const handleDestroy = article => {
    const { id, type, name } = article
    const title = type === 'Tutorial' ? 'el tutorial' : 'la actualización'
    if (window.confirm(`¿Estás seguro que deseas eliminar ${title} ${name}?`)) {
      try {
        if (type === 'Tutorial') {
          deleteTutorial(id, {
            onSuccess: () => invalidateQueries(urlFetchTutorial)
          })
        } else if (type === 'Upgrade') {
          deleteUpgrade(id, {
            onSuccess: () => invalidateQueries(urlToJson(urlFetchUpgrade))
          })
        }
      } catch (error) {
        const errorMessage = `Ocurrió un error al eliminar ${title}`
        window.alert(errorMessage)
      }
    }
  }

  const optionsOrderBy = [
    { value: 'create_desc', label: 'Creación: más recientes primero' },
    { value: 'create_asc', label: 'Creación: más antiguos primero' },
    { value: 'update_desc', label: 'Actualización: más recientes primero' },
    { value: 'update_asc', label: 'Actualización: más antiguos primero' }
  ]

  return (
    <>
      <PageHeading
        title={<span className="flex items-center">Centro de ayuda</span>}
        pageDescription="Crea o edita los artículos de tutoriales y actualizaciones."
      />
      <BoxTable
        title="Tutoriales"
        description="Estos son los artículos de tutoriales que pueden ver las entidades públicas"
        initialFilters={initialFiltersTutorials}
        onFilter={onFilterTutorial}
        buttons={[
          {
            name: 'Ver categorías',
            link: '/centro-de-ayuda/categorias',
            variant: 'secondary',
            className: 'md:mr-4',
            fullWidth: false
          },
          {
            name: 'Crear tutorial',
            link: '/centro-de-ayuda/articulo-tutorial/crear',
            className: 'mb-4 md:mb-0',
            fullWidth: false
          }
        ]}
        filters={<ArticleFilter hasCategory hasCreators hasStatus type="Tutorial" suffix="_t" />}
        sortOptions={optionsOrderBy}
        sortByName="sort_t"
        values={tutorials}
        displayTotal
        displaySortTop
        searchFieldName="search_t"
        searchPlaceholder="Buscar por nombre"
        paginationLabel="page_t"
        className="mb-10"
      >
        <ArticleTable category user={user} title="tutorial" articles={tutorials.data} onDestroy={handleDestroy} />
      </BoxTable>
      <BoxTable
        title="Actualizaciones"
        description="Estos son los artículos de actualizaciones que pueden ver las entidades públicas"
        initialFilters={initialFiltersUpgrades}
        onFilter={onFilterUpgrade}
        buttons={[
          { name: 'Crear actualización', link: '/centro-de-ayuda/articulo-actualizacion/crear', fullWidth: false }
        ]}
        filters={<ArticleFilter hasCreators hasStatus type="Upgrade" suffix="_u" />}
        sortOptions={optionsOrderBy}
        sortByName="sort_u"
        values={upgrades}
        displayTotal
        displaySortTop
        searchFieldName="search_u"
        searchPlaceholder="Buscar por nombre"
        paginationLabel="page_u"
      >
        <ArticleTable user={user} title="actualización" articles={upgrades.data} onDestroy={handleDestroy} />
      </BoxTable>
    </>
  )
}
export default HelpCenter
