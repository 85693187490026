import React, { useState } from 'react'
import PropTypes from 'prop-types'

const AlertPageContext = React.createContext(null)

export function usePageAlertContext() {
  return React.useContext(AlertPageContext)
}

export function AlertPageProvider(props) {
  const [pageAlert, setPageAlert] = useState(null)

  return <AlertPageContext.Provider value={{ setPageAlert, pageAlert }}>{props.children}</AlertPageContext.Provider>
}

AlertPageProvider.propTypes = {
  children: PropTypes.node
}
