import PropTypes from 'prop-types'
import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

export const LinearProgressBar = ({ color = 'primary', percentage = 0, classes = {} }) => {
  const variant = percentage === 0 ? 'indeterminate' : 'determinate'
  return (
    <LinearProgress
      variant={variant}
      color={color}
      value={percentage}
      classes={{ barColorPrimary: 'bg-green-700', root: 'bg-neutral-200', ...classes }}
      data-testid="progress-bar"
      aria-label={'Cargando'}
    />
  )
}

LinearProgressBar.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  percentage: PropTypes.number
}
