import { FormControl } from '@material-ui/core'
import React from 'react'
import PublishedFilter from '../filters/PublishedFilter'
import DateRangeField from '../../molecules/fields/DateRangeField'

const ProcedureManagementFilter = () => {
  return (
    <div className="flex gap-10 md:flex-row w-full flex-col py-6 px-0.5">
      <FormControl component="fieldset">
        <PublishedFilter />
      </FormControl>
      <FormControl component="fieldset" classes={{ root: 'md:mt-0' }}>
        <DateRangeField name="date" label="Fecha de creación" />
      </FormControl>
    </div>
  )
}

export default ProcedureManagementFilter
