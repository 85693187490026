export const compressImage = (inputBase64, fileType, maxWidth, callback) => {
  const img = new Image()

  img.onload = function () {
    let width = img.width
    let height = img.height

    if (maxWidth && width > maxWidth) {
      height = (maxWidth / width) * height
      width = maxWidth
    }

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height

    ctx.drawImage(img, 0, 0, width, height)

    const compressedBase64 = canvas.toDataURL(fileType)

    callback(compressedBase64)
  }

  img.src = inputBase64
}
