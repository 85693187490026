import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import InstitutionDetails from '../../components/pages/admin/institutions/InstitutionDetails'
import useUserPermission from '../useUserPermission'

const useInstitutionsRoute = () => {
  const { canSeeInstitutionInfo } = useUserPermission('menu')

  return [
    { path: '/entidades', component: ResourcesTab, hasPermission: canSeeInstitutionInfo },
    { path: '/entidades/:id/detalles', component: InstitutionDetails, hasPermission: canSeeInstitutionInfo }
  ]
}
export default useInstitutionsRoute
