import PropTypes from 'prop-types'
import React from 'react'
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core'
import { Button } from '../../atoms/Button'
import { Close } from '@material-ui/icons'
import useTask, { isDefault, isMakePayment } from '../../../hooks/useTask'
import { formValuesPreview } from '../../../configs/templatesConfig'
import TaskExecutionsForm from '../procedures/TaskExecutionsForm'
import CitizenFormContainer from '../citizens/CitizenFormContainer'
import makePaymentTaskPreviewImg from '../../../images/procedure_managements/make_payment_task_preview.png'
import makePaymentTaskPreviewSmImg from '../../../images/procedure_managements/make_payment_task_preview_sm.png'

const TaskPreview = ({ taskId, onClose: close }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { data: task, status } = useTask(taskId)
  if (status !== 'success') return null
  return (
    <Dialog
      open
      onClose={close}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth="md"
      scroll="paper"
      aria-labelledby="vista-previa-dialog-title"
      aria-describedby="vista-previa-dialog-description"
    >
      <Button variant="text" size="xs" className="font-bold ml-auto" endIcon={<Close />} onClick={close}>
        Cerrar
      </Button>
      <DialogTitle id="vista-previa-dialog-title">Vista previa</DialogTitle>
      <DialogContent>
        {isDefault(task) ? (
          <CitizenFormContainer form={formValuesPreview(task.form)} isPreview />
        ) : isMakePayment(task) ? (
          <>
            <img src={makePaymentTaskPreviewImg} className="hidden sm:block m-auto" alt="make-paymnt-task-preview" />
            <img src={makePaymentTaskPreviewSmImg} className="sm:hidden m-auto" alt="make-paymnt-task-preview-mobile" />
          </>
        ) : (
          <TaskExecutionsForm form={formValuesPreview(task.form)} onSubmit={() => null} kind={task.kind} />
        )}
      </DialogContent>
    </Dialog>
  )
}
export default TaskPreview

TaskPreview.propTypes = {
  onClose: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired
}
