import React, { Fragment } from 'react'
import queryString from 'query-string'
import { AreasForm } from '../../../organisms/areas_users/AreasForm'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import Container from '../../../atoms/Container'
import { getAxiosErrorMessages } from '../../../../utils/helpers'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const updateArea = ({ values, areaId }) => axios.put(`/admin/v1/areas/${areaId}.json`, values)
const fetchArea = areaId => axios.get(`/admin/v1/areas/${areaId}.json`).then(a => a.data)

export const AreasEdit = () => {
  const history = useHistory()
  const { id: areaId } = useParams()
  const { data: area, status } = useQuery(['area', areaId], () => fetchArea(areaId))

  const handleAreaUpdate = async values => {
    try {
      await updateArea({ values, areaId })
      const urlSaveParams = {
        institution_id: area.institution_id,
        branch_office_id: area.branch_office_id
      }
      history.push(`/areas-y-usuarios?${queryString.stringify(urlSaveParams)}`)
    } catch (error) {
      if (error.response?.data) {
        return getAxiosErrorMessages(error)
      } else {
        alert('Ocurrió un error al intentar guardar.')
      }
    }
  }

  if (status !== 'success') return null

  return (
    <Fragment>
      <PageNavigation />
      <Container className="py-16 px-6 md:px-0">
        <h1 className="mb-4 font-bold text-2xl">Editar área</h1>
        <AreasForm onSubmit={handleAreaUpdate} initialValues={area} />
      </Container>
    </Fragment>
  )
}
