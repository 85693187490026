import React from 'react'
import { WhatCanIDo } from '../../organisms/home/WhatCanIDo'
import { Header } from '../../organisms/home/Header'
import { Statistics } from '../../organisms/home/Statistics'
import { Institutions } from '../../organisms/home/Institutions'
import { useAuth } from '../../molecules/Auth'
import { Redirect } from 'react-router-dom'
import { isSuccessful } from '../../../hooks/useQuery'
import PublicFooter from '../../organisms/footer/PublicFooter'
import { CreateForm } from '../../organisms/home/CreateForm'

const Home = () => {
  const { user, status } = useAuth()
  if (isSuccessful(status) && user) return <Redirect to="/formularios" />
  if (status === 'loading') return null
  return (
    <div>
      <Header />
      <WhatCanIDo />
      <CreateForm />
      <Statistics />
      <Institutions />
      <PublicFooter />
    </div>
  )
}

export default Home
