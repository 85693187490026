import axios from 'axios'
import useQuery from './useQuery'

const fetchUserTypeStates = userTypeId =>
  axios.get(`/admin/v1/user_types/${userTypeId}/states.json`).then(res => res.data)

const useUserTypeStates = userTypeId => {
  return useQuery(['user_type_states', userTypeId], () => fetchUserTypeStates(userTypeId), {
    refetchOnWindowFocus: false
  })
}
export default useUserTypeStates
