import React, { Fragment } from 'react'
import TitleQuestion from '../forms/TitleQuestion'
import QuestionRenderer from './QuestionRenderer'
import PreviewModal from '../forms/PreviewModal'
import PropTypes from 'prop-types'

const QuestionsRenderer = ({ step, stepTranslated, question, questionIndex, stepIndex, form, formDataInSpanish }) => {
  const questionIndexWithSteps =
    formDataInSpanish.steps_attributes
      .slice(0, stepIndex)
      .reduce((total, currentStep) => total + currentStep.questions_attributes.length, 0) + questionIndex

  const questionTranslated = stepTranslated?.questions_attributes[questionIndex]

  const questionsPreviousSteps = formDataInSpanish.steps_attributes
    .slice(0, stepIndex)
    .flatMap(step => step.questions_attributes)

  const currentQuestionData =
    form.getState().values.steps_attributes?.[stepIndex]?.questions_attributes?.[questionIndex]

  return (
    <Fragment key={question.id}>
      <div className="shadow-sm hover:shadow-2xl rounded border mb-6">
        <div className="bg-white p-6">
          <div key={question.id} className="my-4">
            <TitleQuestion
              questions={step.questions_attributes}
              index={questionIndex}
              questionsPreviousSteps={questionsPreviousSteps}
            />
          </div>

          <QuestionRenderer
            question={question}
            questionTranslated={questionTranslated}
            baseName={`steps_attributes[${stepIndex}].questions_attributes[${questionIndex}]`}
          />
        </div>
        <aside className="bg-neutral-100 py-4 flex justify-end items-center ">
          <div className="mt-6 md:mt-0">
            <PreviewModal index={questionIndex + 1} question={currentQuestionData || question} />
          </div>
        </aside>
      </div>

      {question.children_attributes &&
        Object.entries(question.children_attributes).map(([key, subQuestions]) =>
          subQuestions.map((subQuestion, subQuestionIndex) => {
            const currentSubQuestionData =
              form.getState().values.steps_attributes?.[stepIndex]?.questions_attributes?.[questionIndex]
                ?.children_attributes?.[key]?.[subQuestionIndex]
            if (!currentSubQuestionData) {
              return null
            }
            const subQuestionTranslated = questionTranslated?.children_attributes[key][subQuestionIndex]

            return (
              <div className="shadow-sm hover:shadow-2xl rounded border mb-6" key={subQuestion.id}>
                <div className="bg-white p-6">
                  <Fragment key={subQuestion.id}>
                    <div className="my-4">
                      <TitleQuestion
                        questions={subQuestions}
                        index={subQuestionIndex}
                        parentQuestionIndex={questionIndexWithSteps + 1}
                        questionsPreviousSteps={questionsPreviousSteps}
                      />
                    </div>
                    <QuestionRenderer
                      question={subQuestion}
                      questionTranslated={subQuestionTranslated}
                      baseName={`steps_attributes[${stepIndex}].questions_attributes[${questionIndex}].children_attributes.${key}[${subQuestionIndex}]`}
                    />
                  </Fragment>
                </div>
                <aside className="bg-neutral-100 py-4 flex justify-end items-center ">
                  <div className="mt-6 md:mt-0">
                    <PreviewModal index={subQuestionIndex} question={currentSubQuestionData || subQuestion} />
                  </div>
                </aside>
              </div>
            )
          })
        )}
    </Fragment>
  )
}

QuestionsRenderer.propTypes = {
  step: PropTypes.object.isRequired,
  stepTranslated: PropTypes.object,
  question: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  stepIndex: PropTypes.number.isRequired,
  form: PropTypes.shape({
    getState: PropTypes.func.isRequired
  }).isRequired,
  formDataInSpanish: PropTypes.object.isRequired
}

export default QuestionsRenderer
