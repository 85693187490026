import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../atoms/Button'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import useFieldValue from '../../../hooks/useFieldValue'
import { BoxTable } from '../../molecules/BoxTable'
import { baseOrderOptions } from '../../../configs/orderConfig'
import UsersFormAssignedTable from './UsersFormAssignedTable'
import { isSuccessful } from '../../../hooks/useQuery'
import useFormQuickSearch from '../../../hooks/useFormQuickSearch'

const UsersFormAssignedModal = ({ open, onClose, onSave, initialValues }) => {
  const [boxTableIndex, setBoxTableIndex] = useState(Date.now())
  const [initialValuesForm, setInitialValuesForm] = useState(initialValues?.form || [])
  const [initialValuesQuickSearch, setInitialValuesQuickSearch] = useState(initialValues?.quickSearch || [])
  const [filter, setFilter] = useState()
  const [allowedForms, setAllowedForms] = useState(initialValues?.form || [])
  const [allowedQuickSearches, setAllowedQuickSearches] = useState(initialValues?.quickSearch || [])
  const emailValue = useFieldValue('email')
  const { getFormsQuickSearches } = useFormQuickSearch()
  const { data: formsQuickSearches, status: formQuickSearchStatus } = getFormsQuickSearches(
    ['forms', JSON.stringify(filter)],
    { ...filter },
    { enabled: open }
  )
  const selectedCount = allowedForms.length + allowedQuickSearches.length

  const handleFilter = filterValues => {
    if (filterValues?.filter) setFilter(filterValues?.filter)
    if (filterValues?.forms) setAllowedForms([...(filterValues?.forms || [])])
    if (filterValues?.quick_searches) setAllowedQuickSearches([...(filterValues?.quick_searches || [])])
  }

  const handlePageChange = page => {
    setFilter({ ...filter, page })
  }

  const handleClear = () => {
    setAllowedForms([])
    setAllowedQuickSearches([])
    setInitialValuesForm([])
    setInitialValuesQuickSearch([])
    setBoxTableIndex(Date.now())
  }

  return (
    <ModalWithAnimation size="full" open={open} modalClassName="justify-end md:pl-10" onClose={onClose}>
      <h1 className="text-xl md:text-4xl font-bold mt-6 title mb-8 px-6 md:px-0">
        Selecciona y añade los formularios a los que tendrá acceso el usuario {emailValue}
      </h1>
      <BoxTable
        onFilter={handleFilter}
        initialFilters={{ forms: initialValuesForm, quick_searches: initialValuesQuickSearch }}
        sortOptions={baseOrderOptions}
        values={formsQuickSearches || { total_count: 0, total_pages: 0 }}
        searchPlaceholder="Buscar por nombre o ID"
        sortByName="filter.sort"
        searchFieldName="filter.search"
        displayTotal
        displaySort
        displayHeader={false}
        onPageChange={handlePageChange}
        key={boxTableIndex}
      >
        {isSuccessful(formQuickSearchStatus) ? (
          <>
            <UsersFormAssignedTable name="" formsQuickSearches={formsQuickSearches?.data} />
          </>
        ) : null}
      </BoxTable>
      <div className="bg-neutral-300 p-8 -mx-8 -mb-8 hidden justify-between md:flex">
        <Button variant="secondary" className="md:max-w-52" size="full" onClick={onClose}>
          Cancelar
        </Button>
        <div className="flex items-center gap-4">
          <span className="pr-4 text-gray-800 border-r border-gray-800 border-solid min-w-45 text-right">
            {selectedCount}
            {selectedCount === 1 ? ' seleccionado' : ' seleccionados'}
          </span>
          <Button variant="text" size="full" onClick={handleClear}>
            Deseleccionar
          </Button>
          <Button
            className="md:ml-4 md:max-w-52"
            variant="primary"
            size="full"
            onClick={() => onSave(allowedForms, allowedQuickSearches)}
          >
            Añadir
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:hidden">
        <Button variant="secondary" className="md:max-w-52" size="full" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          className="md:ml-4 md:max-w-52"
          variant="primary"
          size="full"
          onClick={() => onSave(allowedForms, allowedQuickSearches)}
        >
          Añadir
        </Button>
        <div className="flex gap-2 items-center justify-evenly">
          <span className="pr-4 text-gray-800 border-r border-gray-800 border-solid min-w-max text-right">
            {selectedCount}
            {selectedCount === 1 ? ' seleccionado' : ' seleccionados'}
          </span>
          <Button variant="text" size="full" onClick={handleClear}>
            Deseleccionar
          </Button>
        </div>
      </div>
    </ModalWithAnimation>
  )
}

UsersFormAssignedModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  initialValues: PropTypes.exact({
    form: PropTypes.array,
    quickSearch: PropTypes.array
  })
}

export default UsersFormAssignedModal
