import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import QuestionOptionsSection from './QuestionOptionsSection'
import TextListField from './TextListField'

const TextWithRowsColsField = ({ baseName, label }) => {
  return (
    <>
      {label ? (
        <p className="font-bold mb-6">{label}</p>
      ) : (
        <TextField
          label="Etiqueta de la pregunta"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validations.required()}
          margin="none"
          errorClassName="relative inset-0"
        />
      )}
      <QuestionOptionsSection baseName={baseName} size="2xl" />
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <TextListField aria="fila" label="Filas" name={`${baseName}.payload.rows`} />
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0">
          <TextListField aria="columna" label="Columnas" name={`${baseName}.payload.cols`} />
        </div>
      </div>
    </>
  )
}

export default TextWithRowsColsField

TextWithRowsColsField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string
}
