import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import QuestionsFields from './QuestionsFields'
import { useField, useFormState } from 'react-final-form'
import AdmirationMark from 'images/admiration_mark.svg'
import { FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import formValidations from '../../../utils/formValidations'

const truncateLabel = label => (label?.length >= 50 ? label?.slice(0, 50) + '...' : label)

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    top: '0px',
    height: '4px',
    '& > span': {
      maxWidth: 50,
      width: '100%',
      backgroundColor: '#CF000B'
    }
  },
  flexContainer: {
    height: 'auto'
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {
        <Box p={3} classes={{ root: 'px-8 py-4' }}>
          {children}
        </Box>
      }
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const SubQuestionNews = ({ name, parentQuestionIndex, tabs, canDo }) => {
  const [indexSelected, setIndexSelected] = useState(0)
  const { submitFailed } = useFormState({ subscription: { submitFailed: true } })

  const {
    meta: { error: erroredTabsObject }
  } = useField(`${name}.children_attributes`, { subscription: { error: true } })

  const erroredTabs = Object.keys(erroredTabsObject || {})

  useEffect(() => {
    setIndexSelected(0)
  }, [tabs?.length])

  const handleChange = (event, newValue) => {
    setIndexSelected(newValue)
  }

  const showWarningIcon = (id, submitFailed) => {
    if (erroredTabs.includes(id) && submitFailed)
      return { icon: <img src={AdmirationMark} alt="warning_icon" className="ml-2 mb-0" /> }
    return {}
  }

  const atLeastTwoAlternatives = tabs?.length >= 2
  return atLeastTwoAlternatives ? (
    <div className="md:w-10/12 lg:w-11/12 flex-row border mb-8">
      <AppBar position="static" color="default" classes={{ colorDefault: 'bg-white', root: 'shadow-none' }}>
        <StyledTabs
          value={indexSelected}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {(tabs || []).map((tab, key) => (
            <Tab
              label={truncateLabel(tab?.label)}
              key={key}
              classes={{
                selected: 'bg-neutral-100 text-red-800 font-bold',
                wrapper: 'flex-row-reverse flex',
                labelIcon: 'min-h-12'
              }}
              {...showWarningIcon(tab?.id, submitFailed)}
              {...a11yProps(key)}
            />
          ))}
        </StyledTabs>
      </AppBar>
      {(tabs || []).map(
        (tab, key) =>
          tab && (
            <TabPanel
              value={indexSelected}
              index={key}
              key={key}
              className={'bg-neutral-100' + (indexSelected === key ? ' block' : ' hidden js-hidden-container')}
            >
              {submitFailed && erroredTabs.length > 0 && (
                <div className="relative js-field-container">
                  <div className={`bg-red-200 leading-none flex mb-4 p-6`}>
                    <FormHelperText error className={`js-error mt-0 font-bold`}>
                      Revisa que los campos estén correctamente llenados en las pestañas señaladas con
                    </FormHelperText>
                    <img src={AdmirationMark} alt="warning_icon" className="ml-2 mb-0" />
                  </div>
                </div>
              )}
              <QuestionsFields
                name={`${name}.children_attributes.${tab?.id}`}
                parentQuestionIndex={parentQuestionIndex}
                validate={formValidations.subQuestions(name)}
                canDo={canDo}
              />
            </TabPanel>
          )
      )}
    </div>
  ) : (
    <div className="bg-neutral-100 py-6 text-center md:w-10/12 lg:w-11/12 mb-8">
      Aquí podrás agregar las preguntas una vez hayas ingresado al menos 2 alternativas
    </div>
  )
}
export default SubQuestionNews

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number
}

SubQuestionNews.propTypes = {
  canDo: PropTypes.object,
  name: PropTypes.string,
  parentQuestionIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.object)
}
