import useCrud from './useCrud'

const urlBaseForm = `/admin/v1/stats`

const useStatistics = () => {
  const { get: getDownloadedStatus } = useCrud(`${urlBaseForm}/download_status`)

  return { getDownloadedStatus }
}

export default useStatistics
