import PropTypes from 'prop-types'
import React from 'react'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import TagState from './TagState'
import clsx from 'clsx'
import ExpirationDaysText from './ExpirationDaysText'
import DetailGeneralRules from './DetailGeneralRules'

const DetailGeneralInfo = ({ procedure }) => {
  const executionList = [...procedure.task_executions, ...procedure.task_rule_executions]
  return (
    <div className="flex flex-col">
      <div className="bg-neutral-100 p-4 md:p-6">
        {executionList.map(
          (execution, index) =>
            execution.state && (
              <div className="mb-2" key={index}>
                <TagState state={execution.state} />
              </div>
            )
        )}
        <div className="text-lg">
          Código de solicitud: <span className="font-bold">{procedure.code}</span>
        </div>
      </div>
      <div className="border-2 border-neutral-100 p-4 md:p-6">
        <BoldLabelAndContent
          label="Nombre del solicitante:"
          content={procedure.citizen_fullname}
          className="flex flex-col"
        />
        {procedure.citizen_document?.doc_id && (
          <BoldLabelAndContent
            label={`${procedure.citizen_document.doc_type_short_name}:`}
            content={procedure.citizen_document.doc_id}
            className="flex flex-col mt-3"
          />
        )}
        {procedure.citizen_email && (
          <BoldLabelAndContent
            label="Correo electrónico:"
            content={procedure.citizen_email}
            className="flex flex-col mt-3"
          />
        )}
        {procedure.citizen_phone && (
          <BoldLabelAndContent label="Teléfono:" content={procedure.citizen_phone} className="flex flex-col mt-3" />
        )}
      </div>
      <div className={clsx('p-4 md:p-6', procedure.expiration?.urgency === 'high' ? 'bg-red-300' : 'bg-neutral-100  ')}>
        <BoldLabelAndContent
          label="Fecha de ingreso: "
          content={procedure.created_at}
          contentClassName="inline-block"
        />
        {procedure.observation_corrected_at && (
          <BoldLabelAndContent
            label="Fecha de subsanación: "
            content={procedure.observation_corrected_at}
            contentClassName="inline-block"
          />
        )}
        {procedure.expiration && <ExpirationDaysText expiration={procedure.expiration} />}
        {procedure.is_attention_time_stopped && (
          <BoldLabelAndContent
            label="Vence en: "
            content="esperando subsanación"
            className="mb-0"
            contentClassName="inline-block"
          />
        )}
        {procedure.archived_at && (
          <BoldLabelAndContent
            label="Fecha archivada: "
            content={procedure.archived_at}
            className="mb-0"
            contentClassName="inline-block"
          />
        )}
        {procedure.attended_at && (
          <BoldLabelAndContent
            label="Fecha atendida: "
            content={procedure.attended_at}
            className="mb-0"
            contentClassName="inline-block"
          />
        )}
        <DetailGeneralRules rules={procedure.rules} />
      </div>
    </div>
  )
}
export default DetailGeneralInfo

DetailGeneralInfo.propTypes = {
  procedure: PropTypes.object.isRequired
}
