import { addYAtTheEnd } from '../utils/helpers'

const groupFileTypes = [
  { type: 'images', label: 'Imágenes (jpg, png, jpeg)' },
  { type: 'text', label: 'Texto (pdf, doc, docx, txt)' },
  { type: 'sheets', label: 'Hojas de cálculo (xls, xlsx, xlsm)' },
  { type: 'compressed', label: 'Comprimidos (rar, zip)' },
  { type: 'media', label: 'Audio y video (mp3, wma, mp4, wmv)' }
]

const fileTypesConfig = [
  { mimeType: 'application/pdf', extension: 'pdf', type: 'text' },
  { mimeType: 'application/msword', extension: 'doc', type: 'text' },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: 'docx',
    type: 'text'
  },
  { mimeType: 'text/plain', extension: 'txt', type: 'text' },
  { mimeType: 'application/vnd.ms-excel', extension: 'xls', type: 'sheets' },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx',
    type: 'sheets'
  },
  { mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12', extension: 'xlsm', type: 'sheets' },
  { mimeType: 'image/jpg', extension: 'jpg', type: 'images' },
  { mimeType: 'image/jpeg', extension: 'jpg', type: 'images' },
  { mimeType: 'image/jpeg', extension: 'jpeg', type: 'images' },
  { mimeType: 'image/png', extension: 'png', type: 'images' },
  { mimeType: 'image/tiff', extension: 'tif', type: 'images' },
  { mimeType: 'image/bmp', extension: 'bmp', type: 'images' },
  { mimeType: 'audio/mpeg', extension: 'mp3', type: 'media' },
  { mimeType: 'audio/x-ms-wma', extension: 'wma', type: 'media' },
  { mimeType: 'video/mp4', extension: 'mp4', type: 'media' },
  { mimeType: 'video/x-ms-wmv', extension: 'wmv', type: 'media' },
  { mimeType: 'application/x-rar-compressed', extension: 'rar', type: 'compressed' },
  { mimeType: 'application/vnd.rar', extension: 'rar', type: 'compressed' },
  { mimeType: 'application/x-rar', extension: 'rar', type: 'compressed' },
  { mimeType: 'application/zip', extension: 'zip', type: 'compressed' },
  { mimeType: 'application/x-zip', extension: 'zip', type: 'compressed' },
  { mimeType: 'application/x-zip-compressed', extension: 'zip', type: 'compressed' },
  { mimeType: 'text/csv', extension: 'csv', type: 'sheets' }
]

const getFileTypesInfo = (types, info) => [
  ...new Set(
    [].concat(
      ...(types || []).map(type =>
        fileTypesConfig.filter(fileType => fileType.type === type).map(detail => detail[info])
      )
    )
  )
]

const getAcceptedExtensions = types => getAcceptedExtensionsFormat(getFileTypesInfo(types, 'extension'))

const getAcceptedExtensionsFormat = extensions => extensions.map(ext => `.${ext}`).join(',')

const humanFileSize = size => {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

const getFilteredByMimeType = (files, types) =>
  files.filter(file => getFileTypesInfo(types, 'mimeType').includes(file.type))

const isValidByMimeType = (file, types) => getFileTypesInfo(types, 'mimeType').includes(file.type)

const getExtensionsText = types => addYAtTheEnd(getFileTypesInfo(types, 'extension'))

const findMimeTypeAndExtensionMatch = (extensions, file) =>
  fileTypesConfig.find(fileType => fileType.mimeType === file?.type && extensions?.includes(fileType.extension))

const findFileTypeByExtension = extension => fileTypesConfig.find(fileType => fileType.extension === extension)

export {
  humanFileSize,
  getAcceptedExtensions,
  getFilteredByMimeType,
  isValidByMimeType,
  getExtensionsText,
  findMimeTypeAndExtensionMatch,
  getAcceptedExtensionsFormat,
  findFileTypeByExtension,
  groupFileTypes
}
