import { useQuery } from 'react-query'
import axios from 'axios'

const fetchInitialForm = procedureManagementId =>
  axios.get(`/admin/v1/procedure_managements/${procedureManagementId}/initial_form.json`).then(res => res.data)

const useInitialForm = id => {
  return useQuery(['initialForm', id], () => fetchInitialForm(id), { refetchOnWindowFocus: false })
}
export default useInitialForm
