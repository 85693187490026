import PropTypes from 'prop-types'
import { FormControl } from '@material-ui/core'
import React from 'react'
import CreatorsFilter from '../filters/CreatorsFilter'
import CategoriesFilter from '../filters/CategoriesFilter'
import PublishFilter from '../filters/PublishFilter'

const ArticleFilter = ({
  suffix = '',
  type = '',
  hasCategory = false,
  hasCreators = false,
  hasStatus = false,
  categoryAlign
}) => {
  return (
    <FormControl
      className="w-full pt-4 pb-6 flex-col md:flex-row border-solid border-neutral-400 border-t-0.375"
      component="fieldset"
    >
      {hasCategory && (
        <div className="md:mr-8 mb-4 md:mb-0 w-full md:w-auto">
          <CategoriesFilter suffix={suffix} categoryAlign={categoryAlign} />
        </div>
      )}
      {hasCreators && (
        <div className="md:mr-8 mb-4 md:mb-0 min-w-15">
          <CreatorsFilter type={type} suffix={suffix} />
        </div>
      )}
      {hasStatus && (
        <div className="min-w-12">
          <PublishFilter suffix={suffix} />
        </div>
      )}
    </FormControl>
  )
}

export default ArticleFilter

ArticleFilter.propTypes = {
  categoryAlign: PropTypes.string,
  hasCategory: PropTypes.bool,
  hasCreators: PropTypes.bool,
  hasStatus: PropTypes.bool,
  suffix: PropTypes.string,
  type: PropTypes.string
}
