import React from 'react'
import PropTypes from 'prop-types'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import { ChevronRight } from '@material-ui/icons'
import { Button } from '../../atoms/Button'
import { numberFormatter } from '../../../utils/helpers'

const StatCard = ({ icon, title, value, indicator, onShowMore }) => {
  const valueFormatted = numberFormatter.quantity(value)

  return (
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col hover:shadow-lg transition-shadow md:w-1/5 w-40">
      <img src={icon} alt="" className="mb-1.5 w-9 h-9" />
      <h3 className="font-bold w-9">{title}</h3>
      <p className="text-xl md:text-4xl xl:text-5xl font-bold mt-2.5">{valueFormatted}</p>
      <FieldsSeparator />
      <Button variant="text" onClick={() => onShowMore(indicator)}>
        <span className="mr-3">Ver más</span>
        <ChevronRight className="w-6 h-6" />
      </Button>
    </div>
  )
}

StatCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  indicator: PropTypes.string.isRequired,
  onShowMore: PropTypes.func.isRequired
}

export default StatCard
