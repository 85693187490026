import PropTypes from 'prop-types'
import React from 'react'
import useFieldValue from '../../../hooks/useFieldValue'
import * as validations from '../../../utils/validations'
import QuestionComponentMounter from '../question_types_admin/QuestionComponentMounter'

const QuestionFields = ({ baseName, index, stepIndex }) => {
  const kind = useFieldValue(`${baseName}.kind`)
  return (
    <QuestionComponentMounter
      baseName={baseName}
      validate={validations.required()}
      index={index}
      dispatch="Form"
      kind={kind}
      stepIndex={stepIndex}
    />
  )
}

export default QuestionFields

QuestionFields.propTypes = {
  baseName: PropTypes.string,
  index: PropTypes.number,
  stepIndex: PropTypes.number
}
