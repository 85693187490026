import useCrud from './useCrud'
import useInvalidateQueries from './useInvalidateQueries'

const useProcedure = ({ id, enabledProcedureManagementId }) => {
  const { findById } = useCrud(`/admin/v1/procedures`)
  const { create } = useCrud(`/admin/v1/enabled_procedure_managements/${enabledProcedureManagementId}/procedures`)

  const invalidateQueries = useInvalidateQueries()

  const invalidateProcedureQueries = () => invalidateQueries(['procedure', id])

  return { invalidateProcedureQueries, create, findById }
}
export default useProcedure
