import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ConceptualMapTask from './ConceptualMapTask'
import useTasks from '../../../hooks/useTasks'
import axios from 'axios'
import { useQueryClient } from 'react-query'
import TaskFormNew from './TaskFormNew'
import TaskFormEdit from './TaskFormEdit'
import clsx from 'clsx'
import { defaultUserType } from '../../../configs/procedureManagementsConfig'
import { ProcedureManagementContext } from '../../../utils/contexts'

const ConceptualMapUserType = ({ onEdit: edit, userType = defaultUserType }) => {
  const procedureManagementId = useContext(ProcedureManagementContext)
  const [taskToEdit, setTaskToEdit] = useState(null)
  const queryClient = useQueryClient()
  const handleDestroy = async () => {
    if (window.confirm(`¿Estás seguro que deseas eliminar el usuario ${userType.name}?`)) {
      try {
        await axios.delete(`/admin/v1/procedure_managements/${procedureManagementId}/user_types/${userType.id}.json`)
        queryClient.invalidateQueries(['user_types', procedureManagementId])
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }
  const { data: tasks, status } = useTasks(procedureManagementId, userType.id)
  if (status !== 'success') return null
  return (
    <>
      <div className="flex flex-col">
        <div className={clsx('flex flex-col md:w-68', tasks.length === 0 && 'mb-5')}>
          <div className="flex gap-6 justify-between items-center bg-indigo-700 rounded px-8 py-4 min-h-16">
            <div className="text-white font-bold text-xl">{userType.name}</div>
            {userType.id !== 1 && (
              <div className="flex gap-3">
                <IconButton className="p-0 text-white" onClick={edit}>
                  <EditIcon fontSize="small" aria-label="Editar tipo de usuario" />
                </IconButton>
                <IconButton className="p-0 text-white" onClick={handleDestroy}>
                  <DeleteIcon fontSize="small" aria-label="Eliminar tipo de usuario" />
                </IconButton>
              </div>
            )}
          </div>
          {tasks.map((task, index) => (
            <ConceptualMapTask task={task} key={index} onEdit={() => setTaskToEdit(task)} />
          ))}
        </div>
        <TaskFormNew
          userType={userType}
          onAfterSave={() => queryClient.invalidateQueries(['tasks', procedureManagementId])}
        />
      </div>
      {taskToEdit !== null && (
        <TaskFormEdit
          userType={userType}
          taskId={taskToEdit.id}
          onClose={() => setTaskToEdit(null)}
          onAfterSave={() => {
            setTaskToEdit(null)
            queryClient.invalidateQueries(['tasks', procedureManagementId])
          }}
        />
      )}
    </>
  )
}
export default ConceptualMapUserType

ConceptualMapUserType.propTypes = {
  onEdit: PropTypes.func,
  userType: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string
  })
}
