import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import SelectField from '../../molecules/fields/SelectField'
import {
  amountTypeOptions,
  isDefaultUserType,
  getTaskTypeOptions,
  defaultQuestionByTaskKind
} from '../../../configs/procedureManagementsConfig'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { HtmlField } from '../../molecules/fields/HtmlField'
import RadioField from '../../molecules/fields/RadioField'
import useProcedureManagement from '../../../hooks/useProcedureManagement'
import { getFirstQuestionByType } from '../../../configs/questionTypesAdminConfig'
import { isCommentary, isEnablePayment, amountTypeIsFixed, amountTypeIsVariable } from '../../../hooks/useTask'
import { useForm } from 'react-final-form'
import useFieldValue from '../../../hooks/useFieldValue'
import { useFieldArray } from 'react-final-form-arrays'

const contactMediums = {
  email: {
    title: 'Notificar al ciudadano y enviarle un correo',
    label:
      'Quiero que al realizarse esta tarea, se le avise al ciudadano en el seguimiento de su trámite y se le envíe un correo'
  },
  cellphone: {
    title: 'Notificar al ciudadano y enviarle un SMS',
    label:
      'Quiero que al realizarse esta tarea, se le avise al ciudadano en el seguimiento de su trámite y se le envíe un SMS'
  },
  email_cellphone: {
    title: 'Notificar al ciudadano y enviarle un correo o SMS',
    label:
      'Quiero que al realizarse esta tarea, se le avise al ciudadano en el seguimiento de su trámite y se le envíe un correo o SMS'
  }
}

const TaskFormSetup = ({ initialValues, onChangeDefaultQuestions }) => {
  const { change } = useForm()
  const originIsDefaultUserType = isDefaultUserType(initialValues.origin_user_type_id)
  const { data: procedureManagement, status } = useProcedureManagement(initialValues.procedure_management_id)
  const kind = useFieldValue('kind')
  const showAutoResponseText = useFieldValue('show_auto_response_text')
  const showNameForCitizen = useFieldValue('show_name_for_citizen')
  const amountType = useFieldValue('amount_type')
  const { fields: questionsFields } = useFieldArray('steps_attributes[0].questions_attributes')

  useEffect(() => {
    onChangeDefaultQuestions && onChangeDefaultQuestions(kind)
  }, [kind])

  if (status !== 'success') return null
  const firstEmailPhoneApplicant = getFirstQuestionByType(
    procedureManagement.form.steps_attributes,
    'email_phone_applicant'
  )
  const autoResponseByContactMedium =
    contactMediums[firstEmailPhoneApplicant.question?.payload.contact_medium || 'email']

  const toggleDefaultQuestion = kind => {
    const deleteIndex = questionsFields.value.findIndex(el =>
      ['external_institution_task', 'refer_branch_office'].includes(el.kind)
    )
    deleteIndex >= 0 && questionsFields.remove(deleteIndex)

    const taskQuestionToAdd = defaultQuestionByTaskKind(kind)
    if (taskQuestionToAdd) {
      questionsFields.insert(1, taskQuestionToAdd)
      questionsFields.swap(0, 1)
    }
  }

  return (
    <>
      <TextField name="name" label="Nombre de la tarea" size="2xl" validate={validations.required()} margin="none" />
      {!originIsDefaultUserType && (
        <>
          <button onClick={() => change('name_for_citizen', null)} type="button">
            <CheckboxField
              name="show_name_for_citizen"
              margin="none"
              label="Quiero que el nombre de la tarea cambie para la vista de seguimiento del ciudadano."
              className="mb-4 md:mb-1"
            />
          </button>
          {showNameForCitizen && (
            <div className="ml-7">
              <TextField
                name="name_for_citizen"
                label="Nombre para la vista de seguimiento del ciudadano"
                size="2xl"
                validate={validations.required()}
                margin="none"
              />
            </div>
          )}
        </>
      )}
      <SelectField
        name="kind"
        label="Tipo de tarea"
        hint={
          <>
            Define si la tarea debe tener alguna de las lógicas adicionales de las <strong>tareas especiales</strong>;
            de lo contrario debes mantener como <strong>tarea normal</strong>
          </>
        }
        options={getTaskTypeOptions(initialValues.origin_user_type_id)}
        size="full"
        validate={validations.required()}
        onChange={kind => {
          ;['amount_type', 'cost'].map(field => change(field, null))
          toggleDefaultQuestion(kind)
        }}
        containerClassName="mt-6"
      />
      {isEnablePayment({ kind }) && (
        <div className="pl-5 md:pl-9 mb-6">
          <RadioField
            name="amount_type"
            label="Selecciona si el monto a pagar es fijo o variable"
            options={amountTypeOptions}
            validate={validations.required()}
            margin="none"
            onChange={() => change('cost', null)}
          />
          {amountTypeIsFixed(amountType) && (
            <div className="bg-neutral-200 px-8 py-4 mt-2 md:max-w-72">
              <TextField
                leftDecorator={<span className="mr-2">S/ </span>}
                name="cost"
                size="md"
                label="Indica el monto a pagar"
                validate={validations.required()}
                margin="none"
              />
            </div>
          )}
          {amountTypeIsVariable(amountType) && (
            <div className="font-bold pl-9">
              El monto será definido por el funcionario colaborador al momento de realizar esta tarea.
            </div>
          )}
        </div>
      )}
      {!originIsDefaultUserType && !isCommentary({ kind }) && (
        <>
          <div className="font-bold">Tarea reversible</div>
          <CheckboxField
            name="reversible"
            label={
              <>
                Quiero que la tarea pueda ser revertida por el funcionario que la realiza.
                <div>
                  <span className="font-bold">Ejm:</span> para revertir una derivación hecha a un área equivocada
                </div>
              </>
            }
          />
          <div className="font-bold">{autoResponseByContactMedium?.title}</div>
          <button onClick={() => change('auto_response_text', null)} type="button">
            <CheckboxField
              name="show_auto_response_text"
              margin="none"
              label={autoResponseByContactMedium?.label}
              className="mb-4 md:mb-1"
            />
          </button>
          {showAutoResponseText && (
            <div className="ml-9">
              <label
                className="block"
                id="auto_response_text-label"
                htmlFor="auto_response_text-input"
                aria-describedby="auto_response_text-hint"
              >
                Redacta el mensaje para el ciudadano
              </label>
              <HtmlField
                name="auto_response_text"
                ariaLabel="Redacta el mensaje para el ciudadano"
                placeholder="Ejm: Estimado ciudadano(a), su solicitud ha sido derivada al área correspondiente y será atendida en la brevedad posible."
                validate={validationsAdmin.isRichTextFieldEmpty()}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
export default TaskFormSetup

TaskFormSetup.propTypes = {
  initialValues: PropTypes.object,
  onChangeDefaultQuestions: PropTypes.func
}
