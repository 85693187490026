import useCrud from './useCrud'

const urlFetch = '/v1/ubigeos'

const useUbigeos = () => {
  const { get } = useCrud(urlFetch)

  const getRegions = () => get(['regions'], { country: true })

  const getProvinces = regionId => get(['provinces', regionId], { region_code: regionId }, { enabled: !!regionId })

  const getDistricts = provinceId =>
    get(['districts', provinceId], { province_code: provinceId }, { enabled: !!provinceId })

  return { getRegions, getProvinces, getDistricts }
}

export default useUbigeos
