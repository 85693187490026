import React, { useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import CitizenQuickSearch from '../../organisms/citizens/CitizenQuickSearch'
import CitizenHeader from '../../organisms/question_fields/CitizenHeader'
import CitizenQuickSearchResult from '../../organisms/citizens/CitizenQuickSearchResult'
import Feedback from '../../organisms/feedbacks/Feedback'
import NotFound from '../../organisms/citizens/NotFound'
import PropTypes from 'prop-types'
import CitizenNotFound from './CitizenNotFound'

const fetchQuickSearch = (id, isPreview) =>
  axios.get(`/v1/quick_searches/${id}.json`, { params: { isPreview } }).then(res => res.data)

const CitizenQuickSearchPage = ({ isPreview = false }) => {
  const [quickResult, setQuickResult] = useState()
  const { id } = useParams()
  const {
    data: quickSearch,
    isSuccess,
    isError,
    error
  } = useQuery(['consult', id, isPreview], () => fetchQuickSearch(id, isPreview), {
    refetchOnWindowFocus: false,
    retry: false
  })

  if (isSuccess && quickSearch)
    return (
      <>
        <CitizenHeader showUserInfo={false} institution={quickSearch.institution} name={quickSearch.name} />
        {quickResult ? (
          <>
            <CitizenQuickSearchResult quickResult={quickResult} onBack={setQuickResult} />
            <Feedback senderType="citizen_quick_search" />
          </>
        ) : (
          <CitizenQuickSearch quickSearch={quickSearch} setQuickResult={setQuickResult} />
        )}
      </>
    )

  if (isError && error?.response?.data) return <NotFound data={error?.response.data} />

  if (isError && error?.response?.status === 404) return <CitizenNotFound />

  return null
}

CitizenQuickSearchPage.propTypes = {
  isPreview: PropTypes.bool
}

export default CitizenQuickSearchPage
