import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Toast from '../../atoms/Toast'
import Alert from '../../molecules/Alert'
import { isLoading } from '../../../hooks/useQuery'

const FormsCopyAlert = ({ form, streamData, onClose, channel }) => {
  const [newForm, setNewForm] = useState()
  const [status, setStatus] = useState()

  useEffect(() => {
    if (streamData) {
      setNewForm(streamData?.form)
      setStatus(streamData?.state)
    }
  }, [streamData])

  const statusMessage = {
    loading: {
      actionText: 'Cancelar',
      alertText: name => (
        <>
          Creando copia de <span className="font-bold ml-1">{name}</span>
        </>
      ),
      handleActionClick: () => {
        channel.send({ action: 'cancel_copy', id: newForm?.id })
        channel.unsubscribe()
        onClose()
      }
    },
    success: {
      alertText: () => (
        <>
          Se ha creado el formulario: <span className="font-bold ml-1">{newForm?.name}</span>
        </>
      )
    },
    error: {
      alertText: () => <>No se ha podido crear el formulario; inténtalo nuevamente</>
    }
  }

  const { actionText, alertText, handleActionClick } = statusMessage[status] || {}

  return (
    <>
      {status && (
        <Toast open={Boolean(status)} onClose={onClose} autoHide>
          <Alert
            type={status}
            className="text-sm p-6"
            onClose={onClose}
            showClose={!isLoading(status)}
            actionText={actionText}
            actionProps={{ onClick: handleActionClick }}
          >
            {alertText(form.name)}
          </Alert>
        </Toast>
      )}
    </>
  )
}

export default FormsCopyAlert

FormsCopyAlert.propTypes = {
  channel: PropTypes.object,
  form: PropTypes.object,
  onClose: PropTypes.func,
  streamData: PropTypes.object
}
