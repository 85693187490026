import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import BaseIconButton from '../atoms/BaseIconButton'
import InfoIcon from '../atoms/icons/Info'
import Tooltip from '../atoms/Tooltip'

const Toggletip = ({ iconClassName, toggletipText, buttonLabel }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={toggletipText}
          type="light"
        >
          <BaseIconButton color="primary" ariaLabel={buttonLabel} onClick={handleTooltipOpen} size="small">
            <InfoIcon className={clsx('text-2xl', iconClassName)} />
          </BaseIconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export default Toggletip

Toggletip.propTypes = {
  iconClassName: PropTypes.string,
  toggletipText: PropTypes.node,
  buttonLabel: PropTypes.string
}
