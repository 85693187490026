import React, { Fragment } from 'react'
import NotificationsTable from './NotificationsTable'
import useFilters from '../../../hooks/useFilters'
import { BoxTable } from '../../molecules/BoxTable'
import NotificationsFilter from './NotificationsFilter'
import { sortOptions } from '../../../configs/notificationsConfig'

const NotificationsBody = () => {
  const urlFetch = `/admin/v1/notifications.json`

  const {
    onFilter,
    initialFilters,
    results: notifications
  } = useFilters(urlFetch, { notification_sort: 'created_at desc' })

  return (
    <Fragment>
      <BoxTable
        initialFilters={initialFilters}
        onFilter={onFilter}
        filters={<NotificationsFilter />}
        values={notifications}
        paginationLabel="page"
        searchFieldName="search"
        sortByName="sort"
        sortOptions={sortOptions}
        searchPlaceholder="Buscar por contenido"
        displayTotal
        displayHeader={false}
        accordionHasBackground={false}
      >
        <NotificationsTable notifications={notifications.data} />
      </BoxTable>
    </Fragment>
  )
}
export default NotificationsBody
