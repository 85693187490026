import PropTypes from 'prop-types'
import React from 'react'
import { ReactSVG } from 'react-svg'
import countryIcon from '../../../images/icons/country.svg'
import LabelInputAndInfo from '../question_config_fields/LabelInputAndInfo'
import TextWithInfoField from '../../molecules/TextWithInfoField'

const infoContent = 'Al usuario le aparecerá una lista con todos los países.'

const Form = props => {
  return <LabelInputAndInfo baseName={props.baseName} infoContent={infoContent} />
}

const Template = props => {
  return <TextWithInfoField payload={props.question.payload} name={props.name} infoContent={infoContent} disabled />
}

const CountryQuestion = {
  selectOption: {
    value: 'country',
    label: 'País',
    icon: (
      <ReactSVG
        src={countryIcon}
        beforeInjection={svg => {
          svg.classList.add('w-5')
          svg.setAttribute('style', 'height: 24px')
        }}
      />
    )
  },
  Form,
  Template,
  name: 'CountryQuestion'
}

export default CountryQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
