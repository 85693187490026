import PropTypes from 'prop-types'
import React from 'react'
import { AppBar, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import formValidations from '../../../utils/formValidations'
import QuestionsFields from './QuestionsFields'

const SubQuestionsFromTemplate = ({ name, parentQuestionIndex, tabs, canDo }) => {
  const [tabSelected, setTabSelected] = React.useState(tabs[0].id)
  const handleChangeTab = (event, newValue) => setTabSelected(newValue)
  return (
    <div className="md:w-10/12 lg:w-11/12 flex-row border shadow-3xl">
      <TabContext value={tabSelected}>
        <AppBar position="static" color="default" classes={{ colorDefault: 'bg-white' }}>
          <TabList onChange={handleChangeTab} variant="fullWidth" classes={{ indicator: 'h-1 bg-blue-700 top-0' }}>
            {tabs.map((tab, index) => (
              <Tab
                label={tab.label}
                value={tab.id}
                key={index}
                classes={{ selected: 'bg-neutral-100 text-blue-700 font-bold' }}
              />
            ))}
          </TabList>
        </AppBar>
        {tabs.map((tab, index) => (
          <TabPanel value={tab.id} classes={{ root: 'p-0' }} key={index}>
            <div className="bg-neutral-100 p-4">
              <QuestionsFields
                name={`${name}.children_attributes.${tab.id}`}
                parentQuestionIndex={parentQuestionIndex}
                validate={formValidations.subQuestions(name)}
                canDo={canDo}
              />
            </div>
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}
export default SubQuestionsFromTemplate

SubQuestionsFromTemplate.propTypes = {
  canDo: PropTypes.object,
  name: PropTypes.string,
  parentQuestionIndex: PropTypes.number,
  tabs: PropTypes.array.isRequired
}
