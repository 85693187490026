import PropTypes from 'prop-types'
import React from 'react'
import FileIcon from 'images/file.svg'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export const DropZoneBox = ({
  getRootProps,
  getInputProps,
  index = 0,
  name,
  multipleFiles = false,
  className = '',
  isHovering = false,
  disabled,
  ariaLabel
}) => {
  const { t, ready } = useTranslation('validation', { useSuspense: false })
  if (!ready) return null
  return (
    <div
      {...getRootProps({
        className: clsx(
          'border-2 border-dashed display items-center justify-center pb-4 pt-7 px-8 rounded-lg',
          className,
          isHovering && 'bg-blue-300',
          disabled ? 'pointer-events-none border-gray-800 bg-neutral-300' : 'cursor-pointer border-blue-700 bg-white'
        )
      })}
    >
      <input aria-label={ariaLabel} id={`input-file-${index}-${name}`} {...getInputProps()} />
      <div className="text-center ">
        <img src={FileIcon} loading="lazy" alt="upload_icon" className="mx-auto mb-3" />
        <span className={clsx('font-bold', disabled ? 'text-gray-800' : 'text-blue-700')}>
          {multipleFiles ? t('validate_select_files') : t('validate_select_file')}{' '}
        </span>
        <span className="text-gray-500 font-medium">
          {multipleFiles ? t('validate_drag_them') : t('validate_drag_it')}
        </span>
      </div>
    </div>
  )
}

DropZoneBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  index: PropTypes.number,
  isHovering: PropTypes.bool,
  multipleFiles: PropTypes.bool,
  name: PropTypes.string,
  ariaLabel: PropTypes.string
}
