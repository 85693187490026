import PropTypes from 'prop-types'
import React, { useState } from 'react'
import AutoSaveForm from '../../molecules/AutoSaveForm'
import useFilters from '../../../hooks/useFilters'
import AutocompleteFieldFilter from '../../molecules/filters/AutocompleteFieldFilter'
import useInstitution from '../../../hooks/useInstitution'
import useUserPermission from '../../../hooks/useUserPermission'
import BranchOfficeFilter from './BranchOfficeFilter'

const AllInstitutionsBranchOfficeFilter = ({ byType }) => {
  const { onFilter: onFilterInstitution, initialFilters: initialFiltersInstitution } = useFilters('')
  const { canFilterByInstitution, canFilterByBranchOffice } = useUserPermission()
  const [selectedAllInstitution, setSelectedAllInstitution] = useState(
    canFilterByInstitution && !initialFiltersInstitution.institution_id
  )
  const { useGetInstitutionByType } = useInstitution()
  const { data: institutions, status } = useGetInstitutionByType(byType)

  const handleInstitutionFilterChange = institution => {
    setSelectedAllInstitution(!institution.value)
    onFilterInstitution({ institution_id: institution.value, branch_office_id: undefined }, false)
  }

  return (
    <div className="flex-col md:flex-row flex w-full items-baseline md:gap-2 gap-4">
      {canFilterByInstitution && (
        <AutoSaveForm
          className="w-full sm:max-w-sm"
          onSubmit={onFilterInstitution}
          initialValues={initialFiltersInstitution}
        >
          <AutocompleteFieldFilter
            options={institutions}
            defaultOptionLabel="Todas las entidades"
            name="institution_id"
            status={status}
            margin="none"
            onChange={handleInstitutionFilterChange}
          />
        </AutoSaveForm>
      )}
      {canFilterByBranchOffice && !selectedAllInstitution && <BranchOfficeFilter all byType={byType} />}
    </div>
  )
}

export default AllInstitutionsBranchOfficeFilter

AllInstitutionsBranchOfficeFilter.propTypes = {
  byType: PropTypes.string
}
