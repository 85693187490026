import { useEffect, useState } from 'react'

const useCountDown = (start, onTimeEnd) => {
  const [counter, setCounter] = useState(start)
  useEffect(() => {
    if (counter === 0) {
      onTimeEnd()
      return
    }
    setTimeout(() => {
      setCounter(counter - 1)
    }, 1000)
  }, [counter])
  return counter
}
export default useCountDown
