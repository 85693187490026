import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { useTranslation } from 'react-i18next'
import * as validations from '../../../utils/validations'
import HiddenField from './HiddenField'

const DocIdDocTypeApplicantField = ({ baseName, question }) => {
  const { t } = useTranslation('form')

  let hintIdLabel = ''
  let initialValueIdLabel = ''
  let hintTypeLabel = ''
  let initialValueTypeLabel = ''

  switch (question.payload.person_type) {
    case 'natural':
      hintIdLabel = 'Número de documento de identidad del solicitante'
      initialValueIdLabel = t('form_label_applicant_identification_document_number')
      hintTypeLabel = 'Tipo de documento de identidad del solicitante'
      initialValueTypeLabel = t('form_label_applicant_identification_document_type')
      break
    case 'juridica':
      hintIdLabel = 'Ingresa el número de RUC'
      initialValueIdLabel = t('form_label_enter_ruc_number')
      break
    case 'natural-juridica':
      hintIdLabel = 'Número de documento de identidad del solicitante'
      initialValueIdLabel = t('form_label_applicant_identification_document_number')
      hintTypeLabel = 'Tipo de documento de identidad del solicitante'
      initialValueTypeLabel = t('form_label_applicant_identification_document_type')
      break
    default:
      break
  }

  return (
    <div className="grid gap-4">
      <Field name={`${baseName}.payload.doc_id_label`}>
        {({ input }) => {
          if (input.value === '') {
            input.onChange(initialValueIdLabel)
          }

          return (
            <TextField
              label="Etiqueta de la pregunta"
              size="full"
              hint={hintIdLabel}
              validate={validations.required()}
              margin="none"
              errorClassName="relative inset-0"
              {...input}
              disabled
            />
          )
        }}
      </Field>

      {question.payload.person_type !== 'juridica' && (
        <Field name={`${baseName}.payload.doc_type_label`} className="mt-4">
          {({ input }) => {
            if (input.value === '') {
              input.onChange(initialValueTypeLabel)
            }

            return (
              <TextField
                label="Etiqueta de la pregunta"
                size="full"
                hint={hintTypeLabel}
                validate={validations.required()}
                {...input}
                disabled
              />
            )
          }}
        </Field>
      )}

      <HiddenField name={`${baseName}.payload.has_hint`} value={question.payload.has_hint} />
      <HiddenField name={`${baseName}.payload.has_indication_text`} value={question.payload.has_indication_text} />
      <HiddenField name={`${baseName}.payload.person_type`} value={question.payload.person_type} />
    </div>
  )
}

export default DocIdDocTypeApplicantField

DocIdDocTypeApplicantField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object
}
