import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import clsx from 'clsx'

const handleCopyToClipboard = textToCopy => {
  navigator.clipboard.writeText(textToCopy)
}

const sizes = {
  s: 'text-base',
  m: 'md:text-lg'
}

const iconSizes = {
  s: 'text-lg',
  m: 'md:text-2xl'
}

const styleMode = {
  dark: 'text-white bg-neutral-600 hover:bg-neutral-500',
  light: 'text-blue-700'
}

const CopyText = ({ copy, ariaLabel, className, mode = 'light', size = 'm' }) => {
  const [copyText, setCopyText] = useState('Copiar')
  const handleCopy = () => {
    handleCopyToClipboard(copy)
    setCopyText('Copiado')
  }

  useEffect(() => {
    let timer
    if (copyText === 'Copiado') {
      timer = setTimeout(() => setCopyText('Copiar'), 2000)
    }
    return () => timer && clearTimeout(timer)
  }, [copyText])

  return (
    <button
      type="button"
      aria-label={ariaLabel}
      className={clsx('font-bold rounded flex items-center', styleMode[mode], sizes[size], className)}
      onClick={handleCopy}
    >
      <FileCopyIcon classes={{ root: clsx('mr-1', iconSizes[size]) }} />
      {copyText}
    </button>
  )
}
export default CopyText

CopyText.propTypes = {
  ariaLabel: PropTypes.string,
  copy: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  mode: PropTypes.oneOf['dark, light']
}
