import React from 'react'
import PropTypes from 'prop-types'
import CheckboxDoubleCitizenField from '../question_fields/CheckboxDoubleCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin } = props
  return (
    <CheckboxDoubleCitizenField
      index={index}
      {...question}
      maxOneCol={question.max_one_col}
      requiredOneRow={question.required_one_row}
      name={name}
      disabled={disabled}
      margin={margin}
    />
  )
}

const CheckboxDoubleQuestion = {
  Citizen,
  name: 'CheckboxDoubleQuestion'
}

export default CheckboxDoubleQuestion

Citizen.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  question: PropTypes.object,
  disabled: PropTypes.bool,
  margin: PropTypes.string
}
