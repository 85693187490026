import React, { Fragment } from 'react'
import Alert from '../../molecules/Alert'
import ScrollToTop from '../../molecules/ScrollToTop'
import { usePageAlertContext } from '../../../context/AlertPageProvider'
import { PaymentTransferDisableErrorMessage } from './PaymentTransferDisableErrorMessage'

const PaymentAlert = () => {
  const { pageAlert, setPageAlert } = usePageAlertContext()

  if (!pageAlert) return null

  return (
    <Fragment>
      {pageAlert.alert_reason === 'payment_transfer_accounts_disable' && (
        <Alert type="error" className="mt-10" onClose={() => setPageAlert(null)} showClose>
          <ScrollToTop />
          <h2>
            No es posible desactivar el medio de pago <span className="font-bold">Transferencia bancaria</span>.
          </h2>
          <PaymentTransferDisableErrorMessage params={pageAlert.params} />
        </Alert>
      )}
    </Fragment>
  )
}

export default PaymentAlert
