import PropTypes from 'prop-types'
import React from 'react'
import ManualEntryPayload from './ManualEntryPayload'
import Hint from './Hint'

const QuestionOptionsSection = ({ baseName, margin = 'dense', size }) => {
  return (
    <div className="mt-4">
      <Hint baseName={`${baseName}.payload`} />
      <ManualEntryPayload baseName={baseName} margin={margin} size={size} />
    </div>
  )
}

export default QuestionOptionsSection

QuestionOptionsSection.propTypes = {
  baseName: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string
}
