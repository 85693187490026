import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import QuickSearchNew from '../../components/pages/admin/quick_searches/QuickSearchNew'
import QuickSearchEdit from '../../components/pages/admin/quick_searches/QuickSearchEdit'
import QuickSearchResults from '../../components/pages/admin/quick_searches/QuickSearchResults'
import QuickSearchPreview from '../../components/pages/admin/quick_searches/QuickSearchPreview'
import QuickSearchExample from '../../components/pages/admin/quick_searches/QuickSearchExample'
import QuickSearchFormat from '../../components/pages/admin/quick_searches/QuickSearchExampleFormat'
import useUserPermission from '../useUserPermission'

const useQuickSearchesRoute = () => {
  const { canCreateOrEdit } = useUserPermission('quickSearch')

  return [
    { path: '/consultas-rapidas', component: ResourcesTab, hasPermission: true },
    { path: '/consultas-rapidas/crear', component: QuickSearchNew, hasPermission: canCreateOrEdit },
    { path: '/consultas-rapidas/:id/editar', component: QuickSearchEdit, hasPermission: canCreateOrEdit },
    { path: '/consultas-rapidas/:id/resultados', component: QuickSearchResults, hasPermission: canCreateOrEdit },
    {
      path: '/consultas-rapidas/:id/vista-previa',
      component: QuickSearchPreview,
      hasPermission: true,
      hasFeedback: true
    },
    { path: '/consultas-rapidas/ejemplo', component: QuickSearchExample, hasPermission: true },
    { path: '/consultas-rapidas/ejemplo-formato', component: QuickSearchFormat, hasPermission: true }
  ]
}
export default useQuickSearchesRoute
