import React from 'react'
import PropTypes from 'prop-types'
import TextWithRowsColsField from './TextWithRowsColsField'

const CheckboxDoubleField = ({ baseName, label }) => {
  return <TextWithRowsColsField baseName={baseName} label={label} />
}

export default CheckboxDoubleField

CheckboxDoubleField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string
}
