import PropTypes from 'prop-types'
import React from 'react'
import CheckboxField from './CheckboxField'

const CheckboxesField = ({ name, label, options }) => {
  return (
    <fieldset className="mb-6">
      <legend className="text-base font-bold text-gray-800">{label}</legend>
      {options.map((option, index) => (
        <CheckboxField
          key={index}
          name={name}
          margin="none"
          label={option.name}
          value={option.code || option.id.toString()}
          total={option.total ? option.total : null}
        />
      ))}
    </fieldset>
  )
}

export default CheckboxesField

CheckboxesField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array
}
