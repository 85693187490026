import PropTypes from 'prop-types'
import React from 'react'
import AddressField from '../question_config_fields/AddressField'
import AddressIcon from '@material-ui/icons/LocationOn'
import AddressCitizenField from '../question_fields/AddressCitizenField'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'

const Form = props => {
  return <AddressField baseName={props.baseName} />
}

const Template = props => {
  return <AddressCitizenField payload={props.question.payload} name={props.name} disabled />
}

const Answer = props => {
  const content = getArrayToShow(props).map((item, key) => <p key={`${props.question.id}_${key}`}>{item}</p>)
  return <BoldLabelAndContent label={props.question.label} content={content} el="li" />
}

const TaskResponse = props => {
  const content = getArrayToShow(props).map((item, key) => <p key={`${props.question.id}_${key}`}>{item}</p>)
  return <BoldLabelAndContent label={props.question.label} content={content} className="mb-0" />
}

const getArrayToShow = ({ answer, question }) => {
  const content = [
    `${question.payload.ubigeo_region_label}: ${answer.region_name}`,
    `${question.payload.ubigeo_city_label}: ${answer.province_name}`,
    `${question.payload.ubigeo_district_label}: ${answer.district_name}`,
    `${question.payload.address}: ${answer.address}`
  ]
  return content
}

const AddressQuestion = {
  selectOption: { value: 'address', label: 'Dirección', icon: <AddressIcon fontSize="small" /> },
  Form,
  Template,
  Answer,
  TaskResponse,
  name: 'AddressQuestion'
}

export default AddressQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}

Answer.propTypes = {
  question: PropTypes.object.isRequired
}

TaskResponse.propTypes = {
  question: PropTypes.object.isRequired
}
