import useCrud from './useCrud'

export const urlBase = '/admin/v1/tutorials'

const useTutorial = () => {
  const { update, findById, create: createTutorial, destroy } = useCrud(urlBase)

  const getTutorial = id => findById(['tutorial', id])

  const { get: getCreators } = useCrud(`${urlBase}/creators`)

  const { mutate: updateTutorial } = update()

  const { mutate: deleteTutorial } = destroy()

  return { getTutorial, updateTutorial, createTutorial, getCreators, deleteTutorial }
}

export default useTutorial
