import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import SelectField from '../../molecules/fields/SelectField'
import TextField from '../../molecules/fields/TextField'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import * as validations from '../../../utils/validations'
import { marginSizes } from '../../../configs/sizesConfig'
import clsx from 'clsx'
import { getValidationByType, useDocumentTypeOptions } from '../../../configs/documentTypesConfig'
import { useForm } from 'react-final-form'
import { getDefaultDocType, isJuridica } from '../../../configs/personTypesConfig'
import useFieldValue from '../../../hooks/useFieldValue'
import Hint from '../../atoms/Hint'
import { useTranslation } from 'react-i18next'
import useTextValidation from '../../../hooks/useTextValidation'

const DocIdTypeCitizenField = ({
  index,
  name,
  payload,
  required,
  disabled = false,
  margin = 'normal',
  noDefaultValue = false
}) => {
  const label = index ? `${index}. ${payload.doc_type_label}` : payload.doc_type_label
  const { change } = useForm()
  const docTypeValue = useFieldValue(name)
  const docType = useFieldValue(`${name}.doc_type`)
  const personType = payload.person_type

  useEffect(() => {
    if (!docTypeValue && !noDefaultValue) change(`${name}.doc_type`, getDefaultDocType(personType))
  }, [])

  useEffect(() => {
    const validation = getValidationByType(docType, required)
    if (validation) {
      change(`${name}.doc_id`, '')
      change(`${name}.doc_id`, undefined, { validate: validation })
    }
  }, [docType, required])

  const documentTypeOptions = useDocumentTypeOptions(personType)

  const getLabelDocType = docType => {
    if (isJuridica(personType)) {
      return `${index}. ${payload.doc_id_label}`
    } else if (docType === 'other_document') {
      return `${index}.2 ${payload.doc_id_label}`
    } else {
      return payload.doc_id_label
    }
  }

  const { t } = useTranslation('form_field_options')
  const translationText = useTextValidation()

  return (
    <>
      <label htmlFor={`${name}.doc_type-input`} id={`${name}.doc_type-label`} className="block font-bold min-w-25 mb-2">
        {isJuridica(personType) ? getLabelDocType(docType) : label}
      </label>
      {payload?.hint && <Hint hint={payload.hint} name={name} />}
      <div className={marginSizes[margin]}>
        {!isJuridica(personType) && (
          <SelectField
            margin="dense"
            name={`${name}.doc_type`}
            className="block"
            options={documentTypeOptions}
            disabled={disabled}
            validate={required && validations.required(translationText.required)}
            size="2xl"
            emptyLabel={t('document_type_select_a_document')}
          />
        )}
        <div className={clsx(docType === 'other_document' && 'pt-3 px-4 pb-4 bg-blue-200')}>
          {docType === 'other_document' && (
            <TextField
              name={`${name}.doc_name`}
              label={`${index}.1 ${t('document_type_document_type_name')} `}
              disabled={disabled}
              margin="dense"
              size="2xl"
            />
          )}
          <WhenFieldChanges field={`${name}.doc_type`} set={`${name}.doc_id`} to={null} />
          <TextField
            name={`${name}.doc_id`}
            label={!isJuridica(personType) && getLabelDocType(docType)}
            disabled={disabled}
            margin="none"
            validate={getValidationByType(docType, required)}
            size="2xl"
          />
        </div>
      </div>
    </>
  )
}

export default DocIdTypeCitizenField

DocIdTypeCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  name: PropTypes.string,
  noDefaultValue: PropTypes.bool,
  payload: PropTypes.object,
  required: PropTypes.bool
}
