import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Form } from 'react-final-form'
import { Button } from '../../atoms/Button'
import Alert from '../../molecules/Alert'
import * as validations from '../../../utils/validations'
import TextField from '../../molecules/fields/TextField'
import SelectField from '../../molecules/fields/SelectField'
import { useQuery } from 'react-query'
import Axios from 'axios'
import { useHistory, Link } from 'react-router-dom'
import { FormHelperText } from '@material-ui/core'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { useAuth } from '../../molecules/Auth'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import Value from '../../molecules/Value'
import { MultipleSelectField } from '../../molecules/fields/MultipleSelectField'
import { focusOnFirstError } from '../../../utils/decorators'
import { dataToOptions, getAxiosErrorMessages } from '../../../utils/helpers'
import { FORM_ERROR } from 'final-form'
import useBranchOffices from '../../../hooks/useBranchOffice'
import useUserPermission from '../../../hooks/useUserPermission'
import { getTypeDescription } from '../../../configs/usersConfig'
import useArea from '../../../hooks/useArea'
import useSearchParams from '../../../hooks/useSearchParams'
import queryString from 'query-string'
import DialogErrorMessage from './DialogErrorMessage'
import DialogChangeAreaMessage from './DialogChangeAreaMessage'
import useToggle from '../../../hooks/useToggle'
import { useAllowedRoles } from '../../../hooks/useAllowedRoles'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { useRedirectToGdt } from '../../../hooks/procedures_managements/useRedirectToGdt'

const fetchForms = () => Axios.get('/admin/v1/forms/allowed_forms.json').then(a => a.data)
const fetchQuickSearches = () => Axios.get('/admin/v1/quick_searches/allowed_quick_searches.json').then(a => a.data)
const fetchChangeAreaErrors = id => Axios.get(`/admin/v1/users/${id}/change_area_errors.json`).then(a => a.data)

const parseValueLabel = arr => arr?.map(el => ({ value: el.id, label: el.name }))

export const UsersFormDetail = ({ initialValues, onSave, editByDetail }) => {
  const { user } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()
  const [paramsDialog, setParamsDialog] = useState(null)
  const [handleConfirmDialogSave, setHandleConfirmDialogSave] = useState()
  const [openDialogChangeAreaMessage, toggleDialogChangeAreaMessage] = useToggle()
  const { redirectToGdtUrl, hasRedirectToGdt, redirectToGdtId, gdtInitialValues } = useRedirectToGdt()
  const paramsBranchOfficeId = searchParams.get('branch_office_id')
  const initialFormValues = { branch_office_id: paramsBranchOfficeId, ...gdtInitialValues, ...initialValues }
  const {
    canEditCreateOrEditForms,
    canEditCreateOrEditQuickSearches,
    canEditManagePermission,
    canEditProfileType,
    canShowAllowedData,
    canEditEmail,
    canChangeAllowedData,
    canFilterByBranchOffice,
    canSeeBranchRecords,
    canEdit
  } = useUserPermission('user')
  const { canManageAreas } = useUserPermission('area')
  const { data: allowedForms, status: allowedFormsStatus } = useQuery(['forms_user_creation'], fetchForms)
  const { getBranchOffices } = useBranchOffices(user.institution_id)
  const { data: branchOffices, status: branchOfficesStatus } = getBranchOffices()
  const [branchOfficeId, setBranchOfficeId] = useState(initialFormValues?.branch_office_id)
  const { useGetAreasByBranchOffice, useGetAllAreas } = useArea()
  const areasByBranchOffice = useGetAreasByBranchOffice(branchOfficeId)
  const allAreas = useGetAllAreas()
  const { data: areas } = canFilterByBranchOffice ? areasByBranchOffice : allAreas
  const { data: quickSearches, status: quickSearchesStatus } = useQuery(['quick_searches'], fetchQuickSearches)
  const allowedRoles = useAllowedRoles()
  const onSubmit = async values => {
    if (initialFormValues.enabled_procedure_managements?.length > 0 && values.area_id !== initialFormValues.area_id) {
      toggleDialogChangeAreaMessage()
      setHandleConfirmDialogSave(() => () => handleSave(values))
    } else {
      return await handleSave(values)
    }
  }

  const handleSave = async values => {
    try {
      await onSave(values)
      const urlSaveParams = {
        action: 'user_created',
        institution_id: user.institution_id,
        branch_office_id: values.branch_office_id
      }
      let urlAfterSave = `/areas-y-usuarios?${queryString.stringify(urlSaveParams)}`
      if (hasRedirectToGdt) urlAfterSave = `/gestiones-de-tramites-habilitadas/${redirectToGdtId}/areas-y-usuarios`
      history.push(urlAfterSave)
    } catch (error) {
      if (error.response.data.dialog) {
        setParamsDialog(error.response.data.dialog[0])
      } else if (error.response.data.error) {
        return { [FORM_ERROR]: error.response.data.error }
      } else {
        return getAxiosErrorMessages(error)
      }
    }
  }

  const filterAllowedForm = canSeeBranchRecords
    ? allowedForms?.filter(
        form => form.branch_office_id === user.branch_office_id || initialFormValues.allowed_forms?.includes(form.id)
      )
    : allowedForms
  const filterAllowedQuickSearches = canSeeBranchRecords
    ? quickSearches?.filter(
        quickSearch =>
          quickSearch.branch_office_id === user.branch_office_id ||
          initialFormValues.allowed_quick_searches?.includes(quickSearch.id)
      )
    : quickSearches

  const parsedAreas = parseValueLabel(areas)
  const parsedAllowedForms = parseValueLabel(filterAllowedForm)
  const parsedQuickSearches = parseValueLabel(filterAllowedQuickSearches)

  const { data: changeAreaErrors = [] } = useQuery(['change_area_errors', initialValues?.id], () =>
    fetchChangeAreaErrors(initialValues?.id)
  )

  const handleChangeArea = change => {
    if (changeAreaErrors.dialog) {
      change('area_id', initialValues.area_id)
      setParamsDialog(changeAreaErrors.dialog[0])
    }
  }

  if (allowedFormsStatus !== 'success' || quickSearchesStatus !== 'success' || branchOfficesStatus !== 'success')
    return null

  return (
    <>
      <DialogChangeAreaMessage
        open={openDialogChangeAreaMessage}
        onClose={toggleDialogChangeAreaMessage}
        onConfirm={handleConfirmDialogSave}
        enabledProcedureManagements={initialFormValues.enabled_procedure_managements}
        area={initialFormValues.area}
        userId={initialFormValues.id}
      />
      {paramsDialog !== null && <DialogErrorMessage onClose={() => setParamsDialog(null)} params={paramsDialog} />}
      <Form onSubmit={onSubmit} initialValues={initialFormValues} decorators={[focusOnFirstError]}>
        {({ handleSubmit, submitError, submitting, form }) => (
          <form onSubmit={handleSubmit} className="flex flex-col h-full">
            <div className="flex-1 md:overflow-y-auto">
              <div className="py-6 md:py-8 px-6 md:pl-8 bg-neutral-100 mb-4">
                <div className="flex flex-row justify-between">
                  <h2 className="mb-4 text-xl flex">
                    <div className="font-bold mr-1">Datos de usuario </div> (registrados en Gob.pe)
                  </h2>
                  {!editByDetail ? (
                    canEdit(user.institution_id) && (
                      <Button
                        variant="text"
                        el={Link}
                        to={`/usuarios/editar-desde-gobpe?email=${initialValues.email}&${redirectToGdtUrl}`}
                        className="flex"
                      >
                        <EditIcon className="mr-2" />
                        Editar
                      </Button>
                    )
                  ) : (
                    <Button variant="text" className="flex" onClick={() => history.goBack()}>
                      <CloseIcon className="mr-2" />
                      Cancelar Edición
                    </Button>
                  )}
                </div>

                <div className={`bg-white flex flex-col items-start ${editByDetail ? `gap-2` : `gap-4`} p-8 w-full`}>
                  <div className="md:flex justify-between w-full">
                    <div className="flex flex-col items-start p-0 gap-2 w-full">
                      {!editByDetail ? (
                        <div className="flex md:flex-row flex-col">
                          <p className="font-bold w-68">Correo electrónico (usuario)</p>
                          <p>{initialValues.email}</p>
                        </div>
                      ) : (
                        <TextField
                          label={`Correo electrónico (este será su usuario)`}
                          name="email"
                          disabled={!canEditEmail}
                          validate={validations.mix(validations.isEmail(), validations.required())}
                          size="2xl"
                        />
                      )}
                    </div>
                    {editByDetail ? (
                      <Alert type="warning" className="md:ml-4 md:w-72 lg:w-125 xl:w-272 w-full mb-6" border>
                        {
                          'Te recomendamos mantener los datos de correo, nombres y apellidos y DNI, a menos que presenten errores de digitación'
                        }
                      </Alert>
                    ) : (
                      <Alert type="info" className="md:ml-4 md:w-72 lg:w-125 xl:w-272 w-full mb-6" border>
                        {
                          'Este usuario fue registrado anteriormente en Gob.pe. Para darle acceso a Facilita, debes completar los campos faltantes en la sección Datos requeridos por Facilita'
                        }
                      </Alert>
                    )}
                  </div>
                  <hr className="w-full mb-1" />
                  <div className="flex flex-col items-start p-0 gap-2 w-full">
                    {!editByDetail ? (
                      <div className="flex md:flex-row flex-col">
                        <p className="font-bold w-68">Nombres y Apellidos</p>
                        <p>{initialValues.name}</p>
                      </div>
                    ) : (
                      <TextField
                        label="Nombre y apellidos"
                        name="name"
                        size="2xl"
                        validate={validations.mix(validations.required(), validations.isLetter())}
                      />
                    )}
                  </div>
                  <hr className="w-full mb-1" />
                  <div className="flex flex-col items-start p-0 gap-2 w-full">
                    {!editByDetail ? (
                      <div className="flex md:flex-row flex-col">
                        <p className="font-bold w-68">DNI</p>
                        <p>{initialValues.document_number}</p>
                      </div>
                    ) : (
                      <TextField
                        label="DNI"
                        size="2xl"
                        name="document_number"
                        validate={validations.mix(
                          validations.minLength(8),
                          validations.maxLength(8),
                          validations.isNumber(),
                          validations.required()
                        )}
                      />
                    )}
                  </div>
                  <hr className="w-full mb-1" />
                  <div className="flex flex-col items-start p-0 gap-2 w-full">
                    <div className={`flex ${!editByDetail ? `md:flex-row` : ``}  flex-col`}>
                      <p className="font-bold w-68">Contraseña</p>
                      {!editByDetail ? (
                        <p>********</p>
                      ) : (
                        <p className="mt-2">
                          Si habilitas este usuario en Facilita, se creará con la misma contraseña que tiene en GOB.PE.
                          Posterior al primer inicio de sesión, el funcionario podrá modificarla si lo desea.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-6 md:py-8 px-6 md:pl-8 bg-neutral-100">
                <h2 className="mb-4 font-bold text-xl">Datos requeridos por Facilita</h2>
                <div className="py-6 md:py-8 px-6 md:pl-8 bg-white">
                  {canFilterByBranchOffice && (
                    <SelectField
                      name="branch_office_id"
                      size="2xl"
                      options={dataToOptions(branchOffices)}
                      validate={validations.required()}
                      onChange={value => setBranchOfficeId(value)}
                      label="Sede"
                    />
                  )}
                  <SelectField
                    name="area_id"
                    size="2xl"
                    options={parsedAreas}
                    label="Área interna"
                    disabled={!canManageAreas}
                    validate={user.institution_has_enabled_procedures ? validations.required() : null}
                    onChange={() => handleChangeArea(form.change)}
                  />
                  <div className="flex flex-col md:flex-row">
                    <SelectField
                      name="role"
                      label="Tipo de perfil"
                      className="block md:w-96"
                      size="2xl"
                      options={dataToOptions(allowedRoles)}
                      validate={validations.required()}
                      disabled={!canEditProfileType}
                      margin={canEditManagePermission ? 'lowdense' : 'normal'}
                    />
                    <Value name="role">
                      {({ value }) => {
                        const typeDescription = getTypeDescription(value)
                        return typeDescription ? (
                          <Alert
                            type="info"
                            className="md:ml-4 md:mt-8 md:w-72 lg:w-125 xl:w-148 w-full"
                            border
                            hideIcon
                          >
                            {typeDescription}
                          </Alert>
                        ) : null
                      }}
                    </Value>
                  </div>
                  <WhenFieldChanges field="role" set="allowed_forms" to={null} />
                  <Value name="role">
                    {({ value: role }) =>
                      canShowAllowedData(role) && (
                        <Fragment>
                          {canEditManagePermission && (
                            <CheckboxField
                              name="permission.general.manage_permission"
                              label="Puede administrar sus permisos"
                            />
                          )}
                          {canChangeAllowedData(initialValues) && (
                            <MultipleSelectField
                              label="Formularios a los que tiene acceso"
                              options={parsedAllowedForms}
                              name="allowed_forms"
                              size="full"
                              margin={canEditCreateOrEditForms ? 'lowdense' : 'normal'}
                            />
                          )}
                          {canEditCreateOrEditForms && (
                            <CheckboxField
                              name="permission.forms.create_or_edit"
                              label="Puede crear y editar formularios"
                            />
                          )}
                          {canChangeAllowedData(initialValues) && (
                            <MultipleSelectField
                              label="Consultas rápidas a las que tiene acceso"
                              options={parsedQuickSearches}
                              name="allowed_quick_searches"
                              size="full"
                              margin={canEditCreateOrEditQuickSearches ? 'lowdense' : 'normal'}
                            />
                          )}
                          {canEditCreateOrEditQuickSearches && (
                            <CheckboxField
                              name="permission.quick_searches.create_or_edit"
                              label="Puede crear y editar consultas rápidas"
                            />
                          )}
                        </Fragment>
                      )
                    }
                  </Value>
                  {submitError ? <FormHelperText error>{submitError}</FormHelperText> : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row justify-end py-4 md:px-0">
              <Button variant="secondary" className="md:max-w-56 md:mr-3" size="full" onClick={() => history.goBack()}>
                Regresar
              </Button>
              <Button type="submit" className="md:max-w-56 mb-3 md:mb-0" size="full" disabled={submitting}>
                Crear usuario
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  )
}

UsersFormDetail.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  editByDetail: PropTypes.bool
}
