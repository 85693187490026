import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const FormSection = ({ title, children, className, bottomSpace = true }) => {
  return (
    <div className={clsx('bg-neutral-100 px-6 md:px-16 py-12 md:mt-6', bottomSpace && 'mb-10 md:mb-12', className)}>
      {title && <div className="font-bold text-xl md:text-2xl mb-6 md:mb-8">{title}</div>}
      {children}
    </div>
  )
}
export default FormSection

FormSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  bottomSpace: PropTypes.bool
}
