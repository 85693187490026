import { useEffect } from 'react'
import PropTypes from 'prop-types'

export const ScrollToElement = ({ elementId, trigger }) => {
  useEffect(() => {
    const element = document.querySelector(`#${elementId}`)
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }, 1)
    }
  }, [trigger])

  return null
}

ScrollToElement.propTypes = {
  elementId: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
