import PropTypes from 'prop-types'
import { getLabelResult } from '../../../configs/quickSearchesConfig'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { HtmlField } from '../../molecules/fields/HtmlField'
import PreviewModal from './PreviewModal'
import { Button } from '../../atoms/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import IconButton from '@material-ui/core/IconButton'
import FormSection from '../../molecules/FormSection'
import useToggle from '../../../hooks/useToggle'
import SpecialCodesModal from './SpecialCodesModal'
import { TextButton } from '../../molecules/buttons/TextButton'
import Tooltip from '../../atoms/Tooltip'

const canDelete = index => index >= 2
const isMaxLength = length => length === 27

const FooterResult = ({ fields, index }) => (
  <div className="bg-white flex justify-end">
    <PreviewModal index={index} result={fields.value[index]} />
    {canDelete(index) && (
      <div className="flex">
        <Tooltip title="Eliminar">
          <IconButton type="button" color="primary" classes={{ root: 'p-0' }} onClick={() => fields.remove(index)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Button
          type="button"
          variant="text"
          size="xs"
          className="mr-4"
          onClick={() => fields.remove(index)}
          classes={{ root: 'px-0' }}
        >
          Borrar
        </Button>
      </div>
    )}
  </div>
)

const ResultsSection = () => {
  const { fields } = useFieldArray('results')
  const [specialCodesModal, setSpecialCodesModal] = useToggle()

  return (
    <FormSection title="Respuestas">
      <p className="mb-6">
        Crea los tipos de respuesta que se mostrarán a las personas que hagan la consulta. De forma obligatoria, debes
        crear una respuesta para un documento o código que no esté en la base de datos. Adicionalmente, deberás crear
        una o más tipos de respuestas en caso el documento o código consultado sí se encuentre en la base.
      </p>
      {fields.map((name, index) => (
        <div key={index} className="mb-6">
          {index === 0 && (
            <>
              <p className="text-5.5 font-bold mb-2 mt-2 flex items-end">
                Respuesta para números consultados que no está en la base de datos (obligatorio)
              </p>
              <p>Sigue estas indicaciones para crear tu respuesta:</p>
              <ul className="list-disc ml-4 mb-8">
                <li className="mb-4">
                  Ingresa un <strong className="font-bold">texto corto que anuncie el resultado</strong>.
                  <br />
                  <strong aria-label="Ejemplo">Ej.: </strong>No te encuentras inscrito en el padrón.
                </li>
                <li>Si lo consideras necesario, puedes dar más información.</li>
              </ul>
            </>
          )}
          {index === 1 && (
            <>
              <p className="text-5.5 font-bold mb-2 flex items-end">
                Respuestas para números consultados que sí están en la base de datos
              </p>
              <p>Sigue estas indicaciones para crear tu respuesta:</p>
              <ul className="list-disc ml-4 mb-8">
                <li className="mb-4">
                  Escribe un <strong className="font-bold">texto corto que anuncie el resultado</strong>. Puedes usar
                  Sí/No al inicio de la oración.
                  <br /> Ej.: Sí te encuentras inscrito en el padrón. / No te encuentras inscrito en el padrón.
                </li>
                <li className="mb-4">
                  Luego, escribe el <strong className="font-bold">detalle del resultado de la consulta</strong>. Si
                  consideraste variables en tu base de datos, debes incluirlas en el texto de este campo.
                  <br />
                  <strong>Ej.: </strong>Has sido beneficiado con un bono de <strong className="font-bold">$1</strong>,
                  que te será entregado el <strong className="font-bold">$2 </strong>(Has sido beneficiado con el bono
                  de <strong>S/ 30 </strong>, que te será entregado el <strong className="font-bold">02/12/21</strong>
                  ).
                </li>
                <li>
                  Si tus resultados contienen <strong className="font-bold">montos de dinero</strong>, te recomendamos
                  usar los <TextButton onClick={setSpecialCodesModal}>códigos especiales</TextButton>.
                </li>
              </ul>
            </>
          )}
          <div className="bg-neutral-300">
            <div className="p-8">
              <p className="text-xl font-bold mb-2 flex items-end">
                {index > 0 && (
                  <>
                    Resultado:
                    {getLabelResult(index)}
                    {index < 2 && <span className="font-normal text-sm mb-1 ml-1">(obligatorio)</span>}
                  </>
                )}
              </p>
              <TextField
                name={`${name}.title`}
                label="Texto corto que anunciará el resultado"
                size="full"
                validate={validations.required()}
              />
              <HtmlField name={`${name}.hint`} label="Información adicional" validate={validations.required()} />
            </div>
          </div>
          <FooterResult fields={fields} index={index} />
        </div>
      ))}
      <div className="flex gap-6">
        <Button
          onClick={() => fields.push()}
          variant="secondary"
          size="full"
          startIcon={<AddIcon />}
          className="w-full md:max-w-56"
          disabled={isMaxLength(fields.length)}
        >
          Agregar resultado
        </Button>
      </div>
      {specialCodesModal && <SpecialCodesModal onClose={setSpecialCodesModal} />}
    </FormSection>
  )
}

export default ResultsSection

FooterResult.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
