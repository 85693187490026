import PropTypes from 'prop-types'
import React from 'react'
import Title from '../atoms/Title'
import { Button } from '../atoms/Button'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

const PageHeading = ({
  title,
  pageDescription,
  linksSection,
  button = false,
  customButton,
  filterSection,
  boxTableClasses,
  headingRightClass
}) => {
  return (
    <div className={clsx('md:pt-14 pt-8 w-full md:flex justify-between px-5 md:px-0', boxTableClasses || 'pb-10')}>
      <div className="md:w-2/3">
        <Title aria-describedby="page-description">{title}</Title>
        <p id="page-description">{pageDescription}</p>
        {filterSection && <div className="mt-6">{filterSection}</div>}
        {linksSection && <div className="hidden md:block mt-6">{linksSection}</div>}
      </div>
      <div className={clsx('md:w-1/3  md:flex m:flex-wrap', headingRightClass || 'md:justify-center')}>
        {button && (
          <Button
            size="full"
            el={button.href ? 'a' : Link}
            href={button.href}
            to={button.link}
            className={clsx('mt-6 md:mt-auto md:max-w-18 lg:max-w-sm text-base md:text-lg', button.className)}
            target={button.href ? '_blank' : '_self'}
            startIcon={button.icon}
            {...button}
          >
            {button.name}
          </Button>
        )}
        {customButton}
      </div>
      {linksSection && <div className="md:hidden mt-6">{linksSection}</div>}
    </div>
  )
}
export default PageHeading

PageHeading.propTypes = {
  boxTableClasses: PropTypes.string,
  headingRightClass: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  customButton: PropTypes.element,
  filterSection: PropTypes.element,
  linksSection: PropTypes.element,
  pageDescription: PropTypes.node,
  title: PropTypes.node
}
