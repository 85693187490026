import PropTypes from 'prop-types'
import React from 'react'
import QuestionCardFromTemplate from './QuestionCardFromTemplate'
import QuestionCardNew from './QuestionCardNew'
import QuestionCardSubtitleFromTemplate from './QuestionCardSubtitleFromTemplate'
import { createdFromTemplate } from '../../../configs/questionTypesAdminConfig'

const QuestionCard = props => {
  const question = props.fields.value[props.index]
  if (!createdFromTemplate(question) || props.editDeleteQuestions) return <QuestionCardNew {...props} />
  if (question.kind === 'subtitle') {
    return <QuestionCardSubtitleFromTemplate {...props} />
  } else {
    return <QuestionCardFromTemplate {...props} />
  }
}
export default QuestionCard

QuestionCard.propTypes = {
  fields: PropTypes.object,
  index: PropTypes.number,
  editDeleteQuestions: PropTypes.bool
}
