import PropTypes from 'prop-types'
import React from 'react'
import { FullNameField } from '../question_config_fields/FullNameField'
import { FullNameCitizenField } from '../question_fields/FullNameCitizenField'
import { ReactSVG } from 'react-svg'
import FullNameIcon from '../../../images/icons/fullname_icon.svg'

const Form = props => (
  <FullNameField disabled baseName={props.baseName} labelInitialValue="Nombres y apellidos del solicitante" />
)

const Template = props => <FullNameCitizenField payload={props.question.payload} name={props.name} disabled />

const FullNameApplicantQuestion = {
  selectOption: {
    value: 'full_name_applicant',
    label: 'Nombres y apellidos del solicitante',
    icon: <ReactSVG src={FullNameIcon} />
  },
  Form,
  Template
}

export default FullNameApplicantQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
