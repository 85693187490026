import PropTypes from 'prop-types'
import React from 'react'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckboxDoubleField from '../../organisms/question_config_fields/CheckboxDoubleField'
import CheckboxesCitizenField from '../question_fields/CheckboxesCitizenField'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'

const Form = props => {
  return <CheckboxDoubleField baseName={props.baseName} />
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <CheckboxDoubleField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <CheckboxesCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const Answer = props => {
  const content = props.answer?.map((item, key) => <p key={key}>{item}</p>)
  return <BoldLabelAndContent label={`${props.question.label}:`} content={content} el="li" />
}

const CheckboxDoubleQuestion = {
  selectOption: {
    value: 'checkboxes_double',
    label: 'Matriz con casillas (selección múltiple)',
    icon: <CheckBoxIcon fontSize="small" />
  },
  Form,
  Template,
  Answer,
  name: 'CheckboxDoubleQuestion'
}

export default CheckboxDoubleQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}

Answer.propTypes = {
  answer: PropTypes.array,
  question: PropTypes.object.isRequired
}
