import PropTypes from 'prop-types'
import React from 'react'
import LabelInputAndInfo from '../question_config_fields/LabelInputAndInfo'
import TextWithInfoField from '../../molecules/TextWithInfoField'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'

const infoContent =
  'Al usuario le aparecerá una lista con las entidades externas que tienen habilitada la gestión para este trámite.'

const Form = props => {
  return <LabelInputAndInfo baseName={props.baseName} infoContent={infoContent} />
}

const Template = props => {
  return <TextWithInfoField payload={props.question.payload} name={props.name} infoContent={infoContent} disabled />
}

const ExternalInstitutionQuestion = {
  selectOption: {
    value: 'external_institution',
    label: 'Entidad externa',
    icon: <AccountBalanceOutlinedIcon fontSize="small" />
  },
  Form,
  Template,
  name: 'ExternalInstitutionQuestion'
}

export default ExternalInstitutionQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
