import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import useArea from '../../../hooks/useArea'
import SelectField from '../../molecules/fields/SelectField'
import { dataToOptions } from '../../../utils/helpers'
import { isLoading } from '../../../hooks/useQuery'
import { Field, useForm } from 'react-final-form'
import * as validations from '../../../utils/validations'
import useFieldValue from '../../../hooks/useFieldValue'
import { useAuth } from '../../molecules/Auth'

const SelectArea = ({ userTypedId }) => {
  const { change } = useForm()
  const { user } = useAuth()
  const { useGetAreasByUserType } = useArea()
  const destinyBranchOfficeId = useFieldValue('special_task_data.destiny_branch_office_id')
  const destinyInstitutionId = useFieldValue('special_task_data.destiny_institution_id')
  const paramsForAreasByUserType = {
    branch_office_id: destinyBranchOfficeId || user.branch_office_id,
    institution_id: destinyInstitutionId || user.institution_id
  }
  const { data: areas = [], status } = useGetAreasByUserType(userTypedId, paramsForAreasByUserType)
  useEffect(() => change('destiny_area_id', areas[0]?.id), [areas])

  if (isLoading(status) || areas.length === 0) return null
  return areas.length === 1 ? (
    <Field component="input" className="hidden" name="destiny_area_id" initialValue={areas[0].id} />
  ) : (
    <div className="bg-neutral-200 p-6 mb-6">
      <SelectField
        label="Selecciona el área que recibirá la solicitud"
        name="destiny_area_id"
        options={dataToOptions(areas)}
        emptyLabel="Selecciona el área"
        margin="none"
        validate={validations.required()}
      />
    </div>
  )
}
export default SelectArea

SelectArea.propTypes = {
  userTypedId: PropTypes.number
}
