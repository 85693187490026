import PropTypes from 'prop-types'
import React from 'react'

const PinnedIcon = ({ pinned }) => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4922 20.3395C16.0813 20.7503 15.4148 20.7503 15.0039 20.3395L12.4611 17.7966L6.35163 21.827C6.14319 21.9659 5.86615 21.9371 5.69016 21.7611C5.51417 21.5851 5.48573 21.3084 5.62392 21.0993L9.6543 14.9898L7.11147 12.447C6.70094 12.0365 6.70094 11.37 7.1118 10.9591C8.24236 9.82856 9.90892 9.47022 11.6106 9.8202L13.8292 7.60158L13.138 6.91033C12.7274 6.4998 12.7274 5.83398 13.1383 5.42311L15.5721 2.98936C15.9829 2.57849 16.6491 2.57816 17.0596 2.98869L18.4946 4.42372L23.0279 8.95697L24.4623 10.3913C24.8735 10.8025 24.8728 11.4684 24.4619 11.8792L22.0282 14.313C21.6173 14.7238 20.9518 14.7242 20.5406 14.313L19.85 13.6224L17.6314 15.841C17.9811 17.5424 17.6227 19.2089 16.4922 20.3395Z"
      fill={pinned ? '#0056AC' : '#A8A9AB'}
    />
  </svg>
)

export default PinnedIcon

PinnedIcon.propTypes = {
  pinned: PropTypes.bool
}
