import React from 'react'
import useFilters from '../../../../hooks/useFilters'
import CollaboratorPage from '../../../organisms/enabled_procedure_managements/CollaboratorPage'
import CoordinatorPage from '../../../organisms/enabled_procedure_managements/CoordinatorPage'
import AdminPage from '../../../organisms/enabled_procedure_managements/AdminPage'
import { orderOptions } from '../../../../configs/enabledProcedureManagementsConfig'
import useUserPermission from '../../../../hooks/useUserPermission'

const EnabledProcedureManagements = () => {
  const { canSeeAllEnabledProcedureManagements, canManageEnabledProcedureManagements } =
    useUserPermission('procedureManagement')
  const options = orderOptions(canSeeAllEnabledProcedureManagements)

  const {
    initialFilters,
    filters,
    results: enabledProcedureManagements,
    onFilter
  } = useFilters(`/admin/v1/enabled_procedure_managements.json`, { sort: 'create_desc' })

  if (canSeeAllEnabledProcedureManagements)
    return (
      <AdminPage
        enabledProcedureManagements={enabledProcedureManagements}
        options={options}
        initialFilters={initialFilters}
        filters={filters}
        onFilter={onFilter}
      />
    )

  if (canManageEnabledProcedureManagements)
    return (
      <CoordinatorPage
        enabledProcedureManagements={enabledProcedureManagements}
        options={options}
        initialFilters={initialFilters}
        onFilter={onFilter}
      />
    )

  return (
    <CollaboratorPage
      onFilter={onFilter}
      enabledProcedureManagements={enabledProcedureManagements}
      options={options}
      initialFilters={initialFilters}
    />
  )
}
export default EnabledProcedureManagements
