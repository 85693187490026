import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { AlertContext } from '../../../utils/contexts'
import FormsCopyAlert from './FormsCopyAlert'
import { isError, isSuccessful } from '../../../hooks/useQuery'
import useToggle from '../../../hooks/useToggle'
import useSubscription from '../../../hooks/useSubscription'

const AlertContainer = ({ children }) => {
  const [form, setForm] = useState()
  const [newStreamData, setNewStreamData] = useState()
  const [isSubscriptionEnabled, setIsSubscriptionEnabled] = useToggle()
  const { streamData, channel } = useSubscription('FormCopyChannel', form?.id, isSubscriptionEnabled)

  useEffect(() => {
    if (streamData) {
      setNewStreamData(streamData)
    }
    if (isError(streamData?.state) || isSuccessful(streamData?.state)) {
      channel.unsubscribe()
      setIsSubscriptionEnabled()
    }
  }, [streamData])

  return (
    <AlertContext.Provider value={{ form, setForm, isSubscriptionEnabled, setIsSubscriptionEnabled, streamData }}>
      {children}
      <FormsCopyAlert
        streamData={{ state: isSubscriptionEnabled && 'loading', ...newStreamData }}
        form={form}
        onClose={setNewStreamData}
        channel={channel}
      />
    </AlertContext.Provider>
  )
}

export default AlertContainer

AlertContainer.propTypes = {
  children: PropTypes.node
}
