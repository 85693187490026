import PropTypes from 'prop-types'
import React from 'react'
import * as validations from '../../../utils/validations'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import ReferToBranchOfficeField from '../question_config_fields/ReferToBranchOfficeField'

const Form = props => {
  return <ReferToBranchOfficeField baseName={props.baseName} validate={validations.required()} />
}

const ReferToBranchOfficeQuestion = {
  selectOption: {
    value: 'refer_branch_office',
    label: 'Sede a donde se enviará la solicitud',
    icon: <AccountBalanceIcon fontSize="small" />
  },
  Form,
  name: 'ReferExternalEntityQuestion'
}

export default ReferToBranchOfficeQuestion

Form.propTypes = {
  baseName: PropTypes.string
}
