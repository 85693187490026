import useCrud from './useCrud'
import { serialize } from 'object-to-formdata'

const useTaskExecutions = (procedureId, accessId) => {
  const { create, destroy } = useCrud(`/admin/v1/procedures/${procedureId}/task_executions`)

  const createTaskExecution = async (values, options) => {
    const formData = serialize({ ...values, procedure_id: procedureId, access_id: accessId }, { indices: true })
    await create(formData, options)
  }

  const { mutate: deleteTaskExecution } = destroy({ access_id: accessId })

  return { createTaskExecution, deleteTaskExecution }
}
export default useTaskExecutions
