import React from 'react'
import Alert from '../../molecules/Alert'

const NoBranchOffices = () => {
  return (
    <div className="container mx-auto px-6 md:px-0">
      <Alert border className="py-6 pr-4">
        <div className="flex md:flex-row flex-col text-left font-normal">
          <p className="md:mr-10 mb-6 md:mb-0">
            <span className="font-bold"> Importante: </span>
            Actualmente, tu entidad no tiene sedes; puedes crearlas en Gob.pe, siguiendo los pasos de la{' '}
            <a
              href="https://guias.servicios.gob.pe/paginas-institucionales/sedes/index"
              target="_blank"
              rel="noreferrer"
            >
              guía elaborada
            </a>
            .
            <br />
            Si no tienes acceso a Gob.pe,{' '}
            <a href="https://www.gob.pe/11486" target="_blank" rel="noreferrer">
              solicítalo.
            </a>
            <br />
            Los usuarios, áreas y formularios que existen actualmente serán asignados a la primera sede que crees.
            Después, podrás modificar su asignación a las sedes que correspondan.
          </p>
        </div>
      </Alert>
    </div>
  )
}

export default NoBranchOffices
