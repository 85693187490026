import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Container from '../../atoms/Container'
import Form from './Form'
import { PageNavigation } from '../navigation/PageNavigation'

const PageNew = ({ headerInfo, createArticle, isTutorial }) => {
  const initialValues = useMemo(() => ({ sections: [{}] }), [])
  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <Form
          headerInfo={headerInfo}
          initialValues={initialValues}
          mutateForm={createArticle}
          isTutorial={isTutorial}
        />
      </Container>
    </>
  )
}
export default PageNew

PageNew.propTypes = {
  createArticle: PropTypes.func.isRequired,
  headerInfo: PropTypes.object,
  isTutorial: PropTypes.bool
}
