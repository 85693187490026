import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import RadioField from '../../molecules/fields/RadioField'
import * as validations from '../../../utils/validations'
import { paymentMethods } from '../../../configs/paymentMethodConfigs'
import PaymentMethodForm from './PaymentMethodForm'
import Value from '../../molecules/Value'
import FormStepHeader from '../citizens/FormStepHeader'
import axios from 'axios'
import { serialize } from 'object-to-formdata'

const payAnswer = (answerCode, values, config) =>
  axios.put(`/v1/answers/${answerCode}/pay.json`, values, config).then(res => res.data)

export const PaymentMethodFormSelectionCitizen = ({ send, state }) => {
  const [uploadProgress, setUploadProgress] = useState(null)
  const { hint, kind_payment: kindPayment } = state.context.form_data
  const valuesPayment = kindPayment.map(code => ({ value: code, label: paymentMethods[code].label }))
  const handleSubmit = async values => {
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    try {
      await payAnswer(state.context.answer_data.hashid, serialize(values), config)
      send('GO_NEXT')
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }
  return (
    <>
      <div className="hint-html-injected mt-6 mb-4 px-4 md:px-0" dangerouslySetInnerHTML={{ __html: hint }} />
      <FormStepHeader state={state} form={state.context.form_data} name="Realiza el pago" isPaymentPage />
      <Form
        onSubmit={handleSubmit}
        decorators={[focusOnFirstError]}
        initialValues={valuesPayment.length === 1 ? { selected_kind_payment: valuesPayment[0].value } : {}}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="p-4 md:p-8 bg-neutral-100">
            <RadioField
              name="selected_kind_payment"
              label="1. Método de pago"
              className="block"
              options={valuesPayment}
              validate={validations.required()}
            />
            <Value name="selected_kind_payment">
              {({ value: paymentMethod }) =>
                paymentMethod && (
                  <PaymentMethodForm
                    paymentMethod={paymentMethod}
                    kind="citizen"
                    context={state.context}
                    onBack={() => send('GO_BACK')}
                    uploadProgress={uploadProgress}
                  />
                )
              }
            </Value>
          </form>
        )}
      </Form>
    </>
  )
}

PaymentMethodFormSelectionCitizen.propTypes = {
  send: PropTypes.func.isRequired,
  state: PropTypes.object
}
