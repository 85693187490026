import React, { useState } from 'react'
import Container from '../../atoms/Container'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { onWheel, scrollInstitutions } from '../../../configs/homeConfig'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import useMediaQuery, { screenSizes } from '../../../hooks/useMediaQuery'
import LeftScrollNavigation from '../../molecules/carrousel/LeftScrollNavigation'
import RightScrollNavigation from '../../molecules/carrousel/RightScrollNavigation'
import FooterScrollNavigation from '../../molecules/carrousel/FooterScrollNavigation'

const elemPrefix = 'institution'
const getId = index => `${elemPrefix}${index}`
const getItems = () => scrollInstitutions.map((inst, ind) => ({ id: getId(ind), inst }))

const useStyles = makeStyles({
  scrollMenuDesktop: {
    minWidth: `calc(25% - 9px)`
  },
  scrollMenuTablet: {
    minWidth: `calc(33% - 8px)`
  },
  scrollMenuMobile: {
    minWidth: `calc(50% - 12px)`
  }
})

export const Institutions = () => {
  const [items] = useState(getItems)
  const classes = useStyles()
  const matchDesktopSize = useMediaQuery(screenSizes.desktop)
  const matchTabletSize = useMediaQuery(screenSizes.tablet) && !matchDesktopSize
  const matchMobileSize = !matchTabletSize && !matchDesktopSize

  let itemsPerPage = 2
  if (matchTabletSize) itemsPerPage = 3
  if (matchDesktopSize) itemsPerPage = 4

  return (
    <section className="py-14 md:pt-20 md:pb-24 px-8 md:px-0 bg-blue-300">
      <Container className="md:px-36">
        <h2 className="mb-8 md:mb-20 font-bold text-xl md:text-7.5 text-center">
          Conoce las entidades que usan Facilita Perú
        </h2>
        <div className="my-8">
          <ScrollMenu
            LeftArrow={LeftScrollNavigation}
            RightArrow={RightScrollNavigation}
            Footer={() => <FooterScrollNavigation itemsPerPage={itemsPerPage} />}
            onWheel={onWheel}
            wrapperClassName="flex justify-between items-center"
            scrollContainerClassName="gap-1.5"
            itemClassName={clsx(
              'flex items-center justify-center h-36 bg-white p-2 rounded-lg',
              matchDesktopSize && classes.scrollMenuDesktop,
              matchTabletSize && classes.scrollMenuTablet,
              matchMobileSize && classes.scrollMenuMobile
            )}
          >
            {items.map(({ id, inst }) => (
              <Card inst={inst} itemId={id} key={id} />
            ))}
          </ScrollMenu>
        </div>
      </Container>
    </section>
  )
}

const Card = ({ inst }) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        margin: '0 10px',
        height: '80px',
        userSelect: 'none'
      }}
      className="card items-center"
    >
      <div>
        <a href={inst.url} target="_blank" rel="noreferrer">
          <img src={inst.logo} alt={inst.name} className="lg:w-full max-h-20 lg:max-w-50" loading="lazy" />
        </a>
      </div>
    </div>
  )
}

Card.propTypes = {
  inst: PropTypes.object
}
