import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const colors = {
  green: 'bg-green-500 text-green-800 border-green-600',
  yellow: 'text-yellow-800 bg-yellow-300 border-yellow-700',
  orange: 'text-orange-800 bg-orange-400 border-orange-600',
  red: 'text-strawberry-800 bg-strawberry-500 border-strawberry-700',
  purple: 'text-purple-800 bg-purple-400 border-purple-500',
  blue: 'text-blue-700 bg-blue-400 border-blue-600'
}
const TagState = ({ state, className }) => {
  return (
    <p className={clsx('w-max font-bold text-xs px-3 py-1 my-1 inline-block border', colors[state.color], className)}>
      {state.name}
    </p>
  )
}
export default TagState

TagState.propTypes = {
  className: PropTypes.string,
  state: PropTypes.object.isRequired
}
