import useCrud from './useCrud'

const urlBaseForm = `/admin/v1/forms_quick_searches`

const useFormQuickSearch = () => {
  const { get: getFormsQuickSearches } = useCrud(urlBaseForm)

  return {
    getFormsQuickSearches
  }
}
export default useFormQuickSearch
