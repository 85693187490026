import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isEllipsisActive } from '../../utils/helpers'
import Tooltip from '../atoms/Tooltip'

const useStyles = makeStyles({
  popper: {
    top: '-13px !important'
  }
})

export const TruncatedTextWithTooltip = ({ text, children, clampedRows = 3 }) => {
  const [showToolTip, setShowToolTip] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (isEllipsisActive(ref.current)) {
      setShowToolTip(true)
    }
  }, [])

  const classes = useStyles()
  return (
    <Tooltip title={text} placement="bottom-start" classes={classes} disableHoverListener={!showToolTip}>
      {children ? (
        children({ ref, className: 'truncate-text-x-rows', style: { WebkitLineClamp: clampedRows } })
      ) : (
        <span ref={ref} className="truncate-text-x-rows" style={{ WebkitLineClamp: clampedRows }}>
          {text}
        </span>
      )}
    </Tooltip>
  )
}

TruncatedTextWithTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  clampedRows: PropTypes.number,
  text: PropTypes.string
}
