import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import ChevronDown from 'images/chevron-down.svg'
import ChevronUp from 'images/chevron-up.svg'
import * as validations from '../../../utils/validations'
import RadioField from '../../molecules/fields/RadioField'
import { Button } from '../../atoms/Button'
import TextField from '../../molecules/fields/TextField'
import clsx from 'clsx'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { senderTypes } from '../../../configs/feedbaksConfig'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import FieldTitle from '../../molecules/FieldTitle'
import Value from '../../molecules/Value'
import useFeedback from '../../../hooks/useFeedback'
import { isSuccessful } from '../../../hooks/useQuery'
import { useTranslation } from 'react-i18next'
import useTextValidation from '../../../hooks/useTextValidation'

const FeedbackForm = ({ onFeedbackSent, senderType }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { createFeedback, getReasons } = useFeedback(senderType, onFeedbackSent)
  const { data: reasonOptions, status } = getReasons(
    ['reasons', senderType],
    { sender_type: senderType },
    {
      refetchOnWindowFocus: false,
      enabled: isOpen
    }
  )

  const { t, ready } = useTranslation('feedback', { useSuspense: false })
  const translationText = useTextValidation()
  const handleSubmit = values => createFeedback(values, { onSuccess: onFeedbackSent })

  if (!ready) return null
  return (
    <>
      <button
        className="flex justify-between font-bold hover:bg-neutral-300 p-4 md:p-8 text-blue-700 border-b-3 border-white w-full items-center"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls="feedback-form-content"
      >
        <span className="text-center">{t(senderTypes[senderType].formTitle)}</span>
        <img loading="lazy" src={isOpen ? ChevronUp : ChevronDown} alt="" className="w-4 h-4" />
      </button>
      {isSuccessful(status) && (
        <div className={clsx('flex flex-col ', { hidden: !isOpen })} hidden={!isOpen} id="feedback-form-content">
          <Form onSubmit={handleSubmit}>
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className="p-4 md:p-8 flex flex-col">
                <RadioField
                  name="reason_id"
                  label={t('feedback_tell_us_how_we_can_improve')}
                  options={reasonOptions.map(option => ({ value: option.id, label: option.name }))}
                  validate={validations.required(translationText.required)}
                  nsTranslation="feedback"
                />
                <TextField
                  name="comment"
                  label={t('feedback_describe_your_suggestion_and_or_issue')}
                  hint={t('feedback_we_dont_respond_but_we_do_read_all_messages')}
                  element="textarea"
                  size="full"
                  rows={5}
                  margin="high"
                  validate={validations.mix(
                    validations.required(translationText.required),
                    validations.minLength(3, translationText.minLenght(3)),
                    validations.maxLength(256, translationText.maxLenght(256))
                  )}
                />
                {(senderType === 'citizen' || senderType === 'citizen_quick_search') && (
                  <>
                    <FieldTitle
                      title={t('feedback_contact_information')}
                      hint={
                        <CheckboxField
                          name="contact_the_sender"
                          margin="none"
                          label={t('feedback_i_want_to_be_contacted_to_help_improve_Gob')}
                        />
                      }
                    />
                    <Value name="contact_the_sender">
                      {({ value }) =>
                        value && (
                          <div className="bg-neutral-300 px-4 py-3 mb-2 mt-2">
                            <TextField
                              name="sender_name"
                              label={t('feedback_first_and_last_name')}
                              size="full"
                              margin="normal"
                              validate={validations.required(translationText.required)}
                            />
                            <TextField
                              name="sender_email"
                              label={t('feedback_email')}
                              size="full"
                              margin="normal"
                              validate={validations.mix(
                                validations.required(translationText.required),
                                validations.isEmail(translationText.isEmail)
                              )}
                            />
                            <TextField
                              name="phone"
                              label={
                                <span className="font-bold">
                                  {t('feedback_phone_number')}
                                  <span className="ml-1 font-normal">({t('feedback_optional')})</span>
                                </span>
                              }
                              size="full"
                              margin="none"
                              validate={validations.isPhone(translationText.isPhone)}
                            />
                          </div>
                        )
                      }
                    </Value>
                    <FieldsSeparator className="mt-8 mb-8" />
                    <CheckboxField
                      name="accepts_privacy_policy"
                      validate={validations.required(translationText.required)}
                      margin="dense"
                      label={
                        <p>
                          {t('feedback_i_accept_the')}{' '}
                          <a
                            href="https://www.gob.pe/institucion/pcm/informes-publicaciones/1991056-politica-de-privacidad-de-retroalimentacion-de-formularios-en-facilita"
                            target="_blank"
                            className="font-bold"
                            rel="noreferrer"
                          >
                            {t('feedback_privacy_policy')}
                          </a>
                        </p>
                      }
                    />
                  </>
                )}
                <Button type="submit" disabled={submitting} size="m" className="self-center h-13">
                  {t('feedback_button_submit')}
                </Button>
              </form>
            )}
          </Form>
        </div>
      )}
    </>
  )
}

export default FeedbackForm

FeedbackForm.propTypes = {
  onFeedbackSent: PropTypes.func,
  senderType: PropTypes.string
}
