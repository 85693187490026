import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import SelectField from '../fields/SelectField'

const SelectFieldFilter = ({ name, label, options, className, emptyLabel, margin, onChange }) => {
  const _options = options.map(option => ({ value: option.code || option.id.toString(), label: option.name }))
  return (
    <SelectField
      name={name}
      label={label}
      className={clsx('block', className)}
      options={_options}
      margin={margin}
      size="2xl"
      emptyLabel={emptyLabel}
      onChange={onChange}
    />
  )
}

export default SelectFieldFilter

SelectFieldFilter.propTypes = {
  className: PropTypes.string,
  emptyLabel: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  margin: PropTypes.string,
  onChange: PropTypes.func
}
