import LogoReniec from 'images/escudos/logo_reniec.png'
import LogoMuniSanBartolo from 'images/escudos/logo_muni_san_bartolo.png'
import LogoMuniMaranon from 'images/escudos/logo_muni_maranon.png'
import LogoMuniMajes from 'images/escudos/logo_muni_majes.png'
import LogoMuniLobitos from 'images/escudos/logo_muni_lobitos.png'
import LogoMINEDU from 'images/escudos/logo_MINEDU.png'
import LogoMIMP from 'images/escudos/logo_MIMP.png'
import LogoMariaAuxiliadora from 'images/escudos/logo_maria_auxiliadora.png'
import LogoGrCusco from 'images/escudos/logo_gr_cusco.png'
import LogoGrAmazonas from 'images/escudos/logo_gr_amazonas.png'
import LogoEssalud from 'images/escudos/logo_essalud.png'

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15
  if (isThouchpad) {
    ev.stopPropagation()
    return
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext()
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev()
  }
}

const scrollInstitutions = [
  {
    logo: LogoReniec,
    url: 'https://www.gob.pe/reniec',
    name: 'Reniec'
  },
  {
    logo: LogoMuniSanBartolo,
    url: 'https://www.gob.pe/munisanbartolo',
    name: 'Municipalidad de San Bartolo'
  },
  {
    logo: LogoMuniMaranon,
    url: 'https://www.gob.pe/munimaranon',
    name: 'Municipalidad de Marañon'
  },
  {
    logo: LogoMuniMajes,
    url: 'https://www.gob.pe/munimajes',
    name: 'Municipalidad de Majes'
  },
  {
    logo: LogoMuniLobitos,
    url: 'https://www.gob.pe/munilobitos',
    name: 'Municipalidad de Lobitos'
  },
  {
    logo: LogoMINEDU,
    url: 'https://www.gob.pe/minedu',
    name: 'Ministerio de Educación'
  },
  {
    logo: LogoMIMP,
    url: 'https://www.gob.pe/mimp',
    name: 'Ministerio de la Mujer y Poblaciones Vulnerables'
  },
  {
    logo: LogoMariaAuxiliadora,
    url: 'https://www.gob.pe/hma',
    name: 'Hospital Maria Auxiliadora'
  },
  {
    logo: LogoGrCusco,
    url: 'https://www.gob.pe/regioncusco',
    name: 'Gobierno Regional Cusco'
  },
  {
    logo: LogoGrAmazonas,
    url: 'https://www.gob.pe/regionamazonas',
    name: 'Gobierno Regional Amazonas'
  },
  {
    logo: LogoEssalud,
    url: 'https://www.gob.pe/essalud',
    name: 'Essalud'
  }
]

export { onWheel, scrollInstitutions }
