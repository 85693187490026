import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import AutoSaveForm from '../../molecules/AutoSaveForm'
import useBranchOffice from '../../../hooks/useBranchOffice'
import useSearchParams from '../../../hooks/useSearchParams'
import useFilters from '../../../hooks/useFilters'
import { createForm } from 'final-form'
import { useAuth } from '../../molecules/Auth'
import AutocompleteFieldFilter from '../../molecules/filters/AutocompleteFieldFilter'
import { isLoading, isSuccessful } from '../../../hooks/useQuery'
import useToggle from '../../../hooks/useToggle'
import useInstitution from '../../../hooks/useInstitution'

const BranchOfficeFilter = ({ all = false, hasWithOutOption = false, byType, onChangeBranchRequired }) => {
  const { user } = useAuth()
  const [isFirstRender, toggleIsFirstRender] = useToggle(true)
  const searchParams = useSearchParams()
  const institutionId = searchParams.get('institution_id') || user.institution_id
  const { getBranchOffices } = useBranchOffice(institutionId, byType)
  const { data: branchOffices = [], status: branchOfficeStatus } = getBranchOffices()
  const { getInstitution } = useInstitution()
  const { data: institution, status: institutionStatus } = getInstitution(institutionId)
  const { onFilter, initialFilters } = useFilters('', { branch_office_id: searchParams.get('branch_office_id') })

  const formRef = useRef(
    createForm({
      onSubmit: onFilter,
      initialValues: initialFilters
    })
  )

  useEffect(() => {
    if (isLoading(branchOfficeStatus) || isLoading(institutionStatus)) {
      return null
    }

    if (initialFilters?.branch_office_id && isFirstRender) {
      toggleIsFirstRender()
      return null
    }

    if (branchOffices?.length < 1 || all) {
      formRef.current.change('branch_office_id', undefined)
      onChangeBranchRequired && onChangeBranchRequired(false)
      return null
    }

    if (branchOffices?.length > 0 && hasWithOutOption) {
      const firstBranchId = parseInt(initialFilters?.branch_office_id) || user?.branch_office_id
      const myBranchInList = branchOffices.find(el => el.id === firstBranchId)
      const primaryBranch = branchOffices.find(el => el.primary_branch)
      const branchOfficeId = myBranchInList?.id || primaryBranch?.id || branchOffices[0].id
      if (institution?.need_migration && institution?.has_users_or_areas_without_branch_office) {
        return formRef.current.change('branch_office_id', 'to_be_assigned')
      }
      if (!institution?.need_migration && institution?.has_users_or_areas_without_branch_office) {
        return formRef.current.change('branch_office_id', 'all_branches')
      }
      return formRef.current.change('branch_office_id', branchOfficeId.toString())
    }
    onChangeBranchRequired && onChangeBranchRequired(true)
  }, [branchOffices, institution])

  if (!institutionId || !branchOffices) return null

  const addOptions = branchOffices => {
    const toBeAssignedOp = { name: 'Por asignar', code: 'to_be_assigned' }
    const allBranchesOp = { name: 'Todas las sedes', code: 'all_branches' }
    let options = [...branchOffices]
    if (institution?.has_users_or_areas_without_branch_office) {
      options = [toBeAssignedOp, ...options]
    }
    if (!institution?.need_migration) {
      options = [allBranchesOp, ...options]
    }
    return options
  }

  const options = hasWithOutOption ? addOptions(branchOffices) : branchOffices
  const params = {
    options: options || [],
    defaultOptionLabel: all && 'Todas las sedes',
    name: 'branch_office_id',
    boxClassName: 'min-w-72',
    margin: 'none',
    status: branchOfficeStatus
  }

  return branchOffices.length && isSuccessful(institutionStatus) ? (
    <AutoSaveForm className="md:w-auto w-full" onSubmit={onFilter} initialValues={initialFilters} formRef={formRef}>
      <AutocompleteFieldFilter {...params} />
    </AutoSaveForm>
  ) : null
}

export default BranchOfficeFilter

BranchOfficeFilter.propTypes = {
  all: PropTypes.bool,
  byType: PropTypes.string,
  onChangeBranchRequired: PropTypes.func.isRequired,
  hasWithOutOption: PropTypes.bool
}
