import PropTypes from 'prop-types'
import React from 'react'
import EmailCitizenField from './EmailCitizenField'
import { EmailPhoneCitizenField } from './EmailPhoneCitizenField'
import PhoneCitizenField from './PhoneCitizenField'

export const EmailPhoneApplicantCitizenField = props => {
  const component = {
    email: EmailCitizenField,
    cellphone: PhoneCitizenField,
    email_cellphone: EmailPhoneCitizenField
  }
  const contactMedium = props?.payload?.contact_medium
  const El = component[contactMedium]
  return El && <El {...props} />
}

EmailPhoneApplicantCitizenField.propTypes = {
  payload: PropTypes.object
}
