import { senderTypes } from '../configs/feedbaksConfig'
import useCrud from './useCrud'

const useFeedback = senderType => {
  const createUrl = senderTypes[senderType].urlCreate
  const { create } = useCrud(createUrl)
  const { get: getReasons } = useCrud(`/v1/feedbacks/reasons`)

  const createFeedback = (values, options) => {
    return create({ ...values, sisoli: 'sisoli' }, options)
  }

  return { createFeedback, getReasons }
}

export default useFeedback
