import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useErrorWithinPaper } from '../organisms/forms/PaperContainerErrorsHandler'
import { useCustomField } from '../../hooks/useCustomField'
import ErrorValidationMessage from './ErrorValidationMessage'

const Error = ({ name, className, showInfoIcon = false, touched = true }) => {
  const { error, gotError, input } = useCustomField(name, {
    subscription: { touched, error: true, submitError: true, dirtySinceLastSubmit: true }
  })

  const context = useErrorWithinPaper()

  useEffect(() => {
    context?.setError(name, gotError)
  }, [gotError])

  useEffect(() => {
    return () => {
      context?.setError(input.name, false)
    }
  }, [])

  return gotError ? <ErrorValidationMessage error={error} className={className} showInfoIcon={showInfoIcon} /> : null
}

export default Error

Error.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  showInfoIcon: PropTypes.bool,
  touched: PropTypes.bool
}
