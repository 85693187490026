import PropTypes from 'prop-types'
import React from 'react'
import RadioField from '../../molecules/fields/RadioField'
import { paymentMethods } from '../../../configs/paymentMethodConfigs'
import * as validations from '../../../utils/validations'
import Value from '../../molecules/Value'
import PaymentMethodForm from '../payments/PaymentMethodForm'
import { numberFormatter } from '../../../utils/helpers'

const SpecialTaskFormMakePayment = ({
  special_task_data: specialTaskData,
  institution,
  enabled_procedure_management: enabledProcedureManagement
}) => {
  return (
    <>
      <div className="mb-6">
        El monto a pagar es de <span className="font-bold">{numberFormatter.currency(specialTaskData.cost)}</span>
      </div>
      <RadioField
        name="selected_kind_payment"
        label="1. Método de pago"
        className="block"
        options={specialTaskData.payment_methods.map(code => ({ value: code, label: paymentMethods[code].label }))}
        validate={validations.required()}
      />
      <Value name="selected_kind_payment">
        {({ value: paymentMethod }) =>
          paymentMethod && (
            <PaymentMethodForm
              paymentMethod={paymentMethod}
              kind="procedure"
              cost={specialTaskData.cost}
              institution={institution}
              paymentTransferAccounts={enabledProcedureManagement.payment_transfer_accounts}
            />
          )
        }
      </Value>
    </>
  )
}
export default SpecialTaskFormMakePayment

SpecialTaskFormMakePayment.propTypes = {
  institution: PropTypes.object,
  special_task_data: PropTypes.exact({
    cost: PropTypes.number,
    payment_methods: PropTypes.array.isRequired
  }),
  enabled_procedure_management: PropTypes.array
}
