import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useField } from 'react-final-form'
import SelectField from '../../molecules/fields/SelectField'
import useFieldValue from '../../../hooks/useFieldValue'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import { dataToOptions } from '../../../utils/helpers'

const UserTypeFilter = ({ userTypeList, onChange, stateUpdater }) => {
  const optionMapped = userTypeList.map(value => ({ ...value, accessId: value.id, id: value.user_type_id }))
  const options = dataToOptions(optionMapped)
  const { input: stateInput } = useField('state')

  const userTypeId = useFieldValue('user_type_id')
  const userTypeEl = userTypeList.find(value => value.user_type_id === Number(userTypeId))
  const accessIdValue = userTypeEl?.id
  const areaId = userTypeEl?.area_id

  useEffect(() => {
    stateInput.onChange(stateUpdater)
    onChange(true)
  }, [stateUpdater])

  return (
    <>
      <SelectField
        defaultValue={options[0].value}
        className="mb-4"
        options={options}
        name="user_type_id"
        onChange={() => onChange(false)}
      />
      <WhenFieldChanges field="user_type_id" set="access_id" to={accessIdValue} />
      <WhenFieldChanges field="access_id" set="area_id" to={areaId} />
    </>
  )
}
export default UserTypeFilter

UserTypeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  stateUpdater: PropTypes.array,
  userTypeList: PropTypes.array
}
