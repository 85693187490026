import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import * as uuid from 'uuid'
import { Field } from 'react-final-form'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { useFieldArray } from 'react-final-form-arrays'
import useAutoScroll from '../../../hooks/useAutoScroll'
import * as validations from '../../../utils/validations'
import Error from '../../molecules/Error'
import TextField from '../../molecules/fields/TextField'

const TextListField = ({ name, label, aria }) => {
  const { fields } = useFieldArray(name, { validate: validations.requiredArray('Ingresa al menos dos opciones') })
  const containerRef = useAutoScroll(fields.length)
  const showHr = fields.length > 5

  useEffect(() => {
    !fields.length && fields.push()
  }, [fields.length])

  return (
    <div className="js-field-container">
      <fieldset>
        <legend className="font-bold flex items-center">{label}</legend>
        {showHr && <hr className="h-0.5 bg-neutral-400 my-4" />}
        <div className="overflow-y-auto max-h-80" ref={containerRef}>
          {fields.map((name, index) => (
            <div className="flex items-center sm:max-w-sm mt-3" key={index}>
              <TextField
                ariaLabel={`${aria} ${index + 1}`}
                name={`${name}.label`}
                margin="none"
                size="full"
                rootClassName="flex-1"
                validate={validations.required()}
              />
              <Field
                component="input"
                className="hidden"
                name={`${name}.id`}
                initialValue={fields?.value[index]?.id || uuid.v4()}
              />
              <IconButton
                className={clsx('self-start -mt-1', !index && 'invisible')}
                aria-label="Eliminar opcion"
                onClick={() => fields.remove(index)}
                type="button"
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
        {showHr && <hr className="h-0.5 bg-neutral-400 mt-3 mb-4" />}
      </fieldset>
      <IconButton
        type="button"
        color="primary"
        onClick={() => fields.push()}
        classes={{ root: 'p-0 -ml-1.5' }}
        aria-label={`Agregar ${aria}`}
      >
        <AddBoxIcon style={{ fontSize: 50 }} />
      </IconButton>
      <div className="relative">
        <Error name={fields.name} />
      </div>
    </div>
  )
}

export default TextListField

TextListField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  aria: PropTypes.string
}
