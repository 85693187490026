import axios from 'axios'
import useCrud from './useCrud'
import useInvalidateQueries from './useInvalidateQueries'

export const urlFetch = '/admin/v1/users'

const useUser = () => {
  const { findById, destroy, update } = useCrud(urlFetch)
  const invalidateQueries = useInvalidateQueries()

  const getUser = id => findById(['users', id])

  const { get: getVerify } = useCrud(`${urlFetch}/verify`)

  const getVerifyByEmail = email => getVerify(['email', email], { email }, { enabled: Boolean(email) })

  const { get: getUserFromGobpe } = useCrud(`${urlFetch}/show_by_email_in_gobpe`)

  const getUserFromGobpeByEmail = email => getUserFromGobpe(['email', email], { email }, { enabled: Boolean(email) })

  const { create: createByFacilita } = useCrud(`${urlFetch}/create_by_facilita`)

  const { mutate: updateUser } = update()

  const { mutate: deleteUser, error: errorDeleteUser } = destroy()

  const updateEnabledUser = (userId, enabled) => () =>
    axios.put(`${urlFetch}/${userId}/update_enabled.json`, { enabled })

  const invalidateUsers = params => invalidateQueries(params)

  return {
    getUser,
    getVerifyByEmail,
    getUserFromGobpeByEmail,
    createByFacilita,
    deleteUser,
    errorDeleteUser,
    updateUser,
    updateEnabledUser,
    invalidateUsers
  }
}

export default useUser
