import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import i18n from 'i18next'

export const useFormTranslation = formId => {
  const history = useHistory()
  const currentLanguage = i18n.language || 'es'
  const { procedureManagementId } = useParams()

  const updateFormTranslation = async values => {
    const transformedValues = { ...values }

    transformedValues.steps_attributes.forEach(step => {
      if (step.questions_attributes) {
        step.questions_attributes.forEach(question => {
          if (question.children_attributes) {
            const children = question.children_attributes
            const newChildren = {}
            Object.keys(children).forEach((key, index) => {
              if (children[key].length > 0) {
                newChildren[index] = { ...children[key][0] }
              }
            })
            question.children_attributes = newChildren
          }
        })
      }
    })

    try {
      await axios.put(`/admin/v1/forms/${formId}.json?locale=${currentLanguage}`, transformedValues)
      if (procedureManagementId) {
        history.push(`/gestiones-de-tramites/${procedureManagementId}/traducciones?action=translation_saved`)
      } else {
        history.push(`/formularios/${formId}/traducciones?action=translation_saved`)
      }
    } catch (error) {
      alert(error)
    }
  }

  const handleSubmit = async values => {
    try {
      await updateFormTranslation(values)
    } catch (error) {
      alert(error)
    }
  }
  return { handleSubmit }
}
