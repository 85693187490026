import React from 'react'
import ErrorAccessPage from '../../templates/ErrorAccessPage'
import PublicFooter from '../../organisms/footer/PublicFooter'

const AdminNotFound = () => {
  return (
    <>
      <div className="mb-28">
        <ErrorAccessPage
          importantText="Lo sentimos, la página no existe."
          description="Puede que hayas escrito mal la dirección, que la hayan cambiado o eliminado."
          textButton="Ir a la página de inicio"
          linkButton="/"
        />
      </div>
      <PublicFooter />
    </>
  )
}

export default AdminNotFound
