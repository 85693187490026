import React from 'react'
import Container from '../../../atoms/Container'
import PropTypes from 'prop-types'
import { UsersFormDetail } from '../../../organisms/areas_users/UsersFormDetail'
import useSearchParams from '../../../../hooks/useSearchParams'
import useUser from '../../../../hooks/useUser'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const UsersDetailFromGobpe = ({ title = 'Crear usuario', editByDetail }) => {
  const searchParams = useSearchParams()
  const email = searchParams.get('email')
  const { getUserFromGobpeByEmail, createByFacilita } = useUser()
  const { data, status } = getUserFromGobpeByEmail(email)
  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="py-12 md:px-0">
        <h1 className="text-2xl font-bold mb-4">{title}</h1>
        <UsersFormDetail initialValues={data} onSave={createByFacilita} editByDetail={editByDetail} />
      </Container>
    </>
  )
}

UsersDetailFromGobpe.propTypes = {
  title: PropTypes.string,
  editByDetail: PropTypes.bool
}

export default UsersDetailFromGobpe
