import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import clsx from 'clsx'
import Close from '../../atoms/icons/Close'

const CloseButton = ({ onClose }) => {
  return (
    <Button
      type="button"
      variant="text"
      fullWidth={false}
      onClick={onClose}
      className={clsx('font-bold text-sm mb-0')}
      endIcon={<Close />}
    >
      Cerrar
    </Button>
  )
}

export default CloseButton

CloseButton.propTypes = {
  onClose: PropTypes.func
}
