import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import EditButton from '../../molecules/buttons/EditButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import NoBranchOffices from './NoBranchOffices'
import { useAuth } from '../../molecules/Auth'

const BranchOfficesTable = ({ branchOffices = [] }) => {
  const { user } = useAuth()

  return (
    <Table
      className="table-auto w-full"
      values={branchOffices}
      notValues={
        user.institution_has_branch_offices === false ? (
          <Tr>
            <Td>
              <NoBranchOffices />
            </Td>
          </Tr>
        ) : null
      }
      head={
        <Tr body={false}>
          <Th className="text-center">ID</Th>
          <Th>Nombre</Th>
          <Th>Ubicación</Th>
          <Th>Coordinador de sede</Th>
          <Th>Cantidad de usuarios</Th>
          <Th>Acciones</Th>
        </Tr>
      }
      body={branchOffices?.map(branchOffice => (
        <Tr key={branchOffice.id}>
          <Td className="text-center">{branchOffice.id}</Td>
          <Td>{branchOffice.name}</Td>
          <Td>{branchOffice.location}</Td>
          <Td>
            {branchOffice.coordinators.length ? (
              <div className="flex flex-col">
                {branchOffice.coordinators.map(coordinator => (
                  <Link to={`/usuarios/${coordinator.id}/editar`} key={coordinator.id}>
                    {coordinator.name}
                  </Link>
                ))}
              </div>
            ) : (
              <span className="italic">Sin coordinador</span>
            )}
          </Td>
          <Td>
            <Link
              to={`areas-y-usuarios?institution_id=${branchOffice.institution_id}&branch_office_id=${branchOffice.id}`}
            >
              {branchOffice.users_count}
            </Link>
          </Td>
          <Td>
            <EditButton
              component="a"
              target="_blank"
              rel="noreferrer"
              text="Editar en gob.pe"
              href={`${window.sisoli.gobpe_url}/admin2/sedes/${branchOffice.gobpe_id}/edit`}
            />
          </Td>
        </Tr>
      ))}
    />
  )
}
export default BranchOfficesTable

BranchOfficesTable.propTypes = {
  branchOffices: PropTypes.arrayOf(PropTypes.object)
}
