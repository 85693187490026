import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'

const { admin } = roles

const Payment = () => {
  const hasRolePermissions = useHasRolePermissions()

  const canEnablePayment = hasRolePermissions([admin])

  return { canEnablePayment }
}

export default Payment
