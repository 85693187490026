import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

const FooterScrollNavigation = ({ itemsPerPage }) => {
  const visibility = useContext(VisibilityContext)
  const { items, visibleElements } = visibility

  if (!visibleElements.length) {
    return null
  }

  const totalWithSeparators = items?.size + 1
  const total = totalWithSeparators / 2
  const totalPages = Math.ceil(total / itemsPerPage)
  const pages = Array.from({ length: totalPages }, (_, ind) => ind + 1)

  const lastVisibleInd = items.get(visibleElements.slice(-1)[0])?.index
  const currentPage = Math.ceil(lastVisibleInd / itemsPerPage)

  const scrollToPage = page => {
    const itemInd = page * itemsPerPage - 1
    const itemByIndx = visibility.getItemByIndex(itemInd) || visibility.getItemByIndex(total - 1)

    visibility.scrollToItem(itemByIndx)
  }

  return (
    <div className="flex justify-center gap-2 mt-6">
      {pages.map(page => (
        <button
          key={page}
          onClick={() => scrollToPage(page)}
          onKeyDown={ev => {
            if (ev.code === 'Space') {
              scrollToPage(page)
            }
          }}
          className={`w-4 h-4 rounded-full transition-colors ${
            page === currentPage ? 'bg-blue-700' : 'bg-neutral-400'
          }`}
        />
      ))}
    </div>
  )
}

export default FooterScrollNavigation

FooterScrollNavigation.propTypes = {
  itemsPerPage: PropTypes.number
}
