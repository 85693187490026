import PropTypes from 'prop-types'
import React from 'react'
import { ChildQuestion } from './ChildQuestion'
import useFieldValue from '../../../hooks/useFieldValue'

const getChildQuestions = (question, selectedEl) => {
  const selectedText = typeof selectedEl === 'string' ? selectedEl : selectedEl.selected
  const selectedId = question.payload.alternatives.find(a => a.label === selectedText)?.id
  return question?.children_attributes?.[selectedId]
}

export const ChildrenQuestions = ({ baseName, question, parentIndex, parentIndexSubType }) => {
  const value = useFieldValue(`answers.${parentIndex}.answer`)

  if (!value) return null

  const childQuestions = getChildQuestions(question, value)
  if (!childQuestions) return null

  return childQuestions.length ? (
    <div className="bg-neutral-300 p-6">
      {childQuestions.map((question, index) => (
        <ChildQuestion
          key={index}
          index={index}
          alternativeBaseName={`${baseName}.sub_answers_attributes.${question.alternative_id}.${index}`}
          questions={childQuestions}
          parentIndex={parentIndexSubType}
        />
      ))}
    </div>
  ) : null
}

ChildrenQuestions.propTypes = {
  baseName: PropTypes.string,
  parentIndex: PropTypes.number,
  parentIndexSubType: PropTypes.number,
  question: PropTypes.object
}
