import PropTypes from 'prop-types'
import React from 'react'
import SelectAndPreviewCard from './SelectAndPreviewCard'

const SelectAndPreviewCards = ({ collection, createPath, onPreview }) => {
  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 my-6">
      {collection.map((item, key) => {
        const path = item.legal_conditions ? '/formularios/crear/condiciones/' : createPath
        return <SelectAndPreviewCard key={key} item={item} createPath={path} onPreview={onPreview} />
      })}
    </div>
  )
}
export default SelectAndPreviewCards

SelectAndPreviewCards.propTypes = {
  collection: PropTypes.array.isRequired,
  createPath: PropTypes.string,
  onPreview: PropTypes.func
}
