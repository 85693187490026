import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Tr = ({ children, ariaDescribedby, ariaBusy, className, body = true }) => {
  const classesBody = body ? 'border-b border-t bg-white' : ''
  return (
    <tr aria-describedby={ariaDescribedby} aria-busy={ariaBusy} className={clsx(classesBody, className)}>
      {children}
    </tr>
  )
}

export default Tr

Tr.propTypes = {
  children: PropTypes.node,
  ariaDescribedby: PropTypes.string,
  ariaBusy: PropTypes.bool,
  body: PropTypes.bool,
  className: PropTypes.string
}
