import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../molecules/Auth'
import UserInfoTable from '../forms/UserInfoTable'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'

const AdminTable = ({ enabledProcedureManagements = [] }) => {
  const { user } = useAuth()
  return (
    <div className="pt-8 pb-2">
      <Table
        className="table-fixed lg:w-full"
        values={enabledProcedureManagements}
        head={
          <Tr body={false}>
            <Th className="w-52">Tramite</Th>
            <Th className="w-52">Entidad</Th>
            <Th className="w-44">Habilitación</Th>
            <Th className="w-44">Actualización</Th>
            <Th className="w-36">Publicación</Th>
            <Th className="w-36">Solicitudes</Th>
          </Tr>
        }
        body={enabledProcedureManagements.map(row => (
          <Tr key={row.id}>
            <Td>{row.name}</Td>
            <Td>
              <Link to={`/entidades/${row.institution_id}/detalles`}>{row.institution_name}</Link>
            </Td>
            <Td>
              <UserInfoTable
                user={user}
                id={row.created_by}
                name={row.creator_name}
                eliminatorName={row.creator_eliminator_name}
                eliminatedName={row.eliminated_creator_name}
                date={row.date_created_at}
                hour={row.hour_created_at}
                hasHour
              />
            </Td>
            <Td>
              <UserInfoTable
                user={user}
                id={row.updated_by}
                name={row.updater_name}
                eliminatorName={row.updater_eliminator_name}
                eliminatedName={row.eliminated_updater_name}
                date={row.date_updated_at}
                hour={row.hour_updated_at}
                hasHour
              />
            </Td>
            <Td>{row.published ? 'Publicado' : 'No publicado'}</Td>
            <Td>{row.procedures_count}</Td>
          </Tr>
        ))}
      />
    </div>
  )
}

export default AdminTable

AdminTable.propTypes = {
  enabledProcedureManagements: PropTypes.arrayOf(PropTypes.object)
}
