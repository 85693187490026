import React from 'react'
import img1 from '../../../../images/quick_search/format/img_1.png'
import img2 from '../../../../images/quick_search/format/img_2.png'
import img3 from '../../../../images/quick_search/format/img_3.png'
import img4 from '../../../../images/quick_search/format/img_4.png'
import imgMobile2 from '../../../../images/quick_search/format/img_mobile_2.png'
import imgMobile3 from '../../../../images/quick_search/format/img_mobile_3.png'
import imgMobile4 from '../../../../images/quick_search/format/img_mobile_4.png'
import Container from '../../../atoms/Container'

const QuickSearchExampleFormat = () => {
  return (
    <Container className="grid align-middle">
      <div className="mx-auto my-12">
        <h1 className="font-bold text-3xl mb-12 px-2 md:px-0">Ejemplo de base de datos en excel</h1>
        <div className="overflow-auto mb-12 px-2 md:px-0">
          <img className="transform scale-150 md:scale-100 w-full" src={img1} alt="quick-search-fields" />
        </div>
        <div className="hidden md:block">
          <img className="mb-12" src={img2} alt="quick-search-document-type" />
          <img className="mb-12" src={img3} alt="quick-search-results" />
          <img className="mb-12" src={img4} alt="quick-search-variables" />
        </div>
        <div className="md:hidden px-2">
          <img className="mb-12 w-full" src={imgMobile2} alt="quick-search-document-type-mobile" />
          <img className="mb-12 w-full" src={imgMobile3} alt="quick-search-results-mobile" />
          <img className="mb-12 w-full" src={imgMobile4} alt="quick-search-variables-mobile" />
        </div>
      </div>
    </Container>
  )
}
export default QuickSearchExampleFormat
