import React from 'react'
import useUserPermission from '../../../hooks/useUserPermission'
import ConfigPaymentMethods from '../../organisms/payments/ConfigPaymentMethods'
import EnablePaymentToInstitutions from '../../organisms/payments/EnablePaymentToInstitutions'
import PageHeading from '../../molecules/PageHeading'
import { ReactSVG as Svg } from 'react-svg'
import WalletIcon from '../../../images/icons/wallet_icon.svg'
import Title from '../../atoms/Title'
import { useAuth } from '../../molecules/Auth'
import { AlertPageProvider } from '../../../context/AlertPageProvider'
import PaymentAlert from '../../organisms/payments/PaymentAlert'

const PaymentMethodsConfiguration = () => {
  const { user } = useAuth()
  const { canEnablePayment } = useUserPermission('payment')

  if (canEnablePayment)
    return (
      <AlertPageProvider>
        <PaymentAlert />
        <EnablePaymentToInstitutions />
      </AlertPageProvider>
    )

  if (user.institution_enabled_payment)
    return (
      <AlertPageProvider>
        <PaymentAlert />
        <PageHeading title="Medios de pago" pageDescription="Agrega medios de pago para tu institución." />
        <ConfigPaymentMethods />
      </AlertPageProvider>
    )

  return (
    <>
      <Title className="pt-8 px-6 md:px-0">Medios de pago</Title>
      <div className="bg-neutral-100 py-10 px-6">
        <div className="max-w-screen-md flex flex-col items-center mx-auto">
          <div className="text-2xl md:text-3xl font-bold mb-4">No tienes activada la funcionalidad de pagos</div>
          <div className="mb-6 max-w-screen-sm md:text-center">
            Esta funcionalidad te permitirá agregar medios de pago a tus formularios.{' '}
            <span className="font-bold">Para activarla, envía un correo a mesadeayuda@gobiernodigital.gob.pe</span> con
            el asunto
            {' "'}Activar medios de pago{'"'}.
          </div>
          <div className="bg-white w-full flex flex-col-reverse md:flex-row justify-between items-center border-3 border-neutral-300 rounded-lg py-4 px-4 md:px-8">
            <div className="mt-8 md:mt-0">
              <div className="font-bold mb-4">El correo debe incluir:</div>
              <ul className="list-disc list-outside ml-4">
                <li>Nombre de tu entidad</li>
                <li>Nombre de los trámites en los que deseas activar el pago.</li>
              </ul>
            </div>
            <Svg src={WalletIcon} />
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentMethodsConfiguration
