import useCrud from './useCrud'

const useEnabledProcedureManagement = id => {
  const urlBase = '/admin/v1/enabled_procedure_managements'

  const { findById } = useCrud(urlBase)

  const { get: getStates } = useCrud(`${urlBase}/${id}/states`)

  const { get: getAreas } = useCrud(`${urlBase}/${id}/areas`)

  return { findById, getStates, getAreas }
}
export default useEnabledProcedureManagement
