import PropTypes from 'prop-types'
import React from 'react'
import TextField from './fields/TextField'
import Alert from './Alert'

const TextWithInfoField = ({ payload, name, infoContent, disabled = false }) => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <TextField label={payload.label} name={name} disabled={disabled} hint={payload?.hint} />
      <Alert type="info" border>
        {infoContent}
      </Alert>
    </div>
  )
}

export default TextWithInfoField

TextWithInfoField.propTypes = {
  disabled: PropTypes.bool,
  infoContent: PropTypes.node,
  name: PropTypes.string,
  payload: PropTypes.object
}
