import React, { Fragment } from 'react'
import AddIcon from '@material-ui/icons/Add'
import useFilters from '../../../hooks/useFilters'
import PageBody from '../../molecules/PageBody'
import PageHeading from '../../molecules/PageHeading'
import BranchOfficesTable from '../../organisms/branch_offices/BranchOfficesTable'
import InstitutionFilter from '../../organisms/filters/InstitutionFilter'
import useUserPermission from '../../../hooks/useUserPermission'

const BranchOffices = () => {
  const { canFilterByInstitution } = useUserPermission()

  const urlFetch = `/admin/v1/branch_offices.json`
  const { onFilter, initialFilters, results: branchOffices } = useFilters(urlFetch, { sort: 'name asc' })
  const sortOptions = [
    { value: 'name asc', label: 'Nombre (A-Z)' },
    { value: 'name desc', label: 'Nombre (Z-A)' },
    { value: 'created_at desc', label: 'Más recientes primero' },
    { value: 'created_at asc', label: 'Más antiguos primero' }
  ]

  return (
    <Fragment>
      <PageHeading
        title="Sedes"
        pageDescription={
          canFilterByInstitution ? (
            <InstitutionFilter label="Selecciona la entidad cuyas sedes quieres ver." />
          ) : (
            'Estas son las sedes de tu entidad.'
          )
        }
        button={{
          name: 'Crear sede en Gob.pe',
          href: `${window.sisoli.gobpe_url}/admin2/sedes/new`,
          icon: <AddIcon />
        }}
      />
      <PageBody
        results={branchOffices}
        optionsOrderBy={sortOptions}
        onFilter={onFilter}
        initialFilters={initialFilters}
      >
        <BranchOfficesTable branchOffices={branchOffices.data} />
      </PageBody>
    </Fragment>
  )
}
export default BranchOffices
