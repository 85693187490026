import PropTypes from 'prop-types'
import React from 'react'
import DropdownField from '../question_config_fields/DropdownField'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DropdownCitizenField from '../question_fields/DropdownCitizenField'

const Form = props => {
  return <DropdownField baseName={props.baseName} />
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <DropdownField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <DropdownCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const DropdowQuestion = {
  selectOption: { value: 'dropdown', label: 'Desplegable (dropdown)', icon: <ArrowDropDownIcon fontSize="small" /> },
  Form,
  Template,
  name: 'DropdowQuestion'
}

export default DropdowQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.element
}
