import React from 'react'
import SearchField from './SearchField'
import { Form } from 'react-final-form'
import clsx from 'clsx'
import SortBy from './SortBy'
import { Pagination } from './Pagination'
import AutoSave from './AutoSave'
import { Button } from '../atoms/Button'
import { Link } from 'react-router-dom'
import AccordionFilter from './filters/AccordionFilter'
import PropTypes from 'prop-types'

export const BoxTable = ({
  id,
  title,
  description,
  children,
  initialFilters,
  onFilter,
  buttons,
  filters = null,
  sortOptions,
  sortByName,
  values,
  paginationLabel,
  searchFieldName,
  searchPlaceholder = 'Buscar por nombre o ID',
  className,
  displayTotal = false,
  displayHeader = true,
  displaySort = false,
  displaySortTop = false,
  accordionHasBackground,
  asideFilter,
  displayExpandedFilter = false,
  onPageChange
}) => {
  return (
    <div className={className} id={id} data-testid={id}>
      {displayHeader && (
        <div className="py-8 px-6 bg-blue-300 flex justify-center md:justify-between items-center border-b border-gray-350 border-solid flex-wrap md:flex-nowrap">
          <div className="flex flex-col pb-4 md:pb-0">
            <h2 className="md:text-left w-full md:w-auto font-bold text-2xl">{title}</h2>
            {description && <p className="mb-2 md:mb-0">{description}</p>}
          </div>
          <div className="flex flex-col-reverse md:flex-row content-end md:items-center justify-end w-full md:w-auto">
            {buttons &&
              buttons.map((button, index) => (
                <Button
                  key={index}
                  el={Link}
                  variant={button.variant}
                  to={button.link}
                  size="full"
                  className={clsx('max-w-full', button.className)}
                  {...button}
                >
                  {button.name}
                </Button>
              ))}
          </div>
        </div>
      )}
      <Form onSubmit={onFilter} initialValues={initialFilters}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AutoSave debounce={1000} save={onFilter} />
            {filters && (
              <AccordionFilter
                accordionHasBackground={accordionHasBackground}
                hasHeader={displayHeader}
                displayExpandedFilter={displayExpandedFilter}
              >
                {filters}
              </AccordionFilter>
            )}
            <div className="px-6 pt-8 pb-4 bg-neutral-100 rounded">
              <div className="flex justify-between flex-wrap md:flex-nowrap">
                <div className="w-full md:w-1/2 mb-0">
                  <SearchField placeholder={searchPlaceholder} name={searchFieldName} />
                </div>
                <div className="w-full md:w-auto">
                  {asideFilter}
                  {displaySortTop && (
                    <div className="w-full md:w-auto">
                      <SortBy options={sortOptions} name={sortByName} />
                    </div>
                  )}
                </div>
              </div>
              <hr className="h-2px bg-gray-350 mt-6 md:block hidden" />
              {(displayTotal || displaySort) && (
                <div className="flex justify-between flex-wrap md:flex-nowrap border-solid border-neutral-400 border-t-0.375 pt-6 md:mb-2">
                  {displayTotal && (
                    <div className="w-full mb-4 md:mb-0">
                      <div className="pt-2 w-full">
                        <h3 className="text-lg font-bold">Resultados encontrados ({values.total_count})</h3>
                      </div>
                    </div>
                  )}
                  {displaySort && (
                    <div className="w-full md:w-auto">
                      <SortBy options={sortOptions} name={sortByName} disabled={values.total_count === 0} />
                    </div>
                  )}
                </div>
              )}
              <div className="mt-0 flex justify-center flex-wrap">
                <div className="overflow-x-auto overflow-y-hidden w-full">{children}</div>
                <div>
                  {values.total_count > 0 && values.total_pages && (
                    <Pagination
                      numPages={values.total_pages}
                      paginationLabel={paginationLabel}
                      onPageChange={onPageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

BoxTable.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  initialFilters: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.element,
  sortOptions: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  sortByName: PropTypes.string,
  values: PropTypes.exact({
    data: PropTypes.array,
    total_count: PropTypes.number,
    total_pages: PropTypes.number
  }).isRequired,
  paginationLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  className: PropTypes.string,
  displayTotal: PropTypes.bool,
  displayHeader: PropTypes.bool,
  displaySort: PropTypes.bool,
  displaySortTop: PropTypes.bool,
  accordionHasBackground: PropTypes.bool,
  displayExpandedFilter: PropTypes.bool,
  asideFilter: PropTypes.node,
  searchFieldName: PropTypes.string,
  onPageChange: PropTypes.func
}
