import PropTypes from 'prop-types'
import React from 'react'
import ModalWithAnimation from '../../molecules/dialogs/ModalWithAnimation'
import axios from 'axios'
import { Button } from '../../atoms/Button'
import { Close } from '@material-ui/icons'
import { AreasForm } from '../areas_users/AreasForm'
import useFieldValue from '../../../hooks/useFieldValue'

const NewAreaModal = ({ open, onClose, onSave }) => {
  const handleSubmit = async values => {
    try {
      const area = await axios.post(`/admin/v1/areas.json`, values).then(res => res.data)
      onSave(area)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }
  const branchOfficeId = useFieldValue('branch_office_id')
  const initialValues = { branch_office_id: branchOfficeId }

  return (
    <ModalWithAnimation size="l" open={open}>
      <div className="text-right">
        <Button
          type="button"
          variant="text"
          size="xs"
          className="font-bold -right-6"
          endIcon={<Close />}
          onClick={onClose}
        >
          Cerrar
        </Button>
      </div>
      <h2 className="header text-xl mb-4 font-bold">Crear nueva área</h2>
      <p className="mb-2" id="page-description">
        Estás creando una nueva área para tu institución dentro de Facilita. Podrás editarla desde la sección Áreas y
        usuarios.
      </p>
      <AreasForm onSubmit={handleSubmit} initialValues={initialValues} create />
    </ModalWithAnimation>
  )
}
export default NewAreaModal

NewAreaModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool
}
