import React from 'react'
import { TextField } from '@material-ui/core'

const DateFieldText = ({ ...props }) => (
  <div className="flex flex-wrap">
    <div className="absolute h-full w-11.25 bg-blue-400 border-l-2 border-black right-0" />
    <TextField {...props} className="relative" />
  </div>
)

export default DateFieldText
