import PropTypes from 'prop-types'
import React from 'react'
import FormSection from '../../molecules/FormSection'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import TextField from '../../molecules/fields/TextField'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import { HtmlField } from '../../molecules/fields/HtmlField'
import FieldTitle from '../../molecules/FieldTitle'
import { PaymentMethods } from '../forms/PaymentMethods'
import CheckboxField from '../../molecules/fields/CheckboxField'
import TasksWithPayment from './TasksWithPayment'
import useUserPermission from '../../../hooks/useUserPermission'
import DestinyBranchOfficeType from './DestinyBranchOfficeType'
import { swornDeclarationUrl } from '../../../configs/assetsConfig'
import { TagBox } from '../../molecules/TagBox'
import Value from '../../molecules/Value'

const EnabledProcedureManagementsFormSetup = ({ initialValues, config }) => {
  const { canChoseDestinyBranchOfficeType } = useUserPermission('procedureManagement')
  return (
    <FormSection title="Información y configuración general">
      <TextField
        name="name"
        label="Nombre del formulario"
        margin="none"
        size="2xl"
        validate={validations.required()}
        disabled
      />
      <FieldsSeparator />
      <HtmlField
        name="hint"
        label="Información general del formulario"
        hint="Es la información que se le dará al ciudadano para ayudarlo con el proceso del trámite."
        validate={validationsAdmin.isRichTextFieldEmpty()}
        maxContentLength={600}
      />
      {canChoseDestinyBranchOfficeType && <DestinyBranchOfficeType />}
      <FieldsSeparator />
      {config.tasks_with_payment.length ? (
        <>
          <FieldTitle title="Medios de pago" hint={<TasksWithPayment data={config.tasks_with_payment} />} />
          {config?.enabled_payment_methods && (
            <div className="p-4 md:p-6 bg-neutral-200 mt-2 md:ml-6">
              <p className="mb-4">Selecciona los medios de pago habilitados para este trámite:</p>
              <PaymentMethods
                paymentTransferAccounts={config.enabled_payment_methods.has_transfer_accounts}
                enabledPaymentTransferAccountsAttributes={
                  config.enabled_payment_methods.payment_transfer_accounts_attributes
                }
              />
            </div>
          )}
        </>
      ) : (
        <FieldTitle
          title="Pago junto con el envío de formulario"
          hint="Este trámite no requiere de un pago junto con el envío del formulario."
        />
      )}
      <FieldsSeparator />
      {initialValues.sworn_declaration ? (
        <>
          <FieldTitle
            title="Declaración jurada"
            hint="Este formulario tiene carácter de declaración jurada, para enviarlo, el ciudadano deberá marcar el siguiente recuadro:"
          />
          <div className="mt-6 bg-neutral-300 p-4">
            <CheckboxField
              name="accepts_sworn_declaration"
              margin="none"
              label={
                <p>
                  Acepto{' '}
                  <a href={swornDeclarationUrl} target="_blank" rel="noreferrer">
                    la declaración jurada de veracidad de la información
                  </a>
                </p>
              }
              disabled
            />
          </div>
        </>
      ) : (
        <FieldTitle title="Declaración jurada" hint="Este formulario no tiene caracter de Declaración jurada." />
      )}
      <FieldsSeparator />
      {initialValues.auto_response && (
        <HtmlField
          name="auto_response_text"
          label="Información para el ciudadano después de enviado el formulario"
          hint="La siguiente información se le mostrará al ciudadano en la página y correo de constancia de envío del formulario."
          validate={validationsAdmin.isRichTextFieldEmpty()}
          maxContentLength={600}
        />
      )}
      <FieldsSeparator />
      <FieldTitle
        title={
          <>
            Respuestas hacia una API <TagBox className="ml-2" label="BETA" />
          </>
        }
        hint={
          <CheckboxField
            name="has_webhook"
            margin="none"
            label="Quiero que las respuestas de los ciudadanos se registren también en un API de mi institución."
          />
        }
      />
      <Value name="has_webhook">
        {({ value }) =>
          value && (
            <>
              <TextField
                name="webhook_url"
                size="5xl"
                rootClassName="ml-8"
                rows={6}
                margin="high"
                label="Ingresa la ruta"
                hint="Haremos un POST con content-type: application/json"
                validate={validations.mix(validations.required(), validations.isURL())}
              />
              <TextField
                name="webhook_auth"
                size="full"
                rootClassName="ml-8"
                rows={6}
                margin="none"
                hint="Lo enviaremos en el header authorization y así podrás comprobar que el envío viene desde Facilita"
                label="Ingresa el texto de autenticación"
                validate={validations.required()}
              />
            </>
          )
        }
      </Value>
    </FormSection>
  )
}
export default EnabledProcedureManagementsFormSetup

EnabledProcedureManagementsFormSetup.propTypes = {
  config: PropTypes.object,
  initialValues: PropTypes.object
}
