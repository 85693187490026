import PropTypes from 'prop-types'
import PageHeading from '../../molecules/PageHeading'
import AddIcon from '@material-ui/icons/Add'
import LaunchIcon from '@material-ui/icons/Launch'
import PageBody from '../../molecules/PageBody'
import EnabledProcedureManagementsTable from './EnabledProcedureManagementsTable'
import React from 'react'
import axios from 'axios'
import { useQueryClient } from 'react-query'
import useUserPermission from '../../../hooks/useUserPermission'
import BranchOfficeFilter from '../filters/BranchOfficeFilter'
import { pageDescription } from '../../../configs/enabledProcedureManagementsConfig'

const CoordinatorPage = ({ enabledProcedureManagements, onFilter, initialFilters, options }) => {
  const { canCreateEnabledProcedureManagements, canFilterByBranchOffice } = useUserPermission('procedureManagement')
  const generalPermissions = useUserPermission()
  const queryClient = useQueryClient()

  const handleDestroy = async enabledProcedureManagement => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la gestión de trámite ${enabledProcedureManagement.name}?`)) {
      try {
        await axios.delete(`/admin/v1/enabled_procedure_managements/${enabledProcedureManagement.id}.json`)
        queryClient.invalidateQueries([`/admin/v1/enabled_procedure_managements.json`])
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }
  return (
    <>
      <PageHeading
        title={<span className="flex items-center">Gestión de trámites</span>}
        pageDescription={pageDescription(generalPermissions)}
        button={
          canCreateEnabledProcedureManagements && {
            link: '/gestiones-de-tramites-habilitadas/crear',
            name: 'Habilitar nueva gestión de trámite',
            icon: <AddIcon />
          }
        }
        filterSection={
          <div className="flex-col md:flex-row flex w-full items-baseline md:gap-2">
            {canFilterByBranchOffice && <BranchOfficeFilter byType="by_enabled_procedure_management" />}
          </div>
        }
        linksSection={
          <a className="flex" href="gestiones-de-tramites/que-es-un-gestor-de-tramites" target="_blank">
            <p className="font-bold mx-auto md:ml-0 text-lg">
              ¿Qué es una gestión de trámite? <LaunchIcon className="ml-2 mb-1" />
            </p>
          </a>
        }
      />
      <PageBody
        results={enabledProcedureManagements}
        onFilter={onFilter}
        initialFilters={initialFilters}
        optionsOrderBy={options}
      >
        <EnabledProcedureManagementsTable
          enabledProcedureManagements={enabledProcedureManagements.data}
          onDestroy={handleDestroy}
        />
      </PageBody>
    </>
  )
}

export default CoordinatorPage

CoordinatorPage.propTypes = {
  enabledProcedureManagements: PropTypes.arrayOf(PropTypes.object),
  initialFilters: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
  options: PropTypes.array
}
