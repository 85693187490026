import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useField, useForm } from 'react-final-form'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Error from '../Error'
import clsx from 'clsx'
import Hint from '../../atoms/Hint'
import { marginSizes } from '../../../configs/sizesConfig'
import { useTranslation } from 'react-i18next'
import OtherField from './OtherField'

const RadioField = ({
  label,
  name,
  baseName,
  hint,
  validate,
  otherValidate,
  options = [],
  disabled = false,
  containerClassName = '',
  errorClassName = '',
  margin = 'normal',
  allowClearSelection = false,
  nsTranslation = 'none',
  ...props
}) => {
  const { input } = useField(name, { validate, ...props })
  const { input: inputOther } = useField(`${baseName}.other_text`)
  const { mutators } = useForm()
  const { t } = useTranslation([nsTranslation, 'validation'])
  const handleDeleteSelected = () => {
    setOtherSelected(false)
    mutators.clear(name)
    const FIRST_NAME_AND_ID_REGEX = /^[^.]+\.[^.]+\./i
    const answerIdName = FIRST_NAME_AND_ID_REGEX.exec(name)[0]
    mutators.clear(`${answerIdName}sub_answers_attributes`)
    if (baseName) {
      mutators.clear(`${baseName}.other_text`)
    }
  }
  const [otherSelected, setOtherSelected] = useState()
  const handleChange = type => {
    setOtherSelected(type === 'other')
    inputOther.onChange(null)
  }

  return (
    <div className="relative">
      <fieldset className={clsx('js-field-container', containerClassName, marginSizes[margin])}>
        <legend className="font-bold mb-4">{label}</legend>
        {hint && <Hint hint={hint} name={name} />}
        <RadioGroup
          aria-label={label}
          name={name}
          {...input}
          onChange={e => {
            input.onChange(e.target.value)
            props.onChange && props.onChange(e.target.value)
          }}
        >
          {options.map((o, index) => {
            const isOtherAndSelected = o.type === 'other' && otherSelected
            return (
              <div className="flex items-start flex-col md:flex-row" key={index}>
                <FormControlLabel
                  value={o.value.toString()}
                  control={
                    <Radio
                      disabled={disabled}
                      className="py-1"
                      onChange={() => handleChange(o.type)}
                      checked={o.value.toString() === input.value || isOtherAndSelected}
                    />
                  }
                  classes={{ label: 'text-base py-1', root: clsx(isOtherAndSelected && 'mt-3') }}
                  label={t(o.label, { defaultValue: o.label })}
                />
                <OtherField name={baseName} open={isOtherAndSelected} validate={otherValidate} />
              </div>
            )
          })}
        </RadioGroup>
        {allowClearSelection && input.value && !validate && (
          <button className="text-blue-700 inline-block mt-2" onClick={handleDeleteSelected} type="button">
            {t('validate_clear_selection', { ns: 'validation' })}
          </button>
        )}
        <Error name={name} className={errorClassName} />
      </fieldset>
    </div>
  )
}

export default RadioField

RadioField.propTypes = {
  allowClearSelection: PropTypes.bool,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  errorClassName: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string,
  baseName: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  otherValidate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  nsTranslation: PropTypes.string
}
