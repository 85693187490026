import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from '../../atoms/Button'
import AddIcon from '@material-ui/icons/Add'
import { useFieldArray } from 'react-final-form-arrays'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import useTasks from '../../../hooks/useTasks'
import useStates from '../../../hooks/useStates'
import useUserTypes from '../../../hooks/useUserTypes'
import { dataToOptions } from '../../../utils/helpers'
import Value from '../../molecules/Value'
import {
  conditionActionOptions,
  conditionAfterOptions,
  conditionTimeOptions,
  getBgColor,
  getOptionTasksToRules,
  thenActionOptions,
  getRulesTitleAdditionalText
} from '../../../configs/procedureManagementsConfig'
import TextField from '../../molecules/fields/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Menu, MenuItem } from '@material-ui/core'
import { useForm } from 'react-final-form'
import useFieldValue from '../../../hooks/useFieldValue'

const RulesSection = ({ task }) => {
  const { change } = useForm()
  const [anchorEl, setAnchorEl] = useState(null)
  const procedureManagementId = useFieldValue('procedure_management_id')
  const { data: tasks, status } = useTasks(procedureManagementId)
  const { data: states, status: statusState } = useStates(procedureManagementId)
  const { data: userTypes, status: statusUserType } = useUserTypes(procedureManagementId)
  const { fields } = useFieldArray('rules')
  if (status !== 'success' || statusState !== 'success' || statusUserType !== 'success') return null
  const userTypeOptions = [{ value: 'all', label: 'todos los usuarios' }, ...dataToOptions(userTypes)]
  const permittedStates = states.filter(state => state.color !== null)
  return (
    <>
      <h3 className="font-bold mb-4">
        Reglas adicionales <span className="font-normal">{getRulesTitleAdditionalText(task.kind)}</span>{' '}
      </h3>
      {fields.map((name, index) => (
        <div className="flex flex-col md:flex-row flex-wrap gap-3 md:items-center mb-8" key={index}>
          <div>Si esta tarea ({task.name})</div>
          <SelectField
            name={`${name}.condition_action_code`}
            options={conditionActionOptions}
            margin="none"
            validate={validations.required()}
            emptyLabel="Seleccionar"
          />
          <Value name={`${name}.condition_time_code`}>
            {({ value: conditionTimeCode }) =>
              conditionTimeCode === 'in_x_time' ? (
                <div className="flex items-center">
                  en
                  <TextField
                    name={`${name}.condition_time_days`}
                    rootClassName="max-w-16 mx-2"
                    margin="none"
                    validate={validations.required()}
                    errorClassName="leading-none -bottom-9"
                  />
                  días
                  <IconButton
                    type="button"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={event => setAnchorEl(event.currentTarget)}
                    className="p-1"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    {conditionTimeOptions.map((conditionTime, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          change(`${name}.condition_time_code`, conditionTimeOptions[index].value)
                          change(`${name}.condition_time_days`, null)
                          change(`${name}.condition_after_code`, null)
                          setAnchorEl(null)
                        }}
                      >
                        {conditionTime.label}
                      </MenuItem>
                    ))}
                  </Menu>
                  <SelectField
                    name={`${name}.condition_after_code`}
                    options={conditionAfterOptions}
                    margin="none"
                    validate={validations.required()}
                    emptyLabel="Seleccionar"
                    size="full"
                  />
                </div>
              ) : (
                <SelectField
                  name={`${name}.condition_time_code`}
                  options={conditionTimeOptions}
                  margin="none"
                  validate={validations.required()}
                  emptyLabel="Seleccionar"
                />
              )
            }
          </Value>
          <div>entonces</div>
          <SelectField
            name={`${name}.then_action_code`}
            options={thenActionOptions}
            margin="none"
            validate={validations.required()}
            emptyLabel="Seleccionar"
          />
          <Value name={`${name}.then_action_code`}>
            {({ value: thenActionCode }) =>
              thenActionCode === 'change_state' ? (
                <>
                  <div>a</div>
                  <SelectField
                    name={`${name}.then_state_id`}
                    options={dataToOptions(permittedStates, stateOption => getBgColor(stateOption.color))}
                    margin="none"
                    validate={validations.required()}
                    emptyLabel="Seleccionar"
                  />
                  <div>para</div>
                  <div className="flex items-center">
                    <SelectField
                      name={`${name}.then_user_type_id`}
                      options={userTypeOptions}
                      containerClassName="flex-1 md:flex-0"
                      margin="none"
                      validate={validations.required()}
                      emptyLabel="Seleccionar"
                    />
                    <IconButton type="button" color="primary" onClick={() => fields.remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </>
              ) : (
                <div className="flex items-center gap-3">
                  <SelectField
                    name={`${name}.then_task_id`}
                    options={getOptionTasksToRules(tasks, task)}
                    margin="none"
                    validate={validations.required()}
                    emptyLabel="Seleccionar tarea"
                    size="3xl"
                  />
                  <div>por el resto de la atención.</div>
                  <IconButton type="button" color="primary" onClick={() => fields.remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )
            }
          </Value>
        </div>
      ))}
      <Button
        variant="text"
        size="s"
        className="font-bold text-lg"
        startIcon={<AddIcon />}
        onClick={() => fields.push()}
      >
        Añadir regla
      </Button>
    </>
  )
}
export default RulesSection

RulesSection.propTypes = {
  task: PropTypes.object.isRequired
}
