import FormsHeader from '../../../molecules/FormsHeader'
import React, { useState } from 'react'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import { formSteps } from '../../../../configs/enabledProcedureManagementsConfig'
import SelectAndPreviewCard from '../../../organisms/forms/SelectAndPreviewCard'
import { useQuery } from 'react-query'
import axios from 'axios'
import SelectPreviewModal from '../../../organisms/enabled_procedure_managements/SelectPreviewModal'
import notResultsImg from '../../../../images/procedure_managements/not_results.png'
import { Link } from 'react-router-dom'
import { Button } from '../../../atoms/Button'
import { useAuth } from '../../../molecules/Auth'
import PreviewAndGoToPaymentInfo from '../../../organisms/forms/PreviewAndGoToPaymentInfo'

const headerInfo = {
  title: 'Habilitar una gestión de trámite',
  hint: 'Configura la gestión de trámite que deseas habilitar para tu institución.'
}
const getPublishedProcedureManagements = () =>
  axios.get(`/admin/v1/procedure_managements/published_without_enabling.json`).then(res => res.data)

const EnabledProcedureManagementsSelect = () => {
  const { user } = useAuth()
  const { data: procedureManagements } = useQuery(['publishedProcedureManagement'], getPublishedProcedureManagements, {
    refetchOnWindowFocus: false
  })
  const [procedureManagement, setProcedureManagement] = useState(null)
  const [hideSelect, setHideSelect] = useState(false)

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FormsHeader
          title={headerInfo.title}
          hint={headerInfo.hint}
          steps={formSteps}
          activeStep={formSteps.SELECTION}
          size="l"
        />
        {procedureManagements?.length > 0 ? (
          <div className="bg-neutral-100 py-6 md:py-10 px-4 md:px-6">
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 my-6 items-start">
              {procedureManagements.map((procedureManagement, index) => (
                <SelectAndPreviewCard
                  key={index}
                  item={procedureManagement}
                  createPath="/gestiones-de-tramites-habilitadas/crear/"
                  onPreview={() => {
                    setProcedureManagement(procedureManagement)
                    setHideSelect(false)
                  }}
                  footer={
                    procedureManagement.has_payment ? (
                      !user.institution_enabled_payment ? (
                        <PreviewAndGoToPaymentInfo
                          text="Este trámite requiere que actives la funcionalidad de pagos."
                          onPreview={() => {
                            setProcedureManagement(procedureManagement)
                            setHideSelect(true)
                          }}
                        />
                      ) : !user.institution_has_payment_methods ? (
                        <PreviewAndGoToPaymentInfo
                          text="Este trámite requiere que habilites al menos un medio de pago."
                          onPreview={() => {
                            setProcedureManagement(procedureManagement)
                            setHideSelect(true)
                          }}
                        />
                      ) : null
                    ) : null
                  }
                />
              ))}
            </div>
            {procedureManagement && (
              <SelectPreviewModal
                procedureManagement={procedureManagement}
                onClose={() => setProcedureManagement(null)}
                hideSelect={hideSelect}
              />
            )}
          </div>
        ) : (
          <div className="flex justify-center">
            <div>
              <img className="mb-8" src={notResultsImg} alt="no-results-procedure-managements" />
              <Button el={Link} to="/gestiones-de-tramites-habilitadas" className="mx-auto flex" size="s">
                Regresar
              </Button>
            </div>
          </div>
        )}
      </Container>
    </>
  )
}

export default EnabledProcedureManagementsSelect
