import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'

const TemplatesTable = ({ templates = [], onDestroy }) => {
  return (
    <Table
      className="table-auto w-full"
      values={templates}
      head={
        <Tr body={false}>
          <Th className="text-center">ID</Th>
          <Th>Nombre</Th>
          <Th>Creación</Th>
          <Th>Última actualización</Th>
          <Th>Publicación</Th>
          <Th>Acciones</Th>
        </Tr>
      }
      body={templates.map(template => (
        <Tr key={template.id}>
          <Td className="text-center">{template.id}</Td>
          <Td>
            <Link to={`/plantillas/${template.id}/vista-previa`}>{template.name}</Link>
          </Td>
          <Td>{template.created_at}</Td>
          <Td>{template.updated_at}</Td>
          <Td>{template.published ? 'Publicado' : 'No publicado'}</Td>
          <Td>
            <EditButton to={`/plantillas/${template.id}/editar`} />
            <DeleteButton onClick={() => onDestroy(template)} />
          </Td>
        </Tr>
      ))}
    />
  )
}

export default TemplatesTable

TemplatesTable.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object.isRequired)
}
