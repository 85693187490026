import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import { formSteps } from '../../../../configs/procedureManagementsConfig'
import axios from 'axios'
import { ProcedureManagementForm } from '../../../organisms/procedure_managements/ProcedureManagementForm'
import PropTypes from 'prop-types'

const updateProcedureManagement =
  procedureManagementId =>
  ({ values, config }) => {
    return axios
      .put(`/admin/v1/procedure_managements/${procedureManagementId}.json`, values, config)
      .then(res => res.data)
  }

const fetchProcedureManagement = procedureManagementId => {
  return axios.get(`/admin/v1/procedure_managements/${procedureManagementId}.json`).then(response => response.data)
}

export const ProcedureManagementsEdit = ({ step }) => {
  const { id: procedureManagementId } = useParams()

  const queryClient = useQueryClient()
  const history = useHistory()
  const { search } = useLocation()
  const { data: procedureManagementData, status } = useQuery(
    ['proceduraManagement', procedureManagementId],
    () => fetchProcedureManagement(procedureManagementId),
    {
      refetchOnWindowFocus: false
    }
  )
  const { mutate } = useMutation(updateProcedureManagement(procedureManagementId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['proceduraManagement', procedureManagementId])
      history.push(`/gestiones-de-tramites/${procedureManagementId}/tipos-de-usuarios-tareas-conexiones${search}`)
    }
  })

  if (status !== 'success') return null

  const { form, ...procedureManagementAttributes } = procedureManagementData
  const initialValues = { procedure_management_attributes: procedureManagementAttributes, ...form }

  return <ProcedureManagementForm initialValues={initialValues} mutate={mutate} formSteps={formSteps} step={step} />
}

ProcedureManagementsEdit.propTypes = {
  step: PropTypes.number
}
