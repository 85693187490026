import { FormControl } from '@material-ui/core'
import React from 'react'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import { dataToOptions } from '../../../utils/helpers'
import PublishedFilter from '../filters/PublishedFilter'
import DateRangeField from '../../molecules/fields/DateRangeField'
import useQuickSearch from '../../../hooks/useQuickSearch'
import PublishScheduleFilter from '../filters/PublishScheduleFilter'

const QuickSearchFilter = () => {
  const { getCreators } = useQuickSearch()
  const { data: creators, status } = getCreators(['creators'])

  if (status !== 'success') return null
  return (
    <div className="flex gap-10 md:flex-row w-full flex-col py-6 px-0.5">
      <FormControl component="fieldset">
        <PublishedFilter />
      </FormControl>
      <FormControl>
        <AutocompleteField
          label="Creado por"
          emptyLabel="Seleccionar usuario"
          options={[...dataToOptions(creators)]}
          name="created_by"
          size="full"
          margin="none"
          boxClassName="min-w-60 mt-2"
        />
      </FormControl>
      <FormControl component="fieldset" classes={{ root: 'md:mt-0' }}>
        <DateRangeField name="date" label="Fecha de creación" />
      </FormControl>
      <FormControl component="fieldset">
        <PublishScheduleFilter />
      </FormControl>
    </div>
  )
}

export default QuickSearchFilter
