import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { Button } from '../../atoms/Button'
import { Close } from '@material-ui/icons'

const ConfirmDialog = ({
  title,
  open,
  confirmLabel = 'Aceptar',
  onConfirm,
  onClose,
  children,
  primaryConfirmBtn = false,
  rightButtonProps,
  leftButton,
  rightButton
}) => {
  const leftButtonObj = leftButton || {
    onClick: primaryConfirmBtn ? onClose : onConfirm,
    text: primaryConfirmBtn ? 'Cancelar' : confirmLabel
  }
  const rightButtonObj = rightButton || {
    onClick: primaryConfirmBtn ? onConfirm : onClose,
    text: primaryConfirmBtn ? confirmLabel : 'Cancelar'
  }
  return (
    <Dialog aria-labelledby="dialog-title" open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle className="pb-0">
        <div className="text-right">
          <Button variant="text" size="xxs" className="font-bold mb-0" endIcon={<Close />} onClick={onClose}>
            Cerrar
          </Button>
        </div>
        <p className="header font-bold text-xl mb-4">{title}</p>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className="p-6 flex flex-col-reverse md:flex-row justify-end gap-3">
        <Button onClick={leftButtonObj.onClick} variant="secondary" size="full" className="md:max-w-40">
          {leftButtonObj.text}
        </Button>
        <Button onClick={rightButtonObj.onClick} size="full" fullWidth={false} {...rightButtonProps}>
          {rightButtonObj.text}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog

ConfirmDialog.propTypes = {
  children: PropTypes.node,
  confirmLabel: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  primaryConfirmBtn: PropTypes.bool,
  rightButtonProps: PropTypes.object,
  leftButton: PropTypes.object,
  rightButton: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}
