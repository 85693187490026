import PropTypes from 'prop-types'
import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Input, InputAdornment } from '@material-ui/core'
import clsx from 'clsx'
import { useField } from 'react-final-form'
import Error from '../Error'
import { marginSizes, fieldWidthSizes } from '../../../configs/sizesConfig'
import Hint from '../../atoms/Hint'

export const PasswordField = ({
  label,
  name,
  validate,
  disabled = false,
  size = 'xl',
  margin = 'normal',
  rootClassName,
  errorClassName,
  autoComplete,
  hint,
  ariaLabel,
  ...props
}) => {
  const { input } = useField(name, { validate, ...props })
  const [isVisible, setVisibility] = useState(false)
  return (
    <div className={clsx('relative', marginSizes[margin], rootClassName)}>
      {label && (
        <label
          className="block font-bold mb-2"
          id={`${name}-label`}
          htmlFor={`${name}-input`}
          aria-describedby={`${name}-hint`}
        >
          {label}
        </label>
      )}
      {hint && <Hint hint={hint} />}
      <Input
        id={`${name}-input`}
        type={isVisible ? 'text' : 'password'}
        className={clsx(
          'border-2 border-gray-800 shadow py-1 pl-4 pr-0 rounded-sm bg-white focus:rounded-sm text-base w-full focus-within:border-yellow-500',
          fieldWidthSizes[size]
        )}
        aria-label={ariaLabel}
        aria-describedby={`${name}-hint`}
        disabled={disabled}
        {...input}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setVisibility(!isVisible)
              }}
            >
              {isVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Error name={name} className={clsx('-bottom-6 absolute', errorClassName)} />
      {autoComplete === 'off' && (
        <input autoComplete="new-password" name="fakepasswordremembered" type="password" className="hidden" />
      )}
    </div>
  )
}

PasswordField.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errorClassName: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string,
  rootClassName: PropTypes.string,
  size: PropTypes.string,
  validate: PropTypes.func,
  ariaLabel: PropTypes.string
}
