import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'

const Value = ({ name, children }) => (
  <Field name={name} subscription={{ value: true }}>
    {({ input: { value } }) => children({ value })}
  </Field>
)

export default Value

Value.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string
}
