import React from 'react'
import useTutorial from '../../../../hooks/useTutorial'
import PageNew from '../../../organisms/onboarding/PageNew'

const headerInfo = { title: 'Crear artículo de tutorial' }

const TutorialNew = () => {
  const { createTutorial } = useTutorial()

  return <PageNew headerInfo={headerInfo} createArticle={createTutorial} isTutorial />
}

export default TutorialNew
