import PropTypes from 'prop-types'
import React from 'react'

const getFirstLetter = (name = '') => (name.match(/[a-z]/i).length > 0 ? name.match(/[a-z]/i)[0].toUpperCase() : '')

const UserInfo = ({ user }) => {
  return (
    <>
      <div className="bg-red-800 rounded-md flex items-center justify-center w-8 h-8">
        <span className="text-white text-sm font-bold">{getFirstLetter(user.name)}</span>
      </div>
      <div className="hidden md:block max-w-40 text-xs text-left">
        <div className="font-bold truncate">{user.name}</div>
        <div className="truncate font-medium">{user.email}</div>
      </div>
    </>
  )
}

export default UserInfo

UserInfo.propTypes = {
  user: PropTypes.object.isRequired
}
