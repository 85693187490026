import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { AppBar, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { defaultUserType } from '../../../configs/procedureManagementsConfig'
import CitizenFormContainer from '../citizens/CitizenFormContainer'
import { formValuesPreview } from '../../../configs/templatesConfig'
import desktopPreview from '../../../images/procedure_managements/desktop_preview.png'
import mobilePreview from '../../../images/procedure_managements/mobile_preview.png'

const TabContextPreview = ({ userTypes, form }) => {
  const [tabSelected, setTabSelected] = useState(defaultUserType.name)
  const userTypesAndDefaultUserType = [{ name: defaultUserType.name }, ...userTypes]
  const handleChangeTab = (event, newValue) => setTabSelected(newValue)

  return (
    <TabContext value={tabSelected}>
      <AppBar position="static" color="default" classes={{ colorDefault: 'bg-white' }}>
        <TabList onChange={handleChangeTab} variant="fullWidth" classes={{ indicator: 'h-1 bg-red-700 top-0' }}>
          {userTypesAndDefaultUserType.map((userType, index) => (
            <Tab
              key={index}
              label={userType.name}
              value={userType.name}
              classes={{ selected: 'bg-neutral-200 text-red-700 font-bold' }}
            />
          ))}
        </TabList>
      </AppBar>
      <TabPanel value={defaultUserType.name} classes={{ root: 'p-0' }}>
        <CitizenFormContainer form={formValuesPreview(form)} isPreview />
      </TabPanel>
      {userTypes.map((userType, index) => (
        <TabPanel key={index} value={userType.name} classes={{ root: 'p-0' }}>
          <img src={desktopPreview} alt="" className="hidden md:block w-full h-auto" />
          <img src={mobilePreview} alt="" className="md:hidden mx-auto" />
        </TabPanel>
      ))}
    </TabContext>
  )
}
export default TabContextPreview

TabContextPreview.propTypes = {
  form: PropTypes.object,
  userTypes: PropTypes.array.isRequired
}
