import PropTypes from 'prop-types'
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Button } from '../../atoms/Button'
import clsx from 'clsx'
import { Close } from '@material-ui/icons'

export const ExampleModal = ({
  show,
  children,
  desktopImage,
  mobileImage,
  title,
  mobileScrollable = false,
  onClose
}) => {
  return (
    <Dialog aria-labelledby="dialog-title" onClose={onClose} maxWidth="md" open={show} classes={{ paper: 'rounded' }}>
      <div className={clsx('px-4 pb-4 pt-0 md:px-8 md:pb-12', mobileScrollable && 'w-1000 md:w-auto mt-0 md:mt-0')}>
        <div className={clsx('text-right bg-white sticky top-0 py-2 md:pt-0')}>
          <Button
            variant="text"
            type="button"
            fullWidth={false}
            onClick={onClose}
            className={clsx('text-base font-bold pt-4', mobileScrollable && 'right-12 top-12 fixed md:static pt-0')}
            endIcon={<Close />}
          >
            Cerrar
          </Button>
          <div className={clsx('text-left', mobileScrollable && 'mt-20 md:mt-0')}>
            <h1 className="font-bold text-xl mb-1">{title}</h1>
          </div>
        </div>
        <p className="mb-6 md:mb-8">{children}</p>
        <div className="flex justify-center">
          <img src={desktopImage} alt="" className="hidden md:block" />
          <img src={mobileImage} alt="" className="block md:hidden mx-auto" />
        </div>
      </div>
    </Dialog>
  )
}

ExampleModal.propTypes = {
  children: PropTypes.node,
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
  mobileScrollable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  title: PropTypes.string
}
