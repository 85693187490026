import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { useTranslation } from 'react-i18next'
import * as validations from '../../../utils/validations'
import HiddenField from './HiddenField'

const EmailField = ({ baseName, question }) => {
  const { t } = useTranslation('form')

  let hint
  let initialValue
  switch (question.payload.contact_medium) {
    case 'cellphone':
      hint = 'Teléfono celular del contacto (debes tener acceso al teléfono)'
      initialValue = t('form_label_contact_cell_phone_must_have_access')
      break
    case 'email':
      hint = 'Correo electrónico de contacto (debes tener acceso al correo)'
      initialValue = t('form_label_contact_email_must_have_access')
      break
    case 'email_cellphone':
      hint = 'Correo electrónico o teléfono celular (a elección del ciudadano)'
      initialValue = t('form_label_email_cellphone')
      break
    default:
      hint = ''
      initialValue = ''
  }

  return (
    <div className="grid gap-4">
      <Field name={`${baseName}.payload.label`}>
        {({ input }) => {
          if (input.value === '') {
            input.onChange(initialValue)
          }
          return (
            <TextField
              label="Etiqueta de la pregunta"
              size="full"
              hint={hint}
              validate={validations.required()}
              margin="none"
              errorClassName="relative inset-0"
              {...input}
              disabled
            />
          )
        }}
      </Field>

      <HiddenField name={`${baseName}.payload.contact_medium`} value={question.payload.contact_medium} />
      <HiddenField name={`${baseName}.payload.has_hint`} value={question.payload.has_hint} />
      <HiddenField name={`${baseName}.payload.has_indication_text`} value={question.payload.has_indication_text} />
    </div>
  )
}

export default EmailField

EmailField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object
}
