import React, { useState, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import Container from '../../atoms/Container'
import { useLocation, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { ChevronRight } from '@material-ui/icons'
import {
  getTabsTree,
  flatTabsTreeAndFilterVisible,
  getSubTabsSelectedAndVisible,
  isParentTab,
  hasChildTabSelected,
  isLastParentTab
} from '../../../configs/tabsConfig'
import { ChildrenTabs } from '../../molecules/ChildrenTabs'
import useUserPermission from '../../../hooks/useUserPermission'
import MenuButton from '../../molecules/buttons/MenuButton'

const MenuList = ({ onChangeMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedTab, setSelectedTab] = useState()
  const containerMenu = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname
  const currentTab = `/${pathname?.split('/')[1]}`
  const [showMenuMobile, setShowMenuMobile] = useState(false)
  const [showSubMenuMobile, setShowSubMenuMobile] = useState(false)

  const menuPermissions = useUserPermission('menu')
  const tabsTree = getTabsTree(menuPermissions)
  const flattedTabs = flatTabsTreeAndFilterVisible(tabsTree)
  const selectedSubTabs = getSubTabsSelectedAndVisible(flattedTabs, selectedTab)

  const onClickTabHandler = (event, tab) => {
    if (isParentTab(tab)) {
      const anchor = showMenuMobile ? containerMenu.current : event.currentTarget
      setAnchorEl(anchor)
      setSelectedTab(tab.name)
    } else {
      history.push(tab.path)
    }
    setShowMenuMobile(false)
    if (showMenuMobile) setShowSubMenuMobile(true)
    if (onChangeMenu) onChangeMenu(event)
  }

  const isCurrentMenuTab = tab => {
    return (
      currentTab === tab.path ||
      (isParentTab(tab) && tab.subMenu?.find(el => currentTab === el.path || el.relatedPaths?.includes(currentTab)))
    )
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <div ref={containerMenu} className="md:flex md:h-16 md:bg-white container-tabs">
      <Container>
        <div className="flex md:hidden items-center justify-between px-4 bg-white h-16 absolute right-2 top-4">
          <MenuButton onClick={() => setShowMenuMobile(!showMenuMobile)} />
        </div>
        <nav>
          <ul
            className={clsx('md:flex flex-col md:flex-row justify-center bg-neutral-100', { hidden: !showMenuMobile })}
          >
            {flattedTabs.map((tab, index) => (
              <Fragment key={index}>
                {!tab.isChildTab && (
                  <li className="w-full" {...{ 'aria-current': isCurrentMenuTab(tab) ? 'page' : false }}>
                    <button
                      className={clsx(
                        'text-center cursor-pointer w-full h-16 border-l border-t border-neutral-300 relative bg-white border-b-2',
                        'hover:bg-neutral-100',
                        { 'border-b-4 bg-neutral-200 font-bold text-red-800': isCurrentMenuTab(tab) },
                        { 'border-r border-b-2': isLastParentTab(index, flattedTabs) },
                        { 'react-tabs__tab--selected': hasChildTabSelected(tab, pathname) }
                      )}
                      onClick={event => onClickTabHandler(event, tab)}
                      data-testid={tab.name}
                      data-component-id="menu-tab"
                    >
                      {tab.name}{' '}
                      {isCurrentMenuTab(tab) && (
                        <i className="absolute h-1 w-12 rounded mt-auto mb-0 mx-auto inset-0 bg-red-800" />
                      )}
                      {isParentTab(tab) && (showMenuMobile ? <ChevronRight /> : <KeyboardArrowDown className="ml-2" />)}
                    </button>
                  </li>
                )}
              </Fragment>
            ))}
            {selectedSubTabs?.length && (
              <ChildrenTabs
                anchorEl={anchorEl}
                onClose={handleClose}
                container={containerMenu.current}
                selectedSubTabs={selectedSubTabs}
                setShowMenuMobile={setShowMenuMobile}
                showSubMenuMobile={showSubMenuMobile}
              />
            )}
          </ul>
        </nav>
      </Container>
    </div>
  )
}

export default MenuList

MenuList.propTypes = {
  onChangeMenu: PropTypes.func
}
