import React, { Fragment, useRef, useState } from 'react'
import Container from '../../../atoms/Container'
import { Link, useParams } from 'react-router-dom'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Title from '../../../atoms/Title'
import useProcedureManagementAccess from '../../../../hooks/useProcedureManagementAccess'
import useEnabledProcedureManagement from '../../../../hooks/useEnabledProcedureManagement'
import { isSuccessful, isUnauthorized } from '../../../../hooks/useQuery'
import useSearchParams from '../../../../hooks/useSearchParams'
import { Button } from '../../../atoms/Button'
import Alert from '../../../molecules/Alert'
import ProcedureBody from '../../../organisms/procedures/ProcedureBody'
import ErrorAccessPage from '../../../templates/ErrorAccessPage'

const filterState = states =>
  states?.filter(state => state.name !== 'Atendido' && state.name !== 'Archivado').map(state => state.id.toString())

const Procedures = () => {
  const { id } = useParams()
  const urlParams = useSearchParams()
  const isNewManualEntry = urlParams.get('action') === 'new_manual_entry'
  const [defaultFilters, setDefaultFilters] = useState(null)

  const urlParamAccessId = urlParams.get('access_id')
  const firstLoadRequired = useRef(!urlParamAccessId)
  const { accesses, currentAccess, error } = useProcedureManagementAccess(id, urlParamAccessId)
  const accessId = urlParamAccessId || currentAccess?.id

  const areaId = currentAccess?.area_id

  const { findById, getStates } = useEnabledProcedureManagement(id)
  const { data: enabledProcedureManagement, status: enabledProcedureManagementStatus } = findById([
    'enabled_procedure_management',
    id
  ])
  const { data: states, status: stateStatus } = getStates(
    ['states', accessId],
    { access_id: accessId },
    { enabled: Boolean(accessId) }
  )
  const [prevStates, setPrevStates] = useState(states)

  const setInitialFilter = () => {
    if (accesses && firstLoadRequired.current) {
      const access = accesses.all.find(el => el.id === parseInt(accessId))
      const filter = {
        state: filterState(states),
        access_id: access.id,
        user_type_id: access.user_type_id,
        area_id: access.area_id,
        sort: 'deadline asc'
      }
      setDefaultFilters(filter)
    }
    if (!firstLoadRequired.current) {
      firstLoadRequired.current = true
    }
  }

  if (states !== prevStates) {
    setPrevStates(states)
    setInitialFilter()
  }
  if (isUnauthorized(error)) {
    return (
      <ErrorAccessPage
        importantText="Lo sentimos, pero no tienes acceso para visualizar este contenido"
        description="Si deseas, puedes consultar otros contenidos desde el menú principal o regresar a la página de inicio"
        textButton="Ir a la página de inicio"
        linkButton="/formularios?action=access_error"
      />
    )
  }

  return (
    currentAccess &&
    isSuccessful(enabledProcedureManagementStatus) &&
    (urlParamAccessId || defaultFilters) && (
      <Fragment>
        <PageNavigation breadcrumbData={enabledProcedureManagement} />
        <Container>
          {isNewManualEntry && (
            <Alert type="success" className="mt-16">
              <p className="font-bold">Solicitud registrada con éxito</p>
              <p>Se ha enviado una constancia de registro al correo electrónico del ciudadano.</p>
            </Alert>
          )}
          <div className="flex flex-col md:flex-row justify-between px-4 md:px-0 pt-16 pb-10">
            <Title aria-describedby="page-description" className="">
              Gestión de {enabledProcedureManagement.name}
            </Title>
            {currentAccess.can_manual_entry && (
              <Button
                className="md:w-72"
                size="full"
                variant="secondary"
                component={Link}
                to={`/gestiones-de-tramites-habilitadas/${enabledProcedureManagement?.id}/entrada-manual`}
              >
                Ingresar solicitud manual
              </Button>
            )}
          </div>
          <ProcedureBody
            defaultFilters={defaultFilters}
            accesses={accesses}
            id={id}
            areaId={areaId}
            states={states}
            stateStatus={stateStatus}
          />
        </Container>
      </Fragment>
    )
  )
}

export default Procedures
