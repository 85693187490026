import PropTypes from 'prop-types'
import React from 'react'
import CitizenHeader from '../question_fields/CitizenHeader'
import Container from '../../atoms/Container'
import LanguageSelector from './LanguageSelector'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'

const Location = ({ ubigeo }) => {
  if (!ubigeo) return null
  const [region, province] = ubigeo.split('-')
  return (
    <h2 className="mb-6">
      Departamento de {region.trim()}, Provincia de {province.trim()}
    </h2>
  )
}

export const CitizenFormPageContainer = ({ children, showContainerHeader = true, show, state }) => {
  const institution = state?.context.form_data?.institution
  const translations = state?.context.form_data?.translations
  const history = useHistory()
  const activeStep = state?.context.activeStep
  const coverImage = state?.context.form_data?.cover_image?.url

  const handleLanguageChange = event => {
    const selectedLanguage = event.target.value
    i18n.changeLanguage(selectedLanguage).then(() => {
      history.push(`?locale=${selectedLanguage}`)
    })
  }

  const languages = translations || []

  return (
    show && (
      <div id="preview-scroll-to">
        <CitizenHeader institution={institution} showUserInfo={false} />
        <Container className="py-8 md:py-12 px-4 md:px-0">
          <main className="max-w-screen-lg m-auto">
            {coverImage && <img className="mb-4" src={coverImage} alt="" />}
            {showContainerHeader && (
              <div>
                <div className="flex justify-between">
                  <h1 className="font-bold text-3xl md:text-4xl leading-9 md:leading-10 mb-2">
                    {state?.context.form_data?.name}
                  </h1>
                  {activeStep === 0 && <LanguageSelector languages={languages} onChange={handleLanguageChange} />}
                </div>
                <Location ubigeo={institution?.ubigeo_full_name} />
              </div>
            )}
            {children}
          </main>
        </Container>
      </div>
    )
  )
}

Location.propTypes = {
  ubigeo: PropTypes.string
}

CitizenFormPageContainer.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  showContainerHeader: PropTypes.bool,
  state: PropTypes.object
}
