import { useQuery } from 'react-query'
import axios from 'axios'

export const isDefault = task => task.kind === 'default'

export const isMakePayment = task => task.kind === 'make_payment'

export const isEnablePayment = task => task.kind === 'enable_payment'

export const isCommentary = task => task.kind === 'commentary'

export const amountTypeIsFixed = amountType => amountType === 'fixed'

export const amountTypeIsVariable = amountType => amountType === 'variable'

const fetchTask = id => axios.get(`/admin/v1/tasks/${id}.json`).then(res => res.data)
const useTask = id => {
  return useQuery(['task', id], () => fetchTask(id), { refetchOnWindowFocus: false })
}
export default useTask
