import PropTypes from 'prop-types'
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { Button } from '../../atoms/Button'
import AddIcon from '@material-ui/icons/Add'
import AreaSection from './AreaSection'
import useQuery from '../../../hooks/useQuery'
import axios from 'axios'
import { getUnselected } from '../../../utils/helpers'
import useFieldValue from '../../../hooks/useFieldValue'

const fetchAreas = branchOfficeId =>
  axios.get(`/admin/v1/areas/all.json`, { params: { branch_office_id: branchOfficeId } }).then(a => a.data)

const AreasSection = ({ baseName }) => {
  const { fields } = useFieldArray(`${baseName}.areas_attributes`)
  const branchOfficeId = useFieldValue('branch_office_id')
  const { data: areas, status } = useQuery(['areas', branchOfficeId], fetchAreas)
  if (status !== 'success') return null
  const selectedAreas = fields.value.map(field => (field._destroy ? undefined : field.id?.toString()))
  return (
    <>
      <div className="mb-6">
        {fields.map(
          (name, index) =>
            !fields.value[index]?._destroy && (
              <AreaSection
                key={index}
                name={name}
                onRemove={() => fields.markForDestroy(index)}
                areas={getUnselected(areas, selectedAreas, index)}
              />
            )
        )}
      </div>
      <Button
        variant="secondary"
        className="font-bold text-lg h-12 md:h-13 md:max-w-56"
        size="full"
        startIcon={<AddIcon />}
        onClick={() => fields.push({ accesses_attributes: [null] })}
      >
        Agregar área
      </Button>
    </>
  )
}
export default AreasSection

AreasSection.propTypes = {
  baseName: PropTypes.string
}
