import { useField } from 'react-final-form'

export const useCustomField = (name, config) => {
  const { input, meta } = useField(name, { ...config })
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError)
  const hasTouched = config?.subscription?.touched ? meta.touched : true
  const gotError = hasTouched && error && typeof error === 'string'

  return { input, meta, error, gotError }
}
