import PropTypes from 'prop-types'
import React from 'react'

const TaskHistoryTitleRight = ({ leafId, onShowAll }) => {
  return leafId ? (
    <div className="flex items-center">
      Estás viendo las tareas de <span className="font-bold ml-1">1 flujo</span>
      <button className="font-bold ml-3 text-blue-700" onClick={onShowAll} type="button">
        Ver todos
      </button>
    </div>
  ) : (
    <div>
      Estás viendo el historial de <span className="font-bold">todos los flujos</span>
    </div>
  )
}
export default TaskHistoryTitleRight

TaskHistoryTitleRight.propTypes = {
  leafId: PropTypes.number,
  onShowAll: PropTypes.func.isRequired
}
