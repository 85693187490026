import React from 'react'
import FunctionaryPage from '../../../organisms/onboarding/FunctionaryPage'
import useUpgrade from '../../../../hooks/useUpgrade'

const FunctionaryTutorialPage = () => {
  const { getUpgrade } = useUpgrade()
  return <FunctionaryPage getArticle={getUpgrade} />
}

export default FunctionaryTutorialPage
