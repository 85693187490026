import PropTypes from 'prop-types'
import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import ReactPaginate from 'react-paginate'

export const Pagination = ({ numPages, paginationLabel = 'page', onPageChange }) => {
  const location = useLocation()
  const history = useHistory()

  const handlePageChange = ({ selected }) => {
    const parsed = { ...queryString.parse(location.search), [paginationLabel]: selected + 1 }
    history.push({ search: queryString.stringify(parsed) })
    onPageChange && onPageChange(selected + 1)
  }

  return numPages !== 1 ? (
    <div className="mb-6 flex">
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={<span className="gap">...</span>}
        pageCount={numPages}
        forcePage={queryString.parse(location.search)[paginationLabel] - 1 || 0}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        previousLinkClassName={'previous_page'}
        nextLinkClassName={'next_page'}
        pageClassName={'page'}
        activeClassName={'active'}
        activeLinkClassName={'active-link'}
        pageLinkClassName={'link'}
        disabledClassName={'disable'}
      />
    </div>
  ) : null
}

Pagination.propTypes = {
  numPages: PropTypes.number,
  paginationLabel: PropTypes.string,
  onPageChange: PropTypes.func
}
