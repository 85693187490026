import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { Button } from '../../atoms/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Header from '../../molecules/Header'
import { getWelcomeMessage, getLabelDocumentType, getLabelResult } from '../../../configs/quickSearchesConfig'
import CloseIcon from '@material-ui/icons/Close'
import useFieldValue from '../../../hooks/useFieldValue'
import ResultInfo from './ResultInfo'

const PreviewModal = ({ index, result }) => {
  const [open, setOpen] = useState(false)
  const documentTypeField = useFieldValue('document_type')
  const otherDocumentLabel = useFieldValue('other_document_label')
  const showContactInfo = useFieldValue('show_contact_info')
  const contactPhone = useFieldValue('contact_phone')
  const contactEmail = useFieldValue('contact_email')

  const contactInfo = showContactInfo ? { phone: contactPhone, email: contactEmail } : false
  const isQuickSearchResult = true
  const labelDocumentType = getLabelDocumentType(documentTypeField, otherDocumentLabel, isQuickSearchResult)

  return (
    <Fragment>
      <Button
        type="button"
        variant="text"
        size="s"
        className="mr-4"
        classes={{ root: 'px-2' }}
        onClick={() => setOpen(!open)}
      >
        <VisibilityIcon className="mr-1" />
        Vista previa
      </Button>
      <Dialog aria-labelledby="dialog-title" onClose={() => setOpen(false)} maxWidth="md" fullWidth open={open}>
        <div className="flex justify-end mr-3 mt-3">
          <Button endIcon={<CloseIcon />} variant="text" type="button" fullWidth={false} onClick={() => setOpen(false)}>
            Cerrar
          </Button>
        </div>
        <DialogTitle classes={{ root: 'pl-6 pb-2 pt-0 ' }} id="dialog-title">
          Vista previa: {index > 0 && 'Resultado'} {getLabelResult(index)}
        </DialogTitle>
        <p className="px-6">
          Esta vista previa <strong className="font-bold">no se conecta con la base de datos.</strong> En la vista
          previa final podrás consultar el número de documento y ver los resultados conectados a la base de datos.
        </p>
        <DialogContent>
          {open ? (
            <div className="mb-6 border-6 border-neutral-300">
              <Header showUserInfo={false} />
              <ResultInfo
                labelTypeDocument={labelDocumentType}
                title={result.title}
                hint={result.hint}
                contactInfo={contactInfo}
                documentNumber="XXXXXXXX"
                welcomeMessage={getWelcomeMessage(index ? undefined : '')}
              />
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default PreviewModal

PreviewModal.propTypes = {
  index: PropTypes.number,
  result: PropTypes.exact({
    title: PropTypes.string,
    hint: PropTypes.string
  })
}
